import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';
import { Inquiry } from '@models/contact';

@Injectable({
    providedIn: 'root',
})
export class ContactService {
    private endPointConfig: string = EndPointApi.getURL();

    constructor(private http: HttpClient) {}

    postInquiry = (inquiry: Inquiry) => {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.http
            .post<any>(this.endPointConfig + '/api/contact/purchaseinquiry', inquiry, {
                headers: header,
            })
            .subscribe((res) => {});
    };
}
