import { ProjectSettingFormData } from '../projectSettings';
import { ObjectSchema, boolean, mixed, number, object, string } from 'yup';
import { locale } from 'common/validators/yup-locale';
import * as yup from 'yup';
import { GenericErrorStrings } from 'icreate/common/validators/validation-errors/generic';
yup.setLocale(locale);

export const projectSettingFormDataValidator: ObjectSchema<ProjectSettingFormData> = object({
    projectName: string()
        .required()
        .nonNullable()
        .max(16, GenericErrorStrings.ABOVE_MAXIMUM)
        .strict(),
    vesselBuildData: object({
        id: string().uuid().required().nonNullable().strict(),
        name: string().nullable(),
    })
        .json()
        .nonNullable()
        .required()
        .strict(),
    vesselData: object({
        id: string().uuid().required().nonNullable().strict(),
        AMSVer: mixed().nullable(),
        managerName: string().nullable(),
        name: string().nullable(),
        ownerName: string().nullable(),
        vesselNumber: string().nullable(),
    })
        .json()
        .nonNullable()
        .required()
        .strict(),
}).strict();
