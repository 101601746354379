import { CloudOperation } from 'common/models/publish';
import { Hmi, LayoutSettings, View } from 'common/models/view';
export class Project {
    name: string;
    layout: Object;
    amsConfig: Object;
    createdAt: any;
    updatedAt: any;
    createdBy: string;
    updatedBy: string;
    VesselBuilderCompanyID: string;
    VesselBuilderCompanyName: string;
    VesselID: string;
    VesselName: string;
    VesselOwner: string;
    VesselManager: string;
    VesselNumber: string;
    AMSVersion: string;
    hmi: Hmi = new Hmi();
}

export enum ProjectDataCmdType {
    SetDevice = 'set-device',
    DelDevice = 'del-device',
    SetView = 'set-view',
    DelView = 'del-view',
    HmiLayout = 'layout',
    Charts = 'charts',
    SetAlarm = 'set-alarm',
    DelAlarm = 'del-alarm',
    SetAMSConfig = 'set-ams-config',
    UpdateDimensions = 'update-project-dimensions',
    SetText = 'set-text',
    DelText = 'del-text',
}

export class ProjectData {
    id: string;
    version?: string = '1.01';
    /** FUXA Server */
    hmi: Hmi = new Hmi();
    /** Devices, connection, Tags, etc. */
    charts?: Chart[] = [];
    /** AMS configuration (replaces devices) */
    amsConfig: any = [
        {
            server: {
                id: '0',
                name: 'Server',
                type: 'FuxaServer',
                enabled: true,
                property: {},
            },
        },
        { device: [] },
    ];
    /** project name */
    name: string;
    createdBy: string;
    VesselID: string;
    VesselBuilderCompanyID: string;
    VesselBuilderCompanyName: string;
    VesselNumber: string;
    VesselManager: string;
    VesselName: string;
    VesselOwner: string;
    AMSVersion: string;

    setVesselBuildData(vesselBuilder: VesselBuilder) {
        this.VesselBuilderCompanyID = vesselBuilder.id;
        this.VesselBuilderCompanyName = vesselBuilder.name;
    }

    setVesselData(vessel: Vessel) {
        this.VesselID = vessel.id;
        this.VesselNumber = vessel.vesselNumber;
        this.AMSVersion = vessel.AMSVer;
        this.VesselManager = vessel.managerName;
        this.VesselName = vessel.name;
    }
}

export class VesselBuilder {
    id: string;
    name: string;
    typeBuilder?: number;
}

export class Vessel {
    AMSVer: string;
    id: string;
    managerName: string;
    name: string;
    ownerName: string;
    vesselNumber: string;
}

export class ProjectSetting {
    projectName: string = null;
    vesselBuildData: VesselBuilder = new VesselBuilder();
    vesselData: Vessel = new Vessel();
}
export interface Chart {
    id: string;
    name: string;
    lines: ChartLine[];
}

export interface ChartLine {
    device?: string;
    id?: string;
    name?: string;
    label?: string;
    color?: string;
    yaxis?: number;
}

export interface CloudStorageItem {
    version: string;
    type: CloudOperation.PULL | CloudOperation.PUBLISH;
    blobProject?: ProjectData;
}
