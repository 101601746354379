<!-- Graph list -->
<div style="overflow: scroll; flex: 1" (click)="unselectRow()">
    <table mat-table [dataSource]="tableDataSource" class="table">
        <ng-container matColumnDef="locked">
            <th mat-header-cell *matHeaderCellDef class="table-header index-header"></th>
            <td mat-cell *matCellDef="let graph" class="table-row index index-header">
                <mat-icon
                    *ngIf="graph.locked"
                    svgIcon="locked"
                    class="icon"
                ></mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef class="table-header index-header"></th>
            <td
                mat-cell
                *matCellDef="let graph; let i = index"
                class="table-row index index-header"
            >
                {{ i + 1 }}
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef class="table-header option-header">
                {{ "graph-settings.main.table.header.type" | translate }}
            </th>
            <td mat-cell *matCellDef="let graph" class="table-row option-header">
                <mat-select
                    [disabled]="graph.locked"
                    [value]="graph.type"
                    (selectionChange)="graph.type = $event.value"
                    style="user-select: none;"
                >
                    <mat-option
                        *ngFor="let type of graphTypes"
                        [value]="type.value"
                        style="user-select: none;"
                    >
                        {{ type.displayString | translate }}
                    </mat-option>
                </mat-select>
                <div
                    class="error-container text light"
                    *ngIf="getGraphErrors(graph, 'type')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span data-testid="errorSetPoint">
                        {{ getGraphErrors(graph, "type") | translate }}</span
                    >
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef class="table-header option-header">
                {{ "graph-settings.main.table.header.title" | translate }}
            </th>
            <td mat-cell *matCellDef="let graph" class="table-row option-header">
                <input
                    [disabled]="graph.locked"
                    [(ngModel)]="graph.title"
                    maxlength="48"
                    class="title"
                    [value]="graph.title || ''"
                    placeholder="Graph Title"
                />
                <div
                    class="error-container text light"
                    *ngIf="getGraphErrors(graph, 'title')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span data-testid="errorSetPoint">
                        {{ getGraphErrors(graph, "title") | translate }}</span
                    >
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef class="table-header index-header"></th>
            <td
                mat-cell
                *matCellDef="let graph"
                class="table-row icon index-header"
                (click)="editGraph(graph)"
                [ngClass]="{ 'disable-button': graph.locked }"
            >
                <mat-icon svgIcon="open-graph-settings"></mat-icon>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="table-header"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selectGraph(row); $event.stopPropagation()"
            [ngStyle]="{
                'background-color':
                    selectedGraph === row ? 'rgba(48, 89, 175, 1)' : ''
            }"
        ></tr>
    </table>
</div>
<!-- Options -->
<div class="buttons-container">
    <button [disabled]="!canAddGraph()" class="add" (click)="addGraph()">
        {{ "graph-settings.main.button.add" | translate }}
    </button>
    <button [disabled]="!selectedGraph" class="delete" (click)="deleteGraph()">
        {{ "graph-settings.main.button.delete" | translate }}
    </button>
    <button class="copy" (click)="copyGraph()" [disabled]="!selectedGraph">
        {{ "graph-settings.main.button.copy" | translate }}
    </button>
    <button [disabled]="!copiedGraph" class="paste" (click)="pasteGraph()">
        {{ "graph-settings.main.button.paste" | translate }}
    </button>
    <button
        class="save"
        [disabled]="saveButtonIsDisabled()"
        (click)="saveGraphs()"
    >
        {{ "graph-settings.main.button.save" | translate }}
    </button>
    <button [disabled]="graphListErrors()" class="exit" (click)="exit()">
        {{ "graph-settings.main.button.exit" | translate }}
    </button>
</div>

<app-confirmation-window
    #confirmationWindow
    [mainLabel]="'graph-settings.graph.close.confirmation.title' | translate"
    [confirmLabel]="
        'graph-settings.graph.close.confirmation.confirm' | translate
    "
    [cancelLabel]="'graph-settings.graph.close.confirmation.cancel' | translate"
></app-confirmation-window>
