<div class="more-lists">
    <div class="more-list" (click)="onDelete(); $event.stopPropagation()">
        {{ "channel.more-option.delete" | translate }}
    </div>
    <div class="more-list" (click)="onDuplicate(); $event.stopPropagation()">
        {{ "channel.more-option.duplicate" | translate }}
    </div>
    <div class="more-list" (click)="onRename(); $event.stopPropagation()">
        {{ "channel.more-option.rename" | translate }}
    </div>
</div>
