<div style="overflow: scroll; flex: 1" (click)="unselectRow()">
    <table mat-table [dataSource]="tableDataSource" class="table">
        <ng-container matColumnDef="number">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="table-header index-header"
            ></th>
            <td
                mat-cell
                *matCellDef="let runHourSetting; let i = index"
                class="table-row index"
            >
                {{ i + 1 }}
            </td>
        </ng-container>

        <ng-container matColumnDef="runHourChannel">
            <th mat-header-cell *matHeaderCellDef class="table-header">
                {{
                    "run-hour-settings.table.header.runHourChannel" | translate
                }}
            </th>
            <td mat-cell *matCellDef="let runHourSetting" class="table-row">
                <div
                    *ngIf="
                        selectedSettings !== runHourSetting &&
                        runHourSetting.runHourChannel
                    "
                >
                    {{ runHourSetting.runHourChannel }}
                </div>

                <app-search-channel-input
                    *ngIf="
                        selectedSettings == runHourSetting ||
                        !runHourSetting.runHourChannel
                    "
                    [channels]="channels"
                    [channelNumber]="runHourSetting.runHourChannel"
                    (onChannelSelected)="runHourSetting.runHourChannel = $event"
                    [channelError]="
                        getSettingErrors(runHourSetting, 'runHourChannel')
                    "
                ></app-search-channel-input>
            </td>
        </ng-container>

        <ng-container matColumnDef="sourceChannel">
            <th mat-header-cell *matHeaderCellDef class="table-header">
                {{ "run-hour-settings.table.header.sourceChannel" | translate }}
            </th>
            <td mat-cell *matCellDef="let runHourSetting" class="table-row">
                <div
                    *ngIf="
                        selectedSettings !== runHourSetting &&
                        runHourSetting.sourceChannel
                    "
                >
                    {{ runHourSetting.sourceChannel }}
                </div>

                <app-search-channel-input
                    *ngIf="
                        selectedSettings == runHourSetting ||
                        !runHourSetting.sourceChannel
                    "
                    [channels]="channels"
                    [channelNumber]="runHourSetting.sourceChannel"
                    (onChannelSelected)="runHourSetting.sourceChannel = $event"
                    [channelError]="
                        getSettingErrors(runHourSetting, 'sourceChannel')
                    "
                ></app-search-channel-input>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="table-header"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selectRow(row); $event.stopPropagation()"
            [ngStyle]="{
                'background-color':
                    selectedSettings === row ? 'rgba(48, 89, 175, 1)' : ''
            }"
        ></tr>
    </table>
</div>
