import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { EndPointApi } from '@helpers/endpointapi';
import { Comment } from 'icreate/common/models/comments';
import { CommentSocketCommands } from 'icreate/common/comments/websocket-message';
import { SocketMiddlewareService } from './socket-middleware.service';

@Injectable({
    providedIn: 'root',
})
export class CommentsService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(
        private http: HttpClient,
        private socketService: SocketMiddlewareService,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    create(commentData: Comment): Promise<any> {
        return lastValueFrom(
            this.http.post(`${this.domain}/api/comments`, commentData, {
                headers: this.headers,
            }),
        );
    }

    getAllByViewId(viewId: string): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/comments`, {
                params: { viewId },
            }),
        );
    }

    getNew() {
        return this.socketService.receiveMessageObserver(CommentSocketCommands.GetNewComments);
    }

    deleteCommentsSocket() {
        return this.socketService.receiveMessageObserver(CommentSocketCommands.DeleteComment);
    }

    delete(commentData: Comment): Promise<any> {
        const commentId = commentData.id;
        return lastValueFrom(
            this.http.delete(`${this.domain}/api/comments/${commentId}`, {
                responseType: 'text',
            }),
        );
    }

    update(commentData: Comment): Promise<any> {
        const commentId = commentData.id;
        return lastValueFrom(
            this.http.put(`${this.domain}/api/comments/${commentId}`, commentData, {
                headers: this.headers,
            }),
        );
    }
}
