import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-infinite-scroll',
    templateUrl: './infinite-scroll.component.html',
    styleUrls: ['./infinite-scroll.component.css'],
})
export class InfiniteScrollComponent {
    @Input() scrollThreshold = 1000;
    @Output() scrolled = new EventEmitter<void>();

    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {}

    @HostListener('scroll', ['$event'])
    onScroll(event: Event): void {
        if (
            this.el.nativeElement.offsetHeight + this.el.nativeElement.scrollTop >=
            this.el.nativeElement.scrollHeight - this.scrollThreshold
        ) {
            this.scrolled.emit();
        }
    }

    scrollToTop() {
        this.el.nativeElement.scrollTop = 0;
    }
}
