<div style="width: 100%; height: 100% !important; position: absolute">
  <div class="header-panel">
    {{ "device.list-title" | translate }}
  </div>
  <div class="work-panel">
    <app-device-list
      [hidden]="showMode === 'map'"
      #devicelist
      (goto)="gotoMap()"
      [readonly]="readonly"
      [currentProjectId]="currentProjectId"
    ></app-device-list>
    <app-device-map
      [hidden]="showMode === 'tags'"
      #devicemap
      (goto)="gotoList($event)"
      [readonly]="readonly"
      [currentProjectId]="currentProjectId"
    ></app-device-map>
  </div>
</div>
<button
  mat-icon-button
  (click)="onReload()"
  class="fab-reload"
  [ngClass]="{ 'fab-reload-active': reloadActive }"
>
  <mat-icon>autorenew</mat-icon>
</button>
<button
  *ngIf="!readonly"
  mat-fab
  color="primary"
  (click)="addItem()"
  class="fab-add"
>
  <mat-icon class="">add</mat-icon>
</button>
<button mat-fab color="primary" (click)="onEditor()" class="fab-add back">
  <mat-icon>keyboard_arrow_left</mat-icon>
</button>
