<div class="panel-wrap">
    <div class="tab-wrap">
        <div class="tabs">
            <div
                (click)="selectMode(GROUP_MODE)"
                [class]="{ active: mode == GROUP_MODE }"
            >
                {{ "channel.label.groups" | translate }}
            </div>
            <div
                (click)="selectMode(CATEGORY_MODE)"
                [class]="{ active: mode == CATEGORY_MODE }"
            >
                {{ "channel.label.categories" | translate }}
            </div>
            <div
                (click)="selectMode(ALARM_MODE)"
                [class]="{ active: mode == ALARM_MODE }"
            >
                {{ "channel.label.alarm" | translate }}
            </div>
            <div
                class="add-icon"
                (click)="toggleAddOption()"
                [class]="{ active: isShowAddOption }"
            >
                <span class="material-symbols-outlined"> add </span>
                <div class="add-option-lists" *ngIf="isShowAddOption">
                    <div
                        class="add-option-list"
                        (click)="openGroupDialog({ type: ADD, group: null })"
                    >
                        {{ "channel.add-groups" | translate }}
                    </div>
                    <div
                        class="add-option-list"
                        (click)="
                            openCategoryDialog({ type: ADD, category: null })
                        "
                    >
                        {{ "channel.add-category" | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="mode == GROUP_MODE">
        <app-channel-panel-group
            (openGroupEmitter)="openGroupDialog($event)"
            [channelGroups]="channelGroups"
        ></app-channel-panel-group>
    </div>
    <div *ngIf="mode == CATEGORY_MODE">
        <app-channel-panel-category
            (openCategoryEmitter)="openCategoryDialog($event)"
            [channelCategories]="channelCategories"
            [channelGroups]="channelGroups"
        ></app-channel-panel-category>
    </div>
    <div *ngIf="mode == ALARM_MODE">
        <app-channel-panel-alarm></app-channel-panel-alarm>
    </div>
</div>
<app-dialog-channel-category
    #categoryDialog
    (renameCategory)="renameCategory($event)"
    (deleteCategory)="deleteCategory($event)"
    (addCategory)="addCategory($event)"
>
</app-dialog-channel-category>
<app-dialog-channel-group
    #groupDialog
    (renameGroup)="renameGroup($event)"
    (deleteGroup)="deleteGroup($event)"
    (addGroup)="addGroup($event)"
>
</app-dialog-channel-group>
