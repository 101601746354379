import { addMethod, ObjectSchema, Schema, string, ValidationError } from 'yup';

// addMethod(Schema, "requiredWhen", function(condition: boolean, message: string) {

// });

export function getValidationErrors(objectValidator: any, customObject: any): ValidationError[] {
    try {
        objectValidator.validateSync(customObject, { abortEarly: false });
        return [];
    } catch (e: any) {
        if (e.name !== 'ValidationError') {
            console.error(e);
            return [];
        }

        return (e as ValidationError).inner;
    }
}

export function mapErrors(errors: ValidationError[]): MappedErrors {
    const newErrors: MappedErrors = {};

    for (let i = 0; i < errors.length; i++) {
        newErrors[errors[i].path] = errors[i];
    }

    return newErrors;
}

export interface MappedErrors {
    [path: string]: ValidationError;
}
