export enum MimicAnimaitonOption {
    NONE = 'none',
    ALARM = 'alarm',
    CANCEL = 'cancel',
    UNACK = 'unack',

    ALARM_UNACK = 'alarm-unack',
    CANCEL_UNACK = 'cancel-unack',

    DIGITAL_NONE = 'digital-none',
}
