import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Comment } from 'icreate/common/models/comments';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';

@Component({
    selector: 'app-posted-comment',
    templateUrl: './posted-comment.component.html',
    styleUrls: ['./posted-comment.component.css'],
})
export class PostedCommentComponent implements OnInit, OnDestroy {
    @Input() comment: Comment;
    @Input() scale;
    @Input() movingComment;
    @Output() deleteCommentEmit = new EventEmitter();
    @Output() updateCommentEmit = new EventEmitter();
    @Output() editingCommentEmit = new EventEmitter();
    @Output() onMovingElement = new EventEmitter();
    @ViewChild('commentContainer', { static: true })
    commentContainer: ElementRef;
    showMessage: boolean = false;
    timeFromNow;
    nameInitials: string;
    showPostIt: boolean = false;
    editingComment: boolean = false;
    originalComment: string;
    movingElement: boolean = false;
    mousePosition = { x: 0, y: 0 };
    elementClicked: boolean = false;
    mouseMoveBinded = this.mouseMove.bind(this);
    mouseUpBinded = this.mouseUp.bind(this);
    mouseDownBinded = this.mouseDown.bind(this);

    constructor() {
        dayjs.extend(relativeTime);
    }

    ngOnInit() {
        this.timeFromNow = dayjs(this.comment.timestamp).fromNow();
        const myNames = this.comment.userName.split(' ');
        let initials;
        if (myNames.length > 1) {
            initials = myNames.shift().charAt(0) + myNames.pop().charAt(0);
        } else {
            initials = myNames.shift().charAt(0);
        }
        this.nameInitials = initials.toUpperCase();
    }

    ngOnDestroy() {
        this.showMessage = false;
        this.showPostIt = false;
        this.removeMouseEvents();
    }

    mouseEnter() {
        if (!this.showPostIt) {
            this.timeFromNow = dayjs(this.comment.timestamp).fromNow();
            this.showMessage = true;
        }
        this.bindMouseEvents();
    }

    mouseLeave() {
        if (!this.movingElement) {
            this.showMessage = false;
            this.movingElement = false;
            this.removeMouseEvents();
        }
    }

    openPostIt() {
        if (!this.movingElement) {
            this.showPostIt = true;
            this.showMessage = false;
        }
        this.movingElement = false;
    }

    closePostIt() {
        this.showPostIt = false;
        this.editingComment = false;
        this.showMessage = false;
    }

    deleteComment() {
        this.deleteCommentEmit.emit(this.comment);
    }

    editComment() {
        this.editingCommentEmit.emit(true);
        this.originalComment = this.comment.commentText;
        this.editingComment = true;
    }

    cancelEdit() {
        this.editingCommentEmit.emit(false);
        this.editingComment = false;
        this.comment.commentText = this.originalComment;
    }

    saveChanges() {
        this.updateCommentEmit.emit(this.comment);
        this.editingCommentEmit.emit(false);
    }

    bindMouseEvents() {
        this.commentContainer.nativeElement.addEventListener('mousedown', this.mouseDownBinded);
        document.addEventListener('mousemove', this.mouseMoveBinded);
        document.addEventListener('mouseup', this.mouseUpBinded);
    }

    removeMouseEvents() {
        this.commentContainer.nativeElement.removeEventListener('mousedown', this.mouseDownBinded);
        document.removeEventListener('mousemove', this.mouseMoveBinded);
        document.removeEventListener('mouseup', this.mouseUpBinded);
    }

    mouseUp() {
        if (this.movingElement) {
            this.updateCommentEmit.emit(this.comment);
        }
        this.onMovingElement.emit(false);
        this.movingElement = false;
        this.elementClicked = false;
        this.removeMouseEvents();
    }

    mouseMove(e) {
        const positionTop = this.comment.positionTop.slice(0, -2);
        const positionLeft = this.comment.positionLeft.slice(0, -2);

        const diffX = Number(e.clientX) / this.scale - Number(this.mousePosition.x) / this.scale;
        const diffY = Number(e.clientY) / this.scale - Number(this.mousePosition.y) / this.scale;
        if (!this.movingElement && this.elementClicked) {
            if (Math.abs(diffX) >= 5 || Math.abs(diffY) >= 5) {
                this.movingElement = true;
            }
        }
        if (!this.elementClicked) {
            this.showMessage = false;
            this.removeMouseEvents();
        }
        if (this.movingElement) {
            const newTopPosition = Number(positionTop) + diffY;
            const newLeftPosition = Number(positionLeft) + diffX;

            this.comment.positionTop = newTopPosition.toString() + 'px';
            this.comment.positionLeft = newLeftPosition.toString() + 'px';
            this.mousePosition.x = e.clientX;
            this.mousePosition.y = e.clientY;
            this.onMovingElement.emit(true);
        }
    }

    mouseDown(e) {
        this.elementClicked = true;
        this.mousePosition.x = e.clientX;
        this.mousePosition.y = e.clientY;
    }
}
