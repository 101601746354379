<div class="wrapper">
    <h1 mat-dialog-title style="display: inline-block; cursor: move">
        Select a vessel
    </h1>
    <br />
    <h2 mat-dialog-title style="display: inline-block; cursor: move">
        Builder: {{ builderName }}
    </h2>
    <br />
    <div class="search-register-area">
        <label
            >Search: <input type="text" name="search" [(ngModel)]="searchText"
        /></label>
        <button
            mat-raised-button
            matTooltip="Register New Vessel"
            color="primary"
            class="main-btn"
            (click)="onRegister()"
        >
            Add New Vessel
        </button>
    </div>
    <table>
        <tr>
            <th>Vessel No</th>
            <th>Vessel Name</th>
        </tr>
    </table>
    <div class="vesselLisstTable">
        <table>
            <tr
                *ngFor="let d of data | filter: searchText"
                (click)="rowClick(d)"
            >
                <td>{{ d.vesselNumber }}</td>
                <td>{{ d.name }}</td>
            </tr>
        </table>
    </div>
</div>
