import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationError, getValidationErrors, mapErrors } from 'common/validators';
import { ProjectData } from 'icreate/common/models/project';
import { PublishRequestValidator } from 'icreate/common/validators/publish-project-request/publish-project-request-validator';

@Component({
    selector: 'app-publish-project-dialog',
    templateUrl: './publish-project-dialog.component.html',
    styleUrls: ['./publish-project-dialog.component.scss'],
})
export class PublishProjectDialogComponent {
    publishReason: string;
    approverEmail: string;
    projectData: ProjectData;

    validationErrors: { [propertyName: string]: ValidationError } = {};

    constructor(
        public dialogRef: MatDialogRef<PublishProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { projectData: ProjectData },
    ) {
        this.projectData = data.projectData;
    }

    ngOnInit(): void {
        this.checkFormErrors();
    }

    async onActConfirm() {
        this.dialogRef.close({
            builderId: this.projectData.VesselBuilderCompanyID,
            vesselId: this.projectData.VesselID,
            projectId: this.projectData.id,
            approverEmail: this.approverEmail,
            publishReason: this.publishReason,
        });
    }

    onCancel(): any {
        this.dialogRef.close();
    }

    checkFormErrors() {
        const validateObject = {
            builderId: this.projectData.VesselBuilderCompanyID,
            vesselId: this.projectData.VesselID,
            projectId: this.projectData.id,
            approverEmail: this.approverEmail,
            publishReason: this.publishReason,
        };
        const errors = getValidationErrors(PublishRequestValidator, validateObject);
        if (errors) {
            this.validationErrors = mapErrors(errors);
        }
    }

    isActDisabled() {
        return !this.approverEmail && !this.publishReason;
    }
}
