import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-more-options',
    templateUrl: './more-options.component.html',
    styleUrls: ['./more-options.component.css'],
})
export class MoreOptionsComponent {
    @Output() deleteEmitter = new EventEmitter();
    @Output() renameEmitter = new EventEmitter();
    @Output() duplicateEmitter = new EventEmitter();

    onDelete() {
        this.deleteEmitter.emit();
    }

    onRename() {
        this.renameEmitter.emit();
    }

    onDuplicate() {
        this.duplicateEmitter.emit();
    }
}
