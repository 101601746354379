import { Point, LineSegment } from '@models/connector';

export function parseSVGPath(svgElement): Point[] {
    const pathString = svgElement.getAttribute('d');
    const commands = pathString.match(/[A-Za-z][^A-Za-z]*/g) || [];
    const segments: LineSegment[] = [];

    let lastPoint: Point | null = null;
    let lastControlPoint: Point | null = null;

    for (const command of commands) {
        const commandType = command.charAt(0);
        const params = command
            .substring(1)
            .trim()
            .split(/\s*,\s*|\s+/)
            .map(parseFloat);

        switch (commandType) {
            case 'M':
                lastPoint = { x: params[0], y: params[1] };
                break;
            case 'L':
                if (lastPoint) {
                    const endPoint = { x: params[0], y: params[1] };
                    segments.push({ start: lastPoint, end: endPoint });
                    lastPoint = endPoint;
                }
                break;
            case 'Q':
                if (lastPoint) {
                    const controlPoint = { x: params[0], y: params[1] };
                    const endPoint = { x: params[2], y: params[3] };
                    segments.push({ start: lastPoint, end: controlPoint });
                    segments.push({ start: controlPoint, end: endPoint });
                    lastPoint = endPoint;
                    lastControlPoint = controlPoint;
                }
                break;
            case 'C':
                if (lastPoint) {
                    const controlPoint1 = { x: params[0], y: params[1] };
                    const controlPoint2 = { x: params[2], y: params[3] };
                    const endPoint = { x: params[4], y: params[5] };
                    segments.push({ start: lastPoint, end: controlPoint1 });
                    segments.push({ start: controlPoint1, end: controlPoint2 });
                    segments.push({ start: controlPoint2, end: endPoint });
                    lastPoint = endPoint;
                    lastControlPoint = controlPoint2;
                }
                break;
            default:
                break;
        }
    }

    const gripPoints = calculatePoints(segments);

    return gripPoints;
}

function calculatePoints(segments: LineSegment[]): Point[] {
    const gripPoints: Point[] = [];

    for (const segment of segments) {
        const startPoint = {
            x: segment.start.x,
            y: segment.start.y,
        };
        const middlePoint = {
            x: (segment.start.x + segment.end.x) / 2,
            y: (segment.start.y + segment.end.y) / 2,
        };
        const endPoint = {
            x: segment.start.x + segment.end.x,
            y: segment.start.y + segment.end.y,
        };
        if (!gripPoints.includes(startPoint)) {
            gripPoints.push(startPoint);
        }
        gripPoints.push(middlePoint);

        if (!gripPoints.includes(endPoint)) {
            gripPoints.push(endPoint);
        }
    }

    return gripPoints;
}
