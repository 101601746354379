import { Injectable } from '@angular/core';
import { Utils } from '@helpers/utils';
import { OpsControlType, OpsRequestBase } from '@models/request-base';
import { ICreateHmiService } from './hmi.service';
import { OpsAmsConfigFileService } from './ops-ams-config-file.service';
import {
    ChannelControlDataDetail,
    ChannelControlDataFormat,
    ControlTargetSide,
} from '@models/channel-control';

@Injectable()
export class OpsRequestToFcuService {
    constructor(
        private hmiService: ICreateHmiService,
        private opsAmsConfigFileService: OpsAmsConfigFileService,
    ) {}

    public requestToFcu(msg: any, controlType: any) {
        const isValid = this.isValidControlValue(controlType, msg);
        if (isValid) {
            const sendData = this.makeRequestData(controlType, msg);
            if (!Utils.isNullOrUndefined(sendData)) {
                this.hmiService.requestToSendToFcu(sendData);
            }
        }
    }

    private isValidControlValue(controlType: any, value: any): boolean {
        switch (controlType) {
            case OpsControlType.ChannelMultiControlRequest:
                return this.checkChannelControlValue(value);
            default:
                break;
        }

        return false;
    }

    /*
        Check if value is in range and target channel is failure
    */
    private checkChannelControlValue(value: any): boolean {
        try {
            // get channel config
            const config = this.opsAmsConfigFileService.getChannelConfigByChNo(value.setData.sigId);
            if (Utils.isNullOrUndefined(config)) return false;

            // range check
            const rangeMaxStr = Utils.adjustDigit(
                config.detail.rangeSet.rangeMax,
                config.detail.rangeSet.displayFlag2,
            );
            const rangeMinStr = Utils.adjustDigit(
                config.detail.rangeSet.rangeMin,
                config.detail.rangeSet.displayFlag2,
            );

            const rangeMaxNum = Number(rangeMaxStr);
            const rangeMinNum = Number(rangeMinStr);

            const res =
                Number(value.setData.value) >= rangeMinNum &&
                Number(value.setData.value) <= rangeMaxNum;

            // channel fail check (not yet)

            return res;
        } catch (e) {
            return false;
        }
    }

    private makeRequestData(controlType: any, value: any): OpsRequestBase {
        try {
            const base = new OpsRequestBase();
            base.command = controlType;
            switch (controlType) {
                case OpsControlType.ChannelMultiControlRequest:
                    return this.makeChannelControlData(base, value);
                default:
                    return;
            }
        } catch {
            return;
        }
    }

    private makeChannelControlData(base: OpsRequestBase, value: any): OpsRequestBase {
        const config = this.opsAmsConfigFileService.getChannelConfigByChNo(value.setData.sigId);
        if (Utils.isNullOrUndefined(config)) return;

        const dataFrame = new ChannelControlDataFormat();
        const detail = new ChannelControlDataDetail();

        detail.channelId = config.chid;
        detail.targetSide = ControlTargetSide.Input;
        detail.value = Utils.convertToInteger(
            Number(value.setData.value),
            config.detail.rangeSet.displayFlag2,
        );

        dataFrame.controlDetails.push(detail);
        dataFrame.controlNum = dataFrame.controlDetails.length;

        const buffer = dataFrame.convertToBinary();
        base.request = buffer;

        return base;
    }
}
