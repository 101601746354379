import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationError, getValidationErrors } from 'common/validators';

@Component({
    selector: 'app-app-settings-error',
    templateUrl: './app-settings-error.component.html',
    styleUrls: ['./app-settings-error.component.scss'],
})
export class AppSettingsErrorComponent {
    @Input() validationErrors: { [propertyName: string]: ValidationError };
    @Input() propertyName: string;
    @Input() settingIsUpdated: boolean;

    constructor(private translateService: TranslateService) {}

    hasValidationErrorString = (propName: string) => {
        return propName in this.validationErrors;
    };

    getErrorString = (propName: string) => {
        if (!this.hasValidationErrorString(propName)) return '';

        const err = this.validationErrors[propName];
        return this.translateService.instant(err.errors[0], err.params);
    };
}
