import { ObjectSchema, object, string, number } from 'yup';
import { ReposeSetting } from '../../models/repose-setting';
import { locale } from './../yup-locale';
import * as yup from 'yup';
yup.setLocale(locale);

export function ReposeSettingValidator(): ObjectSchema<ReposeSetting> {
    return object({
        id: string().uuid().strict(),
        title: string().nonNullable().strict().required(),
        projectId: string().uuid().nonNullable().strict().required(),
        trigger: number().nonNullable().strict().required(),
        mask: number().nonNullable().strict().required(),
        delayOn: number().min(1).nonNullable().strict().required(),
        delayOff: number().min(1).nonNullable().strict().required(),
    }).required().strict().noUnknown().nonNullable() as ObjectSchema<ReposeSetting>;
}