import { AfterViewInit, EventEmitter, Injectable } from '@angular/core';
import { CanvasService } from './canvas.service';
import { SelectedElementsService } from './selected-elements.service';
import { IcreateWindowReferenceService } from './icreate-window-reference.service';

@Injectable()
export class ZoomService {
    private _onZoom = new EventEmitter<number>();

    constructor(
        private canvas: CanvasService,
        private select: SelectedElementsService,
        private windowElements: IcreateWindowReferenceService,
    ) {
        this.emitZoom = this.emitZoom.bind(this);
    }

    init(): void {
        this.canvas.getCanvas().bind('zoomed', this.emitZoom);
        this.setZoom(this.canvas.getCanvas().getZoom()); //Required to init sizes of components
    }

    onZoom() {
        return this._onZoom;
    }

    private emitZoom(zoom: number) {
        this.canvas.getCanvas().runExtensions('zoomChanged', zoom);
        this._onZoom.emit(zoom);
    }

    setZoom(zoom: number, centerPoint?: { x: number; y: number }) {
        this.canvas.getCanvas().setZoom(zoom);

        if (centerPoint) {
            this.updateCanvas(centerPoint);
        } else {
            this.updateCanvas();
        }
    }

    getZoom(): number {
        return this.canvas.getCanvas().getZoom();
    }

    //taken from svgedit Editor.js
    updateCanvas = (newCtr?) => {
        const zoom = this.getZoom();
        const canvas = this.canvas.getCanvas();
        const svgRoot = canvas.getSvgRoot();
        const workArea = this.windowElements.getWorkArea();

        let w = parseFloat(getComputedStyle(workArea.nativeElement, null).width.replace('px', ''));
        let h = parseFloat(getComputedStyle(workArea.nativeElement, null).height.replace('px', ''));
        const wOrig = w;
        const hOrig = h;
        const oldCtr = {
            x: workArea.nativeElement.scrollLeft + wOrig / 2,
            y: workArea.nativeElement.scrollTop + hOrig / 2,
        };
        const multi = 2; //this value controls how far off the canvas we can scroll
        w = Math.max(wOrig, canvas.contentW * zoom * multi);
        h = Math.max(hOrig, canvas.contentH * zoom * multi);

        workArea.nativeElement.style.overflow = 'scroll';

        const oldCanY = parseFloat(getComputedStyle(svgRoot, null).height.replace('px', '')) / 2;
        const oldCanX = parseFloat(getComputedStyle(svgRoot, null).width.replace('px', '')) / 2;

        svgRoot.style.width = w + 'px';
        svgRoot.style.height = h + 'px';

        const newCanY = h / 2;
        const newCanX = w / 2;

        let oldMPos;
        let newMPos;

        //save canvas-space mouse point pre zoom
        if (newCtr) oldMPos = this.canvas.toCanvasCoordinates(newCtr.x, newCtr.y);

        //actually do the zoom
        const offset = canvas.updateCanvas(w, h);
        //save canvas space mouse point post zoom
        if (newCtr) newMPos = this.canvas.toCanvasCoordinates(newCtr.x, newCtr.y);

        const ratio = newCanX / oldCanX;

        const scrollX = w / 2 - wOrig / 2;
        const scrollY = h / 2 - hOrig / 2;

        if (!newCtr) {
            const oldDistX = oldCtr.x - oldCanX;
            const newX = newCanX + oldDistX * ratio;

            const oldDistY = oldCtr.y - oldCanY;
            const newY = newCanY + oldDistY * ratio;

            newCtr = {
                x: newX,
                y: newY,
            };
        } else {
            newCtr.x += offset.x;
            newCtr.y += offset.y;
        }

        if (newMPos) {
            const sctm = this.canvas.getSCTM().inverse();
            //transform the difference between pre and post zoom mouse positions into screen space, then add that to the scroll
            workArea.nativeElement.scrollLeft += (newMPos.x - oldMPos.x) * sctm.a * -1;
            workArea.nativeElement.scrollTop += (newMPos.y - oldMPos.y) * sctm.d * -1;
        } else {
            workArea.nativeElement.scrollLeft = newCtr.x - wOrig / 2;
            workArea.nativeElement.scrollTop = newCtr.y - hOrig / 2;
        }

        this.emitZoom(zoom);
        this.select.getSelectedElements().forEach((elem) => {
            if (!elem) {
                return;
            }
            canvas.selectorManager.requestSelector(elem).resize();
        });

        canvas.pathActions.zoomChange();
    };
}
