import { Router, RouterModule, Routes, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { EditorComponent } from '@components/editor/editor.component';
import { ICreateLoginComponent } from '@components/i-create-login/i-create-login.component';
import { VerifyComponent } from '@components/verify/verify.component';
import { AuthGuard } from './auth.guard';
import { DeviceComponent } from '@components/device/device.component';
import { LabComponent } from '@components/lab/lab.component';
import { FuSettingsComponent } from '@components/fu-settings/fu-settings.component';
import { RunHourSettingsComponent } from '@components/run-hour-settings/run-hour-settings.component';
import { ReposeSettingsComponent } from '@components/repose-settings/repose-settings.component';
import { GraphSettingsComponent } from '@components/graph-settings/graph-settings.component';
import { ProjectSelectorPageComponent } from '@components/project-selector-page/project-selector-page.component';
import { LocalStorageService } from '@service/local-storage.service';
import { ApproveProjectPageComponent } from '@components/approve-project-page/approve-project-page.component';
import { ChannelComponent } from '@components/channel/channel.component';

@Injectable({
    providedIn: 'root',
})
export class EditorResolver implements Resolve<void> {
    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
    ) {}
    resolve(): any {
        const currentProjectId = this.localStorageService.getCurrentProjectId();
        if (currentProjectId) {
            return this.router.navigate(['/editor', currentProjectId]);
        } else {
            return;
        }
    }
}

export const appRoutes: Routes = [
    { path: 'iCreateLogin', component: ICreateLoginComponent },
    {
        path: 'approve',
        canActivate: [AuthGuard],
        children: [
            {
                path: '**',
                component: ApproveProjectPageComponent,
            },
        ],
    },
    {
        path: 'editor',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: ProjectSelectorPageComponent,
                resolve: {
                    project: EditorResolver,
                },
            },
            {
                path: ':projectId',
                component: EditorComponent,
            },
            {
                path: ':projectId/fu-settings',
                component: FuSettingsComponent,
            },
            {
                path: ':projectId/run-hour-settings',
                component: RunHourSettingsComponent,
            },
            {
                path: ':projectId/repose-settings',
                component: ReposeSettingsComponent,
            },
            {
                path: ':projectId/graph-settings',
                component: GraphSettingsComponent,
            },
        ],
    },
    {
        path: 'lab',
        canActivate: [AuthGuard],
        children: [{ path: ':projectId', component: LabComponent, canActivate: [AuthGuard] }],
    },
    { path: 'view', component: DeviceComponent, canActivate: [AuthGuard] },
    { path: 'verify', component: VerifyComponent, canActivate: [AuthGuard] },
    {
        path: 'channels',
        canActivate: [AuthGuard],
        children: [{ path: ':projectId', component: ChannelComponent, canActivate: [AuthGuard] }],
    },
    {
        path: 'device',
        canActivate: [AuthGuard],
        children: [
            {
                path: ':projectId',
                component: DeviceComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
    { path: '', component: VerifyComponent },
    { path: '**', redirectTo: '/' },
];

export const routing = RouterModule.forRoot(appRoutes, {});
