import { BlobOperationParam, ChannelMerged } from './../../models/blob-operations';
import { ObjectSchema, boolean, mixed, number, object, string } from 'yup';
import { locale } from './../yup-locale';
import * as yup from 'yup';
yup.setLocale(locale);

export const blobOperationParamValidator: ObjectSchema<BlobOperationParam> = object({
    builderId: string().required().nonNullable().strict(),
    vesselId: string().required().nonNullable().strict(),
    projectId: mixed().required().nonNullable(),

    channelMerged: string().oneOf([ChannelMerged.Merged, ChannelMerged.NotMerged]).nonNullable(),
})
    .strict()
    .noUnknown();
