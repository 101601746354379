import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';
import { ChannelCategory } from 'common/models/channel-category';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChannelCategoryService {
    private domain: string;

    addNewCateogry = new EventEmitter<ChannelCategory>();

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    public getChannelCategories(projectId: string): Promise<ChannelCategory[]> {
        return lastValueFrom(
            this.http.get<ChannelCategory[]>(`${this.domain}/api/channel-categories`, {
                params: {
                    projectId,
                },
            }),
        );
    }

    public addChannelCategory(channelCategory: ChannelCategory): Promise<ChannelCategory> {
        return lastValueFrom(
            this.http.post<ChannelCategory>(`${this.domain}/api/channel-category`, {
                channelCategory: channelCategory,
            }),
        );
    }

    public updateChannelCategory(channelCategory: ChannelCategory): Promise<any> {
        return lastValueFrom(
            this.http.put<any>(`${this.domain}/api/channel-category`, {
                params: channelCategory,
            }),
        );
    }

    public deleteChannelCategory(channelCategoryId: string): Promise<any> {
        return lastValueFrom(
            this.http.delete(`${this.domain}/api/channel-category/${channelCategoryId}`),
        );
    }
}
