import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MimicDefsService {
    private _alarmGradientId = 'alarmGradient';
    private _alarmColorId = 'alarmColor';

    private _cancelGradientId = 'cancelGradient';
    private _cancelColorId = 'cancelColor';

    private _unackGradientId = 'unackGradient';
    private _unackColorId = 'unackColor';

    public customGradientCount = 0;

    public generateDefs(): string {
        const customGradients = Array(this.customGradientCount);
        for (let i = 0; i < this.customGradientCount; i++) {
            customGradients[i] = `
            <linearGradient id="${this.generateCustomGradientId(i)}">
                <stop id="${this.generateCustomColorId(i)}" stop-color="rgb(255,0,0)" />
            </linearGradient>
            `;
        }

        return `
            <linearGradient id="${this.alarmGradientId}">
                <stop id="${this.alarmColorId}" stop-color="#e20000" />
            </linearGradient>
            <linearGradient id="${this.cancelGradientId}">
                <stop id="${this.cancelColorId}" stop-color="#ffff00" />
            </linearGradient>
            <linearGradient id="${this.unackGradientId}">
                <stop id="${this.unackColorId}" stop-color="#039b05" />
            </linearGradient>
            ${customGradients.join('\n')}
        `;
    }

    private generateCustomGradientId(index: number) {
        return `mimic-custom-generated-gradient-${index}`;
    }

    private generateCustomColorId(index: number) {
        return `mimic-custom-generated-color-${index}`;
    }

    public getCustomGradientId(index: number) {
        return this.generateCustomGradientId(index);
    }

    public getCustomColorId(index: number) {
        return this.generateCustomColorId(index);
    }

    public get alarmGradientId() {
        return this._alarmGradientId;
    }
    public get alarmColorId() {
        return this._alarmColorId;
    }

    public get cancelGradientId() {
        return this._cancelGradientId;
    }
    public get cancelColorId() {
        return this._cancelColorId;
    }

    public get unackGradientId() {
        return this._unackGradientId;
    }
    public get unackColorId() {
        return this._unackColorId;
    }
}
