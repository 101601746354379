<div class="top-container">
    <!-- Channel list -->
    <div class="table-wrapper" (click)="unselectRow()">
        <table mat-table [dataSource]="tableDataSource" class="table">
            <ng-container matColumnDef="number">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="table-header index-header"
                ></th>
                <td
                    mat-cell
                    *matCellDef="let channel; let i = index"
                    class="table-row index"
                >
                    {{ i + 1 }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Ch. No.">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="table-header fixed-column"
                >
                    {{
                        "graph-settings.gauge-graph.table.header.channel"
                            | translate
                    }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let channel"
                    class="table-row fixed-column"
                >
                    <app-search-channel-input
                        [channels]="channels"
                        [channelNumber]="channel.fcuChannelId"
                        (onChannelSelected)="channel.fcuChannelId = $event"
                        [channelError]="getChannelErrors(channel, 'fcuChannelId')"
                    ></app-search-channel-input>
                </td>
            </ng-container>

            <ng-container matColumnDef="divisions">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="table-header fixed-column"
                >
                    {{
                        "graph-settings.gauge-graph.table.header.divisions"
                            | translate
                    }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let channel"
                    class="table-row fixed-column"
                >
                    <input
                        type="number"
                        max="10"
                        min="1"
                        [value]="channel.divisions"
                        (input)="
                            channel.divisions = inputLimit(
                                $event,
                                channel.divisions
                            )
                        "
                    />
                    <div
                        class="error-container text light"
                        *ngIf="getChannelErrors(channel, 'divisions')"
                    >
                        <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                        <span data-testid="errorSetPoint">
                            {{
                                getChannelErrors(channel, "divisions")
                                    | translate
                            }}</span
                        >
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="range">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="table-header fixed-column"
                >
                    {{
                        "graph-settings.gauge-graph.table.header.range"
                            | translate
                    }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let channel"
                    class="table-row fixed-column"
                >
                    <div class="channel-range">
                        {{
                            "graph-settings.gauge-graph.table.header.range.max"
                                | translate
                        }}
                        {{ getChannelRange(channel.fcuChannelId)?.max }}
                    </div>
                    <div class="channel-range">
                        {{
                            "graph-settings.gauge-graph.table.header.range.min"
                                | translate
                        }}
                        {{ getChannelRange(channel.fcuChannelId)?.min }}
                    </div>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
                class="table-header"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                (click)="selectRow(row); $event.stopPropagation()"
                [ngStyle]="{
                    'background-color':
                        selectedRow === row ? 'rgba(48, 89, 175, 1)' : ''
                }"
            ></tr>
        </table>
    </div>
</div>

<!-- Buttons Container -->
<div class="buttons-container">
    <button [disabled]="!canAddChannel()" class="add" (click)="addChannel()">
        {{ "graph-settings.gauge-graph.button.add" | translate }}
    </button>
    <button [disabled]="!selectedRow" class="delete" (click)="deleteChannel()">
        {{ "graph-settings.gauge-graph.button.delete" | translate }}
    </button>
    <button
        class="save"
        [disabled]="saveButtonIsDisabled()"
        (click)="saveGraph()"
    >
        {{ "graph-settings.gauge-graph.button.save" | translate }}
    </button>
    <button class="exit" (click)="exit()">
        {{ "graph-settings.gauge-graph.button.exit" | translate }}
    </button>
</div>

<app-confirmation-window
    #confirmationWindow
    [mainLabel]="'graph-settings.graph.close.confirmation.title' | translate"
    [confirmLabel]="
        'graph-settings.graph.close.confirmation.confirm' | translate
    "
    [cancelLabel]="'graph-settings.graph.close.confirmation.cancel' | translate"
></app-confirmation-window>
