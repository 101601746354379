import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-lab-header',
    templateUrl: './lab-header.component.html',
    styleUrls: ['./lab-header.component.css'],
})
export class LabHeaderComponent {
    localtime: string;
    utctime: string;

    public statusPannel = {
        statusAlarm: true,
        statusMaintenance: true,
        statusSwitch: true,
        statusSound: false,
    };

    statusMaintenance = true;
    statusAlarm = true;

    constructor(public translate: TranslateService) {
        console.log(translate.currentLang);
    }

    ngOnInit(): void {
        this.setTime();
        setInterval(() => {
            this.setTime();
        }, 1000);
    }

    setTime() {
        const d = new Date();
        const utc = d
            .toISOString()
            .substring(d.toISOString().indexOf('T') + 1, d.toISOString().indexOf('T') + 6);
        this.utctime = String(utc);
        this.localtime = String(
            d.getHours().toString().padStart(2, '0') +
                ':' +
                d.getMinutes().toString().padStart(2, '0'),
        );
    }
}
