export enum IoEventTypes {
    DEVICE_LOAD = 'device-load',
    DEVICE_STATUS = 'device-status',
    DEVICE_VALUES = 'device-values',
    ALARMS_STATUS = 'alarms-status',
    AMS_CONFIG_FILE_UPDATE = 'ams-config-file-update',
    PROJECT_UPDATED = 'project-updated',
    TAG_VALUES = 'tag-values',
    AMS_REQUEST_SEND = 'ams-request-send',
}

export enum GraphEvents {
    GRAPH_LOCKED = 'graph-locked',
    GRAPH_UNLOCKED = 'graph-unlocked',
    GRAPH_DELETED = 'graph-deleted',
    GRAPH_CREATED = 'graph-created',
    GRAPH_UPDATED = 'graph-updated'
}   

export enum ReposeSettingsEvents {
    CREATE_SETTING = 'setting-created',
    DELETE_SETTING = 'setting-deleted',
    UPDATE_SETTING = 'setting-updated'
}

export enum RunHourSettingEvents {
    DELETE_SETTING = 'run-hour-setting-deleted',
    CREATE_SETTING = 'run-hour-setting-created',
    UPDATE_SETTING = 'run-hour-setting-updated',
}

export enum FuSettingsEvent {
    FCU_DELETED = 'fcu-page-deleted',
    FCU_CREATED = 'fcu-page-created',
    FCU_LOCKED = 'fcu-page-locked',
    FCU_UNLOCKED = 'fcu-page-unlocked'
} 