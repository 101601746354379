import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class Utils {
    static defaultColor = [
        '#FFFFFF',
        '#000000',
        '#EEECE1',
        '#1F497D',
        '#4F81BD',
        '#C0504D',
        '#9BBB59',
        '#8064A2',
        '#4BACC6',
        '#F79646',
        '#C00000',
        '#FF0000',
        '#FFC000',
        '#FFD04A',
        '#FFFF00',
        '#92D050',
        '#0AC97D',
        '#00B050',
        '#00B0F0',
        '#4484EF',
        '#3358C0',
        '#002060',
        '#7030A0',
        '#D8D8D8',
        '#BFBFBF',
        '#A5A5A5',
        '#7F7F7F',
        '#595959',
        '#3F3F3F',
        '#262626',
    ];

    static lineColor = [
        '#4484ef',
        '#ef0909',
        '#00b050',
        '#ffd04a',
        '#7030a0',
        '#a5a5a5',
        '#c0504d',
        '#000000',
    ];

    static searchTreeStartWith(element: any, matchingStart: any): any {
        if (element.id.startsWith(matchingStart)) {
            return element;
        } else if (element.children != null) {
            let i;
            let result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = Utils.searchTreeStartWith(element.children[i], matchingStart);
            }
            return result;
        }
        return null;
    }

    static isNullOrUndefined(ele: any) {
        return ele === null || ele === undefined ? true : false;
    }

    static adjustDigit(value: number, decimalDigit: number): string {
        let convertedValue: string = String(value);
        const isNegativeValue: boolean = value < 0;

        if (isNegativeValue) {
            convertedValue = String(value * -1);
        }

        if (!this.isNullOrUndefined(decimalDigit) && decimalDigit > 0) {
            if (convertedValue.length == decimalDigit) {
                convertedValue = '0.' + convertedValue;
            } else if (convertedValue.length < decimalDigit) {
                const d = this.zeroPadding(value, decimalDigit);
                convertedValue = '0.' + d;
            } else {
                const insertIdx = convertedValue.length - decimalDigit;
                convertedValue =
                    convertedValue.slice(0, insertIdx) + '.' + convertedValue.slice(insertIdx);
            }
        }

        if (isNegativeValue) {
            convertedValue = '-' + convertedValue;
        }

        return convertedValue;
    }

    static zeroPadding(data: number, digit: number): string {
        return (Array(digit).join('0') + data).slice(-digit);
    }

    static convertToPercentage(value: number, config: any): number {
        if (config.detail) {
            if (config.detail.rangeSet) {
                const max = config.detail.rangeSet.rangeMax;
                const min = config.detail.rangeSet.rangeMin;
                const scale = Math.abs(max) + Math.abs(min);
                const d = value - min;
                const percent = d / scale;
                return percent * 100;
            }
        }
        return value;
    }

    static convertToInteger(value: number, magnification: number): number {
        return value * Math.pow(10, magnification);
    }

    // returns keys of enum
    //   static enumKeys(p: any): Array<string> {
    //     const keys = Object.keys(p);
    //     return keys;
    //   }

    // returns values of enum
    //   static enumValues(p: any): Array<string> {
    //     const keys = Object.keys(p);
    //     return keys.map((el) => Object(p)[el]);
    //   }

    static getGUID(prefix: string = ''): string {
        let uuid = '',
            i,
            random;
        for (i = 0; i < 16; i++) {
            random = (Math.random() * 16) | 0;
            if (i == 8) {
                uuid += '-';
            }
            uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
        }
        return prefix + uuid;
    }

    //   static getShortGUID(prefix: string = ''): string {
    //     let uuid = '',
    //       i,
    //       random;
    //     for (i = 0; i < 12; i++) {
    //       random = (Math.random() * 16) | 0;
    //       if (i == 8) {
    //         uuid += '-';
    //       }
    //       uuid += (i == 4 ? 4 : i == 6 ? (random & 3) | 8 : random).toString(12);
    //     }
    //     return prefix + uuid;
    //   }

    static getNextName(prefix: string, inuse: string[]) {
        let index = 1;
        let result = prefix + index;
        while (inuse.indexOf(result) >= 0) {
            index++;
            result = prefix + index;
        }
        return result;
    }

    static isObject(value: any) {
        return typeof value == 'object' && value !== null;
    }

    static getType(value: any) {
        return typeof value;
    }

    //   static getTextHeight(font: string) {
    //     // re-use canvas object for better performance
    //     const canvas = document.createElement('canvas');
    //     const context = canvas.getContext('2d')!;
    //     context.font = font;
    //     const metrics = context.measureText('M');
    //     return metrics.width;
    //   }

    //   static getDomTextHeight(size: number, font: string) {
    //     const text = document.createElement('span');
    //     document.body.appendChild(text);
    //     text.style.font = font;
    //     text.style.fontSize = size + 'px';
    //     text.style.height = 'auto';
    //     text.style.width = 'auto';
    //     text.style.position = 'absolute';
    //     text.style.whiteSpace = 'no-wrap';
    //     text.innerHTML = 'M';

    //     const height = Math.ceil(text.clientHeight);
    //     document.body.removeChild(text);
    //     return height;
    //   }

    static getEnumKey(etype: any, ekey: any) {
        return Object.keys(etype).find((key) => etype[key] === ekey);
    }

    static isJson(item: string) {
        try {
            const obj = JSON.parse(item);
            if (obj && Object.keys(obj).length) {
                return true;
            }
        } catch (e) {}
        return false;
    }

    static Boolify(arg: any) {
        const BoolArray = [true, false, 'true', 'false', 1, 0];
        if (BoolArray.indexOf(arg) === -1) {
            return null;
        } else {
            return arg == true || arg == 'true' || arg == 1 ? true : false;
        }
    }
}

// @Pipe({
//   name: 'enumToArray',
// })
// export class EnumToArrayPipe implements PipeTransform {
//   transform(value, args: string[]): any {
//     const result = [];
//     const keys = Object.keys(value);
//     const values = Object.values(value);
//     for (let i = 0; i < keys.length; i++) {
//       result.push({ key: keys[i], value: values[i] });
//     }
//     return result;
//     //or if you want to order the result:
//     //return result.sort((a, b) => a.value < b.value ? -1 : 1);
//   }
// }

// @Pipe({ name: 'keepHtml', pure: false })
// export class EscapeHtmlPipe implements PipeTransform {
//   constructor(private sanitizer: DomSanitizer) {}

//   transform(content) {
//     return this.sanitizer.bypassSecurityTrustHtml(content);
//   }
// }

// @Pipe({ name: 'objectToRowPipe' })
// export class ObjectToRowPipe implements PipeTransform {
//   constructor() {}

//   transform(blob, local, flattenKey = '') {
//     if (!blob || local === null || !blob || local === null) {
//       return [];
//     }

//     const allKeys = Object.keys(local);

//     const rows: {
//       key: string;
//       flattenKey: string;
//       local: any;
//       blob: any;
//     }[] = [];

//     allKeys.forEach((key) => {
//       const newFlattenKey = flattenKey ? flattenKey.concat('.', key) : key;

//       if (typeof local[key] === 'object' && typeof blob[key] === 'object') {
//         rows.push({
//           key: key,
//           flattenKey: newFlattenKey,
//           local: null,
//           blob: null,
//         });
//         rows.push(...this.transform(blob[key], local[key], newFlattenKey));
//       } else {
//         rows.push({
//           key: key,
//           flattenKey: newFlattenKey,
//           local: local[key],
//           blob: blob[key],
//         });
//       }
//     });

//     return rows;
//   }
// }
