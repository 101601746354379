import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { lastValueFrom } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { LoadingSpinnerComponent } from '@layout/loading-spinner/loading-spinner.component';
import { IapiService } from './iapi.service';
import { SocketService } from './socket.service';
import { VesselBuilderSelectListComponent } from '@components//vessel-builder-select-list/vessel-builder-select-list.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjectService } from './project.service';
import { EndPointApi } from '@helpers/endpointapi';

@Injectable()
export class VesselBuilderSelectService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });
    overlayRef = this.overlay.create({
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });

    constructor(
        public dialog: MatDialog,
        private iApi: IapiService,
        private overlay: Overlay,
        private toastr: ToastrService,
        private projectService: ProjectService,
        private http: HttpClient,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    saveBuilderData(builderData: IVesselBuildData, projectId: string): Promise<any> {
        const requestBody = {
            projectId: projectId,
            builderData: builderData,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/company/builderData`, requestBody, {
                headers: this.headers,
            }),
        );
    }

    async openBuilderSelectDialog() {
        this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));
        try {
            const list = await this.iApi.getCompanyList();
            this.iApi.setCache(list);
            const builderList = this.iApi.getBuilderList(list);

            this.overlayRef.detach();

            builderList.sort(this.iApi.compareForSort);

            const dialogRef = this.dialog.open(VesselBuilderSelectListComponent, {
                data: builderList,
            });

            const result = await lastValueFrom(dialogRef.afterClosed());

            return result;
        } catch (e) {
            this.notifyError(e);
            this.overlayRef.detach();
            return;
        }
    }

    public notifyError(error) {
        console.error(error);

        this.toastr.error(error.message, '', {
            timeOut: 3000,
            closeButton: true,
            disableTimeOut: true,
        });
    }
}

interface IVesselBuildData {
    id: string;
    name: string;
}
