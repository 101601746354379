import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-channel-compare-header',
    templateUrl: './channel-compare-header.component.html',
    styleUrls: ['./channel-compare-header.component.css'],
})
export class ChannelCompareHeaderComponent {
    @Input() selectedVersion: string;
    @Input() channelCompareCount = {
        add: 0,
        update: 0,
        delete: 0,
    };

    constructor() {}
}
