<app-new-comment
    id="new-comment"
    [ngStyle]="{
        top: positionBackground.top,
        left: positionBackground.left
    }"
    *ngIf="creatingComment"
    (onSubmitComment)="saveComment($event)"
    [newCommentPosition]="newCommentPosition"
    [scale]="positionBackground.scale"
></app-new-comment>
<div
    *ngFor="let comment of comments"
    id="comments-container"
    [ngStyle]="{
        top: positionBackground.top,
        left: positionBackground.left,
        transform: 'scale(' + positionBackground.scale + ')'
    }"
>
    <app-posted-comment
        [scale]="positionBackground.scale"
        [comment]="comment"
        [movingComment]="movingComment"
        (deleteCommentEmit)="deleteComment($event)"
        (updateCommentEmit)="updateComment($event)"
        (editingCommentEmit)="writingComment($event)"
        (onMovingElement)="movingElement($event)"
    ></app-posted-comment>
</div>
