import * as uuid from 'uuid';

export class OpsUser {
    id?: string;
    projectId: string;
    password: string;
    name: string;
    isAdmin: boolean;
    permissions: OpsUserPermissions;

    constructor(projectId, name?, isAdmin = false) {
        let userId = uuid.v4();
        this.id = userId;
        this.projectId = projectId;
        this.password = '';
        this.name = name ? name : '';
        this.isAdmin = isAdmin;
        this.permissions = new OpsUserPermissions(userId, isAdmin);
    }
}

export class OpsUserPermissions {
    id?: string;
    userId: string;
    channelSettings: boolean;
    constructor(userId, isAdmin = false) {
        this.userId = userId;
        this.channelSettings = isAdmin;
    }
}

export const MAX_OPS_USERS = 6;
export const MIN_OPS_USERS = 2;
export const DEFAULT_USERS = [
    'Admin',
    'Chief engineer',
    '1st engineer',
    '2nd engineer',
    '3rd engineer',
];
