import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from '@helpers/utils';
import { ChannelService } from '@service/channel.service';
import { ProjectService } from '@service/project.service';

import SvgCanvas from '@svgeditor/svgcanvas.js';
import { Channel } from 'common/models/channel';
import { lastValueFrom } from 'rxjs';

interface AlarmOptions {
    displayName: string;
    checked: boolean;
    SubOptions?: AlarmOptions[];
}

export class colorRange {
    constructor(
        public id: String,
        public min: number,
        public max: number,
        public color: String,
    ) {}
}

@Component({
    selector: 'flex-variable',
    templateUrl: './flex-variable.component.html',
    styleUrls: ['./flex-variable.component.css'],
})
export class FlexVariableComponent implements OnInit, OnDestroy {
    @Input() data: any;
    @Input() variableId;
    @Input() value: any;
    @Input() allowManualEdit: boolean = false;
    @Input() variableValue: string;
    @Input() variableLabel: string = 'gauges.property-variable-value';
    @Input() withStaticValue: boolean = true;
    @Input() tagLabel: string = 'gauges.property-tag-label';
    @Input() tagTitle: string = '';

    @Output() onchange: EventEmitter<any> = new EventEmitter();
    @Output() valueChange: EventEmitter<any> = new EventEmitter();

    public settingName;
    public channelName;
    public channelType;
    public itemSelected;
    public svgCanvas: SvgCanvas;
    public channels: Channel[];

    public manualEdit: boolean = false;

    colorRange: Boolean = false;

    public alarmOptions: AlarmOptions = {
        displayName: 'All',
        checked: false,
        SubOptions: [
            { displayName: 'highHigh', checked: false },
            { displayName: 'high', checked: false },
            { displayName: 'low', checked: false },
            { displayName: 'lowLow', checked: false },
            { displayName: 'sensorFail', checked: false },
            { displayName: 'feedback', checked: false },
        ],
    };

    private alarmFlags = {
        highHigh: false,
        high: false,
        lowLow: false,
        low: false,
        over: false,
        under: false,
        sensorFail: false,
        feedback: false,
    };

    public allChecked: boolean = false;
    public colorRangeInstances = [];

    public rangeSetting;

    constructor(
        public dialog: MatDialog,
        private channelService: ChannelService,
        private projectService: ProjectService,
    ) {}

    ngOnDestroy(): void {
        this.saveTag();
    }

    async ngOnInit() {
        this.getItem();
        this.checkCheckedSettings();
        const projectId = this.projectService.getProjectId();
        this.channels = await lastValueFrom(this.channelService.getChannels(projectId));
        if (this.data.settings.property.ranges) {
            this.colorRangeInstances = this.data.settings.property.ranges;
        }
        if (this.data.canvas) {
            this.svgCanvas = this.data.canvas;
        }
        if (this.variableId) {
            this.handleChannelSelected(this.variableId)
        }
        if (!this.value) {
            this.value = {
                variableId: this.variableId,
            };
        } else if (this.value.variableId) {
            this.variableId = this.value.variableId;
        }
    }

    saveTag() {
        if (!this.value) {
            return;
        }
        let tag;
        for (let i = 0; i < this.data.ams[1].device.length; i++) {
            if (this.data.ams[1].device[i].tags[this.variableId]) {
                tag = this.data.ams[1].device[i].tags[this.variableId];
                this.createChannelTextBox();
            }
        }
        if (tag) {
            this.value.variableId = tag.id;
            this.value.variableValue = tag.value;
            this.value.variableRaw = tag;
        }
        this.value.alarmThreshold = this.alarmFlags;
        if (this.colorRangeInstances.length > 0) {
            this.value.ranges = this.colorRangeInstances;
        } else {
            this.value.ranges = [];
        }
        for (let i = 0; i < this.alarmOptions.SubOptions.length; i++) {
            if (this.alarmOptions.SubOptions[i].checked) {
                if (this.alarmOptions.SubOptions[i].displayName === 'sensorFail') {
                    this.value.alarmThreshold.over = true;
                    this.value.alarmThreshold.under = true;
                } else {
                    this.value.alarmThreshold[this.alarmOptions.SubOptions[i].displayName] = true;
                }
            }
        }
        this.onchange.emit(this.value); // Legacy
        this.valueChange.emit(this.value);
    }

    updateAllComplete() {
        this.allChecked =
            this.alarmOptions.SubOptions != null &&
            this.alarmOptions.SubOptions.every((t) => t.checked);
    }

    setAll(checked: boolean) {
        this.allChecked = checked;
        if (this.alarmOptions.SubOptions == null) {
            return;
        }
        this.alarmOptions.SubOptions.forEach((t) => (t.checked = checked));
    }

    onAddColorChange() {
        this.rangeSetting = new colorRange(Utils.getGUID('r_'), 0, 0, '#4695e2');
        this.colorRangeInstances.push(this.rangeSetting);
    }

    checkCheckedSettings() {
        for (let i = 0; i < this.alarmOptions.SubOptions.length; i++) {
            if (this.data.settings.property?.editorProperties) {
                if (
                    this.data.settings.property?.editorProperties[
                        this.alarmOptions.SubOptions[i].displayName
                    ]
                ) {
                    this.alarmOptions.SubOptions[i].checked = true;
                } else if (
                    this.alarmOptions.SubOptions[i].displayName === 'sensorFail' &&
                    this.data.settings.property.editorProperties.over
                ) {
                    this.alarmOptions.SubOptions[i].checked = true;
                }
            }
        }
        this.updateAllComplete();
    }

    onDeleteRange(range) {
        for (let i = 0; i < this.colorRangeInstances.length; i++) {
            if (this.colorRangeInstances[i].id === range.id) {
                this.colorRangeInstances.splice(i, 1);
            }
        }
    }

    getItem() {
        let canvas = this.data.canvas.svgroot;
        let element = canvas.querySelector('#' + this.data.settings.id);
        if (element) {
            this.itemSelected = element;
        }
    }

    createChannelTextBox() {
        let canvas = this.data.canvas.svgroot;
        if (canvas.querySelector('#g-' + this.data.settings.id)) {
            canvas.querySelector('#Ch-label-' + this.data.settings.id).innerHTML = this.variableId;
        } else {
            if (this.itemSelected) {
                let x = this.itemSelected.getBBox().x + this.itemSelected.getBBox().width / 2 + 15;
                let y = this.itemSelected.getBBox().y + this.itemSelected.getBBox().height / 2 + 10;
                let newText = this.data.canvas.addSVGElementsFromJson({
                    element: 'text',
                    curStyles: true,
                    text: this.variableId,
                    attr: {
                        x,
                        y,
                        id: 'Ch-label-' + this.data.settings.id,
                        fill: this.svgCanvas.getCurText('fill'),
                        'stroke-width': this.svgCanvas.getCurText('stroke_width'),
                        'font-size': 10,
                        'font-family': 'Inter-Regular',
                        'text-anchor': 'middle',
                        'xml:space': 'preserve',
                        opacity: 100,
                        visibility: 'hidden',
                    },
                });
                this.data.canvas.selectedElements.unshift(newText);
                this.data.canvas.groupSelectedElements(
                    undefined,
                    undefined,
                    'g-' + this.data.settings.id,
                );
            }
        }
    }

    handleChannelSelected(channelNumber) {
        let channelFound: boolean = false;
        this.channels.map((channel) => {
            if (channel.fcuData.fcuChannelId === channelNumber) {
                channelFound = true;
                this.channelName = channel.opsData.name;
                this.channelType = channel.opsData.channelType;
                this.variableId = channelNumber;
            }
        });
        if (!channelFound) {
            this.channelName = null;
            this.channelType = null;
            this.variableId = null
        }
    }
}
