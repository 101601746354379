<div class="wrapper">
  <mat-dialog-content
    ><h1 mat-dialog-title style="display: inline-block; cursor: move">
      Select a project
    </h1></mat-dialog-content
  >
  <table>
    <tr>
      <th>Builder</th>
      <th>Vessel No</th>
      <th>Project Name</th>
      <th>Last Edited</th>
      <th>Created by</th>
      <th>Updated by</th>
    </tr>
  </table>
  <div class="withSideBar">
    <table>
      <tr *ngFor="let d of data" (click)="rowClick(d)">
        <td>{{ d.VesselBuilderCompanyName }}</td>
        <td>{{ d.VesselNumber }}</td>
        <td>{{ d.name }}</td>
        <td>{{ d.updatedAt }}</td>
        <td>{{ d.createdAt }}</td>
        <td>{{ d.updatedBy }}</td>
      </tr>
    </table>
  </div>
</div>
