import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash'

@Component({
    selector: 'app-graph-selector',
    templateUrl: './graph-selector.component.html',
    styleUrls: ['./graph-selector.component.scss'],
})
export class GraphSelectorComponent implements OnChanges{
    constructor() {}

    @Input() graphList: SelectGraph[];
    @Output() onGraphSelectedChanged: EventEmitter<SelectGraph[]> = new EventEmitter<SelectGraph[]>()

    showGraphs: boolean = false;
    graphsSelected: SelectGraph[] = [];
    printAll: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.graphList) {
            this.showGraphs = false
            this.printAll = false;
            this.graphsSelected = []
        }
    }

    checkedGraph(graph: SelectGraph, labelClicked) {
        if (graph.checked && labelClicked) {
            this.graphsSelected.push(graph);
        }
        if (!graph.checked && !labelClicked) {
            graph.checked = true;
            this.graphsSelected.push(graph);
        } else if (graph.checked && !labelClicked) {
            graph.checked = false;
            this.graphsSelected.splice(this.graphsSelected.indexOf(graph), 1);
        } else if (!graph.checked && labelClicked) {
            this.graphsSelected.splice(this.graphsSelected.indexOf(graph), 1);
        }
        this.printAll = this.allGraphsSelected()
        this.onGraphSelectedChanged.emit(this.graphsSelected)
    }

    unselectGraph(selectedGraph) {
        this.graphList.forEach((graph: SelectGraph) => {
            if (_.isEqual(graph , selectedGraph)) {
                graph.checked = false;
                this.graphsSelected.splice(this.graphsSelected.indexOf(selectedGraph), 1);
                this.onGraphSelectedChanged.emit(this.graphsSelected)
            }
        });
        this.printAll = this.allGraphsSelected()
    }

    selectAllGraphs() {
        if (!this.printAll) {
            this.graphList.map((graph: SelectGraph) => {
                if (!graph.checked) {
                    this.checkedGraph(graph, false);
                }
            });
        }  else {
        this.graphList.map((graph: SelectGraph) => {
            if (graph.checked) {
                this.unselectGraph(graph);
            }
        });
    }
        this.onGraphSelectedChanged.emit(this.graphsSelected)
    }

    allGraphsSelected(): boolean {
        const unselectedGraphs = this.graphList.filter((graph) => {
            return !graph.checked;
        });
        return unselectedGraphs.length === 0;
    }
}

export class SelectGraph {
    title: string;
    checked: boolean;
    id: string;
}
