import { Channel } from 'common/models/channel';
import { ExhaustChannel } from 'common/models/graphs';
import * as echarts from 'echarts';

export async function printDeviationGraph(graph, projectChannels: Channel[], graphDifferences) {
    const exhaustChannels = graph.EXHAUST_GAS.exhaustChannels;

    if (!exhaustChannels || exhaustChannels.length === 0) {
        return;
    }

    const channelsIdsSet: Set<number> = new Set();
    exhaustChannels.forEach((channel: ExhaustChannel) => {
        channelsIdsSet.add(channel.deviationChannel);
    });

    const averageChannelTag = getAverageChannelInfo(
        graph.EXHAUST_GAS.averageChannel,
        projectChannels,
    );

    const deviationLimit = graph.EXHAUST_GAS.deviationLimit;
    const graphChannels = getGraphChannels(channelsIdsSet, projectChannels);
    const { notBorderedLables, borderedLables } = prepareXAxislabel(
        exhaustChannels,
        graphDifferences?.channelsChanges?.deviationGraph,
    );
    const graphValuesArray = prepareGraphValuesArray(graphChannels, deviationLimit);
    const titleBorder = graphDifferences?.oldTitle
        ? [true, true, true, true]
        : [false, false, false, false];
    const averageChannelTagTextStyle = graphDifferences?.averageChannel
        ? {
              borderColor: 'red',
              borderWidth: 1,
              padding: [2, 2, 2, 2],
          }
        : {};

    let barGraphDivision = document.createElement('div');
    barGraphDivision.style.height = '490pt';
    barGraphDivision.style.width = '763pt';
    let myChart = echarts.init(barGraphDivision);
    let option;

    option = {
        color: ['black'],
        xAxis: [
            {
                type: 'category',
                data: notBorderedLables.data,
                position: 'bottom',
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    interval: 0,
                    rich: {
                        border: {
                            borderColor: 'red',
                            borderWidth: 1,
                            padding: [2, 2, 2, 2],
                        },
                    },
                },
            },
            {
                type: 'category',
                data: borderedLables.data,
                position: 'bottom',
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    customValues: borderedLables.customValues,
                    interval: 0,
                    textStyle: {
                        borderColor: 'red',
                        borderWidth: 1,
                        padding: [2, 2, 2, 2],
                    },
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: 'black',
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: 'black',
                    },
                },
                axisLabel: {
                    formatter: function (value) {
                        if (value === 0) {
                            return '';
                        }
                        return value;
                    },
                },
                max: Math.abs(graph.EXHAUST_GAS.deviationLimit),
                min: -Math.abs(graph.EXHAUST_GAS.deviationLimit),
                interval: Math.abs(graph.EXHAUST_GAS.deviationLimit) / 2,
            },
            {
                type: 'value',
                axisLabel: {
                    customValues: [0],

                    formatter: function (value) {
                        if (value === 0) {
                            return `AVE \n ***** \n (${averageChannelTag})`;
                        }
                        return value; // Example of simple formatter
                    },
                    textStyle: averageChannelTagTextStyle,
                },
                position: 'left',
                max: Math.abs(graph.EXHAUST_GAS.deviationLimit),
                min: -Math.abs(graph.EXHAUST_GAS.deviationLimit),
                interval: Math.abs(graph.EXHAUST_GAS.deviationLimit) / 2,
            },
        ],
        series: [
            {
                data: graphValuesArray,
                type: 'bar',
                emphasis: {
                    itemStyle: {
                        color: 'black',
                    },
                },
            },
        ],
    };

    myChart.setOption(option);

    await new Promise((resolve) => setTimeout(resolve, 500));

    const dataUrl = myChart.getDataURL({
        type: 'png',
        pixelRatio: 1,
        backgroundColor: '#fff',
    });

    return {
        body: [
            [
                [
                    {
                        table: {
                            margin: [10, 10],
                            body: [
                                [
                                    {
                                        text: graph.title,
                                        border: titleBorder,
                                        borderColor: ['red', 'red', 'red', 'red'],
                                    },
                                ],
                            ],
                        },
                        margin: [10, 10],
                    },
                    {
                        columns: [
                            {
                                width: 'auto',
                                table: {
                                    body: [
                                        [
                                            {
                                                image: dataUrl,
                                                width: 750,
                                                height: 440,
                                                border: [false, false],
                                            },
                                        ],
                                    ],
                                    alignment: 'center',
                                },
                            },
                        ],
                    },
                ],
            ],
        ],
        widths: [763],
        heights: [490],
    };
}

function getAverageChannelInfo(channelFcuId, projectChannels: Channel[]) {
    let averageChannelTag;
    projectChannels.forEach((channel) => {
        if (channelFcuId === channel.fcuData.fcuChannelId) {
            averageChannelTag = channel.opsData.tag;
        }
    });
    return averageChannelTag;
}

function getGraphChannels(channelsIdsSet: Set<number>, projectChannels: Channel[]) {
    const graphChannels = [];
    projectChannels.forEach((channel) => {
        if (channelsIdsSet.has(channel.fcuData.fcuChannelId)) {
            graphChannels.push(channel);
        }
    });
    return graphChannels;
}

function prepareXAxislabel(channels: ExhaustChannel[], channelsDifferences) {
    let newChannelsIds = new Set();
    let updatedIds = new Set();
    let deletedChannelsLength = 0;

    let borderedLables = {
        data: [],
        customValues: [],
    };

    let notBorderedLables = {
        data: [],
        customValues: [],
    };

    if (channelsDifferences) {
        deletedChannelsLength = channelsDifferences.deleted.length;
        channelsDifferences.added.forEach((channel) => {
            newChannelsIds.add(channel.fcuChannelId);
        });
        channelsDifferences.updated.deviationChannel.forEach((channel) => {
            updatedIds.add(channel.fcuChannelId);
        });
    }

    channels.forEach((channel: ExhaustChannel, index) => {
        if (newChannelsIds.has(channel.fcuChannelId)) {
            borderedLables.data.push(`*****\n${channel.deviationChannel}`);
            borderedLables.customValues.push(index);
            notBorderedLables.data.push('');
        } else if (updatedIds.has(channel.fcuChannelId)) {
            notBorderedLables.data.push(`*****\n{border|${channel.deviationChannel}}`);
            notBorderedLables.customValues.push(index);
            borderedLables.data.push(``);
        } else {
            notBorderedLables.data.push(`*****\n${channel.deviationChannel}`);
            notBorderedLables.customValues.push(index);
            borderedLables.data.push(``);
        }
    });

    for (let i = 0; i < deletedChannelsLength; i++) {
        notBorderedLables.data.push('');
        borderedLables.data.push(`     \n     `);
        borderedLables.customValues.push(channels.length + i);
    }

    return { borderedLables, notBorderedLables };
}

function prepareGraphValuesArray(graphChannels: Channel[], deviationLimit) {
    const channelsValueArray = [];
    graphChannels.map(() => {
        const sign = Math.random() > 0.5 ? -1 : 1;
        const randomNumber = Math.random() * deviationLimit * sign;
        channelsValueArray.push(randomNumber);
    });
    return channelsValueArray;
}
