import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import {
    ICreateLoginComponent,
    UnauthorizedUserComponent,
} from '@components/i-create-login/i-create-login.component';
import { VerifyComponent } from './components/verify/verify.component';
import { routing } from './app.routing';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorComponent } from './components/editor/editor.component';
import { AuthGuard } from './auth.guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Define } from '@helpers/define';
import { EndPointApi } from '@helpers/endpointapi';
import { Utils } from '@helpers/utils';
import { WindowRef } from '@helpers/windowref';
import { HierarchyDraggableComponent } from '@components/editor/hierarchy-view/hierarchy-draggable/hierarchy-draggable.component';
import { ContextMenuComponent } from '@components/editor/context-menu/context-menu.component';
import { SvgPreviewComponent } from '@components/editor/hierarchy-view/svg-preview/svg-preview.component';
import { DndModule } from 'ngx-drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { BagPropertyComponent } from '@components/editor/gauges/bag-property/bag-property.component';
import { HtmlSwitchPropertyComponent } from '@components/editor/gauges/html-switch-property/html-switch-property.component';
import { FlexActionComponent } from '@components/editor/gauges/gauge-property/flex-action/flex-action.component';
import { FlexAuthComponent } from '@components/editor/gauges/gauge-property/flex-auth/flex-auth.component';
import { FlexEventComponent } from '@components/editor/gauges/gauge-property/flex-event/flex-event.component';
import { FlexHeadComponent } from '@components/editor/gauges/gauge-property/flex-head/flex-head.component';
import { FlexVariableMapComponent } from '@components/editor/gauges/gauge-property/flex-variable-map/flex-variable-map.component';
import { FlexVariableComponent } from '@components/editor/gauges/gauge-property/flex-variable/flex-variable.component';
import { FlexVariablesMappingComponent } from '@components/editor/gauges/gauge-property/flex-variables-mapping/flex-variables-mapping.component';
import {
    GaugePropertyComponent,
    DialogGaugePermission,
} from '@components/editor/gauges/gauge-property/gauge-property.component';
import { ConfirmDialogComponent } from '@components/editor/gui-helpers/confirm-dialog/confirm-dialog.component';
import { NgxFabButtonComponent } from '@components/editor/gui-helpers/fab-button/ngx-fab-button.component';
import { NgxFabItemButtonComponent } from '@components/editor/gui-helpers/fab-button/ngx-fab-item-button.component';
import { NgxGaugeComponent } from '@components/editor/gui-helpers/ngx-gauge/ngx-gauge.component';
import { NgxSwitchComponent } from '@components/editor/gui-helpers/ngx-switch/ngx-switch.component';
import { SelOptionsComponent } from '@components/editor/gui-helpers/sel-options/sel-options.component';
import { TreetableComponent } from '@components/editor/gui-helpers/treetable/treetable.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorTwitterModule } from 'ngx-color/twitter';
import { EnumToArrayPipe, EscapeHtmlPipe, ObjectToRowPipe } from '@helpers/custom-pipes';
import { OpsAmsConfigFileService } from '@service/ops-ams-config-file.service';
import { EditFlgService } from '@service/editflg.service';
import { EnvironmentService } from '@service/environment.service';
import { ThemeService } from '@service/theme.service';
import { CanvasExtensionsService } from '@service/canvas/canvas-extensions.service';
import { CanvasInputMode, CanvasInputModeService } from '@service/canvas/canvas-input-mode.service';
import { CanvasService } from '@service/canvas/canvas.service';
import { EyeDropperPlugin, EyeDropperService } from '@service/canvas/eye-dropper.service';
import { GroupService } from '@service/canvas/group.service';
import { IcreateWindowReferenceService } from '@service/canvas/icreate-window-reference.service';
import { KeyboardShortcutService } from '@service/canvas/keyboard-shortcut.service';
import { MoveCanvasService } from '@service/canvas/move-canvas.service';
import { PasteService } from '@service/canvas/paste.service';
import { SelectedElementsService } from '@service/canvas/selected-elements.service';
import {
    TemplateInsertPlugin,
    TemplateInsertService,
} from '@service/canvas/template-insert.service';
import { ZoomService } from '@service/canvas/zoom.service';

import { SvgJsonSerializationService } from '@service/svg/svg-json-serialization.service';
import { SvgTransformService } from '@service/svg/svg-transform.service';
import { SvgUtilsService } from '@service/svg/svg-utils.service';
import { SvgXmlSerializationService } from '@service/svg/svg-xml-serialization.service';
import { SvgComponentSerializationService } from '@service/svg/svg-component-serialization.service';

// import { GaugesManager } from '@components/editor/gauges/gauges.component';
import { SocketService } from '@service/socket.service';
import { SocketIoModule } from 'ngx-socket-io';
import { ViewService } from '@service/view.service';
import { ProjectService } from '@service/project.service';
import { TemplateService } from '@service/template.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectApiService } from '@service/project-api.service';
import { AnimationTimingService } from '@service/animation-timing.service';
import { MimicDefsService } from '@service/mimic-defs.service';
import { ViewsComponent } from '@components/editor/views/views.component';
import { ViewGroupsService } from '@service/view-groups.service';
import { DndListModule } from 'ngx-drag-and-drop-lists';
import { ICreateHmiService } from '@service/hmi.service';
import { TemplateButtonComponent } from '@components/editor/template-button/template.button.component';
import { PropertiesBarComponent } from '@components/editor/properties-bar/properties-bar.component';
import { CommentsComponent } from '@components/editor/comment/comments.component';
import { NewCommentComponent } from '@components/editor/comment/new-comment/new-comment.component';
import { PostedCommentComponent } from '@components/editor/comment/posted-comment/posted-comment.component';
import { CommentsService } from '@service/comments.service';
import { ChangesDetectorComponent } from '@components/editor/changes-detector/changes-detector.component';
import { ColorPanelWrapperComponent } from '@components/editor/color-panel-wrapper/color-panel-wrapper.component';
import { MimicAnimationDeciderService } from '@service/mimic-animation-decider.service';
import { ContactService } from '@service/contact.service';
import { LocalStorageService } from '@service/local-storage.service';
import { MimicChangesService } from '@service/mimic-changes.service';
import { ProjectListComponent } from '@components//project-list/project-list.component';
import { PrintService } from '@service/print.service';
import { ResClientService } from '@service/resclient.service';
import { ResDemoService } from '@service/resdemo.service';
import { ResWebApiService } from '@service/reswebapi.service';
import { IapiService } from '@service/iapi.service';
import { NotificationService } from '@service/notification.service';
import { ProjectBlobSelectDialogComponent } from '@components//project-blob-select-dialog/project-blob-select-dialog.component';
import { VesselBuilderCreateService } from '@service/vessel-builder-create.service';
import { VesselBuilderSelectService } from '@service/vessel-builder-select.service';
import { VesselSelectService } from '@service/vessel-select.service';
import { LoadingSpinnerComponent } from '@layout/loading-spinner/loading-spinner.component';
import { VesselBuilderSelectListComponent } from '@components//vessel-builder-select-list/vessel-builder-select-list.component';
import { NewVesselCreateDialogComponent } from '@components//new-vessel-create-dialog/new-vessel-create-dialog.component';
import { VesselSelectListComponent } from '@components//vessel-select-list/vessel-select-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ChannelCompareService } from '@service/channel-compare.service';
import { MergeDialogComponent } from '@components//merge-dialog/merge-dialog.component';
import { ChannelCompareDialogComponent } from '@components//channel-compare-dialog/channel-compare-dialog.component';
import { ChannelMergeComponent } from '@components//channel-merge/channel-merge.component';
import { ChannelCompareHeaderComponent } from '@components//channel-compare-header/channel-compare-header.component';
import { InfiniteScrollComponent } from '@layout/infinite-scroll/infinite-scroll.component';
import { DialogNewDocComponent } from './components/editor/dialog-new-doc/dialog-new-doc.component';
import { DialogNewViewGroupComponent } from '@components/editor/dialog-new-view-group/dialog-new-view-group.component';
import { DialogDocPropertyComponent } from './components/editor/dialog-doc-property/dialog-doc-property.component';
import { DialogDocNameComponent } from './components/editor/dialog-doc-name/dialog-doc-name.component';
import { VesselBuilderCreateDialogComponent } from '@components//vessel-builder-create-dialog/vessel-builder-create-dialog.component';
import { ChannelDiffComponent } from '@components//channel-diff/channel-diff.component';
import { DeviceComponent } from '@components/device/device.component';
import { ControlConfirmDialogComponent } from '@components/device/control-confirm-dialog/control-confirm-dialog.component';
import { DeviceListComponent } from '@components/device/device-list/device-list.component';
import { DeviceMapComponent } from '@components/device/device-map/device-map.component';
import { DevicePropertyComponent } from '@components/device/device-property/device-property.component';
import { NavigationService } from '@service/navigation.service';
import { AppService } from '@service/app.service';
import { DeviceService } from '@service/device.service';
import { DeviceListService } from '@service/device-list.service';
import { OpsRequestToFcuService } from '@service/ops-request-to-fcu.service';
import { ImportStandardService } from '@service/import-standard.service';
import { AppSettingsComponent } from '@components/app-settings/app-settings.component';
import { ChannelService } from '@service/channel.service';
import { SetupComponent } from '@components//setup/setup.component';
import { DialogInfoComponent } from './layout/header/dialog-info/dialog-info.component';
import { DialogTextInputComponent } from './layout/header/dialog-text-input/dialog-text-input.component';
import { DialogConfirmationComponent } from './layout/header/dialog-confirmation/dialog-confirmation.component';
import { DialogVesselInfoComponent } from './layout/header/dialog-vessel-info/dialog-vessel-info.component';
import { DialogGridComponent } from './layout/header/dialog-grid/dialog-grid.component';
import { ExportDialogComponent } from '@components/editor/export-dialog/export-dialog.component';
import { ContactFormComponent } from '@components/contact-form/contact-form.component';
import { NgxFuxaModule } from 'ngx-fuxa';
import { LabComponent } from '@components/lab/lab.component';
import { TesterComponent } from '@components/tester/tester.component';
import { TesterCheckOptionsComponent } from '@components/tester/tester-check-options/tester-check-options.component';
import { TesterService } from '@components/tester/tester.service';
import { NewVesselCreateService } from '@service/new-vessel-create.service';
import { LabHeaderComponent } from './components/lab/lab-header/lab-header.component';
import { LabSidebarComponent } from './components/lab/lab-sidebar/lab-sidebar.component';
import { AlarmStatusIconComponent } from '@components/lab/lab-header/alarm-status-icon/alarm-status-icon.component';
import { MimicsGroupSubmenuComponent } from '@components/lab/lab-sidebar/mimics-group-submenu.component';
import { UrlParserService } from '@service/url-parser.service';
import { BlobStorageService } from '@service/blob-storage.service';
import { ChannelExcelService } from '@service/channel-excel.service';
import { SocketMiddlewareService } from '@service/socket-middleware.service';
import { ViewsService } from '@service/views.service';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { LineSelectorHelperService } from '@service/canvas/line-selector-helper.service';
import { HttpInterceptorService } from '@service/http-interceptor.service';
import { SpreadSheetReaderService } from '@service/spreadsheet-file-reader.service';
import { AppSettingsErrorComponent } from '@components/app-settings-error/app-settings-error.component';
import { OptimisticErrorComponent } from '@components/optimistic-error/optimistic-error.component';
import { BlobStorageApiService } from '@service/blob-storage-api.service';
import {
    ConnectorExtensionPlugin,
    ConnectorExtensionService,
} from '@service/canvas/connector/connector-extension.service';
import { ConnectorDrawerService } from '@service/canvas/connector/connector-drawer.service';
import { UndoManagerService } from '@service/canvas/undoMgr.service';
import { MoveElementsService } from '@service/canvas/move-element.service';
import { FuSettingsComponent } from './components/fu-settings/fu-settings.component';
import { FuSettingsTableComponent } from './components/fu-settings/fu-settings-table/fu-settings-table.component';
import { RunHourSettingsComponent } from './components/run-hour-settings/run-hour-settings.component';
import { RunHourSettingsTableComponent } from './components/run-hour-settings/run-hour-settings-table/run-hour-settings-table.component';
import { SearchChannelInputComponent } from './components/search-channel-input/search-channel-input.component';
import { RunHourSettingsService } from '@service/run-hour-settings.service';
import { ReposeSettingsComponent } from './components/repose-settings/repose-settings.component';
import { ReposeSettingsTableComponent } from './components/repose-settings/repose-settings-table/repose-settings-table.component';
import { GraphSettingsComponent } from './components/graph-settings/graph-settings.component';
import { GaugesComponent } from './components/graph-settings/gauges/gauges.component';
import { BarGraphComponent } from './components/graph-settings/bar-graph/bar-graph.component';
import { ExhaustGraphComponent } from './components/graph-settings/exhaust-graph/exhaust-graph.component';
import { GraphListComponent } from '@components/graph-settings/graph-list/graph-list.component';
import { GraphService } from '@service/graphs/graph.service';
import { ExhaustTableComponent } from './components/graph-settings/exhaust-graph/exhaust-table/exhaust-table.component';
import { TurboChargerTableComponent } from './components/graph-settings/exhaust-graph/turbo-charger-table/turbo-charger-table.component';
import { GraphSocketService } from '@service/graphs/graphs-socket.service';
import { ChannelGroupSelectorComponent } from './components/channel-group-selector/channel-group-selector.component';
import { PrintChannelsService } from '@service/print-channels-service/print-channels.service';
import { ProjectVersionSelectorComponent } from './components/project-version-selector/project-version-selector.component';
import {
    EmptyBlobStorageErrorComponent,
    ProjectVersionSelectorService,
} from '@service/project-version-selector.service';
import { BlobVersionSelectorComponent } from '@components/blob-version-selector/blob-version-selector.component';
import { PrintChannelsComponent } from './components/print-channels/print-channels.component';
import { PrintGraphsComponent } from './components/print-graphs/print-graphs.component';
import { GraphSelectorComponent } from '@components/graph-selector/graph-selector.component';
import { ProjectSelectorPageComponent } from './components/project-selector-page/project-selector-page.component';
import { CdkDropListGroup, CdkDropList, CdkDrag, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { ViewGroupComponent } from '@components/editor/views/view-group/view-group.component';
import { ViewComponent } from './components/editor/views/view/view.component';
import { ChannelSheetComponent } from './components/channel/channel-sheet/channel-sheet.component';
import { ChannelComponent } from './components/channel/channel.component';
import { DialogChannelGroupComponent } from './components/channel/dialog-channel-group/dialog-channel-group.component';
import { ChannelTextInputComponent } from './components/channel/channel-text-input/channel-text-input.component';
import { ChannelSelectInputComponent } from './components/channel/channel-select-input/channel-select-input.component';
import { ChannelPanelComponent } from './components/channel/channel-panel/channel-panel.component';
import { ChannelPanelAlarmComponent } from './components/channel/channel-panel-alarm/channel-panel-alarm.component';
import { ChannelPanelGroupComponent } from './components/channel/channel-panel-group/channel-panel-group.component';
import { ChannelPanelCategoryComponent } from './components/channel/channel-panel-category/channel-panel-category.component';
import { MoreOptionsComponent } from './components/channel/more-options/more-options.component';
import { DialogChannelCategoryComponent } from './components/channel/dialog-channel-category/dialog-channel-category.component';
import { ProjectConfirmDialogComponent } from '@components/project-confirm-dialog/project-confirm-dialog.component';
import { DialogSaveasComponent } from './layout/header/dialog-saveas/dialog-saveas.component';
import { PublishProjectDialogComponent } from './components/publish-project-dialog/publish-project-dialog.component';
import { ApproveProjectPageComponent } from './components/approve-project-page/approve-project-page.component';
import { DialogConfirmComponent } from './components/channel/dialog-confirm/dialog-confirm.component';
import { OpsSettingsComponent } from './components/ops-settings/ops-settings.component';
import { OpsUserComponent } from './components/ops-user/ops-user.component';
import { OpsUserPermissionsComponent } from './components/ops-user-permissions/ops-user-permissions.component';
import { OpsUserPasswordComponent } from './components/ops-user-password/ops-user-password.component';

function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent,
        ContactFormComponent,
        HeaderComponent,
        DialogInfoComponent,
        DialogTextInputComponent,
        DialogConfirmationComponent,
        ProjectConfirmDialogComponent,
        DialogVesselInfoComponent,
        DialogGridComponent,
        SetupComponent,
        ICreateLoginComponent,
        UnauthorizedUserComponent,
        VerifyComponent,
        LoadingSpinnerComponent,
        EditorComponent,
        HierarchyDraggableComponent,
        ContextMenuComponent,
        SvgPreviewComponent,
        ViewsComponent,
        DialogNewViewGroupComponent,
        TemplateButtonComponent,
        PropertiesBarComponent,
        CommentsComponent,
        NewCommentComponent,
        PostedCommentComponent,
        ChangesDetectorComponent,
        PropertiesBarComponent,
        ColorPanelWrapperComponent,
        DialogNewDocComponent,
        DialogNewViewGroupComponent,
        DialogDocPropertyComponent,
        DialogDocNameComponent,
        ExportDialogComponent,
        ConfirmationWindowComponent,
        ProjectBlobSelectDialogComponent,
        VesselBuilderSelectListComponent,
        NewVesselCreateDialogComponent,
        VesselSelectListComponent,
        VesselBuilderCreateDialogComponent,
        ProjectListComponent,
        AppSettingsComponent,
        MergeDialogComponent,
        ChannelCompareDialogComponent,
        ChannelMergeComponent,
        ChannelCompareHeaderComponent,
        ChannelDiffComponent,
        InfiniteScrollComponent,
        DeviceComponent,
        ControlConfirmDialogComponent,
        DeviceListComponent,
        DeviceMapComponent,
        DevicePropertyComponent,
        LabComponent,
        MimicsGroupSubmenuComponent,
        LabSidebarComponent,
        AlarmStatusIconComponent,
        TesterCheckOptionsComponent,
        TesterComponent,
        AppSettingsErrorComponent,
        OptimisticErrorComponent,
        ViewGroupComponent,
        LabHeaderComponent,
        LabSidebarComponent,
        GraphSettingsComponent,
        GraphListComponent,
        ChannelGroupSelectorComponent,

        //===== guages compoent START =====
        FlexAuthComponent,
        FlexHeadComponent,
        FlexEventComponent,
        FlexActionComponent,
        FlexVariableComponent,
        FlexVariablesMappingComponent,
        FlexVariableMapComponent,

        GaugePropertyComponent,
        DialogGaugePermission,
        BagPropertyComponent,
        HtmlSwitchPropertyComponent,
        //===== guages compoent END =====

        //===== guages-helpers compoent START =====
        NgxFabButtonComponent,
        NgxFabItemButtonComponent,
        TreetableComponent,
        SelOptionsComponent,
        NgxGaugeComponent,
        NgxSwitchComponent,
        ConfirmDialogComponent,
        //===== guages-helpers compoent END =====

        //===== PIPE START =====
        EnumToArrayPipe,
        EscapeHtmlPipe,
        ObjectToRowPipe,
        ViewComponent,
        //===== PIPE END =====

        GaugesComponent,
        BarGraphComponent,
        ExhaustGraphComponent,
        ExhaustTableComponent,
        TurboChargerTableComponent,
        FuSettingsComponent,
        FuSettingsTableComponent,
        RunHourSettingsComponent,
        RunHourSettingsTableComponent,
        SearchChannelInputComponent,
        ReposeSettingsComponent,
        ReposeSettingsTableComponent,
        ProjectVersionSelectorComponent,
        EmptyBlobStorageErrorComponent,
        BlobVersionSelectorComponent,
        PrintChannelsComponent,
        PrintGraphsComponent,
        GraphSelectorComponent,
        ProjectVersionSelectorComponent,
        BlobVersionSelectorComponent,
        ProjectSelectorPageComponent,
        DialogSaveasComponent,
        PublishProjectDialogComponent,
        ApproveProjectPageComponent,
        ChannelSheetComponent,
        ChannelComponent,
        DialogChannelGroupComponent,
        ChannelTextInputComponent,
        ChannelSelectInputComponent,
        ChannelPanelComponent,
        ChannelPanelAlarmComponent,
        ChannelPanelGroupComponent,
        ChannelPanelCategoryComponent,
        MoreOptionsComponent,
        DialogChannelCategoryComponent,
        DialogConfirmComponent,
        OpsSettingsComponent,
        OpsUserComponent,
        OpsUserPermissionsComponent,
        OpsUserPasswordComponent,
        ChannelSheetComponent,
        ChannelComponent,
        DialogChannelGroupComponent,
        ChannelTextInputComponent,
        ChannelSelectInputComponent,
        ChannelPanelComponent,
        ChannelPanelAlarmComponent,
        ChannelPanelGroupComponent,
        ChannelPanelCategoryComponent,
        MoreOptionsComponent,
        DialogChannelCategoryComponent,
        DialogConfirmComponent,
        OpsSettingsComponent,
        OpsUserComponent,
        OpsUserPermissionsComponent,
        OpsUserPasswordComponent,
    ],
    imports: [
        CdkDropList,
        CdkDrag,
        CdkDropListGroup,
        CdkDragPlaceholder,
        BrowserModule,
        BrowserAnimationsModule,
        routing,
        HttpClientModule,
        // MatIconModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            defaultLanguage: 'en',
        }),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: false,
        }),
        DndModule,
        DndListModule,
        ColorPickerModule,
        ColorTwitterModule,
        SocketIoModule.forRoot({ url: EndPointApi.getURL(), options: {} }),
        Ng2SearchPipeModule,
        NgxFuxaModule,
    ],
    providers: [
        AppService,
        AuthGuard,
        Define,
        EndPointApi,
        Utils,
        WindowRef,
        OpsAmsConfigFileService,
        EditFlgService,
        EnvironmentService,
        ThemeService,
        // GaugesManager,
        SocketService,
        ViewService,
        ProjectService,
        ProjectApiService,
        TemplateService,
        AnimationTimingService,
        MimicDefsService,
        ViewGroupsService,
        ICreateHmiService,
        CommentsService,
        MimicAnimationDeciderService,
        ContactService,
        LocalStorageService,
        MimicChangesService,
        SpreadSheetReaderService,
        PrintService,
        ResClientService,
        ResDemoService,
        ResWebApiService,
        IapiService,
        NotificationService,
        VesselBuilderCreateService,
        VesselBuilderSelectService,
        VesselSelectService,
        NewVesselCreateService,
        ChannelCompareService,
        NavigationService,
        ImportStandardService,
        ChannelService,
        SetupComponent,
        RunHourSettingsService,
        GraphService,
        GraphSocketService,
        PrintChannelsService,
        ProjectVersionSelectorService,

        //* ==== canvas services ==== */
        CanvasExtensionsService,
        CanvasInputModeService,
        CanvasService,
        EyeDropperService,
        EyeDropperPlugin,
        GroupService,
        IcreateWindowReferenceService,
        KeyboardShortcutService,
        MoveCanvasService,
        PasteService,
        SelectedElementsService,
        TemplateInsertService,
        TemplateInsertPlugin,
        ZoomService,
        LocalStorageService,
        LineSelectorHelperService,
        UndoManagerService,

        //* ==== svg services ==== */
        {
            provide: SvgComponentSerializationService,
            useClass: SvgXmlSerializationService,
        },
        SvgJsonSerializationService,
        SvgTransformService,
        SvgUtilsService,
        SvgXmlSerializationService,

        //* ==== device services ==== */
        DeviceService,
        DeviceListService,
        OpsRequestToFcuService,

        TesterService,
        UrlParserService,
        BlobStorageService,
        BlobStorageApiService,
        ChannelExcelService,
        SocketMiddlewareService,
        ViewsService,
        ConnectorExtensionPlugin,
        ConnectorExtensionService,
        ConnectorDrawerService,
        MoveElementsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
