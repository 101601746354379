<div #overlay class="confirm-overlay" [ngStyle]="style">
    <div class="confirm-content center-content">
        <div *ngIf="!isLoadingPdf" class="search-container">
            <div>
                <app-channel-group-selector
                    [groupList]="groupList"
                    (onGroupSelectedChanged)="
                        handleSelectedGroupsChanged($event)
                    "
                ></app-channel-group-selector>

                <mat-checkbox
                    [(ngModel)]="compareChannels"
                    *ngIf="selectedGroups.length > 0"
                >
                    {{ "Compare Channels " }}
                </mat-checkbox>
                <app-project-version-selector
                    *ngIf="compareChannels"
                    [projectVersion]="projectVersion"
                    (onVersionSelected)="projectVersionSelected($event)"
                    (onDisablePrint)="disablePrintButton($event)"
                >
                </app-project-version-selector>
            </div>

            <div
                style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 30px;
                "
            >
                <button (click)="exit()">
                    {{ "print-channels.button.exit" | translate }}
                </button>
                <button
                    (click)="printChannels()"
                    [disabled]="shouldDisablePrintButton()"
                >
                    {{ "print-channels.button.print" | translate }}
                </button>
            </div>
        </div>
        <app-loading-spinner #loadingSpinner *ngIf="isLoadingPdf">
        </app-loading-spinner>
    </div>
</div>
