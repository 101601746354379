import { Component, OnInit } from '@angular/core';
import { Group } from '@components/channel-group-selector/channel-group-selector.component';
import { ChannelCompareService } from '@service/channel-compare.service';
import { PrintChannelsService } from '@service/print-channels-service/print-channels.service';
import { ProjectService } from '@service/project.service';
import { ProjectData } from 'icreate/common/models/project';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
    selector: 'app-print-channels',
    templateUrl: './print-channels.component.html',
    styleUrls: ['./print-channels.component.scss'],
})
export class PrintChannelsComponent implements OnInit {
    constructor(
        private printChannelsService: PrintChannelsService,
        private projectService: ProjectService,
        private channelCompareService: ChannelCompareService,
    ) {
        (window as any).pdfMake.vfs = pdfFonts?.pdfMake.vfs;
    }

    style = {
        display: 'none',
    };
    isLoadingPdf: boolean = false;
    compareChannels: boolean = false;
    projectVersion: string;
    groupList: Group[] = [];
    selectedGroups: Group[] = [];

    printButtonIsDisabled: boolean = false

    ngOnInit(): void {
        this.printChannelsService.onPrintChannels.subscribe(async () => {
            const projectId = this.projectService.getProjectId();
            this.groupList = [];
            const groups = await this.printChannelsService.getChannelGroups(projectId);
            for (let i = 0; i < groups.length; i++) {
                this.groupList.push({
                    name: groups[i].displayName,
                    checked: false,
                    id: groups[i].id,
                });
            }
            this.style.display = 'flex';
        });
    }

    handleSelectedGroupsChanged(newSelectedGroups) {
        this.selectedGroups = newSelectedGroups;
    }

    async printChannels() {
        const selectedGroups = [];
        this.isLoadingPdf = true;
        this.selectedGroups.map((group: Group) => {
            if (group.checked) {
                selectedGroups.push(group);
            }
        });
        const projectId = this.projectService.getProjectId();
        const currentChannels = await this.printChannelsService.getCurrentChannels(
            selectedGroups,
            projectId,
        );

        let versionsDifferences = {};
        if (this.compareChannels) {
            if (this.projectVersion) {
                versionsDifferences = await this.getChannelsDifference(projectId);
            } else {
                console.error('no project version selected');
            }
        }

        const pdfData = await this.printChannelsService.getPdpFile(
            currentChannels,
            versionsDifferences,
            selectedGroups,
        );
        this.isLoadingPdf = false;
        pdfMake.createPdf(pdfData).print();
        this.clearDataAfterPrint();
    }

    async getChannelsDifference(projectId) {
        const projectData: ProjectData = await this.projectService.getProjectData(projectId);
        try {
            const channelDiff = await this.channelCompareService.getChannelDiff(
                projectId,
                projectData.VesselID,
                projectData.VesselBuilderCompanyID,
                this.projectVersion,
            );
            return channelDiff;
        } catch (err) {
            if (err.status === 304) {
                return {};
            } else {
                console.error(err);
            }
        }
    }

    projectVersionSelected(projectVersion) {
        this.projectVersion = projectVersion;
    }

    exit() {
        this.clearDataAfterPrint()
        this.style.display = 'none';
    }

    clearDataAfterPrint() {
        this.groupList.map((group: Group) => {
            if (group.checked) {
                group.checked = false;
            }
        });
        this.projectVersion = undefined;
        this.compareChannels = false;
        this.selectedGroups = [];
    }

    disablePrintButton(shouldDisable) {
        this.printButtonIsDisabled = shouldDisable
    }

    shouldDisablePrintButton() {
        if (this.selectedGroups.length === 0) {
            return true
        }
        if (this.compareChannels && !this.projectVersion) {
            return true
        }
        return this.printButtonIsDisabled
    }

}
