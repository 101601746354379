export enum ControlTargetSide {
    Output = 0,
    Input = 1,
}

export class ChannelControlDataFormat {
    controlNum: number;
    controlDetails: ChannelControlDataDetail[] = [];

    public convertToBinary(): any {
        let buffer = [];
        buffer.push(this.controlNum);
        buffer.push(0);
        this.controlDetails.forEach((d) => {
            buffer = buffer.concat(d.convertToBinary());
        });
        return buffer;
    }
}

export class ChannelControlDataDetail {
    targetSide: number;
    channelId: number;
    value: number;

    public convertToBinary(): any {
        const buffer = [];
        buffer.push(this.targetSide);
        buffer.push(0);
        buffer.push(this.channelId & 0xff);
        buffer.push((this.channelId >> 8) & 0xff);
        buffer.push(this.value & 0xff);
        buffer.push((this.value >> 8) & 0xff);
        buffer.push((this.value >> 16) & 0xff);
        buffer.push((this.value >> 24) & 0xff);
        return buffer;
    }
}
