import { Injectable } from '@angular/core';
import { SvgComponentSerializationService } from './svg-component-serialization.service';
import { CanvasService } from '@service/canvas/canvas.service';

@Injectable()
export class SvgJsonSerializationService extends SvgComponentSerializationService {
    constructor(private canvasService: CanvasService) {
        super();
    }

    public serialize(components: SVGGraphicsElement[]): any[] {
        const canvas = this.canvasService.getCanvas();
        return components.map((element) => canvas.getJsonFromSvgElements(element));
    }

    public deserialize(serializedComponents: any[]): SVGGraphicsElement[] {
        //SvgCanvas does not provide an API to deserialize without adding to canvas.
        //This is the only way to perform the expected behavior of the deserialize
        // function using SvgEdit.
        const canvas = this.canvasService.getCanvas();
        const elements = serializedComponents.map((component) => {
            const deserializedComponent = canvas.addSVGElementsFromJson(component);
            deserializedComponent.parentNode.removeChild(deserializedComponent);
            return deserializedComponent;
        });

        return elements;
    }
}
