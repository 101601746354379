import { Injectable } from '@angular/core';
import { SVG } from '@svgdotjs/svg.js';

@Injectable({ providedIn: 'root' })
export class SvgTransformService {
    move(element: SVGGraphicsElement, x: number, y: number) {
        SVG(element).move(x, y);
    }

    shift(element: SVGGraphicsElement, dx: number, dy: number) {
        SVG(element).dmove(dx, dy);
    }
}
