import { Injectable } from '@angular/core';
import { ICreateHmiService } from './hmi.service';

@Injectable({
    providedIn: 'root',
})
export class DeviceListService {
    constructor(private hmiService: ICreateHmiService) {}

    public onGetTags() {
        return this.hmiService.onDeviceValueResponse();
    }

    public onGetUpdatedTagValue() {
        return this.hmiService.onDeviceFlagsUpdated();
    }
}
