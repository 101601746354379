<div>
  <h1
    mat-dialog-title
    style="display: inline-block; cursor: move"
    mat-dialog-draggable
  >
    {{ "dlg.app-settings-title" | translate }}
  </h1>
  <mat-icon
    (click)="onNoClick()"
    style="
      float: right;
      margin-right: -10px;
      margin-top: -10px;
      color: gray;
      cursor: pointer;
    "
    >clear</mat-icon
  >
  <ng-container *ngIf="data.newProject; else edit">
    <div mat-dialog-content>
      <mat-tab-group style="height: 500px; width: 620px">
        <mat-tab label="{{ 'dlg.app-settings-system' | translate }}">
          <div style="width: 420px; margin-top: 20px">
            <div style="display: block; margin-top: 20px">
              <div class="my-form-field">
                <span>{{ "dlg.app-settings-project-name" | translate }}</span>
                <input
                  matInput
                  style="width: 320px"
                  type="text"
                  required="required"
                  [(ngModel)]="projectSettingFormData.projectName"
                  (input)="checkFormErrors()"
                  [value]="projectSettingFormData.projectName"
                  placeholder="No data"
                />
                <app-app-settings-error
                  [validationErrors]="validationErrors"
                  [propertyName]="'projectName'"
                >
                </app-app-settings-error>
              </div>
            </div>
            <div
              *ngIf="!data.newProject"
              style="display: block; margin-top: 10px"
            >
              <div class="my-form-field">
                <span>{{ "dlg.app-settings-language" | translate }}</span>
                <mat-select
                  [(value)]="settings.language"
                  style="width: 320px"
                  (selectionChange)="onLanguageChange($event.value)"
                >
                  <mat-option
                    *ngFor="let language of languageType"
                    [value]="language.value"
                  >
                    {{ language.text | translate }}
                  </mat-option>
                </mat-select>
                <app-app-settings-error
                  [validationErrors]="validationErrors"
                  [propertyName]="'language'"
                >
                </app-app-settings-error>
              </div>
            </div>
            <div style="display: block; margin-top: 20px">
              <div class="my-form-field" style="width: 100%">
                <span>{{ "dlg.app-settings-vessel-builder" | translate }}</span>
                <input
                  matInput
                  style="width: 320px"
                  type="text"
                  required="required"
                  placeholder="No Select"
                  [value]="projectSettingFormData?.vesselBuildData.name"
                  (click)="onSelectVessel()"
                  readonly="true"
                />

                <mat-icon
                  (click)="onSelectVessel()"
                  style="
                    vertical-align: middle;
                    transform: translate(-120%, 0);
                    cursor: pointer;
                  "
                  >link</mat-icon
                >
                <app-app-settings-error
                  [validationErrors]="validationErrors"
                  [propertyName]="'vesselBuildData.id'"
                >
                </app-app-settings-error>
              </div>
            </div>
            <div style="display: block; margin-top: 20px">
              <div class="my-form-field">
                <span>{{ "dlg.app-settings-vessel-name" | translate }}</span>
                <input
                  [(ngModel)]="projectSettingFormData?.vesselData.name"
                  style="width: 320px"
                  type="text"
                  disabled="true"
                />
              </div>
              <app-app-settings-error
                [validationErrors]="validationErrors"
                [propertyName]="'vesselData.id'"
              >
              </app-app-settings-error>
            </div>
            <div style="display: block; margin-top: 20px">
              <div class="my-form-field">
                <span>{{ "dlg.app-settings-standard-name" | translate }}</span>
                <select matNativeControl>
                  <option>JSMEA</option>
                  <option>DNV</option>
                </select>
                <input
                  type="file"
                  style="display: none"
                  #fileInput
                  accept=".xls,.xlsx"
                  (change)="onChangeSelectedFile($event)"
                />
                <button mat-raised-button color="primary" (click)="onImport()">
                  import standard
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div mat-dialog-actions class="dialog-action">
      <button mat-raised-button (click)="onNoClick()">
        {{ "dlg.cancel" | translate }}
      </button>
      <button
        mat-raised-button
        [disabled]="!checkDisabledButton()"
        color="primary"
        (click)="onCreateClick()"
        cdkFocusInitial
      >
        {{ "dlg.create" | translate }}
      </button>
    </div>
  </ng-container>
  <ng-template #edit>
    <div mat-dialog-content>
      <mat-tab-group style="height: 500px; width: 620px">
        <mat-tab label="{{ 'dlg.app-settings-system' | translate }}">
          <div style="width: 420px; margin-top: 20px">
            <div style="display: block; margin-top: 10px">
              <div class="my-form-field">
                <span>{{ "dlg.app-settings-language" | translate }}</span>
                <mat-select
                  [(value)]="projectLanguage"
                  style="width: 320px"
                  (selectionChange)="onLanguageChange($event.value)"
                >
                  <mat-option
                    *ngFor="let language of languageType"
                    [value]="language.value"
                  >
                    {{ language.text | translate }}
                  </mat-option>
                </mat-select>
              </div>
              <app-app-settings-error
                [validationErrors]="validationErrors"
                [propertyName]="'language'"
              >
              </app-app-settings-error>
            </div>
            <div style="display: block; margin-top: 20px">
              <div class="my-form-field" style="width: 100%">
                <span>{{ "dlg.app-settings-vessel-builder" | translate }}</span>
                <input
                  matInput
                  style="width: 320px"
                  type="text"
                  required="required"
                  placeholder="No Select"
                  [value]="projectSettingFormData?.vesselBuildData.name"
                  (click)="onSelectVessel()"
                  readonly="true"
                />

                <mat-icon
                  (click)="onSelectVessel()"
                  style="
                    vertical-align: middle;
                    transform: translate(-120%, 0);
                    cursor: pointer;
                  "
                  >link</mat-icon
                >
              </div>
              <app-app-settings-error
                [validationErrors]="validationErrors"
                [propertyName]="'vesselBuildData.id'"
                [settingIsUpdated]="settingsIsUpdated.vesselBuildData"
              >
              </app-app-settings-error>
            </div>
            <div style="display: block; margin-top: 20px">
              <div class="my-form-field">
                <span>{{ "dlg.app-settings-vessel-name" | translate }}</span>
                <input
                  [(ngModel)]="projectSettingFormData?.vesselData.name"
                  (ngModelChange)="onChangeVesselName($event)"
                  style="width: 320px"
                  type="text"
                />
              </div>
              <app-app-settings-error
                [validationErrors]="validationErrors"
                [propertyName]="'vesselData.id'"
                [settingIsUpdated]="settingsIsUpdated.vesselData"
              >
              </app-app-settings-error>
            </div>
            <div style="display: block; margin-top: 20px">
              <div class="my-form-field">
                <span>{{ "dlg.app-settings-standard-name" | translate }}</span>
                <select matNativeControl>
                  <option>JSMEA</option>
                  <option>DNV</option>
                </select>
                <input
                  type="file"
                  style="display: none"
                  #fileInput
                  accept=".xls,.xlsx"
                  (change)="onChangeSelectedFile($event)"
                />
                <button mat-raised-button color="primary" (click)="onImport()">
                  import standard
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div mat-dialog-actions class="dialog-action">
      <button mat-raised-button (click)="onNoClick()">
        {{ "dlg.cancel" | translate }}
      </button>
      <button
        mat-raised-button
        [disabled]="isError"
        color="primary"
        (click)="onEditClick()"
        cdkFocusInitial
      >
        {{ "dlg.edit" | translate }}
      </button>
    </div>
  </ng-template>
</div>
