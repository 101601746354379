import { EventEmitter, Injectable } from '@angular/core';
import { ChannelGroup } from 'common/models/channel-group';
import jspreadsheet from 'jspreadsheet-ce';

@Injectable({
    providedIn: 'root',
})
export class ChannelSheetService {
    sheet: jspreadsheet.JspreadsheetInstance;
    activeGroup: ChannelGroup;
    selectRowEmitter = new EventEmitter<number>();
    selectGroupEmitter = new EventEmitter<ChannelGroup>();
    updateChannelGroupEmitter = new EventEmitter<UpdateType>();
    updateChannelCategoryEmitter = new EventEmitter();
    updateChannelSheetEmitter = new EventEmitter();
    loadingEmitter = new EventEmitter<boolean>();

    setSheet(sheet: jspreadsheet.JspreadsheetInstance) {
        this.sheet = sheet;
    }

    setActiveGroup(group: ChannelGroup) {
        this.activeGroup = group;
    }

    get _sheet() {
        return this.sheet;
    }

    get _selectedRow() {
        return this.sheet.getSelectedRows(true);
    }

    get _activeGroup() {
        return this.activeGroup;
    }

    updateChannelSheet() {
        this.updateChannelSheetEmitter.emit();
    }

    selectedRow(rowIndex) {
        this.selectRowEmitter.emit(rowIndex);
    }

    selectGroup(group) {
        this.selectGroupEmitter.emit(group);
    }

    updateGroupList(updateType = UpdateType.update) {
        this.updateChannelGroupEmitter.emit(updateType);
    }

    updateCategoryList() {
        this.updateChannelCategoryEmitter.emit();
    }

    getRowValue(rowIndex: number) {
        let cell = this.sheet.getCell([0, rowIndex]);
        return this.sheet.getValue(cell).toString();
    }

    showLoading() {
        this.loadingEmitter.emit(true);
    }

    hideLoading() {
        this.loadingEmitter.emit(false);
    }
}

export enum UpdateType {
    update,
    delete,
    add,
}
