import { ChannelCategory } from './channel-category';

export class ChannelGroup {
    id: string;
    projectId: string;
    displayName: string;
    description: string;
    categoryId: string;
    category?: ChannelCategory;
}
