import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';

@Component({
    selector: 'app-vessel-builder-create-dialog',
    templateUrl: './vessel-builder-create-dialog.html',
    styleUrls: ['./vessel-builder-create-dialog.scss'],
})
export class VesselBuilderCreateDialogComponent implements OnInit {
    reactiveForm: UntypedFormGroup;

    constructor(
        public fb: UntypedFormBuilder,
        public companyInfoEnteringDialog: MatDialogRef<VesselBuilderCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.reactiveForm = this.fb.group({
            name: ['', [Validators.required, this.dirtyAndNull]],
            othername: [''],
            ceo: [''],
            address: [''],
            telephone: [''],
            fax: [''],
            email: ['', [Validators.email]],
        });
    }

    ngOnInit(): void {}

    //UNUSED
    // ctrl(name:string){
    //   return this.reactiveForm.controls[name];
    // }

    onRegister(): any {
        const info = {
            name: this.reactiveForm.controls['name'].value,
            othername: this.reactiveForm.controls['othername'].value,
            ceo: this.reactiveForm.controls['ceo'].value,
            address: this.reactiveForm.controls['address'].value,
            telephone: this.reactiveForm.controls['telephone'].value,
            fax: this.reactiveForm.controls['fax'].value,
            email: this.reactiveForm.controls['email'].value,
            thumbnailExtension: '',
            businessContents: '',
            types: ['builder'],
        };
        this.companyInfoEnteringDialog.close(info);
    }

    onCancel(): any {
        this.companyInfoEnteringDialog.close();
    }

    private dirtyAndNull(ctrl: AbstractControl): ValidationErrors | null {
        if (ctrl.dirty && !ctrl.value) {
            return { dirtyAndNull: true };
        } else {
            return null;
        }
    }
}
