import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { CanvasService } from './canvas.service';
import { IcreateWindowReferenceService } from './icreate-window-reference.service';

import { CanvasInputMode, CanvasInputModeService } from './canvas-input-mode.service';
import { WindowRef } from '@helpers/windowref';

@Injectable()
export class MoveCanvasService implements OnDestroy {
    private _onCanvasMoved = new EventEmitter<void>();

    private moveCanvasMouseMove: (e: MouseEvent) => void;
    private moveCanvasMouseUp: (e: MouseEvent) => void;
    private moveCanvasMouseDown: (e: MouseEvent) => void;

    constructor(
        private windowElements: IcreateWindowReferenceService,
        private canvas: CanvasService,
        private tools: CanvasInputModeService,
        private winRef: WindowRef,
    ) {}

    init(): void {
        this.bindMoveCanvasEvents();
    }

    ngOnDestroy(): void {
        this.unbindMoveCanvasEvents();
    }

    public onCanvasMoved() {
        return this._onCanvasMoved;
    }

    public moveCanvas(offsetX, offsetY) {
        const editor = this.windowElements.getEditorContainer();
        editor.style.top = editor.offsetTop + offsetY + 'px';
        editor.style.left = editor.offsetLeft + offsetX + 'px';
    }

    private emitCanvasMove() {
        this._onCanvasMoved.emit();
    }

    private bindMoveCanvasEvents() {
        let pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;
        const editor: HTMLElement = this.windowElements.getEditorContainer();

        let isMovingCanvas = false;

        this.moveCanvasMouseDown = (e) => {
            if (this.tools.getMode() == CanvasInputMode.MOVE_CANVAS) {
                e.preventDefault();
                pos3 = e.clientX;
                pos4 = e.clientY;
                isMovingCanvas = true;
            }
        };

        this.moveCanvasMouseMove = (e) => {
            if (isMovingCanvas) {
                e.preventDefault();
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;

                this.moveCanvas(-pos1, -pos2);
                this.emitCanvasMove();
            }
        };

        this.moveCanvasMouseUp = (e) => {
            isMovingCanvas = false;
        };

        this.winRef.nativeWindow.addEventListener('mouseup', this.moveCanvasMouseUp);
        this.winRef.nativeWindow.addEventListener('mousemove', this.moveCanvasMouseMove);
        editor.addEventListener('mousedown', this.moveCanvasMouseDown);
    }

    private unbindMoveCanvasEvents() {
        this.winRef.nativeWindow.removeEventListener('mouseup', this.moveCanvasMouseUp);
        this.winRef.nativeWindow.removeEventListener('mousemove', this.moveCanvasMouseMove);
        this.windowElements
            .getEditorContainer()
            .removeEventListener('mousedown', this.moveCanvasMouseDown);
    }
}
