import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    Output,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Channel } from 'common/models/channel';
import { RunHourSetting } from 'common/models/run-hour-setting';
import { RunHourSettingValidator } from 'common/validators/run-hour-setting/run-hour-setting-validator';
import { getValidationErrors, mapErrors } from 'icreate/common/validators';

@Component({
    selector: 'app-run-hour-settings-table',
    templateUrl: './run-hour-settings-table.component.html',
    styleUrls: ['./run-hour-settings-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunHourSettingsTableComponent {
    @Input() runHourSettingList: RunHourSetting[] = [];
    @Input() channels: Channel[];
    @Output() onSelectedChange: EventEmitter<RunHourSetting | void> = new EventEmitter();

    tableDataSource = new MatTableDataSource<RunHourSetting>();
    displayedColumns: string[] = ['number', 'runHourChannel', 'sourceChannel'];
    iterableDiffer: IterableDiffer<any>;
    selectedSettings: RunHourSetting;

    inputModel = {};

    constructor(
        private iterableDiffers: IterableDiffers,
        private cdr: ChangeDetectorRef,
    ) {
        this.iterableDiffer = iterableDiffers.find([]).create(null);
    }

    ngOnInit(): void {
        this.tableDataSource.data = this.runHourSettingList;
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    ngDoCheck() {
        let runHourListChanges = this.iterableDiffer.diff(this.runHourSettingList);
        if (runHourListChanges) {
            this.tableDataSource.data = this.runHourSettingList;
        }
    }

    selectRow(runHourSetting) {
        this.selectedSettings = runHourSetting;
        this.inputModel = { ...runHourSetting };
        this.onSelectedChange.emit(runHourSetting);
    }

    unselectRow() {
        this.selectedSettings = null;
        this.onSelectedChange.emit();
    }

    getSettingErrors(runHourSetting, key) {
        const errors = getValidationErrors(RunHourSettingValidator(), runHourSetting);
        if (errors) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                return validationErrors[key].errors[0];
            }
        }
    }
}
