import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Channel } from 'common/models/channel';

import { ChannelGroup } from 'common/models/channel-group';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

import { CloudOperation } from 'common/models/publish';
import { LoadingSpinnerComponent } from '@layout/loading-spinner/loading-spinner.component';
import { ChannelCompareService } from '@service/channel-compare.service';
import { ProjectService } from '@service/project.service';
import {
    ChannelObjectKey,
    ChannelOperation,
    ChannelCompare,
    GroupCompare,
    ChannelMerge,
} from 'icreate/common/models/channel-compare';
import { InfiniteScrollComponent } from '@layout/infinite-scroll/infinite-scroll.component';
import { ChannelCompareDialogComponent } from '../channel-compare-dialog/channel-compare-dialog.component';
import { ChannelConfirmDialogComponent } from '../channel-confirm-dialog/channel-confirm-dialog.component';
import { ProjectData } from 'icreate/common/models/project';
import { BlobOperationParam } from 'common/models/blob-operations';
import { BlobStorageApiService } from '@service/blob-storage-api.service';
import { BlobStorageService } from '@service/blob-storage.service';

@Component({
    selector: 'app-merge-dialog',
    templateUrl: './merge-dialog.component.html',
    styleUrls: ['./merge-dialog.component.css'],
})
export class MergeDialogComponent {
    readonly groups = ChannelObjectKey.GROUPS;
    readonly channels = ChannelObjectKey.CHANNELS;
    readonly update = ChannelOperation.UPDATE;

    channelDiffArray: ChannelCompare[] = [];
    groupsDiffArray: GroupCompare[] = [];
    chunkedDiffArray = [];
    version: string;
    compareCount = {
        add: 0,
        update: 0,
        delete: 0,
    };

    totalPages = 0;
    private pageSize = 10;
    currentPage = 1;

    left = 'left';
    right = 'right';

    isConflictResolved = {};
    mergeResolvedUpdate = {
        channels: {},
        groups: {},
    };
    mergeResolvedDelete = {
        channels: {},
        groups: {},
    };
    currentProjecData: ProjectData = new ProjectData();

    //channels or groups
    selectedChannelObjectKey = ChannelObjectKey.CHANNELS;
    isOverlayVisible: boolean = false;
    pullOrPublish = '';

    overlayRef = this.overlay.create({
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });

    @ViewChild(InfiniteScrollComponent) infiniteScroll: InfiniteScrollComponent;

    constructor(
        private channelCompareService: ChannelCompareService,
        private projectService: ProjectService,
        private blobStorageService: BlobStorageService,
        private blobStorageApiService: BlobStorageApiService,
        @Inject(MAT_DIALOG_DATA)
        public data: ChannelMerge,
        public dialog: MatDialog,
        private overlay: Overlay,
        public dialogRef: MatDialogRef<ChannelCompareDialogComponent>,
        public dialogConfirmRef: MatDialogRef<ChannelConfirmDialogComponent>,
    ) {
        this.showLoadingOverlay();
        this.version = data.version;
        this.channelDiffArray = data.channels.differenceItems;
        this.groupsDiffArray = data.groups.differenceItems;
        this.compareCount = data.channels.compareCount;
        this.pullOrPublish = data.type;
        this.currentProjecData = data.projectData;
        this.initializeConflictData();
        this.hideLoadingOverlay();
    }

    ngAfterViewInit(): void {
        this.infiniteScroll.scrollToTop();
    }

    initializeConflictData() {
        this.chunkedDiffArray = this.channelDiffArray.slice(0, this.pageSize);
        this.totalPages = Math.ceil(this.channelDiffArray.length / this.pageSize);

        this.initializeConflictArray(this.channelDiffArray, ChannelObjectKey.CHANNELS);
        this.initializeConflictArray(this.groupsDiffArray, ChannelObjectKey.GROUPS);
    }

    initializeConflictArray(array: GroupCompare[] | ChannelCompare[], type: ChannelObjectKey) {
        for (let i = 0; i < array.length; i++) {
            const currentItem = array[i];
            if (currentItem.operationType === ChannelOperation.UPDATE) {
                this.initializeIsConflicResolved(currentItem.local.id, currentItem.modifiedFields);
                this.initializeMergedResolvedData(
                    currentItem.local.id,
                    _.cloneDeep(
                        this.pullOrPublish === CloudOperation.PULL
                            ? currentItem.blob
                            : currentItem.local,
                    ),
                    type,
                );
            }
        }
    }

    initializeIsConflicResolved(id: string, flattenKeys?: string[]) {
        if (flattenKeys) {
            for (let i = 0; i < flattenKeys.length; i++) {
                const fullKey = id.concat('.', flattenKeys[i]);
                if (!this.isConflictResolved[fullKey]) {
                    this.isConflictResolved[fullKey] = '';
                }
            }
        }
    }

    initializeMergedResolvedData(
        id: string,
        currentData: Channel | ChannelGroup,
        type: ChannelObjectKey,
    ) {
        if (!this.mergeResolvedUpdate[type][id]) {
            this.mergeResolvedUpdate[type][id] = currentData;
        }
    }

    onSwitchType(key: ChannelObjectKey) {
        this.selectedChannelObjectKey = key;
        this.chunkedDiffArray = [];
        if (this.selectedChannelObjectKey == ChannelObjectKey.GROUPS) {
            this.chunkedDiffArray = this.groupsDiffArray.slice(0, this.pageSize);
            this.compareCount = this.data.groups.compareCount;
        } else {
            this.chunkedDiffArray = this.channelDiffArray.slice(0, this.pageSize);
            this.compareCount = this.data.channels.compareCount;
        }
    }

    onResolvedClick(event: { id: string; flattenKey: string; resolvedData: any }) {
        const { id, flattenKey, resolvedData } = event;
        const fullKey = `${id}.${flattenKey}`;
        _.set(
            this.mergeResolvedUpdate[this.selectedChannelObjectKey][id],
            flattenKey,
            resolvedData,
        );

        this.isConflictResolved[fullKey] = resolvedData;
    }

    loadMoreData() {
        if (this.currentPage < this.totalPages) {
            const startIndex = this.currentPage * this.pageSize;
            const endIndex = startIndex + this.pageSize;
            let next;
            if ((this.selectedChannelObjectKey = ChannelObjectKey.GROUPS)) {
                next = this.groupsDiffArray.slice(startIndex, endIndex);
            } else {
                next = this.channelDiffArray.slice(startIndex, endIndex);
            }
            this.chunkedDiffArray = this.chunkedDiffArray.concat(next);

            this.currentPage++;
        }
    }

    onAccept(event: { id: string; resolvedValue: any; operationType: string; side: string }) {
        const { id, operationType, side, resolvedValue } = event;
        this.isConflictResolved[id] = side;
        if (operationType == ChannelOperation.DELETE) {
            if (side == this.left) {
                this.mergeResolvedUpdate[this.selectedChannelObjectKey][id] = resolvedValue;
            } else {
                delete this.mergeResolvedUpdate[this.selectedChannelObjectKey][id];
            }
        } else if (operationType == ChannelOperation.ADD) {
            if (side == this.left) {
                this.mergeResolvedDelete[this.selectedChannelObjectKey][id] = resolvedValue;
            } else {
                delete this.mergeResolvedDelete[this.selectedChannelObjectKey][id];
            }
        }
    }

    async onMergeDone() {
        const done = this.isEveryConfictResolved();
        let msg = '';
        if (!done || Object.values(this.isConflictResolved).length === 0) {
            if (this.pullOrPublish === CloudOperation.PULL) {
                msg =
                    'There are unresolved conflicts. Proceeding will overwrite with the content in the storage.';
            } else {
                msg =
                    'There are unresolved conflicts. Proceeding will push with the content currently in the local.';
            }
            this.showConfirmMsg(msg);
        } else {
            this.showLoadingOverlay();
            const mergeUpdate = {
                channels: Object.values(this.mergeResolvedUpdate.channels) as Channel[],
                groups: Object.values(this.mergeResolvedUpdate.groups) as ChannelGroup[],
            };

            const mergeDelete = {
                channels: Object.values(this.mergeResolvedDelete.channels) as Channel[],
                groups: Object.values(this.mergeResolvedDelete.groups) as ChannelGroup[],
            };

            await this.channelCompareService.updateChannelDiff(
                this.currentProjecData.id,
                this.currentProjecData.VesselID,
                this.currentProjecData.VesselBuilderCompanyID,
                mergeUpdate,
                mergeDelete,
            );
            this.hideLoadingOverlay();
            this.dialogRef.close(true);
        }
    }

    showConfirmMsg(msg: string) {
        let param = new BlobOperationParam();
        param.builderId = this.currentProjecData.VesselBuilderCompanyID;
        param.vesselId = this.currentProjecData.VesselID;
        param.projectId = this.currentProjecData.id;

        this.dialog
            .open(ChannelConfirmDialogComponent, {
                data: {
                    message: msg,
                    confirmedContents: '',
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    if (this.pullOrPublish == CloudOperation.PULL) {
                        this.blobStorageService.blobOperationParamValidate(param);
                        this.blobStorageApiService.pullProjectFromBlob(param);
                    } else {
                        this.blobStorageService.blobOperationParamValidate(param);
                        this.blobStorageApiService.publishProjectFromBlob(param);
                    }

                    this.projectService.load(this.currentProjecData.id);
                    this.dialogRef.close(true);
                }
            });
    }

    isEveryConfictResolved() {
        let mergeDone = true;

        const values = Object.values(this.isConflictResolved);

        for (let i = 0; i < values.length; i++) {
            if (!values[i]) {
                mergeDone = false;
                break;
            }
        }
        return mergeDone;
    }

    showLoadingOverlay() {
        if (!this.isOverlayVisible) {
            this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));
            this.isOverlayVisible = true;
        }
    }

    hideLoadingOverlay() {
        this.overlayRef.detach();
        this.isOverlayVisible = false;
    }
}
