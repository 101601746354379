<div class="search-form">
    <div>
        <div
            class="selector"
            (click)="showGraphs ? (showGraphs = false) : (showGraphs = true)"
        >
            <p class="inside-selector">
                {{ "print-graphs.selector.title" | translate }}
            </p>
            <mat-icon *ngIf="!showGraphs" [ngStyle]="{ marginLeft: 'auto' }"
                >expand_more</mat-icon
            >
            <mat-icon *ngIf="showGraphs" [ngStyle]="{ marginLeft: 'auto' }"
                >expand_less</mat-icon
            >
        </div>
        <div *ngIf="showGraphs && graphList.length > 0" class="graphList">
            <mat-checkbox [(ngModel)]="printAll" (click)="selectAllGraphs()">
                {{ "print-graphs.selector.option.print-all" | translate }}
            </mat-checkbox>
            <div
                *ngFor="let graph of graphList"
                class="graph-name"
                [ngClass]="{ selected: graph.checked }"
                (click)="checkedGraph(graph, false)"
            >
                <mat-checkbox
                    [(ngModel)]="graph.checked"
                    (ngModelChange)="checkedGraph(graph, true)"
                    (click)="$event.stopPropagation()"
                >
                    {{ graph.title }}
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>

<div class="selected-graphs">
    <div *ngFor="let graph of graphsSelected" class="selected-graph">
        <p>
            {{ graph.title }}
        </p>
        <mat-icon class="close-icon" (click)="unselectGraph(graph)"
            >clear</mat-icon
        >
    </div>
</div>
