import { Injectable } from '@angular/core';
import print from 'print-js';

@Injectable()
export class PrintService {
    addMimictoPrint(view, imagesContainer) {
        const svgNS = 'http://www.w3.org/2000/svg';
        const viewName = document.createElementNS(svgNS, 'text');
        viewName.setAttributeNS(null, 'x', '30');
        viewName.setAttributeNS(null, 'y', '30');
        viewName.setAttributeNS(null, 'font-size', '24');
        const imagesDiv = imagesContainer.nativeElement;
        const svgContents = view.svgcontent;
        //Add view name to the svg content
        const newSVG = new DOMParser()
            .parseFromString(svgContents, 'text/xml')
            .getElementsByTagName('svg');
        viewName.innerHTML = view.name;
        const layer = newSVG[0].getElementsByClassName('layer');
        layer[0].append(viewName);
        const changesClass = document.getElementsByClassName(
            'changesClass',
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < changesClass.length; i++) {
            const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            rect.setAttribute('x', changesClass[0].style.left);
            rect.setAttribute('y', changesClass[0].style.top);
            rect.setAttribute('height', changesClass[0].style.height);
            rect.setAttribute('width', changesClass[0].style.width);
            rect.setAttribute('fill', 'none');
            rect.setAttribute('stroke', 'red');
            layer[0].append(rect);
        }
        //Transform the view into a base64 image
        const xml = new XMLSerializer().serializeToString(newSVG[0]);
        const svg64 = btoa(unescape(encodeURIComponent(xml)));
        const b64Start = 'data:image/svg+xml;base64,';
        const image64 = b64Start + svg64;
        //Add the image to a div to print
        const img = document.createElement('img');
        img.src = image64;
        const div = document.createElement('div');
        div.setAttribute('class', 'imgContainer');
        div.append(img);
        imagesDiv.append(div);
    }

    printMimics(imagesContainer) {
        const onFinishedPrinting = () => {
            imagesContainer.nativeElement.innerHTML = '';
        };
        print({
            printable: 'print-mimics',
            type: 'html',
            showModal: true, // Optional
            modalMessage: 'Loading mimics to print', // Optional
            scanStyles: false,
            onPrintDialogClose: onFinishedPrinting,
            style: ' @page { size: landscape; margin:0} img {display: block; border: 0.5px solid black; ; width:100%; height:100%;} .imgContainer{ margin:0 ; width:257mm; height:168mm; padding: 2cm 2cm 2cm 2cm}',
        });
    }
}
