<div class="flex-variable-mapping">
    <!-- <div class="my-form-field" style="min-width: 200px;margin-right: 10px">
        <span>{{'gauges.property-events-mapping-from' | translate}}</span>
        <mat-select class="my-form-field" [compareWith]="compareVariables" [(value)]="value.from" (valueChange)="onValueChange()">
            <mat-option *ngFor="let vr of fromVariables" [value]="vr">{{ vr.variable }}</mat-option>
        </mat-select>
    </div> -->
    <div style="display: inline-block;">
        <flex-variable style="display: block" [data]="data" [(value)]="value.from" (valueChange)="onValueChange()" [allowManualEdit]="true"
         [withStaticValue]="false" [tagTitle]="'gauges.property-tag-internal-title'"></flex-variable>
        <flex-variable style="display: block" [data]="data" [(value)]="value.to" (valueChange)="onValueChange()" [allowManualEdit]="false"
         [withStaticValue]="false"></flex-variable>
    </div>
    <div style="display: inline-block;">
        <div style="display: inline-block; margin-bottom: 2px; width: 12px; border-radius: 2px; height: 45px; 
            border-radius: 0px 3px 3px 0px; border-top: 2px solid #cacaca; border-right: 2px solid #cacaca; border-bottom: 2px solid #cacaca;">
        </div>
    </div>
    <ng-content></ng-content>
</div>