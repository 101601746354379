<!-- basis for this template taken from https://stackblitz.com/edit/ngx-drag-drop-issue-template-9dgxtj?file=src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.component.html -->
<ng-template #elementRef let-element="item">
  <div
    [dndDisableIf]="disableDrag"
    [dndDraggable]="element.nodeName"
    dndEffectAllowed="move"
    (dndMoved)="onDragged(element)"
    class="tree-item"
    [style.backgroundColor]="backgroundColor(element)"
  >
    <div class="wrapper">
      <app-svg-preview
        #preview
        [targetNode]="element"
        [canvas]="canvas"
      ></app-svg-preview>
      <span
        *ngIf="element.id != renameFlag"
        (click)="raiseSelectEvent(element)"
        (contextmenu)="renameElement(element)"
        (dblclick)="renameElement(element)"
        >{{ getDisplayName(element) }}</span
      >
      <input
        id="renameInputBox"
        *ngIf="element.id == renameFlag"
        type="text"
        value="{{ currentName }}"
        (blur)="onBlur(element, newName.value)"
        (keydown)="checkEnterKey($event)"
        onfocus="this.select();"
        autocomplete="off"
        #newName
      />
      <div
        *ngIf="element.id != renameFlag"
        [ngClass]="utils.getVisibleClass(element) + ' hover-box-visible'"
      >
        <img
          style="cursor: pointer"
          (click)="utils.toggleVis(element)"
          [src]="
            utils.isVisible(element)
              ? 'assets/icons/eye-open.svg'
              : 'assets/icons/eye-closed.svg'
          "
        />
      </div>
      <div
        *ngIf="element.id != renameFlag"
        [ngClass]="utils.getLockClass(element) + ' hover-box-locked'"
      >
        <img
          style="cursor: pointer"
          (click)="utils.toggleLocked(element)"
          [src]="
            utils.isLocked(element)
              ? 'assets/icons/locked.svg'
              : 'assets/icons/unlocked.svg'
          "
        />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #groupRef let-group="item">
  <div
    [dndDisableIf]="disableDrag"
    [dndDraggable]="group.nodeName"
    dndEffectAllowed="move"
    (dndMoved)="onDragged(group)"
    class="tree-item"
    [style.backgroundColor]="backgroundColor(group)"
  >
    <div dndDropzone class="group-drop-area" (dndDrop)="onDrop($event, group)">
      <svg
        style="margin: 5px"
        (click)="handleExpand(group)"
        width="20"
        height="20"
        *ngIf="group.visible"
      >
        <path d="m12 16 6.062-6H5.938L12 16z" fill="#D8D8D8" />
      </svg>
      <svg
        style="margin: 5px"
        (click)="handleExpand(group)"
        width="20"
        height="20"
        *ngIf="!group.visible"
      >
        <path d="m16 12-6 6.062V5.938L16 12z" fill="#D8D8D8" />
      </svg>
      <span (click)="raiseSelectEvent(group)">{{ getDisplayName(group) }}</span>

      <div [ngClass]="utils.getVisibleClass(group) + ' hover-box-visible'">
        <img
          style="cursor: pointer"
          (click)="utils.toggleVis(group)"
          [src]="
            utils.isVisible(group)
              ? 'assets/icons/eye-open.svg'
              : 'assets/icons/eye-closed.svg'
          "
        />
      </div>

      <div [ngClass]="utils.getLockClass(group) + ' hover-box-locked'">
        <img
          style="cursor: pointer"
          [ngClass]="utils.getLockClass(group)"
          (click)="utils.toggleLocked(group)"
          [src]="
            utils.isLocked(group)
              ? 'assets/icons/locked.svg'
              : 'assets/icons/unlocked.svg'
          "
        />
      </div>
    </div>

    <ng-container *ngIf="group.visible">
      <ng-container
        *ngTemplateOutlet="
          tree;
          context: { entries: group.childNodes, root: group }
        "
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #tree let-entries="entries" let-root="root">
  <div
    dndDropzone
    [dndDisableIf]="disableDrag"
    (dndDrop)="onDrop($event, root)"
    class="main-area"
  >
    <div dndPlaceholderRef class="placeholder"></div>

    <ng-container *ngIf="entries != undefined">
      <ng-container
        *ngFor="let item of [].slice.call(entries).reverse(); let last = last"
      >
        <ng-container *ngIf="isNodeNameWhitelisted(item)">
          <ng-container *ngIf="item.tagName == 'g'">
            <ng-container
              *ngTemplateOutlet="groupRef; context: { item: item }"
            ></ng-container>
          </ng-container>

          <ng-container *ngIf="item.tagName != 'g'">
            <ng-container
              *ngTemplateOutlet="elementRef; context: { item: item }"
            ></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-container
  *ngTemplateOutlet="
    tree;
    context: { entries: rootNode.childNodes, root: rootNode }
  "
></ng-container>
