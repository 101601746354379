<ng-template [ngIf]="canDisplay">
  <ng-template [ngIf]="!showLandingPage">
    <div id="main-container" class="container">
      <div [ngClass]="getClass()">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-template>
  <div
    *ngIf="showLandingPage"
    class="bg-image"
    [class.iops-bg-image]="env == 'edge'"
    [class.icreate-bg-image]="env == 'cloud'"
  >
    <img
      class="splash-logo"
      [src]="
        env == 'edge'
          ? '/assets/images/iops-logo.webp'
          : '/assets/images/icreate-logo.png'
      "
    />
    <h1 class="title">{{ env == "edge" ? "iOPS" : "iCreate" }}</h1>
    <div class="sign-in">
      <h1>Welcome to {{ env == "edge" ? "iOPS" : "iCreate" }}</h1>
      <p>ご契約済みの方はこちら/ Account holders click here:</p>
      <button class="sign-in-button" (click)="login()">
        サインイン / Sign in
      </button>
      <br />
      <br />
      <br />
      <br />
      <br />
      <img
        style="cursor: pointer"
        (click)="openContactForm()"
        src="/assets/images/contact-us.svg"
      />
      <a href="https://infoceanus.com" target="_blank" rel="noopener noreferrer"
        ><img
          class="sign-in-infoceanus-logo"
          src="/assets/images/infoceanus.webp"
      /></a>
    </div>
  </div>
</ng-template>
