import { ObjectSchema, object, string, number, boolean } from 'yup';
import { locale } from './../yup-locale';
import * as yup from 'yup';
import { OpsUser } from '../../models/ops-users';
yup.setLocale(locale);

export const OpsUserValidator: ObjectSchema<OpsUser> = object({
    id: string().uuid().strict(),
    projectId: string().uuid().nonNullable().strict().required(),
    password: string().min(8).max(64).nonNullable().strict().required(),
    name: string().nonNullable().strict().required(),
    isAdmin: boolean().nonNullable().strict().required(),
    permissions: object().nonNullable().strict().required(),
})
    .required()
    .strict()
    .noUnknown()
    .nonNullable() as ObjectSchema<OpsUser>;

export const OpsPasswordValidator = string().min(8).max(64).nonNullable().strict().required();
