import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SvgComponentSerializationService } from '@service/svg/svg-component-serialization.service';
import { SvgUtilsService } from '@service/svg/svg-utils.service';
import { EditorTemplate } from 'icreate/common/models/editor-templates';

@Component({
    selector: 'app-template-button',
    templateUrl: './template-button.component.html',
    styleUrls: ['./template-button.component.css'],
})
export class TemplateButtonComponent implements AfterViewInit {
    @Input() template: EditorTemplate;
    @Input() previewPadding: number = 5;
    @ViewChild('previewSvg') previewSvg: ElementRef;
    @ViewChild('objectGroup') objectGroup: ElementRef;

    constructor(
        private serializer: SvgComponentSerializationService,
        private svgUtils: SvgUtilsService,
    ) {}

    ngAfterViewInit(): void {
        const components = this.serializer.deserialize(this.template.serializedComponents);
        components.forEach((e) => this.objectGroup.nativeElement.appendChild(e));

        const boundingBox = this.svgUtils.calculateBoundingBox(components);
        const viewportSize = Math.max(boundingBox.width, boundingBox.height) + this.previewPadding;

        const adjustWidth = Math.floor(viewportSize - boundingBox.width) / 2;
        const adjustHeight = Math.floor(viewportSize - boundingBox.height) / 2;

        const transform = this.previewSvg.nativeElement.createSVGTransform();
        transform.setTranslate(adjustWidth, adjustHeight);

        this.objectGroup.nativeElement.transform.baseVal.appendItem(transform);

        this.previewSvg.nativeElement.setAttribute(
            'viewBox',
            `0 0 ${viewportSize} ${viewportSize}`,
        );
    }
}
