<div class="fcu-settings-main">
    <div class="fcu-settings" *ngIf="fcuPages">
        <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="tabPanel">
            <div *ngFor="let fcuPage of fcuPages; index as i">
                <a
                    mat-tab-link
                    (click)="selectPage(fcuPage)"
                    [active]="activePage == fcuPage"
                    style="border-bottom: 1px solid gray"
                >
                    <div
                        style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 5px;
                        "
                    >
                        {{ "FCU " + (i + 1) }}
                        <mat-icon
                            aria-label="Delete"
                            matTooltip="Delete FCU Page"
                            class="delete-icon"
                            (click)="
                                deleteFcuPage(fcuPage); $event.stopPropagation()
                            "
                            >add
                        </mat-icon>
                    </div>
                </a>
            </div>
            <mat-icon
                *ngIf="canAddFcuPage()"
                aria-label="Add"
                matTooltip="Add FCU Page"
                class="add-icon"
                (click)="addFcuPage(); $event.stopPropagation()"
                >add
            </mat-icon>
        </nav>
        <mat-tab-nav-panel #tabPanel class="page-settings-table">
            <app-fu-settings-table
                [fcuSetting]="activePage"
                style="height: 100%; display: flex; flex-direction: column"
            ></app-fu-settings-table>
        </mat-tab-nav-panel>
    </div>
</div>


<app-confirmation-window #deletePage
[mainLabel]="'fu-settings.delete-page-confirmation.title'|translate"
[confirmLabel]="'fu-settings.delete-page-confirmation.confirm'|translate"
[cancelLabel]="'fu-settings.delete-page-confirmation.cancel'|translate"
></app-confirmation-window>
