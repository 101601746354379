<div class="wrapper">
    <h3 mat-dialog-title style="display: inline-block">{{ message }}</h3>
    <br />
    <h3 mat-dialog-title style="display: inline-block" class="confirm-message">
        {{ confirmedContents }}
    </h3>
    <table class="buttons" cellspacing="15">
        <tr>
            <td>
                <button mat-raised-button color="warn" (click)="onOk()">
                    OK
                </button>
            </td>
            <td>
                <button mat-raised-button color="primary" (click)="onCancel()">
                    CANCEL
                </button>
            </td>
        </tr>
    </table>
</div>
