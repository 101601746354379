<div class="permissions-container">
    <div class="title">{{ "ops-settings.users.permissions.title" | translate }}</div>
    <div
        *ngFor="let permission of permissionsList | keyvalue"
        class="permission-container"
    >
        <mat-checkbox
            [(ngModel)]="permission.value"
            (click)="permissionChanged(permission.key)"
            [labelPosition]="'before'"
            [disabled]="isAdmin"
        >
            {{ translationString.concat(".", permission.key) | translate }}
        </mat-checkbox>
    </div>
</div>
