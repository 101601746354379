import { locale } from 'common/validators/yup-locale';
import * as yup from 'yup';
import { ObjectSchema, object, string } from 'yup';
yup.setLocale(locale);

export class ChannelGroup {
    displayName: string;
    categoryId: string;
}

export function GroupValidator(): ObjectSchema<ChannelGroup> {
    return object({
        displayName: string().nonNullable().strict().required(),
        categoryId: string().nonNullable().strict().required(),
    })
        .noUnknown()
        .strict();
}
