import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjectBlobSelectDialogComponent } from '@components/project-blob-select-dialog/project-blob-select-dialog.component';
import { handleExport } from '@helpers/export-utils';
import { Utils } from '@helpers/utils';
import { WindowRef } from '@helpers/windowref';
import { HeaderComponent } from '@layout/header/header.component';
import { GaugeProperty, GaugeSettings, SelElement } from '@models/hmi';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@service/auth.service';
import { AuthorizationErrorService } from '@service/authorization.service';
import { CanvasExtensionsService } from '@service/canvas/canvas-extensions.service';
import { CanvasInputMode, CanvasInputModeService } from '@service/canvas/canvas-input-mode.service';
import { CanvasService } from '@service/canvas/canvas.service';
import { ConnectorExtensionService } from '@service/canvas/connector/connector-extension.service';
import { deletePaths } from '@service/canvas/connector/connector-utils';
import { EyeDropperService } from '@service/canvas/eye-dropper.service';
import { GroupService } from '@service/canvas/group.service';
import { IcreateWindowReferenceService } from '@service/canvas/icreate-window-reference.service';
import {
    KeyboardShortcutHandler,
    KeyboardShortcutService,
} from '@service/canvas/keyboard-shortcut.service';
import { MoveCanvasService } from '@service/canvas/move-canvas.service';
import { MoveElementsService } from '@service/canvas/move-element.service';
import { PasteService } from '@service/canvas/paste.service';
import { SelectedElementsService } from '@service/canvas/selected-elements.service';
import { TemplateInsertService } from '@service/canvas/template-insert.service';
import { UndoManagerService } from '@service/canvas/undoMgr.service';
import { ZoomService } from '@service/canvas/zoom.service';
import { CommentsService } from '@service/comments.service';
import { EditFlgService, EditView } from '@service/editflg.service';
import { LocalStorageService } from '@service/local-storage.service';
import { MimicChangesService } from '@service/mimic-changes.service';
import { PrintService } from '@service/print.service';
import { ProjectService, SaveMode } from '@service/project.service';
import { SocketService } from '@service/socket.service';
import { TemplateService } from '@service/template.service';
import { UrlParserService } from '@service/url-parser.service';
import { ViewGroupsService } from '@service/view-groups.service';
import { ViewService } from '@service/view.service';
import { ViewsService } from '@service/views.service';
import { text2xml, walkTree } from '@svgeditor/utilities.js';
import { Hmi, View, ViewGroup, ViewType } from 'common/models/view';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { BlobOperationParam } from 'common/models/blob-operations';
import { Comment } from 'icreate/common/models/comments';
import { EditorTemplate } from 'icreate/common/models/editor-templates';
import { ProjectData } from 'icreate/common/models/project';
import {
    GaugeProgressComponent,
    GaugeSemaphoreComponent,
    GaugesManager,
    HtmlButtonComponent,
    HtmlInputComponent,
    HtmlSelectComponent,
    ValueComponent,
} from 'ngx-fuxa';
import { ToastrService } from 'ngx-toastr';
import { Subscription, lastValueFrom, take } from 'rxjs';
import { pdf2svg } from 'src/pdf2svg/pdf2svg.js';
import { ConfirmationWindowComponent } from './confirmation-window/confirmation-window.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { DialogNewDocComponent } from './dialog-new-doc/dialog-new-doc.component';
import { DialogNewViewGroupComponent } from './dialog-new-view-group/dialog-new-view-group.component';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { BagPropertyComponent } from './gauges/bag-property/bag-property.component';
import {
    GaugeDialogType,
    GaugePropertyComponent,
} from './gauges/gauge-property/gauge-property.component';
import { HtmlSwitchPropertyComponent } from './gauges/html-switch-property/html-switch-property.component';
import { HierarchyDraggableComponent } from './hierarchy-view/hierarchy-draggable/hierarchy-draggable.component';
import { LineSelectorHelperService } from '@service/canvas/line-selector-helper.service';
import { BlobStorageApiService } from '@service/blob-storage-api.service';

declare let Gauge: any;

declare let $: any;
declare let svgEditor: any;
declare let mypathseg: any; ///< svg-editor component
declare let initPathSeg: any;
declare let mybrowser: any;
declare let initBrowser: any;
declare let mysvgutils: any;
declare let initSvgutils: any;
declare let myselect: any;
declare let initSelect: any;
declare let mydraw: any;
declare let initDraw: any;
declare let mylocal: any;
declare let initLocale: any;
declare let mycontextmenu: any;
declare let initContextmenu: any;
declare let mysvgcanvas: any;
declare let initSvgCanvas: any;
declare let mysvgeditor: any;
declare let initSvgEditor: any;

declare let paper: any;

enum BooleanTypes {
    UNION,
    SUBTRACT,
    INTERSECT,
    EXCLUDE,
    DIVIDE,
}

@Component({
    // It is nesessary to use moduleId.
    // If there are error rerated on module.id,
    // we can remove it.
    moduleId: module.id,
    templateUrl: 'editor.component.html',
    styleUrls: ['editor.component.css'],
})
export class EditorComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('viewFileImportInput') viewFileImportInput: any;
    @ViewChild('cmenuComp') contextmenu: ContextMenuComponent;
    @ViewChild('hierarchyview') hierarchyview: HierarchyDraggableComponent;
    @ViewChild('inputTextHolder') inputTextHolder: ElementRef;
    @ViewChild('workArea', { static: true }) workArea: ElementRef;
    @ViewChild('header') header: HeaderComponent;
    @ViewChild('templateContextMenu') templateContextMenu: ContextMenuComponent;
    @ViewChild('images', { static: true }) images: ElementRef;
    @ViewChild('azureError') azureErrorWindow: ConfirmationWindowComponent;
    @ViewChild('azureError') iAPIError: ConfirmationWindowComponent;
    @ViewChild('deleteView') deleteView: ConfirmationWindowComponent;

    readonly colorDefault = { fill: '#FFFFFF', stroke: '#000000' };
    changeCursor: boolean = false;
    commentCursor: boolean = false;
    comments: Comment[] = [];
    creatingComment: Boolean = false;
    editingComment: Boolean = false;
    newCommentPosition = { top: '', left: '' };
    positionBackground = { top: '', left: '', scale: '' };
    changesInProject = [];
    showChanges: Boolean = false;
    isLoading = true;
    colorFill = this.colorDefault.fill;
    colorStroke = this.colorDefault.stroke;
    currentView: View = null;
    currentProject = new ProjectData();
    hmi: Hmi = new Hmi(); // = {_id: '', name: '', networktype: '', ipaddress: '', maskaddress: '' };
    viewGroups: ViewGroup[] = [];
    imagefile: string;
    selectedElement: SelElement = new SelElement();
    loadingPDFViews: Boolean = false;
    panelsState = {
        enabled: false,
        panelView: true,
        panelHierarchy: true,
        panelGeneral: true,
        panelC: true,
        panelD: true,
        panelS: true,
        panelT: true,
    };
    isInCanvasMoveMode: Boolean = false;

    showChannels = 'hidden';

    panning = false;
    modeBeforePanning = CanvasInputMode.SELECT;
    // favIcon: HTMLLinkElement = document.querySelector('#favicon');

    showElementName = [];
    editingList: Map<string, { editorName: string; editorOid: string }> = new Map();
    editingViews: Map<string, EditView> = new Map();
    private gaugesRef = [];
    currentProjectId: string;
    currentViewInitalSettings: View = null;

    private subscriptionSave: Subscription;
    private subscriptionLoad: Subscription;
    private subscriptionViewLiveUpdate: Subscription;
    private subscriptionViewDelete: Subscription;
    private subscriptionDimensionUpdate: Subscription;
    private subscriptionGetComments: Subscription;
    private subscriptionDeleteComments: Subscription;
    private subscriptionShowChanges: Subscription;
    private subscriptionChangesInMimics: Subscription;
    private authSubscription: Subscription;
    private iAPISubscription: Subscription;

    private activeSubscriptions: Subscription[] = [];
    private activeViewEditing: View;

    protected allTemplates: EditorTemplate[];
    private templateContextMenuTarget: EditorTemplate;
    public comparingViews: boolean;

    public canvas;

    public initComponent: boolean;

    constructor(
        private projectService: ProjectService,
        private winRef: WindowRef,
        public dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
        private translateService: TranslateService,
        private gaugesManager: GaugesManager,
        private viewContainerRef: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        private socketService: SocketService,
        public editFlg: EditFlgService,
        private templateService: TemplateService,
        private toastr: ToastrService,
        private commentService: CommentsService,
        private printService: PrintService,
        private mimicChangesService: MimicChangesService,
        private viewGroupsService: ViewGroupsService,
        private localStorageService: LocalStorageService,
        private keyboardShortcuts: KeyboardShortcutService,
        private windowElements: IcreateWindowReferenceService,
        private canvasService: CanvasService,
        private extensions: CanvasExtensionsService,
        private inputMode: CanvasInputModeService,
        private selectionService: SelectedElementsService,
        private moveCanvas: MoveCanvasService,
        private zoom: ZoomService,
        private group: GroupService,
        private pasteService: PasteService,
        private templateInsert: TemplateInsertService,
        private viewService: ViewService,
        private viewsService: ViewsService,
        private eyeDropper: EyeDropperService,
        private location: Location,
        private urlParserService: UrlParserService,
        private router: Router,
        private blobStorageApiService: BlobStorageApiService,
        private authorizationService: AuthorizationErrorService,
        private authService: AuthService,
        private connectorExtensionService: ConnectorExtensionService,
        private undoManagerService: UndoManagerService,
        private moveElementService: MoveElementsService,
        private lineSelectorHelperService: LineSelectorHelperService,
    ) {
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.handleGaugeAdded = this.handleGaugeAdded.bind(this);
        this.handleCanvasChanged = this.handleCanvasChanged.bind(this);
        this.handleToolChange = this.handleToolChange.bind(this);
        this.handleZoom = this.handleZoom.bind(this);
        this.handleCanvasMoved = this.handleCanvasMoved.bind(this);
        this.handleEditAreaClicked = this.handleEditAreaClicked.bind(this);
        this.handleGaugePasted = this.handleGaugePasted.bind(this);
    }

    //#region Implemented onInit / onAfterInit event
    /**
     * Init Save Project event and clear gauge memory (to manage event signal/gauge)
     */
    async ngOnInit() {
        this.currentProjectId = this.getProjectId();
        if (!this.currentProjectId) {
            await this.router.navigate(['/editor']);
        }
        if (this.currentProjectId) {
            this.socketService.deviceLoad(this.currentProjectId);
        }

        this.initComponent = true;
        this.windowElements.init({
            workArea: this.workArea,
        });

        this.canvasService.init();
        this.canvas = this.canvasService.getCanvas();
        this.extensions.init();

        this.selectionService.init();
        this.moveCanvas.init();
        this.zoom.init();
        this.keyboardShortcuts.init();
        this.moveElementService.init();
        this.lineSelectorHelperService.init();

        this.selectionService.onElementsSelected().subscribe(this.handleSelectionChange);
        this.canvasService.onGaugeAdded().subscribe(this.handleGaugeAdded);
        this.canvasService.onChanged().subscribe(this.handleCanvasChanged);
        this.inputMode.onModeChanged().subscribe(this.handleToolChange);
        this.pasteService.onGaugePasted().subscribe(this.handleGaugePasted);

        this.extensions.installLegacyExtensions();
        this.extensions.install(this.templateInsert);
        this.extensions.install(this.eyeDropper);
        this.extensions.install(this.connectorExtensionService);
        this.initKeyboardShortcuts();

        await this.loadProject(this.currentProjectId);

        try {
            this.subscriptionSave = this.projectService.onSaveCurrent.subscribe(
                (mode: SaveMode) => {
                    if (mode === SaveMode.Current) {
                        this.onSaveProject();
                    } else if (mode === SaveMode.SaveAs) {
                        this.projectService.saveAs(this.currentProject);
                    } else if (mode === SaveMode.Save) {
                        this.onSaveProject();
                    }
                },
            );
            this.gaugesManager.clearMemory();
        } catch (err) {
            console.error(err);
        }

        this.subscriptionViewLiveUpdate = this.socketService
            .updateView$()
            .subscribe(async (v: View) => {
                if (this.currentProject.id == v.projectId) {
                    if (this.currentView.id === v.id) {
                        await this.loadProject(v.projectId);
                    }
                    await this.updateHmi();
                }
            });

        this.subscriptionViewLiveUpdate = this.socketService
            .removeView$()
            .subscribe(async (v: View) => {
                if (this.currentProject.id == v.projectId) {
                    if (this.currentView.id == v.id) {
                        if (
                            this.editFlg.getViewEditingMap().get(v.id).editor.oid !==
                            this.authService.getCurrentUser().oid
                        ) {
                            this.deleteView.setVisible(true);
                            const confirmationWindowResult = await lastValueFrom(
                                this.deleteView.onOptionSelected.pipe(take(1)),
                            );
                            if (confirmationWindowResult) {
                                await this.loadProject(this.getProjectId());
                            }
                        }
                    } else {
                        await this.loadProject(this.getProjectId());
                    }
                    await this.updateHmi();
                    this.editFlg.deleteViewEditingMap(v.id);
                }
            });

        this.subscriptionShowChanges = this.mimicChangesService.showChangesSubject.subscribe(
            (displayChanges: Boolean) => {
                this.showChanges = displayChanges;
            },
        );

        this.subscriptionChangesInMimics =
            this.mimicChangesService.changesInProjectSubject.subscribe((changes) => {
                this.changesInProject = changes;
            });

        this.authSubscription = this.authorizationService.azureAuthorizationError.subscribe(
            async () => {
                this.azureErrorWindow.setVisible(true);
                const confirmationWindowResult = await lastValueFrom(
                    this.azureErrorWindow.onOptionSelected.pipe(take(1)),
                );
                if (confirmationWindowResult) {
                    this.authService.azureLogOut();
                }
            },
        );

        this.iAPISubscription = this.authorizationService.iApiAuthorizationError.subscribe(
            async () => {
                this.iAPIError.setVisible(true);
                const confirmationWindowResult = await lastValueFrom(
                    this.iAPIError.onOptionSelected.pipe(take(1)),
                );
                if (confirmationWindowResult) {
                    this.authService.azureLogOut();
                }
            },
        );

        this.activeSubscriptions.push(
            this.subscriptionSave,
            this.subscriptionDimensionUpdate,
            this.subscriptionViewDelete,
            this.subscriptionViewLiveUpdate,
            this.subscriptionShowChanges,
            this.subscriptionChangesInMimics,
            this.subscriptionGetComments,
            this.subscriptionDeleteComments,
            this.authSubscription,
            this.iAPISubscription,
        );

        this.checkServerData();
        this.viewsService.unlockViewAll(
            this.authService.getCurrentUser().oid,
            this.currentProjectId,
        );
        this.getEditingViews();
    }

    async getEditingViews() {
        let editingViews = await this.viewsService.getLockedViewAll(this.currentProjectId);

        editingViews.forEach((view) => {
            this.editFlg.addViewEditingMap(view.id, view.lockingEditorName, view.userID);
        });

        this.editingViews = new Map(this.editFlg.getViewEditingMap());
    }

    getProjectId() {
        let segments = this.urlParserService.getSegment(this.location.path());
        if (segments.length > 1) {
            return segments[1].path;
        } else {
            return this.localStorageService.getCurrentProjectId();
        }
    }

    /**
     * after init event
     */
    async ngAfterViewInit() {
        // this.changeDetector.detectChanges();

        document.title = 'iCreate';

        paper.setup();

        this.windowElements
            .getEditorContainer()
            .addEventListener('mousedown', this.handleEditAreaClicked);

        this.setMode(CanvasInputMode.SELECT);
        this.header.CanvasReady();
        if (this.currentProjectId) {
            await this.loadHmi();
        }
        this.subscriptionLoad = this.projectService.onLoadHmi.subscribe(
            async (projectId) => {
                this.currentProjectId = projectId;
                this.currentProject = await this.projectService.getProjectData(
                    this.currentProjectId,
                );
                await this.loadHmi();
                this.loadTemplates();
            },
            (error) => {
                console.error('Error loadHMI');
            },
        );

        this.activeSubscriptions.push(this.subscriptionLoad);
        this.viewsService.register(
            this.authService.getCurrentUser().name,
            this.authService.getCurrentUser().oid,
        );

        this.editFlg.setClientData(this.localStorageService.getCurrentViewId());
        if (this.isViewMode()) {
            const layer = document.getElementsByClassName('layer');
            const target = layer[0].children;
            for (let i = 0; i < target.length; i++) {
                target[i].setAttribute('style', 'pointer-events: none');
            }
        }
    }

    ngOnDestroy() {
        this.windowElements
            .getEditorContainer()
            .removeEventListener('mousedown', this.handleEditAreaClicked);

        try {
            for (let i = 0; i < this.activeSubscriptions.length; i++) {
                if (this.activeSubscriptions[i]) {
                    this.activeSubscriptions[i].unsubscribe();
                }
            }
        } catch (e) {
            console.error(e);
        }
        this.onSaveProject();
    }
    //#endregion

    ngAfterContentChecked() {
        this.changeDetector.detectChanges();
    }

    initKeyboardShortcuts() {
        const shortcuts: KeyboardShortcutHandler[] = [
            {
                key: 'arrowright',
                handleKeyDown: () =>
                    this.moveSelectedElements(this.canvas.curConfig.snappingStep, 0),
            },
            {
                key: 'arrowleft',
                handleKeyDown: () =>
                    this.moveSelectedElements(-1 * this.canvas.curConfig.snappingStep, 0),
            },
            {
                key: 'arrowup',
                handleKeyDown: () =>
                    this.moveSelectedElements(0, -1 * this.canvas.curConfig.snappingStep),
            },
            {
                key: 'arrowdown',
                handleKeyDown: () =>
                    this.moveSelectedElements(0, this.canvas.curConfig.snappingStep),
            },
            { key: 'delete', handleKeyDown: () => this.deleteSelected() },
            { key: 'backspace', handleKeyDown: () => this.deleteSelected() },
            { key: 'shift+ctrl+}', handleKeyDown: () => this.moveFront() },
            { key: 'shift+ctrl+{', handleKeyDown: () => this.moveBack() },
            { key: 'shift+}', handleKeyDown: () => this.moveUp() },
            { key: 'shift+{', handleKeyDown: () => this.moveDown() },
            {
                key: 'g',
                handleKeyDown: () => {
                    if (this.selectionService.getSelectedElements().length != 0)
                        this.group.groupElements(this.selectionService.getSelectedElements());
                },
            },
            {
                key: 'u',
                handleKeyDown: () =>
                    this.group.ungroupElements(this.selectionService.getSelectedElements()),
            },
            { key: 'meta+z', handleKeyDown: () => this.onUndo() },
            { key: 'ctrl+z', handleKeyDown: () => this.onUndo() },
            { key: 'meta+y', handleKeyDown: () => this.onRedo() },
            { key: 'ctrl+y', handleKeyDown: () => this.onRedo() },
            {
                key: 'meta+b',
                handleKeyDown: () => this.doBoolean(BooleanTypes.UNION),
            },
            { key: 'meta+arrowup', handleKeyDown: () => this.doZoomIn() },
            { key: 'meta+arrowdown', handleKeyDown: () => this.doZoomOut() },
            { key: 'ctrl+arrowup', handleKeyDown: () => this.doZoomIn() },
            { key: 'ctrl+arrowdown', handleKeyDown: () => this.doZoomOut() },
            {
                key: ' ',
                handleKeyDown: (event: KeyboardEvent) => {
                    if (event.repeat) {
                        return;
                    }

                    this.panning = true;
                    this.modeBeforePanning = this.inputMode.getMode();
                    this.setMode(CanvasInputMode.MOVE_CANVAS);
                },
                keyUpIngoreModifiers: true,
                handleKeyUp: (event: KeyboardEvent) => {
                    this.panning = false;
                    this.canvas.spaceKey = false;

                    if (this.modeBeforePanning == CanvasInputMode.MULTISELECT)
                        this.modeBeforePanning = CanvasInputMode.SELECT;

                    this.setMode(this.modeBeforePanning);

                    event.preventDefault();
                },
            },
            {
                key: 'meta+a',
                handleKeyDown: () => {
                    if (!this.isViewMode()) {
                        this.canvas.selectAllInCurrentLayer();
                    }
                },
            },
            {
                key: 'ctrl+a',
                handleKeyDown: () => {
                    if (!this.isViewMode()) {
                        this.canvas.selectAllInCurrentLayer();
                    }
                },
            },
            {
                key: 'meta+r',
                handleKeyDown: () => {
                    document.getElementById('gaugename')?.focus();
                },
            },
            {
                key: 'ctrl+r',
                handleKeyDown: () => {
                    document.getElementById('gaugename')?.focus();
                },
            },
            {
                key: 'm',
                handleKeyDown: () => {
                    this.setMode(CanvasInputMode.SELECT);
                },
            },
            {
                key: 't',
                handleKeyDown: () => {
                    this.setMode(CanvasInputMode.TEXT);
                },
            },
            {
                key: 'h',
                handleKeyDown: () => {
                    this.setMode(CanvasInputMode.MOVE_CANVAS);
                },
            },
            {
                key: 'c',
                handleKeyDown: () => {
                    this.onShowChannelNo();
                },
            },
            {
                key: 'l',
                handleKeyDown: () => {
                    this.setMode(CanvasInputMode.CONNECTOR);
                },
            },
        ];

        for (let i = 0; i < shortcuts.length; i++) {
            this.keyboardShortcuts.addShortcut(shortcuts[i]);
        }
    }

    async openProjectSelectDialog() {
        const selectedProject = await this.projectService.openDialog();
        if (selectedProject) {
            this.currentProjectId = selectedProject.id;
            this.localStorageService.setCurrentProjectId(this.currentProjectId);
            await this.loadProject(this.currentProjectId);
            this.socketService.deviceLoad(this.currentProjectId);
            this.gaugesManager.clearMemory();
            await this.router.navigate(['/editor', selectedProject.id]);
        }
    }

    handleToolChange(mode: CanvasInputMode) {
        this.commentCursor = false;
        this.changeCursor = false;

        this.isInCanvasMoveMode = mode === CanvasInputMode.MOVE_CANVAS;
        this.changeCursor = this.isInCanvasMoveMode === true;
        this.commentCursor = mode === CanvasInputMode.COMMENT;
    }

    handleSelectionChange(elems) {
        if (!elems.length) {
            this.selectedElement.ele = null;
            return;
        } else if (elems.length > 1) {
            this.selectedElement.id = elems[0].id;
            this.selectedElement.ele = elems;
            this.selectedElement.isMulti = true;
        } else {
            this.selectedElement.id = elems[0].id;
            this.selectedElement.ele = elems[0];
            this.selectedElement.isMulti = false;

            this.selectedElement.type = 'svg-ext-shapes-' + elems[0].tagName;

            if (elems[0].tagName === 'text') {
                this.inputTextHolder.nativeElement.value = elems[0].textContent;
            }

            this.checkColors(this.selectedElement);
            this.checkGaugeInView(this.selectedElement);
        }

        const ga: GaugeSettings = this.getGaugeSettings(elems[0]);
        if (ga?.name === '' && ga?.property === null) {
            ga.name = this.setPropertyName(elems[0]);
            ga.property = new GaugeProperty();
            this.setGaugeSettings(ga);
            this.showElementName.push({ id: ga.id, name: ga.name });
        }

        this.changeDetector.detectChanges();
        this.hierarchyview.forceUpdate();
    }

    handleGaugeAdded(elem) {
        const ga: GaugeSettings = this.getGaugeSettings(elem);
        this.setGaugeSettings(ga);
        this.checkGaugeAdded(ga);
        setTimeout(() => {
            this.setMode(CanvasInputMode.SELECT, false);
        }, 700);
    }

    handleCanvasChanged(elems) {
        if (elems && elems.length > 0 && elems[0].id) {
            const ga: GaugeSettings = this.getGaugeSettings(elems[0]);
            this.gaugesManager.checkElementToResize(ga, this.resolver, this.viewContainerRef, {
                width: elems[0].clientWidth,
                height: elems[0].clientHeight,
            });
        }
        this.changeDetector.detectChanges();
        this.hierarchyview.forceUpdate(elems);
    }

    handleCanvasMoved() {
        this.updatePostionCommentLayer();
    }

    handleEditAreaClicked() {
        if (this.showChanges && !this.isViewMode()) {
            this.showChanges = false;
        }
        const mode = this.inputMode.getMode();
        if (mode === CanvasInputMode.COMMENT) {
            if (this.creatingComment === true) {
                this.creatingComment = false;
            } else {
                this.creatingComment = true;
                const zoom = this.canvas.zoom;
                const positionY = this.canvas.startY * zoom;
                const positionX = this.canvas.startX * zoom;
                const positionTop = positionY.toString() + 'px';
                const positionLeft = positionX.toString() + 'px';
                this.newCommentPosition = {
                    top: positionTop,
                    left: positionLeft,
                };
            }
        }
    }

    handleGaugePasted(elem) {
        const gasrc: GaugeSettings = this.searchGaugeSettings({
            id: elem.attr.id,
            type: elem.attr.type,
        });

        const gadest: GaugeSettings = this.gaugesManager.createSettings(elem.attr.id, gasrc.type);
        gadest.property = JSON.parse(JSON.stringify(gasrc.property));
        this.setGaugeSettings(gadest);
        this.checkGaugeAdded(gadest);
    }

    async loadTemplates() {
        try {
            const templates = await this.templateService.getTemplates(this.currentProjectId);

            this.allTemplates = templates;
        } catch (e) {
            console.error(e);
        }
    }

    setMode(mode: CanvasInputMode | string | any, clearSelection = true) {
        if (clearSelection) {
            this.selectionService.setSelectedElements([]);
        }

        if (this.isViewMode()) {
            this.inputMode.setMode(CanvasInputMode.SELECT);
        } else {
            this.inputMode.setMode(mode);
        }
    }

    onShowChannelNo() {
        this.showChannels === 'hidden'
            ? (this.showChannels = 'visible')
            : (this.showChannels = 'hidden');
        const layer = this.canvas.svgroot.querySelector('.layer');
        const groupElement = layer.getElementsByTagName('g');
        for (let i = 0; i < groupElement.length; i++) {
            const id = groupElement[i].getAttribute('id');
            if (id.startsWith('g-')) {
                const text = groupElement[i].getElementsByTagName('text');
                text[0].setAttribute('visibility', this.showChannels);
            }
        }
    }

    moveSelectedElements = (dx, dy) =>
        this.canvas.moveSelectedElements(dx * this.canvas.getZoom(), dy * this.canvas.getZoom());

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent = (event: KeyboardEvent) => {
        if ((event.target as Element).tagName === 'INPUT') {
            //Use the ESC key to exit text input, otherwise don't process
            if (event.key == 'Escape') {
                this.setMode(CanvasInputMode.SELECT, false);
            }

            return;
        }

        if (event.key.toLowerCase() === 'control' || event.key.toLowerCase() === 'meta') {
            this.canvas.setLinkControlPoints(true);
        }
    };

    @HostListener('window:keyup', ['$event'])
    handleKeyUp = (event: KeyboardEvent) => {
        // Check if the lifted key was ctrl or meta
        if (event.key.toLowerCase() === 'control' || event.key.toLowerCase() === 'meta') {
            this.canvas.setLinkControlPoints(false);
        }
    };

    deleteSelected = () => {
        if (this.inputMode.getMode() == CanvasInputMode.PATH_EDIT) {
            this.canvas.pathActions.deletePathNode();
        } else {
            this.selectionService.getSelectedElements().forEach((element) => {
                deletePaths(element, this.canvas);
                this.onRemoveElement(element);
            });
            this.canvas.deleteSelectedElements();
        }
    };

    //taken from svgedit editor
    //should we prevent default here?
    @HostListener('window:copy', ['$event'])
    copySelected() {
        if (!(this.selectedElement === undefined)) {
            this.canvas.copySelectedElements();
        }
    }

    //should we prevent default here?
    @HostListener('window:paste', ['$event'])
    paste() {
        this.pasteService.paste(JSON.parse(sessionStorage.getItem(this.canvas.getClipboardID())));
    }

    @HostListener('window:beforeunload', ['$event'])
    async beforeUnload() {
        this.onSaveProject();
        const edtior = this.authService.getCurrentUser();
        if (this.currentView) {
            this.viewsService.unlockView(
                this.currentView.id,
                this.currentProjectId,
                edtior.name,
                edtior.oid,
            );
        }
        await this.viewsService.unregister(edtior.oid, this.currentProjectId);
    }

    safeCloneSelectedElements() {
        if (!(this.selectedElement === undefined)) {
            this.canvas.copySelectedElements();
            this.pasteService.paste(
                JSON.parse(sessionStorage.getItem(this.canvas.getClipboardID())),
            );
        }
    }

    writingComment(event) {
        if (event.creatingComment) {
            this.creatingComment = false;
        } else {
            this.editingComment = event;
        }
    }

    getEditorContainer(): HTMLElement {
        return document.getElementById('editorContainer');
    }

    //Change cursor when moveCanvas is active and enters workarea
    mouseEnteredCanvas() {
        return (this.changeCursor = this.isInCanvasMoveMode === true);
    }

    workareaMouseDown = (event: MouseEvent) => {
        this.workArea.nativeElement.focus();
    };

    @HostListener('window:mousedown', ['$event'])
    handleMouseDown = (event: MouseEvent) => {
        if (this.isViewMode()) {
            this.setMode(CanvasInputMode.SELECT);
        }
    };

    @HostListener('wheel', ['$event'])
    handleMouseScroll = (event: WheelEvent) => {
        if (this.panning || event.ctrlKey) {
            const dy = event.deltaY * -1; //(event.ctrlKey ? -1 : 1);
            let nextVal = this.canvas.getZoom() * 100 + dy * this.canvas.getZoom() * 0.5; //zoom faster the more we zoom in
            nextVal = Math.max(50, nextVal);
            nextVal = Math.min(800, nextVal);

            this.onZoomSlider(nextVal, { x: event.clientX, y: event.clientY });
            event.preventDefault();
        }
    };

    OnContextMenu = (event: any) => {
        this.contextmenu.OnContextMenu(event);
    };

    closeCmenu = () => {
        this.contextmenu.CloseContextMenu();
    };

    onTemplateContextMenu = (event: any, template: EditorTemplate) => {
        this.templateContextMenuTarget = template;
        this.templateContextMenu.OnContextMenu(event);
    };

    closeTemplateContextMenu = () => {
        this.templateContextMenu.CloseContextMenu();
    };

    moveFront = () => {
        const multi: boolean = this.selectionService.getSelectedElements().length > 1;
        if (multi) this.canvas.groupSelectedElements();

        this.canvas.moveToTopSelectedElement();

        if (multi) this.canvas.ungroupSelectedElement();
    };
    moveUp = () => {
        const selectedElems = this.selectionService.getSelectedElements();
        selectedElems.forEach((e) => {
            this.canvas.selectOnly([e], false);
            this.canvas.moveUpDownSelected('Up');
        });
        this.canvas.selectOnly(selectedElems, selectedElems.length == 1);
    };
    moveDown = () => {
        const selectedElems = this.selectionService.getSelectedElements();
        selectedElems.reverse();
        selectedElems.forEach((e) => {
            this.canvas.selectOnly([e], false);
            this.canvas.moveUpDownSelected('Down');
        });
        this.canvas.selectOnly(selectedElems, selectedElems.length == 1);
    };
    moveBack = () => {
        const multi: boolean = this.selectionService.getSelectedElements().length > 1;
        if (multi) this.canvas.groupSelectedElements();

        this.canvas.moveToBottomSelectedElement();

        if (multi) this.canvas.ungroupSelectedElement();
    };

    IsSomethingSelected = () => {
        return this.selectionService.getSelectedElements().length > 0;
    };
    AreMultipleSelected = () => {
        return this.selectionService.getSelectedElements().length > 1;
    };
    IsOneThingSelected = () => {
        return this.selectionService.getSelectedElements().length == 1;
    };
    IsPasteAvailable = () => {
        if (this.isViewMode()) {
            return false;
        } else {
            const res = sessionStorage.getItem(this.canvas?.getClipboardID());
            return res != null && res.length > 0;
        }
    };

    HandleCMenuClick = (command: string) => {
        switch (command) {
            case 'copy':
                this.copySelected();
                break;
            case 'paste':
                this.paste();
                break;
            case 'delete':
                this.deleteSelected();
                break;
            case 'moveFront':
                this.moveFront();
                break;
            case 'moveUp':
                this.moveUp();
                break;
            case 'moveDown':
                this.moveDown();
                break;
            case 'moveBack':
                this.moveBack();
                break;
            case 'group':
                this.canvas.groupSelectedElements();
                break;
            case 'ungroup':
                this.canvas.ungroupSelectedElement();
                break;
            case 'union':
                this.doBoolean(BooleanTypes.UNION);
                break;
            case 'subtract':
                this.doBoolean(BooleanTypes.SUBTRACT);
                break;
            case 'intersect':
                this.doBoolean(BooleanTypes.INTERSECT);
                break;
            case 'divide':
                this.doBoolean(BooleanTypes.DIVIDE);
                break;
            case 'exclude':
                this.doBoolean(BooleanTypes.EXCLUDE);
                break;
            case 'export':
                this.openExportDialog();
                break;
            case 'templateCreate':
                this.saveSelectedAsTemplate();
                break;
        }
        this.closeCmenu();
    };

    handleTemplateContextMenuClick = (command: string) => {
        switch (command) {
            case 'delete':
                this.deleteSelectedTemplate();
                break;
        }
        this.closeTemplateContextMenu();
    };

    deleteSelectedTemplate = async () => {
        this.setMode(CanvasInputMode.SELECT);
        this.templateInsert.setTemplate(null);
        const deleteId = this.templateContextMenuTarget.id;

        this.allTemplates.splice(
            this.allTemplates.findIndex((v) => v.id == deleteId),
            1,
        );
        await this.templateService.deleteTemplate(deleteId);
    };

    isSelectedTemplate(template: EditorTemplate) {
        return (
            this.inputMode.getMode() === CanvasInputMode.TEMPLATE_INSERT &&
            this.templateInsert.getSelectedTemplate() === template
        );
    }

    openExportDialog = () => {
        const dialogRef = this.dialog.open(ExportDialogComponent, {
            position: { top: '200px' },
            data: { areElementsSelected: this.IsSomethingSelected() },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                handleExport(result, this.canvas);
            }
        });
    };

    async saveSelectedAsTemplate() {
        try {
            await this.templateService.saveTemplate(
                this.templateService.toTemplate(
                    this.selectionService.getSelectedElements(),
                    this.currentProjectId,
                ),
            );
        } catch (e) {
            console.error(e);
        }

        this.loadTemplates();
    }

    onImportTemplatesClicked = (e) => {
        e.stopPropagation();

        const dialogRef = this.dialog.open(ProjectBlobSelectDialogComponent, {
            data: {
                title: 'Pull Project',
                type: 'PullBlobConfirm',
                projectData: this.currentProject,
            },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            if (Utils.isNullOrUndefined(result)) return;

            const builderId = result.builderId;
            const vesselId = result.vesselId;

            try {
                await this.templateService.importTemplates(
                    this.currentProjectId,
                    builderId,
                    vesselId,
                );

                this.toastr.success('Pull successful!', '', {
                    timeOut: 3000,
                    closeButton: true,
                });
            } catch (e) {
                console.error(e);
                this.toastr.error('Pull error!', '', {
                    timeOut: 3000,
                    closeButton: true,
                });
            }

            this.loadTemplates();
        });
    };

    handleHierarchySelect = (t: any) => {
        if (!this.isViewMode()) {
            this.canvas.clearSelection();
            this.canvas.addToSelection([t], true);
        }
    };

    zoomLevels: Array<number> = [0.5, 1, 1.5, 2, 3, 5, 8];
    displayZoom = 100;

    doZoomIn = () => {
        const newZoom = this.zoomLevels.find((x) => x > this.canvas.getZoom());
        this.zoom.setZoom(newZoom ?? this.canvas.getZoom());
        this.displayZoom = (newZoom ?? this.canvas.getZoom()) * 100;
        this.updatePostionCommentLayer();
    };

    doZoomOut = () => {
        const newZoom = this.zoomLevels
            .slice()
            .reverse()
            .find((x) => x < this.canvas.getZoom());
        this.zoom.setZoom(newZoom ?? this.canvas.getZoom());
        this.displayZoom = (newZoom ?? this.canvas.getZoom()) * 100;
    };

    onZoomSlider(val, newCtr?) {
        const newVal = val / 100;
        this.zoom.setZoom(newVal ?? this.canvas.getZoom(), newCtr);
        this.displayZoom = Math.round(newVal * 100);
        this.canvas.runExtensions('gridUpdate');
        this.updatePostionCommentLayer();
    }

    getDisplayZoom() {
        return this.displayZoom;
    }

    handleZoom(zoom) {
        this.updatePostionCommentLayer();
    }

    updatePostionCommentLayer() {
        const zoom = this.canvas.getZoom();
        const editorContainer = document.getElementById('editorContainer');
        const containerTopPosition = editorContainer.style.top;
        const containerleftPosition = editorContainer.style.left;
        const canvasBackground = document.getElementById('canvasBackground');
        const canvasX = canvasBackground.getAttribute('x');
        const canvasY = canvasBackground.getAttribute('y');
        if (containerTopPosition) {
            const position = containerTopPosition.slice(0, -2);
            const newTopPosition = Number(position) + Number(canvasY);
            this.positionBackground.top = newTopPosition.toString() + 'px';
        }
        if (containerleftPosition) {
            const position = containerleftPosition.slice(0, -2);
            const newLeftPosition = Number(canvasX) + Number(position);
            this.positionBackground.left = newLeftPosition.toString() + 'px';
        } else {
            this.positionBackground.top = Number(canvasY).toString() + 'px';
            this.positionBackground.left = Number(canvasX).toString() + 'px';
        }
        this.positionBackground.scale = zoom;
    }

    getContentLayer = () => {
        return (
            Array.from(this.windowElements.getSvgContentElement()?.children ?? [])?.find((e) =>
                e.classList.contains('layer'),
            ) ?? []
        );
    };
    /**
     * Load the hmi resource and bind it
     */
    private async loadHmi(loadCount = 0) {
        // this.currentView = null;
        loadCount = loadCount + 1;
        try {
            this.currentProject = await this.projectService.getProjectData(this.currentProjectId);
            this.hmi = this.currentProject.hmi;
            // check new hmi
            if (!this.hmi.views || this.hmi.views.length <= 0) {
                this.hmi.views = [];
                this.addView();
            } else {
                let oldsel = this.localStorageService.getCurrentViewId();
                let eixst = this.hmi.views.find((view: View) => view.id === oldsel);
                if (!oldsel || !eixst) {
                    oldsel = this.hmi.views[0].id;
                }

                let foundOld = false;
                for (let i = 0; i < this.hmi.views.length; i++) {
                    if (this.hmi.views[i].id === oldsel) {
                        await this.onSelectView(this.hmi.views[i]);
                        foundOld = true;
                        break;
                    }
                }
                if (!foundOld) await this.onSelectView(this.hmi.views[0]);
            }
            this.loadPanelState();
            this.viewGroups = await this.viewGroupsService.getAll(this.currentProjectId);
            const lockedViews = await this.viewsService.lockedViews(this.currentProjectId);
            lockedViews.forEach((view) => {
                this.editFlg.getEditorNameFromServer(view);
                this.editFlg.addViewEditingMap(view.ViewID, view.EditorName, view.EditorOID);
            });
            this.isLoading = false;
        } catch (error) {
            if (error.status === 404) {
                if (loadCount > 0) {
                    await this.openProjectSelectDialog();
                } else {
                    this.router.navigate(['/editor']);
                    if (this.localStorageService.getCurrentProjectId()) {
                        await this.loadProject(this.getProjectId());
                    }
                }
            }
        }
    }

    public async updateHmi() {
        this.currentProject = await this.projectService.getProjectData(this.currentProjectId);
        this.hmi = this.currentProject.hmi;
    }

    public onSaveView(view: View) {
        this.saveView(view, true);
    }

    /**
     * Set or Add the View to Project
     * Save the View to Server
     */
    private saveView(view: View, notify: boolean = false, force: boolean = false) {
        // Only save if we have edit access (or force is true - used when the view is first created)
        if (!(this.editFlg.checkEditMode(view) || force)) {
            return;
        }
        // We hope this never has any effect
        // this.ensureViewResolutionMatch(view);
        if (this.currentProject.id === view.projectId) {
            this.projectService.setView(this.currentProject, view, notify);
        }
    }

    /**
     * Remove the View from Project
     * Remove the View from Server
     * @param view
     */
    private removeView(view: View) {
        this.projectService.removeView(this.currentProjectId, view);
    }

    private getContent() {
        this.canvas.leaveContext();
        return this.canvas.getSvgString();
    }

    /**
     * get gauge settings from current view items, if not exist create void settings from GaugesManager
     * @param ele gauge id
     */
    private getGaugeSettings(ele) {
        if (ele && this.currentView) {
            if (this.currentView.items[ele.id]) {
                return this.currentView.items[ele.id];
            }
            return this.gaugesManager.createSettings(this.currentView.items[ele.id], ele.type);
        }
        return null;
    }

    /**
     * search gauge settings on all views items, if not exist create void settings from GaugesManager
     * @param ele gauge element
     */
    private searchGaugeSettings(ele) {
        if (ele) {
            if (this.currentView) {
                if (this.currentView.items[ele.id]) {
                    return this.currentView.items[ele.id];
                }
            }
            for (let i = 0; i < this.hmi.views.length; i++) {
                if (this.hmi.views[i].items[ele.id]) {
                    return this.hmi.views[i].items[ele.id];
                }
            }
            return this.gaugesManager.createSettings(this.currentView.items[ele.id], ele.type);
        }
        return null;
    }

    /**
     * add the gauge settings to the current view items list
     * @param ga GaugeSettings
     */
    private setGaugeSettings(ga) {
        if (ga) {
            this.currentView.items[ga.id] = ga;
        }
    }

    /**
     * check the gauge in current view of element
     * @param ele element to check
     */
    private checkGaugeInView(ele) {
        const g = this.getGaugeSettings(ele);
        if (!g) {
        } else {
            this.selectedElement.type = g.type;
        }
    }

    /**
     * check and set the color panel with selected element
     * @param ele selected element
     */
    private checkColors(ele) {
        const eles = this.canvas.selectedElements;
        let clrfill = null;
        let clrstroke = null;
        if (eles && (eles.length <= 1 || !eles[1]) && eles[0]) {
            // check for gauge fill and stroke color
            const colors = { fill: clrfill, stroke: clrstroke };
            if (GaugesManager.checkGaugeColor(ele, eles, colors)) {
                if (colors.fill) {
                    this.colorFill = colors.fill;
                }
                if (colors.stroke) {
                    this.colorStroke = colors.stroke;
                }
            } else {
                if (eles[0].attributes['fill']) {
                    clrfill = eles[0].attributes['fill'].value;
                    this.colorFill = clrfill;
                }
                if (eles[0].attributes['stroke']) {
                    clrstroke = eles[0].attributes['stroke'].value;
                    this.colorStroke = clrstroke;
                }
                // this.setFillColor(this.colorFill);
            }
        }
    }

    private fixForeignObjects(elem: Element) {
        const self = this;
        if (elem.nodeName.toLowerCase() == 'foreignobject') {
            elem.innerHTML = elem.innerHTML;
        } else {
            elem.childNodes.forEach(function (child) {
                self.fixForeignObjects(<Element>child);
            });
        }
    }

    private async loadProject(projectId: string) {
        try {
            this.projectService.load(projectId);
        } catch (err) {
            await this.openProjectSelectDialog();
        }
    }

    /**
     * load the view to svg-editor and canvas
     * @param view view to load
     */
    private loadView(view: View) {
        const oldResolution = this.canvas?.getResolution();
        if (view) {
            this.clearEditor();
            let svgcontent: string = '';
            const v = this.getView(view.id);
            this.canvas.setResolution((v.profile.width - 50) * 0.8, (v.profile.height - 65) * 0.8);
            this.changeDetector.detectChanges();
            this.showElementName = [];
            if (v) {
                svgcontent = v.svgcontent;
            }
            if (svgcontent == '') {
                svgcontent = this.canvasService.getEmptySvg(view);
            }
            if (this.canvas) {
                //TODO is this important? -> this.canvas.setDocProperty(view.name, view.profile.width, view.profile.height, view.profile.bkcolor);
                this.canvas.setSvgString(svgcontent);
                const svgroot = this.canvas.svgroot;
                this.fixForeignObjects(svgroot);

                if (!document.getElementById('canvasBackground') && svgroot) {
                    document.getElementById('editorContainer').append(svgroot);
                    if (document.getElementById('canvasBackground')) {
                        document
                            .getElementById('canvasBackground')
                            .children[0].setAttribute('fill', view.profile.bkcolor);
                    } else {
                        return;
                    }
                } else {
                    document
                        .getElementById('canvasBackground')
                        .children[0].setAttribute('fill', view.profile.bkcolor);
                }
            }

            // check gauge to init
            this.gaugesRef = [];
            for (const key in v.items) {
                const ga: GaugeSettings = this.getGaugeSettings(v.items[key]);
                this.checkGaugeAdded(ga);
                this.showElementName.push({ id: ga.id, name: ga.name });
                this.showElementName = Array.from(new Set(this.showElementName));
            }

            let sleft = this.workArea.nativeElement.scrollLeft;
            let stop = this.workArea.nativeElement.scrollTop;

            //TODO reimplement this -> this.canvas.refreshCanvas();
            this.zoom.setZoom(this.zoom.getZoom()); //Updates canvas size to content

            if (oldResolution != undefined) {
                if (oldResolution.w != 0) {
                    // Remain in same position whens switching view, even if resolution changes
                    const difw = oldResolution.w - view.profile.width;
                    const difh = oldResolution.h - view.profile.height;
                    sleft -= difw;
                    stop -= difh;
                } else {
                    // Center on screen when loading view at project start
                    sleft = (view.profile.width * 2 - this.workArea.nativeElement.clientWidth) / 2;
                    stop = (view.profile.height * 2 - this.workArea.nativeElement.clientHeight) / 2;
                }
            }

            this.workArea.nativeElement.scrollLeft = sleft;
            this.workArea.nativeElement.scrollTop = stop;

            this.canvas.undoMgr.resetUndoStack();
        }

        //align center
        const canvasBg = document.getElementById('canvasBackground');
        canvasBg.scrollIntoView();
        canvasBg.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        });
    }

    /**
     * get view from hmi views list
     * @param name view name
     */
    private getView(id) {
        for (let i = 0; i < this.hmi.views.length; i++) {
            if (this.hmi.views[i].id === id) {
                return this.hmi.views[i];
            }
        }
        return null;
    }

    getViewGroups() {
        return this.viewGroups.sort((a, b) => {
            if (a.groupName === b.groupName) {
                return a.id > b.id ? 1 : -1;
            } else if (a.groupName > b.groupName) {
                return 1;
            }
            return -1;
        });
    }

    getViewsSorted() {
        return this.hmi.views.sort((a, b) => {
            if (a.name === b.name) {
                return a.id > b.id ? 1 : -1;
            } else if (a.name > b.name) {
                return 1;
            }
            return -1;
        });
    }
    //#endregion

    //#region Svg-editor event and function interface

    selectTemplate(template: EditorTemplate) {
        this.templateInsert.setTemplate(template);
        this.setMode(CanvasInputMode.TEMPLATE_INSERT);
    }

    /**
     * check with the current mode
     * @param mode mode to check
     */
    isModeActive(mode) {
        return this.inputMode.getMode() === mode;
    }

    /**
     * clear svg-editor and the canvas
     */
    private clearEditor() {
        if (this.winRef.nativeWindow.svgEditor) {
            this.winRef.nativeWindow.svgEditor.clickClearAll();
        }
    }

    /**
     * event from svg-editor: svg element removed
     * @param ele svg element
     */

    private onRemoveElement(ele: any) {
        if (this.currentView && this.currentView.items && ele) {
            //for (let i = 0; i < ele.length; i++) {
            if (this.currentView.items[ele.id]) {
                delete this.currentView.items[ele.id];
                if (this.gaugesRef.indexOf(ele.id) != -1) {
                    if (this.gaugesRef[ele.id].ref && this.gaugesRef[ele.id].ref['ngOnDestroy']) {
                        try {
                            this.gaugesRef[ele.id].ref['ngOnDestroy']();
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
            }
            //}
        }
    }

    setCurrentText(event) {
        this.canvas.setTextContent(event.currentTarget.value);
    }

    /**
     * add image to view
     * @param event selected file
     */
    onSetImage(event) {
        if (event.target.files) {
            this.imagefile = 'assets/images/' + event.target.files[0].name;
            const self = this;
            if (this.imagefile.split('.').pop().toLowerCase() === 'svg') {
                const reader = new FileReader();
                reader.onloadend = function (e: any) {
                    self.importSvgString(e.target.result);
                };
                reader.readAsText(event.target.files[0]);
            } else {
                this.getBase64Image(event.target.files[0], function (imgdata) {
                    if (self.canvas.setGoodImage) {
                        self.canvas.setGoodImage(imgdata);

                        const image = new Image();
                        image.src = imgdata;

                        image.onload = function () {
                            let w = image.width;
                            let h = image.height;
                            if (image.width > self.canvas.getWidth()) {
                                const ratio = self.canvas.getWidth() / w;
                                w = w * ratio;
                                h = h * ratio;
                            }
                            if (h > self.canvas.getHeight()) {
                                const ratio = self.canvas.getHeight() / h;
                                w = w * ratio;
                                h = h * ratio;
                            }

                            const x = self.canvas.getWidth() / 2 - w / 2;
                            const y = self.canvas.getHeight() / 2 - h / 2;

                            const newImage = self.canvas.addSVGElementsFromJson({
                                element: 'image',
                                attr: {
                                    x: x + 'px',
                                    y: y + 'px',
                                    width: w + 'px',
                                    height: h + 'px',
                                    id: self.canvas.getNextId(),
                                    opacity: 1,
                                    style: 'pointer-events:inherit',
                                },
                            });
                            self.canvas.setHref(newImage, self.canvas.getLastGoodImgUrl());
                            //self.canvas.preventClickDefault(newImage)
                        };
                    }
                });
            }
        }
    }

    importSvgString = (data: string) => {
        let elementID;
        // Convert to nodes
        const nodes = text2xml(data);
        // Sanitize (fixes paths from relative -> absolute)
        this.canvas.prepareSvg(nodes);

        // Adopt, skip top level node
        const adopted = document.adoptNode(nodes.children[0]);

        // Fix IDs
        this.canvas.uniquifyElems(adopted);

        // Get our root content to attach these new nodes to (bad way of doing this)
        let root = this.canvas.getSvgContent().children[1];

        // Give ids to elements that dont have any
        walkTree(adopted, (el) => {
            if (el.id == '') el.id = this.canvas.getNextId();
            elementID = el.id;
        });

        // Wrap in a group if multiple elements
        if (adopted.children.length > 1) {
            const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            group.setAttribute('id', this.canvas.getNextId());
            root.appendChild(group);
            root = group;
        }

        // Add nodes
        for (let i = adopted.children.length - 1; i >= 0; i--) {
            root.prepend(adopted.children[i]);
        }

        if (root.tagName === 'g') {
            const bbox = root.getBBox();
            root.setAttribute('x', bbox.x);
            root.setAttribute('y', bbox.y);
        }

        // Unsure if necessary, but safe
        adopted.remove();
        return elementID;
    };

    @HostListener('window:dragover', ['$event'])
    handleWindowDrag(event: DragEvent) {
        event.preventDefault();
    }

    @HostListener('window:drop', ['$event'])
    handleWindowDrop(event: DragEvent) {
        event.preventDefault();

        if (this.isViewMode()) return;

        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind === 'file') {
                    const file = event.dataTransfer.items[i].getAsFile();
                    // Make a fake event so we can pass this directly to our existing image import code
                    const ev = { target: { files: [file] } };
                    if (i === 0) {
                        this.onSetImage(ev);
                    } else {
                        setTimeout(() => this.onSetImage(ev), i * 100);
                    }
                }
            }
        }
    }

    /**
     * convert image file to code to attach in svg
     * @param file image file
     * @param callback event for end load image
     */
    private getBase64Image(file, callback) {
        const fr = new FileReader();
        fr.onload = function () {
            callback(fr.result);
        };
        fr.readAsDataURL(file);
    }

    onUndo() {
        this.undoManagerService.handleUndo();
    }
    onRedo() {
        this.undoManagerService.handleRedo();
    }

    Booleanables = ['rect', 'circle', 'ellipse', 'path'];
    // Check if we should show the boolean option in context menu and header
    isBooleanValid(): boolean {
        const elems = this.canvas?.getSelectedElements();
        if (!elems) return false;

        if (elems.length != 2) return false;

        for (const element of elems) {
            const tag = element.tagName.toLowerCase();
            if (!this.Booleanables.includes(tag)) return false;
        }

        return true;
    }

    doBoolean(type: BooleanTypes) {
        const group = new paper.Group();
        const elems = this.selectionService.getSelectedElements();

        // Import selected elements to paperjs
        elems.forEach((element) => {
            group.importSVG(element);
        });

        // Convert paperjs items to boolean-able objects
        const paths = new paper.Group();
        group.children.forEach((item) => {
            if (item.unite) paths.addChild(item.clone());
            else paths.addChild(item.toPath());
        });

        const a = paths.children[1];
        const b = paths.children[0];

        // Do boolean
        let out;
        switch (type) {
            case BooleanTypes.UNION:
                out = a.unite(b);
                break;
            case BooleanTypes.SUBTRACT:
                out = a.subtract(b);
                break;
            case BooleanTypes.INTERSECT:
                out = a.intersect(b);
                break;
            case BooleanTypes.DIVIDE:
                out = a.divide(b);
                break;
            case BooleanTypes.EXCLUDE:
                out = a.exclude(b);
                break;
        }

        // Clear paperjs canvas, remove elements
        group.remove();
        paths.remove();
        this.deleteSelected();

        // Remove the last undo command (deleting elements) from the stack because we want to add to it
        const { undoMgr } = this.canvas;
        const batchCmd = undoMgr.undoStack.pop();

        // Add elements back to our canvas
        this.exportPaperItemToSVG(out, batchCmd);

        // Add our "remove elements -> add boolean element" batch command
        this.canvas.addCommandToHistory(batchCmd);
    }

    exportPaperItemToSVG(item, batchCmd) {
        const out = item.exportSVG();

        // Have to convert the path or svgedit will break
        const convertedPath = this.canvas.pathActions.convertPath(out);
        out.setAttribute('d', convertedPath);
        this.canvas.pathActions.fixEnd(out);

        this.canvas.getDrawing().getCurrentLayer().appendChild(out);

        // batchCmd.addSubCommand(new InsertElementCommand(out));
    }

    /**
     * check and set the special gauge like ngx-gauge, ... if added
     * if return true then the GaugeSettings is changed have to set again
     * @param ga
     */
    checkGaugeAdded(ga: GaugeSettings) {
        const gauge = this.gaugesManager.initElementAdded(
            ga,
            this.resolver,
            this.viewContainerRef,
            false,
        );
        if (gauge) {
            if (gauge !== true) {
                if (this.gaugesRef.indexOf(ga.id) === -1) {
                    this.gaugesRef[ga.id] = { type: ga.type, ref: gauge };
                }
            }
            this.setGaugeSettings(ga);
        }
    }

    //#endregion

    //#region Toolbar Top Events
    /**
     * save current project and launch the Test in new Windows 'lab'
     */
    async onStartCurrent(view) {
        const editor = this.authService.getCurrentUser();
        this.onSaveProject();
        await this.viewsService.unlockView(view.id, this.currentProjectId, editor.name, editor.oid);
        this.FinishEdit();
        this.winRef.nativeWindow.open(
            `lab/${this.currentProjectId};view=${this.currentView.id}`,
            'MyTest',
            'width=1920,height=1080,menubar=0',
        );
    }
    //#endregion

    //#region Project Events
    /**
     * Save Project
     * Save the current View
     */
    onSaveProject() {
        if (this.currentView) {
            this.currentView.svgcontent = this.getContent();
            this.saveView(this.currentView, true);
        }
    }

    //#endregion

    //#region View Events (Add/Rename/Delete/...)
    onAddDoc() {
        const exist = this.hmi.views.map((v) => {
            return v.name;
        });
        const dialogRef = this.dialog.open(DialogNewDocComponent, {
            position: { top: '60px' },
            data: {
                name: '',
                type: Utils.getEnumKey(ViewType, ViewType.svg),
                exist: exist,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.name && result.type) {
                this.addView(result.name, result.type);
            }
        });
    }

    onAddViewGroup() {
        const dialogRef = this.dialog.open(DialogNewViewGroupComponent, {
            position: { top: '60px' },
            data: {
                name: '',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.name) {
                this.createViewGroup(result.name);
            }
        });
    }

    /**
     * Create View group in Project with the given name
     */
    async createViewGroup(groupName) {
        const newGroup: ViewGroup = await this.viewGroupsService.create(
            groupName,
            this.currentProjectId,
        );
        if (newGroup) {
            this.viewGroups = [...this.viewGroups, newGroup];
        }
    }

    /**
     * Add View to Project with a default name View_[x]
     */
    addView(name?: string, type?: ViewType) {
        if (this.hmi.views) {
            const nn = 'View_';
            let idx = 1;
            for (idx = 1; idx < this.hmi.views.length + 2; idx++) {
                let found = false;
                for (let i = 0; i < this.hmi.views.length; i++) {
                    if (this.hmi.views[i].name === nn + idx) {
                        found = true;
                        break;
                    }
                }
                if (!found) break;
            }
            const v = new View();
            v.type = type;
            if (name) {
                v.name = name;
            } else if (this.hmi.views.length <= 0) {
                v.name = 'MainView';
            } else {
                v.name = nn + idx;
            }
            v.svgcontent = this.canvasService.getEmptySvg(v);
            v.profile.bkcolor = '#ffffffff';
            v.projectId = this.currentProjectId;
            v.id = Utils.getGUID();
            this.hmi.views.push(v);
            this.onSelectView(v);
            this.saveView(v, true, true);
        }
    }

    /**
     * Clone the View, copy and change all ids
     * @param view
     */
    onCloneView(view: any) {
        const newView = {};
        if (view) {
            const nn = 'View_';
            let idx = 1;
            for (idx = 1; idx < this.hmi.views.length + 2; idx++) {
                let found = false;
                for (let i = 0; i < this.hmi.views.length; i++) {
                    if (this.hmi.views[i].name === nn + idx) {
                        found = true;
                        break;
                    }
                }
                if (!found) break;
            }

            const { renamedId, newItems } = this.viewService.renameSvgItemId(view);
            const v = this.viewService.replaceSvgContentId(renamedId, view, newItems);
            v.id = Utils.getGUID();
            v.name = nn + idx;
            this.hmi.views.push(v);
            this.onSelectView(v);
            this.projectService.setView(this.currentProject, v, false);
        }
    }

    /**
     * select the view, save current vieww before
     * @param view selected view to load resource
     */
    public async onSelectView(view) {
        if (this.currentView) {
            this.currentView.svgcontent = this.getContent();
            this.currentView.items;
            // this.hmi.views[this.currentView].svgcontent = this.winRef.nativeWindow.svgEditor.getSvgString();
        }
        if (this.currentView && this.currentView.id !== view.id) {
            this.saveView(this.currentView);
        }

        this.currentView = view;

        this.localStorageService.setCurrentViewId(this.currentView.id);
        this.editFlg.getEditorName(this.currentView.id);
        this.editFlg.setClientData(this.currentView.id);
        if (this.editFlg.checkEditMode(this.currentView)) {
            this.hierarchyview.unlockDnd();
        } else {
            this.hierarchyview.lockDnd();
        }
        this.loadView(this.currentView);

        const commentsArray = await this.commentService.getAllByViewId(this.currentView.id);
        this.comments = commentsArray;
        this.updatePostionCommentLayer();

        this.subscribeComments(this.currentView.id);
    }

    subscribeComments(viewID) {
        if (this.subscriptionGetComments) {
            this.subscriptionGetComments.unsubscribe();
            this.subscriptionDeleteComments.unsubscribe();
        }

        this.subscriptionGetComments = this.commentService.getNew().subscribe((data: Comment) => {
            if (data.viewId == viewID) {
                this.comments.forEach((comment, i) => {
                    if (data.id == comment.id) {
                        this.comments.splice(i, 1);
                    }
                });
                this.comments.push(data);
            }
        });

        this.subscriptionDeleteComments = this.commentService
            .deleteCommentsSocket()
            .subscribe((data) => {
                this.comments.forEach((comment, i) => {
                    if (data == comment.id) {
                        this.comments.splice(i, 1);
                    }
                });
            });
    }

    /**
     * check with the current view
     * @param view view to check
     */
    isViewActive(view) {
        return this.currentView && this.currentView.id === view.id;
    }

    /**
     * Import view from file (exported in json format MyView.fuxav)
     */
    onImportView() {
        const ele = document.getElementById('viewFileUpload');
        ele.click();
    }

    /**
     * Import view from file (exported in json format MyView.fuxav)
     */
    onImportPDF() {
        const ele = document.getElementById('viewPDFUpload');
        ele.click();
    }

    /**
     * Import views from PDF file
     */
    async onSetPDF(event) {
        this.loadingPDFViews = true;
        const fileToLoad = event.target.files[0];
        const fileName = fileToLoad.name;
        const fileReader = new FileReader();
        let base64File;
        const self = this;
        fileReader.onload = async function (event) {
            base64File = event.target.result;
            const PDFsToSVGs = await pdf2svg(base64File);
            PDFsToSVGs.forEach((pdfPage, i) => {
                self.addView(fileName.slice(0, -4) + ' ' + pdfPage[1]);
                self.importSvgString(pdfPage[0]);
            });

            const newRoot = self.canvas.getSvgContent();
            const s = new XMLSerializer();
            const groupString = s.serializeToString(newRoot);
            self.currentView.svgcontent = groupString;
            self.saveView(self.currentView, false, true);

            const hmi = self.currentProject.hmi;
            hmi.views.forEach((view) => {
                self.projectService.setView(self.currentProject, view, false);
            });
            self.loadingPDFViews = false;
        };

        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
    }

    /**
     * open Project event file loaded
     * @param event file resource
     */
    onViewFileChangeListener(event) {
        const text = [];
        const files = event.srcElement.files;
        const input = event.target;
        const reader = new FileReader();
        reader.onload = (data) => {
            const view = JSON.parse(reader.result.toString());
            if (view) {
                let idx = 1;
                const startname = view.name;
                let existView = null;
                while ((existView = this.hmi.views.find((v) => v.name === view.name))) {
                    view.name = startname + '_' + idx++;
                }
                view.id = Utils.getGUID();
                this.hmi.views.push(view);
                this.onSelectView(view);
                this.saveView(this.currentView);
            }
        };

        reader.onerror = function () {
            const msg = 'Unable to read ' + input.files[0];
            // this.translateService.get('msg.project-load-error', {value: input.files[0]}).subscribe((txt: string) => { msg = txt });
            alert(msg);
        };
        reader.readAsText(input.files[0]);
        this.viewFileImportInput.nativeElement.value = null;
    }
    //#endregion

    //temporary button for ReadOnlyMode
    async onEdit(view?: View) {
        const v = view ? view : this.currentView;
        const viewID = v.id;
        const currentUser = this.authService.getCurrentUser();

        let project = await this.projectService.getProjectData(this.currentProjectId);

        //If the user is editing a view and moves to another view, clicking the edit button should end the editing of the previous view.
        project.hmi.views.forEach(async (view) => {
            if (view.id !== v.id && view.locked && view.userID == currentUser.oid) {
                await this.viewsService.unlockView(
                    view.id,
                    this.currentProjectId,
                    currentUser.name,
                    currentUser.oid,
                );
            }
        });

        if (this.isViewMode()) {
            await this.viewsService.lockView(
                viewID,
                currentUser.name,
                currentUser.oid,
                this.currentProjectId,
            );
            this.activeViewEditing = v;
            this.currentViewInitalSettings = _.cloneDeep(v);
            setTimeout(() => {
                this.onSelectView(v);
            }, 100);
            this.activeViewEditing = v;
        } else {
            await this.viewsService.unlockView(
                viewID,
                this.currentProjectId,
                currentUser.name,
                currentUser.oid,
            );
            this.FinishEdit();
        }
    }

    async onFinishEdit(item: View, showMsg = true) {
        const editor = this.authService.getCurrentUser();

        item.svgcontent = this.canvas.getSvgString();
        this.projectService.setView(this.currentProject, item, showMsg);
        await this.viewsService.unlockView(item.id, this.currentProjectId, editor.name, editor.oid);
        this.FinishEdit();
    }

    compareViewsEvent() {
        this.mimicChangesService.toggleShowChanges();
        if (!this.isViewMode()) {
            this.onEdit(this.currentView);
        }
        const inActionView = this.currentView;
        const hmi = this.currentProject.hmi;
        const activeViews = hmi.views;
        const dialogRef = this.dialog.open(ProjectBlobSelectDialogComponent, {
            data: {
                title: 'Compare Project',
                type: 'CompareMimics',
                projectData: this.currentProject,
            },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            if (Utils.isNullOrUndefined(result)) return;
            const param = new BlobOperationParam();
            param.builderId = result.builderId;
            param.vesselId = result.vesselId;
            param.projectId = this.currentProjectId;
            const oldBlob = await this.blobStorageApiService.getProjectBlob(param);
            if (oldBlob.views) {
                activeViews.forEach((view) => {
                    this.onSelectView(view);
                    this.mimicChangesService.compareViewsEvent(
                        this.currentView,
                        oldBlob,
                        this.canvas,
                    );
                });
            }
            this.onSelectView(inActionView);
        });
    }

    FinishEdit() {
        this.isInCanvasMoveMode = false;
        this.changeCursor = false;
        this.editFlg.currentEditor = '';
        this.activeViewEditing = undefined;
        this.currentViewInitalSettings = null;
    }

    private checkServerData() {
        const editDataSubscription = this.socketService.viewLocked$().subscribe({
            next: (data: { ViewID: string; EditorName: string; EditorOID: string }) => {
                this.editFlg.addViewEditingMap(data.ViewID, data.EditorName, data.EditorOID);
                this.editingViews = new Map(this.editFlg.getViewEditingMap());
            },
            error: (e) => {
                console.log(e);
            },
        });

        const deleteDataSubscription = this.socketService.viewUnlocked$().subscribe({
            next: async (data) => {
                this.editFlg.deleteViewEditingMap(data.ViewID);
                this.editingViews = new Map(this.editFlg.getViewEditingMap());
                const layer = document.getElementsByClassName('layer');
                const target = layer[0].children;
                for (let i = 0; i < target.length; i++) {
                    target[i].setAttribute('style', 'pointer-events: none');
                }
            },
            error: (e) => {
                console.log(e);
            },
        });

        const viewUnlockedAllSubscription = this.socketService.viewUnlockedAll$().subscribe({
            next: async (data) => {
                this.editFlg.deleteAllEditingMap(data.EditorOID);
                this.editingViews = new Map(this.editFlg.getViewEditingMap());
            },
            error: (e) => {
                console.log(e);
            },
        });

        this.activeSubscriptions.push(
            editDataSubscription,
            deleteDataSubscription,
            viewUnlockedAllSubscription,
        );
    }

    //#region Panels State
    /**
     * Load the left panels state copied in localstorage
     */
    private loadPanelState() {
        const ps = this.localStorageService.getPanelsState();
        this.panelsState.enabled = true;
        if (ps) {
            this.panelsState = JSON.parse(ps);
        }
    }

    /**
     * Save the panels state in localstorage (after every toggled)
     */
    savePanelState() {
        if (this.panelsState.enabled) {
            this.localStorageService.setPanelsState(JSON.stringify(this.panelsState));
        }
    }
    //#endregion

    /**
     * callback to open edit gauge property form (from GaugeBase)
     * @param event
     */
    onGaugeEdit(event) {
        const settings = event.settings;
        this.openEditGauge(event, (data) => {
            this.setGaugeSettings(data);
        });
    }

    isWithEvents(type) {
        return this.gaugesManager.isWithEvents(type);
    }

    isWithActions(type) {
        return this.gaugesManager.isWithActions(type);
    }

    /**
     * edit the gauges/chart settings property, the settings are composed from gauge id... and property
     * in property will be the result values saved
     *
     * @param settings
     * @param callback
     */
    openEditGauge(event, callback) {
        const tempsettings = JSON.parse(JSON.stringify(this.currentView.items));
        const hmi = this.currentProject.hmi;
        const dlgType = GaugesManager.getEditDialogTypeToUse(event.settings.type);
        const eventsSupported = this.isWithEvents(event.settings.type);
        const actionsSupported = this.isWithActions(event.settings.type);
        const defaultValue = GaugesManager.getDefaultValue(event.settings.type);
        const names = Object.values(this.currentView.items).map((gs) => gs.name);

        // set default name
        if (!tempsettings.name) {
            tempsettings.name = Utils.getNextName(
                GaugesManager.getPrefixGaugeName(event.settings.type),
                names,
            );
        }
        let dialogRef: any;
        if (dlgType === GaugeDialogType.Gauge) {
            dialogRef = this.dialog.open(BagPropertyComponent, {
                position: { top: '30px' },
                data: {
                    settings: tempsettings,
                    ams: this.currentProject.amsConfig,
                    dlgType: dlgType,
                    names: names,
                },
            });
        } else if (dlgType === GaugeDialogType.Switch) {
            dialogRef = this.dialog.open(HtmlSwitchPropertyComponent, {
                position: { top: '60px' },
                data: {
                    settings: tempsettings,
                    ams: this.currentProject.amsConfig,
                    withEvents: eventsSupported,
                    withActions: actionsSupported,
                    names: names,
                },
            });
        } else {
            const title = this.getGaugeTitle(event.settings.type);
            dialogRef = this.dialog.open(GaugePropertyComponent, {
                position: { top: '60px' },
                data: {
                    canvas: this.canvas,
                    settings: event.settings,
                    ams: this.currentProject.amsConfig,
                    title: title,
                    views: hmi.views,
                    dlgType: dlgType,
                    withEvents: eventsSupported,
                    withActions: actionsSupported,
                    default: defaultValue,
                    inputs: Object.values(this.currentView.items).filter(
                        (gs) => gs.name && (gs.id.startsWith('HXS_') || gs.id.startsWith('HXI_')),
                    ),
                    selectedElementsId: event.settings.id,
                    names: names,
                },
            });
        }
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                callback(result.settings);
                const currentContent = result.canvas.getSvgString()
                if (currentContent !== this.currentView.svgcontent) {
                    this.currentView.svgcontent = currentContent
                }
                this.saveView(this.currentView);
                const result_gauge = this.gaugesManager.initInEditor(
                    result.settings,
                    this.resolver,
                    this.viewContainerRef,
                );
                const setVieElementName = {
                    id: result.settings.id,
                    name: result.settings.name,
                };
                this.showElementName = this.showElementName.filter((val) => {
                    return val?.id !== result.settings.id;
                });
                this.showElementName.push(setVieElementName);
                this.hierarchyview.forceUpdate();
            }
        });
    }

    private getGaugeTitle(type) {
        let msg = '';
        if (type.startsWith(HtmlInputComponent.TypeTag)) {
            this.translateService.get('editor.controls-input-settings').subscribe((txt: string) => {
                msg = txt;
            });
        } else if (type.startsWith(ValueComponent.TypeTag)) {
            this.translateService
                .get('editor.controls-output-settings')
                .subscribe((txt: string) => {
                    msg = txt;
                });
        } else if (type.startsWith(HtmlButtonComponent.TypeTag)) {
            this.translateService
                .get('editor.controls-button-settings')
                .subscribe((txt: string) => {
                    msg = txt;
                });
        } else if (type.startsWith(HtmlSelectComponent.TypeTag)) {
            this.translateService
                .get('editor.controls-select-settings')
                .subscribe((txt: string) => {
                    msg = txt;
                });
        } else if (type.startsWith(GaugeProgressComponent.TypeTag)) {
            this.translateService
                .get('editor.controls-progress-settings')
                .subscribe((txt: string) => {
                    msg = txt;
                });
        } else if (type.startsWith(GaugeSemaphoreComponent.TypeTag)) {
            this.translateService
                .get('editor.controls-semaphore-settings')
                .subscribe((txt: string) => {
                    msg = txt;
                });
        } else {
            this.translateService.get('editor.controls-shape-settings').subscribe((txt: string) => {
                msg = txt;
            });
        }
        return msg;
    }

    //#endregion

    private clearSelection() {
        this.canvas.clearSelection();
    }

    setPropertyName(elem) {
        const str = elem.nodeName;
        //this is temporary element name
        return (
            str.charAt(0).toUpperCase() +
            str.substring(1).toLowerCase() +
            ' - ' +
            elem.id.slice(0, 6)
        );
    }

    onNameEdit(ga) {
        if (ga) {
            this.setGaugeSettings(ga);
            this.showElementName = this.showElementName.filter((val) => {
                return val?.id !== ga.id;
            });
            this.showElementName.push({ id: ga.id, name: ga.name });
            this.hierarchyview.forceUpdate();
        }
    }

    printMimicsEvent() {
        this.onSaveProject();
        const views = this.currentProject.hmi.views;
        views.forEach((view) => {
            this.onSelectView(view);
            this.printService.addMimictoPrint(this.currentView, this.images);
        });
        this.printService.printMimics(this.images);
        this.showChanges = false;
    }

    isViewMode() {
        if (this.currentView) {
            let editingView = this.editingViews.get(this.currentView.id);
            return editingView
                ? editingView.editor.oid !== this.authService.getCurrentUser().oid
                    ? true
                    : false
                : true;
        } else {
            return true;
        }
    }

    shouldShowViewOnly() {
        if (this.currentView) {
            let editingView = this.editingViews.get(this.currentView.id);
            return editingView ? false : true;
        } else {
            return true;
        }
    }

    shouldShowExpansion() {
        if (this.currentView) {
            let editingView = this.editingViews.get(this.currentView.id);
            return editingView
                ? editingView.editor.oid === this.authService.getCurrentUser().oid
                : false;
        } else {
            return false;
        }
    }
}

export enum EditorModeType {
    EDIT,
    VIEWONLY,
}
