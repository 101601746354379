import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChannelOperation } from 'icreate/common/models/channel-compare';

@Component({
    selector: 'app-channel-merge',
    templateUrl: './channel-merge.component.html',
    styleUrls: ['./channel-merge.component.scss'],
})
export class ChannelMergeComponent {
    readonly LEFT = 'left';
    readonly RIGHT = 'right';
    readonly UPDATE = ChannelOperation.UPDATE;

    @Input() local: any;
    @Input() blob: any;
    @Input() id: string;

    @Output() resolvedClick = new EventEmitter<any>();
    @Input() rowNum: number;
    @Input() operationType: ChannelOperation;

    selectedValue = {};

    constructor() {}

    onResolvedClick(id: string, flattenKey: string, resolvedData: any) {
        this.selectedValue[`${this.id}.${flattenKey}`] = resolvedData;
        this.resolvedClick.emit({ id, flattenKey, resolvedData });
    }

    getIsResolvedKey(flattenKey: string) {
        if (this.operationType === ChannelOperation.UPDATE) {
            return `${this.id}.${flattenKey}`;
        } else {
            return this.id;
        }
    }

    isObject(item: any) {
        return typeof item == 'object' && item;
    }

    onAcceptClick(id: string, resolvedData: any, side: string) {
        this.selectedValue[id] = side;
        const event = {
            id: id,
            resolvedData: resolvedData,
            operationType: this.operationType,
            side: side,
        };
        this.resolvedClick.emit(event);
    }

    isSelected(flattendKey: string, compareValue: any) {
        const selectedValue = this.selectedValue[this.getIsResolvedKey(flattendKey)];
        if (selectedValue) {
            if (selectedValue === compareValue) {
                return 'selected';
            } else {
                return 'not-selected';
            }
        } else {
            return '';
        }
    }
}

interface IResovlvedClickParams {
    id: string;
    resolvedData: any;
    flattenKey: string;
}

interface IAcceptedClickParams {
    id: string;
    resolvedData: any;
    side: string;
    operationType: ChannelOperation;
}
