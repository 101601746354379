<div
    *ngIf="!(this.canvas?.getMode() == 'pathedit'); else pathPanel"
    class="svg-tools-fly"
>
    <mat-expansion-panel
        class="svg-workarea-fly-p"
        (opened)="panelPropertyIdOpenState = true"
        (closed)="panelPropertyIdOpenState = false"
        hideToggle="true"
        [expanded]="panelPropertyIdOpenState"
        style="display: none"
    >
        <mat-expansion-panel-header class="svg-workarea-flybar-h">
            <mat-panel-title>
                <mat-icon aria-label="Select" *ngIf="panelPropertyIdOpenState"
                    >expand_less</mat-icon
                >
                <mat-icon aria-label="Select" *ngIf="!panelPropertyIdOpenState"
                    >expand_more</mat-icon
                >
                <span>{{ "editor.interactivity" | translate }}</span>
                <!-- INTERACTIVITY -->
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="_selected_panel">
            <div class="_toolset">
                <label
                    id="idLabel"
                    matTooltip="{{
                        'editor.interactivity-id-title' | translate
                    }}"
                    class="svg-property"
                >
                    <span>{{ "editor.interactivity-id" | translate }}</span>
                    <input
                        id="elem_id"
                        #selid
                        class="attr_changer"
                        data-attr="id"
                        type="text"
                    />
                </label>
                <label
                    id="classLabel"
                    matTooltip="{{
                        'editor.interactivity-class-title' | translate
                    }}"
                    class="svg-property"
                >
                    <span>{{ "editor.interactivity-class" | translate }}</span>
                    <input
                        id="elem_class"
                        class="attr_changer"
                        data-attr="class"
                        type="text"
                    />
                </label>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel
        *ngIf="
            shouldShowFillAndStroke() ||
            this.getSelectedElements()[0]?.tagName == 'line'
        "
        class="svg-workarea-fly-p"
        (opened)="panelPropertyTransformOpenState = true"
        (closed)="panelPropertyTransformOpenState = false"
        hideToggle="true"
        [expanded]="panelPropertyTransformOpenState"
    >
        <mat-expansion-panel-header class="svg-workarea-leftbar-h">
            <mat-panel-title>
                <mat-icon
                    aria-label="Select"
                    *ngIf="panelPropertyTransformOpenState"
                    >expand_less</mat-icon
                >
                <mat-icon
                    aria-label="Select"
                    *ngIf="!panelPropertyTransformOpenState"
                    >expand_more</mat-icon
                >
                <span>{{ "editor.transform" | translate }}</span>
                <!-- TRANSFORM SIZE/POSITION-->
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="_selected_panel" class="rightbar-panel">
            <div class="_toolset">
                <div
                    *ngIf="
                        IsOneThingSelected() &&
                        !noShowXYSideBarShapes.includes(selectedElement?.type)
                    "
                    id="xy_panel"
                    class="_toolset"
                >
                    <div class="svg-property-split2">
                        <div class="svg-property">
                            <span>{{ "editor.transform-x" | translate }}</span>
                            <input
                                numberOnly
                                id="selected_x"
                                [value]="selectedElement?.getAttribute('x')"
                                (input)="
                                    RoundGiveParameter('x', $event.target.value)
                                "
                                class="attr_changer rightbar-input"
                                type="number"
                                step="1"
                                size="4"
                                data-attr="x"
                            />
                        </div>
                        <div class="svg-property">
                            <span>{{ "editor.transform-y" | translate }}</span>
                            <input
                                numberOnly
                                id="selected_y"
                                [value]="selectedElement?.getAttribute('y')"
                                (input)="
                                    RoundGiveParameter('y', $event.target.value)
                                "
                                class="attr_changer rightbar-input"
                                type="number"
                                step="1"
                                size="4"
                                data-attr="y"
                            />
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="
                        IsOneThingSelected() &&
                        selectedElement?.type == 'svg-ext-shapes-path'
                    "
                >
                    <div class="svg-property-split2">
                        <div class="svg-property">
                            <span>{{ "editor.transform-x" | translate }}</span>
                            <input
                                numberOnly
                                id="selected_x_path"
                                [value]="
                                    roundBBoxCoordinate(selectedElement, 'x')
                                "
                                (input)="
                                    RoundGiveParameter('x', $event.target.value)
                                "
                                class="attr_changer rightbar-input"
                                type="number"
                                size="4"
                                data-attr="x"
                                step="1"
                            />
                        </div>
                        <div class="svg-property">
                            <span>{{ "editor.transform-y" | translate }}</span>
                            <input
                                numberOnly
                                id="selected_y_path"
                                [value]="
                                    roundBBoxCoordinate(selectedElement, 'y')
                                "
                                (input)="
                                    RoundGiveParameter('y', $event.target.value)
                                "
                                class="attr_changer rightbar-input"
                                type="number"
                                size="4"
                                data-attr="y"
                                step="1"
                            />
                        </div>
                    </div>
                </div>
                <ng-template
                    [ngIf]="
                        IsOneThingSelected() &&
                        selectedElement?.type == 'svg-ext-shapes-line'
                    "
                >
                    <div id="line_panel">
                        <div class="svg-property-split2">
                            <div class="svg-property">
                                <span>{{
                                    "editor.transform-x1" | translate
                                }}</span>
                                <input
                                    numberOnly
                                    id="line_x1"
                                    [value]="
                                        selectedElement?.getAttribute('x1')
                                    "
                                    (input)="
                                        RoundGiveParameter(
                                            'x1',
                                            $event.target.value
                                        )
                                    "
                                    class="attr_changer rightbar-input"
                                    type="number"
                                    step="1"
                                    size="4"
                                    data-attr="x1"
                                />
                            </div>
                            <div class="svg-property">
                                <span>{{
                                    "editor.transform-y1" | translate
                                }}</span>
                                <input
                                    numberOnly
                                    id="line_y1"
                                    [value]="
                                        selectedElement?.getAttribute('y1')
                                    "
                                    (input)="
                                        RoundGiveParameter(
                                            'y1',
                                            $event.target.value
                                        )
                                    "
                                    class="attr_changer rightbar-input"
                                    type="number"
                                    step="1"
                                    size="4"
                                    data-attr="y1"
                                />
                            </div>
                        </div>
                        <div class="svg-property-split2">
                            <div class="svg-property">
                                <span>{{
                                    "editor.transform-x2" | translate
                                }}</span>
                                <input
                                    numberOnly
                                    id="line_x2"
                                    [value]="
                                        selectedElement?.getAttribute('x2')
                                    "
                                    (input)="
                                        RoundGiveParameter(
                                            'x2',
                                            $event.target.value
                                        )
                                    "
                                    class="attr_changer rightbar-input"
                                    type="number"
                                    step="1"
                                    size="4"
                                    data-attr="x2"
                                />
                            </div>
                            <div class="svg-property">
                                <span>{{
                                    "editor.transform-y2" | translate
                                }}</span>
                                <input
                                    numberOnly
                                    id="line_y2"
                                    [value]="
                                        selectedElement?.getAttribute('y2')
                                    "
                                    (input)="
                                        RoundGiveParameter(
                                            'y2',
                                            $event.target.value
                                        )
                                    "
                                    class="attr_changer rightbar-input"
                                    type="number"
                                    step="1"
                                    size="4"
                                    data-attr="y2"
                                />
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="
                        IsOneThingSelected() &&
                        selectedElement.type == 'svg-ext-shapes-rect'
                    "
                >
                    <div id="rect_panel">
                        <div class="svg-property-split2">
                            <div class="svg-property">
                                <span>{{
                                    "editor.transform-width" | translate
                                }}</span>
                                <input
                                    numberOnly
                                    id="rect_width"
                                    [value]="getSelAttribute('width', 0)"
                                    (input)="
                                        RoundGiveParameter(
                                            'width',
                                            $event.target.value
                                        )
                                    "
                                    class="attr_changer rightbar-input"
                                    type="number"
                                    step="1"
                                    size="4"
                                    data-attr="width"
                                />
                            </div>
                            <div class="svg-property">
                                <span>{{
                                    "editor.transform-height" | translate
                                }}</span>
                                <input
                                    numberOnly
                                    id="rect_height"
                                    [value]="getSelAttribute('height', 0)"
                                    (input)="
                                        RoundGiveParameter(
                                            'height',
                                            $event.target.value
                                        )
                                    "
                                    class="attr_changer rightbar-input"
                                    type="number"
                                    step="1"
                                    size="4"
                                    data-attr="height"
                                />
                            </div>
                        </div>
                        <div class="svg-property-split2">
                            <div class="svg-property">
                                <span>{{
                                    "editor.transform-radiuscorner" | translate
                                }}</span>
                                <input
                                    id="rect_rx"
                                    size="4"
                                    min="0"
                                    step="1"
                                    value="0"
                                    type="number"
                                    (keypress)="onPreventMinus($event)"
                                    [value]="getSelAttribute('rx', 0)"
                                    (input)="
                                        RoundGiveParameter(
                                            'rx',
                                            $event.target.value
                                        )
                                    "
                                    step="1"
                                    class="rightbar-input"
                                    data-attr="Corner Radius"
                                />
                            </div>
                        </div>
                    </div>
                </ng-template>

                <div
                    *ngIf="
                        IsOneThingSelected() &&
                        selectedElement.type == 'svg-ext-shapes-circle'
                    "
                    id="circle_panel"
                >
                    <div class="svg-property-split2">
                        <div class="svg-property">
                            <span>{{
                                "editor.transform-circlecx" | translate
                            }}</span>
                            <input
                                numberOnly
                                id="circle_cx"
                                type="number"
                                [value]="getSelAttribute('cx', 0)"
                                (input)="
                                    RoundGiveParameter(
                                        'cx',
                                        $event.target.value
                                    )
                                "
                                step="1"
                                class="attr_changer rightbar-input"
                                size="4"
                                data-attr="cx"
                            />
                        </div>
                        <div class="svg-property">
                            <span>{{
                                "editor.transform-circlecy" | translate
                            }}</span>
                            <input
                                numberOnly
                                id="circle_cy"
                                type="number"
                                [value]="getSelAttribute('cy', 0)"
                                (input)="
                                    RoundGiveParameter(
                                        'cy',
                                        $event.target.value
                                    )
                                "
                                step="1"
                                class="attr_changer rightbar-input"
                                size="4"
                                data-attr="cy"
                            />
                        </div>
                    </div>
                    <div class="svg-property-split2">
                        <div class="svg-property">
                            <span>{{
                                "editor.transform-circler" | translate
                            }}</span>
                            <input
                                numberOnly
                                id="circle_r"
                                type="number"
                                [value]="getSelAttribute('r', 0)"
                                (input)="
                                    RoundGiveParameter('r', $event.target.value)
                                "
                                step="1"
                                class="attr_changer rightbar-input"
                                size="4"
                                data-attr="r"
                            />
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="
                        IsOneThingSelected() &&
                        selectedElement?.type == 'svg-ext-shapes-ellipse'
                    "
                    id="ellipse_panel"
                >
                    <div class="svg-property-split2">
                        <div class="svg-property">
                            <span>{{
                                "editor.transform-ellipsecx" | translate
                            }}</span>
                            <input
                                numberOnly
                                id="ellipse_cx"
                                type="number"
                                [value]="getSelAttribute('cx', 0)"
                                (input)="
                                    RoundGiveParameter(
                                        'cx',
                                        $event.target.value
                                    )
                                "
                                step="1"
                                class="attr_changer rightbar-input"
                                size="4"
                                data-attr="cx"
                            />
                        </div>
                        <div class="svg-property">
                            <span>{{
                                "editor.transform-ellipsecy" | translate
                            }}</span>
                            <input
                                numberOnly
                                id="ellipse_cy"
                                type="number"
                                [value]="getSelAttribute('cy', 0)"
                                (input)="
                                    RoundGiveParameter(
                                        'cy',
                                        $event.target.value
                                    )
                                "
                                step="1"
                                class="attr_changer rightbar-input"
                                size="4"
                                data-attr="cy"
                            />
                        </div>
                    </div>
                    <div class="svg-property-split2">
                        <div class="svg-property">
                            <span>{{
                                "editor.transform-ellipserx" | translate
                            }}</span>
                            <input
                                numberOnly
                                id="ellipse_rx"
                                type="number"
                                [value]="getSelAttribute('rx', 0)"
                                (input)="
                                    RoundGiveParameter(
                                        'rx',
                                        $event.target.value
                                    )
                                "
                                step="1"
                                class="attr_changer rightbar-input"
                                size="4"
                                data-attr="rx"
                            />
                        </div>
                        <div class="svg-property">
                            <span>{{
                                "editor.transform-ellipsery" | translate
                            }}</span>
                            <input
                                numberOnly
                                id="ellipse_ry"
                                type="number"
                                [value]="getSelAttribute('ry', 0)"
                                (input)="
                                    RoundGiveParameter(
                                        'ry',
                                        $event.target.value
                                    )
                                "
                                step="1"
                                class="attr_changer rightbar-input"
                                size="4"
                                data-attr="ry"
                            />
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="
                        IsOneThingSelected() &&
                        (selectedElement?.type == 'svg-ext-shapes-text' ||
                            selectedElement?.type == 'svg-ext-value')
                    "
                    id="text_panel"
                >
                    <div class="svg-property">
                        <span>{{
                            "editor.transform-fontfamily" | translate
                        }}</span>
                        <select
                            class="font-family"
                            id="font_family"
                            #fontfamily
                            (change)="onFontFamilyChange(fontfamily.value)"
                        >
                            <option
                                *ngFor="let font of fonts"
                                [style.fontFamily]="font"
                                [selected]="font == selectedFont"
                                [value]="font"
                            >
                                {{ font }}
                            </option>
                        </select>
                    </div>
                    <div class="svg-property-split2">
                        <div class="svg-property">
                            <span>{{
                                "editor.transform-fontsize" | translate
                            }}</span>
                            <input
                                (input)="onFontSizeChange($event)"
                                numberOnly
                                id="font_size"
                                size="4"
                                [value]="
                                    selectedElement?.getAttribute(
                                        'font-size'
                                    ) || 8
                                "
                                type="number"
                                class="rightbar-input"
                            />
                        </div>
                        <div class="svg-property">
                            <span>text anchor</span>
                            <select
                                class="text-align"
                                id="text_anchor"
                                #textalign
                                (change)="onTextAlignChange(textalign.value)"
                            >
                                <option
                                    [selected]="'start' == selectedTextAnchor"
                                    value="start"
                                >
                                    start
                                </option>
                                <option
                                    [selected]="'middle' == selectedTextAnchor"
                                    value="middle"
                                >
                                    middle
                                </option>
                                <option
                                    [selected]="'end' == selectedTextAnchor"
                                    value="end"
                                >
                                    end
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="IsOneThingSelected()"
                    id="tool_angle"
                    class="svg-property"
                >
                    <div class="svg-property-split2">
                        <div class="svg-property">
                            <span>{{
                                "editor.transform-angle" | translate
                            }}</span>
                            <input
                                [(ngModel)]="SelectedObjAngle"
                                numberOnly
                                id="angle"
                                size="4"
                                value="0"
                                type="number"
                                class="rightbar-input"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel
        *ngIf="false"
        class="svg-workarea-fly-p"
        (opened)="panelAlignOpenState = true"
        (closed)="panelAlignOpenState = false"
        hideToggle="true"
        [expanded]="panelAlignOpenState"
    >
        <mat-expansion-panel-header class="svg-workarea-leftbar-h">
            <mat-panel-title>
                <mat-icon aria-label="Select" *ngIf="panelAlignOpenState"
                    >expand_less</mat-icon
                >
                <mat-icon aria-label="Select" *ngIf="!panelAlignOpenState"
                    >expand_more</mat-icon
                >
                <span>{{ "editor.align" | translate }}</span>
                <!-- ALIGN-->
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="align_panel" class="rightbar_panel">
            <div
                class="svg-tool-button"
                (click)="onAlignSelected('left')"
                matTooltip="{{ 'editor.align-left-title' | translate }}"
            >
                <span class="icon-align-left"></span>
            </div>
            <div
                class="svg-tool-button"
                (click)="onAlignSelected('center')"
                matTooltip="{{
                    'editor.align-center-title' | translate
                }}Align Center"
            >
                <span class="icon-align-center"></span>
            </div>
            <div
                class="svg-tool-button"
                (click)="onAlignSelected('right')"
                matTooltip="{{
                    'editor.align-right-title' | translate
                }}Align Right"
            >
                <span class="icon-align-right"></span>
            </div>
            <div
                class="svg-tool-button"
                (click)="onAlignSelected('top')"
                matTooltip="{{ 'editor.align-top-title' | translate }}Align Top"
            >
                <span class="icon-align-top"></span>
            </div>
            <div
                class="svg-tool-button"
                (click)="onAlignSelected('middle')"
                matTooltip="{{
                    'editor.align-middle-title' | translate
                }}Align Middle"
            >
                <span class="icon-align-middle"></span>
            </div>
            <div
                class="svg-tool-button"
                (click)="onAlignSelected('bottom')"
                matTooltip="{{
                    'editor.align-bottom-title' | translate
                }}Align Bottom"
            >
                <span class="icon-align-bottom"></span>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel
        *ngIf="IsOneThingSelected() && selectedElement.tagName == 'image'"
        class="svg-workarea-fly-p"
        (opened)="panelImageOpenState = true"
        (closed)="panelImageOpenState = false"
        hideToggle="true"
        [expanded]="panelImageOpenState"
    >
        <mat-expansion-panel-header class="svg-workarea-leftbar-h">
            <mat-icon aria-label="Select" *ngIf="panelImageOpenState"
                >expand_less</mat-icon
            >
            <mat-icon aria-label="Select" *ngIf="!panelImageOpenState"
                >expand_more</mat-icon
            >
            <span>Image</span>
            <!-- FILL-->
        </mat-expansion-panel-header>
        <div class="rightbar_panel">
            <div class="svg-property" style="vertical-align: top">
                <span>lock aspect ratio</span>
                <input [(ngModel)]="PreserveAspectRatio" type="checkbox" />
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel
        *ngIf="shouldShowGenericOpacity()"
        class="svg-workarea-fly-p"
        (opened)="panelOpacityOpenState = true"
        (closed)="panelOpacityOpenState = false"
        hideToggle="true"
        [expanded]="panelOpacityOpenState"
    >
        <mat-expansion-panel-header class="svg-workarea-leftbar-h">
            <mat-icon aria-label="Select" *ngIf="panelOpacityOpenState"
                >expand_less</mat-icon
            >
            <mat-icon aria-label="Select" *ngIf="!panelOpacityOpenState"
                >expand_more</mat-icon
            >
            <span>Opacity</span>
            <!-- FILL-->
        </mat-expansion-panel-header>
        <div class="rightbar_panel">
            <div class="svg-property" style="vertical-align: top">
                <span>opacity</span>
                <input
                    (keypress)="onPreventMinus($event)"
                    id="xopacity"
                    type="number"
                    value="100"
                    step="10"
                    min="0"
                    max="100"
                    style="margin-bottom: 0px; margin-top: 6px"
                    [value]="
                        (selectedElement?.getAttribute('opacity') ?? 1) * 100
                    "
                    (input)="
                        RoundGiveParameter('opacity', $event.target.value / 100)
                    "
                />
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel
        *ngIf="shouldShowFillAndStroke()"
        class="svg-workarea-fly-p"
        (opened)="panelFillOpenState = true"
        (closed)="panelFillOpenState = false"
        hideToggle="true"
        [expanded]="panelFillOpenState"
    >
        <mat-expansion-panel-header class="svg-workarea-leftbar-h">
            <mat-icon aria-label="Select" *ngIf="panelFillOpenState"
                >expand_less</mat-icon
            >
            <mat-icon aria-label="Select" *ngIf="!panelFillOpenState"
                >expand_more</mat-icon
            >
            <span>Fill</span>
            <!-- FILL-->
        </mat-expansion-panel-header>
        <div class="rightbar_panel">
            <div class="_color_tool" id="tool_fill">
                <div class="svg-property-split2">
                    <div class="svg-property" style="vertical-align: middle">
                        <span>color</span>
                        <app-color-panel-wrapper
                            #fillcolorpicker
                            (colorEvent)="onChangeFillColor($event)"
                            [selectedColor]="getSelAttribute('fill', '#FFF')"
                            style="display: inline-block; margin-left: 6px"
                        ></app-color-panel-wrapper>
                    </div>
                    <div class="svg-property" style="vertical-align: top">
                        <span>opacity</span>
                        <input
                            (keypress)="onPreventMinus($event)"
                            id="fill-opacity"
                            type="number"
                            value="100"
                            step="10"
                            min="0"
                            max="100"
                            style="margin-bottom: 0px; margin-top: 6px"
                            [value]="
                                (selectedElement?.getAttribute(
                                    'fill-opacity'
                                ) ?? 1) * 100
                            "
                            (input)="
                                RoundGiveParameter(
                                    'fill-opacity',
                                    $event.target.value / 100
                                )
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel
        *ngIf="shouldShowFillAndStroke()"
        class="svg-workarea-fly-p"
        (opened)="panelStrokeOpenState = true"
        (closed)="panelStrokeOpenState = false"
        hideToggle="true"
        [expanded]="panelStrokeOpenState"
    >
        <mat-expansion-panel-header class="svg-workarea-leftbar-h">
            <mat-panel-title>
                <mat-icon aria-label="Select" *ngIf="panelStrokeOpenState"
                    >expand_less</mat-icon
                >
                <mat-icon aria-label="Select" *ngIf="!panelStrokeOpenState"
                    >expand_more</mat-icon
                >
                <span>{{ "editor.stroke" | translate }}</span>
                <!-- STROKE-->
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="rightbar_panel">
            <div class="_color_tool" id="tool_stroke">
                <div class="svg-property-split2">
                    <div class="svg-property" style="vertical-align: top">
                        <span>color</span>
                        <app-color-panel-wrapper
                            #strokecolorpicker
                            (colorEvent)="onChangeStrokeColor($event)"
                            [selectedColor]="getSelAttribute('stroke', '#FFF')"
                            style="display: inline-block; margin-left: 6px"
                        ></app-color-panel-wrapper>
                    </div>
                    <div class="svg-property" style="vertical-align: top">
                        <span>opacity</span>
                        <input
                            (keypress)="onPreventMinus($event)"
                            id="stroke-opacity"
                            type="number"
                            value="100"
                            step="10"
                            min="0"
                            max="100"
                            style="margin-bottom: 0px; margin-top: 6px"
                            [value]="
                                (selectedElement?.getAttribute(
                                    'stroke-opacity'
                                ) ?? 1) * 100
                            "
                            (input)="
                                RoundGiveParameter(
                                    'stroke-opacity',
                                    $event.target.value / 100
                                )
                            "
                        />
                    </div>
                </div>
                <div class="svg-property-split2">
                    <div class="svg-property">
                        <span>{{ "editor.stroke-width" | translate }}</span>
                        <input
                            id="stroke_width"
                            type="number"
                            (keypress)="onPreventMinus($event)"
                            [(ngModel)]="SelObjStrokeWidth"
                            class="rightbar-input"
                            size="4"
                            value="1"
                            min="0"
                            data-attr="Stroke Width"
                        />
                    </div>
                    <div class="svg-property">
                        <span>{{ "editor.stroke-style" | translate }}</span>
                        <select
                            id="stroke_style"
                            class="style-stroke"
                            (change)="onStrokeStyleSelect($event.target.value)"
                        >
                            <option selected="selected" value="none">
                                &#8212;
                            </option>
                            <option
                                *ngFor="let stroke of strokeTypes"
                                [value]="stroke.val"
                                [selected]="selectedStroke == stroke.val"
                            >
                                {{ stroke.id }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="svg-property">
                    <div
                        class="svg-tool-button"
                        id="linejoin_miter"
                        #linejoinmiter
                        (click)="onSetLineJoin('miter')"
                        matTooltip="{{
                            'editor.stroke-joinmiter-title' | translate
                        }}"
                    >
                        <span
                            class="icon-linejoin-miter"
                            [ngClass]="{
                                'selected-attribute': selectedLinejoin('miter')
                            }"
                        ></span>
                    </div>
                    <div
                        class="svg-tool-button"
                        id="linejoin_round"
                        #linejoinround
                        (click)="onSetLineJoin('round')"
                        matTooltip="{{
                            'editor.stroke-joinround-title' | translate
                        }}"
                    >
                        <span class="icon-linejoin-round"
                        [ngClass]="{
                          'selected-attribute': selectedLinejoin('round')
                      }"></span>
                    </div>
                    <div
                        class="svg-tool-button"
                        id="linejoin_bevel"
                        #linejoinbevel
                        (click)="onSetLineJoin('bevel')"
                        matTooltip="{{
                            'editor.stroke-joinbevel-title' | translate
                        }}"
                    >
                        <span class="icon-linejoin-bevel" [ngClass]="{
                          'selected-attribute': selectedLinejoin('bevel')
                      }"></span>
                    </div>
                </div>
                <div class="svg-property" *ngIf="this.getSelectedElements()[0]?.tagName == 'line'">
                    <div
                        class="svg-tool-button"
                        id="linecap_butt"
                        #linecapbutt
                        (click)="onSetLineCap('butt')"
                        matTooltip="{{
                            'editor.stroke-capbutt-title' | translate
                        }}"
                    >
                        <span class="icon-linecap-butt"></span>
                    </div>
                    <div
                        class="svg-tool-button"
                        id="linecap_square"
                        #linecapsquare
                        (click)="onSetLineCap('square')"
                        matTooltip="{{
                            'editor.stroke-capsquare-title' | translate
                        }}"
                    >
                        <span
                            class="icon-linecap-square"
                        ></span>
                    </div>
                    <div
                        class="svg-tool-button"
                        id="linecap_round"
                        #linecapround
                        (click)="onSetLineCap('round')"
                        matTooltip="{{
                            'editor.stroke-capround-title' | translate
                        }}"
                    >
                        <span
                            class="icon-linecap-round"
                        ></span>
                    </div>
                </div>
                <div class="svg-property-split2" style="display: none">
                    <div class="svg-property">
                        <span for="class_shadow">{{
                            "editor.stroke-shadow" | translate
                        }}</span>
                        <input
                            type="checkbox"
                            id="class_shadow"
                            name="class_shadow"
                            class="attr_changer"
                            matTooltip="{{
                                'editor.stroke-shadow-title' | translate
                            }}"
                            (change)="onSetShadowOption($event.target.checked)"
                            label="shadow"
                        />
                    </div>
                    <div class="svg-property"></div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <!---MARKER
    <mat-expansion-panel *ngIf="selectedElement?.type.includes('svg-ext-shapes')" class="svg-workarea-fly-p" (opened)="panelMarkerOpenState = true" (closed)="panelMarkerOpenState = false" hideToggle="true"
        expanded="true">
        <mat-expansion-panel-header class="svg-workarea-leftbar-h">
            <mat-panel-title>
                <mat-icon aria-label="Select" *ngIf="panelMarkerOpenState">expand_less</mat-icon>
                <mat-icon aria-label="Select" *ngIf="!panelMarkerOpenState">expand_more</mat-icon>
                <span>{{'editor.marker' | translate}}</span> 
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="marker_panel" class="rightbar_panel">
            <div class="svg-property-split3">
                <div class="svg-property">
                    <span>{{'editor.marker-start' | translate}}</span>
                    <select id="start_marker" #smarker class="style-stroke" matTooltip="{{'editor.marker-start-title' | translate}}"
                        (click)="onSetMarker('start_marker', smarker.selectedIndex);" onfocus="this.selectedIndex=-1;this.blur();">
                    </select>
                </div>
                <div class="svg-property">
                    <span>{{'editor.marker-middle' | translate}}</span>
                    <select id="mid_marker" #mmarker class="style-stroke" matTooltip="{{'editor.marker-middle-title' | translate}}"
                        (click)="onSetMarker('mid_marker', mmarker.selectedIndex);" onfocus="this.selectedIndex=-1;this.blur();">
                    </select>
                </div>
                <div class="svg-property">
                    <span>{{'editor.marker-end' | translate}}</span>
                    <select id="end_marker" #emarker class="style-stroke" matTooltip="{{'editor.marker-end-title' | translate}}"
                        (click)="onSetMarker('end_marker', emarker.selectedIndex);" onfocus="this.selectedIndex=-1;this.blur();">
                    </select>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    --->
    <mat-expansion-panel
        *ngIf="IsOneThingSelected()"
        class="svg-workarea-fly-p"
        (opened)="panelEventOpenState = true"
        (closed)="panelEventOpenState = false"
        hideToggle="true"
        [expanded]="panelEventOpenState"
    >
        <mat-expansion-panel-header class="svg-workarea-leftbar-h">
            <mat-panel-title>
                <mat-icon aria-label="Select" *ngIf="panelEventOpenState"
                    >expand_less</mat-icon
                >
                <mat-icon aria-label="Select" *ngIf="!panelEventOpenState"
                    >expand_more</mat-icon
                >
                <span>{{ "editor.interactivity" | translate }}</span>
                <!-- INTERACTIVITY -->
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="interaction_panel" class="rightbar_panel">
            <app-gauge-base
                #gaugepanel
                *ngIf="getSelectedElements().length == 1"
                [settings]="getGaugeSettings(selectedElement)"
                (edit)="OnGaugeEdit($event)"
                (inputElemName)="onNameEdit($event)"
            ></app-gauge-base>
        </div>
    </mat-expansion-panel>
</div>
<ng-template #pathPanel>
    <div class="svg-tools-fly">
        <mat-expansion-panel class="svg-workarea-fly-p" expanded hideToggle>
            <mat-expansion-panel-header class="svg-workarea-leftbar-h">
                <!--<mat-icon aria-label="Select" *ngIf="panelFillOpenState">expand_less</mat-icon>
                <mat-icon aria-label="Select" *ngIf="!panelFillOpenState">expand_more</mat-icon>-->
                <span>{{ "editor.path-panel" | translate }}</span>
                <!-- FILL-->
            </mat-expansion-panel-header>
            <div class="rightbar_panel">
                <div class="_vector_tools" id="tool_vector">
                    <div style="display: flex; justify-content: space-evenly">
                        <div class="svg-property">
                            <button
                                mat-button
                                class="vector-button"
                                (click)="SetSegment()"
                                [disabled]="
                                    !VectorToolEnableCheck('setsegment')
                                "
                                matTooltip="{{
                                    'editor.vector-set-segment' | translate
                                }}"
                            >
                                <span class="icon-set-segment"></span>
                            </button>
                        </div>
                        <div class="svg-property" style="vertical-align: top">
                            <button
                                mat-button
                                class="vector-button"
                                (click)="SetCurve()"
                                [disabled]="!VectorToolEnableCheck('setcurve')"
                                matTooltip="{{
                                    'editor.vector-set-curve' | translate
                                }}"
                            >
                                <span class="icon-set-curve"></span>
                            </button>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-evenly">
                        <div class="svg-property" style="vertical-align: top">
                            <button
                                mat-button
                                class="vector-button"
                                (click)="SplitPath()"
                                [disabled]="!VectorToolEnableCheck('splitpath')"
                                matTooltip="{{
                                    'editor.vector-split-path' | translate
                                }}"
                            >
                                <span class="icon-split-path"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</ng-template>
