import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Channel } from 'common/models/channel';
import { ChannelType } from 'common/models/channel-type';

@Component({
    selector: 'app-search-channel-input',
    templateUrl: './search-channel-input.component.html',
    styleUrls: ['./search-channel-input.component.scss'],
})
export class SearchChannelInputComponent implements OnInit {
    @Input() displayErrorContainer: boolean = true;
    @Input() channelError: string;
    @Input() channels: Channel[];
    @Input() channelNumber: number;
    @Output() onChannelSelected: EventEmitter<number> = new EventEmitter();

    selectedChannel: string;

    ngOnInit(): void {
        if (this.channelNumber) {
            this.selectedChannel = this.channelNumber.toString();
        }
    }

    filterChannels(e) {
        const inputedChannel = e.target.value;
        if (inputedChannel === '') {
            this.onChannelSelected.emit(null);
            return;
        }
        this.onChannelSelected.emit(Number(inputedChannel));
    }

    selectedChannelErrors() {
        if (this.channelError) {
            return this.channelError;
        }
        let errorString = 'errors.validation.selected-channel.not-found';
        for (let i = 0; i < this.channels.length; i++) {
            if (
                this.channels[i].fcuData.fcuChannelId === Number(this.selectedChannel) ||
                this.channels[i].opsData.tag === this.selectedChannel
            ) {
                if (this.isDigitalChannel(this.channels[i].fcuData.channelType)) {
                    errorString = 'errors.validation.selected-channel.digital';
                } else {
                    errorString = null;
                }
                break;
            }
        }
        return errorString;
    }

    isDigitalChannel(channelType) {
        const digitalChannelTypes = new Set([
            ChannelType.DIGITAL_MOTOR,
            ChannelType.DIGITAL_NORMAL_CLOSE,
            ChannelType.DIGITAL_NORMAL_OPEN,
        ]);
        return digitalChannelTypes.has(channelType);
    }
}
