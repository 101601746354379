<div style="width: 740px; position: relative">
  <h1
    mat-dialog-title
    style="display: inline-block; cursor: move"
    mat-dialog-draggable
  >
    {{ data.title }}
  </h1>
  <mat-icon
    (click)="onNoClick()"
    style="
      float: right;
      margin-right: -10px;
      margin-top: -10px;
      cursor: pointer;
      color: gray;
    "
    >clear</mat-icon
  >
  <mat-tab-group style="width: 100%">
    <mat-tab label="{{ 'gauges.property-props' | translate }}">
      <div class="mat-tab-container">
        <div>
          <div class="my-form-field" style="padding-left: 10px">
            <span>{{ "gauges.property-name" | translate }}</span>
            <input
              [(ngModel)]="data.settings.name"
              style="width: 220px"
              type="text"
            />
          </div>
          <!-- <div class="toolbox" *ngIf="isToolboxToShow()">
						<button mat-icon-button (click)="onAddInput()">
							<mat-icon>add_circle_outline</mat-icon>
						</button>
					</div> -->
        </div>
        <div style="overflow: visible; width: 100%">
          <flex-head [data]="data" [property]="property" #flexHead></flex-head>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      label="{{ 'gauges.property-events' | translate }}"
      *ngIf="eventsSupported"
    >
      <div class="mat-tab-container">
        <div class="toolbox">
          <button
            mat-icon-button
            (click)="onAddEvent()"
            matTooltip="{{ 'gauges.property-tooltip-add-event' | translate }}"
          >
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </div>
        <div mat-dialog-content style="overflow: visible; width: 100%">
          <flex-event
            [property]="property"
            [views]="views"
            [data]="data"
            [inputs]="inputs"
            #flexevent
            *ngIf="eventsSupported"
            style="padding-bottom: 5px"
          ></flex-event>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      label="{{ 'gauges.property-actions' | translate }}"
      *ngIf="actionsSupported"
    >
      <div class="mat-tab-container">
        <div class="toolbox">
          <button mat-icon-button (click)="onAddAction()">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
          <!-- <button mat-icon-button (click)="slideActionView = !slideActionView; onActionRangeViewToggle()">
                        <mat-icon class="header-icon" *ngIf="slideActionView">toll</mat-icon>
                        <mat-icon class="header-icon" *ngIf="!slideActionView">input</mat-icon>
                    </button>-->
        </div>
        <!-- <div mat-dialog-content style="overflow: visible; width:100%">
                    <flex-action [data]="data" [property]="property" #flexaction *ngIf="actionsSupported" style="padding-bottom: 5px"></flex-action>
                </div> -->
      </div>
    </mat-tab>
  </mat-tab-group>
  <div mat-dialog-actions class="dialog-action">
    <button mat-raised-button (click)="onNoClick()">
      {{ "dlg.cancel" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="onOkClick()"
      [mat-dialog-close]="data"
      cdkFocusInitial
    >
      {{ "dlg.ok" | translate }}
    </button>
  </div>
</div>
