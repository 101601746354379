import { Channel } from './channel';
import { ChannelGroup } from './channel-group';
import { Hmi } from './view';

export class BlobOperationParam {
    builderId: string;
    vesselId: string;
    projectId: any;
    channelMerged: ChannelMerged = ChannelMerged.NotMerged;
}

export enum ChannelMerged {
    NotMerged = '0',
    Merged = '1',
}
class Project {
    name: string;
    layout: Object;
    amsConfig: Object;
    createdAt: any;
    updatedAt: any;
    createdBy: string;
    updatedBy: string;
    VesselBuilderCompanyID: string;
    VesselBuilderCompanyName: string;
    VesselID: string;
    VesselName: string;
    VesselOwner: string;
    VesselManager: string;
    VesselNumber: string;
    AMSVersion: string;
    hmi: Hmi = new Hmi();
}

class BlobProject extends Project {
    id: string;
    hmi: Hmi;
}

interface EditorTemplate {
    id?: string;
    projectId: any;
    serializedComponents: string;
}

export class BlobData {
    project: BlobProject;
    editorTemplates: EditorTemplate;
    channelsObj: {
        channels: Channel[];
        groups: ChannelGroup[];
    };
}
