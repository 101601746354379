import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, lastValueFrom } from 'rxjs';
import { EndPointApi } from '@helpers/endpointapi';
import { SocketMiddlewareService } from './socket-middleware.service';
import { FuSettingsEvent } from 'icreate/common/io-event/websocket-message';
import { FcuSetting, FuSetting } from 'icreate/common/models/FU-settings';

@Injectable({
    providedIn: 'root',
})
export class FuSettingService {

    onUnlockActivePage: Subject<string> = new Subject<string>();

    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(
        private http: HttpClient,
        private socketService: SocketMiddlewareService,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    getFcuPages(projectId: string): Promise<FcuSetting[]> {
        return lastValueFrom(
            this.http.get<FcuSetting[]>(`${this.domain}/api/fuSettings/fcuSettings`, {
                params: { projectId },
            }),
        );
    }

    getFuSettings(fcuId: string): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/fuSettings`, {
                params: { fcuId },
            }),
        );
    }

    createFcuPage(projectId: string): Promise<any> {
        return lastValueFrom(
            this.http.post(
                `${this.domain}/api/fuSettings`,
                { projectId },
                { headers: this.headers },
            ),
        );
    }

    deleteFcuPage(fcuPageId: string): Promise<any> {
        return lastValueFrom(
            this.http.delete(`/api/fuSettings/${fcuPageId}`, {
                responseType: 'text',
            }),
        );
    }

    updateFcuSettings(fcuSetting: FcuSetting): Promise<any> {
        return lastValueFrom(
            this.http.put(`${this.domain}/api/fuSettings/fcuSetting`, fcuSetting, {
                headers: this.headers,
            }),
        );
    }

    updateFuSettings(fuSetting: FuSetting): Promise<any> {
        return lastValueFrom(
            this.http.put(`${this.domain}/api/fuSettings`, fuSetting, {
                headers: this.headers,
            }),
        );
    }

    sendUnlockBeacon(fcuPageId: string) {
        navigator.sendBeacon(`${this.domain}/api/fuSettings/unlock?fcuPageId=${fcuPageId}`)
    }

    unlockFcuPage(fcuPageId: string): Promise<any> {
        return lastValueFrom(
            this.http.put(
                `${this.domain}/api/fuSettings/unlock`,
                { fcuPageId },
                { headers: this.headers },
            ),
        );
    }

    lockedPage$() {
        return this.socketService.receiveMessageObserver(FuSettingsEvent.FCU_LOCKED);
    }

    unlockedPage$() {
        return this.socketService.receiveMessageObserver(FuSettingsEvent.FCU_UNLOCKED);
    }

    deletedPage$() {
        return this.socketService.receiveMessageObserver(FuSettingsEvent.FCU_DELETED);
    }

    newPage$() {
        return this.socketService.receiveMessageObserver(FuSettingsEvent.FCU_CREATED);
    }
}
