<div
  style="width: 750px; height: 810px; position: relative"
  class="dialog-mdsd-v"
>
  <h1
    mat-dialog-title
    style="display: inline-block; cursor: move"
    mat-dialog-draggable
  >
    {{ "editor.controls-bag-settings" | translate }}
  </h1>
  <mat-icon
    (click)="onNoClick()"
    style="
      float: right;
      margin-right: -10px;
      margin-top: -10px;
      cursor: pointer;
      color: gray;
    "
    >clear</mat-icon
  >
  <div style="display: block; width: 660px">
    <div style="display: block">
      <div class="my-form-field">
        <span>{{ "gauges.property-name" | translate }}</span>
        <input
          [(ngModel)]="data.settings.name"
          style="width: 220px"
          type="text"
          name="settings"
        />
      </div>
      <div
        class="my-form-field"
        style="vertical-align: bottom; margin-left: 13px"
      >
        <span>{{ "gauges.property-permission" | translate }}</span>
        <div
          style="text-align: center; cursor: pointer"
          class="my-form-field-permission"
          (click)="onEditPermission()"
        >
          <mat-icon
            class="header-icon"
            style="line-height: 30px"
            *ngIf="!property || !property.permission"
            >lock_open</mat-icon
          >
          <mat-icon
            class="header-icon"
            style="line-height: 30px"
            *ngIf="property && property.permission"
            >lock</mat-icon
          >
        </div>
      </div>
    </div>
    <div mat-dialog-content style="overflow: hidden; width: 100%">
      <flex-head [data]="data" [property]="property" #flexhead></flex-head>
    </div>
  </div>
  <div style="width: 750px; height: 660px; position: relative">
    <div style="display: block; height: 360px">
      <div class="gauge">
        <div class="gauge-select">
          <div
            (click)="onGaugeChange(gaugeTypeEnum.Gauge)"
            class="btn-gauge btn-gauge-mat"
            [ngStyle]="{
              'background-color':
                gaugeType === gaugeTypeEnum.Gauge
                  ? 'transparent'
                  : 'rgba(0,0,0,0.1)'
            }"
          ></div>
          <div
            (click)="onGaugeChange(gaugeTypeEnum.Donut)"
            class="btn-gauge btn-gauge-donut"
            [ngStyle]="{
              'background-color':
                gaugeType === gaugeTypeEnum.Donut
                  ? 'transparent'
                  : 'rgba(0,0,0,0.1)'
            }"
          ></div>
          <div
            (click)="onGaugeChange(gaugeTypeEnum.Zones)"
            class="btn-gauge btn-gauge-zone"
            [ngStyle]="{
              'background-color':
                gaugeType === gaugeTypeEnum.Zones
                  ? 'transparent'
                  : 'rgba(0,0,0,0.1)'
            }"
          ></div>
        </div>
        <div class="gauge-view">
          <ngx-gauge
            [options]="options"
            #ngauge
            [value]="gauge.value"
          ></ngx-gauge>
        </div>
      </div>
      <div class="toolbox-det" *ngIf="gaugeType != gaugeTypeEnum.Donut">
        <div class="field-row">
          <div class="my-form-field" style="padding-left: 12px">
            <span>{{ "bag.property-ticks" | translate }}</span>
            <mat-slide-toggle
              color="primary"
              [(ngModel)]="optcfg.ticksEnabled"
              (change)="onChangeTicks($event.checked)"
            ></mat-slide-toggle>
          </div>
        </div>
        <div class="field-row">
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-divisions" | translate }}</span>
            <mat-slider
              [disabled]="!optcfg.ticksEnabled"
              style="display: inline-block"
              [max]="20"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.renderTicks.divisions"
              (input)="onChangeOptionsTicks('divisions', $event)"
              name="divisions"
            >
            </mat-slider>
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-subdivisions" | translate }}</span>
            <mat-slider
              [disabled]="!optcfg.ticksEnabled"
              style="display: inline-block"
              [max]="20"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.renderTicks.subDivisions"
              (input)="onChangeOptionsTicks('subDivisions', $event)"
              name="subDivisions"
            >
            </mat-slider>
          </div>
        </div>
        <div class="field-row">
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-divisions-length" | translate }}</span>
            <mat-slider
              [disabled]="!optcfg.ticksEnabled"
              style="display: inline-block"
              [max]="100"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.renderTicks.divLength"
              (input)="onChangeOptionsTicks('divLength', $event)"
              name="divLength"
            >
            </mat-slider>
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-subdivisions-length" | translate }}</span>
            <mat-slider
              [disabled]="!optcfg.ticksEnabled"
              style="display: inline-block"
              [max]="100"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.renderTicks.subLength"
              (input)="onChangeOptionsTicks('subLength', $event)"
              name="subLength"
            >
            </mat-slider>
          </div>
        </div>
        <div class="field-row">
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-divisions-width" | translate }}</span>
            <mat-slider
              [disabled]="!optcfg.ticksEnabled"
              style="display: inline-block"
              [max]="100"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.renderTicks.divWidth"
              (input)="onChangeOptionsTicks('divWidth', $event)"
              name="divWidth"
            >
            </mat-slider>
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-subdivisions-width" | translate }}</span>
            <mat-slider
              [disabled]="!optcfg.ticksEnabled"
              style="display: inline-block"
              [max]="100"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.renderTicks.subWidth"
              (input)="onChangeOptionsTicks('subWidth', $event)"
              name="subWidth"
            >
            </mat-slider>
          </div>
        </div>
        <div class="field-row">
          <div
            class="my-form-field slider-field color-field"
            style="padding-left: 12px"
          >
            <span>{{ "bag.property-divisions-color" | translate }}</span>
            <input
              [disabled]="!optcfg.ticksEnabled"
              [(colorPicker)]="optcfg.renderTicks.divColor"
              [cpAlphaChannel]="'always'"
              class="input-color"
              [style.background]="optcfg.renderTicks.divColor"
              [cpPresetColors]="defaultColor"
              [cpOKButton]="true"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'cpCancelButtonClass'"
              [cpCancelButtonText]="'Cancel'"
              [cpOKButtonText]="'OK'"
              [cpOKButtonClass]="'cpOKButtonClass'"
              style="width: 121px"
              [cpPosition]="'left'"
              (colorPickerChange)="onChangeOptionsTicks('divColor', $event)"
              name="divColor"
            />
          </div>
          <div
            class="my-form-field slider-field color-field"
            style="padding-left: 12px"
          >
            <span>{{ "bag.property-subdivisions-color" | translate }}</span>
            <input
              [disabled]="!optcfg.ticksEnabled"
              [(colorPicker)]="optcfg.renderTicks.subColor"
              [cpAlphaChannel]="'always'"
              class="input-color"
              [style.background]="optcfg.renderTicks.subColor"
              [cpPresetColors]="defaultColor"
              [cpOKButton]="true"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'cpCancelButtonClass'"
              [cpCancelButtonText]="'Cancel'"
              [cpOKButtonText]="'OK'"
              [cpOKButtonClass]="'cpOKButtonClass'"
              style="width: 121px"
              [cpPosition]="'left'"
              (colorPickerChange)="onChangeOptionsTicks('subColor', $event)"
              name="subColor"
            />
          </div>
        </div>
        <div class="field-row">
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-divisionfont-size" | translate }}</span>
            <mat-slider
              [disabled]="!optcfg.ticksEnabled"
              style="display: inline-block"
              [max]="100"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.staticFontSize"
              (input)="onChangeOptionsLabels('fontSize', $event)"
              name="fontSize"
            >
            </mat-slider>
          </div>
          <div
            class="my-form-field slider-field color-field"
            style="padding-left: 12px"
          >
            <span>{{ "bag.property-divisionfont-color" | translate }}</span>
            <input
              [(colorPicker)]="optcfg.staticFontColor"
              [cpDisabled]="!optcfg.ticksEnabled"
              [cpAlphaChannel]="'always'"
              class="input-color"
              [style.background]="optcfg.staticFontColor"
              [cpPresetColors]="defaultColor"
              [cpOKButton]="true"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'cpCancelButtonClass'"
              [cpCancelButtonText]="'Cancel'"
              [cpOKButtonText]="'OK'"
              [cpOKButtonClass]="'cpOKButtonClass'"
              style="width: 121px"
              [cpPosition]="'left'"
              (colorPickerChange)="onChangeOptionsLabels('labelsColor', $event)"
              name="labelsColor"
            />
          </div>
        </div>
        <div class="field-row">
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span style="max-width: 180px">{{
              "bag.property-divisions-labels" | translate
            }}</span>
            <input
              [disabled]="!optcfg.ticksEnabled"
              [(ngModel)]="optcfg.staticLabelsText"
              (change)="
                onChangeOptionsLabels('labels', optcfg.staticLabelsText)
              "
              style="width: 265px"
              type="text"
              name="labels"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="toolbox">
      <div class="toolbox-left">
        <div class="field-row">
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-current-value" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="optcfg.maxValue"
              [min]="optcfg.minValue"
              [step]="1"
              discrete
              [(ngModel)]="gauge.value"
              (input)="onChangeValue($event)"
            >
            </mat-slider>
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-min" | translate }}</span>
            <input
              numberOnly
              [(ngModel)]="optcfg.minValue"
              (change)="onChangeOptions('minValue', optcfg.minValue)"
              style="width: 50px; text-align: center; display: inline-block"
              type="text"
              name="minValue"
            />
          </div>
          <div class="my-form-field slider-field" style="padding-left: 14px">
            <span>{{ "bag.property-max" | translate }}</span>
            <input
              numberOnly
              [(ngModel)]="optcfg.maxValue"
              (change)="onChangeOptions('maxValue', optcfg.maxValue)"
              style="width: 50px; text-align: center; display: inline-block"
              type="text"
              name="maxValue"
            />
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-bar-width" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="70"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.lineWidth"
              (input)="onChangeOptions('lineWidth', $event)"
              name="lineWidth"
            >
            </mat-slider>
          </div>
        </div>
        <div class="field-row">
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-animation-speed" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="128"
              [min]="1"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.animationSpeed"
              (input)="onChangeOptions('animationSpeed', $event)"
              name="animationSpeed"
            >
            </mat-slider>
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-angle" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="50"
              [min]="-50"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.angle"
              (input)="onChangeOptions('angle', $event)"
              name="angle"
            >
            </mat-slider>
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-radius" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="100"
              [min]="50"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.radiusScale"
              (input)="onChangeOptions('radiusScale', $event)"
              name="radiusScale"
            >
            </mat-slider>
          </div>
        </div>
        <div class="field-row">
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-font-size" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="100"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.fontSize"
              (input)="onChangeOptions('fontSize', $event)"
              name="fontSize"
            >
            </mat-slider>
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-textfield-position" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="100"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.textFilePosition"
              (input)="onChangeOptions('textFilePosition', $event)"
              name="textFilePosition"
            >
            </mat-slider>
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-format-digits" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="5"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.fractionDigits"
              (input)="onChangeOptions('fractionDigits', $event)"
              name="fractionDigits"
            >
            </mat-slider>
          </div>
        </div>
        <div class="field-row">
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-pointer-length" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="100"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.pointer.length"
              (input)="onChangeOptionsPointer('pointerLength', $event)"
              name="pointerLength"
            >
            </mat-slider>
          </div>
          <div class="my-form-field slider-field" style="padding-left: 12px">
            <span>{{ "bag.property-pointer-stroke" | translate }}</span>
            <mat-slider
              style="display: inline-block"
              [max]="300"
              [min]="0"
              [step]="1"
              discrete
              [(ngModel)]="optcfg.pointer.strokeWidth"
              (input)="onChangeOptionsPointer('pointerStrokeWidth', $event)"
              name="pointerStrokeWidth"
            >
            </mat-slider>
          </div>
          <div
            class="my-form-field slider-field color-field"
            style="padding-left: 12px"
          >
            <span>{{ "bag.property-pointer-color" | translate }}</span>
            <input
              [(colorPicker)]="optcfg.pointer.color"
              [cpAlphaChannel]="'always'"
              [style.background]="optcfg.pointer.color"
              class="input-color"
              [cpPresetColors]="defaultColor"
              [cpOKButton]="true"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'cpCancelButtonClass'"
              [cpCancelButtonText]="'Cancel'"
              [cpOKButtonText]="'OK'"
              [cpOKButtonClass]="'cpOKButtonClass'"
              style="width: 121px"
              [cpPosition]="'top'"
              (colorPickerChange)="onChangeOptionsPointer('color', $event)"
              name="color"
            />
          </div>
        </div>
        <div class="field-row slider-field">
          <div class="my-form-field color-field" style="padding-left: 12px">
            <span>{{ "bag.property-color-start" | translate }}</span>
            <input
              [(colorPicker)]="optcfg.colorStart"
              [style.background]="optcfg.colorStart"
              [cpAlphaChannel]="'always'"
              class="input-color"
              [cpPresetColors]="defaultColor"
              [cpOKButton]="true"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'cpCancelButtonClass'"
              [cpCancelButtonText]="'Cancel'"
              [cpOKButtonText]="'OK'"
              [cpOKButtonClass]="'cpOKButtonClass'"
              style="width: 121px"
              [cpPosition]="'top'"
              (colorPickerChange)="onChangeOptions('colorStart', $event)"
              name="colorStart"
            />
          </div>
          <div
            class="my-form-field slider-field color-field"
            style="padding-left: 12px"
          >
            <span>{{ "bag.property-color-stop" | translate }}</span>
            <input
              [(colorPicker)]="optcfg.colorStop"
              [style.background]="optcfg.colorStop"
              [cpAlphaChannel]="'always'"
              class="input-color"
              [cpPresetColors]="defaultColor"
              [cpOKButton]="true"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'cpCancelButtonClass'"
              [cpCancelButtonText]="'Cancel'"
              [cpOKButtonText]="'OK'"
              [cpOKButtonClass]="'cpOKButtonClass'"
              style="width: 121px"
              [cpPosition]="'top'"
              (colorPickerChange)="onChangeOptions('colorStop', $event)"
              name="colorStop"
            />
          </div>
          <div
            class="my-form-field slider-field color-field"
            style="padding-left: 12px"
          >
            <span>{{ "bag.property-background" | translate }}</span>
            <input
              [(colorPicker)]="optcfg.strokeColor"
              [style.background]="optcfg.strokeColor"
              [cpAlphaChannel]="'always'"
              class="input-color"
              [cpPresetColors]="defaultColor"
              [cpOKButton]="true"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'cpCancelButtonClass'"
              [cpCancelButtonText]="'Cancel'"
              [cpOKButtonText]="'OK'"
              [cpOKButtonClass]="'cpOKButtonClass'"
              style="width: 121px"
              [cpPosition]="'top'"
              (colorPickerChange)="onChangeOptions('strokeColor', $event)"
              name="colorStop"
            />
          </div>
        </div>
        <div class="field-row slider-field">
          <div class="my-form-field" style="padding-left: 12px; width: 160px">
            <span>{{ "bag.property-font" | translate }}</span>
            <mat-select
              [(value)]="options.fontFamily"
              (selectionChange)="onChangeOptionsLabels('fontFamily', $event)"
            >
              <mat-option
                *ngFor="let font of fonts"
                [style.fontFamily]="font"
                [value]="font"
              >
                {{ font }}
              </mat-option>
            </mat-select>
          </div>
          <!-- <div class="my-form-field slider-field color-field" style="padding-left: 12px">
                        <span>{{'bag.property-background' | translate}}</span>
                        <input [(colorPicker)]="optcfg.backgroundColor" [style.background]="optcfg.backgroundColor" [cpAlphaChannel]="'always'" class="input-color" [cpPresetColors]="defaultColor" [cpOKButton]="true" [cpCancelButton]="true" [cpCancelButtonClass]="'cpCancelButtonClass'" [cpCancelButtonText]="'Cancel'" [cpOKButtonText]="'OK'" [cpOKButtonClass]="'cpOKButtonClass'" style="padding: 8px 0 0 0; width: 126px" [cpPosition]="'top'" (colorPickerChange)="onChangeOptions('backgroundColor', $event)" />
                    </div> -->
        </div>
      </div>
      <div class="toolbox-right" *ngIf="gaugeType == gaugeTypeEnum.Zones">
        <div style="position: absolute; top: 0px; right: 0px">
          <span>{{ "bag.property-zones" | translate }}</span>
          <button
            mat-icon-button
            (click)="onAddZone()"
            [disabled]="optcfg.staticZones && optcfg.staticZones.length >= 5"
          >
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </div>
        <div style="margin-top: 20px; margin-bottom: 20px; height: 250px">
          <div
            *ngFor="let item of optcfg.staticZones; index as i"
            style="display: block"
          >
            <div class="my-form-field slider-field">
              <span>{{ "bag.property-min" | translate }}</span>
              <input
                [(ngModel)]="item.min"
                style="width: 60px; text-align: center"
                type="text"
                (change)="onChangeStaticZones()"
                name="min"
              />
            </div>
            <div class="my-form-field slider-field" style="padding-left: 12px">
              <span>{{ "bag.property-max" | translate }}</span>
              <input
                [(ngModel)]="item.max"
                style="width: 60px"
                type="text"
                (change)="onChangeStaticZones()"
                name="max"
              />
            </div>
            <div
              class="my-form-field slider-field color-field"
              style="padding-left: 12px"
            >
              <span>{{ "bag.property-color" | translate }}</span>
              <input
                [cpDialogDisplay]="'popup'"
                [(colorPicker)]="item.strokeStyle"
                [cpAlphaChannel]="'always'"
                class="input-color"
                [style.background]="item.strokeStyle"
                [cpPresetColors]="defaultColor"
                [cpOKButton]="true"
                [cpCancelButton]="true"
                [cpCancelButtonClass]="'cpCancelButtonClass'"
                [cpCancelButtonText]="'Cancel'"
                [cpOKButtonText]="'OK'"
                [cpOKButtonClass]="'cpOKButtonClass'"
                style="width: 58px"
                [cpPosition]="'top'"
                (colorPickerChange)="onChangeStaticZones()"
                name="popup"
              />
            </div>
            <div class="my-form-field" style="line-height: 40px">
              <button mat-icon-button (click)="onRemoveZone(i)" class="remove">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-action">
    <button mat-raised-button (click)="onNoClick()">
      {{ "dlg.cancel" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="onOkClick()"
      [mat-dialog-close]="data"
      cdkFocusInitial
    >
      {{ "dlg.ok" | translate }}
    </button>
  </div>
</div>
