import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChannelCategoryService } from '@service/channel-category.service';
import { ChannelExcelService } from '@service/channel-excel.service';
import { ChannelGroupsService } from '@service/channel-groups.service';
import { ChannelSheetService, UpdateType } from '@service/channel-sheet.service';
import { ProjectService } from '@service/project.service';
import { ChannelCategory } from 'common/models/channel-category';
import { ChannelGroup } from 'common/models/channel-group';
import { ErrorNameLookup } from 'icreate/common/excel-import/error';
import { ToastrService } from 'ngx-toastr';
import { ChannelSheetComponent } from './channel-sheet/channel-sheet.component';
import { Subscription } from 'rxjs';
import { ProjectData } from 'icreate/common/models/project';
import { NavigationService } from '@service/navigation.service';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';

@Component({
    selector: 'app-channel',
    templateUrl: './channel.component.html',
    styleUrls: ['./channel.component.css'],
})
export class ChannelComponent {
    @ViewChild('channelSheet') channelSheet: ChannelSheetComponent;
    @ViewChild('fileInput') fileInput!: ElementRef;
    @ViewChild('confirm') confirm: DialogConfirmComponent;
    @Output() confirmEmitter = new EventEmitter<boolean>();
    showPannel = false;
    tableWidth = 0;
    readonly excelImportError = 'excel-channel-import.errors';

    channelGroups: ChannelGroup[] = [];
    activeGroup: ChannelGroup;
    selectedFile: File;
    channelCategories: ChannelCategory[] = [];
    project: ProjectData = new ProjectData();

    private activeSubscriptions: Subscription[] = [];
    private updateChannelSheetSub: Subscription;
    private updateChannelGroupSub: Subscription;
    private updateChannelCategorySub: Subscription;
    private selectGroupSub: Subscription;

    constructor(
        private channelGroupsService: ChannelGroupsService,
        private projectService: ProjectService,
        private channelExcelService: ChannelExcelService,
        private channelSheetService: ChannelSheetService,
        private toastr: ToastrService,
        private translateService: TranslateService,
        private channelCategoryService: ChannelCategoryService,
        private navigationService: NavigationService,
    ) {}

    async ngOnInit() {
        this.project = await this.projectService.getProjectData(this.projectService.getProjectId());
        await this.updateChannelCategory();
        await this.updateChannelGroup();
        if (this.channelGroups.length > 0) {
            this.updateActiveGroup(this.channelGroups[0]);
            this.channelSheetService.setActiveGroup(this.activeGroup);
        }

        this.updateChannelSheetSub = this.channelSheetService.updateChannelSheetEmitter.subscribe(
            async () => {
                await this.updateChannelGroup();
                if (
                    this.channelGroups.length > 0 &&
                    this.activeGroup.id !== this.channelSheetService._activeGroup.id
                ) {
                    this.updateActiveGroup(this.channelSheetService._activeGroup);
                    this.channelSheet.reloadSheet();
                }
            },
        );

        this.updateChannelGroupSub = this.channelSheetService.updateChannelGroupEmitter.subscribe(
            async (updateType) => {
                await this.updateChannelGroup();
                this.updateActiveGroup(this.channelSheetService._activeGroup);
                if (updateType != UpdateType.update) {
                    this.channelSheetService.selectGroup(this.activeGroup);
                    this.channelSheet.updateActiveGroup(this.activeGroup);
                    this.channelSheet.reloadSheet();
                }
            },
        );

        this.selectGroupSub = this.channelSheetService.selectGroupEmitter.subscribe((group) => {
            if (this.activeGroup.id !== group.id) {
                this.updateActiveGroup(group);
                this.channelSheet.reloadSheet();
            }
        });

        this.updateChannelCategorySub =
            this.channelSheetService.updateChannelCategoryEmitter.subscribe(async () => {
                await this.updateChannelCategory();
                await this.updateChannelGroup();
            });

        this.activeSubscriptions.push(
            this.updateChannelSheetSub,
            this.updateChannelGroupSub,
            this.selectGroupSub,
            this.updateChannelCategorySub,
        );
    }

    ngOnDestroy() {
        for (let i = 0; i < this.activeSubscriptions.length; i++) {
            if (this.activeSubscriptions[i]) {
                this.activeSubscriptions[i].unsubscribe();
            }
        }
    }

    onOpenPanel() {
        this.showPannel = this.showPannel ? false : true;
    }

    selectedFileChange(e: any) {
        this.selectedFile = e.target.files[0];
    }

    async importExcelFile() {
        if (this.selectedFile) {
            try {
                await this.channelExcelService.importExcelFile(
                    this.selectedFile,
                    this.projectService.getProjectId(),
                );

                this.channelGroups = await this.channelGroupsService.getChannelGroups(
                    this.projectService.getProjectId(),
                );
                this.activeGroup = this.channelGroups[0];
                this.channelCategories = await this.channelCategoryService.getChannelCategories(
                    this.projectService.getProjectId(),
                );
            } catch (e) {
                if (e?.error.errorCode && e?.error.properties) {
                    let msg = this.translateService.instant(
                        `${this.excelImportError}.${ErrorNameLookup[e.error.errorCode]}`,
                        e.error.properties,
                    );
                    this.showErrorMsg(msg);
                } else {
                    this.showErrorMsg('Failed to load channel list');
                }
            } finally {
                this.selectedFile = undefined;
                if (this.fileInput) {
                    this.fileInput.nativeElement.value = '';
                }
            }
        }
    }

    showErrorMsg(msg: string) {
        this.toastr.error(msg, '', {
            timeOut: 4000,
            closeButton: true,
        });
    }

    async updateChannelGroup() {
        this.channelGroups = await this.channelGroupsService.getChannelGroups(
            this.projectService.getProjectId(),
        );
    }

    async updateChannelCategory() {
        this.channelCategories = await this.channelCategoryService.getChannelCategories(
            this.projectService.getProjectId(),
        );
    }

    updateActiveGroup(group: ChannelGroup) {
        this.activeGroup = group;
    }

    onSave() {
        this.channelSheet.save();
    }

    onCancel() {
        if (!this.channelSheet) {
            this.navigationService.toEditor(this.projectService.getProjectId());
        } else {
            if (this.channelSheet.isChannelUpdate()) {
                this.confirm.setContentMsg('channel.confirm.msg');
                this.confirm.open();
                this.confirmEmitter.subscribe((confirm) => {
                    if (confirm) {
                        this.channelSheet.save();
                    }
                    this.navigationService.toEditor(this.projectService.getProjectId());
                });
            } else {
                this.navigationService.toEditor(this.projectService.getProjectId());
            }
        }
    }

    isButtonDisabled() {
        return this.channelSheet && !this.channelSheet.isLoading
            ? !this.channelSheet.isChannelUpdate()
            : true;
    }

    isShowElements() {
        return this.channelSheet ? !this.channelSheet.isLoading : false;
    }

    onConfirm(event: boolean) {
        this.confirmEmitter.emit(event);
    }
}
