import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-project-confirm-dialog',
    templateUrl: './project-confirm-dialog.component.html',
    styleUrls: ['./project-confirm-dialog.component.scss'],
})
export class ProjectConfirmDialogComponent {
    message: string;
    confirmedContents: string;

    constructor(
        public dialogRef: MatDialogRef<ProjectConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (this.data.type === ProjectConfirmDialogType.PullBlobConfirm) {
            this.confirmedContents = `VesselCompany: ${data.contents.VesselCompanyName}  VesselName: ${data.contents.VesselName}`;
            this.message = 'project-blob.pull-blob.confirmation-message';
        } else if (this.data.type === ProjectConfirmDialogType.CompareMimics) {
            this.confirmedContents = `VesselCompany: ${data.contents.VesselCompanyName}  VesselName: ${data.contents.VesselName}`;
            this.message = 'project-blob.compare-blob.confirmation-message';
        } else if (this.data.type === ProjectConfirmDialogType.CompareChannels) {
            this.confirmedContents = `VesselCompany: ${data.contents.VesselCompanyName}  VesselName: ${data.contents.VesselName}`;
            this.message = 'project-blob.compare-channels.confirmation-message';
        } else if (this.data.type === ProjectConfirmDialogType.CreateNewProject) {
            this.confirmedContents = `ProjectName: ${data.contents.projectName} \n VesselCompany: ${data.contents.vesselBuildData.name} \n VesselName: ${data.contents.vesselData.name} \n`;
            this.message = 'project-blob.create-project.confirmation-message';
        } else if (this.data.type === ProjectConfirmDialogType.EditProject) {
            this.message = 'project-blob.edit-project.confirmation-message';
            this.confirmedContents = `VesselCompany: ${
                data.contents.vesselBuildData.name
            } \n VesselName: ${data.contents.vesselData.name} \n VesselNumber: ${
                data.contents.vesselData.vesselNumber !== null
                    ? data.contents.vesselData.vesselNumber
                    : ''
            }`;
        }
    }

    onOk(): any {
        this.dialogRef.close(true);
    }

    onCancel(): any {
        this.dialogRef.close(false);
    }
}

export enum ProjectConfirmDialogType {
    PublishBlobConfirm = 'PublishBlobConfirm',
    PullBlobConfirm = 'PullBlobConfirm',
    CreateNewProject = 'CreateNewProject',
    EditProject = 'EditProject',
    CompareMimics = 'CompareMimics',
    CompareChannels = 'CompareChannels',
}
