import { Injectable } from '@angular/core';
import { SocketMiddlewareService } from './../socket-middleware.service';
import { GraphEvents } from 'icreate/common/io-event/websocket-message';

@Injectable({
    providedIn: 'root',
})
export class GraphSocketService {

    constructor(
        private socketService: SocketMiddlewareService,
    ) {}

    lockedGraphs$() {
        return this.socketService.receiveMessageObserver(GraphEvents.GRAPH_LOCKED);
    }

    unlockedGraphs$() {
        return this.socketService.receiveMessageObserver(GraphEvents.GRAPH_UNLOCKED);
    }

    deletedGraphs$() {
        return this.socketService.receiveMessageObserver(GraphEvents.GRAPH_DELETED);
    }

    newGraph$() {
        return this.socketService.receiveMessageObserver(GraphEvents.GRAPH_CREATED);
    }

    updateGraph$() {
        return this.socketService.receiveMessageObserver(GraphEvents.GRAPH_UPDATED);
    }
}
