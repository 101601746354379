import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@service/auth.service';
import { EnvironmentService } from '@service/environment.service';
import { LocalStorageService } from '@service/local-storage.service';
import { RuntimeEnvironments } from 'src/app/models/runtime-enviroment.enum';

@Component({
    selector: 'app-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.css'],
})
export class VerifyComponent {
    private env: RuntimeEnvironments;
    private projectId: string;
    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private environmentService: EnvironmentService,
        private localstorageService: LocalStorageService,
    ) {
        this.env = this.environmentService.getEnvironment();
        this.projectId = this.localstorageService.getCurrentProjectId();
        if (!this.activatedRoute.snapshot.routeConfig?.path) {
            if (this.projectId) {
                this.router.navigate(['/editor', this.projectId], {
                    state: { projectId: this.projectId },
                });
            } else {
                this.router.navigate(['/editor'], {
                    state: { projectId: this.projectId },
                });
            }
        } else {
            this.authService.setLogInned(true);
            this.router.navigate(['/editor']);
        }
    }

    ngOnInit(): void {}
}
