<div class="my-form-field">
    <span>{{'gauges.property-name' | translate}}</span>
    <input [(ngModel)]="name" style="width: 220px" type="text">
</div>
<div class="my-form-field" style="vertical-align: bottom; margin-left: 13px;">
    <span>{{'gauges.property-permission' | translate}}</span>
    <div style="text-align:center;cursor:pointer;" class="my-form-field-permission" (click)="onEditPermission()">
        <mat-icon class="header-icon" style="line-height: 30px;" *ngIf="!permission">lock_open</mat-icon>
        <mat-icon class="header-icon" style="line-height: 30px;" *ngIf="permission">lock</mat-icon>
    </div>
</div>