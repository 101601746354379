import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-blob-version-selector',
    templateUrl: './blob-version-selector.component.html',
    styleUrls: ['./blob-version-selector.component.scss'],
})
export class BlobVersionSelectorComponent {
    searchText: string;

    public builderName: string;
    public vesselName: string;
    public verionList: string[];

    constructor(
        public dialogRef: MatDialogRef<BlobVersionSelectorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.verionList = data.versionList;
        this.builderName = data.builderName;
        this.vesselName = data.vesselName;
    }

    rowClick = (p) => {
        this.dialogRef.close(p);
    };
}
