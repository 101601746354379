<div class="bg-image" [class.icreate-bg-image]="'cloud'">
  <img class="splash-logo" [src]="'/assets/images/icreate-logo.png'" />
  <h1 class="title">iCreate</h1>
  <div class="sign-in">
    <h1>Welcome to iCreate</h1>
    <p>ご契約済みの方はこちら/ Account holders click here:</p>
    <button class="sign-in-button" (click)="onLogin()">
      サインイン / Sign in
    </button>
    <br />
    <br />
    <br />
    <br />
    <br />
    <img
      id="contact"
      style="cursor: pointer"
      (click)="openContactForm()"
      src="/assets/icons/contact-us.svg"
    />
    <a href="https://infoceanus.com" target="_blank" rel="noopener noreferrer"
      ><img
        class="sign-in-infoceanus-logo"
        src="/assets/images/infoceanus.webp"
    /></a>
  </div>
</div>
