<td
    [ngClass]="[
        operationType,
        'left',
        'index-td',
        diffItemRow.blob !== diffItemRow.local ? 'diff' : ''
    ]"
>
    <span
        [ngClass]="[
            diffItemRow.blob !== diffItemRow.local ? 'delete' : '',
            'index-items'
        ]"
        >{{ rowNum }}</span
    >
</td>
<td
    [ngClass]="[
        operationType,
        'left',
        diffItemRow.blob !== diffItemRow.local ? 'diff' : ''
    ]"
>
    <span
        [ngClass]="[
            'obj-item-' + diffItemRow.flattenKey.split('.').length.toString()
        ]"
        >{{ diffItemRow.key }} : {{ diffItemRow.blob }}</span
    >
</td>
<td
    [ngClass]="[
        operationType,
        'right',
        'index-td',
        diffItemRow.blob !== diffItemRow.local ? 'diff' : ''
    ]"
>
    <span
        [ngClass]="[
            diffItemRow.blob !== diffItemRow.local ? 'add' : '',
            'index-items'
        ]"
        >{{ rowNum }}</span
    >
</td>
<td
    [ngClass]="[
        operationType,
        'right',
        diffItemRow.blob !== diffItemRow.local ? 'diff' : ''
    ]"
>
    <span
        [ngClass]="[
            'obj-item-' + diffItemRow.flattenKey.split('.').length.toString()
        ]"
        >{{ diffItemRow.key }} : {{ diffItemRow.local }}</span
    >
</td>
