import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-new-comment',
    templateUrl: './new-comment.component.html',
    styleUrls: ['./new-comment.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class NewCommentComponent {
    @Input() newCommentPosition;
    @Input() scale;
    @Output() onSubmitComment = new EventEmitter();

    commentInput: String = '';

    postComment() {
        let top = Number(this.newCommentPosition.top.slice(0, -2)) / this.scale;
        const left = Number(this.newCommentPosition.left.slice(0, -2)) / this.scale;
        if (this.scale > 1) {
            top = top - 24;
        } else if (this.scale < 1) {
            top = top + 24;
        }
        if (this.commentInput.length > 0) {
            const data = {
                comment: this.commentInput,
                positionTop: top.toString() + 'px',
                positionLeft: left.toString() + 'px',
            };
            this.onSubmitComment.emit(JSON.stringify(data));
        }
    }
}
