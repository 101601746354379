<div style="width: 100%; position: relative">
  <div *ngIf="isToRemove" style="margin-top: 20px; margin-bottom: 20px">
    {{ "msg.device-remove" | translate }} '{{ data.device.name }}' ?
  </div>
  <div *ngIf="!isToRemove">
    <h1
      mat-dialog-title
      style="display: inline-block; cursor: move; padding-top: 15px"
      mat-dialog-draggable
    >
      {{ "device.property-client" | translate }}
    </h1>
    <mat-icon
      (click)="onNoClick()"
      style="
        float: right;
        cursor: pointer;
        color: gray;
        position: relative;
        top: 10px;
        right: 0px;
      "
      >clear</mat-icon
    >
  </div>
  <div
    style="overflow-y: hidden; overflow-x: hidden; padding-top: 5px"
    *ngIf="!isToRemove"
  >
    <div style="display: block; padding: 20px">
      <div
        class="my-form-field"
        style="display: flex; margin-bottom: 10px; justify-content: center"
      >
        <mat-form-field>
          <span>{{ "device.property-fcu-number" | translate }}</span>
          <mat-select
            [(value)]="data.device.name"
            #devicetype
            style="width: 160px"
            (selectionChange)="checkFormErrors()"
          >
            <mat-option *ngFor="let id of fcuIds" [value]="id">
              {{ id }}
            </mat-option>
          </mat-select>
          <div
            *ngIf="hasErrorString('name')"
            class="error-container text light"
          >
            <mat-icon svgIcon="error" class="error-icon"></mat-icon>
            <span data-testid="errorSetPoint">
              {{ getErrorString("name") }}</span
            >
          </div>
        </mat-form-field>
      </div>
      <div
        class="my-form-field"
        style="display: inline-block; margin-bottom: 10px"
      >
        <span>{{ "device.property-type" | translate }}</span>
        <mat-select
          [(value)]="data.device.type"
          (selectionChange)="onDeviceTypeChanged()"
          #devicetype
          style="width: 160px"
        >
          <mat-option
            *ngFor="let type of deviceType | enumToArray"
            [value]="type.key"
          >
            {{ type.value }}
          </mat-option>
        </mat-select>
      </div>
      <div
        class="my-form-field"
        style="display: inline-block; margin-left: 15px"
        *ngIf="isWithPolling"
      >
        <span>{{ "device.property-polling" | translate }}</span>
        <mat-select [(value)]="data.device.polling" style="width: 80px">
          <mat-option
            *ngFor="let polling of pollingType"
            [value]="polling.value"
          >
            {{ polling.text }}
          </mat-option>
        </mat-select>
      </div>
      <div
        class="my-form-field"
        style="
          display: inline-block;
          margin-bottom: 10px;
          text-align: center;
          width: 80px;
        "
      >
        <span *ngIf="data.device.type === deviceType.WebStudio">{{
          "device.property-subscribe" | translate
        }}</span>
        <span *ngIf="data.device.type !== deviceType.WebStudio">{{
          "device.property-enable" | translate
        }}</span>
        <mat-slide-toggle
          color="primary"
          [(ngModel)]="data.device.enabled"
        ></mat-slide-toggle>
      </div>
      <div
        *ngIf="data.device.property && data.device.type"
        [ngSwitch]="data.device.type"
      >
        <div *ngSwitchCase="deviceType.AmsInterface">
          <div style="display: flex; margin-bottom: 10px">
            <div class="my-form-field" style="display: inline-block">
              <span>Local Address</span>
              <input
                [(ngModel)]="data.device.property[0].localaddress"
                style="width: 262px"
                type="ip"
                (click)="onAddressChanged()"
                (input)="checkFormErrors()"
              />
              <div
                *ngIf="hasErrorString('property[0].localaddress')"
                class="error-container text light"
              >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                  {{ getErrorString("property[0].localaddress") }}</span
                >
              </div>
            </div>
            <div
              class="my-form-field"
              style="display: inline-block; margin-left: 15px"
            >
              <span>Port</span>
              <input
                type="number"
                [(ngModel)]="data.device.property[0].localport"
                min="0"
                max="65536"
                style="width: 65px"
                (input)="checkFormErrors()"
              />
              <div
                *ngIf="hasErrorString('property[0].localport')"
                class="error-container text light"
              >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                  {{ getErrorString("property[0].localport") }}</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div class="my-form-field" style="display: inline-block">
              <span>AMS Address</span>
              <input
                [(ngModel)]="data.device.property[0].amsaddress"
                style="width: 262px"
                type="ip"
                (input)="checkFormErrors()"
              />
              <div
                *ngIf="hasErrorString('property[0].amsaddress')"
                class="error-container text light"
              >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                  {{ getErrorString("property[0].amsaddress") }}</span
                >
              </div>
            </div>
            <div
              class="my-form-field"
              style="display: inline-block; margin-left: 15px"
            >
              <span>Port</span>
              <input
                type="number"
                [(ngModel)]="data.device.property[0].amsport"
                min="0"
                max="65536"
                style="width: 65px"
                (input)="checkFormErrors()"
              />
              <div
                *ngIf="hasErrorString('property[0].amsport')"
                class="error-container text light"
              >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                  {{ getErrorString("property[0].amsport") }}</span
                >
              </div>
            </div>
          </div>
          <div class="my-form-second-property">
            <span>Please add a second address</span>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div class="my-form-field" style="display: inline-block">
              <span>Local Address</span>
              <input
                [(ngModel)]="data.device.property[1].localaddress"
                style="width: 262px"
                type="ip"
                (input)="checkFormErrors()"
              />
              <div
                *ngIf="hasErrorString('property[1].localaddress')"
                class="error-container text light"
              >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                  {{ getErrorString("property[1].localaddress") }}</span
                >
              </div>
            </div>
            <div
              class="my-form-field"
              style="display: inline-block; margin-left: 15px"
            >
              <span>Port</span>
              <input
                type="number"
                [(ngModel)]="data.device.property[1].localport"
                min="0"
                max="65536"
                style="width: 65px"
                (input)="checkFormErrors()"
              />
              <div
                *ngIf="hasErrorString('property[1].localport')"
                class="error-container text light"
              >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                  {{ getErrorString("property[1].localport") }}</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div class="my-form-field" style="display: inline-block">
              <span>AMS Address</span>
              <input
                [(ngModel)]="data.device.property[1].amsaddress"
                style="width: 262px"
                type="ip"
                (input)="checkFormErrors()"
              />
              <div
                *ngIf="hasErrorString('property[1].amsaddress')"
                class="error-container text light"
              >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                  {{ getErrorString("property[1].amsaddress") }}</span
                >
              </div>
            </div>
            <div
              class="my-form-field"
              style="display: inline-block; margin-left: 15px"
            >
              <span>Port</span>
              <input
                type="number"
                [(ngModel)]="data.device.property[1].amsport"
                min="0"
                max="65536"
                style="width: 65px"
                (input)="checkFormErrors()"
              />
              <div
                *ngIf="hasErrorString('property[1].amsport')"
                class="error-container text light"
              >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                  {{ getErrorString("property[1].amsport") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-action">
    <button mat-raised-button (click)="onNoClick()">
      {{ "dlg.cancel" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!isValid(data.device) && validationErrors != {}"
      (click)="onOkClick()"
      cdkFocusInitial
    >
      {{ "dlg.ok" | translate }}
    </button>
  </div>
</div>
