import { Injectable } from '@angular/core';
import { CanvasService } from './canvas.service';
import { SelectedElementsService } from './selected-elements.service';

@Injectable()
export class GroupService {
    constructor(
        private canvas: CanvasService,
        private select: SelectedElementsService,
    ) {}

    public groupElements(elements: SVGGraphicsElement[]) {
        this.select.setSelectedElements(elements);
        this.canvas.getCanvas().groupSelectedElements();
    }

    public ungroupElements(elements: SVGGraphicsElement[]) {
        this.select.setSelectedElements(elements);
        this.canvas.getCanvas().ungroupSelectedElement();
    }
}
