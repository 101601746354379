<div class="top-container">
    <!-- Channel list -->
    <div class="table-wrapper" (click)="unselectRow()">
        <table mat-table [dataSource]="tableDataSource" class="table">
            <ng-container matColumnDef="number">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="table-header index-header"
                ></th>
                <td
                    mat-cell
                    *matCellDef="let channel; let i = index"
                    class="table-row index"
                >
                    {{ i + 1 }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Ch. No.">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="table-header fixed-column"
                >
                    {{
                        "graph-settings.bar-graph.table.header.channel"
                            | translate
                    }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let channel"
                    class="table-row fixed-column"
                >
                    <app-search-channel-input
                        [channels]="channels"
                        [channelNumber]="channel.fcuChannelId"
                        (onChannelSelected)="channel.fcuChannelId = $event"
                        [channelError]="getChannelErrors(channel, 'fcuChannelId')"
                    ></app-search-channel-input>
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="table-header fixed-column"
                >
                    {{
                        "graph-settings.bar-graph.table.header.title"
                            | translate
                    }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let channel"
                    class="table-row fixed-column"
                >
                    <input
                        [(ngModel)]="channel.title"
                        maxlength="16"
                        class="title"
                        [value]="channel.title || ''"
                        placeholder="Channel Title"
                    />
                    <div
                        class="error-container text light"
                        *ngIf="getChannelErrors(channel, 'title')"
                    >
                        <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                        <span data-testid="errorSetPoint">
                            {{
                                getChannelErrors(channel, "title") | translate
                            }}</span
                        >
                    </div>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
                class="table-header"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                (click)="selectRow(row); $event.stopPropagation()"
                [ngStyle]="{
                    'background-color':
                        selectedRow === row ? 'rgba(48, 89, 175, 1)' : ''
                }"
            ></tr>
        </table>
    </div>

    <!--Graph Options -->
    <div class="options-container">
        <!--Display Option -->
        <div class="option-wrapper">
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                "
            >
                <label>{{
                    "graph-settings.bar-graph.display.label" | translate
                }}</label>
                <mat-radio-group
                    class="radio-button-group"
                    [(ngModel)]="graph.displayType"
                >
                    <mat-radio-button
                        *ngFor="let displayType of displayTypes"
                        class="radio-button"
                        [value]="displayType.value"
                        >{{
                            displayType.displayString | translate
                        }}</mat-radio-button
                    >
                </mat-radio-group>
            </div>
            <div
                class="error-container text light"
                *ngIf="getGraphErrors('displayType')"
            >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                    {{ getGraphErrors("displayType") | translate }}</span
                >
            </div>
        </div>

        <!--Divisions Option -->
        <div class="option-wrapper">
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                "
            >
                <label>{{
                    "graph-settings.bar-graph.division.label" | translate
                }}</label>
                <mat-radio-group
                    class="radio-button-group"
                    [(ngModel)]="graph.divisions"
                >
                    <mat-radio-button
                        *ngFor="let divisionsType of divisionsTypes"
                        class="radio-button"
                        [value]="divisionsType.value"
                        >{{
                            divisionsType.displayString | translate
                        }}</mat-radio-button
                    >
                </mat-radio-group>
            </div>
            <div
                class="error-container text light"
                *ngIf="getGraphErrors('divisions')"
            >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                    {{ getGraphErrors("divisions") | translate }}</span
                >
            </div>
        </div>
    </div>
</div>

<!-- Buttons Container -->
<div class="buttons-container">
    <button [disabled]="!canAddChannel()" class="add" (click)="addChannel()">
        {{ "graph-settings.bar-graph.button.add" | translate }}
    </button>
    <button [disabled]="!selectedRow" class="delete" (click)="deleteChannel()">
        {{ "graph-settings.bar-graph.button.delete" | translate }}
    </button>
    <button
        class="save"
        [disabled]="saveButtonIsDisabled()"
        (click)="saveGraph()"
    >
        {{ "graph-settings.bar-graph.button.save" | translate }}
    </button>
    <button class="exit" (click)="exit()">
        {{ "graph-settings.bar-graph.button.exit" | translate }}
    </button>
</div>

<app-confirmation-window
    #confirmationWindow
    [mainLabel]="'graph-settings.graph.close.confirmation.title' | translate"
    [confirmLabel]="
        'graph-settings.graph.close.confirmation.confirm' | translate
    "
    [cancelLabel]="'graph-settings.graph.close.confirmation.cancel' | translate"
></app-confirmation-window>
