<div class="wrapper">
    <h1 mat-dialog-title style="display:inline-block;cursor:move;">Select a builder</h1>
    <br>
    <div class="search-register-area">
        <label>Search: <input type="text" name="search" [(ngModel)]="searchText"></label>
        <button mat-raised-button matTooltip="Register New Company" class="main-btn" color="primary" (click)="onRegister()">
            Add New Builder
        </button>
    </div>
    <table>
            <tr>
                <th>Builder Name</th>
            </tr>
    </table>
    <div class="vesselBuilderSelectTable">
        <table>
            <tr *ngFor="let d of data | filter:searchText" (click)="rowClick(d)">
                <td>{{d.name}}</td>
            </tr>
    </table>
    </div>
</div>

