import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectList } from '@models/project';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ProjectListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Array<ProjectList>,
    ) {}

    ngOnInit(): void {}

    rowClick (p) {
        this.dialogRef.close(p);
    };
}
