<div class="top-container">
    <!-- Exhaust Table -->
    <app-exhaust-table
        class="exhaust-container"
        [exhaustChannels]="graph.exhaustChannels"
        [channels]="channels"
        (onTableUpdated)="handleExhaustTableChange($event)"
    ></app-exhaust-table>

    <!-- Turbo Charger Table -->
    <app-turbo-charger-table
        class="turbo-charger-container"
        [turboChannels]="graph.turboChargerChannels"
        [channels]="channels"
        (onTableUpdated)="handleTurboTableChange($event)"
    >
    </app-turbo-charger-table>
</div>

<!-- Options Container -->
<div class="options-wrapper">
    <div class="options-right">
        <div class="option-container">
            {{
                "graph-settings.exhaust-graph.options.average.label" | translate
            }}

            <app-search-channel-input
                [channels]="channels"
                [channelNumber]="graph.averageChannel"
                (onChannelSelected)="graph.averageChannel = $event"
                [channelError]="getGraphErrors('averageChannel')"
            ></app-search-channel-input>
        </div>
        <div class="option-container">
            {{
                "graph-settings.exhaust-graph.options.deviation.label"
                    | translate
            }}
            <div class="option-error-container">
                <input
                    type="number"
                    class="average-input"
                    [value]="graph.deviationLimit"
                    (input)="
                        graph.deviationLimit = inputLimit(
                            $event,
                            graph.deviationLimit
                        )
                    "
                />
                <div
                    class="error-container text light"
                    *ngIf="getGraphErrors('deviationLimit')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span data-testid="errorSetPoint">
                        {{ getGraphErrors("deviationLimit") | translate }}</span
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="option-container">
        {{
            "graph-settings.turbo-charger-graph.options.title.label" | translate
        }}
        <div class="option-error-container">
            <input
                [(ngModel)]="graph.turboChargerTitle"
                class="average-input"
                [value]="graph.turboChargerTitle || ''"
                maxlength="32"
            />
            <div
                class="error-container text light"
                *ngIf="getGraphErrors('turboChargerTitle')"
            >
                <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                <span data-testid="errorSetPoint">
                    {{ getGraphErrors("turboChargerTitle") | translate }}</span
                >
            </div>
        </div>
    </div>
</div>

<!-- Buttons Container -->
<div class="buttons-container">
    <button
        class="save"
        [disabled]="saveButtonIsDisabled()"
        (click)="saveGraph()"
    >
        {{ "graph-settings.exhaust-graph.button.save" | translate }}
    </button>
    <button class="exit" (click)="exit()">
        {{ "graph-settings.exhaust-graph.button.exit" | translate }}
    </button>
</div>

<app-confirmation-window
    #confirmationWindow
    [mainLabel]="'graph-settings.graph.close.confirmation.title' | translate"
    [confirmLabel]="
        'graph-settings.graph.close.confirmation.confirm' | translate
    "
    [cancelLabel]="'graph-settings.graph.close.confirmation.cancel' | translate"
></app-confirmation-window>
