<div *ngFor="let item of actions; index as i" class="item">
    <flex-variable class="item-head" [data]="data" (onchange)="setVariable(i, $event)" [variableId]="item.variableId" [withStaticValue]="false">
    </flex-variable>
    <div class="action-content">
        <div *ngIf="slideView && item.type!=actionAMS" class="item-range">
            <div class="my-form-field">
                <span>{{'gauges.property-input-min' | translate}}</span>
                <input numberOnly [(ngModel)]="item.range.min" type="text" style="width: 80px">
            </div>
            <div class="my-form-field" style="padding-left: 20px;">
                <span>{{'gauges.property-input-max' | translate}}</span>
                <input numberOnly [(ngModel)]="item.range.max" type="text" style="width: 80px">
            </div>
        </div>
        <div class="my-form-field" style="width: 300px;">
            <span>{{'gauges.property-action-type' | translate}}</span>
            <mat-select [(value)]="item.type" (selectionChange)="onCheckActionType(item.type, item)">
                <mat-option *ngFor="let ev of actionsSupported | enumToArray" [value]="ev.key">
                    {{ ev.value }}
                </mat-option>
            </mat-select>
        </div>
        <div class="item-remove">
            <button mat-icon-button (click)="onRemoveAction(i)" class="_remove">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        <div [ngSwitch]="item.type">
            <div *ngSwitchCase="actionBlink" class="action-blink">
                <div class="my-form-field">
                    <span>{{'gauges.property-input-color' | translate}}</span>
                    <input [(colorPicker)]="item.options.fillA" [value]="item.options.fillA" [style.background]="item.options.fillA" [cpPresetColors]="defaultColor" [cpOKButton]="true" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'cpCancelButtonClass'" [cpCancelButtonText]="'Cancel'" [cpOKButtonText]="'OK'" [cpOKButtonClass]="'cpOKButtonClass'" class="input-color"
                        style="width:70px;" [cpPosition]="'auto'" [cpAlphaChannel]="'always'" [cpOutputFormat]="'hex'"/>
                </div>
                <div class="my-form-field" style="margin-left: 10px;">
                    <span>{{'gauges.property-input-stroke' | translate}}</span>
                    <input [(colorPicker)]="item.options.strokeA" [value]="item.options.strokeA" [style.background]="item.options.strokeA" [cpPresetColors]="defaultColor" [cpOKButton]="true" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'cpCancelButtonClass'" [cpCancelButtonText]="'Cancel'" [cpOKButtonText]="'OK'" [cpOKButtonClass]="'cpOKButtonClass'" class="input-color"
                        style="width:70px;" [cpPosition]="'auto'" [cpAlphaChannel]="'always'" [cpOutputFormat]="'hex'"/>
                </div>
                <div class="my-form-field" style="padding-left: 35px;padding-right: 35px;">
                    <span>{{'gauges.property-interval-msec' | translate}}</span>
                    <input numberOnly [(ngModel)]="item.options.interval" type="text" style="width: 80px">
                </div>
                <div class="my-form-field">
                    <span>{{'gauges.property-input-color' | translate}}</span>
                    <input [(colorPicker)]="item.options.fillB" [value]="item.options.fillB" [style.background]="item.options.fillB" [cpPresetColors]="defaultColor" [cpOKButton]="true" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'cpCancelButtonClass'" [cpCancelButtonText]="'Cancel'" [cpOKButtonText]="'OK'" [cpOKButtonClass]="'cpOKButtonClass'" class="input-color"
                        style="width:70px;" [cpPosition]="'auto'" [cpAlphaChannel]="'always'" [cpOutputFormat]="'hex'"/>
                </div>
                <div class="my-form-field" style="margin-left: 10px;">
                    <span>{{'gauges.property-input-stroke' | translate}}</span>
                    <input [(colorPicker)]="item.options.strokeB" [value]="item.options.strokeB" [style.background]="item.options.strokeB" [cpPresetColors]="defaultColor" [cpOKButton]="true" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'cpCancelButtonClass'" [cpCancelButtonText]="'Cancel'" [cpOKButtonText]="'OK'" [cpOKButtonClass]="'cpOKButtonClass'" class="input-color"
                        style="width:70px;" [cpPosition]="'auto'" [cpAlphaChannel]="'always'" [cpOutputFormat]="'hex'"/>
                </div>
            </div>
            <div *ngSwitchCase="actionAMS" class="action-ams">
                <div class="my-form-field">
                    <span>Alarm Color</span>
                    <input [(colorPicker)]="item.options.alarmCol" [value]="item.options.alarmCol" [style.background]="item.options.alarmCol" [cpPresetColors]="defaultColor" [cpOKButton]="true" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'cpCancelButtonClass'" [cpCancelButtonText]="'Cancel'" [cpOKButtonText]="'OK'" [cpOKButtonClass]="'cpOKButtonClass'" class="input-color"
                        style="width:70px;" [cpPosition]="'auto'" [cpAlphaChannel]="'always'" [cpOutputFormat]="'hex'"/>
                </div>
                <div class="my-form-field" style="margin-left: 10px;">
                    <span>Sensor Color</span>
                    <input [(colorPicker)]="item.options.sensorCol" [value]="item.options.sensorCol" [style.background]="item.options.sensorCol" [cpPresetColors]="defaultColor" [cpOKButton]="true" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'cpCancelButtonClass'" [cpCancelButtonText]="'Cancel'" [cpOKButtonText]="'OK'" [cpOKButtonClass]="'cpOKButtonClass'" class="input-color"
                        style="width:70px;" [cpPosition]="'auto'" [cpAlphaChannel]="'always'" [cpOutputFormat]="'hex'"/>
                </div>
                <div class="my-form-field" style="margin-left: 10px;">
                    <span>Repose Color</span>
                    <input [(colorPicker)]="item.options.reposeCol" [value]="item.options.reposeCol" [style.background]="item.options.reposeCol" [cpPresetColors]="defaultColor" [cpOKButton]="true" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'cpCancelButtonClass'" [cpCancelButtonText]="'Cancel'" [cpOKButtonText]="'OK'" [cpOKButtonClass]="'cpOKButtonClass'" class="input-color"
                        style="width:70px;" [cpPosition]="'auto'" [cpAlphaChannel]="'always'" [cpOutputFormat]="'hex'"/>
                </div>
                <div class="my-form-field" style="margin-left: 10px;">
                    <span>Default Color</span>
                    <input [(colorPicker)]="item.options.defaultCol" [value]="item.options.defaultCol" [style.background]="item.options.defaultCol" [cpPresetColors]="defaultColor" [cpOKButton]="true" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'cpCancelButtonClass'" [cpCancelButtonText]="'Cancel'" [cpOKButtonText]="'OK'" [cpOKButtonClass]="'cpOKButtonClass'" class="input-color"
                        style="width:70px;" [cpPosition]="'auto'" [cpAlphaChannel]="'always'" [cpOutputFormat]="'hex'"/>
                </div>
                <div class="my-form-field" style="margin-left: 10px;">
                    <span>{{'gauges.property-interval-msec' | translate}}</span>
                    <input numberOnly [(ngModel)]="item.options.interval" type="text" style="width: 80px">
                </div>
            </div>
        </div>
    </div>
</div>
