import { Injectable } from '@angular/core';
import { RuntimeEnvironments } from 'src/app/models/runtime-enviroment.enum';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    constructor() {}

    getEnvironment(): RuntimeEnvironments {
        return environment.env;
    }
}
