import { Location } from '@angular/common';
import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectListComponent } from '@components//project-list/project-list.component';
import { Utils } from '@helpers/utils';
import { ProjectList } from '@models/project';
import { TranslateService } from '@ngx-translate/core';
import { View } from 'common/models/view';
import * as FileSaver from 'file-saver';
import { Device } from 'icreate/common/models/device';
import { ProjectData, ProjectDataCmdType } from 'icreate/common/models/project';
import { getValidationErrors } from 'icreate/common/validators';
import { viewValidator } from 'icreate/common/validators/view/view-validator';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { IapiService } from './iapi.service';
import { NotificationService } from './notification.service';
import { ProjectApiService } from './project-api.service';
import { ResWebApiService } from './reswebapi.service';
import { SocketService } from './socket.service';
import { UrlParserService } from './url-parser.service';

@Directive()
@Injectable()
export class ProjectService {
    @Output() onSaveCurrent: EventEmitter<SaveMode> = new EventEmitter();
    @Output() onLoadHmi: EventEmitter<string> = new EventEmitter();
    @Output() onLoadError: EventEmitter<any> = new EventEmitter();
    @Output() onViewLiveUpdate: EventEmitter<View> = new EventEmitter();
    @Output() onViewDelete: EventEmitter<View> = new EventEmitter();
    @Output() onDimensionUpdate: EventEmitter<void> = new EventEmitter();

    private projectData = new ProjectData(); // Project data
    public AppId = '';

    // public currentProjectName = '';

    // public serverSettings: ServerSettings;
    private ready = false;
    public projectNeedsReload: boolean = false;
    public projectDialogIsOpened: boolean = false;

    constructor(
        private translateService: TranslateService,
        private toastr: ToastrService,
        private socketService: SocketService,
        public dialog: MatDialog,
        public iapiService: IapiService,
        private projectApi: ProjectApiService,
        private location: Location,
        private urlParserService: UrlParserService,
    ) {
        // Handler for realtime updates
        socketService.getSocket().on('projectupdated', (cmd, data) => {
            // Only react if this update came from a different client
            this.handleProjectLiveUpdate(cmd, data);
        });
    }

    // Handle different ProjectDataCmdType that may occur in realtime
    public handleProjectLiveUpdate(cmd: ProjectDataCmdType, data: any) {
        if (cmd == ProjectDataCmdType.SetView) {
            this.handleLiveSetView(data);
        } else if (cmd == ProjectDataCmdType.DelView) {
            this.handleLiveDeleteView(data);
        }
    }

    // Find the view -> add the view if doesnt exist, update the view if it does. Emit event for editor component
    public handleLiveSetView(data): void {
        const foundView = this.findIndexOfView(data.id);
        if (foundView != -1) {
            this.projectData.hmi.views[foundView] = data;
            this.onViewLiveUpdate.emit(this.projectData.hmi.views[foundView]);
        } else {
            const newInd = this.projectData.hmi.views.push(data) - 1;
            this.onViewLiveUpdate.emit(this.projectData.hmi.views[newInd]);
        }
    }

    // Find the deleted view, remove it, then emit event to let editor component know a view was deleted
    public handleLiveDeleteView(data): void {
        const foundView = this.findIndexOfView(data.id);
        if (foundView != -1) {
            this.projectData.hmi.views.splice(foundView, 1);
            this.onViewDelete.emit(data);
        }
    }

    private findIndexOfView(id: string): number {
        let found = -1;
        this.projectData.hmi.views.forEach((v, i) => {
            if (v.id == id) found = i;
        });
        return found;
    }

    async openDialog(
        setLocal: boolean = true,
        disableCloseOption: boolean = false,
    ): Promise<ProjectList> {
        const projList = await this.getProjectList();
        if (this.projectDialogIsOpened) {
            return;
        }
        this.projectDialogIsOpened = true;
        const dialogRef = this.dialog.open(ProjectListComponent, {
            data: projList,
            disableClose: disableCloseOption,
        });
        const projectSelected = await lastValueFrom(dialogRef.afterClosed());
        if (projectSelected) {
            this.projectDialogIsOpened = false;
            dialogRef.close();
            return projectSelected;
        } else {
            this.projectDialogIsOpened = false;
            dialogRef.close();
        }
    }

    getAppId() {
        return this.AppId;
    }

    async getProjectList() {
        return lastValueFrom(this.projectApi.getProjectList());
    }

    //#region Load and Save
    /**
     * Load Project from Server if enable.
     * From Local Storage, from 'assets' if demo or create a local project
     */
    public load(projectId: string) {
        try {
            this.ready = true;
            this.notifyToLoadHmi(projectId);
            this.projectNeedsReload = false;

            // Alert the server that client is opening a project
            this.socketService.joinProject(projectId);
        } catch (err) {
            console.error('FUXA load error', err);
            this.notifyLoadError();
        }
    }

    async getProjectData(projectId: string): Promise<ProjectData> {
        this.projectData = await lastValueFrom(this.projectApi.getProject(projectId));
        return this.projectData;
    }

    /**
     * Save Project
     */
    save(projectData: ProjectData) {
        return new Promise<string>(async (res, rej) => {
            // check project change don't work some svg object change the order and this to check isn't easy...boooo

            this.projectApi.setServerProject(projectData).subscribe(
                (result) => {
                    const projectId = result.id;
                    this.load(result.id);
                    let msg = '';
                    this.translateService
                        .get('msg.project-save-success')
                        .subscribe((txt: string) => {
                            msg = txt;
                        });
                    this.toastr.success(msg);
                    res(projectId);
                },
                (err) => {
                    console.error(err);
                    let msg = '';
                    this.translateService.get('msg.project-save-error').subscribe((txt: string) => {
                        msg = txt;
                    });
                    this.toastr.error(msg, '', {
                        timeOut: 3000,
                        closeButton: true,
                        disableTimeOut: true,
                    });
                    rej();
                },
            );
        });
    }

    saveAs(projectData: ProjectData) {
        const filename = 'fuxa-project.json';
        const date = new Date();
        const content = JSON.stringify(this.convertToSave(projectData));
        const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, filename);
    }

    reload(projectId: string) {
        this.load(projectId);
    }

    /**
     * Remove Tag value to save without value
     * Value was added by ICreateHmiService from socketIo event
     * @param prj
     */
    private convertToSave(prj: ProjectData) {
        const result = JSON.parse(JSON.stringify(prj));
        for (const devid in result.devices) {
            for (const tagid in result.devices[devid].tags) {
                delete result.devices[devid].tags[tagid].value;
            }
        }
        return result;
    }
    //#endregion

    //#region Device to Save
    /**
     * Add or update Device to Project.
     * Save to Server
     * @param device
     * @param old
     */
    async setDevice(projectId: string, device: Device, security?: any) {
        await this.projectApi.saveDevice(projectId, device);
    }

    /**
     * Remove Device from Project.
     * Save to Server
     * @param device
     */
    async removeDevice(projectId: string, device: Device) {
        try {
            await this.projectApi.removeDevice(projectId, device);
        } catch (error) {
            this.notifySaveError(error);
        }
    }

    /**
     * Add or update View to Project.
     * Save to Server
     * @param view
     */
    setView(projectData: ProjectData, view: View, notify: boolean) {
        let v = null;
        const newItemsObj = {};
        for (let i = 0; i < projectData.hmi.views.length; i++) {
            if (projectData.hmi.views[i].id === view.id) {
                v = projectData.hmi.views[i];
            }
        }
        if (!view.projectId) {
            view.projectId = projectData.id;
        }

        if (v) {
            v = view;
        } else {
            projectData.hmi.views.push(view);
        }
        if (notify) {
            const items = Object.keys(v.items);
            const svgContent = new DOMParser().parseFromString(view.svgcontent, 'text/xml');
            for (let i = 0; i < items.length; i++) {
                if (svgContent.querySelector('#' + items[i])) {
                    newItemsObj[items[i]] = v.items[items[i]];
                }
            }
            for (let i = 0; i < projectData.hmi.views.length; i++) {
                if (projectData.hmi.views[i].id === view.id) {
                    projectData.hmi.views[i].items = newItemsObj;
                    projectData.hmi.views[i].svgcontent = view.svgcontent;
                }
            }
        }
        const validationErrors = getValidationErrors(viewValidator, view);
        if (validationErrors.length > 0) {
            this.notifySaveError(validationErrors);
            this.load(projectData.id);
            throw new Error('Project not validated');
        }

        this.projectApi.saveView(projectData.id, view).then(
            (result) => {
                if (notify) this.notifySaveSuccess();
                if (this.projectNeedsReload) this.load(projectData.id);
            },
            (err) => {
                console.error(err);
                this.notifySaveError(err);
            },
        );
    }

    private getProjectIdBySegments() {
        let segments = this.urlParserService.getSegment(this.location.path());
        if (segments[1]) {
            return segments[1].path;
        }
    }

    getProjectId() {
        if (this.projectData.id) {
            return this.projectData.id;
        }
        let projectId = this.getProjectIdBySegments();
        if (projectId) {
            return projectId;
        }
        projectId = localStorage.getItem('currentProject');
        if (projectId) {
            return projectId;
        }
    }

    /**
     * Remove the View from Project
     * Delete from Server
     * @param view
     */
    removeView(projecId: string, view: View) {
        this.projectApi.removeView(projecId, view).then(
            (result) => {
                this.load(projecId);
            },
            (err) => {
                console.error(err);
                this.notifySaveError(err);
            },
        );
    }

    //#region Notify
    private notifyToLoadHmi(projectId: string) {
        this.onLoadHmi.emit(projectId);
    }

    private notifyLoadError() {
        this.onLoadError.emit();
    }

    public notifyProjectSettingUpdateSuccess() {
        let msg = '';
        this.translateService.get('msg.project-setting-edit-success').subscribe((txt: string) => {
            msg = txt;
        });
        this.toastr.success(msg);
    }

    public notifySaveError(err: any) {
        console.error('FUXA notifySaveError error', err);
        let msg = null;
        this.translateService.get('msg.project-save-error').subscribe((txt: string) => {
            msg = txt;
        });
        if (err.status === 401) {
            this.translateService.get('msg.project-save-unauthorized').subscribe((txt: string) => {
                msg = txt;
            });
        }
        if (msg) {
            this.toastr.error(msg, '', {
                timeOut: 3000,
                closeButton: true,
                disableTimeOut: true,
            });
        }
    }

    public notifyServerError() {
        console.error('FUXA notifyServerError error');
        let msg = null;
        this.translateService.get('msg.server-connection-error').subscribe((txt: string) => {
            msg = txt;
        });
        if (msg) {
            this.toastr.error(msg, '', {
                timeOut: 3000,
                closeButton: true,
                disableTimeOut: true,
            });
        }
    }

    public notifyDeleteError(err: any) {
        console.error('FUXA notifyDeleteError error', err);
        let msg = null;
        this.translateService.get('msg.project-delete-error').subscribe((txt: string) => {
            msg = txt;
        });
        if (msg) {
            this.toastr.error(msg, '', {
                timeOut: 3000,
                closeButton: true,
                disableTimeOut: true,
            });
        }
    }

    public notifySaveSuccess() {
        this.toastr.success('Save successful!', '', {
            timeOut: 1000,
            closeButton: true,
        });
    }

    public notifyDeleteSuccess() {
        this.toastr.success('Delete successful!', '', {
            timeOut: 1000,
            closeButton: true,
        });
    }

    //#endregion

    /**
     * Set Project data and save resource to backend
     * Used from open and upload JSON Project file
     * @param prj project data to save
     */
    setProject(prj: ProjectData, notify?: boolean) {
        this.save(prj);
    }

    setNewProject(projectName: string, createdBy: string) {
        return new Promise<any>((res) => {
            let projectData = new ProjectData();
            projectData.name = projectName;
            projectData.createdBy = createdBy;
            this.save(projectData).then((projectId) => {
                if (!Utils.isNullOrUndefined(projectId)) {
                    this.projectApi
                        .setServerProjectParam({
                            id: projectId,
                            name: projectName,
                            createdBy: createdBy,
                        })
                        .then(() => res('finished'));
                    this.load(projectId);
                    localStorage.setItem('currentProject', String(projectId));
                }
            });
        });
    }

    async createNewProject(projectData: ProjectData, createdBy: string) {
        try {
            projectData.createdBy = createdBy;
            let newProject = await lastValueFrom(this.projectApi.createNewProject(projectData));
            this.notifySaveSuccess();
            return newProject;
        } catch (error) {
            console.log(error);
            this.notifySaveError(error);
            throw error;
        }
    }

    setProjectNewName(projectId: string, result: string, updatedBy: string): Promise<ProjectData> {
        return lastValueFrom(
            this.projectApi.setServerNewProjectName({
                id: projectId,
                name: result,
                updatedBy: updatedBy,
            }),
        );
    }

    cloneAndRename(pid: string, updatedBy: string, newName: string, view: View) {
        return new Promise<string>(async (res, rej) => {
            try {
                const result = await lastValueFrom(
                    this.projectApi.cloneProject({
                        projectId: pid,
                        updatedBy: updatedBy,
                        newName: newName,
                        view: view,
                    }),
                );
                res(result.id);
            } catch (err) {
                rej(err);
            }
        });
    }

    deleteProject(pid: string) {
        return new Promise<any>(async (res, rej) => {
            try {
                await lastValueFrom(this.projectApi.deleteProject({ projectId: pid }));
                res(true);
            } catch {
                rej(false);
            }
        });
    }

    getDeviceFromId(projectData: ProjectData, id: string): any {
        let result;
        projectData.amsConfig[1].device.forEach((k) => {
            if (k.id === id) {
                result = k;
            }
        });
        return result;
    }

    /**
     * Send Save Project to to editor component
     * @param saveas
     */
    saveProject(mode = SaveMode.Save) {
        this.onSaveCurrent.emit(mode);
    }
}

export class ServerSettings {
    version: string;
    secureEnabled: boolean;
}

export enum SaveMode {
    Current,
    Save,
    SaveAs,
}
