import { ObjectSchema, object, string, number } from 'yup';
import { locale } from './../yup-locale';
import * as yup from 'yup';
import { RunHourSetting } from './../../models/run-hour-setting';
yup.setLocale(locale);

export function RunHourSettingValidator(): ObjectSchema<RunHourSetting> {
    return object({
        id: string().uuid().strict(),
        projectId: string().uuid().nonNullable().strict().required(),
        sourceChannel: number().nonNullable().strict().required(),
        runHourChannel: number().nonNullable().strict().required(),
    }).required().strict().noUnknown().nonNullable() as ObjectSchema<RunHourSetting>;
}