import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpsUserPermissions } from 'common/models/ops-users';

@Component({
    selector: 'app-ops-user-permissions',
    templateUrl: './ops-user-permissions.component.html',
    styleUrls: ['./ops-user-permissions.component.scss'],
})
export class OpsUserPermissionsComponent implements OnInit{
    @Input() permissions: OpsUserPermissions;
    @Input() isAdmin: boolean = false;
    @Output() onPermissionChange = new EventEmitter()

    permissionsList: any = {}

    translationString: string = "ops-settings.users.permissions"

    ngOnInit(): void {
        const permssionsKeys = Object.keys(this.permissions)
        permssionsKeys.map((key) => {
            if (key !== 'id' && key !== 'userId') {
                this.permissionsList[key] = this.permissions[key]
            }
        })
    }

    permissionChanged(permission){
        this.onPermissionChange.emit(permission)
    }
}
