import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewVesselCreateService } from '@service/new-vessel-create.service';
@Component({
    selector: 'app-vessel-select-list',
    templateUrl: './vessel-select-list.component.html',
    styleUrls: ['./vessel-select-list.component.scss'],
})
export class VesselSelectListComponent implements OnInit {
    searchText: string;

    public builderName: string;
    private builderId: string;

    constructor(
        private newVesselCreateService: NewVesselCreateService,
        public dialogRef: MatDialogRef<VesselSelectListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        console.log(this.data);
        const builderInfoIndex = this.data.findIndex((d) => d.builder_id !== undefined);

        if (builderInfoIndex != -1) {
            this.builderId = this.data[builderInfoIndex].builder_id;
            this.builderName = this.data[builderInfoIndex].name;
            this.data.splice(builderInfoIndex, 1);
        }
    }

    rowClick = (p) => {
        console.log(p);
        this.dialogRef.close(p);
    };

    onRegister() {
        this.newVesselCreateService
            .openVesselInfoEnteringDialog(this.builderId)
            .then((res) => {
                if (res !== undefined && this.notEmpty(res)) {
                    console.log(res);
                    this.data.push(res);
                }
            })
            .catch((error) => {});
    }

    private notEmpty(obj): boolean {
        if (Object.keys(obj).length > 0) return true;
        return false;
    }
}
