import { Directive, EventEmitter, Injectable, Output } from '@angular/core';

import { environment } from '@environments/environment';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { OpsAmsConfigFileService } from '@service/ops-ams-config-file.service';
import { IoEventTypes } from 'icreate/common/io-event/websocket-message';
import { Hmi, Variable } from 'common/models/view';
import { DeviceFlags } from 'icreate/common/models/device-flags';
import { Observable } from 'rxjs';
import { ProjectService } from './project.service';
import { SocketMiddlewareService } from './socket-middleware.service';
@Directive()
@Injectable()
export class ICreateHmiService {
    @Output() onVariableChanged: EventEmitter<Variable> = new EventEmitter();
    @Output() onDeviceChanged: EventEmitter<boolean> = new EventEmitter();
    @Output() onAlarmsStatus: EventEmitter<any> = new EventEmitter();
    @Output() onUpdateFileData: EventEmitter<any> = new EventEmitter();

    public static separator = '^~^';
    public hmi: Hmi;
    variables = {};
    alarms = { highhigh: 0, high: 0, low: 0, info: 0 };
    private bridge: any = null;

    constructor(
        public projectService: ProjectService,
        private translateService: TranslateService,
        private toastr: ToastrService,
        private OpsAmsConfigFileService: OpsAmsConfigFileService,
        private socketService: SocketMiddlewareService,
    ) {
        if (environment.serverEnabled) {
            this.initSocket();
        }
    }

    /**
     * Set signal value in current frontend signal array
     * Called from Test and value beckame from backend
     * @param sig
     */
    setSignalValue(sig: Variable) {
        // update the signals array value

        // notify the gui
        this.onVariableChanged.emit(sig);
    }

    /**
     * Set signal value in current frontend signal array
     * Called from Test and value beckame from backend
     * @param sig
     */
    updateFileData(fileName: Variable) {
        this.OpsAmsConfigFileService.updateChannelConfig();
    }

    public getAllSignals() {
        return this.variables;
    }

    //#region Communication Socket.io and Bridge
    /**
     * Init the bridge for client communication
     * @param bridge
     * @returns
     */
    initClient(bridge?: any) {
        if (!bridge) return false;
        this.bridge = bridge;
        if (this.bridge) {
            this.bridge.onDeviceValues = (tags: Variable[]) => this.onDeviceValues(tags);
            this.askDeviceValues();
            return true;
        }
        return false;
    }

    private onDeviceValues(tags: Variable[]) {
        for (let idx = 0; idx < tags.length; idx++) {
            const varid = tags[idx].id;
            if (!this.variables[varid]) {
                this.variables[varid] = new Variable(varid, null, null);
            }
            this.variables[varid].value = tags[idx].value;
            this.variables[varid].error = tags[idx].error;
            this.setSignalValue(this.variables[varid]);
        }
    }

    /**
     * Init the socket and subsribe to device status and signal value change
     */
    public initSocket() {
        this.socketService
            .receiveMessageObserver(IoEventTypes.DEVICE_STATUS)
            .subscribe((message) => {
                this.onDeviceChanged.emit(message);
                if (message.status === 'connect-error') {
                    let name = message.id;
                    let msg = '';
                    this.translateService
                        .get('msg.device-connection-error', { value: name })
                        .subscribe((txt: string) => {
                            msg = txt;
                        });
                    this.toastr.error(msg, '', {
                        timeOut: 3000,
                        closeButton: true,
                        // disableTimeOut: true
                    });
                }
            });

        // devices values
        this.socketService
            .receiveMessageObserver(IoEventTypes.DEVICE_VALUES)
            .subscribe((message) => {
                for (let idx = 0; idx < message.values.length; idx++) {
                    const varid = message.values[idx].id;
                    if (!this.variables[varid]) {
                        this.variables[varid] = new Variable(varid, null, null);
                    }
                    this.variables[varid].alarm = message.values[idx].alarm;
                    this.variables[varid].sensor = message.values[idx].sensor;
                    this.variables[varid].repose = message.values[idx].repose;
                    this.variables[varid].blink = message.values[idx].blink;

                    this.variables[varid].value = message.values[idx].value;
                    this.setSignalValue(this.variables[varid]);
                }
            });

        // alarms status
        this.socketService
            .receiveMessageObserver(IoEventTypes.ALARMS_STATUS)
            .subscribe((alarmsstatus) => {
                this.onAlarmsStatus.emit(alarmsstatus);
            });

        // AMS file update notify
        this.socketService
            .receiveMessageObserver(IoEventTypes.AMS_CONFIG_FILE_UPDATE)
            .subscribe((message) => {
                this.updateFileData(message.fileName);
            });

        //recieve updated proj
        this.socketService
            .receiveMessageObserver(IoEventTypes.PROJECT_UPDATED)
            .subscribe((projectId: string) => {
                this.updateProject(projectId);
            });

        this.askDeviceValues();
        this.askAlarmsStatus();
    }

    /**
     * Ask device status to backend
     */
    public askDeviceStatus() {
        if (this.socketService) {
            this.socketService.sendMessage(IoEventTypes.DEVICE_STATUS, 'get');
        }
    }

    public onDeviceValueResponse(): Observable<any> {
        return this.socketService.receiveMessageObserver(IoEventTypes.DEVICE_VALUES);
    }

    public onDeviceFlagsUpdated(): Observable<DeviceFlags> {
        return this.socketService.receiveMessageObserver(IoEventTypes.TAG_VALUES);
    }

    /**
     * Get updated project from backend
     */
    public updateProject(projectId) {
        this.projectService.reload(projectId);
    }

    /**
     * Ask device status to backend
     */
    public askDeviceValues() {
        if (this.socketService) {
            this.socketService.sendMessage(IoEventTypes.DEVICE_VALUES, 'get');
        } else if (this.bridge) {
            this.bridge.getDeviceValues(null);
        }
    }

    /**
     * Ask alarms status to backend
     */
    public askAlarmsStatus() {
        if (this.socketService) {
            this.socketService.sendMessage(IoEventTypes.ALARMS_STATUS, 'get');
        }
    }

    //#region AMS request
    public requestToSendToFcu(msg: any) {
        if (this.socketService) {
            this.socketService.sendMessage(IoEventTypes.AMS_REQUEST_SEND, msg);
        }
    }
}
