<div style="display: block; margin-bottom: 5px; padding-left: 20px">
    <div class="my-form-field" style="display: inline-block">
        <span>Name</span>
        <input type="number" [(ngModel)]="settingName" ngDefaultControl />
    </div>
    <div
        class="my-form-field"
        style="
            display: flex;
            margin-bottom: 5px;
            justify-content: space-between;
        "
    >
        <div class="my-form-field" style="display: flex; margin-bottom: 5px">
            <div class="my-form-field" style="display: inline-block">
                <span>Channel No.</span>
                <app-search-channel-input
                    [displayErrorContainer]="false"
                    [channels]="channels"
                    [channelNumber]="variableId"
                    (onChannelSelected)="handleChannelSelected($event)"
                ></app-search-channel-input>
            </div>
            <div
                class="my-form-field"
                style="display: inline-block; padding-left: 10px"
            >
                <span>Channel Name</span>
                <input
                    disabled
                    type="text"
                    [(ngModel)]="channelName"
                    ngDefaultControl
                    style="width: 200px"
                />
            </div>
            <div
                class="my-form-field"
                style="display: inline-block; padding-left: 10px"
            >
                <span>Channel Type</span>
                <input
                    disabled
                    type="text"
                    [(ngModel)]="channelType"
                    ngDefaultControl
                />
            </div>
        </div>
        <div>
            <button mat-icon-button (click)="onAddColorChange()">
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
    </div>
    <div style="display: inline-block; margin-bottom: 5px">
        Alarm indication setting
        <span style="display: flex; flex-wrap: wrap; list-style-type: none">
            <mat-checkbox
                id="allButton"
                [checked]="allChecked"
                (change)="setAll($event.checked)"
                ngDefaultControl
            >
                {{ "gauges.property-alarm-settings-all" | translate }}
            </mat-checkbox>
            <li *ngFor="let SubOption of alarmOptions.SubOptions">
                <mat-checkbox
                    [(ngModel)]="SubOption.checked"
                    (ngModelChange)="updateAllComplete()"
                    ngDefaultControl
                >
                    {{
                        "gauges.property-alarm-settings-" +
                            SubOption.displayName | translate
                    }}
                </mat-checkbox>
            </li>
        </span>
    </div>
    <div
        *ngFor="let instance of colorRangeInstances"
        style="display: flex; margin-top: 20px"
    >
        <div class="my-form-field" style="display: inline-block">
            <span>Min</span>
            <input type="number" [(ngModel)]="instance.min" />
        </div>
        <div
            class="my-form-field"
            style="display: inline-block; padding-left: 10px"
        >
            <span>Max</span>
            <input type="number" [(ngModel)]="instance.max" />
        </div>
        <div
            class="my-form-field"
            style="display: inline-block; padding-left: 10px"
        >
            <span>Alarm Color</span>
            <input
                [value]="instance.color"
                [style.background]="instance.color"
                [(colorPicker)]="instance.color"
            />
        </div>
        <div style="display: flex">
            <button
                mat-icon-button
                (click)="onDeleteRange(instance)"
                style="display: inline-block"
            >
                <mat-icon>delete_outline</mat-icon>
            </button>
        </div>
    </div>
</div>
