import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom, of } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { EndPointApi } from '@helpers/endpointapi';
import { LoadingSpinnerComponent } from '@layout/loading-spinner/loading-spinner.component';
import { NewVesselCreateDialogComponent } from '@components//new-vessel-create-dialog/new-vessel-create-dialog.component';

@Injectable()
export class NewVesselCreateService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    private builderId: string;

    overlayRef = this.overlay.create({
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });

    constructor(
        private http: HttpClient,
        public dialog: MatDialog,
        private overlay: Overlay,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    async openVesselInfoEnteringDialog(builderId: string) {
        this.builderId = builderId;

        const dialogRef = this.dialog.open(NewVesselCreateDialogComponent);

        const result = await lastValueFrom(dialogRef.afterClosed());

        let newVessel;

        if (result !== undefined) {
            result['builder_id'] = builderId;
            newVessel = this.registerNewVessel(result);
        }

        return newVessel;
    }

    async registerNewVessel(vesselInfo: any) {
        this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));

        const newVessel = await this.postNewVessel(vesselInfo);

        console.log(newVessel);

        this.overlayRef.detach();

        return newVessel;
    }

    private postNewVessel(vesselInfo: any): Promise<any> {
        return lastValueFrom(
            this.http.post(`${this.domain}/api/vessel`, {
                headers: this.headers,
                params: vesselInfo,
            }),
        );
    }
}
