import { ObjectSchema, array, number, object, string } from 'yup';
import {
    BarGraph,
    BarGraphChannel,
    BarDisplayType,
} from '../../../models/graphs';
import { locale } from './../../yup-locale';
import * as yup from 'yup';
yup.setLocale(locale);

export function BarGraphChannelValidator(): ObjectSchema<BarGraphChannel> {
    return object({
        fcuChannelId: number().integer().required().nonNullable().strict(),
        title: string().required().nonNullable().strict(),
    }).required().strict().noUnknown().nonNullable() as ObjectSchema<BarGraphChannel>;
}

export function BarGraphValidator(): ObjectSchema<BarGraph> {
    return object({
        id: string().uuid().nonNullable().strict().required(),
        displayType: string().oneOf([BarDisplayType.Percentage, BarDisplayType.Range]).nonNullable().required(),
        divisions: number().nonNullable().strict().required(),
        channels: array().ensure().of(BarGraphChannelValidator()).required(),
        graphsId: string().notRequired()
    }).required().strict().nonNullable().noUnknown() as ObjectSchema<BarGraph>;
}