import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ResourceStorageService } from './resource-storage.service';
import { ProjectData, ProjectDataCmdType } from 'icreate/common/models/project';

@Injectable()
export class ResClientService implements ResourceStorageService {
    bridge: any = null;
    id: string = null;
    get isReady() {
        return this.bridge ? true : false;
    }

    private _projectId = 'default';

    get projectId(): string {
        return this._projectId;
    }

    set projectId(val: string) {
        this._projectId = val;
    }

    public onRefreshProject: () => boolean;

    constructor(private http: HttpClient) {}

    init(bridge?: any): boolean {
        this.id = this.getAppId();
        if (!this.bindBridge(bridge)) {
            return false;
        }
        return true;
    }

    getProjectList(): Observable<Array<Object>> {
        return of([
            {
                name: 'Demo',
                id: '00000000',
                lastEdited: '2022-04-18T07:03:48Z',
                createdBy: 'N/A',
                updatedBy: 'N/A',
            },
        ]);
    }

    private bindBridge(bridge?: any): boolean {
        if (!bridge) return false;
        this.bridge = bridge;
        if (this.bridge) {
            this.bridge.onRefreshProject = this.onRefreshProject;
            return true;
        }
        return false;
    }

    getDemoProject(): Observable<any> {
        return this.http.get<any>('./assets/project.demo.fuxap', {});
    }

    getStorageProject(): Observable<any> {
        return new Observable((observer) => {
            if (this.bridge) {
                const sprj = this.bridge.loadProject();
                const prj = ResourceStorageService.defileProject(sprj);
                observer.next(prj);
            } else {
                const prj = localStorage.getItem(this.getAppId());
                if (prj) {
                    observer.next(JSON.parse(prj));
                } else {
                    observer.next();
                }
            }
        });
    }

    setServerProject(prj: ProjectData) {
        return new Observable((observer) => {
            if (!prj) {
                observer.next();
            } else if (this.bridge) {
                const sprj = ResourceStorageService.sanitizeProject(prj);
                if (this.bridge.saveProject(sprj, true)) {
                    observer.next();
                } else {
                    observer.error();
                }
            } else {
                this.saveInLocalStorage(prj);
                observer.next();
            }
        });
    }

    // setServerProjectData(cmd: ProjectDataCmdType, data: any, prj: ProjectData) {
    //   return new Observable((observer) => {
    //     if (!prj) {
    //       observer.next();
    //     } else if (this.bridge) {
    //       const sprj = ResourceStorageService.sanitizeProject(prj);
    //       if (this.bridge.saveProject(sprj, false)) {
    //         // if (this.isDataCmdForDevice(cmd)) {
    //         //     let sdevice = ResourceStorageService.sanitizeDevice(data);
    //         //     this.bridge.deviceChange(sdevice);
    //         // }
    //         observer.next();
    //       } else {
    //         observer.error();
    //       }
    //     } else {
    //       this.saveInLocalStorage(prj);
    //       observer.next();
    //     }
    //   });
    // }

    private isDataCmdForDevice(cmd: ProjectDataCmdType): boolean {
        return cmd === ProjectDataCmdType.DelDevice || cmd === ProjectDataCmdType.SetDevice;
    }

    saveInLocalStorage(prj: any) {
        if (this.getAppId()) {
            localStorage.setItem(this.getAppId(), JSON.stringify(prj));
        }
    }

    getDeviceSecurity(id: string): Observable<any> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    setDeviceSecurity(name: string, value: string): Observable<any> {
        return new Observable((observer) => {
            observer.next('Not supported!');
        });
    }

    getAppId() {
        return ResourceStorageService.prjresource;
    }
}
