<div class="password-container">
    <div *ngIf="currentPassword">
        {{ "ops-settings.users.password.current.title" | translate }}
    </div>
    <div *ngIf="currentPassword" class="icon-input">
        <input
            placeholder="Password"
            [type]="showPassword() ? 'text' : 'password'"
            [(ngModel)]="oldPassword"
            (input)="checkPasswordErrors('oldPassword', oldPassword)"
        />
        <div *ngIf="!passwordVisible">
            <mat-icon
                svgIcon="password-hide"
                style="cursor: pointer"
                (click)="onPasswordVisible()"
            ></mat-icon>
        </div>
        <div *ngIf="passwordVisible">
            <mat-icon
                svgIcon="password-watch"
                style="cursor: pointer"
                (click)="onPasswordVisible()"
            ></mat-icon>
        </div>
    </div>
    <app-app-settings-error
        class="error-container"
        *ngIf="currentPassword && validationErrors.oldPassword"
        [validationErrors]="validationErrors"
        [propertyName]="'oldPassword'"
    >
    </app-app-settings-error>

    <div>{{ "ops-settings.users.password.new.title" | translate }}</div>
    <div class="icon-input">
        <input
            placeholder="Password"
            [type]="showPassword() ? 'text' : 'password'"
            [(ngModel)]="newPassword"
            (input)="checkPasswordErrors('newPassword', newPassword)"
        />
        <div *ngIf="!passwordVisible">
            <mat-icon
                svgIcon="password-hide"
                style="cursor: pointer"
                (click)="onPasswordVisible()"
            ></mat-icon>
        </div>
        <div *ngIf="passwordVisible">
            <mat-icon
                svgIcon="password-watch"
                style="cursor: pointer"
                (click)="onPasswordVisible()"
            ></mat-icon>
        </div>
    </div>
    <app-app-settings-error
        class="error-container"
        *ngIf="validationErrors.newPassword"
        [validationErrors]="validationErrors"
        [propertyName]="'newPassword'"
    >
    </app-app-settings-error>

    <button class="password-button" (click)="generatePassword()">
        {{ "ops-settings.users.password.button.generate-password" | translate }}
    </button>
    <div class="options-container">
        <button class="password-button" (click)="onCloseModal.emit()">
            {{ "ops-settings.users.password.button.close" | translate }}
        </button>
        <button
            [disabled]="canSave()"
            class="password-button"
            (click)="savePassword()"
        >
            {{ "ops-settings.users.password.button.save" | translate }}
        </button>
    </div>
</div>
