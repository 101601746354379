import { Injectable, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
@Injectable()
export class TesterService {
    @Output() changeToggle: EventEmitter<boolean> = new EventEmitter();

    toggle(flag: boolean) {
        this.changeToggle.emit(flag);
    }
}
