<div class="container">
    <div mat-dialog-content>
        <h3 mat-dialog-title style="display: inline-block">{{ message | translate }}</h3>
        <br />
        <h3
            mat-dialog-title
            style="display: inline-block; white-space: pre-wrap"
        >
            {{ confirmedContents }}
        </h3>
    </div>
    <div mat-dialog-actions class="dialog-action">
        <button mat-raised-button color="primary" (click)="onOk()">
            {{ "dlg.ok" | translate }}
        </button>
        <button mat-raised-button (click)="onCancel()">
            {{ "dlg.cancel" | translate }}
        </button>
    </div>
</div>
