<div class="wrapper">
    <h3 mat-dialog-title style="display: inline-block">{{ message }}</h3>
    <br />
    <h3 mat-dialog-title style="display: inline-block; white-space: pre-wrap">
        {{ confirmedContents }}
    </h3>
    <table class="buttons" cellspacing="15">
        <tr>
            <td>
                <button mat-raised-button color="warn" (click)="onOk()">
                    OK
                </button>
            </td>
        </tr>
    </table>
</div>
