import { Component, ContentChild, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'app-context-menu',
    template: `<ng-content></ng-content>`,
    styles: [''],
})
export class ContextMenuComponent implements OnInit {
    @ContentChild('cmenu') cmenu!: ElementRef;

    constructor() {}

    ngOnInit(): void {}

    @HostListener('window:contextmenu', ['$event'])
    SelfClick = (event: any) => {
        if (this.cmenu.nativeElement.contains(event.target)) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    @HostListener('document:mousedown', ['$event'])
    DocMouseDown = (event: any) => {
        if (!this.cmenu.nativeElement.contains(event.target)) {
            this.CloseContextMenu();
        }
    };

    OnContextMenu(event: any) {
        event.preventDefault();
        let x = event.pageX;
        let y = event.pageY;

        this.cmenu.nativeElement.style.top = '50%';
        this.cmenu.nativeElement.style.left = '50%';
        this.cmenu.nativeElement.style.display = 'block';

        const xOff = screen.width - this.cmenu.nativeElement.getBoundingClientRect().width; // menu width
        const yOff = screen.height - this.cmenu.nativeElement.getBoundingClientRect().height - 80; // menu height + browser bar

        if (x > xOff) {
            x = xOff;
        }
        if (y > yOff) {
            y = yOff;
        }
        this.cmenu.nativeElement.style.top = y + 'px';
        this.cmenu.nativeElement.style.left = x + 'px';
    }

    CloseContextMenu = () => {
        this.cmenu.nativeElement.style.display = 'none';
    };
}
