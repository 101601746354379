import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from '@service/notification.service';
import { Channel } from 'common/models/channel';
import { TurboChargerChannel } from 'common/models/graphs';
import { getValidationErrors, mapErrors } from 'common/validators';
import { TurboChargerChannelValidator } from 'common/validators/graphs/exhaust-graph/exhaust-graph-validator';

@Component({
    selector: 'app-turbo-charger-table',
    templateUrl: './turbo-charger-table.component.html',
    styleUrls: ['./turbo-charger-table.component.scss'],
})
export class TurboChargerTableComponent implements OnInit {
    readonly MAX__TURBO_GRAPHS: number = 8;

    @Input() turboChannels: TurboChargerChannel[];
    @Input() channels: Channel[];
    @Output() onTableUpdated: EventEmitter<TurboChargerChannel[]> = new EventEmitter();

    turboTableDataSource = new MatTableDataSource<TurboChargerChannel>();
    turboTableColumns: string[] = ['number', 'channel', 'title'];
    selectedTurboChannel: TurboChargerChannel;

    constructor(private notificationService: NotificationService) {}

    ngOnInit(): void {
        this.turboTableDataSource.data = this.turboChannels;
    }

    selectRow(row) {
        this.selectedTurboChannel = row;
    }

    unselectRow() {
        this.selectedTurboChannel = null;
    }

    canAddChannel() {
        return this.turboChannels.length < this.MAX__TURBO_GRAPHS;
    }

    addChannel() {
        const newChannel = new TurboChargerChannel();
        this.turboChannels.push(newChannel);
        this.turboTableDataSource.data = this.turboChannels;
        this.onTableUpdated.emit(this.turboChannels);
        this.notificationService.successMessage(
            'graph-settings.turbo-graph.notification.success.turbo-channel-added',
        );
    }

    deleteChannel() {
        const index = this.turboChannels.findIndex(
            (channel) => channel === this.selectedTurboChannel,
        );
        if (index !== -1) {
            this.turboChannels.splice(index, 1);
            this.selectedTurboChannel = null;
            this.notificationService.successMessage(
                'graph-settings.turbo-graph.notification.success.turbo-channel-deleted',
            );
        }
        this.turboTableDataSource.data = this.turboChannels;
        this.onTableUpdated.emit(this.turboChannels);
        this.selectedTurboChannel = null;
    }

    getChannelErrors(channelRow: TurboChargerChannel, key) {
        const errors = getValidationErrors(TurboChargerChannelValidator(), channelRow);
        if (errors) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                return validationErrors[key].errors[0];
            } else if (key === 'title') {
                let repeatedTitleError;
                this.turboChannels.map((channels) => {
                    if (channels !== channelRow) {
                        if (channels.title === channelRow.title) {
                            repeatedTitleError = 'graph-settings.graph.error.repeated-title';
                        }
                    }
                });
                if (repeatedTitleError) {
                    return repeatedTitleError;
                }
            }
        }
    }
}
