import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { alarmCols, generateColums } from '@models/channel-sheet';
import { ChannelExcelService } from '@service/channel-excel.service';
import { ChannelSheetService, UpdateType } from '@service/channel-sheet.service';
import { ChannelService } from '@service/channel.service';
import { ProjectService } from '@service/project.service';
import { ChannelGroup } from 'common/models/channel-group';
import jspreadsheet from 'jspreadsheet-ce';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, lastValueFrom } from 'rxjs';
import { DialogType } from '../dialog-channel-category/dialog-channel-category.component';
import { DialogChannelGroupComponent } from '../dialog-channel-group/dialog-channel-group.component';
import { ChannelGroupsService } from '@service/channel-groups.service';

@Component({
    selector: 'app-channel-sheet',
    templateUrl: './channel-sheet.component.html',
    styleUrls: ['./channel-sheet.component.css'],
})
export class ChannelSheetComponent {
    readonly SIMPLE_VIEW = ViewMode.SIMPLE_VIEW;
    readonly VIEW_ALL = ViewMode.VIEW_ALL;
    readonly ADD_CHANNEL_GROUP = DialogType.add;
    readonly excelImportError = 'excel-channel-import.errors';

    @ViewChild('spreadsheet') spreadsheet: ElementRef;
    worksheets: jspreadsheet.JspreadsheetInstance;

    @ViewChild('groupDialog') groupDialog: DialogChannelGroupComponent;
    @ViewChild('fileInput') fileInput!: ElementRef;

    @Input() groups: ChannelGroup[] = [];
    @Input() activeGroup: ChannelGroup;
    newGroup: Array<{ sheetName: string }> = [];
    selectedFile: File;
    channelRows: [][] = null;
    currentCell;
    columns = [];
    alarmColumns = [];
    isLoading = true;
    isSheetLoading = true;
    viewMode = this.SIMPLE_VIEW;
    selectedAlarmKeys = new Map();
    private loadingSub: Subscription;

    constructor(
        private projectService: ProjectService,
        private channelExcelService: ChannelExcelService,
        private channelSheetService: ChannelSheetService,
        private channelService: ChannelService,
        private channelGroupService: ChannelGroupsService,
        private toastr: ToastrService,
    ) {}

    @HostListener('document:keydown', ['$event'])
    handleCopy(event: KeyboardEvent) {
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
            event.preventDefault();
            this.save();
        }
    }

    ngOnInit() {
        this.loadingSub = this.channelSheetService.loadingEmitter.subscribe((loading) => {
            this.isSheetLoading = loading;
        });
    }

    ngOnDestory() {
        this.loadingSub.unsubscribe();
    }

    async ngAfterViewInit() {
        this.columns = generateColums();
        this.alarmColumns = Object.keys(alarmCols);
        this.channelRows = await this.channelExcelService.getExcelChannelRows(
            this.projectService.getProjectId(),
            this.groups[0].id,
        );
        this.channelSheetService.setActiveGroup(this.groups[0]);

        this.initSheet();

        this.updateHiddenColumn();
        this.channelSheetService.setSheet(this.worksheets);
        this.isLoading = false;
        this.isSheetLoading = false;
    }

    selectionActive(x1, y1, x2, y2) {
        if (x1 == x2 && y1 == y2) {
            if (this.worksheets.getColumnOptions(x1).type === 'text') {
                this.currentCell = jspreadsheet.getColumnNameFromId([x1, y1]);
            } else {
                this.currentCell = null;
            }
        } else {
            this.currentCell = null;
        }
        this.channelSheetService.selectedRow(y1);
    }

    async onSelectGroup(event: ChannelGroup) {
        this.activeGroup = event;
        this.channelSheetService.setActiveGroup(event);
        this.channelSheetService.selectGroup(event);
    }

    async save() {
        try {
            if (this.currentCell) {
                const cell = this.worksheets.getCell(this.currentCell);
                if (cell) {
                    this.worksheets.closeEditor(cell, true);
                }
            }
        } catch (error) {
            console.log(`${this.currentCell} value is not updated`);
        } finally {
            if (this.isChannelUpdate()) {
                await this.updateChannels();
                await this.reloadSheet();
            }
            this.currentCell = null;
        }
    }

    isChannelUpdate() {
        let currentData = this.worksheets.getData();

        return !_.isEqual(currentData, this.channelRows);
    }

    async updateChannels() {
        let channelObj = { groups: this.activeGroup, channels: this.worksheets.getData() };
        await this.channelExcelService.updateChannel(
            this.projectService.getProjectId(),
            channelObj,
        );
    }

    openGroupDialog(type: DialogType, group: ChannelGroup) {
        this.groupDialog.setDialogData(type, group);
        this.groupDialog.open();
    }

    async addGroup(channelGroup) {
        let group = await this.channelGroupService.addChannelGroup(channelGroup);
        this.channelSheetService.updateGroupList(UpdateType.add);
        this.channelSheetService.setActiveGroup(group);
    }

    async reloadSheet() {
        this.isSheetLoading = true;
        this.channelRows = await this.channelExcelService.getExcelChannelRows(
            this.projectService.getProjectId(),
            this.activeGroup.id,
        );
        if (this.channelRows.length > 0) {
            this.worksheets.setData(_.cloneDeep(this.channelRows));
        } else {
            this.worksheets.setData([]);
        }

        this.isSheetLoading = false;
    }

    selectViewMode(viewMode: ViewMode) {
        this.viewMode = viewMode;
        this.updateHiddenColumn();
    }

    updateHiddenColumn() {
        for (let i = 0; i < this.columns.length; i++) {
            if (this.viewMode == this.SIMPLE_VIEW) {
                if (i > 5) {
                    this.worksheets.colgroup[i].style.visibility = 'collapse';
                }
            } else {
                this.worksheets.colgroup[i].style.visibility = 'revert';
            }
        }
    }

    selectedShowAlarm(key: string) {
        if (!this.selectedAlarmKeys.has(key)) {
            this.selectedAlarmKeys.set(key, alarmCols[key]);
        } else {
            this.selectedAlarmKeys.delete(key);
        }
        this.updateHiddenColumn();
        this.updateShowAlarmColumn();
    }

    updateShowAlarmColumn() {
        for (const [key, value] of Array.from(this.selectedAlarmKeys)) {
            for (let j = value.colStart; j < value.colEnd; j++) {
                this.worksheets.colgroup[j].style.visibility = 'revert';
            }
        }
    }

    showErrorMsg(msg: string) {
        this.toastr.error(msg, '', {
            timeOut: 4000,
            closeButton: true,
        });
    }

    async saveToJson() {
        const channels = await lastValueFrom(
            this.channelService.getChannels(this.projectService.getProjectId()),
        );
        const jsonStr = JSON.stringify(channels, null, 2);

        const blob = new Blob([jsonStr], { type: 'application/json' });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'channel.json';
        a.click();
        window.URL.revokeObjectURL(url);
    }

    initSheet() {
        this.worksheets = jspreadsheet(this.spreadsheet.nativeElement, {
            tableHeight: '1000px',
            tableOverflow: true,
            data: _.cloneDeep(this.channelRows),
            columns: this.columns,
            minDimensions: [this.columns.length, 100],
            loadingSpin: true,
            allowExport: false,
            toolbar: [
                {
                    id: 'undo',
                    type: 'i',
                    content: 'undo',
                    onclick: function () {
                        this.worksheets.undo();
                    },
                },
                {
                    id: 'redo',
                    type: 'i',
                    content: 'redo',
                    onclick: function () {
                        this.worksheets.redo();
                    },
                },
                {
                    id: 'save',
                    type: 'i',
                    content: 'download_for_offline',
                    onclick: async () => {
                        await this.saveToJson();
                    },
                },
                {
                    type: 'select',
                    k: 'font-family',
                    v: ['Arial', 'Verdana'],
                },
                {
                    type: 'select',
                    k: 'font-size',
                    v: [
                        '9px',
                        '10px',
                        '11px',
                        '12px',
                        '13px',
                        '14px',
                        '15px',
                        '16px',
                        '17px',
                        '18px',
                        '19px',
                        '20px',
                    ],
                },
                {
                    id: 'align_left',
                    type: 'i',
                    content: 'format_align_left',
                    k: 'text-align',
                    v: 'left',
                },
                {
                    id: 'align_center',
                    type: 'i',
                    content: 'format_align_center',
                    k: 'text-align',
                    v: 'center',
                },
                {
                    id: 'align_right',
                    type: 'i',
                    content: 'format_align_right',
                    k: 'text-align',
                    v: 'right',
                },
                {
                    id: 'format_bold',
                    type: 'i',
                    content: 'format_bold',
                    k: 'font-weight',
                    v: 'bold',
                },
                {
                    type: 'color',
                    content: 'format_color_text',
                    k: 'color',
                },
                {
                    type: 'color',
                    content: 'format_color_fill',
                    k: 'background-color',
                },
            ],

            onselection: (instance, x1, y1, x2, y2, origin) => this.selectionActive(x1, y1, x2, y2),
        });
    }

    updateActiveGroup(group) {
        this.activeGroup = group;
    }
}

enum ViewMode {
    SIMPLE_VIEW,
    VIEW_ALL,
}

interface AlarmColunm {
    key: string;
    colStart: number;
    colEnd: number;
}
