import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '@service/project.service';
import { UrlParserService } from '@service/url-parser.service';
import { ViewGroupsService } from '@service/view-groups.service';
import { viewCategories, viewsGroupsArrayTypes } from 'common/models/view-category';
import { View } from 'common/models/view';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-lab-sidebar',
    templateUrl: './lab-sidebar.component.html',
    styleUrls: ['./lab-sidebar.component.css'],
})
export class LabSidebarComponent implements OnInit {
    @ViewChild('mimicdropdown') dropdown: ElementRef;

    views: View[];

    projectLoadSub: Subscription;

    dropdownStyle = 'none';
    showSubviews: Boolean = false;
    viewGroups: any = [];
    selectedGroup;
    viewsListArray: viewCategories[] = [];

    constructor(
        private location: Location,
        private urlParserService: UrlParserService,
        private projectService: ProjectService,
        private viewGroupsService: ViewGroupsService,
    ) {}

    ngOnInit(): void {
        this.getViewsList();
    }

    async getViewsList() {
        let urlSegment = this.urlParserService.getSegment(this.location.path());
        let projectId = urlSegment[1].path;

        let [projectData, groups] = await Promise.all([
            this.projectService.getProjectData(projectId),
            this.viewGroupsService.getAll(projectId),
        ]);

        this.views = projectData.hmi.views;
        this.viewGroups = groups;
        this.updateGroupsList();
    }

    updateGroupsList() {
        this.viewsListArray = [];
        this.viewGroups.forEach((group) => {
            const listItem = {
                type: viewsGroupsArrayTypes.container,
                name: group.groupName,
                data: group,
                views: [],
            };
            this.viewsListArray.push(listItem);
        });
        if (this.views) {
            this.updateList();
        }
    }

    updateList() {
        this.views.forEach((view) => {
            let found = false;
            const listItem = {
                type: viewsGroupsArrayTypes.view,
                name: view.name,
                data: view,
                views: [],
            };
            this.viewsListArray.forEach((group) => {
                if (group.data.id === view.viewsGroupId) {
                    found = true;
                    group.views.push(listItem);
                }
            });
            if (!found) {
                this.viewsListArray.push(listItem);
            }
        });
    }

    toggleDropdown() {
        this.dropdownStyle = this.dropdownStyle == 'none' ? 'block' : 'none';
        this.selectedGroup = null;
        this.showSubviews = false;
    }

    groupSelected(group) {
        this.showSubviews = true;
        this.selectedGroup = group;
    }

    isgroupActive(groupName: string) {
        return this.selectedGroup && this.selectedGroup.name === groupName;
    }

    asView(val): View {
        return val;
    }
}
