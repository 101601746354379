import { PDFJS } from './pdf.combined.js';
import Potrace from 'potrace';

export async function pdf2svg(data) {
    const finalSVG = [];
    const sgvElemenstByPage = {};
    async function recursion(node, transformParent, pageNum) {
        if (node.tagName == 'g' || node.tagName == 'svg') {
            if (node.children.length > 0) {
                for (let i = 0; i < node.children.length; i++) {
                    let transformAttribute;
                    if (node.getAttribute('transform')) {
                        if (transformParent) {
                            transformAttribute =
                                transformParent +
                                ' ' +
                                node.getAttribute('transform');
                        } else {
                            transformAttribute = node.getAttribute('transform');
                        }
                    } else {
                        transformAttribute = transformParent;
                    }
                    recursion(node.children[i], transformAttribute, pageNum);
                }
            }
        } else {
            if (node.tagName === 'path') {
                let path = node.getAttribute('d');
                path = path.replace(/\s/g, '');
                if (
                    path.startsWith('M0.160.16L0.16') ||
                    path.startsWith('M00C0-0.') ||
                    path.startsWith('M-') ||
                    path.startsWith('M -') ||
                    (!node.getAttribute('fill-rule') &&
                        !node.getAttribute('stroke'))
                ) {
                } else {
                    let transformAttribute;
                    if (node.getAttribute('transform')) {
                        transformAttribute =
                            transformParent +
                            ' ' +
                            node.getAttribute('transform');
                    } else {
                        transformAttribute = transformParent;
                    }

                    node.setAttribute('transform', transformAttribute);
                    sgvElemenstByPage[pageNum].push(node);
                }
            } else if (node.tagName === 'text') {
                let transformAttribute;
                if (node.getAttribute('transform')) {
                    transformAttribute =
                        transformParent + ' ' + node.getAttribute('transform');
                } else {
                    transformAttribute = transformParent;
                }
                node.setAttribute('transform', transformAttribute);
                for (
                    var att,
                        i = 0,
                        atts = node.children[0].attributes,
                        n = atts.length;
                    i < n;
                    i++
                ) {
                    att = atts[i];
                    if (att.nodeName === 'x') {
                        node.setAttribute(att.nodeName, 0);
                    } else {
                        node.setAttribute(att.nodeName, att.nodeValue);
                    }
                }
                let text = node.children[0].innerHTML;
                node.children[0].remove();
                node.innerHTML = text;
                sgvElemenstByPage[pageNum].push(node);
            } else {
                let transformAttribute;
                if (node.getAttribute('transform')) {
                    transformAttribute =
                        transformParent + ' ' + node.getAttribute('transform');
                } else {
                    transformAttribute = transformParent;
                }

                node.setAttribute('transform', transformAttribute);
                sgvElemenstByPage[pageNum].push(node);
            }
        }
    }

    await PDFJS.getDocument(data)
        .then(function (doc) {
            var numPages = doc.numPages;
            var lastPromise = Promise.resolve();
            var loadPage = function (pageNum) {
                return doc.getPage(pageNum).then(function (page) {
                    var viewport = page.getViewport(1.0 /* scale */);
                    return page.getOperatorList().then(function (opList) {
                        console.log(page.commonObjs);
                        console.log(page.objs);

                        var svgGfx = new PDFJS.SVGGraphics(
                            page.commonObjs,
                            page.objs,
                        );
                        svgGfx.embedFonts = true;
                        return svgGfx
                            .getSVG(opList, viewport)
                            .then(async function (svg) {
                                const newSVG = document.createElementNS(
                                    'http://www.w3.org/2000/svg',
                                    'svg',
                                );
                                newSVG.setAttribute('viewBox', '0 0 1496 812');
                                sgvElemenstByPage[pageNum] = [];
                                let defsTag = svg.querySelector('defs');
                                defsTag.remove();
                                await recursion(svg, undefined, pageNum);
                                return newSVG;
                            })
                            .then(async (newSVG) => {
                                for (const element of sgvElemenstByPage[
                                    pageNum
                                ]) {
                                    const editedElement =
                                        await editElement(element);
                                    newSVG.append(editedElement);
                                }

                                return newSVG;
                            })
                            .then((newSVG) => {
                                console.log('now?');
                                var s = new XMLSerializer();
                                var svgString = s.serializeToString(newSVG);
                                finalSVG.push([svgString, pageNum]);
                            });
                    });
                });
            };

            for (var i = 1; i <= numPages; i++) {
                lastPromise = lastPromise.then(loadPage.bind(null, i));
            }
            return lastPromise;
        }).catch(err=> console.error('Error: ' + err));
    return finalSVG;
}

async function editElement(element) {
    if (element.tagName === 'image') {
        async function addImageProcess() {
            return new Promise((resolve, reject) => {
                Potrace.trace(
                    element.getAttribute('xlink:href'),
                    function (err, svg) {
                        if (err) throw err;
                        let SVG = new DOMParser().parseFromString(
                            svg,
                            'image/svg+xml',
                        );
                        let path = SVG.querySelector('path');
                        resolve(path);
                    },
                );
            });
        }

        let newPath = await addImageProcess();
        element.removeAttribute('xlink:href');
        let transform = [];
        for (
            var att, i = 0, atts = element.attributes, n = atts.length;
            i < n;
            i++
        ) {
            att = atts[i];
            if (att.nodeName === 'x') {
                transform.unshift(att.nodeValue);
            }
            if (att.nodeName === 'y') {
                transform.push(att.nodeValue);
            } else {
                newPath.setAttribute(att.nodeName, att.nodeValue);
            }
        }
        let transformAttribute = newPath.getAttribute('transform');
        let translate = 'translate(' + transform[0] + ' ' + transform[1] + ')';
        newPath.setAttribute('transform', transformAttribute + translate);
        return newPath;
    }
    return element;
}
