<div class="wrapper">
    <div class="header-panel" *ngIf="true">
        <!--ineditor"> -->
        <img
            src="/assets/images/icreate-logo.png"
            alt="iCreate logo"
            class="menu-button"
            [matMenuTriggerFor]="prjview"
            #prjviewtrigger="matMenuTrigger"
        />
        {{ currentProject.name }}
    </div>

    <!-- Draw top tool bar -->
    <div id="tools" class="tools_panel">
        <div id="m_editor_panel">
            <ng-template [ngIf]="!narrowScreen">
                <!-- if you want to register vessel data, you change this comment-->
                <!-- <button mat-button matTooltip="Vessel Menu" class="main-btn" (click)="onSelectVessel()"> -->
                <button
                    mat-button
                    matTooltip="Vessel Menu"
                    class="main-btn"
                    (click)="onShowVesselInfo()"
                >
                    <span class="vessel-icon"></span>
                </button>
                <div class="main-btn-sep"></div>
                <button
                    mat-button
                    matTooltip="Channels"
                    class="main-btn"
                    (click)="onChannelConfig()"
                    [disabled]="isChannelListOpen"
                >
                    <span class="import-icon"></span>
                </button>

                <div class="main-btn-sep"></div>
                <button
                    mat-button
                    matTooltip="{{ 'editor.tools-launch-title' | translate }}"
                    class="main-btn"
                    (click)="onStartCurrent()"
                >
                    <mat-icon aria-label="Launch Current View"
                        >play_arrow</mat-icon
                    >
                </button>
                <div class="main-btn-sep"></div>
                <button
                    mat-button
                    matTooltip="{{ 'editor.tools-device-title' | translate }}"
                    class="main-btn"
                    (click)="onDevice()"
                >
                    <mat-icon>device_hub</mat-icon>
                </button>
                <div class="main-btn-sep"></div>
                <button
                    [matMenuTriggerFor]="zoommenu"
                    mat-button
                    matTooltip="{{ 'editor.tools-zoom-title' | translate }}"
                    class="main-btn"
                >
                    <mat-icon aria-label="Zoom Tool [Ctrl+Up/Down]"
                        >zoom_in</mat-icon
                    >
                </button>
                <button
                    mat-button
                    matTooltip="{{ 'editor.tools-grid-title' | translate }}"
                    class="main-btn"
                    (click)="onToggleGrid()"
                >
                    <mat-icon
                        aria-label="Show Hide Grid"
                        *ngIf="gridData.show_grid == true"
                        >grid_on</mat-icon
                    >
                    <mat-icon
                        aria-label="Show Hide Grid"
                        *ngIf="gridData.show_grid != true"
                        >grid_off</mat-icon
                    >
                </button>
                <div id="history_panel">
                    <button
                        mat-button
                        id="tool_undo"
                        [disabled]="
                            this.canvas?.undoMgr.getUndoStackSize() <= 0
                        "
                        (click)="onUndo()"
                        matTooltip="{{ 'editor.tools-undo-title' | translate }}"
                        class="main-btn"
                    >
                        <mat-icon aria-label="Undo [Z]">undo</mat-icon>
                    </button>
                    <button
                        mat-button
                        id="tool_redo"
                        [disabled]="
                            this.canvas?.undoMgr.getRedoStackSize() <= 0
                        "
                        (click)="onRedo()"
                        matTooltip="{{ 'editor.tools-redo-title' | translate }}"
                        class="main-btn"
                    >
                        <mat-icon aria-label="Redo [Y]">redo</mat-icon>
                    </button>
                </div>
                <div class="main-btn-sep"></div>
                <button
                    mat-button
                    matTooltip="{{
                        'editor.tools-graph-settings-title' | translate
                    }}"
                    class="main-btn"
                    (click)="openGraphSettings()"
                >
                    <mat-icon svgIcon="graph-settings"></mat-icon>
                </button>
                <button
                    mat-button
                    matTooltip="{{ 'editor.tools-repose-alarms' | translate }}"
                    class="main-btn"
                    (click)="openReposeSettings()"
                >
                    <mat-icon svgIcon="cancel-alarm"></mat-icon>
                </button>
                <button
                    mat-button
                    matTooltip="{{
                        'editor.tools-fu-settings-title' | translate
                    }}"
                    class="main-btn"
                    (click)="openFuSettings()"
                >
                    <mat-icon svgIcon="fu-settings"></mat-icon>
                </button>
                <button
                    mat-button
                    matTooltip="{{
                        'editor.tools-run-hour-settings' | translate
                    }}"
                    class="main-btn"
                    (click)="openRunHourSettings()"
                >
                    <mat-icon svgIcon="run-hour-setting"></mat-icon>
                </button>
            </ng-template>
        </div>
        <div
            *ngIf="this.canvas?.selectedElements?.length > 0"
            id="selected_panel"
        >
            <div *ngIf="!narrowScreen" class="main-btn-sep"></div>
            <button
                mat-button
                id="tool_clone_multi"
                (click)="onClone()"
                matTooltip="{{ 'editor.tools-clonemulti-title' | translate }}"
                class="main-btn"
            >
                <mat-icon aria-label="Clone Elements [C]"
                    >content_copy</mat-icon
                >
            </button>
            <button
                mat-button
                id="tool_delete_multi"
                (click)="onDelete()"
                matTooltip="{{ 'editor.tools-deletemulti-title' | translate }}"
                class="main-btn"
            >
                <mat-icon
                    aria-label="Delete Selected Elements [Delete/Backspace]"
                    >content_cut</mat-icon
                >
            </button>
        </div>
        <div
            *ngIf="this.canvas?.selectedElements?.length > 1"
            id="multiselected_panel"
        >
            <div class="main-btn-sep"></div>

            <button
                mat-button
                id="tool_group_elements"
                (click)="this.canvas.groupSelectedElements()"
                matTooltip="{{ 'editor.tools-group-title' | translate }}"
                class="main-btn"
            >
                <span class="group"></span>
            </button>
            <button
                mat-button
                id="tool_alignleft"
                (click)="this.canvas.alignSelectedElements('l', 'selected')"
                matTooltip="{{ 'editor.tools-alignleft-title' | translate }}"
                class="main-btn"
            >
                <span class="align-left"></span>
            </button>
            <button
                mat-button
                id="tool_aligncenter"
                (click)="this.canvas.alignSelectedElements('c', 'selected')"
                matTooltip="{{ 'editor.tools-aligncenter-title' | translate }}"
                class="main-btn"
            >
                <span class="align-center"></span>
            </button>
            <button
                mat-button
                id="tool_alignright"
                (click)="this.canvas.alignSelectedElements('r', 'selected')"
                matTooltip="{{ 'editor.tools-alignright-title' | translate }}"
                class="main-btn"
            >
                <span class="align-right"></span>
            </button>
            <button
                mat-button
                id="tool_aligntop"
                (click)="this.canvas.alignSelectedElements('t', 'selected')"
                matTooltip="{{ 'editor.tools-aligntop-title' | translate }}"
                class="main-btn"
            >
                <span class="align-top"></span>
            </button>
            <button
                mat-button
                id="tool_alignmiddle"
                (click)="this.canvas.alignSelectedElements('m', 'selected')"
                matTooltip="{{ 'editor.tools-alignmiddle-title' | translate }}"
                class="main-btn"
            >
                <span class="align-middle"></span>
            </button>
            <button
                mat-button
                id="tool_alignbottom"
                (click)="this.canvas.alignSelectedElements('b', 'selected')"
                matTooltip="{{ 'editor.tools-alignbottom-title' | translate }}"
                class="main-btn"
            >
                <span class="align-bottom"></span>
            </button>
            <!-- <button
          mat-button
          id="tool_tidyup"
          (click)="this.canvas.alignSelectedElements('make-grid', 'selected')"
          matTooltip="{{ 'editor.tools-tidyup-title' | translate }}"
          class="main-btn"
          >
              <span class="make-grid"></span>
          </button> -->
            <button
                mat-button
                id="tool_tidyup"
                (click)="this.canvas.alignSelectedElements('dh', 'selected')"
                matTooltip="{{
                    'editor.tools-alignhorizontal-title' | translate
                }}"
                class="main-btn"
            >
                <span class="align-horizontal"></span>
            </button>
            <button
                mat-button
                id="tool_tidyup"
                (click)="this.canvas.alignSelectedElements('dv', 'selected')"
                matTooltip="{{
                    'editor.tools-alignvertical-title' | translate
                }}"
                class="main-btn"
            >
                <span class="align-vertical"></span>
            </button>
        </div>
        <div
            *ngIf="
                this.canvas?.selectedElements &&
                this.canvas?.selectedElements.length == 1 &&
                this.canvas?.selectedElements[0].type == 'svg-ext-shapes-g'
            "
            id="g_panel"
        >
            <div class="main-btn-sep"></div>
            <button
                mat-button
                id="tool_ungroup"
                (click)="this.canvas.ungroupSelectedElement()"
                matTooltip="{{ 'editor.tools-ungroup-title' | translate }}"
                class="main-btn"
            >
                <span class="ungroup"></span>
            </button>
        </div>
    </div>
    <!-- tools_top -->

    <div class="header-right">
        <div class="editor-name">
            {{ getEditorName() }}
        </div>
        <div class="header-help">
            <ng-template [ngIf]="narrowScreen">
                <button
                    mat-button
                    [matMenuTriggerFor]="dropdownmenu"
                    #sideMenu="matMenuTrigger"
                    matTooltip="Tools"
                    id="header_dropdown_button"
                    class="main-btn"
                >
                    <!-- <mat-icon aria-label="Additional Options">menu</mat-icon>-->
                    <span class="dango-menu"></span>
                </button>
            </ng-template>
            <button
                mat-button
                matTooltip="{{ 'header.help-info' | translate }}"
                (click)="showInfo()"
                class="main-btn"
            >
                <mat-icon aria-label="HELP">help_outline</mat-icon>
            </button>
        </div>
    </div>
</div>
<mat-menu #vesselmenu="matMenu"> </mat-menu>
<mat-menu class="zoom-menu" #zoommenu="matMenu">
    <mat-slider
        class="custom-menu-slider"
        discrete
        step="5"
        min="50"
        max="800"
        [displayWith]="formatZoomLabel"
    >
        <input
            matSliderThumb
            (valueChange)="onZoomSliderChanged($event)"
            value="{{ canvas.getZoom() * 100 }}"
        />
    </mat-slider>
</mat-menu>
<mat-menu
    #prjview="matMenu"
    class="leftbar-item-menu header-menu"
    yPosition="below"
    [overlapTrigger]="false"
>
    <button mat-menu-item (click)="onNewProject()">
        {{ "header.new-project" | translate }}
    </button>
    <mat-divider class="menu-separator"></mat-divider>
    <button
        [disabled]="!viewHasChanged()"
        mat-menu-item
        (click)="onSaveProject()"
    >
        {{ "header.save-project" | translate }}
    </button>
    <button mat-menu-item (click)="onSaveProjectAs()">
        {{ "header.saveas-project" | translate }}
    </button>
    <mat-divider class="menu-separator"></mat-divider>
    <button mat-menu-item (click)="onRenameProject()">
        {{ "header.rename-project" | translate }}
    </button>
    <button mat-menu-item (click)="onOpenProject(); $event.stopPropagation()">
        {{ "header.open-project" | translate }}
    </button>
    <button
        mat-menu-item
        (click)="onImportProject(); $event.stopPropagation()"
        [disabled]="true"
    >
        {{ "header.import-project" | translate }}
    </button>
    <button mat-menu-item (click)="onDeleteProject(); $event.stopPropagation()">
        {{ "header.delete-project" | translate }}
    </button>
    <button mat-menu-item (click)="onCompareMimics()">
        {{ "header.compare-mimics" | translate }}
    </button>
    <button mat-menu-item (click)="onPrintMimics()">
        {{ "header.print-mimics" | translate }}
    </button>
    <button mat-menu-item (click)="printChannels()">
        {{ "header.print-channels" | translate }}
    </button>
    <button mat-menu-item (click)="printGraphs()">
        {{ "header.print-graphs" | translate }}
    </button>
    <button mat-menu-item (click)="onCompareChannels()">
        {{ "header.compare-channels" | translate }}
    </button>
    <button mat-menu-item (click)="onSettingProject()">
        {{ "header.setting-project" | translate }}
    </button>
    <button mat-menu-item (click)="onOpenOpsSettings()">
        {{ "header.ops-settings" | translate }}
    </button>
    <mat-divider class="menu-separator"></mat-divider>
    <button mat-menu-item (click)="onPullProject(); $event.stopPropagation()">
        {{ "header.pull-project" | translate }}
    </button>
    <button
        mat-menu-item
        (click)="onPublishProject(); $event.stopPropagation()"
    >
        {{ "header.publish-project" | translate }}
    </button>
    <!-- <button mat-menu-item (click)="onImportVariable();$event.stopPropagation()">Import Variable</button> -->
    <input
        type="file"
        #fileImportInput
        style="display: none"
        id="projectFileUpload"
        (change)="onFileChangeListener($event)"
        accept=".fuxap,.json"
    />
</mat-menu>
<mat-menu #altvesselmenu="matMenu">
    <button mat-menu-item>Vessel: Setsu</button>
    <button mat-menu-item>Template: Ferry</button>
    <button mat-menu-item>Shipyard: FriendlyYamaYard</button>
</mat-menu>

<app-confirmation-window
    #confirmationWindowProjectExist
    [mainLabel]="
        'header.prompt.pull-project.current-project-not-match.title'
            | translate
                : {
                      blobProject: blobProject.name,
                      currentProject: currentProject.name
                  }
    "
    [subLabel]="'header.prompt.pull-project.subtitle' | translate"
    [confirmLabel]="
        'header.prompt.pull-project.current-project-not-match.overwrite'
            | translate
    "
    [cancelLabel]="
        'header.prompt.pull-project.current-project-not-match.update'
            | translate
    "
>
</app-confirmation-window>
<app-confirmation-window
    #confirmationWindowProjectNotExist
    [mainLabel]="
        'header.prompt.pull-project.project-no-exist.title'
            | translate
                : {
                      blobProject: blobProject.name,
                      currentProject: currentProject.name
                  }
    "
    [subLabel]="'header.prompt.pull-project.subtitle' | translate"
    [confirmLabel]="
        'header.prompt.pull-project.project-no-exist.overwrite' | translate
    "
    [cancelLabel]="
        'header.prompt.pull-project.project-no-exist.create' | translate
    "
>
</app-confirmation-window>
