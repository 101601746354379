<div *ngFor="let category of channelCategories" class="category-list">
    <div>
        <div>{{ category.category }}</div>
        <div class="sub-title">
            {{ getGroupsCount(category.id) }} channel groups
        </div>
    </div>
    <span
        class="material-symbols-outlined more-icon"
        (click)="openMore(category); $event.stopPropagation()"
    >
        more_vert
    </span>

    <div class="more-lists" *ngIf="activeCategory.id == category.id">
        <app-more-options
            (deleteEmitter)="onDelete()"
            (renameEmitter)="onRename()"
            (duplicateEmitter)="onDuplicate()"
        ></app-more-options>
    </div>
</div>
<div
    class="category-list add-btn"
    (click)="onOpenCategoryDialog(CATEGORY_ADD, null)"
>
    <div class="add">
        <span class="material-symbols-outlined"> add </span>
        <div>{{ "dlg.add" | translate }}</div>
    </div>
</div>
