import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class MimicChangesService {
    changesInProject = [];
    showChanges: Boolean = false;

    showChangesSubject: Subject<boolean> = new Subject<boolean>();
    changesInProjectSubject: Subject<any> = new Subject<any>();

    constructor() {
        this.showChangesSubject.subscribe((value) => {
            this.showChanges = value;
        });
        this.changesInProjectSubject.subscribe((value) => {
            this.changesInProject = value;
        });
    }

    toggleShowChanges() {
        this.showChangesSubject.next(!this.showChanges);
    }

    updateChangesInProject(changesInProject) {
        this.changesInProjectSubject.next(changesInProject);
    }

    compareViewsEvent(view, oldBlob, canvasContainer) {
        let savedView;
        const canvas = canvasContainer.svgroot;
        const svgContent = canvas.querySelector('#svgcontent');
        const currentViewId = view.id;
        for (let i = 0; i < oldBlob.views.length; i++) {
            const id = oldBlob.views[i].id;
            if (id === currentViewId) {
                savedView = oldBlob.views[i];
            }
        }
        if (savedView) {
            const contentSavedView = new DOMParser().parseFromString(
                savedView.svgcontent,
                'text/xml',
            );
            const savedLayer = contentSavedView.querySelector('.layer');
            const contentActiveView = new DOMParser().parseFromString(view.svgcontent, 'text/xml');
            const activeLayer = contentActiveView.querySelector('.layer');
            const changes = _.differenceWith(
                _.toPairs(this.extendedXml2json(activeLayer)),
                _.toPairs(this.extendedXml2json(savedLayer)),
                _.isEqual,
            );
            if (changes.length > 0) {
                changes.forEach((element) => {
                    if (element[0] !== 'undefined' && element[0] !== '@attributes') {
                        const newElement = svgContent.querySelector('#' + element[0]);
                        if (newElement.tagName === 'g') {
                            this.onDetectChangesGroup(
                                currentViewId,
                                newElement,
                                contentActiveView,
                                contentSavedView,
                                svgContent,
                                undefined,
                            );
                        } else {
                            const bbox = newElement.getBBox();
                            const transform = newElement.getAttribute('transform');
                            this.changesInProject.push({
                                x: (Number(bbox.x) - 10).toString(),
                                y: (Number(bbox.y) - 10).toString(),
                                height: bbox.height + 15,
                                width: bbox.width + 15,
                                viewId: currentViewId,
                                transform: transform,
                            });
                        }
                    }
                });
            }
        } else {
            const bbox = svgContent.getBBox();
            this.changesInProject.push({
                x: (Number(bbox.x) - 10).toString(),
                y: (Number(bbox.y) - 10).toString(),
                height: bbox.height + 15,
                width: bbox.width + 15,
                viewId: currentViewId,
            });
        }
        this.updateChangesInProject(this.changesInProject);
    }

    onDetectChangesGroup(currentViewId, element, activeView, savedView, svgContent, changedItems) {
        if (changedItems === undefined) {
            changedItems = [];
        }
        const elementId = element.id;
        const savedGroup = savedView.querySelector('#' + elementId);
        const activeGroup = activeView.querySelector('#' + elementId);
        if (savedGroup) {
            const changes = _.differenceWith(
                _.toPairs(this.extendedXml2json(activeGroup)),
                _.toPairs(this.extendedXml2json(savedGroup)),
                _.isEqual,
            );
            changes.forEach((element) => {
                if (element[0] !== 'undefined' && element[0] !== '@attributes') {
                    const newElement = svgContent.querySelector('#' + element[0]);
                    if (newElement.tagName === 'g') {
                        this.onDetectChangesGroup(
                            currentViewId,
                            newElement,
                            activeGroup,
                            savedGroup,
                            svgContent,
                            changedItems,
                        );
                    } else {
                        const bbox = newElement.getBBox();
                        const transform = newElement.getAttribute('transform');
                        this.changesInProject.push({
                            x: (Number(bbox.x) - 10).toString(),
                            y: (Number(bbox.y) - 10).toString(),
                            height: bbox.height + 15,
                            width: bbox.width + 15,
                            viewId: currentViewId,
                            transform: transform,
                        });
                    }
                }
            });
        }
        return changedItems;
    }

    /**
     * This function coverts a DOM Tree into JavaScript Object using the item ID as keys.
     * @param srcDOM: DOM Tree to be converted.
     */
    extendedXml2json(xml) {
        if (!xml) {
            return {};
        }
        // Create the return object
        let obj = {};
        if (xml.nodeType == 1 && xml.attributes.length > 0) {
            // element
            // do attributes
            obj['@attributes'] = {};
            for (let j = 0; j < xml.attributes.length; j++) {
                const attribute = xml.attributes.item(j);
                if (attribute.nodeValue !== 'pointer-events:inherit') {
                    obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
                }
            }
        } else if (xml.nodeType == 3) {
            // text
            obj = xml.nodeValue;
        }

        // do children
        if (xml.hasChildNodes()) {
            for (let i = 0; i < xml.childNodes.length; i++) {
                const item = xml.childNodes.item(i);
                const nodeId = item.id;
                if (typeof obj[nodeId] == 'undefined') {
                    obj[nodeId] = this.extendedXml2json(item);
                } else {
                    if (typeof obj[nodeId].push == 'undefined') {
                        obj[nodeId] = [obj[nodeId]];
                    }
                    obj[nodeId].push(this.extendedXml2json(item));
                }
            }
        }

        return obj;
    }

    viewHasUpdated(savedView, svgContent) {
        const contentSavedView = new DOMParser().parseFromString(savedView.svgcontent, 'text/xml');

        let savedLayer = contentSavedView.querySelector('.layer');
        savedLayer = this.removeStyleAttribute(savedLayer);

        let activeLayer = svgContent.querySelector('.layer');
        activeLayer = this.removeStyleAttribute(activeLayer);

        return !savedLayer.isEqualNode(activeLayer);
    }

    removeStyleAttribute(element) {
        element.removeAttribute('style');
        const children = element.querySelectorAll('*');
        children.forEach((child) => {
            child.removeAttribute('style');
        });
        return element;
    }
}
