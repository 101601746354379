<div style="width: 650px; position: relative">
    <h1
        mat-dialog-title
        style="display: inline-block; cursor: move"
        mat-dialog-draggable
    >
        {{ 'editor.controls-html-switch-settings' | translate }}
    </h1>
    <mat-icon
        (click)="onNoClick()"
        style="
            float: right;
            margin-right: -10px;
            margin-top: -10px;
            cursor: pointer;
            color: gray;
        "
        >clear</mat-icon
    >
    <mat-tab-group style="width: 100%">
        <mat-tab label="{{ 'gauges.property-props' | translate }}">
            <div
                style="
                    height: 500px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    padding-top: 15px;
                "
            >
                <div style="display: block">
                    <flex-auth
                        [name]="name"
                        [permission]="property.permission"
                        #flexauth
                    ></flex-auth>
                </div>
                <div mat-dialog-content style="overflow: hidden; width: 100%">
                    <flex-head
                        [data]="data"
                        [property]="property"
                        #flexhead
                    ></flex-head>
                </div>
                <div class="field-row" style="padding-top: 10px">
                    <div class="my-form-field" style="padding-left: 60px">
                        <span>{{
                            'html-switch.property-off-value' | translate
                        }}</span>
                        <input
                            numberOnly
                            [(ngModel)]="options.offValue"
                            style="
                                width: 80px;
                                text-align: center;
                                display: inline-block;
                            "
                            type="text"
                        />
                    </div>
                    <div
                        class="my-form-field"
                        style="float: right; padding-right: 60px"
                    >
                        <span>{{
                            'html-switch.property-on-value' | translate
                        }}</span>
                        <input
                            numberOnly
                            [(ngModel)]="options.onValue"
                            style="
                                width: 80px;
                                text-align: center;
                                display: inline-block;
                            "
                            type="text"
                        />
                    </div>
                </div>
                <div class="field-row" style="padding-top: 10px">
                    <div class="my-form-field color-field">
                        <span>{{
                            'html-switch.property-off-background' | translate
                        }}</span>
                        <input
                            [(colorPicker)]="options.offBackground"
                            [style.background]="options.offBackground"
                            [cpAlphaChannel]="'always'"
                            class="input-color"
                            [cpPresetColors]="defaultColor"
                            [cpOKButton]="true"
                            [cpCancelButton]="true"
                            [cpCancelButtonClass]="'cpCancelButtonClass'"
                            [cpCancelButtonText]="'Cancel'"
                            [cpOKButtonText]="'OK'"
                            [cpOKButtonClass]="'cpOKButtonClass'"
                            style="padding: 8px 0 0 0; width: 98px"
                            [cpPosition]="'right'"
                            (colorPickerChange)="updateOptions()"
                        />
                    </div>
                    <div
                        class="my-form-field color-field"
                        style="padding-left: 12px"
                    >
                        <span>{{
                            'html-switch.property-off-slider-color' | translate
                        }}</span>
                        <input
                            [(colorPicker)]="options.offSliderColor"
                            [style.background]="options.offSliderColor"
                            [cpAlphaChannel]="'always'"
                            class="input-color"
                            [cpPresetColors]="defaultColor"
                            [cpOKButton]="true"
                            [cpCancelButton]="true"
                            [cpCancelButtonClass]="'cpCancelButtonClass'"
                            [cpCancelButtonText]="'Cancel'"
                            [cpOKButtonText]="'OK'"
                            [cpOKButtonClass]="'cpOKButtonClass'"
                            style="padding: 8px 0 0 0; width: 98px"
                            [cpPosition]="'right'"
                            (colorPickerChange)="updateOptions()"
                        />
                    </div>
                    <div
                        class="my-form-field slider-field"
                        style="padding-left: 50px; top: -1px"
                    >
                        <span>{{
                            'html-switch.property-radius' | translate
                        }}</span>
                        <mat-slider
                            ngDefaultControl
                            style="display: inline-block"
                            [max]="100"
                            [min]="0"
                            [step]="1"
                            discrete
                            [(ngModel)]="options.radius"
                            (input)="updateOptions()"
                        >
                        </mat-slider>
                    </div>
                    <div
                        class="my-form-field color-field"
                        style="float: right; padding-left: 12px"
                    >
                        <span>{{
                            'html-switch.property-on-background' | translate
                        }}</span>
                        <input
                            [(colorPicker)]="options.onBackground"
                            [style.background]="options.onBackground"
                            [cpAlphaChannel]="'always'"
                            class="input-color"
                            [cpPresetColors]="defaultColor"
                            [cpOKButton]="true"
                            [cpCancelButton]="true"
                            [cpCancelButtonClass]="'cpCancelButtonClass'"
                            [cpCancelButtonText]="'Cancel'"
                            [cpOKButtonText]="'OK'"
                            [cpOKButtonClass]="'cpOKButtonClass'"
                            style="padding: 8px 0 0 0; width: 98px"
                            [cpPosition]="'left'"
                            (colorPickerChange)="updateOptions()"
                        />
                    </div>
                    <div class="my-form-field color-field" style="float: right">
                        <span>{{
                            'html-switch.property-on-slider-color' | translate
                        }}</span>
                        <input
                            [(colorPicker)]="options.onSliderColor"
                            [style.background]="options.onSliderColor"
                            [cpAlphaChannel]="'always'"
                            class="input-color"
                            [cpPresetColors]="defaultColor"
                            [cpOKButton]="true"
                            [cpCancelButton]="true"
                            [cpCancelButtonClass]="'cpCancelButtonClass'"
                            [cpCancelButtonText]="'Cancel'"
                            [cpOKButtonText]="'OK'"
                            [cpOKButtonClass]="'cpOKButtonClass'"
                            style="padding: 8px 0 0 0; width: 98px"
                            [cpPosition]="'left'"
                            (colorPickerChange)="updateOptions()"
                        />
                    </div>
                </div>
                <div class="field-row">
                    <div class="my-form-field color-field">
                        <span>{{
                            'html-switch.property-off-text-color' | translate
                        }}</span>
                        <input
                            [(colorPicker)]="options.offTextColor"
                            [style.background]="options.offTextColor"
                            [cpAlphaChannel]="'always'"
                            class="input-color"
                            [cpPresetColors]="defaultColor"
                            [cpOKButton]="true"
                            [cpCancelButton]="true"
                            [cpCancelButtonClass]="'cpCancelButtonClass'"
                            [cpCancelButtonText]="'Cancel'"
                            [cpOKButtonText]="'OK'"
                            [cpOKButtonClass]="'cpOKButtonClass'"
                            style="padding: 8px 0 0 0; width: 98px"
                            [cpPosition]="'top'"
                            (colorPickerChange)="updateOptions()"
                        />
                    </div>
                    <div class="my-form-field" style="padding-left: 12px">
                        <span>{{
                            'html-switch.property-off-text' | translate
                        }}</span>
                        <input
                            [(ngModel)]="options.offText"
                            (change)="updateOptions()"
                            style="
                                width: 60px;
                                text-align: center;
                                display: inline-block;
                            "
                            type="text"
                        />
                    </div>
                    <div
                        class="my-form-field slider-field slider-small"
                        style="padding-left: 17px; top: -2px; width: 80px"
                    >
                        <span>{{
                            'html-switch.property-font-size' | translate
                        }}</span>
                        <mat-slider
                            ngDefaultControl
                            style="display: inline-block"
                            [max]="50"
                            [min]="0"
                            [step]="1"
                            discrete
                            [(ngModel)]="options.fontSize"
                            (input)="updateOptions()"
                        >
                        </mat-slider>
                    </div>
                    <div
                        class="my-form-field"
                        style="width: 160px; padding-left: 12px"
                    >
                        <span>{{
                            'html-switch.property-font' | translate
                        }}</span>
                        <mat-select
                            [(value)]="options.fontFamily"
                            (selectionChange)="updateOptions()"
                            name="fontfamily"
                        >
                            <mat-option
                                *ngFor="let font of fonts"
                                [style.fontFamily]="font"
                                [value]="font"
                                name="fontOption"
                            >
                                {{ font }}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div
                        class="my-form-field color-field"
                        style="float: right; padding-left: 12px"
                    >
                        <span>{{
                            'html-switch.property-on-text-color' | translate
                        }}</span>
                        <input
                            [(colorPicker)]="options.onTextColor"
                            [style.background]="options.onTextColor"
                            [cpAlphaChannel]="'always'"
                            class="input-color"
                            [cpPresetColors]="defaultColor"
                            [cpOKButton]="true"
                            [cpCancelButton]="true"
                            [cpCancelButtonClass]="'cpCancelButtonClass'"
                            [cpCancelButtonText]="'Cancel'"
                            [cpOKButtonText]="'OK'"
                            [cpOKButtonClass]="'cpOKButtonClass'"
                            style="padding: 8px 0 0 0; width: 98px"
                            [cpPosition]="'top'"
                            (colorPickerChange)="updateOptions()"
                        />
                    </div>
                    <div
                        class="my-form-field"
                        style="float: right; padding-left: 12px"
                    >
                        <span>{{
                            'html-switch.property-on-text' | translate
                        }}</span>
                        <input
                            [(ngModel)]="options.onText"
                            (change)="updateOptions()"
                            style="
                                width: 60px;
                                text-align: center;
                                display: inline-block;
                            "
                            type="text"
                        />
                    </div>
                </div>
                <div
                    class="field-row"
                    style="
                        position: absolute;
                        height: 210px;
                        width: 650px;
                        margin-top: 16px;
                        max-width: 650px;
                        max-height: 210px;
                        overflow: hidden;
                    "
                >
                    <ngx-switch
                        #switcher
                        style="
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                        "
                        [style.width]="switchWidth + 'px'"
                        [style.height]="switchHeight + 'px'"
                    ></ngx-switch>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div mat-dialog-actions class="dialog-action">
        <button mat-raised-button (click)="onNoClick()">
            {{ 'dlg.cancel' | translate }}
        </button>
        <button
            mat-raised-button
            color="primary"
            (click)="onOkClick()"
            [mat-dialog-close]="data"
            cdkFocusInitial
        >
            {{ 'dlg.ok' | translate }}
        </button>
    </div>
</div>
