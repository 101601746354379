import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';
import { Observable, lastValueFrom, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isUserLogInned = false;
    private domain: string;
    private currentUser = { name: '', oid: '' };

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    azureLogIn() {
        window.location.href = `${this.domain}/auth/login`;
    }

    edgeLogIn(name: string, pincode: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http.post(`${this.domain}/auth/login/pincode`, {
            headers: headers,
            name: name,
            pincode: pincode,
        });
    }

    azureLogOut() {
        this.setLogInned(false);
        window.location.href = `${this.domain}/auth/logout`;
    }

    edgeLogout() {
        this.setLogInned(false);
        window.location.href = `${this.domain}/auth/ops/logout`;
    }

    setLogInned(isSuccess: boolean) {
        this.isUserLogInned = isSuccess;
    }

    isAuthenticated(): Promise<any> {
        return lastValueFrom(this.http.get('/auth/check'));
    }

    setCurrentUser(name: string, oid: string) {
        this.currentUser.name = name;
        this.currentUser.oid = oid;
    }

    getCurrentUser() {
        return this.currentUser;
    }
}
