import { Channel } from 'common/models/channel';
import { ChannelGroup } from 'common/models/channel-group';
import { CloudOperation } from 'common/models/publish';
import { ProjectData } from './project';

export interface ChannelCompare {
    //channel data from blob
    blob: Channel;
    //channel data from db
    local: Channel;
    modifiedFields: string[];
    operationType: string;
}

export interface GroupCompare {
    blob: ChannelGroup;
    local: ChannelGroup;
    modifiedFields: string[];
    operationType: string;
}

export interface CompareCount {
    update: number;
    add: number;
    delete: number;
}

export interface ChannelMerge {
    version: string;
    groups: {
        differenceItems: GroupCompare[];
        compareCount: CompareCount;
    };
    channels: {
        differenceItems: ChannelCompare[];
        compareCount: CompareCount;
    };
    type: CloudOperation.PUBLISH | CloudOperation.PULL;
    projectData: ProjectData;
}

export interface CompareResponse {
    channels: {
        differenceItems: ChannelCompare[];
        compareCount: CompareCount;
    };
    groups: {
        differenceItems: GroupCompare[];
        compareCount: CompareCount;
    };
}

export enum ChannelOperation {
    UPDATE = 'update',
    DELETE = 'delete',
    ADD = 'add',
}

export enum ChannelObjectKey {
    CHANNELS = 'channels',
    GROUPS = 'groups',
}
