import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportSettings } from '@helpers/export-utils';

@Component({
    selector: 'app-export-dialog',
    templateUrl: './export-dialog.component.html',
    styleUrls: ['./export-dialog.component.css'],
})
export class ExportDialogComponent implements OnInit {
    selectedOrCanvas = new UntypedFormControl('canvas');
    individualOrTogether = new UntypedFormControl('together');
    type = new UntypedFormControl('svg');
    resolution = new UntypedFormControl('1');

    constructor(@Inject(MAT_DIALOG_DATA) public data: { areElementsSelected: boolean }) {}

    ngOnInit(): void {}

    getData(): ExportSettings {
        return {
            selected: this.selectedOrCanvas.value == 'selected' ? true : false,
            separate: this.individualOrTogether.value == 'separate' ? true : false,
            type: this.type.value,
            resolution: this.resolution.value,
        };
    }
}
