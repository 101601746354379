<div class="wrapper">
    <h1 mat-dialog-title style="display: inline-block;">
        Select a version
    </h1>
    <br />
    <div class="title-wrapper">
        <h2 mat-dialog-title style="display: inline-block;">
            Builder: {{ builderName }}
        </h2>
        <h2 mat-dialog-title style="display: inline-block;">
            Vessel: {{vesselName}}
        </h2>
    </div>

    <br />
    <div class="search-register-area">
        <label class="search-input"
            >Search: <input type="text" name="search" [(ngModel)]="searchText"
        /></label>
    </div>
    <table>
        <tr>
            <th class="table-title">Version Number</th>
        </tr>
    </table>
    <div class="blobVersionList">
        <table>
            <tr
                *ngFor="let d of verionList | filter: searchText"
                (click)="rowClick(d)"
            >
                <td>{{ d }}</td>
            </tr>
        </table>
    </div>
</div>
