<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    <p>{{ guideText }}</p>
    <mat-form-field appearance="fill">
        <mat-label>{{ hint }}</mat-label>
        <input
            id="textInput"
            matInput
            [(ngModel)]="inputText"
            onfocus="this.select()"
        />
    </mat-form-field>
    <div *ngIf="hasError()" class="error-container text light">
        <mat-icon svgIcon="error" class="error-icon"></mat-icon>
        <span data-testid="errorSetPoint"> {{ getErrorString() }}</span>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">
        {{ "dlg.cancel" | translate }}
    </button>
    <button
        mat-raised-button
        cdkFocusInitial
        (click)="onOkClick()"
        color="primary"
        [disabled]="hasError()"
    >
        {{ "dlg.ok" | translate }}
    </button>
</div>
