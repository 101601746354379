import { Subscription, lastValueFrom } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ChannelService } from '@service/channel.service';
import { ProjectService } from '@service/project.service';
import { Channel } from 'common/models/channel';
import { BarGraph, ExhaustGraph, GaugeGraph, Graph, GraphType } from 'common/models/graphs';
import { GraphService } from '@service/graphs/graph.service';
import { GraphSocketService } from '@service/graphs/graphs-socket.service';

@Component({
    selector: 'app-graph-settings',
    templateUrl: './graph-settings.component.html',
    styleUrls: ['./graph-settings.component.scss'],
})
export class GraphSettingsComponent implements OnInit {
    channels: Channel[];
    graphs: Graph[];
    editingGraph: Graph;
    activeGraph;

    graphTypes = GraphType;

    lockedGraphSubscription: Subscription;
    unlockedGraphSubscription: Subscription;
    deletedGraphSubscription: Subscription;
    newGraphSubscription: Subscription;
    updatedGraphSubscription: Subscription;

    activeSubscriptions: Subscription[];

    constructor(
        private channelService: ChannelService,
        private projectService: ProjectService,
        private graphService: GraphService,
        private graphSocketService: GraphSocketService
    ) {}

    async ngOnInit() {
        const projectId = this.projectService.getProjectId();
        this.channels = await lastValueFrom(this.channelService.getChannels(projectId));
        this.graphs = await this.graphService.getAll(projectId);

        this.lockedGraphSubscription = this.graphSocketService.lockedGraphs$().subscribe((graphId) => {
            console.log("locked graphId", graphId)
            this.graphs.map((graph) => {
                if (graph.id === graphId) {
                    graph.locked = true;
                }
            });
        });

        this.unlockedGraphSubscription = this.graphSocketService
            .unlockedGraphs$()
            .subscribe((graphId) => {
                console.log("unlocked graphId", graphId)
                this.graphs.map((graph) => {
                    if (graph.id === graphId) {
                        graph.locked = false;
                    }
                });
            });

        this.newGraphSubscription = this.graphSocketService.newGraph$().subscribe((graph: Graph) => {
            this.graphs.push(graph)
        });

        this.deletedGraphSubscription = this.graphSocketService.deletedGraphs$().subscribe((graphId) => {
            const index = this.graphs.findIndex((graph) => graph.id === graphId);
            if (index !== -1) {
                this.graphs.splice(index, 1);
            }
        });

        this.updatedGraphSubscription = this.graphSocketService.updateGraph$().subscribe((udpatedGraph: Graph) => {
            this.graphs.map((graph) => {
                if (graph.id === udpatedGraph.id) {
                    graph = udpatedGraph
                }
            });
        });

        this.activeSubscriptions = [
            this.lockedGraphSubscription,
            this.unlockedGraphSubscription,
            this.deletedGraphSubscription,
            this.newGraphSubscription,
            this.updatedGraphSubscription
        ];
    }

    ngOnDestroy(): void {
        this.activeSubscriptions.map((sub) => {
            sub.unsubscribe();
        });
    }

    async handleEditGraph(selectedGraph: Graph) {
        const graph = await this.graphService.getGraph(selectedGraph.id, selectedGraph.type, true);

        if (graph[selectedGraph.type]) {
            this.activeGraph = graph[selectedGraph.type];
        } else {
            if (selectedGraph.type === GraphType.Bar) {
                this.activeGraph = new BarGraph();
            } else if (selectedGraph.type === GraphType.Exhaust) {
                this.activeGraph = new ExhaustGraph();
            } else if (selectedGraph.type === GraphType.Gauge) {
                this.activeGraph = new GaugeGraph();
            }
        }
        this.editingGraph = selectedGraph;
    }

    async handleExitGraph(graphId) {
        const projectId = this.projectService.getProjectId();
        await this.graphService.unlockGraph(graphId)
        this.graphs = await this.graphService.getAll(projectId);
        this.editingGraph = null;
    }
}
