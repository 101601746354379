import { Injectable } from '@angular/core';
import { CanvasService } from './canvas/canvas.service';
import { View, DictionaryGaugeSettings } from 'common/models/view';

@Injectable()
export class ViewService {
    constructor(private canvasService: CanvasService) {}

    public renameSvgItemId(view: View) {
        const newItems: DictionaryGaugeSettings = {};
        const renamedId: Array<{ newId: string; oldId: string }> = [];

        for (const key in view.items) {
            const id = view.items[key].id.substring(0, view.items[key].id.lastIndexOf('_'));
            const canvas = this.canvasService.getCanvas();
            const newId = canvas.getNextId().replace('svg_', id + '_');
            newItems[newId] = {
                ...view.items[key],
                id: newId,
            };
            renamedId.push({ oldId: view.items[key].id, newId: newId });
        }

        return { renamedId, newItems };
    }

    public replaceSvgContentId(
        renamedId: Array<{ newId: string; oldId: string }>,
        view: View,
        newItems: DictionaryGaugeSettings,
    ) {
        let newSvgContent = view.svgcontent;
        renamedId.forEach((data) => {
            newSvgContent = newSvgContent.replace(data.oldId, data.newId);
        });

        const newView = { ...view, items: newItems, svgcontent: newSvgContent };
        const v: View = JSON.parse(JSON.stringify(newView));

        return v;
    }

    public getViewCanvas() {
        return this.canvasService.getCanvas();
    }
}
