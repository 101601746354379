<div class="run-hour-settings-main">
    <div
        class="run-hour-settings"
        *ngIf="channels && runHourSettingList; else loadingSpinner"
    >
        <app-run-hour-settings-table
            [channels]="channels"
            [runHourSettingList]="runHourSettingList"
            (onSelectedChange)="handleSelectedChanged($event)"
            class="run-hour-list"
        >
        </app-run-hour-settings-table>

        <!-- Options -->
        <div class="buttons-container">
            <button
                [disabled]="!canAddSetting()"
                class="add"
                (click)="addSetting()"
            >
                {{ "run-hour-settings.button.add" | translate }}
            </button>
            <button
                [disabled]="!selectedSetting"
                class="delete"
                (click)="deleteSetting()"
            >
                {{ "run-hour-settings.button.delete" | translate }}
            </button>

            <button
                class="save"
                [disabled]="saveButtonIsDisabled()"
                (click)="saveList()"
            >
                {{ "run-hour-settings.button.save" | translate }}
            </button>
            <button
                class="exit"
                [disabled]="settingListhasErrors()"
                (click)="exit()"
            >
                {{ "run-hour-settings.button.exit" | translate }}
            </button>
        </div>

        <app-confirmation-window
            #confirmationWindow
            [mainLabel]="'run-hour-settings.close.confirmation.title' | translate"
            [confirmLabel]="
                'run-hour-settings.close.confirmation.confirm' | translate
            "
            [cancelLabel]="
                'run-hour-settings.close.confirmation.cancel' | translate
            "
        ></app-confirmation-window>
    </div>
</div>

<ng-template #loadingSpinner>
    <app-loading-spinner></app-loading-spinner>
</ng-template>
