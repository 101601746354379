import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChannelSheetService } from '@service/channel-sheet.service';
import { ChannelGroup } from 'common/models/channel-group';
import { DialogType } from '../dialog-channel-category/dialog-channel-category.component';

@Component({
    selector: 'app-channel-panel-group',
    templateUrl: './channel-panel-group.component.html',
    styleUrls: ['./channel-panel-group.component.css'],
})
export class ChannelPanelGroupComponent {
    @Input() channelGroups: ChannelGroup[] = [];
    //current opened channel group
    activeChannelGroup: ChannelGroup;
    //selected channel group for more options(delete/rename/duplicate)
    selectedChannelGroup: ChannelGroup = new ChannelGroup();
    @Output() openGroupEmitter = new EventEmitter();
    constructor(private channelSheetService: ChannelSheetService) {}

    ngOnInit() {
        this.setActiveGroup();
        this.channelSheetService.selectGroupEmitter.subscribe((group) => {
            this.setActiveGroup();
        });
    }

    selectGroup(group: ChannelGroup) {
        this.activeChannelGroup = group;
        this.channelSheetService.setActiveGroup(this.activeChannelGroup);
        this.channelSheetService.updateChannelSheet();
    }

    openMore(group: ChannelGroup) {
        if (this.selectedChannelGroup.id == group.id) {
            this.selectedChannelGroup = new ChannelGroup();
        } else {
            this.selectedChannelGroup = group;
        }
    }

    closeMore() {
        this.selectedChannelGroup = new ChannelGroup();
    }

    onRename() {
        this.openGroupEmitter.emit({ type: DialogType.rename, group: this.selectedChannelGroup });
        this.closeMore();
    }

    onDuplicate() {
        console.log('Design is not done yet');
        this.closeMore();
    }

    onDelete() {
        this.openGroupEmitter.emit({ type: DialogType.delete, group: this.selectedChannelGroup });
        this.closeMore();
    }

    setActiveGroup() {
        this.activeChannelGroup = this.channelSheetService._activeGroup;
    }
}
