import { Injectable } from '@angular/core';
import { SvgComponentSerializationService } from './svg-component-serialization.service';

@Injectable()
export class SvgXmlSerializationService extends SvgComponentSerializationService {
    public serialize(components: SVGGraphicsElement[]): string {
        const serializer = new XMLSerializer();
        return components.map((e) => serializer.serializeToString(e)).join('');
    }

    public deserialize(componentString: string): SVGGraphicsElement[] {
        const parser = new DOMParser();

        const doc = parser.parseFromString('<svg>' + componentString + '</svg>', 'image/svg+xml');

        const parsedElements = Array(doc.documentElement.children.length);
        for (let i = 0; i < doc.documentElement.children.length; i++) {
            parsedElements[i] = doc.documentElement.children[i];
        }

        return parsedElements as SVGGraphicsElement[];
    }
}
