<div
    *ngIf="hasValidationErrorString(this.propertyName)"
    class="error-container text light"
>
    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
    <span data-testid="errorSetPoint">
        {{ getErrorString(this.propertyName) }}</span
    >
</div>
<div
    *ngIf="settingIsUpdated"
    class="error-container text light"
>
    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
    <span data-testid="errorSetPoint">
        {{ 'dlg.app-settings.already-updated' | translate }}</span
    >
</div>
