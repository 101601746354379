import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VesselBuilderCreateService } from '@service/vessel-builder-create.service';

@Component({
    selector: 'app-vessel-builder-select-list',
    templateUrl: './vessel-builder-select-list.component.html',
    styleUrls: ['./vessel-builder-select-list.component.scss'],
})
export class VesselBuilderSelectListComponent implements OnInit {
    searchText: string;

    constructor(
        private vesselBuilderCreateService: VesselBuilderCreateService,
        public dialogRef: MatDialogRef<VesselBuilderSelectListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {}

    rowClick = (p) => {
        console.log(p);
        this.dialogRef.close(p);
    };

    onRegister() {
        this.vesselBuilderCreateService
            .openBuilderInfoEnteringDialog()
            .then((res) => {
                if (res !== undefined && this.notEmpty(res)) {
                    console.log(res);
                    this.data.push(res);
                }
            })
            .catch((error) => {});
    }

    private notEmpty(obj): boolean {
        if (Object.keys(obj).length > 0) return true;
        return false;
    }
}
