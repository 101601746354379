import { ObjectSchema, array, number, object, string } from 'yup';
import { GaugeGraph, GaugeGraphChannel } from '../../../models/graphs';
import { locale } from './../../yup-locale';
import * as yup from 'yup';
yup.setLocale(locale);

export function GaugeGraphChannelValidator(): ObjectSchema<GaugeGraphChannel> {
    return object({
        fcuChannelId: number().integer().required().nonNullable().strict(),
        divisions: number().integer().nonNullable().strict().required()
        .test('not-zero', 'graph-settings.graph.error.not-zero', (value) => value !== 0)
        .test('min', 'graph-settings.graph.error.min', (value) => value >= 0)
        .test('max-ten', 'graph-settings.graph.error.max-ten', (value) => value <= 10),
    }).required().strict().noUnknown().nonNullable();
} 

export function GaugeGraphValidator(): ObjectSchema<GaugeGraph> {
    return object({
        id: string().uuid().required().nonNullable().strict(),
        channels: array().of(GaugeGraphChannelValidator()).required().nonNullable().strict(),
        graphsId: string().notRequired(),
    }).required().strict().noUnknown().nonNullable() as ObjectSchema<GaugeGraph>;
}
