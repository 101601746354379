var sheetColumn = {
    RowId: 'ID',
    FcuNo: 'Fcu no.',
    PartNo: 'Part no.',
    ChannelType: 'Channel type',
    Tag: 'Channel No\n(tag)',
    Name: 'Name',
    MeasurableRangeMax: 'Measurable Range Max',
    MeasurableRangeMin: 'Measurable Range Min',
    NormalRangeMax: 'Normal range Max',
    NormalRangeMin: 'Normal range Min',
    Scale: 'Scale',
    MeasurementUnit: 'Unit',

    InputDataFuInputAddressFuNo: 'Input Fu No.',
    InputDataFuInputAddressSlotNo: 'Input Slot No.',
    InputDataFuInputAddressPin: 'Input Pin  No.',
    InputDataFuInputAddressPinCount: 'Input Pin Count',
    InputDataMemoryInputAddressAddress: 'Input Memory Input Address',
    InputDataMemoryInputAddressMask: 'Input Memory Input Mask',
    InputDataMiscDigitalInputFilter: 'Input Misc Digital Input Filter',
    InputDataMiscHysteresis: 'Input Misc Hysteresis',

    OutputDataFuOutputAddressFuNo: 'Output Fu No.',
    OutputDataFuOutputAddressSlotNo: 'Output Slot No.',
    OutputDataFuOutputAddressPin: 'Output Pin  No.',
    OutputDataFuOutputAddressPinCount: 'Output Pin Count',
    OutputDataMemoryOutputAddressAddress: 'Output Memory Output Address',
    OutputDataMemoryOutputAddressMask: 'Output Memory Output Mask',
    OutputDataMiscSetPoint: 'Output Misc Set Point',
    OutputDataMiscKeepSignal: 'Output Misc Keep Signal',
    OutputDataMiscOneShotSignal: 'Output Misc One Shot Signal',
    OutputDataMiscPulseWidth: 'Output Misc Pulse Width',
    OutputDataMiscHysteresisForOpen: 'Output Misc Hysteresis for open',
    OutputDataMiscHysteresisForClose: 'Output Misc Hysteresis for close',

    AlarmSettingEnable: 'Alarm settings Enable',
    AlarmSettingStatusString: 'Alarm settings Status string',
    AlarmSettingSetPoint: 'Alarm settings Set point',
    AlarmSettingAlarmGroupNo: 'Alarm settings Alarm group no',
    AlarmSettingReposeGroup1No: 'Alarm settings Repose group1 no',
    AlarmSettingReposeGroup2No: 'Alarm settings Repose group2 no',
    AlarmSettingDelayTimer: 'Alarm settings Delay timer',
    AlarmSettingNormalOpenFlag: 'Alarm settings Normal open flag',
    AlarmSettingAlarmLevel: 'Alarm settings Alarm level',

    AlarmSettingVeryHighEnable: 'Very High Enable',
    AlarmSettingVeryHighStatusString: 'Very High Status string',
    AlarmSettingVeryHighSetPoint: 'Very High Set point',
    AlarmSettingVeryHighAlarmGroupNo: 'Very High Alarm group no',
    AlarmSettingVeryHighReposeGroup1No: 'Very High Repose group1 no',
    AlarmSettingVeryHighReposeGroup2No: 'Very High Repose group2 no',
    AlarmSettingVeryHighDelayTimer: 'Very High Delay timer',
    AlarmSettingVeryHighNormalOpenFlag: 'Very High Normal open flag',
    AlarmSettingVeryHighAlarmLevel: 'Very High Alarm level',

    AlarmSettingHighEnable: 'High Enable',
    AlarmSettingHighStatusString: 'High Status string',
    AlarmSettingHighSetPoint: 'High Set point',
    AlarmSettingHighAlarmGroupNo: 'High Alarm group no',
    AlarmSettingHighReposeGroup1No: 'High Repose group1 no',
    AlarmSettingHighReposeGroup2No: 'High Repose group2 no',
    AlarmSettingHighDelayTimer: 'High Delay timer',
    AlarmSettingHighNormalOpenFlag: 'High Normal open flag',
    AlarmSettingHighAlarmLevel: 'High Alarm level',

    AlarmSettingLowEnable: 'Low Enable',
    AlarmSettingLowStatusString: 'Low Status string',
    AlarmSettingLowSetPoint: 'Low Set pointt',
    AlarmSettingLowAlarmGroupNo: 'Low Alarm group no',
    AlarmSettingLowReposeGroup1No: 'Low Repose group1 no',
    AlarmSettingLowReposeGroup2No: 'Low Repose group2 no',
    AlarmSettingLowDelayTimer: 'Low Delay timer',
    AlarmSettingLowNormalOpenFlag: 'Low Normal open flag',
    AlarmSettingLowAlarmLevel: 'Low Alarm level',

    AlarmSettingVeryLowEnable: 'Very Low Enable',
    AlarmSettingVeryLowStatusString: 'Very Low Status string',
    AlarmSettingVeryLowSetPoint: 'Very Low Set point',
    AlarmSettingVeryLowAlarmGroupNo: 'Very Low Alarm group no',
    AlarmSettingVeryLowReposeGroup1No: 'Very Low Repose group1 no',
    AlarmSettingVeryLowReposeGroup2No: 'Very Low Repose group2 no',
    AlarmSettingVeryLowDelayTimer: 'Very Low Delay timer',
    AlarmSettingVeryLowNormalOpenFlag: 'Very Low Normal open flag',
    AlarmSettingVeryLowAlarmLevel: 'Very Low Alarm level',

    AlarmSettingFeedbackErrorEnable: 'Feedback Error enable',
    AlarmSettingFeedbackErrorStatusString: 'Feedback Error Status string',
    AlarmSettingFeedbackErrorSetPoint: 'Feedback Error Set point',
    AlarmSettingFeedbackErrorAlarmGroupNo: 'Feedback Error Alarm group no',
    AlarmSettingFeedbackErrorReposeGroup1No: 'Feedback Error Repose group1 no',
    AlarmSettingFeedbackErrorReposeGroup2No: 'Feedback Error Repose group2 no',
    AlarmSettingFeedbackErrorDelayTimer: 'Feedback Error Delay timer',
    AlarmSettingFeedbackErrorNormalOpenFlag: 'Feedback Error Normal open flag',
    AlarmSettingFeedbackErrorAlarmLevel: 'Feedback Error Alarm level',

    FixedDigit: 'Fixed Digit',
    DigitalInputFilter: 'Digital Input filter',
    StandardNameId: 'Standard Name Id',
};

export const columns = Object.keys(sheetColumn);

const wideColumns = new Map([
    [sheetColumn.Name, 390],
    [sheetColumn.RowId, 150],
    [sheetColumn.ChannelType, 280],
]);

const letColumns = new Set([sheetColumn.Name, sheetColumn.ChannelType]);

const checkBoxPropertySet = new Set([
    'AlarmSettingEnable',
    'AlarmSettingNormalOpenFlag',
    'AlarmSettingNormalOpenFlag',
    'AlarmSettingVeryHighEnable',
    'AlarmSettingVeryHighNormalOpenFlag',
    'AlarmSettingHighEnable',
    'AlarmSettingHighNormalOpenFlag',
    'AlarmSettingLowEnable',
    'AlarmSettingLowNormalOpenFlag',
    'AlarmSettingVeryLowEnable',
    'AlarmSettingVeryLowNormalOpenFlag',
    'AlarmSettingFeedbackErrorEnable',
    'AlarmSettingFeedbackErrorNormalOpenFlag',
    'OutputDataMiscKeepSignal',
    'OutputDataMiscOneShotSignal',
]);

const AlarmPropertySet = new Set([
    'AlarmSettingEnable',
    'AlarmSettingVeryHighEnable',
    'AlarmSettingHighEnable',
    'AlarmSettingLowEnable',
    'AlarmSettingVeryLowEnable',
    'AlarmSettingFeedbackErrorEnable',
]);

const dropdownPropertyMap = new Map([
    ['ChannelType', { dropdownSources: 'channelType' }],
    ['AlarmSettingAlarmLevel', { dropdownSources: 'alarmLevel' }],
    ['AlarmSettingVeryHighAlarmLevel', { dropdownSources: 'alarmLevel' }],
    ['AlarmSettingNormalAlarmLevel', { dropdownSources: 'alarmLevel' }],
    ['AlarmSettingLowAlarmLevel', { dropdownSources: 'alarmLevel' }],
    ['AlarmSettingVeryLowAlarmLevel', { dropdownSources: 'alarmLevel' }],
    ['AlarmSettingFeedbackAlarmLevel', { dropdownSources: 'alarmLevel' }],
]);

const dropdownSources = {
    channelType: [
        'Digital (Normal close)',
        'Digital (Normal open)',
        'Digital (Motor)',
        'Analog (4-20mA PT)',
        'Analog (4-20mA AI)',
        'Analog (1-5V)',
        'Analog (Temp Pt100 3-wire)',
        'Analog (Temp Pt100 2-wire)',
        'Analog (Temp K)',
    ],
    alarmLevel: ['Alarm', 'Warning', 'Caution'],
};

export function generateColums() {
    let columns = [];
    let columnsKey = Object.keys(sheetColumn);

    for (let i = 0; i < columnsKey.length; i++) {
        if (checkBoxPropertySet.has(columnsKey[i])) {
            columns[i] = {
                type: 'checkbox',
                title: sheetColumn[columnsKey[i]],
                width: getColumnWidth(sheetColumn[columnsKey[i]]),
                allowEmpty: true,
            };
        } else if (dropdownPropertyMap.has(columnsKey[i])) {
            let property = dropdownPropertyMap.get(columnsKey[i]);
            columns[i] = {
                type: 'dropdown',
                title: sheetColumn[columnsKey[i]],
                source: dropdownSources[property.dropdownSources],
                align: letColumns.has(sheetColumn[columnsKey[i]]) ? 'left' : 'center',
                width: getColumnWidth(sheetColumn[columnsKey[i]]),
            };
        } else {
            columns[i] = {
                type: 'text',
                title: sheetColumn[columnsKey[i]],
                width: getColumnWidth(sheetColumn[columnsKey[i]]),
                align: letColumns.has(sheetColumn[columnsKey[i]]) ? 'left' : 'center',
                readOnly: sheetColumn[columnsKey[i]] == sheetColumn.RowId,
            };
        }
    }
    return columns;
}

function getColumnWidth(key: string) {
    if (wideColumns.has(key)) {
        return wideColumns.get(key);
    } else {
        return key.length * 10;
    }
}

export let alarmCols = {
    'high-high': { key: 'high-high', colStart: 41, colEnd: 50 },
    high: { key: 'high', colStart: 51, colEnd: 60 },
    low: { key: 'low', colStart: 61, colEnd: 70 },
    'low-low': { key: 'low-low', colStart: 71, colEnd: 80 },
};
