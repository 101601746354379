<div class="search-form">
    <div>
        <div
            class="selector"
            (click)="showGroups ? (showGroups = false) : (showGroups = true)"
        >
            <p class="inside-selector">
                {{ "print-channels.selector.title" | translate }}
            </p>
            <mat-icon *ngIf="!showGroups" [ngStyle]="{ marginLeft: 'auto' }"
                >expand_more</mat-icon
            >
            <mat-icon *ngIf="showGroups" [ngStyle]="{ marginLeft: 'auto' }"
                >expand_less</mat-icon
            >
        </div>
        <div *ngIf="showGroups && groupList.length > 0" class="groupList">
            <mat-checkbox [(ngModel)]="printAll" (click)="selectAllGroups()">
                {{ "print-channels.selector.option.print-all" | translate }}
            </mat-checkbox>
            <div
                *ngFor="let groupName of groupList"
                class="group-name"
                [ngClass]="{ selected: groupName.checked }"
                (click)="checkedGroup(groupName, false)"
            >
                <mat-checkbox
                    [(ngModel)]="groupName.checked"
                    (ngModelChange)="checkedGroup(groupName, true)"
                    (click)="$event.stopPropagation()"
                >
                    {{ groupName.name }}
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>

<div class="selected-groups">
    <div *ngFor="let group of groupsSelected" class="selected-group">
        <p>
            {{ group.name }}
        </p>
        <mat-icon class="close-icon" (click)="unselectGroup(group)"
            >clear</mat-icon
        >
    </div>
</div>
