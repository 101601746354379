import { Component, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-confirmation-window',
    templateUrl: './confirmation-window.component.html',
    styleUrls: ['./confirmation-window.component.scss'],
})
export class ConfirmationWindowComponent {
    @ViewChild('overlay') overlay;
    public onOptionSelected: Subject<boolean>;

    @Input() mainLabel: string = '';
    @Input() subLabel: string = '';

    @Input() confirmLabel: string = '';
    @Input() cancelLabel: string = '';

    @Input() showCancel: boolean = true;

    style = {
        display: 'none',
    };

    constructor() {
        this.onOptionSelected = new Subject();
    }

    ngOnDestroy(): void {
        this.onOptionSelected.complete();
    }

    public setVisible(visible: boolean) {
        this.style.display = visible ? 'block' : 'none';
    }

    emitValue(value: boolean) {
        this.onOptionSelected.next(value);
        this.setVisible(false);
    }
}
