<div *ngIf="user.isAdmin" class="admin-wrapper">
    {{ "ops-settings.users.admin.title" | translate }}
</div>
<div
    *ngIf="!user.isAdmin && !deleteButtonIsDisabled"
    class="icon"
    (click)="deleteUser(user.id)"
>
    <mat-icon svgIcon="delete"></mat-icon>
</div>
<div class="inputs-container">
    <div>
        <div class="user-name-container">
            <div>{{ "ops-settings.users.user-name.title" | translate }}</div>
            <input
                type="text"
                [(ngModel)]="user.name"
                (input)="checkFormErrors()"
                placeholder="Username"
                class="icon-input"
                maxlength="16"
            />
        </div>
        <app-app-settings-error
            [validationErrors]="validationErrors"
            [propertyName]="'name'"
        >
        </app-app-settings-error>
    </div>

    <div>
        <div class="user-password-container">
            <div>{{ "ops-settings.users.password.title" | translate }}</div>
            <div class="icon-input">
                <input
                    placeholder="Password"
                    [type]="'password'"
                    [(ngModel)]="user.password"
                    (input)="checkFormErrors()"
                    (click)="openPasswordModal()"
                    style="cursor: pointer"
                />
            </div>
        </div>
        <app-app-settings-error
            [validationErrors]="validationErrors"
            [propertyName]="'password'"
        >
        </app-app-settings-error>
    </div>
</div>

<app-ops-user-permissions
    [permissions]="user.permissions"
    [isAdmin]="user.isAdmin"
    (onPermissionChange)="handleUpdatePermission($event)"
></app-ops-user-permissions>

<div *ngIf="isChangingPassword" class="password-modal-wrapper">
    <app-ops-user-password
        [currentPassword]="user.password"
        (onCloseModal)="isChangingPassword = false"
        (onSavePassword)="savePassword($event)"
    >
    </app-ops-user-password>
</div>
