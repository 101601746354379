import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.css'],
})
export class ContactFormComponent implements OnInit {
    contactForm = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        company: new UntypedFormControl('', Validators.required),
        subject: new UntypedFormControl('', Validators.required),
        message: new UntypedFormControl('', Validators.required),
    });

    constructor() {}

    ngOnInit(): void {}
}
