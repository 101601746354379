import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-doc-name',
    templateUrl: './dialog-doc-name.component.html',
    styleUrls: ['./dialog-doc-name.component.css'],
})
export class DialogDocNameComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogDocNameComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    isValid(name): boolean {
        return this.data.exist.find((n) => n === name) ? false : true;
    }
}
