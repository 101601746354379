import { EventEmitter, Injectable } from '@angular/core';
import { CanvasService } from './canvas.service';

export enum CanvasInputMode {
    SELECT = 'select',
    MULTISELECT = 'multiselect',
    MOVE_CANVAS = 'moveCanvas',
    COMMENT = 'comment',
    TEXT = 'text',
    CONNECTOR = 'connector',
    PATH_EDIT = 'pathedit',
    TEMPLATE_INSERT = 'insert_template',
    EYE_DROPPER = 'eyeDropper',
}

@Injectable()
export class CanvasInputModeService {
    private _onModeChanged = new EventEmitter<CanvasInputMode>();

    constructor(private canvas: CanvasService) {}

    public onModeChanged() {
        return this._onModeChanged;
    }

    private emitModeChange() {
        this._onModeChanged.emit(this.getMode());
    }

    public setMode(tool: CanvasInputMode) {
        this.canvas.getCanvas().setMode(tool);
        this.emitModeChange();
    }

    public getMode(): CanvasInputMode {
        return this.canvas.getCanvas().getMode();
    }
}
