import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChannelCategoryService } from '@service/channel-category.service';
import { ChannelGroupsService } from '@service/channel-groups.service';
import { ProjectService } from '@service/project.service';
import { ChannelCategory } from 'common/models/channel-category';
import { ChannelGroup } from 'common/models/channel-group';
import { DialogType } from '../dialog-channel-category/dialog-channel-category.component';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { ChannelService } from '@service/channel.service';
import { Channel } from 'common/models/channel';
import _ from 'lodash';
import { getValidationErrors, mapErrors } from 'icreate/common/validators';
import { GroupValidator } from '@models/models-validators/group-validator';
@Component({
    selector: 'app-dialog-channel-group',
    templateUrl: './dialog-channel-group.component.html',
    styleUrls: ['./dialog-channel-group.component.css'],
})
export class DialogChannelGroupComponent {
    readonly GROUP_ADD = DialogType.add;
    readonly GROUP_RENAME = DialogType.rename;
    readonly GROUP_DELETE = DialogType.delete;

    isShow: boolean = false;
    channelGroup: ChannelGroup = new ChannelGroup();
    cloneChannelGroup: ChannelGroup = new ChannelGroup();
    channelCategories: ChannelCategory[] = [];
    channels: { [key: string]: Channel[] } = {};
    groups: ChannelGroup[] = [];
    @Output() addGroup = new EventEmitter();
    @Output() renameGroup = new EventEmitter();
    @Output() deleteGroup = new EventEmitter();
    @Input() type: DialogType;

    constructor(
        private channelGroupService: ChannelGroupsService,
        private channelCategoryService: ChannelCategoryService,
        private projectService: ProjectService,
        private translateService: TranslateService,
        private channelService: ChannelService,
    ) {}

    async ngOnInit() {
        let channel = await lastValueFrom(
            this.channelService.getChannels(this.projectService.getProjectId()),
        );

        this.groups = await this.channelGroupService.getChannelGroups(
            this.projectService.getProjectId(),
        );

        this.channels = _.groupBy(channel, 'groupId');
    }

    async open() {
        this.channelCategories = await this.channelCategoryService.getChannelCategories(
            this.projectService.getProjectId(),
        );
        this.isShow = true;
    }

    onClose() {
        this.isShow = false;
    }

    async onOk() {
        if (this.type == DialogType.add) {
            await this.addNewChannelGroup();
        } else if (this.type == DialogType.rename) {
            this.renameChannelGroup();
        } else {
            this.deleteChannelGroup();
        }

        this.onClose();
    }

    async addNewChannelGroup() {
        this.channelGroup.projectId = this.projectService.getProjectId();
        this.addGroup.emit(this.channelGroup);
    }

    async renameChannelGroup() {
        this.renameGroup.emit(this.channelGroup);
    }

    async deleteChannelGroup() {
        this.deleteGroup.emit(this.channelGroup);
    }

    onInputChange(event: { name: string; value: any }) {
        this.channelGroup[event.name] = event.value;
    }

    setDialogData(type: DialogType, group: ChannelGroup) {
        this.type = type;
        if (group) {
            this.channelGroup = { ...group };
            this.cloneChannelGroup = { ...group };
        } else {
            this.channelGroup = new ChannelGroup();
        }
    }

    getTranslate(text) {
        return this.translateService.instant(text, {
            group: this.channelGroup.displayName,
            channel: this.channels[this.channelGroup.id]
                ? this.channels[this.channelGroup.id].length
                : 0,
        });
    }

    getErrors() {
        const validateObject = {
            displayName: this.channelGroup.displayName,
            categoryId: this.channelGroup.categoryId,
        };
        const errors = getValidationErrors(GroupValidator(), validateObject);
        return errors;
    }

    getErrorMsg(key, name) {
        const errors = this.getErrors();
        if (errors.length > 0) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                let errorMsg = this.translateService.instant(validationErrors[key].message, {});
                return `Channel group “${name}“ ${errorMsg}`;
            } else {
                if (key == 'displayName') {
                    return this.getGroupConflictMsg();
                } else {
                    return '';
                }
            }
        } else {
            if (key == 'displayName') {
                return this.getGroupConflictMsg();
            } else {
                return '';
            }
        }
    }

    getGroupConflictMsg() {
        return this.groups.filter(
            (group) =>
                group.displayName == this.channelGroup.displayName &&
                group.id !== this.channelGroup.id,
        ).length > 0
            ? this.translateService.instant('channel.group.conflict', {
                  group: this.channelGroup.displayName,
              })
            : '';
    }

    isButtonDisable() {
        return (
            this.getErrors().length > 0 ||
            this.getGroupConflictMsg() !== '' ||
            _.isEqual(this.cloneChannelGroup, this.channelGroup)
        );
    }
}
