import { Injectable } from '@angular/core';
import * as jsPlumbBrowserUI from './../../../../assets/lib/extensions/pathFinder.js';
import { CanvasService } from '../canvas.service';
import { calculateClickPosition } from './connector-utils';
import { EditConnectorLink, StartConnectorLink } from '@models/connector.js';

@Injectable()
export class ConnectorDrawerService {
    constructor(private canvasService: CanvasService) {}

    private startClickPositions = {};
    private newStartClickPositions = {};
    private initialPath = {};

    updateStartClickPositions(newPositions) {
        this.startClickPositions = newPositions;
    }

    createConnection(connectorLink: StartConnectorLink) {
        const svgCanvas = this.canvasService.getCanvas();
        const initialPosition = connectorLink.initialPosition;
        const finalPosition = connectorLink.finalPosition;
        const source = connectorLink.source;
        const target = connectorLink.target;
        const svgRoot = svgCanvas.svgroot;
        const layer = svgRoot.querySelector('.layer');
        const instance = jsPlumbBrowserUI.newInstance({
            container: layer,
        });
        const clickPositionSource = calculateClickPosition(initialPosition);
        const clickPositionTarget = calculateClickPosition(finalPosition);
        const ids = [source.getAttribute('id'), target.getAttribute('id')];
        const svgTags = layer.getElementsByTagName('svg');
        const sourceID = source.id;
        const targetId = target.id;

        instance.connect({
            source: source,
            target: target,
            connector: { type: jsPlumbBrowserUI.FlowchartConnector.type },
            clickPosition: {
                source: clickPositionSource,
                target: clickPositionTarget,
            },
        });

        for (let i = 0; i < svgTags.length; i++) {
            const circleTag = svgTags[i].getElementsByTagName('circle');
            let xPosition = svgTags[i].getAttribute('x');
            let yPosition = svgTags[i].getAttribute('y');
            if (!circleTag[0]) {
                const pathTag = svgTags[i].getElementsByTagName('path');
                const path = pathTag[0].getAttribute('d');
                const pathArray = path.split(' ');
                let newPath = '';
                const curShape = svgCanvas.curShape;
                for (let j = 0; j < pathArray.length - 1; j = j + 3) {
                    let x = Number(pathArray[j + 1]) + Number(xPosition);
                    let y = Number(pathArray[j + 2]) + Number(yPosition);
                    newPath = newPath + pathArray[j] + ' ' + x + ' ' + y + ' ';
                }
                svgCanvas.addSVGElementsFromJson({
                    element: 'path',
                    curStyles: true,
                    attr: {
                        d: newPath,
                        id: 'connect-' + ids[0] + 'to' + ids[1],
                        transform: pathTag[0].getAttribute('transform'),
                        fill: 'none',
                        opacity: curShape.opacity,
                        'stroke-linecap': 'round',
                        style: 'pointer-events:none',
                        element1: source.id,
                        element2: target.id,
                        startElement1: JSON.stringify(clickPositionSource),
                        startElement2: JSON.stringify(clickPositionTarget),
                    },
                });
            }
        }

        layer.removeAttribute('data-jtk-container');
        layer.removeAttribute('katavorio-draggable');
        source.setAttribute('class', 'layer');

        let sourcePointPositions = JSON.parse(
            source.getAttribute('pointPosition'),
        );
        if (sourcePointPositions) {
            sourcePointPositions[targetId] = clickPositionSource;
        } else {
            sourcePointPositions = {};
            sourcePointPositions[targetId] = clickPositionSource;
        }

        let targetPointPositions = JSON.parse(
            target.getAttribute('pointPosition'),
        );
        if (targetPointPositions) {
            targetPointPositions[sourceID] = clickPositionTarget;
        } else {
            targetPointPositions = {};
            targetPointPositions[sourceID] = clickPositionTarget;
        }

        source.removeAttribute('data-jtk-managed');
        source.setAttribute(
            'pointPosition',
            JSON.stringify(sourcePointPositions),
        );

        target.removeAttribute('data-jtk-managed');
        target.setAttribute(
            'pointPosition',
            JSON.stringify(targetPointPositions),
        );

        const connection = instance.getConnections();
        instance.deleteConnection(connection[0]);

        const newPathElement = svgRoot.querySelector(
            '#connect-' + ids[0] + 'to' + ids[1],
        );
        newPathElement.parent = newPathElement.parentNode;
        svgCanvas.selectOnly([newPathElement]);
        svgCanvas.moveToBottomSelectedElement();
        svgCanvas.addCommandToHistory(
            new svgCanvas.history.InsertElementCommand(newPathElement),
        );
        svgCanvas.call('changed', [newPathElement]);
    }

    editConnection(editConnectorLink: EditConnectorLink) {
        const svgCanvas = this.canvasService.getCanvas();
        const path = editConnectorLink.path;
        const selectedId = editConnectorLink.selectedId;
        const dx = editConnectorLink.dx;
        const dy = editConnectorLink.dy;
        const svgRoot = svgCanvas.svgroot;
        const layer = svgRoot.querySelector('.layer');
        const element1 = path.getAttribute('element1');
        const element2 = path.getAttribute('element2');
        const startElement1 = JSON.parse(path.getAttribute('startElement1'));
        const startElement2 = JSON.parse(path.getAttribute('startElement2'));
        const source = layer.querySelector('#' + element1);
        const target = layer.querySelector('#' + element2);

        if (source.id === selectedId) {
            if (this.startClickPositions[element2][0] === 0) {
                this.startClickPositions[element2][0] = startElement1.curX;
                this.startClickPositions[element2][1] = startElement1.curY;
                this.newStartClickPositions[path.id] = [
                    startElement1,
                    startElement2,
                ];
                this.initialPath[path.id] = path.getAttribute('d');
            } else {
                startElement1.curX = this.startClickPositions[element2][0] + dx;
                startElement1.curY = this.startClickPositions[element2][1] + dy;
            }
            path.setAttribute('startElement1', JSON.stringify(startElement1));
        } else {
            if (this.startClickPositions[element1][0] === 0) {
                this.startClickPositions[element1][0] = startElement2.curX;
                this.startClickPositions[element1][1] = startElement2.curY;
                this.newStartClickPositions[path.id] = [
                    startElement1,
                    startElement2,
                ];
                this.initialPath[path.id] = path.getAttribute('d');
            } else {
                startElement2.curX = this.startClickPositions[element1][0] + dx;
                startElement2.curY = this.startClickPositions[element1][1] + dy;
            }
            path.setAttribute('startElement2', JSON.stringify(startElement2));
        }
        const instance = jsPlumbBrowserUI.newInstance({
            container: layer,
        });

        const pointPositionSource = JSON.parse(
            path.getAttribute('startElement1'),
        );
        const pointPositionTarget = JSON.parse(
            path.getAttribute('startElement2'),
        );

        instance.connect({
            source: source,
            target: target,
            connector: { type: jsPlumbBrowserUI.FlowchartConnector.type },
            clickPosition: {
                source: pointPositionSource,
                target: pointPositionTarget,
            },
        });

        const svgTags = layer.getElementsByTagName('svg');

        let newPath = '';
        let transform;

        for (let i = 0; i < svgTags.length; i = i + 2) {
            const circleTag = svgTags[i].getElementsByTagName('circle');
            let xPosition = svgTags[i].getAttribute('x');
            let yPosition = svgTags[i].getAttribute('y');
            if (!circleTag[0]) {
                const pathTag = svgTags[i].getElementsByTagName('path');
                const path = pathTag[0].getAttribute('d');
                const pathArray = path.split(' ');
                transform = pathTag[0].getAttribute('transform');
                for (let j = 0; j < pathArray.length - 1; j = j + 3) {
                    let x = Number(pathArray[j + 1]) + Number(xPosition);
                    let y = Number(pathArray[j + 2]) + Number(yPosition);
                    newPath = newPath + pathArray[j] + ' ' + x + ' ' + y + ' ';
                }
            }
        }

        layer.removeAttribute('data-jtk-container');
        layer.removeAttribute('katavorio-draggable');
        source.setAttribute('class', 'layer');

        source.removeAttribute('data-jtk-managed');
        target.removeAttribute('data-jtk-managed');

        const connection = instance.getConnections();
        instance.deleteConnection(connection[0]);

        return [newPath, transform];
    }

    getNewStartClickPositions() {
        return this.newStartClickPositions;
    }

    getInitialPath() {
        return this.initialPath;
    }
}
