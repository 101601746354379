import { Injectable } from '@angular/core';
import { CanvasService } from './../canvas.service';
import {
    CanvasInputModeService,
    CanvasInputMode,
} from './../canvas-input-mode.service';
import { parseSVGPath } from './pathPointCalculator';
import { calculateRectPoints } from './rectPointCalculator';
import { calculateEllipsePoints } from './ellipsePointCalculator';
import {
    addPointHoverStyle,
    checkChildNodes,
    snapToGrid,
    transformPoint,
} from './connector-utils';
import { ConnectorDrawerService } from './connector-drawer.service';
import {
    SvgEditExtension,
    SvgEditExtensionObject,
} from '@models/svg-edit-extension';
import { SelectedElementsService } from './../selected-elements.service';
import { EditConnectorLink } from '@models/connector';

@Injectable()
export class ConnectorExtensionService implements SvgEditExtension {
    name: string = 'connectorExtension';

    constructor(private plugin: ConnectorExtensionPlugin) {
        this.init = this.init.bind(this);
        this.bindMouseEvents();
    }

    init(): SvgEditExtensionObject {
        return this.plugin;
    }

    startConnector() {}

    private bindMouseEvents() {
        return this.plugin.bindMouseEvents();
    }
}

@Injectable()
export class ConnectorExtensionPlugin implements SvgEditExtensionObject {
    private previousTarget;
    private connectorHasStarted: boolean = false;
    private elementIsMoving: boolean = false;
    private elementsToMove = [];
    private positionMouseDown = [];
    private connectedElements = [];
    private startClickPositions = {};
    private newStartClickPositions = {};
    private initialPath = {};

    constructor(
        private canvasService: CanvasService,
        private inputMode: CanvasInputModeService,
        private connectorDrawerService: ConnectorDrawerService,
        private selectedElementsService: SelectedElementsService,
    ) {}

    callback(deps: any) {}

    private redrawConnection(path, selectedId, dx, dy) {
        const emitObject: EditConnectorLink = { path, selectedId, dx, dy };
        return this.connectorDrawerService.editConnection(emitObject);
    }

    bindMouseEvents() {
        document.addEventListener('mousedown', this.handleMouseDown.bind(this));
        document.addEventListener('mousemove', this.handleMouseMove.bind(this));
        document.addEventListener('mouseup', this.handleMouseUp.bind(this));
    }

    private handleMouseDown(event: MouseEvent) {
        if (this.inputMode.getMode() === CanvasInputMode.SELECT) {
            const target = event.target;
            this.handleMouseDownSelect(target);
            this.previousTarget = target;
        }
    }

    private handleMouseMove(event: MouseEvent) {
        const target = event.target;
        const svgRoot = this.canvasService.getSvgRoot();
        if (target === svgRoot) {
            document.getElementById('pathpointgrip_container')?.remove();
        }
        if (this.inputMode.getMode() === CanvasInputMode.CONNECTOR) {
            this.displayConnectorPoints(target);
            if (this.connectorHasStarted) {
                this.updateConnectorPosition(event);
            }
        }
        if (
            this.inputMode.getMode() === CanvasInputMode.SELECT &&
            this.elementIsMoving
        ) {
            this.updateConnectorMovingElements(event);
        }
    }

    private handleMouseUp(event: MouseEvent) {
        if (
            this.inputMode.getMode() === CanvasInputMode.SELECT &&
            this.elementIsMoving
        ) {
            this.updateElemPointPosition();
        } else if (
            this.inputMode.getMode() === CanvasInputMode.CONNECTOR &&
            this.connectorHasStarted
        ) {
            const svgRoot = this.canvasService.getSvgRoot();
            let element = svgRoot.getElementById('svg_123');
            if (element) {
                element.remove();
                this.startClickPositions = [];
                this.positionMouseDown = [];
                this.connectedElements = [];
            }
        }
        this.connectorHasStarted = false;
        this.elementIsMoving = false;
    }

    private startConnector(gripPoint, target) {
        const canvas = this.canvasService.getCanvas();
        const svgRoot = canvas.svgroot;
        const x = Number(gripPoint.getAttribute('cx'));
        const y = Number(gripPoint.getAttribute('cy'));

        if (target !== svgRoot) {
            this.connectorHasStarted = true;
            this.positionMouseDown.push({ x, y });
            this.connectedElements.push(target);
            canvas.started = true;
            const strokeW =
                Number(canvas.curShape.stroke_width) === 0
                    ? 1
                    : canvas.curShape.stroke_width;
            canvas.addSVGElementsFromJson({
                element: 'line',
                curStyles: true,
                attr: {
                    x1: x,
                    y1: y,
                    x2: x,
                    y2: y,
                    id: 'svg_123',
                    stroke: canvas.curShape.stroke,
                    'stroke-width': strokeW,
                    'stroke-dasharray': canvas.curShape.stroke_dasharray,
                    'stroke-linejoin': canvas.curShape.stroke_linejoin,
                    'stroke-linecap': canvas.curShape.stroke_linecap,
                    'stroke-opacity': canvas.curShape.stroke_opacity,
                    fill: 'none',
                    opacity: canvas.curShape.opacity / 2,
                    style: 'pointer-events:none',
                },
            });
        }
    }

    private handleMouseDownSelect(target) {
        this.elementsToMove = [];
        const prentNodeId = target.parentNode.id;
        if (prentNodeId.startsWith('svg_')) {
            const selectedElements =
                this.selectedElementsService.getSelectedElements();
            this.elementsToMove = checkChildNodes(selectedElements[0]);
        }

        let pointPositions = target.getAttribute('pointPosition');
        if (pointPositions) {
            let elements = Object.keys(JSON.parse(pointPositions));
            let hasStartClickPositions =
                Object.keys(this.startClickPositions).length > 0;
            if (hasStartClickPositions) {
                this.startClickPositions = {};
                this.initialPath = {};
                this.newStartClickPositions = {};
            }
            elements.forEach((element) => {
                this.startClickPositions[element] = [0, 0];
            });
            this.elementIsMoving = true;
        }
        if (this.elementsToMove.length > 0) {
            this.elementsToMove.forEach((element) => {
                let pointPositions = element.getAttribute('pointPosition');
                let elements = Object.keys(JSON.parse(pointPositions));
                elements.forEach((element) => {
                    this.startClickPositions[element] = [0, 0];
                });
            });
            this.elementIsMoving = true;
        }

        this.connectorDrawerService.updateStartClickPositions(
            this.startClickPositions,
        );
    }

    private updateConnectorPosition(mouseEvent) {
        const canvas = this.canvasService.getCanvas();
        const rootSctm = this.canvasService.getSCTM();
        const pt = transformPoint(
            mouseEvent.clientX,
            mouseEvent.clientY,
            rootSctm,
        );
        const shape = canvas.getElement('svg_123');
        let x = pt.x;
        let y = pt.y;
        if (canvas.curShape.gridSnapping) {
            x = snapToGrid(x, canvas);
            y = snapToGrid(y, canvas);
        }
        let x2 = x;
        let y2 = y;
        shape.setAttribute('x2', x2);
        shape.setAttribute('y2', y2);
    }

    private displayConnectorPoints(target) {
        if (target === this.previousTarget) {
            return;
        }
        this.previousTarget = target;

        let points = [];

        if (target?.tagName === 'rect') {
            points = calculateRectPoints(target);
        }
        if (target?.tagName === 'ellipse') {
            points = calculateEllipsePoints(target);
        }
        if (target?.tagName === 'path') {
            points = parseSVGPath(target);
        }

        const canvas = this.canvasService.getCanvas();
        this.displayGripPoints(points, target, canvas);
    }

    private updateConnectorMovingElements(mouseEvent) {
        const canvas = this.canvasService.getCanvas();
        const selected = canvas.selectedElements[0];

        if (mouseEvent.target !== this.previousTarget) {
            return;
        }
        if (selected) {
            this.redrawConnectionLine(mouseEvent, selected);
        }
        if (this.elementsToMove.length > 0) {
            this.elementsToMove.forEach((element) => {
                if (element != selected) {
                    this.redrawConnectionLine(mouseEvent, element);
                }
            });
        }
    }

    private redrawConnectionLine(evt, element) {
        const canvas = this.canvasService.getCanvas();
        const rootSctm = this.canvasService.getSCTM();
        let pointPosition = JSON.parse(element.getAttribute('pointPosition'));
        if (pointPosition) {
            let pt;
            pt = transformPoint(evt.clientX, evt.clientY, rootSctm);
            const x = pt.x;
            const y = pt.y;
            const dx = x - canvas.getStartX();
            const dy = y - canvas.getStartY();
            const svgRoot = canvas.svgroot;
            const layer = svgRoot.getElementsByClassName('layer');
            const selectedId = element.getAttribute('id');
            const paths = layer[0].getElementsByTagName('path');
            for (let i = 0; i < paths.length; i++) {
                let id = paths[i].getAttribute('id');
                if (id.includes(selectedId) && !id.startsWith('svg_')) {
                    let pathAndTransform = this.redrawConnection(
                        paths[i],
                        selectedId,
                        dx,
                        dy,
                    );

                    paths[i].setAttribute('d', pathAndTransform[0]);
                    paths[i].setAttribute('transform', pathAndTransform[1]);

                    this.newStartClickPositions =
                        this.connectorDrawerService.getNewStartClickPositions();
                    this.initialPath =
                        this.connectorDrawerService.getInitialPath();
                }
            }
        }
    }

    private createPathLine(gripPoint, target) {
        document.getElementById('pathpointgrip_container')?.remove();
        const canvas = this.canvasService.getCanvas();
        const svgRoot = canvas.svgroot;

        const x = Number(gripPoint.getAttribute('cx'));
        const y = Number(gripPoint.getAttribute('cy'));

        let element = canvas.getElement('svg_123');
        if (target != svgRoot && target != this.connectedElements[0]) {
            this.positionMouseDown.push({ x: x, y: y });
            this.connectedElements.push(target);
            const startPoint = {
                initialPosition: this.positionMouseDown[0],
                finalPosition: this.positionMouseDown[1],
                source: this.connectedElements[0],
                target: this.connectedElements[1],
            };

            this.connectorDrawerService.createConnection(startPoint);
        }
        element.remove();
        this.positionMouseDown = [];
        this.connectedElements = [];
        canvas.setMode('select');
    }

    private updateElemPointPosition() {
        const canvas = this.canvasService.getCanvas();
        const selected = canvas.selectedElements[0];
        if (selected) {
            const svgRoot = canvas.svgroot;
            const layer = svgRoot.getElementsByClassName('layer');
            let pointPosition = JSON.parse(
                selected.getAttribute('pointPosition'),
            );
            if (pointPosition) {
                const selectedId = selected.getAttribute('id');
                const paths = layer[0].getElementsByTagName('path');
                for (let i = 0; i < paths.length; i++) {
                    let id = paths[i].getAttribute('id');
                    if (id.includes(selectedId) && !id.startsWith('svg_')) {
                        const initialPathKeys = Object.keys(this.initialPath);
                        initialPathKeys.forEach((key) => {
                            if (paths[i].id === key) {
                                canvas.addCommandToHistory(
                                    new canvas.history.ChangeElementCommand(
                                        paths[i],
                                        {
                                            d: this.initialPath[key],
                                            transform: 'translate(3,3)',
                                            startElement1: JSON.stringify(
                                                this.newStartClickPositions[
                                                    key
                                                ][0],
                                            ),
                                            startElement2: JSON.stringify(
                                                this.newStartClickPositions[
                                                    key
                                                ][1],
                                            ),
                                        },
                                    ),
                                );
                            }
                        });
                    }
                }
            }
        }
        if (this.elementsToMove.length > 0) {
            this.elementsToMove.forEach((element) => {
                if (element != selected) {
                    const svgRoot = canvas.svgroot;
                    const layer = svgRoot.getElementsByClassName('layer');
                    let pointPosition = JSON.parse(
                        element.getAttribute('pointPosition'),
                    );
                    if (pointPosition) {
                        const selectedId = element.getAttribute('id');
                        const paths = layer[0].getElementsByTagName('path');
                        for (let i = 0; i < paths.length; i++) {
                            let id = paths[i].getAttribute('id');
                            if (
                                id.includes(selectedId) &&
                                !id.startsWith('svg_')
                            ) {
                                const initialPathKeys = Object.keys(
                                    this.initialPath,
                                );
                                initialPathKeys.forEach((key) => {
                                    if (paths[i].id === key) {
                                        canvas.addCommandToHistory(
                                            new canvas.history.ChangeElementCommand(
                                                paths[i],
                                                {
                                                    d: this.initialPath[key],
                                                    transform: 'translate(3,3)',
                                                    startElement1:
                                                        JSON.stringify(
                                                            this
                                                                .newStartClickPositions[
                                                                key
                                                            ][0],
                                                        ),
                                                    startElement2:
                                                        JSON.stringify(
                                                            this
                                                                .newStartClickPositions[
                                                                key
                                                            ][1],
                                                        ),
                                                },
                                            ),
                                        );
                                    }
                                });
                            }
                        }
                    }
                }
            });
        }
        this.elementsToMove = [];
    }

    private displayGripPoints(points, target, canvas) {
        for (let i = 0; i < points.length; i++) {
            const pointGrip = canvas.addPointGrip(i, points[i].x, points[i].y);
            pointGrip.setAttribute('cursor', 'pointer');
            const new_pointGrip = pointGrip.cloneNode(true);
            pointGrip.parentNode.replaceChild(new_pointGrip, pointGrip);
            addPointHoverStyle(new_pointGrip);
            new_pointGrip.setAttribute('targetElement', target);
            new_pointGrip.addEventListener('mousedown', () => {
                this.startConnector(new_pointGrip, target);
            });
            new_pointGrip.addEventListener('mouseup', () => {
                if (this.connectorHasStarted) {
                    this.createPathLine(new_pointGrip, target);
                }
            });
        }
    }
}
