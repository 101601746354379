import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HierarchyUtils } from '../hierarchy-draggable/hierarchy-draggable.component';

@Component({
    selector: 'app-svg-preview',
    templateUrl: './svg-preview.component.html',
    styleUrls: ['./svg-preview.component.css'],
})
export class SvgPreviewComponent implements OnInit, AfterViewInit {
    RAD2DEG: number = 180 / Math.PI;

    @Input() targetNode: any;
    @Input() canvas: any;
    @ViewChild('container') container!: ElementRef;

    displaySVG: string;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewChecked() {}

    ngAfterViewInit(): void {
        this.updateSVG();
    }

    checkUpdate = (elems: any) => {
        if (elems.includes(this.targetNode)) {
            this.updateSVG();
        }
    };

    updateSVG = () => {
        if (this.targetNode.tagName == 'text') {
            this.container.nativeElement.setAttribute('viewBox', '0 0 7.9374997 7.9375');
            this.container.nativeElement.innerHTML = this.textIcon;
            return;
        }

        if (this.targetNode.tagName == 'image') {
            this.container.nativeElement.setAttribute('viewBox', '0 0 7.9374997 7.9375');
            this.container.nativeElement.innerHTML = this.imageIcon;
            return;
        }

        if (this.targetNode.tagName == 'foreignObject') {
            this.container.nativeElement.setAttribute('viewBox', '0 0 7.9374997 7.9375');
            this.container.nativeElement.innerHTML = this.imageIcon;
            return;
        }

        if (this.targetNode.tagName != 'g') {
            const vis = HierarchyUtils.isVisible(this.targetNode);
            if (!vis) HierarchyUtils.toggleVis(this.targetNode);

            const bbox = this.targetNode.getBBox();
            const scale = Math.max(bbox.width, bbox.height);
            const b = bbox.x + ' ' + bbox.y + ' ' + bbox.width + ' ' + bbox.height;
            this.container.nativeElement.setAttribute('viewBox', b);

            const n = this.targetNode.cloneNode();
            //n.style.display = "block";
            const ctm = this.targetNode.getCTM();
            const rotation = Math.atan2(ctm.b, ctm.a) * this.RAD2DEG;

            this.container.nativeElement.innerHTML = '';
            this.container.nativeElement.appendChild(n);
            n.setAttribute(
                'transform',
                `rotate(${rotation} ${bbox.x + bbox.width / 2} ${bbox.y + bbox.height / 2})`,
            );

            if (!vis) HierarchyUtils.toggleVis(this.targetNode);
        }
    };

    textIcon = ` 
<g
inkscape:label="Layer 1"
inkscape:groupmode="layer"
id="layer1"
transform="translate(0,-289.0625)">
<g
  transform="translate(0.41192949,-1.5704726)"
  id="layer1-7"
  inkscape:label="Layer 1">
 <path
    style="fill:none;stroke:#ffffff;stroke-width:0.52916664;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    d="m 1.5891583,292.84431 h 3.7178831 v 0.0863"
    id="path861"
    inkscape:connector-curvature="0" />
 <path
    style="fill:none;stroke:#ffffff;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    d="m 3.573349,292.94505 v 3.76947"
    id="path863"
    inkscape:connector-curvature="0" />
</g>
</g>`;

    imageIcon = `<g
transform="translate(0,-289.0625)"
id="layer1">
<text
  id="text3887"
  y="291.96552"
  x="2.3104365"
  style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:6.3499996px;line-height:1.25;font-family:'Times New Roman';-inkscape-font-specification:'Times New Roman, ';letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332;"
  xml:space="preserve"><tspan
    style="stroke-width:0.26458332;font-size:6.3499996px;"
    y="296.34937"
    x="2.3104365"
    id="tspan3885" /></text>
<path
  transform="matrix(0.26458332,0,0,0.26458332,0,289.0625)"
  id="path4016"
  d=""
  style="opacity:1;fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:1.39999998;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
<path
  transform="matrix(0.26458332,0,0,0.26458332,0,289.0625)"
  id="path4018"
  d=""
  style="opacity:1;fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:1.39999998;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
<path
  transform="matrix(0.26458332,0,0,0.26458332,0,289.0625)"
  id="path4020"
  d=""
  style="opacity:1;fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:1.39999998;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
<path
  id="rect4062"
  d="m 1.2264798,290.79599 v 0.20702 4.05737 h 5.5912313 v -4.26439 z m 0.4278429,0.41402 h 4.7355455 v 3.43634 H 1.6543227 Z"
  style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:0.99;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.27;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:3.9;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate" />
<ellipse
  ry="0.19253638"
  rx="0.16401246"
  cy="291.85861"
  cx="5.1414342"
  id="path4067"
  style="opacity:1;fill:none;fill-opacity:1;stroke:#ffffff;stroke-width:0.37041664;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
<path
  transform="matrix(1,0,0,1.2171797,-0.01426195,-64.096383)"
  d="m 3.8649897,294.33303 -1.4492248,0.01 0.7163705,-1.25983 z"
  id="path4071"
  style="opacity:1;fill:#ffffff;fill-opacity:1;stroke:#ffffff;stroke-width:0.33574778;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
<path
  transform="matrix(1,0,0,0.61393595,1.6505364,113.5092)"
  d="m 3.8649897,294.33303 -1.4492248,0.01 0.7163705,-1.25983 z"
  id="path4071-5"
  style="opacity:1;fill:#ffffff;fill-opacity:1;stroke:#ffffff;stroke-width:0.47274721;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
</g>`;
}
