import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';
import { Channel } from 'common/models/channel';
import { ChannelGroup } from 'common/models/channel-group';
import { Subject, lastValueFrom } from 'rxjs';
import { Group } from '@components/channel-group-selector/channel-group-selector.component';
import { ChannelType } from 'common/models/channel-type';
import {
    generateInnerTable,
    getDeletedChannels,
    getFooter,
    getGroupHeaders,
    getHeader,
    getNameDiff,
    getNewChannels,
    getPdfLayout,
    pagesPerChannelGroup,
    prepareChannelDiffrences,
    prepareGroupDiffrences,
} from './print-channels-utils';

@Injectable({ providedIn: 'root' })
export class PrintChannelsService {
    private domain: string;

    private DEFAULT_MARGIN = [1, 0, 1, 0];

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    tableWidths = [
        30,
        '*',
        114,
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        'auto',
        '*',
    ];

    onPrintChannels: Subject<void> = new Subject<void>();

    getChannelGroups(projectId: string): Promise<ChannelGroup[]> {
        return lastValueFrom(
            this.http.get<ChannelGroup[]>(`${this.domain}/api/channel-groups`, {
                params: {
                    projectId,
                },
            }),
        );
    }

    async getCurrentChannels(selectedGroups, projectId) {
        let channelsToPrint = [];
        await Promise.all(
            selectedGroups.map(async (group) => {
                const groupId = group.id;
                const channelsByGroup = await lastValueFrom(
                    this.http.get<ChannelGroup[]>(`${this.domain}/api/channels/printGroup`, {
                        params: {
                            projectId,
                            groupId,
                        },
                    }),
                );

                channelsToPrint.push(channelsByGroup);
            }),
        );
        return channelsToPrint;
    }

    async getPdpFile(currentChannels: Channel[][], versionDifferences, selectedGroups: Group[]) {
        let channelsDifferences = {};
        let groupsDifferences = {};
        let updatedChannels: UpdatedChannels = { newChannels: [], deletedChannelRowsByGroup: {} };
        if (versionDifferences) {
            if (versionDifferences.channels) {
                channelsDifferences = prepareChannelDiffrences(versionDifferences.channels);
                updatedChannels.newChannels = getNewChannels(versionDifferences.channels);
                updatedChannels.deletedChannelRowsByGroup = getDeletedChannels(
                    versionDifferences.channels,
                );
            }
            if (versionDifferences.groups) {
                groupsDifferences = prepareGroupDiffrences(versionDifferences.groups);
            }
        }

        const tableGroupHeaders = getGroupHeaders(selectedGroups, groupsDifferences);

        return this.preparePdf(
            currentChannels,
            channelsDifferences,
            tableGroupHeaders,
            updatedChannels,
        );
    }

    generatePdfContent(
        channelsToPrint: Channel[][],
        differences,
        updatedChannels: UpdatedChannels,
    ) {
        const content = [];
        for (var i = 0; i < channelsToPrint.length; i++) {
            const deletedRowsInChannelGroup =
                updatedChannels.deletedChannelRowsByGroup[channelsToPrint[i][0].groupId];
            let orderedArray = this.generateTableBody(
                channelsToPrint[i],
                differences,
                updatedChannels,
            );
            let tableHeader = this.getTableHeader();
            let contentObject: { table: any; layout: any; pageBreak?: string } = {
                table: '',
                layout: getPdfLayout(updatedChannels, deletedRowsInChannelGroup),
                pageBreak: 'after',
            };

            if (i === (channelsToPrint.length - 1)) {
                contentObject = {
                    table: '',
                    layout: getPdfLayout(updatedChannels, deletedRowsInChannelGroup),
                };
            }
            let table = {
                headerRows: 1,
                widths: this.tableWidths,
                heights: function (row) {
                    return 20;
                },
                margin: [0, 0],
                dontBreakRows: true,
                body: [tableHeader, ...orderedArray],
            };
            contentObject.table = table;

            content.push(contentObject);
        }
        return content;
    }

    generateTableBody(channels: Channel[], differences, updatedChannels: UpdatedChannels) {
        channels.sort((a, b) => a.rowIndex - b.rowIndex);
        let deletedRowsInChannelGroup = [];
        deletedRowsInChannelGroup = updatedChannels.deletedChannelRowsByGroup[channels[0].groupId];
        const maxPosition = Math.max(...channels.map((channel) => channel.rowIndex));
        const orderedArray = new Array(maxPosition + 1).fill(new Array(19).fill(''));
        channels.map(async (channel: Channel) => {
            const channelRow = [];
            let channelDifferences = differences[channel.id];
            channelRow.push(this.getColumn1(channel, updatedChannels.newChannels));
            channelRow.push(this.getColumn2(channel, channelDifferences));
            channelRow.push(this.getColumn3(channel, channelDifferences));
            channelRow.push(this.getColumn4(channel, channelDifferences));
            channelRow.push(this.getColumn5(channel, channelDifferences));
            channelRow.push(this.getColumn6(channel, channelDifferences));
            channelRow.push(this.getColumn7(channel, channelDifferences));
            channelRow.push(this.getColumn8(channel, channelDifferences));
            channelRow.push(this.getColumn9(channel, channelDifferences));
            channelRow.push(this.getColumn10(channel, channelDifferences));
            channelRow.push(this.getColumn11(channel, channelDifferences));
            channelRow.push(this.getColumn12(channel, channelDifferences));
            channelRow.push(this.getColumn13(channel, channelDifferences));
            channelRow.push(this.getColumn14(channel, channelDifferences));
            channelRow.push(this.getColumn15(channel, channelDifferences));
            channelRow.push(this.getColumn16(channel, channelDifferences));
            channelRow.push(this.getColumn17(channel, channelDifferences));
            channelRow.push(this.getColumn18(channel, channelDifferences));
            channelRow.push(this.getColumn19(channel, updatedChannels.newChannels));
            orderedArray[channel.rowIndex] = channelRow;
        });

        if (deletedRowsInChannelGroup && deletedRowsInChannelGroup.length > 0) {
            deletedRowsInChannelGroup.map((deletedRow) => {
                if (orderedArray[deletedRow][0] === '') {
                    const firstColumn = { text: '', borderColor: ['red', 'red', 'black', 'red'] };
                    const lastColumn = { text: '', borderColor: ['black', 'red', 'red', 'red'] };
                    const middleColumns = new Array(17).fill('');
                    const newRow = [firstColumn, ...middleColumns, lastColumn];
                    orderedArray[deletedRow] = newRow;
                }
            });
        }

        let remainder = orderedArray.length % 20;
        if (remainder !== 0) {
            let emptyRows = new Array(20 - remainder).fill(new Array(19).fill(''));
            orderedArray.push(...emptyRows);
        }

        return orderedArray;
    }

    preparePdf(
        currentChannels: Channel[][],
        differences,
        tableGroupHeaders,
        updatedChannels: UpdatedChannels,
    ) {
        const lastPages = pagesPerChannelGroup(currentChannels);
        let getPageHeader = getHeader;
        const pdfContent = this.generatePdfContent(currentChannels, differences, updatedChannels);
        const pageFooter = { columns: getFooter(), margin: [28.3465, 0], lineHeight: 0.8 };

        return {
            pageOrientation: 'landscape',
            pageSize: 'A4',
            header: function (currentPage) {
                const header = getPageHeader(currentPage, lastPages, tableGroupHeaders);
                return [header];
            },
            footer: pageFooter,
            content: pdfContent,

            pageMargins: [28.3465, 87.874, 28.3465, 28.3465],
            defaultStyle: {
                fontSize: 6.3,
                color: '#393939',
            },
            layout: {
                paddingLeft: function (i, node) {
                    return 0;
                },
                paddingRight: function (i, node) {
                    return 0;
                },
                paddingTop: function (i, node) {
                    return 0;
                },
                paddingBottom: function (i, node) {
                    return 0;
                },
            },
        };
    }

    getTableHeader() {
        return [
            { text: 'Ch No.', margin: this.DEFAULT_MARGIN },
            { text: 'Name', margin: this.DEFAULT_MARGIN },
            { stack: ['Status', 'Range', 'Unit'], margin: this.DEFAULT_MARGIN, noWrap: true },
            { stack: ['ALM H', 'ALM L', 'FEEDBACK'], margin: this.DEFAULT_MARGIN, noWrap: true },
            { text: 'EX', margin: this.DEFAULT_MARGIN },
            { text: 'DLY', margin: this.DEFAULT_MARGIN },
            { text: 'R1', margin: this.DEFAULT_MARGIN },
            { text: 'R2', margin: this.DEFAULT_MARGIN },
            { stack: ['ALM HH', 'ALM LL'], margin: this.DEFAULT_MARGIN, noWrap: true },
            { text: 'EX', margin: this.DEFAULT_MARGIN },
            { text: 'DLY', margin: this.DEFAULT_MARGIN },
            { text: 'R1', margin: this.DEFAULT_MARGIN },
            { text: 'R2', margin: this.DEFAULT_MARGIN },
            { text: 'IN', margin: this.DEFAULT_MARGIN },
            { text: 'S', margin: this.DEFAULT_MARGIN },
            { stack: ['FCU No.', 'FU ADDRESS'], margin: this.DEFAULT_MARGIN },
            { stack: ['AL', 'RL'], margin: this.DEFAULT_MARGIN },
            { stack: ['SEN', 'EX', 'DLY'], margin: this.DEFAULT_MARGIN },
            { text: 'Remarks', margin: this.DEFAULT_MARGIN },
        ];
    }

    getColumn1(channel: Channel, newChannels) {
        let borderColor = newChannels.includes(channel.opsData.tag)
            ? ['red', 'red', 'black', 'red']
            : [];
        return {
            text: channel.opsData.tag,
            margin: this.DEFAULT_MARGIN,
            borderColor: borderColor,
            noWrap: true,
        };
    }

    getColumn2(channel: Channel, channelDifferences) {
        return getNameDiff(channel.opsData.name, channelDifferences);
    }

    getColumn3(channel: Channel, channelDifferences) {
        if (channel.opsData.channelType.includes('Digital')) {
            return channel.fcuData.alarmSetting.statusString;
        } else {
            const statusString = this.getChannelStatusString(channel);
            const innerTable = generateInnerTable(channelDifferences, [
                {
                    text: statusString,
                    fields: [
                        'fcuData.alarmSettingVeryLow.enable',
                        'fcuData.alarmSettingLow.enable',
                        'fcuData.alarmSettingHigh.enable',
                        'fcuData.alarmSettingVeryHigh.enable',
                    ],
                },
                {
                    text: `${channel.fcuData.measurableRange.min}/${channel.fcuData.measurableRange.max}`,
                    fields: ['fcuData.measurableRange.min', 'fcuData.measurableRange.max'],
                },
                { text: channel.opsData.measurementUnit, fields: ['opsData.measurementUnit'] },
            ]);
            return innerTable;
        }
    }

    getChannelStatusString(channel: Channel) {
        let statusStrings = [];
        if (channel.fcuData.alarmSettingVeryLow.enable) {
            statusStrings.push('VERYLOW');
        }
        if (channel.fcuData.alarmSettingLow.enable) {
            statusStrings.push('LOW');
        }
        statusStrings.push('NOR');
        if (channel.fcuData.alarmSettingHigh.enable) {
            statusStrings.push('HIGH');
        }
        if (channel.fcuData.alarmSettingVeryHigh.enable) {
            statusStrings.push('VERYHIGH');
        }
        return statusStrings.join('/');
    }

    getColumn4(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingHigh.setPoint,
                fields: ['fcuData.alarmSettingHigh.setPoint'],
            },
            {
                text: channel.fcuData.alarmSettingLow.setPoint,
                fields: ['fcuData.alarmSettingLow.setPoint'],
            },
            {
                text: channel.fcuData.alarmSettingFeedbackError.setPoint,
                fields: ['fcuData.alarmSettingFeedbackError.setPoint'],
            },
        ]);
    }

    getColumn5(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingHigh.alarmGroupNo,
                fields: ['fcuData.alarmSettingHigh.alarmGroupNo'],
            },
            {
                text: channel.fcuData.alarmSettingLow.alarmGroupNo,
                fields: ['fcuData.alarmSettingLow.alarmGroupNo'],
            },
            {
                text: channel.fcuData.alarmSettingFeedbackError.alarmGroupNo,
                fields: ['fcuData.alarmSettingFeedbackError.alarmGroupNo'],
            },
        ]);
    }

    getColumn6(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingHigh.delayTimer,
                fields: ['fcuData.alarmSettingHigh.delayTimer'],
            },
            {
                text: channel.fcuData.alarmSettingLow.delayTimer,
                fields: ['fcuData.alarmSettingLow.delayTimer'],
            },
            {
                text: channel.fcuData.alarmSettingFeedbackError.delayTimer,
                fields: ['fcuData.alarmSettingFeedbackError.delayTimer'],
            },
        ]);
    }

    getColumn7(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingHigh.reposeGroup1No,
                fields: ['fcuData.alarmSettingHigh.reposeGroup1No'],
            },
            {
                text: channel.fcuData.alarmSettingLow.reposeGroup1No,
                fields: ['fcuData.alarmSettingLow.reposeGroup1No'],
            },
            {
                text: channel.fcuData.alarmSettingFeedbackError.reposeGroup1No,
                fields: ['fcuData.alarmSettingFeedbackError.reposeGroup1No'],
            },
        ]);
    }

    getColumn8(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingHigh.reposeGroup2No,
                fields: ['fcuData.alarmSettingHigh.reposeGroup2No'],
            },
            {
                text: channel.fcuData.alarmSettingLow.reposeGroup2No,
                fields: ['fcuData.alarmSettingLow.reposeGroup2No'],
            },
            {
                text: channel.fcuData.alarmSettingFeedbackError.reposeGroup2No,
                fields: ['fcuData.alarmSettingFeedbackError.reposeGroup2No'],
            },
        ]);
    }

    getColumn9(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingVeryHigh.setPoint,
                fields: ['fcuData.alarmSettingVeryHigh.setPoint'],
            },
            {
                text: channel.fcuData.alarmSettingVeryLow.setPoint,
                fields: ['fcuData.alarmSettingVeryLow.setPoint'],
            },
        ]);
    }

    getColumn10(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingVeryHigh.alarmGroupNo,
                fields: ['fcuData.alarmSettingVeryHigh.alarmGroupNo'],
            },
            {
                text: channel.fcuData.alarmSettingVeryLow.alarmGroupNo,
                fields: ['fcuData.alarmSettingVeryLow.alarmGroupNo'],
            },
        ]);
    }

    getColumn11(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingVeryHigh.delayTimer,
                fields: ['fcuData.alarmSettingVeryHigh.delayTimer'],
            },
            {
                text: channel.fcuData.alarmSettingVeryLow.delayTimer,
                fields: ['fcuData.alarmSettingVeryLow.delayTimer'],
            },
        ]);
    }

    getColumn12(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingVeryHigh.reposeGroup1No,
                fields: ['fcuData.alarmSettingVeryHigh.reposeGroup1No'],
            },
            {
                text: channel.fcuData.alarmSettingVeryLow.reposeGroup1No,
                fields: ['fcuData.alarmSettingVeryLow.reposeGroup1No'],
            },
        ]);
    }

    getColumn13(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.alarmSettingVeryHigh.reposeGroup2No,
                fields: ['fcuData.alarmSettingVeryHigh.reposeGroup2No'],
            },
            {
                text: channel.fcuData.alarmSettingVeryLow.reposeGroup2No,
                fields: ['fcuData.alarmSettingVeryLow.reposeGroup2No'],
            },
        ]);
    }

    getColumn14(channel: Channel, channelDifferences) {
        let channelType;
        if (channel.fcuData.channelType === ChannelType.DIGITAL_NORMAL_OPEN) {
            channelType = 'NO';
        } else if (channel.fcuData.channelType === ChannelType.DIGITAL_NORMAL_CLOSE) {
            channelType = 'NC';
        } else if (channel.fcuData.channelType === ChannelType.DIGITAL_MOTOR) {
            channelType = 'MO';
        } else if (channel.fcuData.channelType === ChannelType.ANALOG_420MA_PT) {
            channelType = 'PT';
        } else if (
            channel.fcuData.channelType === ChannelType.ANALOG_420MA_AI ||
            channel.fcuData.channelType === ChannelType.ANALOG_1_5_V
        ) {
            channelType = 'AI';
        } else if (
            channel.fcuData.channelType === ChannelType.ANALOG_PT100_3WIRE ||
            channel.fcuData.channelType === ChannelType.ANALOG_PT100_2WIRE
        ) {
            channelType = 'TR';
        } else if (channel.fcuData.channelType === ChannelType.ANALOG_TEMP_K) {
            channelType = 'K';
        }

        let row: any = { text: channelType, margin: this.DEFAULT_MARGIN };

        if (!channelDifferences) {
            return row;
        }
        if (channelDifferences.modifiedFields?.includes('fcuData.channelType')) {
            row = {
                table: {
                    margin: this.DEFAULT_MARGIN,
                    body: [
                        [
                            {
                                text: channelType,
                                borderColor: ['red', 'red', 'red', 'red'],
                            },
                        ],
                    ],
                },
            };
        }

        return row;
    }

    getColumn15(channel: Channel, channelDifferences) {
        return '';
    }

    getColumn16(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: channel.fcuData.fcuNo,
                fields: ['fcuData.fcuNo'],
            },
            {
                text:
                    'FU ' +
                    channel.fcuData.inputData.fuInputAddress.fuNo +
                    ' - ' +
                    channel.fcuData.inputData.fuInputAddress.slotNo +
                    ' - ' +
                    channel.fcuData.inputData.fuInputAddress.pin,
                fields: [
                    'fcuData.inputData.fuInputAddress.fuNo',
                    'fcuData.inputData.fuInputAddress.slotNo',
                    'fcuData.inputData.fuInputAddress.pin',
                ],
            },
        ]);
    }

    getColumn17(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: ' ',
                fields: [],
            },
            {
                text: ' ',
                fields: [],
            },
        ]);
    }

    getColumn18(channel: Channel, channelDifferences) {
        return generateInnerTable(channelDifferences, [
            {
                text: ' ',
                fields: [],
            },
            {
                text: ' ',
                fields: [],
            },
            {
                text: ' ',
                fields: [],
            },
        ]);
    }

    getColumn19(channel: Channel, newChannels) {
        let borderColor = newChannels.includes(channel.opsData.tag)
            ? ['black', 'red', 'red', 'red']
            : [];
        return { text: ' ', margin: this.DEFAULT_MARGIN, borderColor: borderColor };
    }
}

export enum OperationType {
    UPDATE = 'update',
    ADD = 'add',
    DELETE = 'delete',
}

export class UpdatedChannels {
    newChannels: string[];
    deletedChannelRowsByGroup: { [string: string]: number[] };
}
