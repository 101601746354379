import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';

@Component({
    selector: 'app-new-vessel-create-dialog',
    templateUrl: './new-vessel-create-dialog.html',
    styleUrls: ['./new-vessel-create-dialog.scss'],
})
export class NewVesselCreateDialogComponent implements OnInit {
    reactiveForm: UntypedFormGroup;

    constructor(
        public fb: UntypedFormBuilder,
        public vesselInfoEnteringDialog: MatDialogRef<NewVesselCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.reactiveForm = this.fb.group({
            vesselnumber: ['', [Validators.required, this.dirtyAndNull]],
            name: [''],
            firstclass: [''],
        });
    }

    ngOnInit(): void {}

    //UNUSED NOW
    // ctrl(vesselnumber: string) {
    //     return this.reactiveForm.controls[vesselnumber];
    // }

    onRegister(): any {
        const info = {
            name: this.reactiveForm.controls['name'].value,
            imo: '',
            vesselNumber: this.reactiveForm.controls['vesselnumber'].value,
            firstClass: this.reactiveForm.controls['firstclass'].value,
            secondClass: '',
            owner_id: '09f08b32-1cbe-4b69-bf51-f3b8a742bc02',
            manager_id: '09f08b32-1cbe-4b69-bf51-f3b8a742bc02',
            builder_id: '',
        };

        this.vesselInfoEnteringDialog.close(info);
    }

    onCancel(): any {
        this.vesselInfoEnteringDialog.close();
    }

    private dirtyAndNull(ctrl: AbstractControl): ValidationErrors | null {
        if (ctrl.dirty && !ctrl.value) {
            return { dirtyAndNull: true };
        } else {
            return null;
        }
    }
}
