import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';

import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChannelExcelService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    importExcelFile(file, projectId: string): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('projectId', projectId);
        return lastValueFrom(this.http.post(`${this.domain}/api/excel/importFile`, formData));
    }

    getExcelChannelRows(projectId, groupId): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/excel/channels`, {
                headers: this.headers,
                params: { projectId: projectId, groupId: groupId },
            }),
        );
    }

    updateChannel(projectId, channelObj) {
        return lastValueFrom(
            this.http.put(`${this.domain}/api/excel/channels`, {
                headers: this.headers,
                params: { projectId: projectId, channelObj: channelObj },
            }),
        );
    }
}
