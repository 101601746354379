import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '@service/project.service';
import { VesselBuilderSelectService } from '@service/vessel-builder-select.service';
import { VesselSelectService } from '@service/vessel-select.service';
import { ValidationError, getValidationErrors, mapErrors } from 'icreate/common/validators';
import * as _ from 'lodash';

import { ImportStandardService } from '@service/import-standard.service';
import { ProjectConfirmDialogComponent } from '@components/project-confirm-dialog/project-confirm-dialog.component';
import { ProjectConfirmDialogType } from '@models/project-confirm.enum';
import { NavigationService } from '@service/navigation.service';
import { ProjectData, ProjectSetting } from 'icreate/common/models/project';
import { Subscription } from 'rxjs';
import { SocketService } from '@service/socket.service';
import { ProjectApiService } from '@service/project-api.service';
import { HttpStatusCode } from '@angular/common/http';
import { projectSettingFormDataValidator } from '@models/models-validators/project-settings-form';
import { OptimisticErrorComponent } from '@components/optimistic-error/optimistic-error.component';

//Error msg
enum RegexMSG {
    NUMBER_ALPHABETS = 'Enter numbers and alphabets only',
    NULL = 'is required',
}

enum EditType {
    VESSEL_NAME = 'vesselName',
    VESSEL_NO = 'vesselNumber',
}

@Component({
    selector: 'app-app-settings',
    templateUrl: './app-settings.component.html',
    styleUrls: ['./app-settings.component.scss'],
})
export class AppSettingsComponent implements OnInit {
    @ViewChild('fileInput') fileInput;
    languageType = [
        { text: 'dlg.app-language-en', value: 'en' },
        { text: 'dlg.app-language-jp', value: 'jp' },
    ];

    projectSettingFormData: ProjectSetting = {
        projectName: '',
        vesselBuildData: {
            id: '',
            name: '',
        },
        vesselData: {
            AMSVer: '',
            id: '',
            managerName: '',
            name: '',
            ownerName: '',
            vesselNumber: '',
        },
    };
    copiedProjectSettingFormData: ProjectSetting = {
        projectName: '',
        vesselBuildData: {
            id: '',
            name: '',
        },
        vesselData: {
            AMSVer: '',
            id: '',
            managerName: '',
            name: '',
            ownerName: '',
            vesselNumber: '',
        },
    };

    settingsIsUpdated = {
        vesselBuildData: false,
        vesselData: false,
    };

    projectData = new ProjectData();
    projectLanguage = this.translateService.currentLang;

    authentication = '';
    authenticationTooltip = '';
    isError: boolean = false;
    regexError = {
        [EditType.VESSEL_NO]: '',
        [EditType.VESSEL_NAME]: '',
    };

    validationErrors: { [propertyName: string]: ValidationError } = {};
    projectUpdatedSubscription: Subscription;

    constructor(
        private translateService: TranslateService,
        private projectService: ProjectService,
        private vesselSelectService: VesselSelectService,
        private vesselBuilderSelectService: VesselBuilderSelectService,
        private navigationService: NavigationService,
        private importStandard: ImportStandardService,
        public dialogRef: MatDialogRef<AppSettingsComponent>,
        public dialog: MatDialog,
        public socketService: SocketService,
        public projectApi: ProjectApiService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (!this.data.newProject) {
            this.projectSettingFormData = _.cloneDeep(this.data.projectData);
            this.copiedProjectSettingFormData = _.cloneDeep(this.projectSettingFormData);
        }
    }

    ngOnInit() {
        for (let i = 0; i < this.languageType.length; i++) {
            this.translateService.get(this.languageType[i].text).subscribe((txt: string) => {
                this.languageType[i].text = txt;
            });
        }

        if (!this.data.newProject) {
            this.projectUpdatedSubscription = this.socketService
                .onProjectSettingsUpdated()
                .subscribe(() => {
                    const projectId = this.projectService.getProjectId();
                    this.projectApi.getProject(projectId).subscribe({
                        next: (prj) => {
                            this.checkForBuilderConflicts(
                                prj.VesselBuilderCompanyName,
                                prj.VesselBuilderCompanyID,
                            );
                            this.checkForVesselConflicts(prj.VesselName, prj.VesselID);
                        },
                    });
                });
        }
        this.checkFormErrors();
    }

    onProjectClick() {
        this.projectService.openDialog(false).then((res) => {
            this.navigationService.toEditor(res.id);
        });
    }

    /**
     * Open shipyard and vessel select dialog
     */
    async onSelectVessel() {
        const res = await this.vesselBuilderSelectService.openBuilderSelectDialog();
        if (res) {
            this.projectSettingFormData.vesselBuildData = { ...res };
            this.showVesselList(res.id, res.name);
            this.checkFormErrors();
        }
    }

    async showVesselList(builderId: string, builderName: string) {
        const res = await this.vesselSelectService.openVesselSelectDialog(builderId, builderName);
        if (res) {
            this.projectSettingFormData.vesselData = {
                ...res,
                vesselNumber: '',
            };
            this.checkFormErrors();
        } else {
            if (this.data.newProject) {
                this.projectSettingFormData.vesselBuildData = {
                    id: '',
                    name: '',
                };
            } else {
                this.dialogRef.close();
            }
        }
    }

    onNoClick() {
        this.projectUpdatedSubscription?.unsubscribe();
        this.dialogRef.close();
    }

    onCreateClick() {
        this.dialog
            .open(ProjectConfirmDialogComponent, {
                data: {
                    contents: {
                        ...this.projectSettingFormData,
                    },
                    type: ProjectConfirmDialogType.CreateNewProject,
                },
            })
            .afterClosed()
            .subscribe((confirm) => {
                if (confirm) {
                    this.dialogRef.close({ ...this.projectSettingFormData });
                } else {
                    this.dialogRef.close();
                }
            });
    }

    onEditClick() {
        if (this.settingsNotUpdated()) {
            this.dialogRef.close();
        } else {
            this.dialog
                .open(ProjectConfirmDialogComponent, {
                    data: {
                        contents: {
                            ...this.projectSettingFormData,
                        },
                        type: ProjectConfirmDialogType.EditProject,
                    },
                })
                .afterClosed()
                .subscribe(async (confirm) => {
                    this.projectUpdatedSubscription.unsubscribe();
                    if (confirm) {
                        await this.saveAppSettings();
                    }
                    this.dialogRef.close();
                });
        }
    }

    settingsNotUpdated() {
        return _.isEqual(this.projectSettingFormData, this.copiedProjectSettingFormData);
    }

    onLanguageChange(language) {
        this.translateService.use(language);
        this.checkFormErrors();
    }

    onImport() {
        this.fileInput.nativeElement.click();
    }

    onChangeSelectedFile(evt: any): void {
        const target: DataTransfer = <DataTransfer>evt.target;
        const file = target.files[0];
        this.importStandard.importJSMEAStandard(file);
    }

    onChangeVesselName(evt: any) {
        this.projectSettingFormData.vesselData.name = evt;
        this.regexCheck(evt, EditType.VESSEL_NAME);
    }

    async saveAppSettings() {
        const projectId = this.projectService.getProjectId();
        try {
            await this.vesselBuilderSelectService.saveBuilderData(
                this.projectSettingFormData.vesselBuildData,
                projectId,
            );
            await this.vesselSelectService.saveVesselData(
                this.projectSettingFormData.vesselData,
                projectId,
            );
            this.projectService.load(projectId);
            this.projectService.notifyProjectSettingUpdateSuccess();
        } catch (err) {
            if (err.status === HttpStatusCode.Conflict) {
                this.dialog.open(OptimisticErrorComponent);
            }
            this.projectService.load(projectId);
        }
    }

    checkForBuilderConflicts(builderName, builderId) {
        if (builderName === this.projectSettingFormData.vesselBuildData.name) {
            return;
        } else if (
            this.projectSettingFormData.vesselBuildData.name ===
            this.copiedProjectSettingFormData.vesselBuildData.name
        ) {
            this.projectSettingFormData.vesselBuildData.name = builderName;
            this.projectSettingFormData.vesselBuildData.id = builderId;
            this.copiedProjectSettingFormData.vesselBuildData.name = builderName;
            this.copiedProjectSettingFormData.vesselBuildData.id = builderId;
            return;
        }
        this.settingsIsUpdated.vesselBuildData = true;
        this.isError = true;
    }

    checkForVesselConflicts(vesselName, vesselId) {
        if (vesselName === this.projectSettingFormData.vesselData.name) {
            return;
        } else if (
            this.projectSettingFormData.vesselData.name ===
            this.copiedProjectSettingFormData.vesselData.name
        ) {
            this.projectSettingFormData.vesselData.name = vesselName;
            this.projectSettingFormData.vesselData.id = vesselId;
            this.copiedProjectSettingFormData.vesselData.name = vesselName;
            this.copiedProjectSettingFormData.vesselData.id = vesselId;
            return;
        }
        this.settingsIsUpdated.vesselData = true;
        this.isError = true;
    }

    regexCheck(value: string, name: string) {
        const regex = /^[a-zA-Z0-9]*$/;
        if (name == EditType.VESSEL_NO && !regex.test(value)) {
            this.regexError[EditType.VESSEL_NO] = RegexMSG.NUMBER_ALPHABETS;
            this.isError = true;
        } else if (name == EditType.VESSEL_NAME && value === '') {
            this.regexError[EditType.VESSEL_NAME] = name.concat(' ').concat(RegexMSG.NULL);
            this.isError = true;
        } else {
            this.regexError[name] = '';
            this.isError = false;
        }
    }

    hasErrorString = (propName: string) => {
        return propName in this.validationErrors;
    };

    getErrorString = (propName: string) => {
        if (!this.hasErrorString(propName)) return '';

        const err = this.validationErrors[propName];
        return this.translateService.instant(err.errors[0], err.params);
    };

    checkFormErrors() {
        const errors = getValidationErrors(
            projectSettingFormDataValidator,
            this.projectSettingFormData,
        );
        if (errors) {
            this.validationErrors = mapErrors(errors);
        }
    }

    checkDisabledButton() {
        return (
            this.projectSettingFormData.projectName &&
            this.projectSettingFormData.vesselBuildData.id &&
            this.projectSettingFormData.vesselData.id &&
            Object.keys(this.validationErrors).length === 0
        );
    }
}

export interface IProjectSetting {
    projectName: string;
    vesselBuildData: {
        id: string;
        name: string;
    };
    vesselData: {
        AMSVer: {};
        id: string;
        managerName: string;
        name: string;
        ownerName: string;
        vesselNumber: string;
    };
}
