import {
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    OnInit,
    Output,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Channel } from 'common/models/channel';
import { ReposeSetting } from 'common/models/repose-setting';
import { getValidationErrors, mapErrors } from 'common/validators';
import { ReposeSettingValidator } from 'common/validators/repose-settings/repose-settings-validator';

@Component({
    selector: 'app-repose-settings-table',
    templateUrl: './repose-settings-table.component.html',
    styleUrls: ['./repose-settings-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReposeSettingsTableComponent implements OnInit, DoCheck {
    @Input() reposeList: ReposeSetting[] = [];
    @Input() channels: Channel[];
    @Output() onSelectedChange: EventEmitter<ReposeSetting | void> = new EventEmitter();

    tableDataSource = new MatTableDataSource<ReposeSetting>();
    displayedColumns: string[] = ['number', 'title', 'trigger', 'mask', 'delayOn', 'delayOff'];
    iterableDiffer: IterableDiffer<any>;

    selectedSettings: ReposeSetting;

    constructor(private iterableDiffers: IterableDiffers) {
        this.iterableDiffer = iterableDiffers.find([]).create(null);
    }

    ngOnInit(): void {
        this.tableDataSource.data = this.reposeList;
    }

    ngDoCheck() {
        let reposeListChanges = this.iterableDiffer.diff(this.reposeList);
        if (reposeListChanges) {
            this.tableDataSource.data = this.reposeList;
        }
    }

    selectRow(reposeSettings) {
        this.selectedSettings = reposeSettings;
        this.onSelectedChange.emit(reposeSettings);
    }

    unselectRow() {
        this.selectedSettings = null;
        this.onSelectedChange.emit();
    }

    inputLimit(event, currentValue) {
        const value = event.target.value.trim();
        if (value === '') {
            event.target.value = '';
            return '';
        }
        const numValue = Number(value);
        if (isNaN(numValue) || numValue < 0 || numValue > 99999999) {
            event.target.value = currentValue;
            return currentValue;
        }
        return numValue;
    }

    getSettingErrors(reposeSetting, key) {
        const errors = getValidationErrors(ReposeSettingValidator(), reposeSetting);
        if (errors) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                return validationErrors[key].errors[0];
            } else if (key === 'title') {
                let repeatedTitleError;
                this.reposeList.map((setting) => {
                    if (setting !== reposeSetting) {
                        if (setting.title === reposeSetting.title) {
                            repeatedTitleError = 'graph-settings.graph.error.repeated-title';
                        }
                    }
                });
                if (repeatedTitleError) {
                    return repeatedTitleError;
                }
            }
        }
    }
}
