import { ObjectSchema, object, string, boolean } from 'yup';
import { Graph, GraphType } from '../../models/graphs';
import { locale } from './../yup-locale';
import * as yup from 'yup';
yup.setLocale(locale);

export function GraphValidator(): ObjectSchema<Graph> {
    return object({
        id: string().uuid().nonNullable().strict().required(),
        type: string()
            .oneOf([GraphType.Bar, GraphType.Gauge, GraphType.Exhaust])
            .nonNullable()
            .required(),
        title: string().nonNullable().strict().required(),
        projectId: string().uuid().nonNullable().strict().required(),
        locked: boolean().nonNullable().strict().required(),
    }).required().strict().noUnknown().nonNullable() as ObjectSchema<Graph>;
}