<div class="settings-container">
    <div class="settings-title">{{ "ops-settings.title" | translate }}</div>
    <div class="users-title">{{ "ops-settings.users.title" | translate }}</div>
    <div
        *ngIf="!isLoadingData; else loadingSpinner"
        class="user-list-container"
    >
        <div *ngFor="let user of userList; index as userIndex">
            <app-ops-user
                [index]="userIndex"
                [user]="user"
                [deleteButtonIsDisabled]="userList.length <=2"
                (onDeleteUser)="deleteUser($event)"
                class="user-container"
            >
            </app-ops-user>
        </div>

        <div class="buttons-container">
            <button [disabled]="!canAddUser()" class="options-button" (click)="addUser()">
                {{ "ops-settings.users.button.add" | translate }}
            </button>
            <button class="options-button" (click)="exit()">
                {{ "ops-settings.users.button.exit" | translate }}
            </button>
            <button class="options-button" (click)="saveUsers()" [disabled]="saveButtonIsDisabled()">
                {{ "ops-settings.users.button.save" | translate }}
            </button>
        </div>
    </div>

    <ng-template #loadingSpinner>
        <app-loading-spinner> </app-loading-spinner>
    </ng-template>
</div>
