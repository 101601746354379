<div class="wrapper">
    <h1 mat-dialog-title>{{title}}</h1>
    <br />
    <ng-container
        *ngIf="projectData.VesselBuilderCompanyID && projectData.VesselID; else select"
    >
        <h3 mat-dialog-title>Vessel Builder Name :</h3>
        <h3 mat-dialog-title>{{!projectData.VesselBuilderCompanyName ? '' : projectData?.VesselBuilderCompanyName}}</h3>
        <h3 mat-dialog-title>Vessel Name :</h3>
        <h3 mat-dialog-title>{{!projectData.VesselName ? '' : projectData.VesselName}}</h3>

        <div class="act-button">
            <button mat-raised-button color="primary" (click)="onAct()">
                Act
            </button>
        </div>
    </ng-container>
    <ng-template #select>
        <h2 mat-dialog-title>Vessel Builder</h2>
        <div class="selector">
            <input
                class="selectedInfo"
                type="text"
                value="{{selectedBuilder.name}}"
                disabled
            />
            <button
                mat-raised-button
                color="primary"
                (click)="onBuilderSelect()"
            >
                Select Builder
            </button>
        </div>
        <div
            *ngIf="hasErrorString('builderId')"
            class="error-container text light"
        >
            <mat-icon svgIcon="error" class="error-icon"></mat-icon>
            <span data-testid="errorSetPoint">
                {{ getErrorString('builderId') }}</span
            >
        </div>
        <h2 mat-dialog-title>Vessel</h2>
        <div class="selector">
            <input
                class="selectedInfo"
                type="text"
                value="{{selectedVessel.name}}"
                disabled
            />
            <button
                mat-raised-button
                color="primary"
                (click)="onVesselSelect()"
                [disabled]="vesselSelectDisable"
            >
                Select Vessel
            </button>
        </div>
        <div
            *ngIf="hasErrorString('vesselId')"
            class="error-container text light"
        >
            <mat-icon svgIcon="error" class="error-icon"></mat-icon>
            <span data-testid="errorSetPoint">
                {{ getErrorString('vesselId') }}</span
            >
        </div>

        <div class="action">
            <div class="act-button">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="onAct()"
                    [disabled]="actDisable"
                >
                    Act
                </button>
            </div>
            <div class="cancel-button">
                <button mat-raised-button color="primary" (click)="onCancel()">
                    Cancel
                </button>
            </div>
        </div>
    </ng-template>
</div>
