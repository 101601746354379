<div class="page-wrapper">
    <div *ngIf="!isLoading; else loadingSpinner" class="dialog-container">
        <div *ngIf="projectUpToDate" class="approval-container">
            {{ "project-blob.approve.up-to-date" | translate}}
        </div>
        <div  *ngIf="!projectUpToDate" class="approval-container">
            <div class="version-container">
                <div>{{ "project-blob.approve.version.title" | translate }}</div>
                {{ blobVersion }}
            </div>
            <div class="approve-message-container">
                <div>{{ "project-blob.approve.reason.title" | translate }}</div>
                {{ publishReason }}
            </div>

            <div class="buttons-container">
                <button class="approve" (click)="approve()">
                    {{ "project-blob.approve.button.approve" | translate }}
                </button>
                <button class="decline" (click)="decline()">
                    {{ "project-blob.approve.button.decline" | translate }}
                </button>
            </div>
        </div>
    </div>
    <ng-template #loadingSpinner>
        <app-loading-spinner></app-loading-spinner>
    </ng-template>
</div>
