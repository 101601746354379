import { ObjectSchema, mixed, number, object, string } from 'yup';

import * as yup from 'yup';
import { locale } from '../yup-locale';
import { EditorTemplate } from '../../models/editor-templates';
yup.setLocale(locale);

export const editorTemplateValidator: ObjectSchema<EditorTemplate> = object({
    id: string().uuid().nonNullable().strict(),
    projectId: mixed().required().nonNullable(),
    serializedComponents: string().required().nonNullable().strict(),
})
    .noUnknown()
    .strict();
