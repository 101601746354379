import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AnimationTimingService implements OnDestroy {
    private alarmFlashInterval = 500;
    private onAlarmFlashTick$ = new Subject<void>();
    private alarmFlashTimer: NodeJS.Timeout = null;

    constructor() {
        this.emitAlarmFlashTick = this.emitAlarmFlashTick.bind(this);
        this.alarmFlashTimer = setInterval(this.emitAlarmFlashTick, this.alarmFlashInterval);
    }

    ngOnDestroy(): void {
        this.onAlarmFlashTick$.complete();
        clearInterval(this.alarmFlashTimer);
    }

    onAlarmFlashTick() {
        return this.onAlarmFlashTick$;
    }

    getAlarmFlashInterval(): number {
        return this.alarmFlashInterval;
    }

    private emitAlarmFlashTick() {
        this.onAlarmFlashTick$.next();
    }
}
