import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
    transform(value): any {
        const result = [];
        const keys = Object.keys(value);
        const values = Object.values(value);
        for (let i = 0; i < keys.length; i++) {
            result.push({ key: keys[i], value: values[i] });
        }
        return result;
        //or if you want to order the result:
        //return result.sort((a, b) => a.value < b.value ? -1 : 1);
    }
}

@Pipe({ name: 'keepHtml', pure: false })
export class EscapeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(content) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }
}

@Pipe({ name: 'objectToRowPipe' })
export class ObjectToRowPipe implements PipeTransform {
    constructor() {}

    transform(blob, local, flattenKey = '') {
        if (!blob || local === null || !blob || local === null) {
            return [];
        }

        const allKeys = Object.keys(local);

        const rows: {
            key: string;
            flattenKey: string;
            local: any;
            blob: any;
        }[] = [];

        allKeys.forEach((key) => {
            const newFlattenKey = flattenKey ? flattenKey.concat('.', key) : key;

            if (typeof local[key] === 'object' && typeof blob[key] === 'object') {
                rows.push({
                    key: key,
                    flattenKey: newFlattenKey,
                    local: null,
                    blob: null,
                });
                rows.push(...this.transform(blob[key], local[key], newFlattenKey));
            } else {
                rows.push({
                    key: key,
                    flattenKey: newFlattenKey,
                    local: local[key],
                    blob: blob[key],
                });
            }
        });

        return rows;
    }
}
