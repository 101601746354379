<!--<div *ngIf="isLoading" style="position:absolute;top:0px;left:0px;right:0px;bottom:0px;background-color:rgba(0,0,0,0.2); z-index: 99999;">
    <mat-progress-bar mode="indeterminate" color="warn" style="position: absolute; top: 0px; left: 0px; right: 0px; z-index: 99999;">
    </mat-progress-bar>
</div>-->
<app-header
    class="header"
    #header
    [canvas]="this.canvas"
    [currentView]="this.currentView"
    (startEvent)="onStartCurrent(this.currentView)"
    (redoEvent)="onRedo()"
    (undoEvent)="onUndo()"
    (deleteEvent)="deleteSelected()"
    (cloneEvent)="safeCloneSelectedElements()"
    (zoomEvent)="onZoomSlider($event)"
    (compareViewsEvent)="compareViewsEvent()"
    (printMimicsEvent)="printMimicsEvent()"
    (finishEdit)="onFinishEdit(this.currentView, false)"
    [hidden]="false"
    [currentProject]="currentProject"
    [initalViewSettings]="currentViewInitalSettings"
></app-header>
<app-confirmation-window
    #azureError
    [mainLabel]="'editor.prompt.azure-authorization-error.title' | translate"
    [subLabel]="'editor.prompt.azure-authorization-error.subtitle' | translate"
    [confirmLabel]="
        'editor.prompt.azure-authorization-error.confirm' | translate
    "
    [cancelLabel]="'editor.prompt.azure-authorization-error.cancel' | translate"
></app-confirmation-window>

<app-confirmation-window
    #iAPIError
    [mainLabel]="'editor.prompt.iapi-authorization-error.title' | translate"
    [subLabel]="'editor.prompt.iapi-authorization-error.subtitle' | translate"
    [confirmLabel]="
        'editor.prompt.iapi-authorization-error.confirm' | translate
    "
    [cancelLabel]="'editor.prompt.iapi-authorization-error.cancel' | translate"
></app-confirmation-window>

<app-confirmation-window
    #deleteView
    [mainLabel]="'editor.prompt.remove-view.title' | translate"
    [subLabel]="'editor.prompt.remove-view.subtitle' | translate"
    [confirmLabel]="'editor.prompt.remove-view.confirm' | translate"
    [cancelLabel]=""
    [showCancel]="false"
></app-confirmation-window>

<div id="svg_editor_container">
    <input
        #inputTextHolder
        (keyup)="setCurrentText($event)"
        (input)="setCurrentText($event)"
        id="text"
        type="text"
        size="35"
        autocomplete="off"
        class="svg_editor_input"
    />
    <div *ngIf="loadingPDFViews" class="spinner">
        <mat-spinner></mat-spinner>
    </div>
    <mat-drawer-container class="svg-workarea-container">
        <!-- LEFT BAR -->
        <mat-drawer
            mode="side"
            opened="true"
            class="svg-sidenav leave-header-area"
            disableClose
        >
            <mat-accordion multi="true">
                <mat-expansion-panel
                    class="svg-workarea-leftbar-p"
                    (opened)="panelsState.panelView = true; savePanelState()"
                    (closed)="panelsState.panelView = false; savePanelState()"
                    hideToggle="true"
                    [expanded]="panelsState.panelView"
                >
                    <mat-expansion-panel-header class="svg-workarea-leftbar-h">
                        <mat-panel-title>
                            <mat-icon
                                aria-label="Select"
                                *ngIf="panelsState.panelView"
                                >expand_less</mat-icon
                            >
                            <mat-icon
                                aria-label="Select"
                                *ngIf="!panelsState.panelView"
                                >expand_more</mat-icon
                            >
                            <span>{{ "editor.views" | translate }}</span>
                        </mat-panel-title>
                        <mat-icon
                            aria-label="Add"
                            matTooltip="Add View"
                            *ngIf="panelsState.panelView"
                            class="leftbar-edit-btn"
                            (click)="onAddDoc(); $event.stopPropagation()"
                            >add
                        </mat-icon>
                        <mat-icon
                            matTooltip="Add Category"
                            *ngIf="panelsState.panelView"
                            class="leftbar-edit-btn"
                            (click)="onAddViewGroup(); $event.stopPropagation()"
                            >subdirectory_arrow_right
                        </mat-icon>
                        <mat-icon
                            matTooltip="Import PDF"
                            *ngIf="panelsState.panelView"
                            class="leftbar-edit-btn"
                            (click)="onImportPDF(); $event.stopPropagation()"
                            >picture_as_pdf
                        </mat-icon>
                        <input
                            #pdfFile
                            type="file"
                            id="viewPDFUpload"
                            style="display: none"
                            (click)="$event.stopPropagation()"
                            (change)="onSetPDF($event)"
                            accept="application/pdf"
                        />
                        <mat-icon
                            aria-label="Import"
                            matTooltip="{{ 'editor.view-import' | translate }}"
                            *ngIf="panelsState.panelView"
                            class="leftbar-edit-btn"
                            (click)="onImportView(); $event.stopPropagation()"
                            >system_update_alt</mat-icon
                        >
                        <input
                            type="file"
                            #viewFileImportInput
                            style="display: none"
                            id="viewFileUpload"
                            (click)="$event.stopPropagation()"
                            (change)="onViewFileChangeListener($event)"
                            accept=".fuxav"
                        />
                    </mat-expansion-panel-header>
                    <div class="leftbar-panel view-panel">
                        <app-views
                            [viewGroups]="viewGroups"
                            [hmi]="hmi"
                            [views]="hmi.views"
                            [currentProjectId]="currentProjectId"
                            [currentView]="currentView"
                            [editingView]="editingViews"
                            (onSelectedView)="onSelectView($event)"
                            (onSetPDF)="onSetPDF($event)"
                            (onFinishEdit)="onFinishEdit($event)"
                            (onSaveView)="onSaveView($event)"
                        >
                        </app-views>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel
                    class="svg-workarea-leftbar-p"
                    (opened)="
                        panelsState.panelHierarchy = true; savePanelState()
                    "
                    (closed)="
                        panelsState.panelHierarchy = false; savePanelState()
                    "
                    hideToggle="true"
                    [expanded]="panelsState.panelHierarchy"
                >
                    <mat-expansion-panel-header class="svg-workarea-leftbar-h">
                        <mat-panel-title>
                            <mat-icon
                                aria-label="Select"
                                *ngIf="panelsState.panelHierarchy"
                                >expand_less</mat-icon
                            >
                            <mat-icon
                                aria-label="Select"
                                *ngIf="!panelsState.panelHierarchy"
                                >expand_more</mat-icon
                            >
                            <span>Hierarchy View</span>
                            <!--Hierarchy View-->
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div
                        class="leftbar-panel view-panel"
                        style="min-height: 400px"
                    >
                        <!--<app-recursivebit style="overflow-x: scroll" isFirst="true" #hierarchyview (selectEvent)="handleHierarchySelect($event)" [canvas]="this.canvas" [TreeData]="getContentLayer()?.childNodes" [elemName]="showElementName"></app-recursivebit>-->
                        <app-hierarchy-draggable
                            style="overflow-x: scroll"
                            #hierarchyview
                            [canvas]="this.canvas"
                            [view]="currentView"
                            [rootNode]="getContentLayer()"
                            (selectEvent)="handleHierarchySelect($event)"
                            [elemName]="showElementName"
                            [gaugeSettings]="currentView?.items"
                            (renameEvent)="onNameEdit($event)"
                        ></app-hierarchy-draggable>
                    </div>
                </mat-expansion-panel>
                <div *ngIf="!isViewMode() && shouldShowExpansion()">
                    <mat-expansion-panel
                        class="svg-workarea-leftbar-p"
                        (opened)="
                            panelsState.panelGeneral = true; savePanelState()
                        "
                        (closed)="
                            panelsState.panelGeneral = false; savePanelState()
                        "
                        hideToggle="true"
                        [expanded]="panelsState.panelGeneral"
                    >
                        <mat-expansion-panel-header
                            class="svg-workarea-leftbar-h"
                        >
                            <mat-panel-title>
                                <mat-icon
                                    aria-label="Select"
                                    *ngIf="panelsState.panelGeneral"
                                    >expand_less</mat-icon
                                >
                                <mat-icon
                                    aria-label="Select"
                                    *ngIf="!panelsState.panelGeneral"
                                    >expand_more</mat-icon
                                >
                                <span>{{ "editor.general" | translate }}</span>
                                <!-- GENERAL -->
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div id="__tools_left" class="leftbar-panel">
                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive('select')
                                }"
                                (click)="setMode('select')"
                                matTooltip="Select [M]"
                            >
                                <span class="icon-select"></span>
                            </div>

                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active':
                                        isModeActive('moveCanvas')
                                }"
                                (click)="setMode('moveCanvas')"
                                matTooltip="Hand Tool [H]"
                            >
                                <span class="icon-hand"></span>
                            </div>

                            <div
                                class="svg-tool-button"
                                [ngStyle]="{
                                    'background-color':
                                        showChannels == 'hidden'
                                            ? ''
                                            : 'rgba(48, 89, 175, 1)'
                                }"
                                (click)="onShowChannelNo()"
                                matTooltip="Channel No. [C]"
                            >
                                <span class="icon-channel-no"></span>
                            </div>

                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive('line')
                                }"
                                (click)="setMode('line')"
                            >
                                <span class="icon-line"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive('rect')
                                }"
                                (click)="setMode('rect')"
                            >
                                <span class="icon-rect"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive('ellipse')
                                }"
                                (click)="setMode('ellipse')"
                            >
                                <span class="icon-circle"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive('path')
                                }"
                                (click)="setMode('path')"
                            >
                                <span class="icon-path"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive('text')
                                }"
                                (click)="setMode('text')"
                                matTooltip="Text [T]"
                            >
                                <span class="icon-text"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active':
                                        isModeActive('image') ||
                                        isModeActive('svg-image')
                                }"
                                (click)="
                                    imagefile.value = ''; imagefile.click()
                                "
                            >
                                <span class="icon-image"></span>
                                <input
                                    #imagefile
                                    type="file"
                                    style="display: none"
                                    (change)="onSetImage($event)"
                                    accept="image/png|jpg|svg"
                                />
                            </div>
                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive('comment')
                                }"
                                (click)="setMode('comment')"
                                matTooltip="Comment Tool"
                            >
                                <span class="icon-comment"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive('connector')
                                }"
                                (click)="setMode('connector')"
                                matTooltip="Connector [L]"
                            >
                                <span class="icon-connector"></span>
                            </div>
                            <div id="tool_line" style="display: none">
                                <span class="icon-connector"></span>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <!-- CONTROLS  -->
                    <mat-expansion-panel
                        class="svg-workarea-leftbar-p"
                        (opened)="panelsState.panelD = true; savePanelState()"
                        (closed)="panelsState.panelD = false; savePanelState()"
                        hideToggle="true"
                        [expanded]="panelsState.panelD"
                    >
                        <mat-expansion-panel-header
                            class="svg-workarea-leftbar-h"
                        >
                            <mat-panel-title>
                                <mat-icon
                                    aria-label="Select"
                                    *ngIf="panelsState.panelD"
                                    >expand_less</mat-icon
                                >
                                <mat-icon
                                    aria-label="Select"
                                    *ngIf="!panelsState.panelD"
                                    >expand_more</mat-icon
                                >
                                <span>{{ "editor.controls" | translate }}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div id="__tools_left" class="leftbar-panel">
                            <div
                                class="svg-tool-button"
                                matTooltip="{{
                                    'editor.controls-input' | translate
                                }}"
                                [ngClass]="{
                                    'svg-tool-active':
                                        isModeActive('html_input')
                                }"
                                (click)="setMode('html_input')"
                            >
                                <span class="icon-tool icon-editvalue"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                matTooltip="{{
                                    'editor.controls-output' | translate
                                }}"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive('value')
                                }"
                                (click)="setMode('value')"
                            >
                                <span class="icon-tool icon-value"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                matTooltip="{{
                                    'editor.controls-button' | translate
                                }}"
                                [ngClass]="{
                                    'svg-tool-active':
                                        isModeActive('html_button')
                                }"
                                (click)="setMode('html_button')"
                            >
                                <span class="icon-tool icon-button"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                matTooltip="{{
                                    'editor.controls-progress' | translate
                                }}"
                                [ngClass]="{
                                    'svg-tool-active':
                                        isModeActive('gauge_progress')
                                }"
                                (click)="setMode('gauge_progress')"
                            >
                                <span class="icon-tool icon-progress-v"></span>
                            </div>
                            <div
                                class="svg-tool-button"
                                matTooltip="{{
                                    'editor.controls-switch' | translate
                                }}"
                                [ngClass]="{
                                    'svg-tool-active':
                                        isModeActive('html_switch')
                                }"
                                (click)="setMode('html_switch')"
                            >
                                <span class="icon-tool icon-switch"></span>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <!-- TEMPLATE -->
                    <mat-expansion-panel
                        class="svg-workarea-leftbar-p"
                        (opened)="panelsState.panelT = true; savePanelState()"
                        (closed)="panelsState.panelT = false; savePanelState()"
                        hideToggle="true"
                        [expanded]="panelsState.panelT"
                    >
                        <mat-expansion-panel-header
                            class="svg-workarea-leftbar-h"
                        >
                            <mat-panel-title>
                                <mat-icon
                                    aria-label="Select"
                                    *ngIf="panelsState.panelT"
                                    >expand_less</mat-icon
                                >
                                <mat-icon
                                    aria-label="Select"
                                    *ngIf="!panelsState.panelT"
                                    >expand_more</mat-icon
                                >
                                <span>{{
                                    "editor.templates" | translate
                                }}</span>
                            </mat-panel-title>
                            <span
                                class="btn-import-template"
                                title="{{
                                    'editor.templates-import' | translate
                                }}"
                                (click)="onImportTemplatesClicked($event)"
                            >
                            </span>
                        </mat-expansion-panel-header>
                        <div
                            id="__tools_left"
                            class="leftbar-panel template-container"
                        >
                            <app-template-button
                                *ngFor="let template of allTemplates"
                                class="btn-template"
                                [ngClass]="{
                                    active: isSelectedTemplate(template)
                                }"
                                [template]="template"
                                (click)="selectTemplate(template)"
                                (contextmenu)="
                                    onTemplateContextMenu($event, template)
                                "
                            >
                            </app-template-button>
                        </div>
                    </mat-expansion-panel>
                    <!-- SHAPES -->
                    <mat-expansion-panel
                        *ngFor="let shGrp of shapesGrps"
                        class="svg-workarea-leftbar-p"
                        (opened)="
                            panelsState[shGrp.name] = true; savePanelState()
                        "
                        (closed)="
                            panelsState[shGrp.name] = false; savePanelState()
                        "
                        hideToggle="true"
                        [expanded]="panelsState[shGrp.name]"
                    >
                        <mat-expansion-panel-header
                            class="svg-workarea-leftbar-h"
                        >
                            <mat-panel-title>
                                <mat-icon
                                    aria-label="Select"
                                    *ngIf="panelsState[shGrp.name]"
                                    >expand_less</mat-icon
                                >
                                <mat-icon
                                    aria-label="Select"
                                    *ngIf="!panelsState[shGrp.name]"
                                    >expand_more</mat-icon
                                >
                                <span>{{ shGrp.name | translate }}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div id="__tools_left" class="leftbar-panel">
                            <div
                                *ngFor="let shape of shGrp.shapes"
                                style="margin-left: 3px; margin-right: 3px"
                                class="svg-tool-button"
                                [ngClass]="{
                                    'svg-tool-active': isModeActive(shape.name)
                                }"
                                (click)="setMode(shape.name)"
                            >
                                <span
                                    class="icon-tool"
                                    [style.backgroundImage]="
                                        'url(' + shape.ico + ')'
                                    "
                                >
                                </span>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-accordion>
        </mat-drawer>
        <!-- END LEFT BAR -->
        <!-- WORK AREA -->
        <mat-drawer-content>
            <!-- SVG-EDITOR -->
            <div>
                <!-- <div> -->
                <!-- RIGHT BAR -->
                <app-properties-bar
                    [canvas]="canvas"
                    [selectedElement]="selectedElement"
                    [currentView]="currentView"
                    (gaugeEditEvent)="onGaugeEdit($event)"
                    (nameSetEvent)="onNameEdit($event)"
                >
                </app-properties-bar>
                <!-- END RIGHT BAR -->
                <!-- Work Area -->
                <div id="svg_editor" style="z-index: -1">
                    <!-- Readonly mode notification -->
                    <div
                        *ngIf="shouldShowViewOnly()"
                        class="notification-readonly-div"
                    >
                        <!--<div style="width:100%; height: 100%; position: relative;">-->
                        <span>View only.</span>
                        <a
                            style="
                                padding-left: 5px;
                                cursor: pointer;
                                font-weight: 600;
                            "
                            (click)="onEdit()"
                            >Edit?</a
                        >
                        <!--</div>-->
                    </div>
                    <!-- Rules bar -->
                    <!--<div id="rulers">
                        <div id="ruler_corner"></div>
                        <div id="ruler_x">
                            <div>
                                <canvas height="15"></canvas>
                            </div>
                        </div>
                        <div id="ruler_y">
                            <div>
                                <canvas width="15"></canvas>
                            </div>
                        </div>
                    </div>
                    <!-- END Rules bar -->
                    <!-- Draw Area -->
                    <div
                        #workArea
                        id="workarea"
                        tabindex="0"
                        class="workarea"
                        (mousedown)="workareaMouseDown($event)"
                        style="overscroll-behavior: contain"
                        [ngClass]="{ 'move-canvas': changeCursor }"
                        [ngClass]="{ 'comment-cursor': commentCursor }"
                    >
                        <style
                            id="styleoverrides"
                            type="text/css"
                            media="screen"
                            scoped="scoped"
                        ></style>

                        <div
                            #editorContainer
                            id="editorContainer"
                            (contextmenu)="OnContextMenu($event)"
                            style="position: relative; height: 100%"
                        >
                            <!--"svgcanvas" style="position:relative">-->
                        </div>
                        <app-comments
                            [creatingComment]="creatingComment"
                            [comments]="comments"
                            [positionBackground]="positionBackground"
                            [viewId]="currentView?.id"
                            [newCommentPosition]="newCommentPosition"
                            (onWritingComment)="writingComment($event)"
                        >
                        </app-comments>
                        <app-changes-detector
                            *ngIf="showChanges"
                            id="changes-container"
                            [ngStyle]="{
                                top: positionBackground.top,
                                left: positionBackground.left,
                                transform:
                                    'scale(' + positionBackground.scale + ')'
                            }"
                            [changesInProject]="changesInProject"
                            [currentViewId]="currentView?.id"
                        >
                        </app-changes-detector>
                    </div>
                    <!-- END Draw Area -->

                    <div id="cur_context_panel"></div>

                    <!-- tool bottom -->
                    <div class="bottom-bar tools_panel">
                        <!-- Zoom buttons -->
                        <div
                            class="zoom-menu"
                            matTooltip="{{
                                'editor.tools-zoomlevel-title' | translate
                            }}"
                        >
                            <div class="zoom-value">
                                <input
                                    id="zoom"
                                    size="3"
                                    [value]="getDisplayZoom()"
                                    type="text"
                                    #zoomValue
                                    hidden
                                    disabled
                                />
                                <div
                                    id="zoom_dropdown"
                                    class="dropdown selection"
                                    style="
                                        margin-top: 0px;
                                        margin-bottom: 0px;
                                        margin-right: 0px;
                                    "
                                >
                                    <button
                                        style="
                                            margin-top: 4px;
                                            width: 80px;
                                            font-size: 12px;
                                        "
                                    >
                                        {{ zoomValue.value }} %
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div id="tools_bottom_2" class="colors"></div>

                        <!--<div id="tools_bottom_3" class="colors-palette">
                            <div id="palette_holder">
                                <div id="palette" matTooltip="{{'editor.tools-palettecolor-title' | translate}}"></div>
                            </div>
                        </div>-->
                    </div>

                    <div id="option_lists" class="dropdown">
                        <ul id="position_opts" class="optcols3">
                            <li
                                class="push_button"
                                id="tool_posleft"
                                matTooltip="Align Left"
                            ></li>
                            <li
                                class="push_button"
                                id="tool_poscenter"
                                matTooltip="Align Center"
                            ></li>
                            <li
                                class="push_button"
                                id="tool_posright"
                                matTooltip="Align Right"
                            ></li>
                            <li
                                class="push_button"
                                id="tool_postop"
                                matTooltip="Align Top"
                            ></li>
                            <li
                                class="push_button"
                                id="tool_posmiddle"
                                matTooltip="Align Middle"
                            ></li>
                            <li
                                class="push_button"
                                id="tool_posbottom"
                                matTooltip="Align Bottom"
                            ></li>
                        </ul>
                    </div>
                </div>
            </div>
        </mat-drawer-content>
        <!-- work area END -->
    </mat-drawer-container>

    <!-- svg_editor -->

    <!-- NOT USED -->
    <div id="svg_source_editor">
        <div class="overlay"></div>
        <div id="svg_source_container">
            <div id="tool_source_back" class="toolbar_button">
                <button id="tool_source_save">Apply Changes</button>
                <button id="tool_source_cancel">Cancel</button>
            </div>
            <div id="save_output_btns">
                <p id="copy_save_note">
                    Copy the contents of this box into a text editor, then save
                    the file with a .svg extension.
                </p>
                <button id="copy_save_done">Done</button>
            </div>
            <form>
                <textarea
                    id="svg_source_textarea"
                    spellcheck="false"
                ></textarea>
            </form>
        </div>
    </div>

    <div id="svg_docprops">
        <div class="overlay"></div>
        <div id="svg_docprops_container">
            <div id="tool_docprops_back" class="toolbar_button">
                <button id="tool_docprops_save">OK</button>
                <button id="tool_docprops_cancel">Cancel</button>
            </div>

            <fieldset id="svg_docprops_docprops">
                <legend id="svginfo_image_props">Image Properties</legend>
                <label>
                    <span id="svginfo_title">Title:</span>
                    <input type="text" id="canvas_title" />
                </label>

                <fieldset id="change_resolution">
                    <legend id="svginfo_dim">Canvas Dimensions</legend>

                    <label>
                        <span id="svginfo_width">width:</span>
                        <input type="text" id="canvas_width" size="6" />
                    </label>

                    <label>
                        <span id="svginfo_height">height:</span>
                        <input type="text" id="canvas_height" size="6" />
                    </label>

                    <label>
                        <select id="resolution">
                            <option id="selectedPredefined" selected="selected">
                                Select predefined:
                            </option>
                            <option>640x480</option>
                            <option>800x600</option>
                            <option>1024x768</option>
                            <option>1280x960</option>
                            <option>1600x1200</option>
                            <option id="fitToContent" value="content">
                                Fit to Content
                            </option>
                        </select>
                    </label>
                </fieldset>

                <fieldset id="image_save_opts">
                    <legend id="includedImages">Included Images</legend>
                    <label>
                        <input
                            type="radio"
                            name="image_opt"
                            value="embed"
                            checked="checked"
                        />
                        <span id="image_opt_embed"
                            >Embed data (local files)</span
                        >
                    </label>
                    <label>
                        <input type="radio" name="image_opt" value="ref" />
                        <span id="image_opt_ref">Use file reference</span>
                    </label>
                </fieldset>
            </fieldset>
        </div>
    </div>

    <div id="svg_prefs">
        <div class="overlay"></div>
        <div id="svg_prefs_container">
            <div id="tool_prefs_back" class="toolbar_button">
                <button id="tool_prefs_save">OK</button>
                <button id="tool_prefs_cancel">Cancel</button>
            </div>

            <fieldset>
                <legend id="svginfo_editor_prefs">Editor Preferences</legend>

                <label>
                    <span id="svginfo_lang">Language:</span>
                    <select id="lang_select">
                        <option id="lang_ar" value="ar">العربية</option>
                        <option id="lang_cs" value="cs">Čeština</option>
                        <option id="lang_de" value="de">Deutsch</option>
                        <option id="lang_en" value="en" selected="selected">
                            English
                        </option>
                        <option id="lang_es" value="es">Español</option>
                        <option id="lang_fa" value="fa">فارسی</option>
                        <option id="lang_fr" value="fr">Français</option>
                        <option id="lang_fy" value="fy">Frysk</option>
                        <option id="lang_hi" value="hi">
                            &#2361;&#2367;&#2344;&#2381;&#2342;&#2368;,
                            &#2361;&#2367;&#2306;&#2342;&#2368;
                        </option>
                        <option id="lang_it" value="it">Italiano</option>
                        <option id="lang_ja" value="ja">日本語</option>
                        <option id="lang_nl" value="nl">Nederlands</option>
                        <option id="lang_pl" value="pl">Polski</option>
                        <option id="lang_pt-BR" value="pt-BR">
                            Português (BR)
                        </option>
                        <option id="lang_ro" value="ro">Română</option>
                        <option id="lang_ru" value="ru">Русский</option>
                        <option id="lang_sk" value="sk">Slovenčina</option>
                        <option id="lang_sl" value="sl">Slovenščina</option>
                        <option id="lang_zh-TW" value="zh-TW">繁體中文</option>
                    </select>
                </label>

                <label>
                    <span id="svginfo_icons">Icon size:</span>
                    <select id="iconsize">
                        <option id="icon_small" value="s">Small</option>
                        <option id="icon_medium" value="m" selected="selected">
                            Medium
                        </option>
                        <option id="icon_large" value="l">Large</option>
                        <option id="icon_xlarge" value="xl">Extra Large</option>
                    </select>
                </label>

                <fieldset id="change_background">
                    <legend id="svginfo_change_background">
                        Editor Background
                    </legend>
                    <div id="bg_blocks"></div>
                    <label>
                        <span id="svginfo_bg_url">URL:</span>
                        <input type="text" id="canvas_bg_url" />
                    </label>
                    <p id="svginfo_bg_note">
                        Note: Background will not be saved with image.
                    </p>
                </fieldset>

                <fieldset id="change_grid">
                    <legend id="svginfo_grid_settings">Grid</legend>
                    <label>
                        <span id="svginfo_snap_onoff">Snapping on/off</span>
                        <input
                            type="checkbox"
                            value="snapping_on"
                            id="grid_snapping_on"
                        />
                    </label>
                    <label>
                        <span id="svginfo_snap_step">Snapping Step-Size:</span>
                        <input
                            type="text"
                            id="grid_snapping_step"
                            size="3"
                            value="10"
                        />
                    </label>
                    <label>
                        <span id="svginfo_grid_color">Grid color:</span>
                        <input
                            type="text"
                            id="grid_color"
                            size="3"
                            value="#000"
                        />
                    </label>
                </fieldset>

                <fieldset id="units_rulers">
                    <legend id="svginfo_units_rulers">
                        Units &amp; Rulers
                    </legend>
                    <label>
                        <span id="svginfo_rulers_onoff">Show rulers</span>
                        <input
                            type="checkbox"
                            value="show_rulers"
                            id="show_rulers"
                            checked="checked"
                        />
                    </label>
                    <label>
                        <span id="svginfo_unit">Base Unit:</span>
                        <select id="base_unit">
                            <option value="px">Pixels</option>
                            <option value="cm">Centimeters</option>
                            <option value="mm">Millimeters</option>
                            <option value="in">Inches</option>
                            <option value="pt">Points</option>
                            <option value="pc">Picas</option>
                            <option value="em">Ems</option>
                            <option value="ex">Exs</option>
                        </select>
                    </label>
                </fieldset>
            </fieldset>
        </div>
    </div>
    <!-- NOT USED END -->

    <div id="dialog_box">
        <div class="overlay"></div>
        <div id="dialog_container">
            <div id="dialog_content"></div>
            <div id="dialog_buttons"></div>
        </div>
    </div>

    <!-- canvas context menu -->
    <app-context-menu #cmenuComp>
        <ul
            #cmenu
            id="se-cmenu_canvas"
            class="contextMenu svg-workarea-contextmenu"
        >
            <li [class.disabled]="!IsSomethingSelected()">
                <a id="#copy" (click)="HandleCMenuClick('copy')">{{
                    "editor.cmenu-copy" | translate
                }}</a>
            </li>
            <li [class.disabled]="!IsPasteAvailable()">
                <a id="#paste" (click)="HandleCMenuClick('paste')">{{
                    "editor.cmenu-paste" | translate
                }}</a>
            </li>
            <li class="separator" [class.disabled]="!IsSomethingSelected()">
                <a (click)="HandleCMenuClick('delete')" id="#delete">{{
                    "editor.cmenu-delete" | translate
                }}</a>
            </li>
            <li class="separator" [class.disabled]="!IsSomethingSelected()">
                <a id="#move_front" (click)="HandleCMenuClick('moveFront')"
                    >{{ "editor.cmenu-bring-front" | translate }}
                    <span class="shortcut">SHFT+CTRL+]</span>
                </a>
            </li>
            <li [class.disabled]="!IsSomethingSelected()">
                <a id="#move_up" (click)="HandleCMenuClick('moveUp')"
                    >{{ "editor.cmenu-bring-forward" | translate }}
                    <span class="shortcut">SHIFT+]</span>
                </a>
            </li>
            <li [class.disabled]="!IsSomethingSelected()">
                <a id="#move_down" (click)="HandleCMenuClick('moveDown')"
                    >{{ "editor.cmenu-send-backward" | translate }}
                    <span class="shortcut">SHIFT+[</span>
                </a>
            </li>
            <li [class.disabled]="!IsSomethingSelected()">
                <a id="#move_back" (click)="HandleCMenuClick('moveBack')"
                    >{{ "editor.cmenu-send-back" | translate }}
                    <span class="shortcut">SHFT+CTRL+[</span>
                </a>
            </li>
            <li *ngIf="AreMultipleSelected()" class="separator">
                <a id="#group" (click)="HandleCMenuClick('group')"
                    >{{ "editor.cmenu-group" | translate }}
                    <span class="shortcut">G</span>
                </a>
            </li>
            <li
                *ngIf="canvas?.getSelectedElements()[0]?.tagName == 'g'"
                class="separator"
            >
                <a id="#ungroup" (click)="HandleCMenuClick('ungroup')"
                    >{{ "editor.cmenu-ungroup" | translate }}
                    <span class="shortcut">U</span>
                </a>
            </li>
            <ng-template [ngIf]="isBooleanValid()">
                <li class="separator">
                    <a
                        id="#boolean_union"
                        (click)="HandleCMenuClick('union')"
                        >{{ "editor.cmenu-union" | translate }}</a
                    >
                </li>
                <li>
                    <a
                        id="#boolean_subtract"
                        (click)="HandleCMenuClick('subtract')"
                        >{{ "editor.cmenu-subtract" | translate }}</a
                    >
                </li>
                <li>
                    <a
                        id="#boolean_intersect"
                        (click)="HandleCMenuClick('intersect')"
                        >{{ "editor.cmenu-intersect" | translate }}</a
                    >
                </li>
                <li>
                    <a
                        id="#boolean_divide"
                        (click)="HandleCMenuClick('divide')"
                        >{{ "editor.cmenu-divide" | translate }}</a
                    >
                </li>
                <li>
                    <a
                        id="#boolean_exclude"
                        (click)="HandleCMenuClick('exclude')"
                        >{{ "editor.cmenu-exclude" | translate }}</a
                    >
                </li>
            </ng-template>
            <li class="separator">
                <a
                    id="#export"
                    (click)="HandleCMenuClick('templateCreate')"
                    [class.disabled]="!IsSomethingSelected()"
                    >{{ "editor.cmenu-template-create" | translate }}</a
                >
                <a id="#export" (click)="HandleCMenuClick('export')">{{
                    "editor.cmenu-export" | translate
                }}</a>
            </li>
        </ul>
    </app-context-menu>
    <!-- canvas context menu END -->

    <!-- layers context menu -->
    <ul id="cmenu_layers" class="contextMenu" style="display: none">
        <li>
            <a href="#dupe">{{ "editor.cmenu-layer-duplicate" | translate }}</a>
        </li>
        <li>
            <a href="#delete">{{ "editor.cmenu-layer-delete" | translate }}</a>
        </li>
        <li>
            <a href="#merge_down">{{
                "editor.cmenu-layer-marge-down" | translate
            }}</a>
        </li>
        <li>
            <a href="#merge_all">{{
                "editor.cmenu-layer-marge-all" | translate
            }}</a>
        </li>
    </ul>
    <!-- layers context menu END -->

    <!-- Template context menu -->
    <app-context-menu #templateContextMenu>
        <ul #cmenu class="contextMenu" style="border: solid white 1px">
            <li>
                <a (click)="handleTemplateContextMenuClick('delete')">{{
                    "editor.cmenu-delete" | translate
                }}</a>
            </li>
        </ul>
    </app-context-menu>
    <!-- Template context menu END -->
</div>

<div style="display: none">
    <div id="print-mimics" #images></div>
</div>

<app-print-channels> </app-print-channels>
<app-print-graphs></app-print-graphs>
