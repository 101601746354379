<div class="graph-settings-main">
    <div class="graph-settings">
        <div style="height: 100%" *ngIf="!editingGraph">
            <app-graph-list
                *ngIf="graphs"
                [graphs]="graphs"
                (onEditGraph)="handleEditGraph($event)"
                class="graph-list"
            ></app-graph-list>
        </div>
        <div style="height: 100%" *ngIf="editingGraph">
            <app-bar-graph
                *ngIf="editingGraph.type === graphTypes.Bar"
                [channels]="channels"
                [graph]="activeGraph"
                [graphListId]="editingGraph.id"
                (onExitGraph)="handleExitGraph(editingGraph.id)"
                class="graph-list"
            >
            </app-bar-graph>
            <app-exhaust-graph
                *ngIf="editingGraph.type === graphTypes.Exhaust"
                [channels]="channels"
                [graph]="activeGraph"
                [graphListId]="editingGraph.id"
                (onExitGraph)="handleExitGraph(editingGraph.id)"
                class="graph-list"
            >
            </app-exhaust-graph>
            <app-gauges
                *ngIf="editingGraph.type === graphTypes.Gauge"
                [channels]="channels"
                [graph]="activeGraph"
                [graphListId]="editingGraph.id"
                (onExitGraph)="handleExitGraph(editingGraph.id)"
                class="graph-list"
            >
            </app-gauges>
        </div>
    </div>
</div>
