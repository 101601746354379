import { Point } from '@models/connector';

export function calculateClickPosition(point: Point) {
    return {
        curX: point.x,
        curY: point.y,
        ox: 0,
        oy: 0,
        x: 0,
        y: 0,
    };
}

export function transformPoint(x, y, m) {
    return {
        x: m.a * x + m.c * y + m.e,
        y: m.b * x + m.d * y + m.f,
    };
}

export function snapToGrid(value, canvas) {
    const unit = canvas.getBaseUnit();
    let stepSize = canvas.getSnappingStep();
    if (unit !== 'px') {
        stepSize *= canvas.getTypeMap()[unit];
    }
    value = Math.round(value / stepSize) * stepSize;
    return value;
}

export function checkChildNodes(element) {
    let elementsToMove = [];

    function traverse(node) {
        let nodes = node?.children;
        if (nodes?.length > 0) {
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].tagName == 'g') {
                    traverse(nodes[i]);
                } else {
                    if (nodes[i].getAttribute('pointPosition')) {
                        elementsToMove.push(nodes[i]);
                    }
                }
            }
        } else {
            if (node.getAttribute('pointPosition')) {
                elementsToMove.push(node);
            }
        }
    }

    traverse(element);
    return elementsToMove;
}

export function addPointHoverStyle(element) {
    element.addEventListener('mouseenter', () => {
        element.setAttribute('r', 6);
        element.setAttribute('fill', 'red');
        element.setAttribute('stroke', 'red');
    });

    element.addEventListener('mouseleave', () => {
        element.setAttribute('r', 4);
        element.setAttribute('fill', '#0FF');
        element.setAttribute('stroke', '#00F');
    });
}

export function deletePaths(element, canvas) {
    const svgRoot = canvas.getSvgRoot();
    const layer = svgRoot.getElementsByClassName('layer');
    const paths = layer[0].getElementsByTagName('path');
    const selectedId = element.id;
    const pathsToDelete = [];
    for (let i = 0; i < paths.length; i++) {
        const pathId = paths[i].id;
        if (pathId.includes(selectedId) && pathId.startsWith('connect-')) {
            pathsToDelete.push(paths[i]);
        }
    }
    pathsToDelete.forEach((path) => {
        canvas.addCommandToHistory(
            new canvas.history.RemoveElementCommand(
                path,
                path.nextSibling,
                layer[0],
                'delete connector path',
            ),
        );
        path.remove();
    });
}
