import { HttpStatusCode } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MergeDialogComponent } from '@components/merge-dialog/merge-dialog.component';
import { BlobOperationParam, ChannelMerged } from 'common/models/blob-operations';
import { blobOperationParamValidator } from 'common/validators';
import { CloudStorageItem, ProjectData } from 'icreate/common/models/project';
import { lastValueFrom } from 'rxjs';
import { BlobStorageApiService } from './blob-storage-api.service';
import { ChannelCompareService } from './channel-compare.service';
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class BlobStorageService {
    @Output() hideLoading = new EventEmitter();

    constructor(
        private blobStorageApi: BlobStorageApiService,
        private notificationService: NotificationService,
        private channelCompareService: ChannelCompareService,
        public dialog: MatDialog,
    ) {}

    blobOperationParamValidate(blobParam: BlobOperationParam) {
        try {
            blobOperationParamValidator.validateSync(blobParam);
        } catch (error) {
            this.notificationService.validationError(error);
        }
    }

    async requestPublishBlob(requestObject) {
        try {
            await this.blobStorageApi.requestPublishProject(requestObject);
            this.notificationService.requestPublishProjectSuccessful();
        } catch (error) {
            throw error;
        } finally {
            this.hideLoading.emit();
        }
    }

    async pullPreApprovedBlob(requestObject) {
        try {
            const projectData = await this.blobStorageApi.pullPreApprovedProject(requestObject);
            this.notificationService.projectPullSuccessful();
            return projectData
        } catch (error) {
            throw error;
        }
    }

    async publishToBlob(
        blobParam: BlobOperationParam,
        publishProject: CloudStorageItem,
        currentProject: ProjectData,
    ): Promise<any> {
        this.blobOperationParamValidate(blobParam);
        try {
            if (!publishProject.version) {
                this.blobStorageApi.publishProjectFromBlob(blobParam);
            } else {
                await this.openChannelConfilictDialog(publishProject, currentProject);
                this.blobStorageApi.publishProjectFromBlob(blobParam);
                this.notificationService.projectPublishSuccessful();
            }
        } catch (error) {
            if (
                error.status === HttpStatusCode.NotFound ||
                error.status === HttpStatusCode.NotModified
            ) {
                //channel is empty or no channel conflict
                await this.blobStorageApi.publishProjectFromBlob(blobParam);
                this.notificationService.projectPublishSuccessful();
            } else {
                throw error;
            }
        } finally {
            this.hideLoading.emit();
        }
    }

    async pullProjectFromBlob(
        blobParam: BlobOperationParam,
        pullProject: CloudStorageItem,
        currentProject: ProjectData,
    ): Promise<any> {
        this.blobOperationParamValidate(blobParam);
        try {
            await this.openChannelConfilictDialog(pullProject, currentProject);
            blobParam.channelMerged = ChannelMerged.Merged;
            await this.blobStorageApi.pullProjectFromBlob(blobParam);
            this.notificationService.projectPullSuccessful();
        } catch (error) {
            if (
                error.status === HttpStatusCode.NotFound ||
                error.status === HttpStatusCode.NotModified
            ) {
                //channel is empty or no channel conflict
                await this.blobStorageApi.pullProjectFromBlob(blobParam);
                this.notificationService.projectPullSuccessful();
            } else {
                throw error;
            }
        } finally {
            this.hideLoading.emit();
        }
    }

    async overwriteProjectFromBlob(blobParam: BlobOperationParam) {
        this.blobOperationParamValidate(blobParam);
        try {
            await this.blobStorageApi.overwriteProject(blobParam);
            this.notificationService.projectOverwriteSuccessful();
        } catch (error) {
            this.notificationService.projectOverwriteError();
        } finally {
            this.hideLoading.emit();
        }
    }

    async openChannelConfilictDialog(cloudStorageItem: CloudStorageItem, projectData: ProjectData) {
        let channelDiff = await this.channelCompareService.getChannelDiff(
            projectData.id,
            projectData.VesselID,
            projectData.VesselBuilderCompanyID,
            cloudStorageItem.version,
        );
        let dialogRef = this.dialog.open(MergeDialogComponent, {
            width: '1200px',
            height: '700px',
            position: { top: '60px' },
            data: {
                version: cloudStorageItem.version,
                channels: channelDiff.channels,
                groups: channelDiff.groups,
                type: cloudStorageItem.type,
                projectData: projectData,
            },
        });
        return lastValueFrom(dialogRef.afterClosed());
    }
}
