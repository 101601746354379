<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    <p>{{ confirmMessage }}</p>
</div>
<div mat-dialog-actions>
    <button
        mat-raised-button
        [color]="okButtonType"
        (click)="dialogRef.close(true)"
    >
        {{ okButtonText }}
    </button>
    <button
        mat-raised-button
        [color]="noButtonType"
        (click)="dialogRef.close(false)"
    >
        {{ noButtonText }}
    </button>
</div>
