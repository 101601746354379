import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ChannelCategory } from 'common/models/channel-category';

@Component({
    selector: 'app-channel-select-input',
    templateUrl: './channel-select-input.component.html',
    styleUrls: ['./channel-select-input.component.css'],
})
export class ChannelSelectInputComponent {
    @Input() name: string;
    @Input() label: string;
    @Input() categories: ChannelCategory[];
    @Input() value = '';

    inputValue = '';
    isShowSelect = false;

    @Output() inputChange = new EventEmitter();

    ngOnInit() {
        let exist = this.categories.find((category) => category.id == this.value);
        if (exist) {
            this.inputValue = exist.category;
        }
    }

    onSelectValue(value: ChannelCategory) {
        this.inputValue = value.category;
        this.inputChange.emit({ name: this.name, value: value.id });
        this.isShowSelect = false;
    }

    onOpenSelect() {
        this.isShowSelect = this.isShowSelect ? false : true;
    }
}
