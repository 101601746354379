import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryValidator } from '@models/models-validators/category-validator';
import { TranslateService } from '@ngx-translate/core';
import { ChannelCategoryService } from '@service/channel-category.service';
import { ChannelSheetService } from '@service/channel-sheet.service';
import { ProjectService } from '@service/project.service';
import { ChannelCategory } from 'common/models/channel-category';
import { getValidationErrors, mapErrors } from 'common/validators';
import _ from 'lodash';

@Component({
    selector: 'app-dialog-channel-category',
    templateUrl: './dialog-channel-category.component.html',
    styleUrls: ['./dialog-channel-category.component.css'],
})
export class DialogChannelCategoryComponent {
    readonly CATEGORY_ADD = DialogType.add;
    readonly CATEGORY_RENAME = DialogType.rename;
    readonly CATEGORY_DELETE = DialogType.delete;

    isShow: boolean = false;
    channelCategories: ChannelCategory[] = [];
    @Output() renameCategory = new EventEmitter<ChannelCategory>();
    @Output() deleteCategory = new EventEmitter<ChannelCategory>();
    @Output() addCategory = new EventEmitter<ChannelCategory>();
    @Input() type: DialogType;
    channelCategory: ChannelCategory = new ChannelCategory();
    cloneCategory: ChannelCategory = new ChannelCategory();

    constructor(
        private channelCategoryService: ChannelCategoryService,
        private projectService: ProjectService,
        private channelSheetService: ChannelSheetService,
        private translateService: TranslateService,
    ) {}

    async open() {
        this.channelCategories = await this.channelCategoryService.getChannelCategories(
            this.projectService.getProjectId(),
        );
        this.isShow = true;
    }

    onClose() {
        this.isShow = false;
    }

    async onOk() {
        if (this.type == DialogType.add) {
            await this.addNewChannelCategory();
        } else if (this.type == DialogType.rename) {
            this.renameChannelGroup();
        } else {
            this.deleteChannelGroup();
        }

        this.onClose();
    }

    async addNewChannelCategory() {
        this.channelCategory.projectId = this.projectService.getProjectId();
        const maxRow = Math.max(...this.channelCategories.map((category) => category.rowId));
        this.channelCategory.rowId = maxRow + 1;
        this.addCategory.emit(this.channelCategory);
    }

    async renameChannelGroup() {
        this.renameCategory.emit(this.channelCategory);
    }

    async deleteChannelGroup() {
        this.deleteCategory.emit(this.channelCategory);
    }

    onInputChange(event: { name: string; value: any }) {
        this.channelCategory[event.name] = event.value;
    }

    setDialogData(type: DialogType, category: ChannelCategory) {
        this.type = type;
        if (category) {
            this.cloneCategory = { ...category };
            this.channelCategory = { ...category };
        }
    }

    getTranslate(text) {
        return this.translateService.instant(text, { category: this.channelCategory.category });
    }

    getErrors() {
        let validation = {
            category: this.channelCategory.category,
        };
        const errors = getValidationErrors(CategoryValidator(), validation);
        return errors;
    }

    getErrorMsg(key, name) {
        const errors = this.getErrors();
        if (errors.length > 0) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                let errorMsg = this.translateService.instant(validationErrors[key].message, {});
                return `Channel category “${name}“ ${errorMsg}`;
            } else {
                if (key == 'category') {
                    return this.getCategoryConflictMsg();
                } else {
                    return '';
                }
            }
        } else {
            if (key == 'category') {
                return this.getCategoryConflictMsg();
            } else {
                return '';
            }
        }
    }

    getCategoryConflictMsg() {
        return this.channelCategories.filter(
            (category) =>
                category.category == this.channelCategory.category &&
                category.id !== this.channelCategory.id,
        ).length > 0
            ? this.translateService.instant('channel.category.conflict', {
                  category: this.channelCategory.category,
              })
            : '';
    }

    isButtonDisable() {
        return (
            this.getErrors().length > 0 ||
            this.getCategoryConflictMsg() !== '' ||
            _.isEqual(this.channelCategory, this.cloneCategory)
        );
    }
}

export enum DialogType {
    add,
    rename,
    delete,
}
