import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private history: string[] = [];

    constructor(
        private router: Router,
        private location: Location,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    toDevice(projectId?: string) {
        this.router.navigate(['/device', projectId]);
    }

    toChannel(projectId?: string) {
        this.router.navigate(['/channels', projectId]);
    }

    goBack() {
        this.history.pop();
        if (this.history.length > 0) {
            this.location.back();
        } else {
            this.router.navigateByUrl('/editor');
        }
    }

    toLab(projectId?: string) {
        this.router.navigate(['/lab', projectId], {
            state: { projectId: projectId },
        });
    }

    toEditor(projectId?: string) {
        if (projectId) {
            this.router.navigate(['/editor', projectId]);
        } else {
            this.router.navigate([`/editor`]);
        }
    }

    toEditorWithParam(projectId: string) {
        this.router.navigate(['/editor', projectId]);
    }
}
