import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from '@helpers/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-dialog-doc-property',
    templateUrl: './dialog-doc-property.component.html',
    styleUrls: ['./dialog-doc-property.component.css'],
})
export class DialogDocPropertyComponent {
    defaultColor = Utils.defaultColor;

    /**Planned using iCreate for full HD (16:9) or larger screen sizes.
     * Smaller sizes temporarily commented out, but may be supported later.
     * In case of necessity, remove the comments and use it.
     **/
    propSizeType = [
        {
            text: 'dlg.docproperty-size-1920-1080',
            value: { width: 1920, height: 1080 },
        },
    ];
    constructor(
        private translateService: TranslateService,
        public dialogRef: MatDialogRef<DialogDocPropertyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        for (let i = 0; i < this.propSizeType.length; i++) {
            this.translateService.get(this.propSizeType[i].text).subscribe((txt: string) => {
                this.propSizeType[i].text = txt;
            });
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSizeChange(size) {
        if (size && size.width && size.height) {
            this.data.profile.width = size.width;
            this.data.profile.height = size.height;
        }
    }
}
