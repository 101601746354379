import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';

import { BlobOperationParam } from 'common/models/blob-operations';
import { CloudStorageItem } from 'icreate/common/models/project';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlobStorageApiService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    pullProject(blobOperationParams): Promise<CloudStorageItem> {
        return lastValueFrom(
            this.http.get<CloudStorageItem>(`${this.domain}/api/blobStorage/pullProject`, {
                headers: this.headers,
                params: blobOperationParams,
            }),
        );
    }

    publishProject(blobOperationParams: BlobOperationParam): Promise<any> {
        return lastValueFrom(
            this.http.post(`${this.domain}/api/blobStorage/publishProject`, blobOperationParams, {
                headers: this.headers,
            }),
        );
    }

    pullProjectFromBlob(blobOperationParams): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/blobStorage/pullProjectBlob`, {
                headers: this.headers,
                params: blobOperationParams,
            }),
        );
    }

    overwriteProject(blobOperationParams): Promise<any> {
        return lastValueFrom(
            this.http.post(`${this.domain}/api/blobStorage/overwriteProject`, blobOperationParams, {
                headers: this.headers,
            }),
        );
    }

    publishProjectFromBlob(blobOperationParams: BlobOperationParam): Promise<any> {
        return lastValueFrom(
            this.http.post(
                `${this.domain}/api/blobStorage/publishProjectBlob`,
                blobOperationParams,
                {
                    headers: this.headers,
                },
            ),
        );
    }

    requestPublishProject(requestObject): Promise<any> {
        return lastValueFrom(
            this.http.post(`${this.domain}/api/blobStorage/request-publish`, requestObject, {
                headers: this.headers,
            }),
        );
    }

    pullPreApprovedProject(requestObject): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/blobStorage/pull-preApproved-blob`, {
                headers: this.headers,
                params: requestObject,
            }),
        );
    }

    getProjectBlob(blobOperationParams): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/blobStorage/getBlob`, {
                headers: this.headers,
                params: blobOperationParams,
            }),
        );
    }

    getVersionList(builderId, vesselId): Promise<any> {
        const params = {
            builderId: builderId,
            vesselId: vesselId,
        };
        return lastValueFrom(
            this.http.get(`${this.domain}/api/blobStorage/versionList`, {
                headers: this.headers,
                params: params,
            }),
        );
    }
}
