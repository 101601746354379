<div class="repose-settings-main">
    <div
        class="repose-settings"
        *ngIf="channels && reposeList; else loadingSpinner"
    >
        <app-repose-settings-table
            [channels]="channels"
            [reposeList]="reposeList"
            (onSelectedChange)="handleSelectedChanged($event)"
            class="repose-list"
        >
        </app-repose-settings-table>

        <!-- Options -->
        <div class="buttons-container">
            <button
                [disabled]="!canAddSetting()"
                class="add"
                (click)="addReposeSetting()"
            >
                {{ "repose-settings.button.add" | translate }}
            </button>
            <button
                [disabled]="!selectedSetting"
                class="delete"
                (click)="deleteReposeSetting()"
            >
                {{ "repose-settings.button.delete" | translate }}
            </button>

            <button
                class="save"
                [disabled]="saveButtonIsDisabled()"
                (click)="saveList()"
            >
                {{ "repose-settings.button.save" | translate }}
            </button>
            <button
                class="exit"
                [disabled]="settingListhasErrors()"
                (click)="exit()"
            >
                {{ "repose-settings.button.exit" | translate }}
            </button>
        </div>

        <app-confirmation-window
            #confirmationWindow
            [mainLabel]="'repose-settings.close.confirmation.title' | translate"
            [confirmLabel]="
                'repose-settings.close.confirmation.confirm' | translate
            "
            [cancelLabel]="
                'repose-settings.close.confirmation.cancel' | translate
            "
        ></app-confirmation-window>
    </div>
</div>

<ng-template #loadingSpinner>
    <app-loading-spinner></app-loading-spinner>
</ng-template>
