import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from '@helpers/utils';
import { DialogInfoComponent } from '../dialog-info/dialog-info.component';

@Component({
    selector: 'app-dialog-grid',
    templateUrl: './dialog-grid.component.html',
    styleUrls: ['./dialog-grid.component.css'],
})
export class DialogGridComponent {
    defaultColor = Utils.defaultColor;

    constructor(
        public dialogRef: MatDialogRef<DialogInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        dialogRef.disableClose = true;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
