<div>
  <h1
    mat-dialog-title
    style="display: inline-block; cursor: move"
    mat-dialog-draggable
  >
    {{ "dlg.new-view-group-title" | translate }}
  </h1>
  <mat-icon (click)="onNoClick()" class="dialog-close-btn" cdkFocusInitial
    >clear</mat-icon
  >
  <div mat-dialog-content>
    <div class="my-form-field" style="display: block; margin-bottom: 10px">
      <span>{{ "dlg.view-group-name" | translate }}</span>
      <input
        [(ngModel)]="data.name"
        type="text"
        style="width: 300px"
        [readonly]="data.readonly"
      />
    </div>
  </div>
  <div mat-dialog-actions class="dialog-action">
    <button mat-raised-button (click)="onNoClick()">
      {{ "dlg.cancel" | translate }}
    </button>
    <button
      mat-raised-button
      [disabled]="!data.name"
      color="primary"
      [mat-dialog-close]="data"
      cdkFocusInitial
    >
      {{ "dlg.ok" | translate }}
    </button>
  </div>
</div>
