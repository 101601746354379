import { AlarmLevel } from './alarm-level';

export class AlarmSetting {
    enable: boolean;
    statusString: string;
    digitalOnString?: string;
    digitalOffString?: string;
    setPoint: number;
    alarmGroupNo: number;
    reposeGroup1No: number;
    reposeGroup2No: number;
    manualReposeFlag: boolean;
    delayTimer: number;
    normalOpenFlag: boolean;
    alarmLevel: AlarmLevel;
}

export const AlarmSettingFns = {
    create,
    clone,
};

function create() {
    return {
        enable: false,
        statusString: '',
        setPoint: 0,
        alarmGroupNo: 0,
        reposeGroup1No: 0,
        reposeGroup2No: 0,
        manualReposeFlag: false,
        delayTimer: 0,
        normalOpenFlag: false,
        alarmLevel: AlarmLevel.Alarm,
    };
}

function clone(alarm: AlarmSetting): AlarmSetting {
    return {
        enable: alarm.enable,
        statusString: alarm.statusString,
        setPoint: alarm.setPoint,
        alarmGroupNo: alarm.alarmGroupNo,
        reposeGroup1No: alarm.reposeGroup1No,
        reposeGroup2No: alarm.reposeGroup2No,
        manualReposeFlag: alarm.manualReposeFlag,
        delayTimer: alarm.delayTimer,
        normalOpenFlag: alarm.normalOpenFlag,
        alarmLevel: alarm.alarmLevel,
    };
}
