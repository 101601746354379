import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-channel-confirm-dialog',
    templateUrl: './channel-confirm-dialog.component.html',
    styleUrls: ['./channel-confirm-dialog.component.css'],
})
export class ChannelConfirmDialogComponent {
    message: string;
    confirmedContents: string;
    constructor(
        public dialogRef: MatDialogRef<ChannelConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.message = data.message;
        this.confirmedContents = data.confirmedContents;
    }

    onOk(): any {
        this.dialogRef.close(true);
    }
}
