<!---<div class="wrapper">
    <div class="colorindicator" #colorindicator [style.backgroundColor]="selectedColor" ></div>
    <div class="panel" [@slideInOut]="panelAnimState" #colorpanel> 
        <color-twitter (onChange)="colorChanged($event)" triangle="hide" ></color-twitter>
    <div>
</div>-->

<div
    class="colorindicator"
    #colorindicator
    [style.backgroundColor]="selectedColor"
>
    <div
        class="panel"
        id="colorpanel"
        [@slideInOut]="panelAnimState"
        #colorpanel
    >
        <color-twitter
            [width]="320"
            (onChange)="colorChanged($event)"
            triangle="hide"
            [colors]="colors"
        ></color-twitter>
        <div class="eyeDropper-wrapper">
            <mat-icon
                class="colour-picker-icon"
                svgIcon="colour-picker"
                (click)="colourPicker()"
            >
            </mat-icon>
            <div class="eyeDropper-color" [ngStyle]="{'backgroundColor': displayedColor}">
                <div class="eyeDropper-arrow">

                </div>
            </div>
        </div>
    </div>
</div>
