<div>
  <h1
    mat-dialog-title
    style="display: inline-block; cursor: move"
    mat-dialog-draggable
  >
    {{ "dlg.docproperty-title" | translate }}
  </h1>
  <mat-icon (click)="onNoClick()" class="dialog-close-btn" cdkFocusInitial
    >clear</mat-icon
  >
  <div mat-dialog-content style="width: 300px">
    <!-- <div *ngIf="data.type !== cardViewType"> -->
    <div style="display: block; width: 100%; margin-top: 10px">
      <div class="my-form-field" style="display: inline-block">
        <span>{{ "dlg.docproperty-width" | translate }}</span>
        <input
          numberOnly
          [(ngModel)]="data.profile.width"
          style="width: 120px"
          type="text"
          disabled
        />
      </div>
      <div class="my-form-field" style="float: right">
        <span>{{ "dlg.docproperty-height" | translate }}</span>
        <input
          numberOnly
          [(ngModel)]="data.profile.height"
          style="width: 120px"
          type="text"
          disabled
        />
      </div>
    </div>
    <div style="display: block; margin-top: 20px; width: 100%">
      <div class="my-form-field">
        <span>{{ "dlg.docproperty-size" | translate }}</span>
        <mat-select
          placeholder="{{ 'dlg.docproperty-select' | translate }}"
          (selectionChange)="onSizeChange($event.value)"
          style="width: 293px"
        >
          <mat-option *ngFor="let prop of propSizeType" [value]="prop.value">
            {{ prop.text }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <!-- </div> -->
    <!-- <div *ngIf="data.type === cardViewType">
            <div class="my-form-field slider-field" style="margin-top: 10px">
                <span>{{'dlg.docproperty-margin' | translate}}</span>
                <mat-slider style="display:inline-block;width: 100px;" [max]="20" [min]="0" [step]="1" discrete [(ngModel)]="data.profile.margin">
                </mat-slider>
            </div>
        </div> -->
    <div
      style="display: block; margin-top: 20px; margin-bottom: 20px; width: 100%"
    >
      <div class="my-form-field" style="width: 300px">
        <span style="font-size: 12px">{{
          "dlg.docproperty-background" | translate
        }}</span>
        <input
          style="
            width: 292px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            height: 15px !important;
          "
          readonly
          [(colorPicker)]="data.profile.bkcolor"
          class="input-color"
          title="Change stroke color"
          [style.background]="data.profile.bkcolor"
          [cpPresetColors]="defaultColor"
          [cpAlphaChannel]="'always'"
          [cpPosition]="'bottom'"
          [value]="data.profile.bkcolor"
          [cpCancelButton]="true"
          [cpCancelButtonClass]="'cpCancelButtonClass'"
          [cpCancelButtonText]="'Cancel'"
          [cpOKButton]="true"
          [cpOKButtonText]="'OK'"
          [cpOKButtonClass]="'cpOKButtonClass'"
        />
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-action">
    <button mat-raised-button (click)="onNoClick()">
      {{ "dlg.cancel" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="data"
      cdkFocusInitial
    >
      {{ "dlg.ok" | translate }}
    </button>
  </div>
</div>
