import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';
import { ChannelGroup } from 'common/models/channel-group';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChannelGroupsService {
    private domain: string;

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    public getChannelGroups(projectId: string): Promise<ChannelGroup[]> {
        return lastValueFrom(
            this.http.get<ChannelGroup[]>(`${this.domain}/api/channel-groups`, {
                params: {
                    projectId,
                },
            }),
        );
    }

    public addChannelGroup(channelGroup: ChannelGroup): Promise<ChannelGroup> {
        return lastValueFrom(
            this.http.post<ChannelGroup>(`${this.domain}/api/channel-groups`, {
                channelGroup: channelGroup,
            }),
        );
    }

    public updateChannelGroup(channelGroup: ChannelGroup): Promise<any> {
        return lastValueFrom(
            this.http.put<any>(`${this.domain}/api/channel-group`, {
                params: channelGroup,
            }),
        );
    }

    public deleteChannelGroup(channelGroupId: string): Promise<any> {
        return lastValueFrom(
            this.http.delete(`${this.domain}/api/channel-group/${channelGroupId}`),
        );
    }
}
