import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from '@service/notification.service';
import { Channel } from 'common/models/channel';
import { ExhaustChannel } from 'common/models/graphs';
import { getValidationErrors, mapErrors } from 'common/validators';
import { ExhaustChannelValidator } from 'common/validators/graphs/exhaust-graph/exhaust-graph-validator';

@Component({
    selector: 'app-exhaust-table',
    templateUrl: './exhaust-table.component.html',
    styleUrls: ['./exhaust-table.component.scss'],
})
export class ExhaustTableComponent implements OnInit {
    readonly MAX__EXHAUST_GRAPHS: number = 24;

    @Input() exhaustChannels: ExhaustChannel[];
    @Input() channels: Channel[];

    @Output() onTableUpdated: EventEmitter<ExhaustChannel[]> = new EventEmitter();

    exhaustTableDataSource = new MatTableDataSource<ExhaustChannel>();
    exhaustTableColumns: string[] = ['number', 'cylinder', 'deviation', 'title'];
    selectedExhaustChannel: ExhaustChannel;

    constructor(private notificationService: NotificationService) {}

    ngOnInit(): void {
        this.exhaustTableDataSource.data = this.exhaustChannels;
    }

    selectRow(row) {
        this.selectedExhaustChannel = row;
    }

    unselectRow() {
        this.selectedExhaustChannel = null;
    }

    canAddChannel() {
        return this.exhaustChannels.length < this.MAX__EXHAUST_GRAPHS;
    }

    addChannel() {
        const newChannel = new ExhaustChannel();
        this.exhaustChannels.push(newChannel);
        this.exhaustTableDataSource.data = this.exhaustChannels;
        this.onTableUpdated.emit(this.exhaustChannels);
        this.notificationService.successMessage(
            'graph-settings.exhaust-graph.notification.success.exhaust-channel-added',
        );
    }

    deleteChannel() {
        const index = this.exhaustChannels.findIndex(
            (channel) => channel === this.selectedExhaustChannel,
        );
        if (index !== -1) {
            this.exhaustChannels.splice(index, 1);
            this.selectedExhaustChannel = null;
            this.notificationService.successMessage(
                'graph-settings.exhaust-graph.notification.success.exhaust-channel-deleted',
            );
        }
        this.exhaustTableDataSource.data = this.exhaustChannels;
        this.onTableUpdated.emit(this.exhaustChannels);
        this.selectedExhaustChannel = null;
    }

    getChannelErrors(channelRow: ExhaustChannel, key) {
        const errors = getValidationErrors(ExhaustChannelValidator(), channelRow);
        if (errors) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                return validationErrors[key].errors[0];
            } else if (key === 'title') {
                let repeatedTitleError;
                this.exhaustChannels.map((channels) => {
                    if (channels !== channelRow) {
                        if (channels.title === channelRow.title) {
                            repeatedTitleError = 'graph-settings.graph.error.repeated-title';
                        }
                    }
                });
                if (repeatedTitleError) {
                    return repeatedTitleError;
                }
            }
        }
    }
}
