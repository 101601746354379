export class ReposeSetting {
    id?: string;
    title: string;
    trigger: number;
    mask: number;
    delayOn: number;
    delayOff: number;
    projectId: string;
}

export const MAX_REPOSE_SETTINGS: number = 264;
