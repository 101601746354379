import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TesterService } from '../tester/tester.service';
import { GaugesManager } from 'ngx-fuxa';

@Component({
    selector: 'app-tester',
    templateUrl: './tester.component.html',
    styleUrls: ['./tester.component.css'],
})
export class TesterComponent implements OnInit {
    show: boolean = false;
    items;
    output: string[] = [];
    subscription: Subscription;
    demoSwitch = true;

    @Input() fuxaView;

    constructor(
        private gaugesManager: GaugesManager,
        private testerService: TesterService,
    ) {}

    ngOnInit() {
        this.testerService.changeToggle.subscribe((isOpen) => {
            this.show = isOpen;
        });

        this.gaugesManager.onevent.subscribe((event) => {
            if (event.dbg) {
                this.addOutput(event.dbg);
            }
        });
    }

    setSignal(sig: any) {
        this.fuxaView.onChangeColor(sig.key, sig.value.property, sig.value.property.variableValue);
        this.addOutput(
            ' > ' +
                sig.key +
                ' - ' +
                sig.value.property.variableId +
                ' = ' +
                sig.value.property.variableValue,
        );
    }

    setSignals(items: any) {
        this.items = items;
    }

    addOutput(item: string) {
        this.output.unshift(item);
    }

    close() {
        this.testerService.toggle(false);
    }

    setFlag(event) {
        const recievedArray = JSON.parse(event);
        const key = recievedArray[2].key;

        for (let i = 0; i < Object.keys(this.items).length; i++) {
            if (Object.keys(this.items)[i] === key) {
                const propertiesItem = this.items[Object.keys(this.items)[i]].property.flags;
                if (recievedArray[0] === 'cancel') {
                    if (recievedArray[1].checked === true) {
                        propertiesItem.cancelAuto1[recievedArray[1].displayName] = 1;
                        propertiesItem.cancelAuto2[recievedArray[1].displayName] = 1;
                        propertiesItem.cancelAuto3[recievedArray[1].displayName] = 1;
                        propertiesItem.cancelManual[recievedArray[1].displayName] = 1;
                    } else {
                        propertiesItem.cancelAuto1[recievedArray[1].displayName] = 0;
                        propertiesItem.cancelAuto2[recievedArray[1].displayName] = 0;
                        propertiesItem.cancelAuto3[recievedArray[1].displayName] = 0;
                        propertiesItem.cancelManual[recievedArray[1].displayName] = 0;
                    }
                } else if (recievedArray[0] === 'alarm') {
                    if (recievedArray[1].checked === true) {
                        propertiesItem.alarm[recievedArray[1].displayName] = 1;
                    } else {
                        propertiesItem.alarm[recievedArray[1].displayName] = 0;
                    }
                } else if (recievedArray[0] === 'unAck') {
                    if (recievedArray[1].checked === true) {
                        propertiesItem.unAck[recievedArray[1].displayName] = 1;
                    } else {
                        propertiesItem.unAck[recievedArray[1].displayName] = 0;
                    }
                }
            }
        }
        this.addOutput(
            ' > ' +
                recievedArray[2] +
                ' changed flag ' +
                recievedArray[0] +
                ' - ' +
                recievedArray[1].displayName +
                ' to ' +
                recievedArray[1].checked,
        );
    }
}
