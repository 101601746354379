import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { FuSettingService } from '@service/fu-settings.service';
import { NotificationService } from '@service/notification.service';
import { ProjectService } from '@service/project.service';
import { FcuSetting, FuSetting, MAX_FU, SlotType } from 'icreate/common/models/FU-settings';
import * as _ from 'lodash';
import { lastValueFrom, take } from 'rxjs';

@Component({
    selector: 'app-fu-settings-table',
    templateUrl: './fu-settings-table.component.html',
    styleUrls: ['./fu-settings-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuSettingsTableComponent implements OnInit, OnChanges {
    @Input() fcuSetting: FcuSetting;
    @ViewChild('confirmationWindow') confirmationWindow: ConfirmationWindowComponent;

    tableDataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = [
        'info',
        'active',
        'name',
        'remark',
        'slot1',
        'slot2',
        'slot3',
        'slot4',
        'slot5',
        'slot6',
        'slot7',
        'slot8',
    ];
    slotTypes = Object.values(SlotType);
    initialDataSource;
    loadingData: boolean = false;
    pageIsLocked: boolean = false;

    constructor(
        private projectService: ProjectService,
        private notificationService: NotificationService,
        private router: Router,
        private fuSettingService: FuSettingService,
        private cdr: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        if (this.fcuSetting) {
            this.loadingData = true;
            const fuSettings = await this.fuSettingService.getFuSettings(this.fcuSetting.id);
            this.populateDataSource(fuSettings);
            this.initialDataSource = _.cloneDeep(this.tableDataSource.data);
            this.loadingData = false;
        } else {
            this.initialDataSource = [];
        }

        this.fuSettingService.onUnlockActivePage.subscribe(async (pageId) => {
            if (this.pageIsLocked && this.fcuSetting.id == pageId) {
                const fuSettings = await this.fuSettingService.getFuSettings(pageId);
                this.populateDataSource(fuSettings);
                this.initialDataSource = _.cloneDeep(this.tableDataSource.data);
                this.pageIsLocked = false;
            }
        });
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.fcuSetting && this.fcuSetting) {
            this.loadingData = true;
            if (!this.fcuSetting.locked) {
                const fuSettings = await this.fuSettingService.getFuSettings(this.fcuSetting.id);
                this.populateDataSource(fuSettings);
                this.initialDataSource = _.cloneDeep(this.tableDataSource.data);
                this.pageIsLocked = false;
            } else {
                this.pageIsLocked = true;
            }
            this.loadingData = false;
        }
    }

    populateDataSource(fuSettings: FuSetting[]) {
        const fuIndexes = new Set();
        if (fuSettings.length > 0) {
            fuSettings.map((fuSetting) => {
                fuIndexes.add(fuSetting.fuIndex);
            });
        }
        if (fuSettings.length < MAX_FU) {
            for (let i = 0; i < MAX_FU; i++) {
                if (!fuIndexes.has(i + 1)) {
                    const newFuSetting = new FuSetting();
                    newFuSetting.fuIndex = i + 1;
                    newFuSetting.fcuId = this.fcuSetting.id;
                    fuSettings.push(newFuSetting);
                }
            }
        }
        fuSettings.sort((fuSetting1, fuSetting2) => fuSetting1.fuIndex - fuSetting2.fuIndex);
        this.tableDataSource.data = [this.fcuSetting, ...fuSettings];
    }

    saveList() {
        try {
            const updatedSettings = this.getChangedSettings();
            updatedSettings.map(async (updatedSetting) => {
                if (updatedSetting.fcuId) {
                    await this.fuSettingService.updateFuSettings(updatedSetting);
                } else {
                    await this.fuSettingService.updateFcuSettings(updatedSetting);
                }
            });
            this.initialDataSource = _.cloneDeep(this.tableDataSource.data);
            this.notificationService.successMessage('fu-settings.notification.success.save');
        } catch (err) {
            this.notificationService.errorMessage('fu-settings.notification.error.save');
        }
    }

    async exit() {
        if (!this.fcuSetting) {
            const projectId = this.projectService.getProjectId();
            this.router.navigate(['editor', projectId]);
        } else if (this.settingsListHasChanged()) {
            this.confirmationWindow.setVisible(true);
            const closeWidndow = await lastValueFrom(
                this.confirmationWindow.onOptionSelected.pipe(take(1)),
            );
            if (closeWidndow) {
                await this.fuSettingService.unlockFcuPage(this.fcuSetting.id);
                const projectId = this.projectService.getProjectId();
                this.router.navigate(['editor', projectId]);
            }
        } else {
            await this.fuSettingService.unlockFcuPage(this.fcuSetting.id);
            const projectId = this.projectService.getProjectId();
            this.router.navigate(['editor', projectId]);
        }
    }

    settingsListHasChanged() {
        return !_.isEqual(this.initialDataSource, this.tableDataSource.data);
    }

    saveButtonIsDisabled() {
        const settingWithNames = this.tableDataSource.data.filter((setting) => {
            if (setting.name) {
                return setting.name;
            }
        });
        const namesArray = settingWithNames.map((setting) => setting.name);
        const uniqueNames = new Set(namesArray);
        if (namesArray.length !== uniqueNames.size) {
            return false;
        }
        return this.settingsListHasChanged();
    }

    getChangedSettings() {
        const updatedObjects = [];

        this.tableDataSource.data.forEach((newObj) => {
            const oldObj = this.initialDataSource.find((o) => o.id === newObj.id);
            if (oldObj && !_.isEqual(oldObj, newObj)) {
                updatedObjects.push(newObj);
            } else if (!oldObj) {
                updatedObjects.push(newObj);
            }
        });
        return updatedObjects;
    }

    getSettingErrors(fuSetting, key) {
        if (key === 'name') {
            let repeatedTitleError;
            this.tableDataSource.data.map((setting) => {
                if (setting !== fuSetting) {
                    if (setting.name && setting.name === fuSetting.name) {
                        repeatedTitleError = 'fu-settings.error.repeated-name';
                    }
                }
            });
            if (repeatedTitleError) {
                return repeatedTitleError;
            }
        }
    }
}
