import { Group } from '@components/channel-group-selector/channel-group-selector.component';
import * as diff from 'diff';
import * as dayjs from 'dayjs';
import { OperationType, UpdatedChannels } from './print-channels.service';

export function pagesPerChannelGroup(channels) {
    let lastPages = [];
    for (let i = 0; i < channels.length; i++) {
        let channelsInGroup = channels[i].length;
        let lastPage = Math.ceil(channelsInGroup / 20);
        if (i > 0) {
            lastPage = lastPage + lastPages[i - 1];
        }
        lastPages.push(lastPage);
    }
    return lastPages;
}

export function getHeader(currentPage, lastPages, tableGroupHeaders: string[]) {
    let header = '';
    for (let i = 0; i < lastPages.length; i++) {
        if (currentPage <= lastPages[i]) {
            header = tableGroupHeaders[i];
            break;
        }
    }

    return header;
}

export function getFooter() {
    return [{ text: dayjs().format('DD/MM/YY HH:mm') }, { text: '', alignment: 'right' }];
}

export function getPdfLayout(updatedChannels: UpdatedChannels, deletedRowsInChannelGroup) {
    return {
        paddingTop: () => {
            return 0;
        },
        paddingBottom: () => {
            return 0;
        },
        paddingLeft: () => {
            return 0;
        },
        paddingRight: () => {
            return 0;
        },
        hLineWidth: (i, node) => {
            if (deletedRowsInChannelGroup?.includes(i - 1)) {
                if (node.table?.body[i][0]?.text) {
                    return 2;
                }
            }
            return 0.75;
        },
        vLineWidth: () => {
            return 0.75;
        },
        hLineColor: function (i, node) {
            if (node.table?.body[i]) {
                if (updatedChannels.newChannels?.includes(node.table?.body[i][0]?.text)) {
                    return 'red';
                }
                if (
                    node.table?.body[i][0]?.text === '' &&
                    deletedRowsInChannelGroup?.includes(i - 1)
                ) {
                    return 'red';
                }
                if (node.table?.body[i - 1]) {
                    if (updatedChannels.newChannels?.includes(node.table?.body[i - 1][0]?.text)) {
                        return 'red';
                    }
                    if (
                        node.table?.body[i - 1][0]?.text === '' &&
                        deletedRowsInChannelGroup?.includes(i - 2)
                    ) {
                        return 'red';
                    }
                }
                if (node.table?.body[i][0]?.text !== '') {
                    if (deletedRowsInChannelGroup?.includes(i - 1)) {
                        return 'red';
                    }
                }
            }
            return 'black';
        },
        vLineColor: function () {
            return 'black';
        },
    };
}

export function getRowBorder(channelDifferences, rowType) {
    if (!channelDifferences) {
        return [false, false, false, false];
    }
    if (channelDifferences.modifiedFields?.some((item) => rowType.includes(item))) {
        return [true, true, true, true];
    }
    return [false, false, false, false];
}

export function getRowBorderColor() {
    return ['red', 'red', 'red', 'red'];
}

export function getInnerTableLayout(sidesSeparation?) {
    return {
        paddingLeft: function (i, node) {
            return sidesSeparation || 0;
        },
        paddingRight: function (i, node) {
            return sidesSeparation || 0;
        },
        paddingTop: function (i, node) {
            return 0;
        },
        paddingBottom: function (i, node) {
            return 0;
        },
    };
}

export function generateInnerTable(
    channelDifferences,
    rows: Array<{ text: any; fields: string[] }>,
) {
    const body = rows.map((row) => {
        return [
            {
                text: row.text,
                noWrap: true,
                border: getRowBorder(channelDifferences, row.fields),
                borderColor: getRowBorderColor(),
            },
        ];
    });

    return {
        table: {
            widths: ['*'],
            body: body,
            margin: [0, 0, 0, 0],
        },
        layout: getInnerTableLayout(),
        margin: [1, 0, 1, 0],
        lineHeight: 0.8,
    };
}

export function prepareChannelDiffrences(channelsDifference) {
    let result = {};
    channelsDifference.differenceItems.map((difference) => {
        if (
            difference.operationType === OperationType.UPDATE &&
            difference.modifiedFields.length === 1 &&
            difference.modifiedFields[0] === 'rowIndex'
        ) {
            return;
        }
        result[difference.blob.id] = {
            operationType: difference.operationType,
            modifiedFields: difference.modifiedFields,
        };
        if (
            difference.operationType === OperationType.UPDATE &&
            difference.modifiedFields.includes('opsData.name')
        ) {
            result[difference.blob.id].oldName = difference.blob.opsData.name;
            result[difference.blob.id].newName = difference.local.opsData.name;
        }
    });
    return result;
}

export function getNewChannels(channelsDifference) {
    let newChannelsTags = [];
    channelsDifference.differenceItems.map((difference) => {
        if (difference.operationType === OperationType.ADD) {
            newChannelsTags.push(difference.local.opsData.tag);
        }
    });
    return newChannelsTags;
}

export function getDeletedChannels(channelsDifference) {
    let deletedChannelsRowPosition = [];
    let deletedChannelRowsByGroup = {};
    channelsDifference.differenceItems.map((difference) => {
        if (difference.operationType === OperationType.DELETE) {
            if (deletedChannelRowsByGroup[difference.local.groupId]) {
                deletedChannelRowsByGroup[difference.local.groupId].push(difference.oldRowPosition);
            } else {
                deletedChannelRowsByGroup[difference.local.groupId] = [difference.oldRowPosition];
            }
        }
    });
    return deletedChannelRowsByGroup;
}

export function prepareGroupDiffrences(groupDifference) {
    let result = {};
    groupDifference.differenceItems.map((difference) => {
        result[difference.blob.id] = {
            operationType: difference.operationType,
            modifiedFields: difference.modifiedFields,
        };
        if (
            difference.operationType === OperationType.UPDATE &&
            difference.modifiedFields.includes('displayName')
        ) {
            result[difference.blob.id].oldName = difference.blob.displayName;
            result[difference.blob.id].newName = difference.local.displayName;
        }
    });
    return result;
}

export function getGroupHeaders(selectedGroups, groupsDifferences) {
    let tableHeaders = [];
    selectedGroups.map((group: Group) => {
        const header = getNameDiff(group.name, groupsDifferences[group.id]);
        header.margin = [28.3465, 70, 28.3465, 0];
        if (header.table) {
            header.table.body[0].unshift({
                text: 'Group Name: ',
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],
            });
        } else {
            header.text = 'Group Name: ' + header.text;
        }

        tableHeaders.push(header);
    });
    return tableHeaders;
}

export function getNameDiff(currentName, differences) {
    if (differences?.oldName) {
        const differencesArray = diff.diffWordsWithSpace(differences.oldName, differences.newName);
        let tableBody = [];
        differencesArray.forEach((part, index) => {
            if (part.removed) {
                return;
            } else if (part.added) {
                tableBody.push({
                    text: part.value,
                    border: [true, true, true, true],
                    borderColor: ['red', 'red', 'red', 'red'],
                    margin: [0, 0, 0, 0],
                });
            } else {
                let border =
                    index === differencesArray.length - 1
                        ? [false, false, false, false]
                        : [false, false, true, false];
                tableBody.push({
                    text: part.value,
                    border: border,
                    borderColor: ['red', 'red', 'red', 'red'],
                    margin: [0, 0, 0, 0],
                });
            }
        });
        return {
            table: {
                margin: [0, 0],
                body: [tableBody],
            },
            layout: getInnerTableLayout(2),
            margin: [0, 0, 0, 0],
            lineHeight: 0.8,
        };
    }

    return { text: currentName, lineHeight: 0.8, margin: [0, 0, 0, 0] };
}
