import { Component, Input, ViewChild } from '@angular/core';
import { ChannelCategoryService } from '@service/channel-category.service';
import { ChannelGroupsService } from '@service/channel-groups.service';
import { ChannelSheetService, UpdateType } from '@service/channel-sheet.service';
import { ChannelCategory } from 'common/models/channel-category';
import { ChannelGroup } from 'common/models/channel-group';
import {
    DialogChannelCategoryComponent,
    DialogType,
} from '../dialog-channel-category/dialog-channel-category.component';
import { DialogChannelGroupComponent } from '../dialog-channel-group/dialog-channel-group.component';

@Component({
    selector: 'app-channel-panel',
    templateUrl: './channel-panel.component.html',
    styleUrls: ['./channel-panel.component.css'],
})
export class ChannelPanelComponent {
    readonly GROUP_MODE = ChannelPanel.GROUP;
    readonly CATEGORY_MODE = ChannelPanel.CATEGORY;
    readonly ALARM_MODE = ChannelPanel.ALARM;

    readonly ADD = DialogType.add;

    mode: ChannelPanel;
    isShowAddOption = false;
    @Input() channelGroups: ChannelGroup[] = [];
    @Input() channelCategories: ChannelCategory[] = [];

    @ViewChild('categoryDialog') categoryDialog: DialogChannelCategoryComponent;
    @ViewChild('groupDialog') groupDialog: DialogChannelGroupComponent;

    constructor(
        private channelGroupService: ChannelGroupsService,
        private channelCategoryService: ChannelCategoryService,
        private channelSheetService: ChannelSheetService,
    ) {}

    async ngOnInit() {
        this.mode = this.GROUP_MODE;
    }

    selectMode(mode: ChannelPanel) {
        this.mode = mode;
    }

    openCategoryDialog(event: { type: DialogType; category: ChannelCategory }) {
        this.categoryDialog.setDialogData(event.type, event.category);
        this.categoryDialog.open();
    }

    openGroupDialog(event: { type: DialogType; group: ChannelGroup }) {
        this.groupDialog.setDialogData(event.type, event.group);
        this.groupDialog.open();
    }

    async renameGroup(group) {
        await this.channelGroupService.updateChannelGroup(group);
        this.channelSheetService.updateChannelSheet();
    }

    async deleteGroup(group: ChannelGroup) {
        let selectedGroupIndex = this.channelGroups.findIndex(
            (channelGroup) => channelGroup.id == group.id,
        );
        this.channelSheetService.showLoading();
        await this.channelGroupService.deleteChannelGroup(group.id);
        this.channelSheetService.updateGroupList(UpdateType.delete);
        this.channelSheetService.setActiveGroup(
            this.channelGroups[Math.max(0, selectedGroupIndex - 1)],
        );
        this.channelSheetService.hideLoading();
    }

    async addGroup(channelGroup) {
        let group = await this.channelGroupService.addChannelGroup(channelGroup);
        this.channelSheetService.updateGroupList(UpdateType.add);
        this.channelSheetService.setActiveGroup(group);
    }

    async addCategory(category) {
        await this.channelCategoryService.addChannelCategory(category);
        this.channelSheetService.updateCategoryList();
    }

    async renameCategory(category) {
        await this.channelCategoryService.updateChannelCategory(category);
        this.channelSheetService.updateCategoryList();
    }

    async deleteCategory(category: ChannelCategory) {
        await this.channelCategoryService.deleteChannelCategory(category.id);
        this.channelSheetService.updateCategoryList();
    }

    toggleAddOption() {
        this.isShowAddOption = this.isShowAddOption ? false : true;
    }
}

export enum ChannelPanel {
    GROUP = 'groups',
    CATEGORY = 'categories',
    ALARM = 'alarms',
}
