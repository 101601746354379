import { viewTypeArray } from 'common/models/view';
import { ObjectSchema, date, lazy, mixed, number, object, string } from 'yup';
import { View, ViewGroup } from 'common/models/view';
import mapValues from 'lodash/mapValues';
import { locale } from '../yup-locale';
import * as yup from 'yup';
yup.setLocale(locale);

export const viewValidator: ObjectSchema<View> = object({
    id: string().nonNullable().strict(),
    svgcontent: string().nonNullable().strict(),
    items: lazy((obj) =>
        object(
            mapValues(obj, () =>
                object({
                    id: string().nonNullable().required().strict(),
                    label: string().nonNullable().required().strict(),
                    name: string().nonNullable().strict(),
                    property: mixed().nonNullable().required().strict(),
                    type: string().nonNullable().required().strict(),
                }),
            ),
        )
            .json()
            .required()
            .strict(),
    ),
    // items: object().json().nonNullable().required(),
    profile: object({
        width: number().nonNullable().required().strict(),
        height: number().nonNullable().required().strict(),
        bkcolor: string().nonNullable().required().strict(),
        margin: number().nonNullable().required().strict(),
    })
        .json()
        .nonNullable()
        .required()
        .strict(),
    name: string().nonNullable().required().strict(),
    projectId: mixed().required().nonNullable(),
    type: string().oneOf(viewTypeArray).strict(),
    createdAt: mixed().nullable().strict(),
    updatedAt: mixed().nullable().strict(),
    locked: number().nonNullable().strict().required(),
    lockingEditorName: string().nullable().strict(),
    category: string().nullable().strict(),
    userID: string().nullable().strict().notRequired(),
    viewsGroupId: string().uuid().nullable().strict(),
    variables: object({
        id: mixed(),
    })
        .nullable()
        .strict(),
})
    .noUnknown()
    .strict();

export const viewGroupValidator: ObjectSchema<ViewGroup> = object({
    id: string().uuid().nonNullable().strict(),
    projectId: mixed().required().nonNullable(),
    groupName: string().nonNullable().required().strict(),
})
    .noUnknown()
    .strict();
