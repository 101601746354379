export class AppSettings {
    /** Editor language */
    language: string = 'EN';
    /** Web server port */
    uiPort: number = 1881;
    /** Security access to enable user and authentication */
    secureEnabled: boolean = false;
    /** Expiration of authanticated token (15m)*/
    tokenExpiresIn: string = '1h';
    /** Active project ID for server */
    activeProjectId: any;
    /** Active project name */
    activeProjectName: string;
}
