import { Injectable } from '@angular/core';
import { View } from 'common/models/view';

@Injectable({
    providedIn: 'root',
})
export class EditFlgService {
    constructor() {}
    clientViewID: string;
    clientAccountName: string;
    clientAccountOID: string;

    currentEditor = '';

    viewEditingMap: Map<string, EditView> = new Map();

    setClientData(viewID: string) {
        this.clientViewID = viewID;
    }

    getEditorName(viewID: string) {
        this.currentEditor = '';
        let editingView = this.viewEditingMap.get(viewID);
        if (editingView) {
            this.currentEditor = editingView.editor.name;
        }
    }

    getEditorNameFromServer(data: { ViewID: string; EditorName: string }) {
        this.currentEditor = '';
        if (data.ViewID === this.clientViewID) {
            this.currentEditor = data.EditorName;
        }
    }

    checkEditMode(view: View) {
        let editingView = this.viewEditingMap.get(view.id);
        return editingView && editingView.editor.oid === this.clientAccountOID;
    }

    getViewEditingMap() {
        return this.viewEditingMap;
    }

    addViewEditingMap(viewId: string, editorName: string, editorOid: string) {
        this.viewEditingMap.set(viewId, {
            viewId: viewId,
            editor: {
                oid: editorOid,
                name: editorName,
            },
        });
    }

    deleteViewEditingMap(viewId: string) {
        this.viewEditingMap.delete(viewId);
    }

    deleteAllEditingMap(editorOid: string) {
        for (const [key, value] of this.viewEditingMap.entries()) {
            if (value.editor.oid == editorOid) {
                this.viewEditingMap.delete(key);
            }
        }
    }
}

export interface EditView {
    viewId: string;
    editor: { name: string; oid: string };
}
