import { getStrokedBBox } from '@svgeditor/utilities.js';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf/dist/jspdf.es.min.js';

type ExportSettings = {
    selected: boolean;
    separate: boolean;
    type: string;
    resolution: number;
};

type SVGData = {
    svg: string;
    width: number;
    height: number;
};

const handleExport = async (settings: ExportSettings, canvas: any) => {
    // First, get the data. this is an array of objects containg svg string, width, height
    const svgStrings: Array<SVGData> = getSVGStrings(settings, canvas);

    // For each object in the array, either do svg export, pdf export, png/jpg export
    for (const el of svgStrings) {
        if (settings.type == 'svg') {
            blobSVGExport(el.svg, 'export.svg');
        } else if (settings.type == 'pdf') {
            const canv = await generateCanvas(el, settings.resolution);
            pdfExport(
                canv,
                'export.pdf',
                el.width * settings.resolution,
                el.height * settings.resolution,
            );
        } else {
            const canv = await generateCanvas(el, settings.resolution);
            rasterExport(canv, 'export.' + settings.type);
        }
    }
};

// Returns an array of objects {svg, width, height}
const getSVGStrings = (settings: ExportSettings, svgcanvas: any): Array<SVGData> => {
    // selected == false means user opted for entire canvas
    if (settings.selected == false) {
        return [getCanvasSVGforExport(svgcanvas)];
    } else {
        if (svgcanvas.getSelectedElements().length == 0) {
            return [];
        }

        if (settings.separate == false) {
            return [getSVGForExport(svgcanvas.getSelectedElements(), svgcanvas)];
        } else {
            const out = [];
            for (const el of svgcanvas.getSelectedElements()) {
                out.push(getSVGForExport([el], svgcanvas));
            }
            return out;
        }
    }
};

// Convert the entire canvas into a data object
const getCanvasSVGforExport = (svgcanvas): SVGData => {
    return {
        svg: svgcanvas.getSvgContent().outerHTML,
        width: svgcanvas.getWidth(),
        height: svgcanvas.getHeight(),
    };
};

// Combine the selectedElems into one valid svg string
const getSVGForExport = (selectedElems: Array<HTMLElement>, svgcanvas: any): SVGData => {
    const viewbox = getStrokedBBox(
        selectedElems,
        svgcanvas.addSVGElementsFromJson,
        svgcanvas.pathActions,
    );

    let outString = `<svg viewBox="${viewbox.x} ${viewbox.y} ${Math.ceil(
        viewbox.width,
    )} ${Math.ceil(viewbox.height)}" fill="none" xmlns="http://www.w3.org/2000/svg">\n`;
    for (const el of selectedElems) {
        outString += el.outerHTML + '\n';
    }
    outString += '</svg>';
    return {
        svg: outString,
        width: Math.ceil(viewbox.width),
        height: Math.ceil(viewbox.height),
    };
};

// Save the canvas as png or jpg
const rasterExport = (canvas: HTMLCanvasElement, filename: string) => {
    canvas.toBlob(function (blob) {
        saveAs(blob, filename);
    });
};

// Create pdf, add the canvas, save
const pdfExport = (canvas: HTMLCanvasElement, filename: string, width: number, height: number) => {
    const imgData = canvas.toDataURL('image/png');
    const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [width, height],
    });

    doc.addImage(imgData, 'PNG', 0, 0, width, height);
    doc.save(filename);
};

// Turn the svg string into an html canvas
const generateCanvas = async (data, resolution): Promise<HTMLCanvasElement> => {
    return await new Promise((res, rej) => {
        // Make an arbitrary iframe
        const iframe = document.createElement('iframe');
        iframe.onload = function () {
            const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
            // set the content of the iframe to be the svg string
            iframedoc.body.innerHTML = data.svg;
            iframedoc.body.style.margin = '0';
            iframedoc.body.style.padding = '0';

            // fix styling on svg string
            (iframedoc.body.children[0] as HTMLElement).style.display = 'block';
            (iframedoc.body.children[0] as HTMLElement).removeAttribute('width');
            (iframedoc.body.children[0] as HTMLElement).removeAttribute('height');

            // set iframe width
            iframe.width = (data.width * resolution).toString();
            iframe.height = (data.height * resolution).toString();

            // convert iframe to canvas
            html2canvas(iframedoc.body, {
                useCORS: true,
                allowTaint: true,
            }).then((canvas) => {
                // remove iframe
                iframe.parentNode.removeChild(iframe);

                //return canvas
                res(canvas);
            });
        };
        // add iframe to document
        document.body.appendChild(iframe);
    });
};

// convert svg string to blob, save it.
const blobSVGExport = async (svg: string, filename: string) => {
    const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
    saveAs(blob, filename);
};

export { handleExport, ExportSettings };
