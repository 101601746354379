import { EventEmitter, Injectable } from '@angular/core';

import { CanvasService } from './canvas.service';
import { CanvasInputModeService, CanvasInputMode } from './canvas-input-mode.service';
import { SvgEditExtension, SvgEditExtensionObject } from '@models/svg-edit-extension';

@Injectable()
export class EyeDropperService implements SvgEditExtension {
    name: string = 'eyeDropper';

    constructor(private plugin: EyeDropperPlugin) {
        this.init = this.init.bind(this);
        this.bindMouseEvents();
    }

    init(): SvgEditExtensionObject {
        return this.plugin;
    }

    private bindMouseEvents() {
        return this.plugin.bindMouseEvents();
    }

    onDisplayColorChanged() {
        return this.plugin.onDisplayColorChanged();
    }
}

@Injectable()
export class EyeDropperPlugin implements SvgEditExtensionObject {
    private displayedColor: string;
    private _onDisplayColorChanged = new EventEmitter<string>();

    constructor(
        private canvasService: CanvasService,
        private inputMode: CanvasInputModeService,
    ) {}

    mouseDown() {
        if (this.inputMode.getMode() === CanvasInputMode.EYE_DROPPER) {
            this.inputMode.setMode(CanvasInputMode.SELECT);
        }
    }

    private transformPoint(x, y, m) {
        return {
            x: m.a * x + m.c * y + m.e,
            y: m.b * x + m.d * y + m.f,
        };
    }

    getColorFromMousePosition(event) {
        const canvas = this.canvasService.getSvgRoot();
        const rootSctm = this.canvasService.getSCTM();
        const pt = this.transformPoint(event.clientX, event.clientY, rootSctm);
        let point = canvas.createSVGPoint();
        point.y = pt.y;
        point.x = pt.x;
        const target = event.target;
        if (target == canvas) {
            this.displayedColor = '#FFFFFF';
        }
        if (target.isPointInFill) {
            if (target.isPointInFill(point)) {
                this.displayedColor = target.getAttribute('fill');
            } else if (!target.isPointInFill(point)) {
                this.displayedColor = target.getAttribute('stroke');
            }
        }
    }

    bindMouseEvents() {
        document.addEventListener('mousemove', (event: MouseEvent) => {
            if (this.inputMode.getMode() === CanvasInputMode.EYE_DROPPER) {
                this.getColorFromMousePosition(event);
                this.emitDisplayColorChange();
            }
        });
    }

    onDisplayColorChanged() {
        return this._onDisplayColorChanged;
    }

    private emitDisplayColorChange() {
        this._onDisplayColorChanged.emit(this.displayedColor);
    }

    callback(deps: any) {}
}
