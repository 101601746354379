export class Device {
    /** Device id, GUID */
    id: string;
    /** Device name */
    name: string | number;
    /** Enabled */
    enabled: boolean;
    /** Connection property, DeviceNetProperty */
    property: any;
    /** Device type, OPC, Modbus, S7, etc. */
    type: string;
    /** Polling interval, check changed value after ask value, by OPCUA there is a monitor  */
    polling: number;
    /** Tags list of Tag */
    tags: any;

    constructor(_id: string) {
        this.id = _id;
    }
}

export class Tag {
    /** Tag id, GUID */
    id: string;
    /** Tag name, is like the id  */
    name: string;
    /** Tag label, used by BACnet and WebAPI  */
    label: string;
    /** not used yet */
    value: string;
    /** Tag type, Bool, Byte, etc. */
    type: string;
    /** Address of Tag, combine with address by Modbus, some property for WebAPI */
    memaddress: string;
    /** Tag address, for OPCUA like the id */
    address: string;
    /** Value divisor, used by Modbus */
    divisor: number;
    /** not used yet */
    access: string;
    /** Options, used for WebAPI and MQTT */
    options: any;
    /** not used yet */
    format: any;
    /** Daq settings */
    daq: TagDaq;

    constructor(_id: string) {
        this.id = _id;
        this.daq = new TagDaq(false, true, 60);
    }
}

export class TagDaq {
    /** DAQ data acquisition is enabled */
    enabled?: boolean;
    /** Fix interval to save the current value in seconds*/
    interval?: number;
    /** Save if the value was changed, the check is in device polling interval */
    changed?: boolean;

    constructor(_enabled: boolean, _changed: boolean, _interval: number) {
        this.enabled = _enabled;
        this.changed = _changed;
        this.interval = _interval;
    }
}

export enum DeviceType {
    FuxaServer = 'FuxaServer',
    SiemensS7 = 'SiemensS7',
    OPCUA = 'OPCUA',
    BACnet = 'BACnet',
    ModbusRTU = 'ModbusRTU',
    ModbusTCP = 'ModbusTCP',
    WebAPI = 'WebAPI',
    MQTTclient = 'MQTTclient',
    WebStudio = 'WebStudio',
    internal = 'internal',
    AmsInterface = 'AmsInterface',
}

export const deviceTypeArray = [
    'FuxaServer',
    'SiemensS7',
    'OPCUA',
    'BACnet',
    'ModbusRTU',
    'ModbusTCP',
    'WebAPI',
    'MQTTclient',
    ' WebStudio',
    'internal',
    'AmsInterface',
];
