import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { EndPointApi } from '@helpers/endpointapi';
import { LoadingSpinnerComponent } from '@layout/loading-spinner/loading-spinner.component';
import { SpreadSheetReaderService } from './spreadsheet-file-reader.service';

@Injectable({
    providedIn: 'root',
})
export class ImportStandardService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    overlayRef = this.overlay.create({
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });

    constructor(
        private spreadSheet: SpreadSheetReaderService,
        private http: HttpClient,
        private overlay: Overlay,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    importJSMEAStandard(file) {
        this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));
        const jsmeaStandard = [
            'Machinery',
            'Navigation',
            'Oil',
            'Motion',
            'Voyage',
            'Weather',
            'Content',
            'Position',
            'Item',
        ];
        const countPoint = [1, 3, 5, 7, 9, 11, 12, 13];
        this.spreadSheet.read(file).then((res) => {
            const sendData = [];
            let j = 0;
            for (let i = 0; i < res.length; i++) {
                res[i].data.forEach((elem) => {
                    if (elem[0] != 'Code') {
                        sendData.push({
                            code: elem[0],
                            channelName: elem[1],
                            group: jsmeaStandard[j],
                        });
                    }
                });
                if (countPoint.includes(i)) {
                    j++;
                }
            }
            this.postJSMEAData(sendData)
                .then(() => {
                    this.overlayRef.detach();
                })
                .catch((e) => {
                    console.log(e);
                    this.overlayRef.detach();
                });
        });
    }

    postJSMEAData(data) {
        return lastValueFrom(this.http.post(`${this.domain}/api/standard/jsmea`, data))
            .then((res) => console.log(res))
            .catch((e) => console.log(e));
    }

    getJSMEAData(): Observable<any> {
        return this.http.get(`${this.domain}/api/standard/jsmea`);
    }
}
