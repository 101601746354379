import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { ChannelCompareDialogComponent } from '@components//channel-compare-dialog/channel-compare-dialog.component';
import { ProjectBlobSelectDialogComponent } from '@components//project-blob-select-dialog/project-blob-select-dialog.component';
import { AppSettingsComponent } from '@components/app-settings/app-settings.component';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { Utils } from '@helpers/utils';
import { LoadingSpinnerComponent } from '@layout/loading-spinner/loading-spinner.component';
import { DEVICE_READONLY, HelpData } from '@models/hmi';
import { EditFlgService } from '@service/editflg.service';
import { VesselSelectService } from '@service/vessel-select.service';
import { VesselBuilderSelectService } from '@service/vessel-builder-select.service';
import { PrintGraphsService } from '@service/graphs/print-graphs.service';
import { LocalStorageService } from '@service/local-storage.service';
import { MimicChangesService } from '@service/mimic-changes.service';
import { NavigationService } from '@service/navigation.service';
import { NotificationService } from '@service/notification.service';
import { PrintChannelsService } from '@service/print-channels-service/print-channels.service';
import { ProjectService } from '@service/project.service';
import { ResWebApiService } from '@service/reswebapi.service';
import { SocketService } from '@service/socket.service';
import { VesselService } from '@service/vessel.service';
import { ViewsService } from '@service/views.service';
import { BlobOperationParam } from 'common/models/blob-operations';
import { View } from 'common/models/view';
import { CloudStorageItem, ProjectData, ProjectSetting } from 'icreate/common/models/project';
import * as _ from 'lodash';
import { lastValueFrom, take, firstValueFrom } from 'rxjs';
import { DialogGridComponent } from './dialog-grid/dialog-grid.component';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { DialogTextInputComponent } from './dialog-text-input/dialog-text-input.component';
import {
    ConfirmButtonType,
    ConfirmationDialogData,
    DialogConfirmationComponent,
} from './dialog-confirmation/dialog-confirmation.component';
import { DialogVesselInfoComponent } from './dialog-vessel-info/dialog-vessel-info.component';
import { DialogSaveasComponent } from './dialog-saveas/dialog-saveas.component';
import { OpsSettingsComponent } from '@components/ops-settings/ops-settings.component';
import { PublishProjectDialogComponent } from '@components/publish-project-dialog/publish-project-dialog.component';
import { AuthService } from '@service/auth.service';
import { BlobStorageApiService } from '@service/blob-storage-api.service';
import { BlobStorageService } from '@service/blob-storage.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
    @Input() canvas: any;
    @Input() currentView: View;
    @Input() currentProject: ProjectData;
    @Input() initalViewSettings: View;
    @Output() startEvent = new EventEmitter();
    @Output() undoEvent = new EventEmitter();
    @Output() redoEvent = new EventEmitter();
    @Output() cloneEvent = new EventEmitter();
    @Output() deleteEvent = new EventEmitter();
    @Output() zoomEvent = new EventEmitter<number>();
    @Output() compareViewsEvent = new EventEmitter();
    @Output() printMimicsEvent = new EventEmitter();
    @Output() onBlobDataReceived = new EventEmitter();
    @Output() overwriteFromBlob = new EventEmitter();
    @Output() onFinishEdit = new EventEmitter();
    @ViewChild('sidenav') sidenav: any;
    @ViewChild('fileImportInput') fileImportInput: any;
    @ViewChild('prjviewtrigger') prjViewTrigger: MatMenuTrigger;
    @ViewChild('sideMenu') sideMenuTrigger: MatMenuTrigger;
    @ViewChild('confirmationWindowProjectExist')
    confirmWindowProjectExist: ConfirmationWindowComponent;
    @ViewChild('confirmationWindowProjectNotExist')
    confirmWindowProjectNotExist: ConfirmationWindowComponent;

    darkTheme = true;
    ineditor = false;
    savededitor = false;
    narrowScreen = false;
    gridData: any = {};
    saveProjectDisabled: Boolean = false;
    blobProject: ProjectData = new ProjectData();
    overlayRef = this.overlay.create({
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
    isOverlayVisible: boolean = false;
    isChannelListOpen: boolean = false;

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        public editFlg: EditFlgService,
        private vesselSelectService: VesselSelectService,
        private resWebAPIService: ResWebApiService,
        public projectService: ProjectService,
        private vesselBuilderSelectService: VesselBuilderSelectService,
        private socket: SocketService,
        private mimicChangesService: MimicChangesService,
        private notificationService: NotificationService,
        private navigationService: NavigationService,
        private localStorageService: LocalStorageService,
        private blobStorageService: BlobStorageService,
        private blobStorageApiService: BlobStorageApiService,
        private vesselService: VesselService,
        private viewsService: ViewsService,
        private overlay: Overlay,
        private printChannelsService: PrintChannelsService,
        private printGraphsService: PrintGraphsService,
        private authService: AuthService,
    ) {
        this.router.events.subscribe(() => {
            this.ineditor =
                this.router.url.indexOf('editor') >= 0 ||
                this.router.url.indexOf('device') >= 0 ||
                this.router.url.indexOf('users') >= 0 ||
                this.router.url.indexOf('text') >= 0 ||
                this.router.url.indexOf('messages') >= 0
                    ? true
                    : false;
            this.savededitor =
                this.router.url.indexOf('device') >= 0 ||
                this.router.url.indexOf('users') >= 0 ||
                this.router.url.indexOf('text') >= 0 ||
                this.router.url.indexOf('messages') >= 0
                    ? true
                    : false;

            if (this.router.url.indexOf(DEVICE_READONLY) >= 0) {
                this.ineditor = false;
            }
        });
    }

    async ngOnInit() {
        this.breakpointObserver
            .observe([Breakpoints.Small, Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.narrowScreen = state.matches;
            });

        const storedValue = localStorage.getItem('grid_data');
        if (storedValue) {
            this.gridData = JSON.parse(storedValue);
        } else {
            this.gridData = {
                grid_color: '#000000',
                grid_spacing: 1,
            };
        }

        this.blobStorageService.hideLoading.subscribe(() => {
            this.hideLoadingOverlay();
        });
    }

    onShowHelp(page: any) {
        const data = new HelpData();
        data.page = page;
        data.tag = 'device';
    }

    async showInfo() {
        const buildVersion = await this.resWebAPIService.getVersion();
        const dialogRef = this.dialog.open(DialogInfoComponent, {
            data: { name: 'Info', version: buildVersion.buildVersion },
        });
    }

    goTo(destination: string) {
        this.router.navigate([destination]); //, this.ID]);
    }

    //#region Project Events
    onNewProject() {
        const dialogRef = this.dialog.open(AppSettingsComponent, {
            position: { top: '60px' },
            data: { newProject: true },
        });
        dialogRef.afterClosed().subscribe(async (result: ProjectSetting) => {
            if (Utils.isNullOrUndefined(result)) {
                return;
            }

            const createdBy = this.editFlg.clientAccountName;
            let projectData = new ProjectData();
            projectData.setVesselData(result.vesselData);
            projectData.setVesselBuildData(result.vesselBuildData);
            projectData.name = result.projectName;
            try {
                const newProject = await this.projectService.createNewProject(
                    projectData,
                    createdBy,
                );
                this.navigationService.toEditor(newProject.id);
                this.projectService.load(newProject.id);
            } catch (error) {
                this.navigationService.toEditor();
            }
        });
    }

    /**
     * Clone the project and rename it
     */
    async onSaveProjectAs() {
        try {
            let updatedView: View;
            this.toggleShowChanges();
            this.showLoadingOverlay();
            let projectList = await this.projectService.getProjectList();
            let filteredProject = projectList.filter(
                (project) =>
                    project.VesselBuilderCompanyID == this.currentProject.VesselBuilderCompanyID &&
                    project.VesselID == this.currentProject.VesselID,
            );
            this.hideLoadingOverlay();
            const dialogRef = this.dialog.open(DialogSaveasComponent, {
                position: { top: '60px' },
                data: {
                    title: 'Save As',
                    guideText: 'Enter a new name',
                    hint: 'Name',
                    seedText: this.currentProject.name,
                    projectList: filteredProject,
                },
            });
            dialogRef.componentInstance.clickOkEmitter.subscribe(async (newProjectName: string) => {
                if (this.currentProject.name == newProjectName) {
                    //show dialog project replace or not
                    let confirm = await this.openConfirmDialog({
                        title: `“${newProjectName}“ already exists. Do you want to replace it?`,
                        okButtonText: 'Replace',
                        okButtonType: ConfirmButtonType.PRIMARY,
                        noButtonText: 'Cancel',
                        noButtonType: ConfirmButtonType.BASIC,
                        confirmMessage:
                            'A project with the same name already exists. Replacing it will overwrite its current contents.',
                    });
                    if (confirm) {
                        dialogRef.close();
                        await this.updateView();
                    }
                } else {
                    updatedView = { ...this.currentView, svgcontent: this.canvas.getSvgString() };
                    const editor = this.authService.getCurrentUser();
                    dialogRef.close();
                    await this.viewsService.unlockView(
                        this.currentView.id,
                        this.currentProject.id,
                        editor.name,
                        editor.oid,
                    );
                    this.projectService
                        .cloneAndRename(
                            this.currentProject.id,
                            editor.name,
                            newProjectName,
                            updatedView,
                        )
                        .then((res) => {
                            localStorage.setItem('currentProject', res);
                            this.navigationService.toEditorWithParam(res);
                            this.projectService.load(res);
                            this.projectService.notifySaveSuccess();
                        })
                        .catch((err) => {
                            this.projectService.notifySaveError(err);
                        });
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    async onOpenProject() {
        this.toggleShowChanges();

        this.projectService.openDialog().then(async (res) => {
            if (res?.id !== this.currentProject?.id) {
                if (this.editFlg.checkEditMode(this.currentView)) {
                    await this.updateView();
                }
                this.navigationService.toEditor(res.id);
                this.localStorageService.setCurrentProjectId(res.id);
                this.projectService.load(res.id);
                this.socket.deviceLoad(res.id);
            }
            this.prjViewTrigger?.closeMenu();
            this.sideMenuTrigger?.closeMenu();
        });
    }

    viewHasChanged() {
        let viewChanged = false;
        if (this.initalViewSettings) {
            const svgContent = this.canvas.svgContent;
            viewChanged = this.mimicChangesService.viewHasUpdated(
                this.initalViewSettings,
                svgContent,
            );
        }
        return viewChanged;
    }

    onImportProject() {
        const ele = document.getElementById('projectFileUpload') as HTMLElement;
        ele.click();
    }

    async onDeleteProject() {
        this.toggleShowChanges();
        try {
            const message = 'Are you sure to delete ' + this.currentProject.name + '?';
            let confirm = await this.openConfirmDialog({
                title: `Delete Project`,
                okButtonText: 'Delete',
                okButtonType: ConfirmButtonType.WARN,
                noButtonText: 'Cancel',
                noButtonType: ConfirmButtonType.PRIMARY,
                confirmMessage: message,
            });
            if (confirm) {
                this.showLoadingOverlay();
                this.projectService.deleteProject(this.currentProject.id).then((res) => {
                    if (res) {
                        this.projectService.notifyDeleteSuccess();
                        this.hideLoadingOverlay();
                        this.prjViewTrigger?.closeMenu();
                        this.sideMenuTrigger?.closeMenu();
                        // force user to open other project.
                        this.projectService.openDialog(true, true).then((res) => {
                            this.navigationService.toEditorWithParam(res.id);
                            this.projectService.load(res.id);
                        });
                    } else {
                        this.hideLoadingOverlay();
                    }
                });
            }
        } catch (err) {
            this.hideLoadingOverlay();
            this.projectService.notifyDeleteError(err);
        }
    }

    async onRenameProject() {
        try {
            this.toggleShowChanges();
            const dialogRef = this.dialog.open(DialogTextInputComponent, {
                position: { top: '60px' },
                data: {
                    title: 'Rename Project',
                    guideText: 'Enter the new project name',
                    hint: 'New Project Name',
                    seedText: this.currentProject.name,
                },
            });
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result === undefined) {
                    console.log('this is undefined');
                } else {
                    const updatedBy = this.editFlg.clientAccountName;
                    try {
                        let updatedProject = await this.projectService.setProjectNewName(
                            this.currentProject.id,
                            result,
                            updatedBy,
                        );
                        this.currentProject.name = updatedProject.name;
                    } catch (e) {
                        throw e;
                    }
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    onSettingProject() {
        this.toggleShowChanges();
        const projectObj = {
            projectName: this.currentProject.name,
            vesselBuildData: {
                id: this.currentProject.VesselBuilderCompanyID,
                name: this.currentProject.VesselBuilderCompanyName,
            },
            vesselData: {
                id: this.currentProject.VesselID,
                name: this.currentProject.VesselName,
                vesselNumber: this.currentProject.VesselNumber,
                managerName: this.currentProject.VesselManager,
                ownerName: this.currentProject.VesselOwner,
                AMSVer: this.currentProject.AMSVersion,
            },
        };

        this.dialog.open(AppSettingsComponent, {
            position: { top: '60px' },
            data: { newProject: false, projectData: projectObj },
        });
    }

    onOpenOpsSettings() {
        this.dialog.open(OpsSettingsComponent, {
            position: { top: '10vh' },
            maxHeight: '80vh',
        });
    }

    /**
     * open Project event file loaded
     * @param event file resource
     */
    onFileChangeListener(event: any) {
        const text = [];
        const files = event.srcElement.files;
        const input = event.target;
        const reader = new FileReader();
        reader.onload = (data) => {
            const prj = JSON.parse(reader.result.toString());
            this.projectService.setProject(prj, true);
        };
        reader.onerror = function () {
            const msg = 'Unable to read ' + input.files[0];
            // this.translateService.get('msg.project-load-error', {value: input.files[0]}).subscribe((txt: string) => { msg = txt });
            alert(msg);
        };
        reader.readAsText(input.files[0]);
        this.fileImportInput.nativeElement.value = null;
    }

    /**
     * save Project and Download in Browser
     */
    async onSaveProject() {
        try {
            this.toggleShowChanges();
            if (this.editFlg.checkEditMode(this.currentView)) {
                await this.updateView();
            }
            this.initalViewSettings = _.cloneDeep(this.currentView);
        } catch (e) {}
    }

    /**
     * Open shipyard and vessel select dialog
     */
    onSelectVessel() {
        this.vesselBuilderSelectService
            .openBuilderSelectDialog()
            .then((res) => {
                if (res == undefined) {
                    throw new Error();
                }
                this.showVesselList(res.id, res.name);
            })
            .catch((error) => {});
    }

    showVesselList(builderId: string, builderName: string) {
        this.vesselSelectService
            .openVesselSelectDialog(builderId, builderName)
            .then((res) => {
                const msg = `vessel id: ${res.id} / vessel No: ${res.vesselNumber} / name: ${res.name}`;
                console.log(msg);
                alert(msg);
            })
            .catch((error) => {});
    }

    async onShowVesselInfo() {
        try {
            const vesselInfo = await this.vesselService.getVesselInfo(this.currentProject.id);
            const shownData = vesselInfo.filter(this.filterNotShownData);
            this.dialog.open(DialogVesselInfoComponent, {
                data: shownData,
            });
        } catch (e) {
            console.log(e);
        }
    }

    filterNotShownData(data: any) {
        if (data.areaName == 'BuilderID' || data.areaName == 'VesselID') return false;
        return true;
    }

    onChannelConfig() {
        this.navigationService.toChannel(this.projectService.getProjectId());
    }

    async onPullProject() {
        let title = 'Pull Project';
        let type = 'PullBlobConfirm';

        let result = await this.openProjectBlobSelectDialog(title, type);
        this.currentProject.VesselBuilderCompanyID = result.builderId;
        this.currentProject.VesselID = result.vesselId;
        this.prjViewTrigger?.closeMenu();
        this.sideMenuTrigger?.closeMenu();

        if (Utils.isNullOrUndefined(result)) return;

        let blobParams = this.generateBlobOperationParam();

        try {
            const [pullProject, projectList] = await Promise.all([
                this.blobStorageApiService.pullProject(blobParams),
                this.projectService.getProjectList(),
            ]);

            this.blobProject = pullProject.blobProject;
            let isProjectExist = projectList.find(
                (project: ProjectData) => project.id === pullProject.blobProject.id,
            );

            if (!isProjectExist) {
                await this.pullProjectIsNotExist(pullProject);
            } else {
                await this.pullProjectIsExist(pullProject);
                this.projectService.load(this.currentProject.id);
            }
        } catch (error) {
            this.notificationService.projectPullError(this.currentProject.name, error);
        }
    }

    async pullProjectIsExist(pullProject: CloudStorageItem) {
        let blobParams = this.generateBlobOperationParam();

        if (this.currentProject.id !== pullProject.blobProject.id) {
            let shouldOverwrite = await this.openBlobConfirmation(true);
            if (shouldOverwrite) {
                this.showLoadingOverlay();
                await this.blobStorageService.overwriteProjectFromBlob(blobParams);
            } else {
                this.navigationService.toEditorWithParam(pullProject.blobProject.id);
                this.projectService.load(pullProject.blobProject.id);
                blobParams.projectId = pullProject.blobProject.id;
                this.showLoadingOverlay();
                await this.blobStorageService.pullProjectFromBlob(
                    blobParams,
                    pullProject,
                    this.currentProject,
                );
            }
        } else {
            this.showLoadingOverlay();
            await this.blobStorageService.pullProjectFromBlob(
                blobParams,
                pullProject,
                this.currentProject,
            );
        }
    }

    async pullProjectIsNotExist(pullProject: CloudStorageItem) {
        let blobParams = this.generateBlobOperationParam();
        let shouldOverwrite = await this.openBlobConfirmation();
        if (shouldOverwrite) {
            await this.blobStorageService.overwriteProjectFromBlob(blobParams);
            this.projectService.load(this.currentProject.id);
        } else {
            blobParams.projectId = pullProject.blobProject.id;
            await this.blobStorageService.pullProjectFromBlob(
                blobParams,
                pullProject,
                pullProject.blobProject,
            );
            this.navigationService.toEditorWithParam(pullProject.blobProject.id);
            this.projectService.load(pullProject.blobProject.id);
        }
    }

    generateBlobOperationParam() {
        const blobParams = new BlobOperationParam();
        blobParams.builderId = this.currentProject.VesselBuilderCompanyID;
        blobParams.vesselId = this.currentProject.VesselID;
        blobParams.projectId = this.currentProject.id;

        return blobParams;
    }

    openBlobConfirmation(exist?: boolean) {
        if (exist) {
            this.confirmWindowProjectExist.setVisible(true);
            return lastValueFrom(this.confirmWindowProjectExist.onOptionSelected.pipe(take(1)));
        } else {
            this.confirmWindowProjectNotExist.setVisible(true);
            return lastValueFrom(this.confirmWindowProjectNotExist.onOptionSelected.pipe(take(1)));
        }
    }

    async onPublishProject() {
        this.toggleShowChanges();
        const dialogRef = this.dialog.open(PublishProjectDialogComponent, {
            data: {
                projectData: this.currentProject,
            },
        });

        const requestObject = await lastValueFrom(dialogRef.afterClosed());

        if (requestObject) {
            try {
                this.blobStorageService.requestPublishBlob(requestObject);
            } catch (err) {
                this.notificationService.projectPublishError();
            }
        }
    }

    async openProjectBlobSelectDialog(title: string, type: string) {
        const dialogRef = this.dialog.open(ProjectBlobSelectDialogComponent, {
            data: {
                title: title,
                type: type,
                projectData: this.currentProject,
            },
        });

        return lastValueFrom(dialogRef.afterClosed());
    }

    onCompareMimics() {
        this.compareViewsEvent.emit();
    }

    onCompareChannels() {
        const dialogRef = this.dialog.open(ProjectBlobSelectDialogComponent, {
            data: {
                title: 'Compare Channels',
                type: 'CompareChannels',
                projectData: this.currentProject,
            },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            if (Utils.isNullOrUndefined(result)) return;
            const param = new BlobOperationParam();
            const builderId = result.builderId;
            const vesselId = result.vesselId;
            const projectId = this.currentProject.id;
            this.projectService.load(projectId);
            const versionList = await this.blobStorageApiService.getVersionList(
                builderId,
                vesselId,
            );
            this.dialog.open(ChannelCompareDialogComponent, {
                width: '1200px',
                height: '700px',
                position: { top: '60px' },
                data: {
                    versionList: versionList,
                    currentProject: this.currentProject,
                },
            });
        });
    }

    toggleShowChanges() {
        this.mimicChangesService.toggleShowChanges();
    }

    onPrintMimics() {
        this.printMimicsEvent.emit(true);
    }

    printChannels() {
        this.printChannelsService.onPrintChannels.next();
    }

    printGraphs() {
        this.printGraphsService.onPrintGraphs.next();
    }

    onStartCurrent() {
        this.startEvent.emit();
    }
    onUndo() {
        this.undoEvent.emit();
    }
    onDelete() {
        this.deleteEvent.emit();
    }
    onRedo() {
        this.redoEvent.emit();
    }
    onClone() {
        this.cloneEvent.emit();
    }

    onZoomSliderChanged(event: number) {
        this.zoomEvent.emit(event);
    }

    formatZoomLabel(value: number) {
        return value + ' %';
    }

    onToggleGrid = () => {
        this.showGridDialog();
    };

    showGridDialog = () => {
        const dialogRef = this.dialog.open(DialogGridComponent, {
            data: this.gridData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.gridData = result;
                this.UpdateSavedGridData();
            }
        });
    };

    CanvasReady = () => {
        this.UpdateSavedGridData();
    };

    UpdateSavedGridData = () => {
        const min: number = 1;
        const max: number = 999;
        if (this.gridData.grid_spacing < min) {
            this.gridData.grid_spacing = min;
        }
        if (max < this.gridData.grid_spacing) {
            this.gridData.grid_spacing = max;
        }
        localStorage.setItem('grid_data', JSON.stringify(this.gridData));
        this.canvas.curConfig.gridSnapping = true;
        this.canvas.curConfig.snappingStep =
            this.gridData.grid_spacing > 0 ? this.gridData.grid_spacing : 1;
        this.canvas.runExtensions('gridUpdate');
    };

    onDevice = () => {
        this.navigationService.toDevice(this.currentProject.id);
    };

    showLoadingOverlay() {
        if (!this.isOverlayVisible) {
            this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));
            this.isOverlayVisible = true;
        }
    }

    hideLoadingOverlay() {
        this.overlayRef.detach();
        this.isOverlayVisible = false;
    }

    openFuSettings() {
        const projectId = this.projectService.getProjectId();
        this.router.navigate(['/editor', projectId, 'fu-settings']);
    }

    openRunHourSettings() {
        const projectId = this.projectService.getProjectId();
        this.router.navigate(['/editor', projectId, 'run-hour-settings']);
    }

    openReposeSettings() {
        const projectId = this.projectService.getProjectId();
        this.router.navigate(['/editor', projectId, 'repose-settings']);
    }

    openGraphSettings() {
        const projectId = this.projectService.getProjectId();
        this.router.navigate(['/editor', projectId, 'graph-settings']);
    }

    getEditorName() {
        if (!this.currentView) return '';
        let editingView = this.editFlg.getViewEditingMap().get(this.currentView.id);
        return editingView ? editingView.editor.name : '';
    }

    //It is triggered when the user forgets to click 'Finish Edit' (e.g., when opening another project or saving the project as).
    private async updateView() {
        this.currentView.svgcontent = this.canvas.getSvgString();
        this.projectService.setView(this.currentProject, this.currentView, false);
        const editor = this.authService.getCurrentUser();
        await this.viewsService.unlockView(
            this.currentView.id,
            this.currentProject.id,
            editor.name,
            editor.oid,
        );
    }

    async openConfirmDialog(confirmData: ConfirmationDialogData): Promise<string | undefined> {
        const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            position: { top: '60px' },
            data: {
                title: confirmData.title,
                okButtonText: confirmData.okButtonText,
                okButtonType: confirmData.okButtonType,
                noButtonText: confirmData.noButtonText,
                noButtonType: confirmData.noButtonType,
                confirmMessage: confirmData.confirmMessage,
            },
        });

        return await firstValueFrom(dialogRef.afterClosed());
    }
}
