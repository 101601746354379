import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-channel-diff',
    templateUrl: './channel-diff.component.html',
    styleUrls: ['./channel-diff.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelDiffComponent {
    @Input() diffItemRow: {
        key: string;
        local: any;
        blob: any;
        flattenKey: string;
    };
    @Input() rowNum: number;
    @Input() operationType: string;

    constructor() {}
}
