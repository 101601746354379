import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelCompareService } from '@service/channel-compare.service';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import {
    ChannelCompare,
    GroupCompare,
    ChannelOperation,
} from 'icreate/common/models/channel-compare';
import * as BlobUtil from 'blob-util';
import { ProjectData } from 'icreate/common/models/project';

@Component({
    selector: 'app-channel-compare-dialog',
    templateUrl: './channel-compare-dialog.component.html',
    styleUrls: ['./channel-compare-dialog.component.css'],
})
export class ChannelCompareDialogComponent {
    channelVersionList = [];
    targetVersion = '';
    channelDiffArray: ChannelCompare[] = [];
    groupsDiffArray: GroupCompare[] = [];
    chunkedDiffArray: any = [];
    channelCompareCount = {
        add: 0,
        update: 0,
        delete: 0,
    };

    groups = 'groups';
    channels = 'channels';

    groupsCompareCount = {
        add: 0,
        update: 0,
        delete: 0,
    };

    totalPages = 0;
    private pageSize = 10;
    currentPage = 1;

    currentValue = '';
    diffMsg = '';
    selectedType = this.channels;
    currentProject: ProjectData = new ProjectData();

    constructor(
        private channelCompareService: ChannelCompareService,
        @Inject(MAT_DIALOG_DATA)
        public data: { versionList: Array<string>; currentProject: ProjectData },
        public dialogRef: MatDialogRef<ChannelCompareDialogComponent>,
    ) {
        this.channelVersionList = this.data.versionList;
        this.currentProject = this.data.currentProject;
    }

    onSwitchType(key: string) {
        this.selectedType = key;
        this.chunkedDiffArray = [];
        if (this.selectedType == this.groups) {
            this.chunkedDiffArray = this.groupsDiffArray.slice(0, this.pageSize);
        } else {
            this.chunkedDiffArray = this.channelDiffArray.slice(0, this.pageSize);
        }
    }

    async onCompareClick() {
        try {
            const response = await this.channelCompareService.getChannelDiff(
                this.currentProject.id,
                this.currentProject.VesselID,
                this.currentProject.VesselBuilderCompanyID,
                this.targetVersion,
            );

            this.channelDiffArray = response.channels.differenceItems;
            this.groupsDiffArray = response.groups.differenceItems;

            this.chunkedDiffArray = this.channelDiffArray.slice(0, this.pageSize);
            this.totalPages = Math.ceil(this.channelDiffArray.length / this.pageSize);
            this.channelCompareCount = response.channels.compareCount;
            this.groupsCompareCount = response.groups.compareCount;
        } catch (error) {
            if (error.status === 304) {
                this.channelDiffArray = [];
                this.groupsDiffArray = [];
                this.diffMsg = 'There are no differences.';
            }
        }
    }

    loadMoreData() {
        if (this.currentPage < this.totalPages) {
            const startIndex = this.currentPage * this.pageSize;
            const endIndex = startIndex + this.pageSize;
            let next;
            if (this.selectedType === this.channels) {
                next = this.channelDiffArray.slice(startIndex, endIndex);
            } else {
                next = this.groupsDiffArray.slice(startIndex, endIndex);
            }
            this.chunkedDiffArray = this.chunkedDiffArray.concat(next);

            this.currentPage++;
        }
    }

    createUpdateDiffObject(blob: any, local: any, modifiedFields: string[]): any {
        let result = {};
        for (let j = 0; j < modifiedFields.length; j++) {
            const newValue = _.get(local, modifiedFields[j]);
            const oldValue = _.get(blob, modifiedFields[j]);
            const obj = {
                new: `++++++++ ${newValue}`,
                old: `-------- ${oldValue}`,
            };

            result = _.set(_.cloneDeep(blob), modifiedFields[j], obj);
        }
        return result;
    }

    stringifyDiffArray(diffArray: any[]): string {
        return JSON.stringify(diffArray, null, 2);
    }

    //In the future, consider changing to a better file format.
    download() {
        const updateChannelDiffArray = [];
        const deleteChannelDiffArray = [];
        const addChannelDiffArray = [];

        for (let i = 0; i < this.channelDiffArray.length; i++) {
            const diffItem = this.channelDiffArray[i];

            if (diffItem.operationType === ChannelOperation.UPDATE) {
                const updateObj = this.createUpdateDiffObject(
                    diffItem.blob,
                    diffItem.local,
                    diffItem.modifiedFields,
                );
                updateChannelDiffArray.push(updateObj);
            } else if (diffItem.operationType === ChannelOperation.ADD) {
                addChannelDiffArray.push(diffItem.local);
            } else {
                deleteChannelDiffArray.push(diffItem.blob);
            }
        }

        const updateDiffString = this.stringifyDiffArray(updateChannelDiffArray);
        const addDiffString = this.stringifyDiffArray(addChannelDiffArray);
        const deleteDiffString = this.stringifyDiffArray(deleteChannelDiffArray);

        const text = `### UPDATE\n${updateDiffString}\n### NEW\n${addDiffString}\n### DELETE\n${deleteDiffString}`;

        const blob = BlobUtil.createBlob([text], { type: 'text/txt' });
        saveAs(blob, 'channel.txt');
    }
}
