import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommentsService } from '@service/comments.service';
import { EditFlgService } from '@service/editflg.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.css'],
})
export class CommentsComponent {
    @Input() creatingComment;
    @Input() comments;
    @Input() positionBackground;
    @Input() newCommentPosition;
    @Input() viewId;
    @Output() onWritingComment = new EventEmitter();
    movingComment: boolean = false;

    constructor(
        private commentService: CommentsService,
        private editFlg: EditFlgService,
    ) {}

    deleteComment(event) {
        this.commentService.delete(event);
    }

    updateComment(event) {
        const user = this.editFlg.clientAccountName;
        event.userName = user;
        this.commentService.update(event);
    }

    writingComment(writingComment: Boolean) {
        this.onWritingComment.emit(writingComment);
    }

    saveComment(event) {
        const timestamp = dayjs();
        const commentData = JSON.parse(event);
        const user = this.editFlg.clientAccountName;
        const comment = {
            timestamp: timestamp,
            positionTop: commentData.positionTop,
            positionLeft: commentData.positionLeft,
            commentText: commentData.comment,
            userName: user,
            viewId: this.viewId,
        };
        this.commentService.create(comment);
        this.onWritingComment.emit({ creatingComment: true });
    }

    movingElement(event) {
        this.movingComment = event;
    }
}
