<div class="sidebar">
  <div class="top">
    <a class="disabled" [routerLinkActive]="'link-active'">
      <mat-icon svgIcon="dashboard" />
    </a>
    <a class="disabled" [routerLinkActive]="'link-active'">
      <mat-icon svgIcon="graph" />
    </a>
    <a (click)="toggleDropdown()" class="dropdown link_active">
      <mat-icon svgIcon="mimics" />
      <div
        #mimicdropdown
        class="dropdown-content"
        [style.display]="dropdownStyle"
      >
        <span class="dropdown-title">MIMICS</span>
        <a *ngFor="let v of viewsListArray">
          <div
            *ngIf="v.type === 'container'"
            (click)="stopPropagation($event); groupSelected(v)"
            [ngClass]="{
              link_active: isgroupActive(v.name)
            }"
          >
            <span class="dropdown-name">{{ v.name }}</span>
          </div>
          <a
            *ngIf="v.type === 'view'"
            [routerLinkActive]="'link_active'"
            (click)="stopPropagation($event); toggleDropdown()"
            [queryParams]="{ id: v.data.id }"
            class="view-container"
          >
            <mat-icon class="view-icon"> insert_drive_file </mat-icon>
            <span class="dropdown-name">{{ asView(v.data).name }}</span>
          </a>
        </a>
      </div>
      <app-mimics-groups
        *ngIf="showSubviews"
        [groupData]="selectedGroup.views"
        (stopPropagationEvent)="stopPropagation($event)"
        (toggleDropdownEvent)="toggleDropdown()"
      >
      </app-mimics-groups>
    </a>

    <a class="disabled">
      <mat-icon svgIcon="alarm-page" />
    </a>
    <a class="disabled">
      <mat-icon svgIcon="channel-list" />
    </a>
    <!-- <a
              [routerLink]="'/ops/status'" 
              class="disabled"
              [ngClass]="{
                  link_active: isActiveLink('/ops/status')
              }"
          > -->
    <mat-icon svgIcon="systemStatus" />
    <!-- </a> -->
  </div>
  <div class="bottom">
    <a class="disabled">
      <mat-icon svgIcon="setting" />
    </a>
  </div>
</div>
