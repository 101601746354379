<div class="wrapper">
    <h1 mat-dialog-title style="display:inline-block;cursor:move;">New Company Registration</h1>
    <form [formGroup]="reactiveForm">
        <div class="container">
          <div class="form">
            <label for="name">Company Name (*Required)</label><br>
            <!-- <span *ngIf="ctrl('name').hasError('dirtyAndNull')">*Required</span> -->
            <!-- <span *ngIf="ctrl('name').hasError('required') && ctrl('name').touched">*Required</span> -->
            <input type="text" id="name" formControlName="name">
          </div>
          <div class="form">
            <label for="kana">Other Name</label><br>
            <input type="text" id="othername" formControlName="othername">  
          </div>
          <div class="form">
            <label for="ceo">CEO</label><br>
            <input type="text" id="ceo" formControlName="ceo">  
          </div>
          <div class="form">
            <label for="address">Address</label><br>
            <input type="text" id="address" formControlName="address">  
          </div>
          <div class="form">
            <label for="telephone">Telephone</label><br>
            <input type="text" id="telephone" formControlName="telephone">  
          </div>
          <div class="form">
            <label for="fax">FAX</label><br>
            <input type="text" id="fax" formControlName="fax">  
          </div>
          <div class="form">
            <label for="email">E-mail</label><br>
            <input type="text" id="email" formControlName="email">  
          </div>
          
        </div>
        <div class="register-button">
          <button (click)="onRegister()" [disabled]="reactiveForm.invalid">Register</button>
        </div>
        <div class="cancel-button">
          <button (click)="onCancel()">Cancel</button>
        </div>
      </form>
</div>

