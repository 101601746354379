import { Component, OnInit } from '@angular/core';
import { GraphService } from '@service/graphs/graph.service';
import { PrintGraphsService } from '@service/graphs/print-graphs.service';
import { ProjectService } from '@service/project.service';
import { Graph } from 'common/models/graphs';
import { ProjectData } from 'icreate/common/models/project';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
    selector: 'app-print-graphs',
    templateUrl: './print-graphs.component.html',
    styleUrls: ['./print-graphs.component.scss'],
})
export class PrintGraphsComponent implements OnInit {
    constructor(
        private projectService: ProjectService,
        private graphsService: GraphService,
        private printGraphsService: PrintGraphsService,
    ) {
        (window as any).pdfMake.vfs = pdfFonts?.pdfMake.vfs;
    }

    style = {
        display: 'none',
    };
    isLoadingPdf: boolean = false;
    compareVersionGraphs: boolean = false;
    projectVersion: string;
    projectGraphs: Graph[];
    graphList = [];
    selectedGraphs = [];

    printButtonIsDisabled: boolean = false;

    ngOnInit(): void {
        this.printGraphsService.onPrintGraphs.subscribe(async () => {
            const projectId = this.projectService.getProjectId();
            this.graphList = [];
            this.projectGraphs = await this.graphsService.getAll(projectId);
            for (let i = 0; i < this.projectGraphs.length; i++) {
                this.graphList.push({
                    title: this.projectGraphs[i].title,
                    checked: false,
                    id: this.projectGraphs[i].id,
                });
            }
            this.style.display = 'flex';
        });
    }

    handleSelectedGraphChanged(newSelectedGroups) {
        this.selectedGraphs = newSelectedGroups;
    }

    async printGraphs() {
        const selectedGraphsIdsSet = new Set();
        const graphsToPrint = [];
        this.isLoadingPdf = true;
        this.selectedGraphs.map((graph) => {
            selectedGraphsIdsSet.add(graph.id);
        });

        this.projectGraphs.map((graph) => {
            if (selectedGraphsIdsSet.has(graph.id)) {
                graphsToPrint.push(graph);
            }
        });

        let versionsDifferences = {};
        if (this.compareVersionGraphs) {
            if (this.projectVersion) {
                versionsDifferences = await this.getGraphsDifference();
                console.log("versionsDifferences", versionsDifferences)
            } else {
                console.error('no project version selected');
            }
        }

        const pdfData = await this.printGraphsService.getPdpFile(
            graphsToPrint,
            versionsDifferences
        );
        this.isLoadingPdf = false;
        pdfMake.createPdf(pdfData).print();
        this.clearDataAfterPrint();
    }

    async getGraphsDifference() {
        const projectId = this.projectService.getProjectId();
        const projectData: ProjectData = await this.projectService.getProjectData(projectId);
        try {
            const differences = await this.graphsService.getGraphsDiff(
                projectId,
                projectData.VesselID,
                projectData.VesselBuilderCompanyID,
                this.projectVersion,
            );
            return differences;
        } catch (err) {
            if (err.status === 304) {
                return {};
            } else {
                console.error(err);
            }
        }
    }

    projectVersionSelected(projectVersion) {
        this.projectVersion = projectVersion;
    }

    exit() {
        this.clearDataAfterPrint();
        this.style.display = 'none';
    }

    clearDataAfterPrint() {
        this.graphList.map((graph) => {
            if (graph.checked) {
                graph.checked = false;
            }
        });
        this.projectVersion = undefined;
        this.compareVersionGraphs = false;
        this.selectedGraphs = [];
    }

    disablePrintButton(shouldDisable) {
        this.printButtonIsDisabled = shouldDisable;
    }

    shouldDisablePrintButton() {
        if (this.selectedGraphs.length === 0) {
            return true;
        }
        if (this.compareVersionGraphs && !this.projectVersion) {
            return true;
        }
        return this.printButtonIsDisabled;
    }
}
