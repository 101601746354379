import { Injectable, OnDestroy } from '@angular/core';
import { CanvasService } from './canvas.service';
import { IcreateWindowReferenceService } from './icreate-window-reference.service';

@Injectable()
export class MoveElementsService implements OnDestroy {
    private isOustideWorkArea: boolean = false;
    private numPxToMove: number = 5;

    constructor(
        private canvas: CanvasService,
        private windowElements: IcreateWindowReferenceService,
    ) {}

    init(): void {
        this.bindMoveEvents();
    }

    ngOnDestroy(): void {
        this.unbindMoveEvents();
    }

    private bindMoveEvents() {
        const workArea: any = this.windowElements.getWorkArea();
        document.addEventListener('mouseup', this.handleMouseUp.bind(this));
        document.addEventListener('mousemove', this.handleMouseMove.bind(this));
        workArea.nativeElement.addEventListener(
            'mouseleave',
            this.handleMouseLeave.bind(this),
        );
        workArea.nativeElement.addEventListener(
            'mouseenter',
            this.handleMouseEnter.bind(this),
        );
    }

    private unbindMoveEvents() {
        const workArea: any = this.windowElements.getWorkArea();
        document.removeEventListener('mouseup', this.handleMouseUp);
        workArea.nativeElement.removeEventListener(
            'mouseleave',
            this.handleMouseLeave,
        );
        workArea.nativeElement.removeEventListener(
            'mouseenter',
            this.handleMouseEnter,
        );
    }

    private handleMouseUp(event: MouseEvent) {
        const canvas = this.canvas.getCanvas();
        if (this.isOustideWorkArea && canvas.started) {
            canvas.mouseUpEvent(event);
            this.isOustideWorkArea = false;
        }
    }

    private handleMouseMove(event: MouseEvent) {
        if (event.clientX >= screen.width - 1) {
            const canvas = this.canvas.getCanvas();
            if (canvas.started) {
                this.isOustideWorkArea = true;
                this.moveCanvas(event);
            }
        }
    }
    private moveWorkArea(event) {
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        const workArea: any = this.windowElements.getWorkArea();
        const workAreaRect = workArea.nativeElement.getBoundingClientRect();
        const propertiesBar = document.getElementsByClassName('svg-tools-fly');
        const propertiesAreaRect = propertiesBar[0].getBoundingClientRect();
        const editor = this.windowElements.getEditorContainer();
        const startX = this.canvas.getCanvas().getStartX();
        const rStartX = this.canvas.getCanvas().getRStartX();
        const startY = this.canvas.getCanvas().getStartY();
        const rStartY = this.canvas.getCanvas().getRStartY();

        if (mouseX < workAreaRect.left) {
            editor.style.left = editor.offsetLeft + this.numPxToMove + 'px';
            this.canvas.getCanvas().setRStartX(rStartX + this.numPxToMove);
            this.canvas.getCanvas().setStartX(startX + this.numPxToMove);
        }
        if (mouseX > workAreaRect.right || mouseX > propertiesAreaRect.left) {
            editor.style.left = editor.offsetLeft - this.numPxToMove + 'px';
            this.canvas.getCanvas().setRStartX(rStartX - this.numPxToMove);
            this.canvas.getCanvas().setStartX(startX - this.numPxToMove);
        }
        if (mouseY < workAreaRect.top) {
            editor.style.top = editor.offsetTop + this.numPxToMove + 'px';
            this.canvas.getCanvas().setStartY(startY + this.numPxToMove);
            this.canvas.getCanvas().setRStartY(rStartY + this.numPxToMove);
        }
        if (mouseY > workAreaRect.bottom) {
            editor.style.top = editor.offsetTop - this.numPxToMove + 'px';
            this.canvas.getCanvas().setStartY(startY - this.numPxToMove);
            this.canvas.getCanvas().setRStartY(rStartY - this.numPxToMove);
        }
    }

    private handleMouseLeave(event) {
        const canvas = this.canvas.getCanvas();
        if (canvas.started) {
            this.isOustideWorkArea = true;
            this.moveCanvas(event);
        }
    }

    private handleMouseEnter() {
        this.isOustideWorkArea = false;
    }

    private moveCanvas(event) {
        const canvas = this.canvas.getCanvas();
        if (canvas.started && this.isOustideWorkArea) {
            this.moveWorkArea(event);
            canvas.mouseMoveEvent(event);
            requestAnimationFrame(() => this.moveCanvas(event));
        }
    }
}
