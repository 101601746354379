<div *ngFor="let change of changesInProject">
    <div *ngIf="currentViewId === change.viewId">
        <div [ngStyle]="{
            top: change.y + 'px',
            left: change.x + 'px',
            width: change.width + 'px',
            height: change.height + 'px',
            position: 'absolute',
            border: 'solid red 2px',
            transform: change.transform
        }"
        class="changesClass">
        </div>
    </div>
</div>