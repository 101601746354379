import { Channel } from 'common/models/channel';
import { GaugeGraphChannel } from 'common/models/graphs';
import * as echarts from 'echarts';

export async function printGaugeGraph(graph, projectChannels, graphDifferences) {
    const gaugeChannels: GaugeGraphChannel[] = graph.ANALOG_METER.channels;

    if (!gaugeChannels || gaugeChannels.length === 0) {
        return;
    }

    const channelsIdsMap: Map<number, GaugeGraphChannel> = new Map();
    gaugeChannels.forEach((channel: GaugeGraphChannel) => {
        channelsIdsMap.set(channel.fcuChannelId, channel);
    });

    const graphChannels = getGraphChannels(channelsIdsMap, projectChannels);
    const titleBorder = graphDifferences?.oldTitle
        ? [true, true, true, true]
        : [false, false, false, false];

    const newGraphChannelsSet = new Set<number>();

    graphDifferences?.channelsChanges?.added?.forEach((channel: GaugeGraphChannel) => {
        newGraphChannelsSet.add(channel.fcuChannelId);
    });

    const firstCell = getTableCell(graphChannels[0], newGraphChannelsSet);
    const secondCell = getTableCell(graphChannels[1], newGraphChannelsSet);
    const thirdCell = getTableCell(graphChannels[2], newGraphChannelsSet);
    const fourthCell = getTableCell(graphChannels[3], newGraphChannelsSet);
    const fifthCell = getTableCell(graphChannels[4], newGraphChannelsSet);
    const sixthCell = getTableCell(graphChannels[5], newGraphChannelsSet);
    const seventhCell = getTableCell(graphChannels[6], newGraphChannelsSet);
    const eighthCell = getTableCell(graphChannels[7], newGraphChannelsSet);

    const tableContent = [
        [
            {
                table: {
                    margin: [10, 10],
                    body: [
                        [
                            {
                                text: graph.title,
                                border: titleBorder,
                                borderColor: ['red', 'red', 'red', 'red'],
                            },
                        ],
                    ],
                },
                margin: [10, 10],
            },
            {
                columns: [
                    { width: '*', text: '' },
                    {
                        width: 'auto',
                        table: {
                            margin: [100, 100, 100, 100],
                            heights: [30, 165, 165],
                            widths: [165, 165, 165, 165],

                            body: [
                                [
                                    { text: '', border: [false, false, false] },
                                    { text: '', border: [false, false, false] },
                                    { text: '', border: [false, false, false] },
                                    { text: '', border: [false, false, false] },
                                ],
                                [firstCell, secondCell, thirdCell, fourthCell],
                                [fifthCell, sixthCell, seventhCell, eighthCell],
                            ],
                            alignment: 'center',
                        },
                    },
                    { width: '*', text: '' },
                ],
            },
        ],
    ];

    return {
        body: [tableContent],
        widths: [763],
        heights: [483],
    };
}

function getGraphChannels(channelsIdsMap: Map<number, GaugeGraphChannel>, projectChannels: Channel[]) {
    const graphChannels = [];
    projectChannels.forEach((channel) => {
        if (channelsIdsMap.has(channel.fcuData.fcuChannelId)) {
            const gaugeChannel = channelsIdsMap.get(channel.fcuData.fcuChannelId)
            const division = gaugeChannel.divisions
            graphChannels.push({channel, division});
        }
    });
    return graphChannels;
}

function getTableCell(channel: {channel: Channel, division: number}, newGraphChannelsSet: Set<number>) {
    if (!channel) {
        return { text: '' };
    } else {
        const dataUrl = getDataUrl(channel.channel, channel.division);
        let borderColor = [];
        if (newGraphChannelsSet.has(channel.channel.fcuData.fcuChannelId)) {
            borderColor = ['red', 'red', 'red', 'red'];
        }
            return {
                stack: [
                    {
                        image: dataUrl,
                        width: 165,
                        height: 165,
                        alignment: 'center',
                    },
                    {
                        text: channel.channel.opsData.name,
                        alignment: 'center',
                    },
                ],
                borderColor: borderColor,
            };
    }
}

function getDataUrl(channel: Channel, divisions: number) {
    let gaugeGraphDivision = document.createElement('div');
    gaugeGraphDivision.style.height = '220pt';
    gaugeGraphDivision.style.width = '190pt';
    let myChart = echarts.init(gaugeGraphDivision);
    let option;

    let randomNumber =
        Math.floor(
            Math.random() *
                (channel.fcuData.measurableRange.max - channel.fcuData.measurableRange.min + 1),
        ) + channel.fcuData.measurableRange.min;

    option = {
        color: ['black'],
        series: [
            {
                type: 'gauge',
                radius: '100%',
                detail: {
                    formatter: (value) => {
                        return `${channel.opsData.tag}\n ${channel.opsData.measurementUnit}`;
                    },
                    offsetCenter: [0, '65%'],
                    fontSize: 10,
                },
                pointer: {
                    itemStyle: {
                        color: 'black',
                    },
                    show: true,
                    width: 3,
                },
                axisTick: {
                    length: 5,
                    lineStyle: {
                        color: 'auto',
                        width: 3,
                    },
                },
                axisLabel: {
                    distance: 15,
                    fontSize: 8,
                    color: 'black',
                    formatter: (value) => {
                        if (value % 1 != 0) {
                            return value.toFixed(2).toString();
                        }
                        return value.toFixed(0)
                    },
                },
                splitNumber: divisions,
                splitLine: {
                    length: 15,
                    lineStyle: {
                        color: 'auto',
                        width: 3,
                    },
                },
                data: [
                    {
                        value: randomNumber,
                    },
                ],
                min: channel.fcuData.measurableRange.min,
                max: channel.fcuData.measurableRange.max,
            },
        ],
    };

    myChart.setOption(option);

    const dataUrl = myChart.getDataURL({
        type: 'png',
        pixelRatio: 1,
        backgroundColor: '#fff',
    });

    return dataUrl;
}
