import { ObjectSchema, array, boolean, mixed, number, object, string } from 'yup';
import { Device, deviceTypeArray, Tag, TagDaq } from '../../models/device';
import * as yup from 'yup';
import '../custom-method';

import { locale } from '../yup-locale';

yup.setLocale(locale);

export const deviceValidator: ObjectSchema<Device> = object({
    id: string().nonNullable().strict(),
    name: number().nonNullable().strict().required(),
    enabled: boolean().nonNullable().strict(),
    property: array()
        .of(
            object({
                amsaddress: string()
                    .isIp('errors.validation.generic.not-valid-Ip')
                    .nonNullable()
                    .required()
                    .strict(),
                amsport: number().nonNullable().required().strict(),
                localaddress: string()
                    .isIp('errors.validation.generic.not-valid-Ip')
                    .nonNullable()
                    .required()
                    .strict(),
                localport: number().nonNullable().required().strict(),
            })
                .noUnknown()
                .nonNullable()
                .strict()
                .required(),
        )
        .min(2)
        .max(2)
        .nonNullable()
        .strict()
        .required(),
    type: string().oneOf(deviceTypeArray).nonNullable().strict().required(),
    polling: number().nonNullable().strict(),
    tags: mixed().nonNullable().strict(),
})
    .noUnknown()
    .strict();

export const tagDaqValidator: ObjectSchema<TagDaq> = object({
    enabled: boolean().required().nonNullable().strict(),
    interval: number().required().nonNullable().strict(),
    changed: boolean().required().nonNullable().strict(),
})
    .noUnknown()
    .strict();

export const tagValidator: ObjectSchema<Tag> = object({
    id: string().nonNullable().strict(),
    name: string().nonNullable().strict(),
    label: string().nonNullable().strict(),
    value: string().nonNullable().strict(),
    type: string().nonNullable().strict(),
    memaddress: string().nonNullable().strict(),
    address: string().nonNullable().strict(),
    divisor: number().nonNullable().strict(),
    access: string().nonNullable().strict(),
    options: mixed().nonNullable().strict(),
    format: mixed().nonNullable().strict(),
    daq: object({
        enabled: boolean().required().nonNullable().strict(),
        interval: number().required().nonNullable().strict(),
        changed: boolean().required().nonNullable().strict(),
    })
        .nonNullable()
        .strict()
        .required()
        .noUnknown(),
})
    .noUnknown()
    .strict();
