<div
    *ngIf="isShow"
    class="dlg-wrap"
    (click)="onClose(); $event.preventDefault()"
>
    <div class="dlg-container" #dlgContents (click)="$event.stopPropagation()">
        <!--  *** CATEGORY ADD *** -->
        <div *ngIf="type == CATEGORY_ADD" class="dlg-contents">
            <div class="dlg-title">
                {{ "channel.add-category" | translate }}
            </div>
            <div class="dlg-inputs">
                <div class="dlg-input">
                    <app-channel-text-input
                        name="category"
                        label="Name"
                        [value]=""
                        (inputChange)="onInputChange($event)"
                    ></app-channel-text-input>
                </div>
                <div
                    class="error-container text light"
                    *ngIf="getErrorMsg('category', 'name')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span> {{ getErrorMsg("category", "name") }}</span>
                </div>
            </div>
            <div class="dlg-buttons">
                <button (click)="onClose()" class="primary">
                    {{ "dlg.cancel" | translate }}
                </button>
                <button
                    (click)="onOk()"
                    class="white"
                    [disabled]="isButtonDisable()"
                >
                    {{ "dlg.add" | translate }}
                </button>
            </div>
        </div>

        <!--  *** CATEGORY RENAME *** -->
        <div *ngIf="type == CATEGORY_RENAME" class="dlg-contents">
            <div class="dlg-title">
                {{ "channel.more-option.rename" | translate }}
            </div>
            <div class="dlg-inputs">
                <div class="dlg-input">
                    <app-channel-text-input
                        name="category"
                        label="Name"
                        [value]="channelCategory.category"
                        (inputChange)="onInputChange($event)"
                    ></app-channel-text-input>
                </div>
                <div
                    class="error-container text light"
                    *ngIf="getErrorMsg('category', 'name')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span> {{ getErrorMsg("category", "name") }}</span>
                </div>
            </div>
            <div class="dlg-buttons">
                <button (click)="onClose()" class="primary">
                    {{ "dlg.cancel" | translate }}
                </button>
                <button
                    (click)="onOk()"
                    class="white"
                    [disabled]="isButtonDisable()"
                >
                    {{ "dlg.rename" | translate }}
                </button>
            </div>
        </div>

        <!--  *** CATEGORY DELETE *** -->
        <div *ngIf="type == CATEGORY_DELETE" class="dlg-contents">
            <div class="dlg-title">
                {{ getTranslate("channel.delete-category.title") }}
            </div>
            <div class="dlg-buttons">
                <button (click)="onClose()" class="primary">
                    {{ "dlg.cancel" | translate }}
                </button>
                <button (click)="onOk()" class="white">
                    {{ "dlg.confirm" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
