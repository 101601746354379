<div style="overflow: scroll; flex: 1" (click)="unselectRow()">
    <table mat-table [dataSource]="tableDataSource" class="table">
        <ng-container matColumnDef="number">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="table-header index-header"
            ></th>
            <td
                mat-cell
                *matCellDef="let reposeSetting; let i = index"
                class="table-row index"
            >
                {{ i + 1 }}
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef class="table-header fixed-column">
                {{ "repose-settings.table.header.title" | translate }}
            </th>
            <td mat-cell *matCellDef="let reposeSetting" class="table-row fixed-column">
                <input
                    [(ngModel)]="reposeSetting.title"
                    maxlength="48"
                    class="title"
                    [value]="reposeSetting.title || ''"
                    placeholder="Repose Title"
                />
                <div
                    class="error-container text light"
                    *ngIf="getSettingErrors(reposeSetting, 'title')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span data-testid="errorSetPoint">
                        {{
                            getSettingErrors(reposeSetting, "title") | translate
                        }}</span
                    >
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="trigger">
            <th mat-header-cell *matHeaderCellDef class="table-header fixed-column">
                {{ "repose-settings.table.header.trigger" | translate }}
            </th>
            <td mat-cell *matCellDef="let reposeSetting" class="table-row fixed-column">
                <div
                    *ngIf="
                        selectedSettings !== reposeSetting &&
                        reposeSetting.trigger
                    "
                >
                    {{ reposeSetting.trigger }}
                </div>

                <app-search-channel-input
                    *ngIf="
                        selectedSettings == reposeSetting ||
                        !reposeSetting.trigger
                    "
                    [channels]="channels"
                    [channelNumber]="reposeSetting.trigger"
                    (onChannelSelected)="reposeSetting.trigger = $event"
                    [channelError]="getSettingErrors(reposeSetting, 'trigger')"
                ></app-search-channel-input>
            </td>
        </ng-container>

        <ng-container matColumnDef="mask">
            <th mat-header-cell *matHeaderCellDef class="table-header fixed-column">
                {{ "repose-settings.table.header.mask" | translate }}
            </th>
            <td mat-cell *matCellDef="let reposeSetting" class="table-row fixed-column">
                <input
                    type="number"
                    class="mask"
                    [value]="reposeSetting.mask"
                    (input)="
                        reposeSetting.mask = inputLimit(
                            $event,
                            reposeSetting.mask
                        )
                    "
                />
                <div
                    class="error-container text light"
                    *ngIf="getSettingErrors(reposeSetting, 'mask')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span data-testid="errorSetPoint">
                        {{
                            getSettingErrors(reposeSetting, "mask") | translate
                        }}</span
                    >
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="delayOn">
            <th mat-header-cell *matHeaderCellDef class="table-header fixed-column">
                {{ "repose-settings.table.header.delay-on" | translate }}
            </th>
            <td mat-cell *matCellDef="let reposeSetting" class="table-row fixed-column">
                <input
                    type="number"
                    min="1"
                    class="delayOn"
                    [value]="reposeSetting.delayOn"
                    (input)="
                        reposeSetting.delayOn = inputLimit(
                            $event,
                            reposeSetting.delayOn
                        )
                    "
                />
                <div
                    class="error-container text light"
                    *ngIf="getSettingErrors(reposeSetting, 'delayOn')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span data-testid="errorSetPoint">
                        {{
                            getSettingErrors(reposeSetting, "delayOn")
                                | translate: { min: 1 }
                        }}</span
                    >
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="delayOff">
            <th mat-header-cell *matHeaderCellDef class="table-header fixed-column">
                {{ "repose-settings.table.header.delay-off" | translate }}
            </th>
            <td mat-cell *matCellDef="let reposeSetting" class="table-row fixed-column">
                <input
                    type="number"
                    min="1"
                    class="delayOff"
                    [value]="reposeSetting.delayOff"
                    (input)="
                        reposeSetting.delayOff = inputLimit(
                            $event,
                            reposeSetting.delayOff
                        )
                    "
                />
                <div
                    class="error-container text light"
                    *ngIf="getSettingErrors(reposeSetting, 'delayOff')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span data-testid="errorSetPoint">
                        {{
                            getSettingErrors(reposeSetting, "delayOff")
                                | translate: { min: 1 }
                        }}</span
                    >
                </div>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="table-header"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selectRow(row); $event.stopPropagation()"
            [ngStyle]="{
                'background-color':
                    selectedSettings === row ? 'rgba(48, 89, 175, 1)' : ''
            }"
        ></tr>
    </table>
</div>
