import { ObjectSchema, array, number, object, string } from 'yup';
import { ExhaustGraph, ExhaustChannel, TurboChargerChannel } from '../../../models/graphs';
import { locale } from './../../yup-locale';
import * as yup from 'yup';
yup.setLocale(locale);

export function TurboChargerChannelValidator(): ObjectSchema<TurboChargerChannel> {
    return object({
        fcuChannelId: number().integer().required().nonNullable().strict(),
        title: string().nonNullable().strict().required(),
    })
        .required()
        .strict()
        .noUnknown()
        .nonNullable() as ObjectSchema<TurboChargerChannel>;
}

export function ExhaustChannelValidator(): ObjectSchema<ExhaustChannel> {
    return object({
        fcuChannelId: number().integer().required().nonNullable().strict(),
        deviationChannel: number().integer().required().nonNullable().strict(),
        title: string().nonNullable().strict().required(),
    })
        .required()
        .strict()
        .noUnknown()
        .nonNullable() as ObjectSchema<ExhaustChannel>;
}

export function ExhaustGraphValidator(): ObjectSchema<ExhaustGraph> {
    return object({
        id: string().uuid().nonNullable().strict().required(),
        deviationLimit: number()
            .integer()
            .nonNullable()
            .strict()
            .required()
            .test('not-zero', 'graph-settings.graph.error.not-zero', (value) => value !== 0),
        averageChannel: number().integer().nonNullable().strict().required(),
        turboChargerTitle: string().nonNullable().strict().required(),
        turboChargerChannels: array()
            .of(TurboChargerChannelValidator())
            .required()
            .nonNullable()
            .strict(),
        exhaustChannels: array().of(ExhaustChannelValidator()).required().nonNullable().strict(),
        graphsId: string().notRequired(),
    })
        .required()
        .strict()
        .noUnknown()
        .nonNullable() as ObjectSchema<ExhaustGraph>;
}
