import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { lastValueFrom } from 'rxjs';
import { EndPointApi } from '@helpers/endpointapi';
import { ProjectService } from './project.service';

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    public newDevice: Boolean = false;
    private domain: string;

    device;
    constructor(
        private http: HttpClient,
        private projectService: ProjectService,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    setNewDevice(boolean): Observable<any> {
        return (this.newDevice = boolean);
    }

    setDevice(device) {
        return (this.device = device);
    }

    public getFcuIds(projectId: string): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/channels/fcu`, {
                params: { projectId: projectId },
            }),
        );
    }
}
