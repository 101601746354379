<div>
  <app-infinite-scroll (scrolled)="loadMoreData()">
    <ul>
      <li (click)="onSwitchType(channels)">channels</li>
      <li *ngIf="groupsDiffArray.length > 0" (click)="onSwitchType(groups)">
        groups
      </li>
    </ul>
    <div class="merge-container">
      <app-channel-compare-header
        [selectedVersion]="version"
        [channelCompareCount]="compareCount"
      >
      </app-channel-compare-header>
      <div
        *ngFor="let diffItem of this.chunkedDiffArray; index as i"
        class="merge-item-container"
      >
        <table [ngClass]="diffItem.operationType">
          <app-channel-merge
            [blob]="diffItem.blob"
            [local]="diffItem.local"
            [rowNum]="i"
            (resolvedClick)="
              diffItem.operationType === update
                ? onResolvedClick($event)
                : onAccept($event)
            "
            [operationType]="diffItem.operationType"
            [id]="diffItem.local.id"
          >
          </app-channel-merge>
        </table>
      </div>
    </div>
  </app-infinite-scroll>
</div>

<button mat-raised-button color="primary" (click)="onMergeDone()">
  {{ "dlg.ok" | translate }}
</button>
