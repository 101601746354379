import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { EndPointApi } from '@helpers/endpointapi';

@Injectable({
    providedIn: 'root',
})
export class IapiService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    //need to optimize...
    companyListCache = [];

    constructor(
        private http: HttpClient,
        public dialog: MatDialog,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    getVesselList(builderName: string): Promise<any> {
        const param = {
            //vesselBuilder: builderName
            vesselBuilder: builderName,
        };

        return lastValueFrom(
            this.http.get(`${this.domain}/api/vessel`, {
                headers: this.headers,
                params: param,
            }),
        );
    }

    getCompanyList(): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/company`, {
                headers: this.headers,
            }),
        );
    }

    getVessel(id: string): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/vessel/${id}`, {
                headers: this.headers,
            }),
        );
    }

    removeNullElement(element: any) {
        if (element.name === null) {
            return false;
        }
        return true;
    }

    getBuilderList(companyList: any) {
        const builderList = companyList.filter((element) => element.typeBuilder === 1);
        return builderList;
    }

    compareForSort(vessel1: any, vessel2: any) {
        let r = 0;
        if (vessel1.name < vessel2.name) {
            r = -1;
        } else if (vessel1.name > vessel2.name) {
            r = 1;
        }

        return r;
    }

    setCache(list) {
        this.companyListCache = list;
    }

    clearCache() {
        this.companyListCache = [];
    }

    public getChannels(projectId: string): Observable<any> {
        const param = {
            projectId: projectId,
        };
        return this.http.get(`${this.domain}/api/channelConfig`, {
            params: param,
        });
    }
}
