import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export interface FlagOptions {
    displayName: string;
    checked: boolean;
    color: ThemePalette;
    subFlagsArray?: any;
}
export interface Flags {
    alarm: FlagOptions;
    cancel: FlagOptions;
    unAck: FlagOptions;
}

export class subFlags {
    constructor(
        public color,
        public subFlag = [
            { displayName: 'highHigh', checked: false, color: color },
            { displayName: 'high', checked: false, color: color },
            { displayName: 'lowLow', checked: false, color: color },
            { displayName: 'low', checked: false, color: color },
            { displayName: 'over', checked: false, color: color },
            { displayName: 'under', checked: false, color: color },
        ],
    ) {}
}

@Component({
    selector: 'app-tester-check-options',
    templateUrl: './tester-check-options.component.html',
    styleUrls: ['./tester-check-options.component.css'],
})
export class TesterCheckOptionsComponent implements OnInit {
    @Input() item;

    @Output() sendCheckedOptions = new EventEmitter<string>();

    allCompleteAlarm = false;
    allCompleteCancel = false;
    allCompleteUnAck = false;

    ngOnInit() {}

    public flags: Flags = {
        alarm: {
            displayName: 'alarm',
            checked: this.allCompleteAlarm,
            color: 'primary',
            subFlagsArray: this.createSubFlag('primary'),
        },
        cancel: {
            displayName: 'cancel',
            checked: this.allCompleteCancel,
            color: 'accent',
            subFlagsArray: this.createSubFlag('accent'),
        },
        unAck: {
            displayName: 'unAck',
            checked: this.allCompleteUnAck,
            color: 'warn',
            subFlagsArray: this.createSubFlag('warn'),
        },
    };

    sendCheckedFlag(checkedFlag, checkedSubFlag, item) {
        this.sendCheckedOptions.emit(JSON.stringify([checkedFlag, checkedSubFlag, item]));
    }

    createSubFlag(color) {
        const newSubFlag = new subFlags(color);
        return newSubFlag.subFlag;
    }

    checkFlag(flag, subFlags, item) {
        if (flag.checked === false) {
            for (let i = 0; i < subFlags.length; i++) {
                if (subFlags[i].checked === true) {
                    subFlags[i].checked = false;
                    this.sendCheckedOptions.emit(
                        JSON.stringify([flag.displayName, subFlags[i], item]),
                    );
                }
            }
        }
    }
}
