<div class="channle-log-contaner">
    <div>
        <mat-form-field>
            <mat-label>Target Version</mat-label>
            <mat-select [(ngModel)]="targetVersion" name="blobVersion">
                <mat-option
                    *ngFor="let channelVersion of channelVersionList"
                    [value]="channelVersion"
                >
                    {{ channelVersion }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <button
        mat-raised-button
        [disabled]="targetVersion === ''"
        color="primary"
        (click)="onCompareClick()"
    >
        {{ 'dlg.ok' | translate }}
    </button>
    <button
        mat-raised-button
        [disabled]="channelDiffArray.length === 0"
        color="primary"
        (click)="download()"
    >
        {{ 'dlg.download' | translate }}
    </button>
</div>

<div>
    <app-infinite-scroll (scrolled)="loadMoreData()">
        <ul>
            <li (click)="onSwitchType(channels)">channels</li>
            <li
                *ngIf="groupsDiffArray.length > 0"
                (click)="onSwitchType(groups)"
            >
                groups
            </li>
        </ul>
        <div class="diff-container">
            <app-channel-compare-header
                [selectedVersion]="targetVersion"
                [channelCompareCount]="
                    selectedType === groups
                        ? groupsCompareCount
                        : channelCompareCount
                "
            >
            </app-channel-compare-header>
            <ng-container *ngIf="this.channelDiffArray.length > 0; else noDiff">
                <div
                    *ngFor="let diffItem of this.chunkedDiffArray; index as i"
                    class="diff-item-container"
                >
                    <table [ngClass]="diffItem.operationType">
                        <tr
                            *ngFor="
                                let diffItemRow of diffItem.blob
                                    | objectToRowPipe: diffItem.local;
                                index as j;
                                count as c
                            "
                        >
                            <app-channel-diff
                                [diffItemRow]="diffItemRow"
                                [rowNum]="i * c + j + 1"
                                [operationType]="diffItem.operationType"
                            >
                            </app-channel-diff>
                        </tr>
                    </table>
                </div>
            </ng-container>
            <ng-template #noDiff>
                <span class="no-diff-msg">{{ diffMsg }}</span>
            </ng-template>
        </div>
    </app-infinite-scroll>
</div>
