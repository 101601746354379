
<!--
<div class="container">
    <span>Export </span>
    <mat-form-field appearance="fill" floatLabel="never">
        <mat-select id="selectedOrCanvas" [formControl]="selectedOrCanvas">
            <mat-option [value]="'selected'">selected elements</mat-option>
            <mat-option [value]="'entire'">entire canvas</mat-option>    
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="selectedOrCanvas.value === 'selected'" floatLabel="never">
        <mat-select id="individualOrTogether" [formControl]="individualOrTogether">
            <mat-option [value]="'individual'">individually</mat-option>
            <mat-option [value]="'together'">together</mat-option>    
        </mat-select>
    </mat-form-field>
</div>
<p>{{selectedOrCanvas.value}}</p>
-->

<div class="container">
    <span>Export</span>
    <select [formControl]="selectedOrCanvas">
        <option [disabled]="!data.areElementsSelected" value='selected'>selected elements</option>
        <option value='canvas'>entire canvas</option>
    </select>
    <select *ngIf="selectedOrCanvas.value=='selected'" [formControl]="individualOrTogether">
        <option selected value="separate">separately</option>
        <option value="together">together</option>
    </select>
    <span>as</span>
    <select [formControl]="type">
        <option value="svg" selected>SVG</option>
        <option value="pdf">PDF</option>
        <option value="png">PNG</option>
        <option value="jpg">JPG</option>
    </select>
    <ng-template [ngIf]="type.value!='svg'">
        <span>at</span>
        <select  [formControl]="resolution">
            <option value="1" selected>1x</option>
            <option value="2">2x</option>
            <option value="3">3x</option>
        </select>
    </ng-template>
    <div class="button-holder">
        <button mat-icon-button [mat-dialog-close]="getData()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>
