import { Injectable } from '@angular/core';
import { CanvasService } from './canvas.service';
import { CanvasInputMode, CanvasInputModeService } from './canvas-input-mode.service';
import { SelectedElementsService } from './selected-elements.service';
import { IcreateWindowReferenceService } from './icreate-window-reference.service';

@Injectable()
export class LineSelectorHelperService {
    private closestLineElement: { elem: any; distance: number };

    constructor(
        private canvasService: CanvasService,
        private inputMode: CanvasInputModeService,
        private selectedElementsService: SelectedElementsService,
        private windowElements: IcreateWindowReferenceService,
    ) {}

    init(): void {
        const container = this.windowElements.getEditorContainer();
        this.bindMouseEvents(container);
    }

    private bindMouseEvents(container) {
        container.addEventListener('mousedown', (event: MouseEvent) => {
            const currentMode: CanvasInputMode = this.inputMode.getMode();
            const selectedElements = this.selectedElementsService.getSelectedElements();

            if (selectedElements.length > 0) {
                return;
            } else if (
                currentMode === CanvasInputMode.SELECT ||
                currentMode === CanvasInputMode.MULTISELECT
            ) {
                const canvas = this.canvasService.getCanvas();

                if (event.target === canvas.svgroot) {
                    this.closestLineElement = undefined;
                    const zoom = canvas.getZoom();
                    const sctm = this.canvasService.getSCTM();
                    const pt = this.transformPoint(event.clientX, event.clientY, sctm);
                    const mouseX = pt.x * zoom;
                    const mouseY = pt.y * zoom;
                    const lineElements = canvas.svgroot.getElementsByTagName('line');
                    for (let i = 0; i < lineElements.length; i++) {
                        this.getDistanceToLine(lineElements[i], mouseX, mouseY);
                    }
                    if (this.closestLineElement) {
                        this.inputMode.setMode(CanvasInputMode.SELECT);
                        this.selectedElementsService.setSelectedElements([
                            this.closestLineElement.elem,
                        ]);
                    }
                }
                return;
            }
        });
    }

    private transformPoint(x, y, m) {
        return {
            x: m.a * x + m.c * y + m.e,
            y: m.b * x + m.d * y + m.f,
        };
    }

    private getDistanceToLine(svgLine, clickX, clickY) {
        if (svgLine.id === 'selectorGrip_rotateconnector') {
            return;
        }

        const x1 = parseFloat(svgLine.getAttribute('x1'));
        const y1 = parseFloat(svgLine.getAttribute('y1'));
        const x2 = parseFloat(svgLine.getAttribute('x2'));
        const y2 = parseFloat(svgLine.getAttribute('y2'));

        // Calculate the length of the line segment
        const lineLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

        // Calculate the distance from mouse click position to the line (using projection)
        const t = ((clickX - x1) * (x2 - x1) + (clickY - y1) * (y2 - y1)) / Math.pow(lineLength, 2);
        const projectionX = x1 + t * (x2 - x1);
        const projectionY = y1 + t * (y2 - y1);

        // Calculate the distance from the mouse click position to the projection point
        const distanceToLine = Math.sqrt(
            Math.pow(clickX - projectionX, 2) + Math.pow(clickY - projectionY, 2),
        );

        if (distanceToLine <= 10) {
            if (!this.closestLineElement) {
                this.closestLineElement = { elem: svgLine, distance: distanceToLine };
            } else if (this.closestLineElement.distance > distanceToLine) {
                this.closestLineElement = { elem: svgLine, distance: distanceToLine };
            }
        }
        return;
    }
}
