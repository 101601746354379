<div class="table-wrapper" (click)="unselectRow()">
    <table mat-table [dataSource]="exhaustTableDataSource" class="table">
        <ng-container matColumnDef="number">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="table-header index-header"
            ></th>
            <td
                mat-cell
                *matCellDef="let channel; let i = index"
                class="table-row number-row"
            >
                {{ i + 1 }}
            </td>
        </ng-container>

        <ng-container matColumnDef="cylinder">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="table-header fixed-column"
            >
                {{
                    "graph-settings.exhaust-graph.table.header.cylinder-channel"
                        | translate
                }}
            </th>
            <td
                mat-cell
                *matCellDef="let channel"
                class="table-row fixed-column"
            >
                <app-search-channel-input
                    [channels]="channels"
                    [channelNumber]="channel.fcuChannelId"
                    (onChannelSelected)="channel.fcuChannelId = $event"
                    [channelError]="getChannelErrors(channel, 'fcuChannelId')"
                ></app-search-channel-input>
            </td>
        </ng-container>

        <ng-container matColumnDef="deviation">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="table-header fixed-column"
            >
                {{
                    "graph-settings.exhaust-graph.table.header.deviation-channel"
                        | translate
                }}
            </th>
            <td
                mat-cell
                *matCellDef="let channel"
                class="table-row fixed-column"
            >
                <app-search-channel-input
                    [channels]="channels"
                    [channelNumber]="channel.deviationChannel"
                    (onChannelSelected)="channel.deviationChannel = $event"
                    [channelError]="getChannelErrors(channel, 'deviationChannel')"
                ></app-search-channel-input>
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="table-header fixed-column"
            >
                {{
                    "graph-settings.exhaust-graph.table.header.title"
                        | translate
                }}
            </th>
            <td
                mat-cell
                *matCellDef="let channel"
                class="table-row fixed-column"
            >
                <input
                    [(ngModel)]="channel.title"
                    maxlength="8"
                    class="title"
                    [value]="channel.title || ''"
                    placeholder="Title"
                />
                <div
                    class="error-container text light"
                    *ngIf="getChannelErrors(channel, 'title')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span data-testid="errorSetPoint">
                        {{
                            getChannelErrors(channel, "title") | translate
                        }}</span
                    >
                </div>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="exhaustTableColumns; sticky: true"
            class="table-header"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: exhaustTableColumns"
            (click)="selectRow(row); $event.stopPropagation()"
            [ngStyle]="{
                'background-color':
                    selectedExhaustChannel === row ? 'rgba(48, 89, 175, 1)' : ''
            }"
        ></tr>
    </table>
</div>

<div class="buttons-container">
    <button [disabled]="!canAddChannel()" class="add" (click)="addChannel()">
        {{ "graph-settings.exhaust-graph.button.add" | translate }}
    </button>
    <button
        [disabled]="!selectedExhaustChannel"
        class="delete"
        (click)="deleteChannel()"
    >
        {{ "graph-settings.exhaust-graph.button.delete" | translate }}
    </button>
</div>
