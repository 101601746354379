<div
    *ngIf="isShow"
    class="dlg-wrap"
    (click)="onClose(); $event.preventDefault()"
>
    <div class="dlg-container" (click)="$event.stopPropagation()">
        <!--  *** GROUP ADD *** -->
        <div *ngIf="type == GROUP_ADD" class="dlg-contents">
            <div class="dlg-title">
                {{ "channel.add-groups" | translate }}
            </div>
            <div class="dlg-inputs" *ngIf="type != GROUP_DELETE">
                <div class="dlg-input">
                    <app-channel-text-input
                        name="displayName"
                        label="Name"
                        [value]="
                            type == GROUP_ADD ? '' : channelGroup.displayName
                        "
                        (inputChange)="onInputChange($event)"
                    ></app-channel-text-input>
                </div>
                <div
                    class="error-container text light"
                    *ngIf="getErrorMsg('displayName', 'name')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span> {{ getErrorMsg("displayName", "name") }}</span>
                </div>
                <div class="dlg-input select">
                    <app-channel-select-input
                        name="categoryId"
                        label="Category"
                        [value]="
                            type == GROUP_ADD ? '' : channelGroup.categoryId
                        "
                        (inputChange)="onInputChange($event)"
                        [categories]="channelCategories"
                    ></app-channel-select-input>
                </div>
                <div
                    class="error-container text light"
                    *ngIf="getErrorMsg('categoryId', 'category')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span> {{ getErrorMsg("categoryId", "category") }}</span>
                </div>
            </div>
            <div class="dlg-buttons">
                <button (click)="onClose()" class="primary">
                    {{ "dlg.cancel" | translate }}
                </button>
                <button
                    (click)="onOk()"
                    class="white"
                    [disabled]="isButtonDisable()"
                >
                    {{ "dlg.add" | translate }}
                </button>
            </div>
        </div>
        <!--  *** GROUP RENAME *** -->
        <div *ngIf="type == GROUP_RENAME" class="dlg-contents">
            <div class="dlg-title">
                {{ "channel.more-option.rename" | translate }}
            </div>
            <div class="dlg-inputs" *ngIf="type != GROUP_DELETE">
                <div class="dlg-input">
                    <app-channel-text-input
                        name="displayName"
                        label="Name"
                        [value]="
                            type == GROUP_ADD ? '' : channelGroup.displayName
                        "
                        (inputChange)="onInputChange($event)"
                    ></app-channel-text-input>
                </div>
                <div
                    class="error-container text light"
                    *ngIf="getErrorMsg('displayName', 'name')"
                >
                    <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                    <span> {{ getErrorMsg("displayName", "name") }}</span>
                </div>
                <div class="dlg-input select">
                    <app-channel-select-input
                        name="categoryId"
                        label="Category"
                        [value]="
                            type == GROUP_ADD ? '' : channelGroup.categoryId
                        "
                        (inputChange)="onInputChange($event)"
                        [categories]="channelCategories"
                    ></app-channel-select-input>
                </div>
            </div>
            <div class="dlg-buttons">
                <button (click)="onClose()" class="primary">
                    {{ "dlg.cancel" | translate }}
                </button>
                <button
                    (click)="onOk()"
                    class="white"
                    [disabled]="isButtonDisable()"
                >
                    {{ "dlg.rename" | translate }}
                </button>
            </div>
        </div>
        <!--  *** GROUP DELETE *** -->
        <div *ngIf="type == GROUP_DELETE" class="dlg-contents">
            <div class="dlg-title">
                {{ getTranslate("channel.delete-groups.title") }}
            </div>
            <div>
                {{ getTranslate("channel.delete-groups.content") }}
            </div>
            <div class="dlg-buttons">
                <button (click)="onClose()" class="primary">
                    {{ "dlg.cancel" | translate }}
                </button>
                <button (click)="onOk()" class="white">
                    {{ "dlg.confirm" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
