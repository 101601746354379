import { Component } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { BlobStorageService } from '@service/blob-storage.service';
import { BlobStorageApiService } from '@service/blob-storage-api.service';
import { BlobOperationParam } from 'common/models/blob-operations';
import { ProjectData } from 'icreate/common/models/project';
import { NotificationService } from '@service/notification.service';

@Component({
    selector: 'app-approve-project-page',
    templateUrl: './approve-project-page.component.html',
    styleUrls: ['./approve-project-page.component.scss'],
})
export class ApproveProjectPageComponent {
    isLoading: boolean = false;
    hasNoData: boolean = false;
    projectUpToDate: boolean = false;
    blobVersion: string;

    private builderId: string;
    private vesselId: string;
    private projectId: string;
    private pulledProject: ProjectData;

    publishReason: string = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private blobStorageService: BlobStorageService,
        private blobStorageApiService: BlobStorageApiService,
        private notificationService: NotificationService,
    ) {}

    async ngOnInit() {
        this.isLoading = true;
        this.activatedRoute.url.subscribe((params) => {
            if (params.length === 3) {
                this.getBlobInfo(params);
            } else {
                this.isLoading = false;
                this.hasNoData = true;
            }
        });
    }

    async getBlobInfo(params: UrlSegment[]) {
        this.builderId = params[0].path;
        this.vesselId = params[1].path;
        this.blobVersion = params[2].path;

        const requestObject = {
            builderId: this.builderId,
            vesselId: this.vesselId,
            blobVersion: this.blobVersion,
        };

        try {
            const versionList: string[] = await this.blobStorageApiService.getVersionList(
                this.builderId,
                this.vesselId,
            );
            if (versionList) {
                if (versionList[versionList.length - 1] >= this.blobVersion) {
                    this.isLoading = false;
                    this.projectUpToDate = true;
                    return
                }
            }
            const projectData = await this.blobStorageService.pullPreApprovedBlob(requestObject);
            this.pulledProject = projectData.project;
            this.projectId = projectData.project.id;
            this.publishReason = projectData.publishReason;
            this.isLoading = false;
        } catch (err) {
            this.notificationService.projectPullError(this.pulledProject.name);
        }
    }

    async approve() {
        this.isLoading = true;
        const blobParams = new BlobOperationParam();
        blobParams.builderId = this.builderId;
        blobParams.vesselId = this.vesselId;
        blobParams.projectId = this.projectId;

        try {
            const publishProject = await this.blobStorageApiService.publishProject(blobParams);
            await this.blobStorageService.publishToBlob(
                blobParams,
                publishProject,
                this.pulledProject,
            );
        } catch (error) {
            this.notificationService.projectPublishError();
        }
        this.router.navigate(['/editor']);
        this.isLoading = false;
    }

    decline() {
        this.router.navigate(['/editor']);
    }
}
