<div (click)="onSelectView.emit(view)" class="view-item">
    <mat-icon
        *ngIf="!view.type || view.type === svgViewType"
        matTooltip="{{ 'editor.view-svg' | translate }}"
        class="leftbar-item-type"
    >
        insert_drive_file
    </mat-icon>
    <span>{{ view.name }}</span>
    <mat-icon
        *ngIf="isEditingView(view)"
        matTooltip="{{ isEditingView(view)?.editor.name }}"
        class="account_icon"
        >account_circle</mat-icon
    >
    <div
        *ngIf="!isEditingView(view) || isLocking(view)"
        class="viewonly-label-div"
    >
        <span>view only</span>
    </div>
    <mat-icon
        aria-label="More"
        [matMenuTriggerFor]="menuview"
        (click)="stopClickEvent($event)"
        *ngIf="isEditingView(view) && !isLocking(view)"
        >more_vert</mat-icon
    >
</div>

<mat-menu #menuview="matMenu" class="leftbar-item-menu">
    <button mat-menu-item (click)="onDeleteView(view)">
        {{ "editor.view-delete" | translate }}
    </button>
    <button mat-menu-item (click)="onRenameView(view)">
        {{ "editor.view-rename" | translate }}
    </button>
    <button mat-menu-item (click)="onPropertyView(view)">
        {{ "editor.view-property" | translate }}
    </button>
    <button mat-menu-item (click)="onCloneView(view)">
        {{ "editor.view-clone" | translate }}
    </button>
    <button mat-menu-item (click)="onExportView(view)">
        {{ "editor.view-export" | translate }}
    </button>
    <button mat-menu-item (click)="pdfFile.value = ''; pdfFile.click()">
        {{ "editor.view-import-pdf" | translate }}
        <input
            #pdfFile
            type="file"
            style="display: none"
            (change)="onSetPDF.emit($event)"
            accept="application/pdf"
        />
    </button>
    <button mat-menu-item (click)="onFinishEdit.emit(view)">
        {{ "editor.view-editmode" | translate }}
    </button>
</mat-menu>
