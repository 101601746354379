import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ProjectService } from './project.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndPointApi } from '@helpers/endpointapi';

@Injectable({ providedIn: 'root' })
export class VesselService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(
        private http: HttpClient,
        public projectService: ProjectService,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    getVesselInfo(projectId: string): Promise<any> {
        const param = {
            projectId: projectId,
        };

        return lastValueFrom(
            this.http.get(`${this.domain}/api/vessel/vesselData/${projectId}`, {
                headers: this.headers,
                params: param,
            }),
        );
    }
}
