export enum CloudOperation {
    PULL = 'pull',
    PUBLISH = 'publish',
}

export class CloudError extends Error {
    constructor(message: string) {
        super();
        this.message = message;
    }
}

export const AzureErrorCodes = {
    BLOB_NOT_FOUND: 404,
    LEASE_IN_MISMATCH_WITH_LEASE_OPERATION: 409,
};

export enum CloudErrorMsg {
    BLOB_NOT_FOUND = 'blob-pull-publish.error.BLOB_NOT_FOUND',
    OVER_MAX_RETRY = 'blob-pull-publish.error.OVER_MAX_RETRY',
    UNKNOWN_ERROR = 'blob-pull-publish.error.UNKNOWN_ERROR',
}
