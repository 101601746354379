import { locale } from 'common/validators/yup-locale';
import { GenericErrorStrings } from 'icreate/common/validators/validation-errors/generic';
import * as yup from 'yup';
import { StringSchema, string } from 'yup';
yup.setLocale(locale);

export const projectNameValidation: StringSchema = string()
    .nonNullable()
    .required()
    .max(16, GenericErrorStrings.ABOVE_MAXIMUM)
    .strict();
