import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-confirmation',
    templateUrl: './dialog-confirmation.component.html',
    styleUrls: ['./dialog-confirmation.component.css'],
})
export class DialogConfirmationComponent {
    title: string = 'Confirmation';
    okButtonText: string = 'OK';
    okButtonType: string = 'basic';
    noButtonText: string = 'NO';
    noButtonType: string = 'basic';
    confirmMessage: string;

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    ) {
        if (data.title) {
            this.title = data.title;
        }
        if (data.okButtonText) {
            this.okButtonText = data.okButtonText;
        }
        if (data.okButtonType) {
            this.okButtonType = data.okButtonType;
        }
        if (data.noButtonText) {
            this.noButtonText = data.noButtonText;
        }
        if (data.noButtonType) {
            this.noButtonType = data.noButtonType;
        }
        if (data.confirmMessage) {
            this.confirmMessage = data.confirmMessage;
        }
    }
}

export class ConfirmationDialogData {
    title: string;
    okButtonText: string;
    okButtonType: ConfirmButtonType;
    noButtonText: string;
    noButtonType: ConfirmButtonType;
    confirmMessage: string;
}

export enum ConfirmButtonType {
    WARN = 'warn',
    PRIMARY = 'primary',
    BASIC = 'basic',
}
