<div class="wrapper">
    <div class="title">{{"project-blob.publish.project.title" | translate }}</div>
    <div class="builder-container">
        <div>{{ "project-blob.publish.builder-name" | translate }}</div>
        <div>
            {{ projectData?.VesselBuilderCompanyName }}
        </div>
    </div>

    <div class="vessel-container">
        <div>{{  "project-blob.publish.vessel-name" | translate }}</div>
        <div>
            {{ projectData.VesselName }}
        </div>
    </div>

    <div class="my-form-field">
        <span>{{ "project-blob.publish.approver-email" | translate }}</span>
        <input
            matInput
            style="width: 320px"
            type="text"
            required="required"
            [(ngModel)]="approverEmail"
            (input)="checkFormErrors()"
            [value]="approverEmail"
        />
        <app-app-settings-error
              [validationErrors]="validationErrors"
              [propertyName]="'approverEmail'"
            >
            </app-app-settings-error>
    </div>

    <div class="my-form-field">
        <span>{{"project-blob.publish.publish-reason" | translate }}</span>
        <input
            matInput
            style="width: 320px"
            type="text"
            required="required"
            [(ngModel)]="publishReason"
            (input)="checkFormErrors()"
            [value]="publishReason"
        />
        <app-app-settings-error
              [validationErrors]="validationErrors"
              [propertyName]="'publishReason'"
            >
            </app-app-settings-error>
    </div>

    <div class="buttons-container">
        <button mat-raised-button color="primary" (click)="onActConfirm()" [disabled]="isActDisabled()">
            {{ "project-blob.publish.button.act" | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="onCancel()">
            {{ "project-blob.publish.button.cancel" | translate }}
        </button>
    </div>
</div>
