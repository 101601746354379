import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@service/auth.service';
import { RuntimeEnvironments } from 'src/app/models/runtime-enviroment.enum';
import { EnvironmentService } from '@service/environment.service';
import { EditFlgService } from '@service/editflg.service';
import { HttpStatusCode } from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {
    private env: RuntimeEnvironments;
    constructor(
        private router: Router,
        private authService: AuthService,
        private environmentService: EnvironmentService,
        private editFlg: EditFlgService,
    ) {
        this.env = this.environmentService.getEnvironment();
    }

    async canActivate() {
        try {
            const authCheck = await this.authService.isAuthenticated();
            if (authCheck.isAuthenticated) {
                this.editFlg.clientAccountName = authCheck.name;
                this.editFlg.clientAccountOID = authCheck.oid;
                this.authService.setCurrentUser(authCheck.name, authCheck.oid);
                return true;
            } else {
                this.router.navigate(['/iCreateLogin']);
                return false;
            }
        } catch (error) {
            if (error.status === HttpStatusCode.Unauthorized) {
                this.router.navigate(['/iCreateLogin']);
            }
        }
    }
}
