export enum ExcelCommands {
    ChannelDialogOpened = 'OpenChannelDialog',
    ImportExcelFile = 'saveexceltoserverdir',
    ImportError = 'excelimporterror',
    OpenExcel = 'openexcel',
    FileUpdated = 'excelfileupdated',
    StopWatchingExcelFile = 'finishWatch',
    Isupdateexcelfile = 'isupdateexcelfile',
    ChannelsUpdated = 'channelsUpdated',
    PublishToBlob = 'publishchannelconfigtoblob',
    PullFromBlob = 'pullchannelconfigfromblob',
    CloseExcelFile = 'closeExcelFile',
    ChannelCompare = 'channelCompare',
}

export enum ExcelSocektCommands {
    ChannelsUpdated = 'channelsUpdated',
}
