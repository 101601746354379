import { Injectable } from '@angular/core';
import * as DOMPurify from 'dompurify';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SvgTransformService } from './svg/svg-transform.service';
import { SvgUtilsService } from './svg/svg-utils.service';
import { SvgComponentSerializationService } from './svg/svg-component-serialization.service';
import { EditorTemplate } from 'icreate/common/models/editor-templates';
import { editorTemplateValidator } from 'icreate/common/validators/editor-templates/editor-templates-validator';

@Injectable()
export class TemplateService {
    constructor(
        private http: HttpClient,
        private serializer: SvgComponentSerializationService,
        private transform: SvgTransformService,
        private svgUtils: SvgUtilsService, // private projectService: ProjectService, // private notificationService: NotificationService
    ) {}

    public async getTemplates(projectId): Promise<EditorTemplate[]> {
        const values = await lastValueFrom(
            this.http.get<EditorTemplate[]>('/api/templates', {
                params: { projectId },
            }),
        );
        return values.map((v) => this.sanitize(v));
    }

    public saveTemplate(template: EditorTemplate): Promise<string> {
        try {
            editorTemplateValidator.validateSync(template);
        } catch (error) {
            //   this.notificationService.saveTemplatesError(error);
            throw new Error('Template not validated');
        }
        return lastValueFrom(
            this.http.post('/api/templates', template, {
                responseType: 'text',
            }),
        );
    }

    public deleteTemplate(templateId: string): Promise<string> {
        return lastValueFrom(
            this.http.delete(`/api/templates/${templateId}`, {
                responseType: 'text',
            }),
        );
    }

    public importTemplates(projectId, builderId, vesselId): Promise<string> {
        return lastValueFrom(
            this.http.post(
                `/api/templates/import`,
                {
                    projectId,
                    builderId,
                    vesselId,
                },
                {
                    responseType: 'text',
                },
            ),
        );
    }

    private sanitize(template: EditorTemplate): EditorTemplate {
        //We have to wrap our component in an svg to sanitize with DOMPurify, but need
        // to remove it so that our templates work with svgCanvas.
        const sanitized = DOMPurify.sanitize('<svg>' + template.serializedComponents + '</svg>');
        template.serializedComponents = sanitized.substring(5, sanitized.length - 6);

        return template;
    }

    public toTemplate(elements: SVGGraphicsElement[], projectId: string): EditorTemplate {
        const elementCopies = this.serializer.deserialize(this.serializer.serialize(elements));

        const boundingBox = this.svgUtils.calculateBoundingBox(elements);
        // const translation = this.canvas.getSvgRoot().createSVGTransform();
        // translation.setTranslate(-boundingBox.x, -boundingBox.y);
        const adjustPosition = {
            x: -boundingBox.x,
            y: -boundingBox.y,
        };

        //Move the serialization to (0, 0)
        for (let i = 0; i < elementCopies.length; i++) {
            const originalElement = elements[i];
            const elementCopy = elementCopies[i];

            originalElement.parentNode.appendChild(elementCopy);
            this.transform.shift(elementCopy, adjustPosition.x, adjustPosition.y);
            originalElement.parentNode.removeChild(elementCopy);
        }
        const serializedComponents = this.serializer.serialize(elementCopies);

        return {
            projectId: projectId,
            serializedComponents: serializedComponents,
        };
    }
}
