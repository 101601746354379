import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-alarm-status-icon',
    templateUrl: './alarm-status-icon.component.html',
    styleUrls: ['./alarm-status-icon.component.css'],
})
export class AlarmStatusIconComponent {
    @Input() statusText: string = '';

    public getClass() {
        return 'icon-' + this.statusText.toLowerCase();
    }
}
