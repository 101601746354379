<div
  class="svg-property"
  *ngIf="item"
  style="
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
  "
>
  <div *ngFor="let flag of flags | keyvalue">
    <div>
      <mat-checkbox
        [(ngModel)]="flag.value.checked"
        [color]="flag.value.color"
        (click)="checkFlag(flag.value, flag.value.subFlagsArray, item)"
        [disabled]="item.value.property.variableId ? false : true"
      >
        {{ "tester.flag-option-" + flag.value.displayName | translate }}
      </mat-checkbox>
    </div>
    <div *ngIf="flag.value.checked">
      <div *ngIf="flag.value.displayName === 'alarm'">
        <div
          *ngFor="let sub of flag.value.subFlagsArray | keyvalue"
          style="display: flex"
        >
          <mat-checkbox
            [(ngModel)]="sub.value['checked']"
            (ngModelChange)="
              sendCheckedFlag(flag.value.displayName, sub.value, item)
            "
            ngDefaultControl
            [color]="sub.value['color']"
            [disabled]="
              item.value.property.editorProperties[sub.value['displayName']]
                ? false
                : true
            "
          >
            {{
              "gauges.property-alarm-settings-" + sub.value["displayName"]
                | translate
            }}
          </mat-checkbox>
        </div>
      </div>
      <div *ngIf="flag.value.displayName != 'alarm'">
        <div
          *ngFor="let sub of flag.value.subFlagsArray | keyvalue"
          style="display: flex"
        >
          <mat-checkbox
            [(ngModel)]="sub.value['checked']"
            (ngModelChange)="
              sendCheckedFlag(flag.value.displayName, sub.value, item)
            "
            ngDefaultControl
            [color]="sub.value['color']"
          >
            {{
              "gauges.property-alarm-settings-" + sub.value["displayName"]
                | translate
            }}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
