import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RuntimeEnvironments } from '@models/runtime-enviroment.enum';
import { AuthService } from '@service/auth.service';
import { ThemeService } from '@service/theme.service';
import { EnvironmentService } from '@service/environment.service';
import { ContactService } from '@service/contact.service';
import { SettingsService } from '@service/settings.service';
import { ContactFormComponent } from '@components/contact-form/contact-form.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    title = 'client';
    location: Location;
    public env: RuntimeEnvironments;
    canDisplay = false;
    isIframe = false;
    showLandingPage = false;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        private router: Router,
        private environmentService: EnvironmentService,
        private settingsService: SettingsService,
        private themeservice: ThemeService,
        private authService: AuthService,
        private contactService: ContactService,
        private dialog: MatDialog,
        location: Location,
        private iconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        this.location = location;
        this.env = this.environmentService.getEnvironment();
        console.log('environment: ' + this.env);

        let pathIcons = '../assets/icons/';
        let pathImages = '../assets/images/';

        if (this.env === RuntimeEnvironments.ELECTRON) {
            pathIcons = 'assets/icons/';
            pathImages = 'assets/images/';
        }

        const icons: [string, string][] = [
            ['error', pathIcons + 'error.svg'],
            ['colour-picker', pathIcons + 'Colour-Picker.svg'],
            ['pencil', pathIcons + 'pencil.svg'],

            ['download', pathIcons + 'download.svg'],
            ['fullscreen', pathIcons + 'fullscreen.svg'],
            ['screenshot', pathIcons + 'screenshot.svg'],
            ['expand', pathIcons + 'Expand.svg'],
            ['alarm', pathIcons + 'bell.svg'],
            ['search', pathIcons + 'search.svg'],
            ['arrow-left', pathIcons + 'arrow-left.svg'],
            ['blue-pencil', pathIcons + 'pencil-blue.svg'],
            ['value-pointer', pathIcons + 'value-pointer.svg'],
            ['expand-details', pathIcons + 'expand-details.svg'],
            ['cancel-close', pathIcons + 'Cancel-Close.svg'],
            ['add', pathIcons + 'Add_Blue.svg'],
            ['delete', pathIcons + 'Delete.svg'],
            ['alarm-page', pathImages + 'ops-images/nav/Alarms.svg'],
            ['graph', pathImages + 'ops-images/nav/Graphs.svg'],
            ['mimics', pathImages + 'ops-images/nav/Mimics.svg'],
            ['channel-list', pathImages + 'ops-images/nav/AllChannels.svg'],
            ['blue-cancel', pathIcons + 'Blue-Cancel.svg'],
            ['blue-refresh', pathIcons + 'Blue-Refresh.svg'],
            ['blue-delete', pathIcons + 'Blue-Trash-Delete.svg'],
            ['fu-settings', pathIcons + 'fu-settings.svg'],
            ['locked', pathIcons + 'locked.svg'],
            ['run-hour-setting', pathIcons + 'run-hour-setting.svg'],
            ['cancel-alarm', pathIcons + 'cancel-alarm.svg'],
            ['graph-settings', pathIcons + 'graph-settings.svg'],
            ['open-graph-settings', pathIcons + 'open-graph-settings.svg'],
            ['locked', pathIcons + 'locked.svg'],
            ['loading-icon', pathIcons + 'loading-icon.svg'],
            ['password-hide', pathIcons + 'password-hide.svg'],
            ['password-watch', pathIcons + 'password-watch.svg'],

            //lab
            ['leading', pathIcons + 'leading-icon.svg'],
            ['alarm', pathIcons + 'bell.svg'],
            ['dashboard', pathIcons + 'Dashboard.svg'],
            ['graph', pathIcons + 'Graphs.svg'],
            ['mimics', pathIcons + 'Mimics.svg'],
            ['alarm-page', pathIcons + 'Alarms.svg'],
            ['channel-list', pathIcons + 'AllChannels.svg'],
            ['systemStatus', pathIcons + 'SystemStatus.svg'],
            ['setting', pathIcons + 'UserSetting.svg'],

            ['group', pathIcons + 'group.svg'],
            ['to_bottom', pathIcons + 'to-bottom.svg'],
            ['to_top', pathIcons + 'to-top.svg'],

            ['visible', pathIcons + 'visible.svg'],
            ['invisible', pathIcons + 'invisible.svg'],
        ];

        for (let i = 0; i < icons.length; i++) {
            iconRegistry.addSvgIcon(
                icons[i][0],
                domSanitizer.bypassSecurityTrustResourceUrl(icons[i][1]),
            );
        }
    }

    ngOnInit() {
        this.isIframe = window !== window.parent && !window.opener;
        this.showLandingPage = false;
        this.canDisplay = true;

        console.log(`iCreate v${environment.version as string}`);
    }

    ngAfterViewInit() {
        try {
            this.settingsService.init();
            this.themeservice.setTheme();

            // When server receives config data from FCU, server notify that to client via websocket, and client call api for retrieving.
            // But if server completes to receive before start client, notification never come to client.
            // To avoid it, when client starts, client should call API itself until the config acquisition is successful.

            // I think we don't use this anymore but leave it for now in case.
            // if (this.opsAmsConfigFileService.needToGetOnce) {
            //   this.opsAmsConfigFileService.updateChannelConfig();
            // }
        } catch (err) {
            console.error(err);
        }
    }

    ngOnDestroy() {
        console.log('app ng destroy');
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    isHidden() {
        const list = ['', '/lab', '/home'],
            route = this.location.path();
        return list.indexOf(route) > -1;
    }

    getClass() {
        const route = this.location.path();
        if (route.startsWith('/view')) {
            return 'work-void';
        }
        return this.isHidden() ? 'work-home' : 'work-editor';
    }

    async login() {
        if (this.env === RuntimeEnvironments.CLOUD) {
            this.authService.azureLogIn();
        } else {
            this.showLandingPage = false;
            await this.router.navigate(['login']);
        }
    }

    logout() {
        if (this.env === RuntimeEnvironments.CLOUD) {
            this.authService.azureLogOut();
        } else {
            this.authService.edgeLogout();
        }
    }

    openContactForm = () => {
        const dialogRef = this.dialog.open(ContactFormComponent, {
            height: '700px',
            width: '500px',
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res) this.contactService.postInquiry(res);
        });
    };
}
