<div #treetable class="container" [style.height]="containerProperty.height" [style.width]="containerProperty.width">
	<div *lazyFor="let node of list; index as i" class="item">
		<div *ngIf="node.visible" [ngSwitch]="treeType">
			<div *ngSwitchCase="TypeOfTree.Standard">
				<div class="item-text" [style.left.px]="node.childPos * 15" [style.width.px]="500 - node.childPos * 15">
					<button mat-icon-button (click)="onExpandToggle(node)" [disabled]="!node.childs">
						<mat-icon *ngIf="node.expanded">expand_more</mat-icon>
						<mat-icon *ngIf="!node.expanded">chevron_right</mat-icon>
					</button>
					<div *ngIf="!node.childs.length" class="item-waiting" style="width:40px" [ngStyle]="{'display': (node.expanded) ? 'inline-block' : 'none'}">
						<mat-spinner diameter="20"></mat-spinner>
					</div>
					<button mat-icon-button enabled="false" *ngIf="node.class === nodeType.Variable">
						<mat-icon>label</mat-icon>
					</button>
					{{node.text}}
				</div>
				<div class="item-property" [style.left.px]="node.childPos * 15">
					{{node.property}}
				</div>
				<div class="item-check">
					<mat-checkbox [(ngModel)]="node.checked" *ngIf="node.class == nodeType.Variable" [disabled]="!node.enabled" (change)="changeStatus(node,$event)"></mat-checkbox>
				</div>
			</div>
			<div *ngSwitchCase="TypeOfTree.ToDefine">
				<div class="item-text" [style.left.px]="node.childPos * 15" [style.width.px]="600 - node.childPos * 15">
					<button mat-icon-button (click)="onExpandToggle(node)" *ngIf="node.expandable">
						<mat-icon *ngIf="node.expanded">expand_more</mat-icon>
						<mat-icon *ngIf="!node.expanded">chevron_right</mat-icon>
					</button>
					<button mat-icon-button *ngIf="node.class === nodeType.Variable || node.class === nodeType.Item">
						<mat-icon>label</mat-icon>
					</button>
					{{node.text}}
					<div *ngIf="node.class === nodeType.Array">
						<span style="margin-left: 15px;margin-right: 5px;">{{'device.tag-array-id' | translate}}</span>
						<mat-select [(value)]="node.todefine.id" style="width: 120px">
							<mat-option *ngFor="let key of node.todefine.options" [value]="key">
								{{ key }}
							</mat-option>
						</mat-select>
						<span style="margin-left: 15px;margin-right: 5px;">{{'device.tag-array-value' | translate}}</span>
						<mat-select [(value)]="node.todefine.value" style="width: 120px">
							<mat-option *ngFor="let key of node.todefine.options" [value]="key">
								{{ key }}
							</mat-option>
						</mat-select>						
					</div>
				</div>
				<div class="item-info" [style.left.px]="node.childPos * 15">
					{{node.property}}
				</div>
				<div class="item-check">
					&nbsp;
					<mat-checkbox [(ngModel)]="node.checked" *ngIf="node.text && (node.class == nodeType.Variable)"
						[disabled]="!node.enabled" (change)="changeStatus(node,$event)"></mat-checkbox>
				</div>
			</div>			
		</div>
	</div>
</div>