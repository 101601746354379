import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom, of } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { EndPointApi } from '@helpers/endpointapi';
import { LoadingSpinnerComponent } from '@layout/loading-spinner/loading-spinner.component';
import { VesselBuilderCreateDialogComponent } from '@components//vessel-builder-create-dialog/vessel-builder-create-dialog.component';

@Injectable()
export class VesselBuilderCreateService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    overlayRef = this.overlay.create({
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });

    constructor(
        private http: HttpClient,
        public dialog: MatDialog,
        private overlay: Overlay,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    async openBuilderInfoEnteringDialog() {
        const dialogRef = this.dialog.open(VesselBuilderCreateDialogComponent);

        const result = await dialogRef.afterClosed().toPromise();

        let newBuilder;

        if (result !== undefined) {
            newBuilder = this.registerNewBuilder(result);
        }

        return newBuilder;
    }

    async registerNewBuilder(companyInfo: any) {
        this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));

        const newBuilder = await this.postNewBuilder(companyInfo);

        console.log(newBuilder);

        this.overlayRef.detach();

        return newBuilder;
    }

    private postNewBuilder(companyInfo: any): Promise<any> {
        return lastValueFrom(
            this.http.post(`${this.domain}/api/company`, {
                headers: this.headers,
                params: companyInfo,
            }),
        );
    }
}
