import { StringSchema, addMethod, string } from 'yup';
declare module 'yup' {
    interface StringSchema {
        isIp: (errorMessage?: string) => StringSchema;
    }
}

const v4 =
    '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';
const v4exact = new RegExp(`^${v4}$`);

export function isIp(errorMessage = 'errors.validation.generic.not-valid-Ip') {
    return this.test('Ip is valid', errorMessage, function (value: any) {
        const { path, createError } = this;
        return v4exact.test(value) || createError({ path, message: errorMessage });
    });
}

addMethod<StringSchema>(string, 'isIp', isIp);
