import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { GraphService } from '@service/graphs/graph.service';
import { NotificationService } from '@service/notification.service';
import { Channel } from 'common/models/channel';
import { ChannelType } from 'common/models/channel-type';
import { GaugeGraph, GaugeGraphChannel } from 'common/models/graphs';
import { getValidationErrors, mapErrors } from 'common/validators';
import {
    GaugeGraphChannelValidator,
    GaugeGraphValidator,
} from 'common/validators/graphs/gauge-graph/gauge-graph-validator';
import * as _ from 'lodash';
import { lastValueFrom, take } from 'rxjs';

@Component({
    selector: 'app-gauges',
    templateUrl: './gauges.component.html',
    styleUrls: ['./gauges.component.scss'],
})
export class GaugesComponent implements OnInit {
    readonly MAX__GAUGES_GRAPHS: number = 8; //Not sure if we have max?

    @Input() channels: Channel[];
    @Input() graph: GaugeGraph;
    @Input() graphListId: string;
    @Output() onExitGraph: EventEmitter<void> = new EventEmitter();
    @ViewChild('confirmationWindow') confirmationWindow: ConfirmationWindowComponent;

    tableDataSource = new MatTableDataSource<GaugeGraphChannel>();

    displayedColumns: string[] = ['number', 'Ch. No.', 'divisions', 'range'];

    selectedRow: GaugeGraphChannel;
    showChannels: boolean = false;
    filteredChannelsByInput = [];

    initalSettings: GaugeGraph;

    constructor(
        private graphService: GraphService,
        private notificationService: NotificationService,
    ) {}

    ngOnInit(): void {
        this.initalSettings = _.cloneDeep(this.graph);
        this.tableDataSource.data = this.graph.channels;
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnload() {
        this.graphService.sendUnlockBeacon(this.graphListId);
        this.onExitGraph.emit();
    }

    selectRow(row) {
        this.showChannels = false;
        this.selectedRow = row;
    }

    getChannelRange(channelFcuId) {
        let channelRange;
        if (channelFcuId) {
            const channel: Channel[] = this.channels.filter((channel) => {
                return channel.fcuData.fcuChannelId == channelFcuId;
            });
            if (channel[0]) {
                channelRange = channel[0].fcuData.measurableRange;
            }
        }

        return channelRange;
    }

    canAddChannel() {
        return this.graph.channels.length < this.MAX__GAUGES_GRAPHS;
    }

    addChannel() {
        const newChannel = new GaugeGraphChannel();
        this.graph.channels.push(newChannel);
        this.tableDataSource.data = this.graph.channels;
        this.notificationService.successMessage(
            'graph-settings.gauge-graph.notification.success.channel-added',
        );
    }

    inputLimit(event, currentValue) {
        if (!Number(event.target.value)) {
            return event.target.value;
        } else if (event.target.value > 15 || event.target.value < 1) {
            event.target.value = currentValue;
            return currentValue;
        } else {
            return Number(event.target.value);
        }
    }

    deleteChannel() {
        const index = this.graph.channels.findIndex((channel) => channel === this.selectedRow);
        if (index !== -1) {
            this.graph.channels.splice(index, 1);
            this.selectedRow = null;
            this.notificationService.successMessage(
                'graph-settings.gauge-graph.notification.success.channel-deleted',
            );
        }
        this.tableDataSource.data = this.graph.channels;
    }

    async saveGraph() {
        await this.graphService.saveGaugeGraph(this.graph, this.graphListId);
        this.initalSettings = _.cloneDeep(this.graph);
        this.notificationService.successMessage(
            'graph-settings.gauge-graph.notification.success.save',
        );
    }

    async exit() {
        if (this.settingsHaveChanged()) {
            this.confirmationWindow.setVisible(true);
            const closeWidndow = await lastValueFrom(
                this.confirmationWindow.onOptionSelected.pipe(take(1)),
            );
            if (closeWidndow) {
                this.onExitGraph.emit();
            }
        } else {
            this.onExitGraph.emit();
        }
    }

    unselectRow() {
        this.selectedRow = null;
        this.showChannels = false;
    }

    settingsHaveChanged() {
        return !_.isEqual(this.initalSettings, this.graph);
    }

    getChannelErrors(channelRow: GaugeGraphChannel, key) {
        const errors = getValidationErrors(GaugeGraphChannelValidator(), channelRow);
        if (errors) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                return validationErrors[key].errors[0];
            }
        }
    }

    saveButtonIsDisabled() {
        const errors = getValidationErrors(GaugeGraphValidator(), this.graph);
        if (errors.length > 0) {
            return true;
        } else {
            const channelNotFound = this.graph.channels.some((graphChannel) => {
                return !this.channels.some((channel) => {
                    return (
                        channel.fcuData.fcuChannelId === graphChannel.fcuChannelId &&
                        !this.isDigitalChannel(channel.fcuData.channelType)
                    );
                });
            });

            if (channelNotFound) {
                return true;
            }
        }
        return !this.settingsHaveChanged();
    }


    isDigitalChannel(channelType) {
        const digitalChannelTypes = new Set([
            ChannelType.DIGITAL_MOTOR,
            ChannelType.DIGITAL_NORMAL_CLOSE,
            ChannelType.DIGITAL_NORMAL_OPEN,
        ]);
        return digitalChannelTypes.has(channelType);
    }
}
