export class ChannelOpsData {
    id: string;
    channelId: string;
    channelType: string;
    measurementUnit: string;
    name: string;
    tag: string;
}

export var ChannelOpsDataFns = {
    create,
};

function create(): ChannelOpsData {
    return {
        id: '',
        channelId: '',
        name: '',
        tag: '',
        channelType: '',
        measurementUnit: '',
    };
}
