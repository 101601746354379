import { Component, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { ChannelService } from '@service/channel.service';
import { ProjectService } from '@service/project.service';
import { Channel } from 'common/models/channel';
import { MAX_RUN_HOUR_SETTINGS, RunHourSetting } from 'common/models/run-hour-setting';
import { Subscription, lastValueFrom, take } from 'rxjs';
import * as _ from 'lodash';
import { getValidationErrors } from 'icreate/common/validators';
import { RunHourSettingValidator } from 'common/validators/run-hour-setting/run-hour-setting-validator';
import { RunHourSettingsService } from '@service/run-hour-settings.service';
import { NotificationService } from '@service/notification.service';
import { ChannelType } from 'common/models/channel-type';

@Component({
    selector: 'app-run-hour-settings',
    templateUrl: './run-hour-settings.component.html',
    styleUrls: ['./run-hour-settings.component.scss'],
})
export class RunHourSettingsComponent {
    @ViewChild('confirmationWindow') confirmationWindow: ConfirmationWindowComponent;

    initalRunHourList: RunHourSetting[];
    runHourSettingList: RunHourSetting[];
    selectedSetting: RunHourSetting;
    channels: Channel[];

    deletedSettingSubscription: Subscription;
    newSettingSubscription: Subscription;
    updatedSettingSubscription: Subscription;

    activeSubscriptions: Subscription[];

    constructor(
        private router: Router,
        private channelService: ChannelService,
        private projectService: ProjectService,
        private runHourSettingsService: RunHourSettingsService,
        private notificationService: NotificationService,
    ) {}

    async ngOnInit() {
        const projectId = this.projectService.getProjectId();
        this.runHourSettingList = await this.runHourSettingsService.getAll(projectId);
        this.startSocketSubscriptions();
        this.channels = await lastValueFrom(this.channelService.getChannels(projectId));
        this.initalRunHourList = _.cloneDeep(this.runHourSettingList);
    }

    startSocketSubscriptions() {
        this.newSettingSubscription = this.runHourSettingsService
            .settingCreated$()
            .subscribe((runHourSetting: RunHourSetting) => {
                this.runHourSettingList.push(runHourSetting);
            });

        this.deletedSettingSubscription = this.runHourSettingsService
            .settingDeleted$()
            .subscribe((settingId) => {
                const index = this.runHourSettingList.findIndex(
                    (runHourSetting) => runHourSetting.id === settingId,
                );
                if (index !== -1) {
                    this.runHourSettingList.splice(index, 1);
                }
            });

        this.updatedSettingSubscription = this.runHourSettingsService
            .settingUpdated$()
            .subscribe((updatedSetting: RunHourSetting) => {
                this.runHourSettingList.map((runHourSetting) => {
                    if (runHourSetting.id === updatedSetting.id) {
                        runHourSetting = updatedSetting;
                    }
                });
            });

        this.activeSubscriptions = [
            this.deletedSettingSubscription,
            this.newSettingSubscription,
            this.updatedSettingSubscription,
        ];
    }

    ngOnDestroy(): void {
        this.activeSubscriptions?.map((sub) => {
            sub.unsubscribe();
        });
    }

    canAddSetting() {
        return this.runHourSettingList.length < MAX_RUN_HOUR_SETTINGS;
    }

    async addSetting() {
        const projectId = this.projectService.getProjectId();
        try {
            const runHourSetting = new RunHourSetting();
            runHourSetting.projectId = projectId;
            this.runHourSettingList.push(runHourSetting);
        } catch (err) {
            console.log(err);
        }
    }

    async deleteSetting() {
        try {
            const index = this.runHourSettingList.findIndex(
                (runHourSetting) => runHourSetting === this.selectedSetting,
            );
            if (index !== -1) {
                this.runHourSettingList.splice(index, 1);
                this.selectedSetting = null;
                this.notificationService.successMessage(
                    'run-hour-settings.notification.success.delete',
                );
                const updatedSettings = this.getChangedSettings();
                console.log('updatedSettings', updatedSettings);
            }
        } catch (err) {
            this.notificationService.errorMessage('run-hour-settings.notification.error.delete');
            console.error(err);
        }
    }

    saveList() {
        try {
            const updatedSettings = this.getChangedSettings();
            const deletedSettings = this.getDeletedSettings();

            updatedSettings.forEach(async (runHourSetting: RunHourSetting) => {
                await this.runHourSettingsService.update(runHourSetting);
            });

            deletedSettings.forEach(async (runHourSetting: RunHourSetting) => {
                await this.runHourSettingsService.delete(runHourSetting.id);
            });
            this.initalRunHourList = _.cloneDeep(this.runHourSettingList);
            this.notificationService.successMessage('run-hour-settings.notification.success.save');
        } catch (err) {
            this.notificationService.successMessage('run-hour-settings.notification.success.save');
            console.error(err);
        }
    }

    async exit() {
        if (this.settingsListHasChanged()) {
            this.confirmationWindow.setVisible(true);
            const closeWidndow = await lastValueFrom(
                this.confirmationWindow.onOptionSelected.pipe(take(1)),
            );
            if (closeWidndow) {
                const projectId = this.projectService.getProjectId();
                this.router.navigate(['editor', projectId]);
            }
        } else {
            const projectId = this.projectService.getProjectId();
            this.router.navigate(['editor', projectId]);
        }
    }

    settingsListHasChanged() {
        return !_.isEqual(this.initalRunHourList, this.runHourSettingList);
    }

    getChangedSettings() {
        const updatedObjects = [];

        this.runHourSettingList.forEach((newObj) => {
            const oldObj = this.initalRunHourList.find((o) => o.id === newObj.id);
            if (oldObj && !_.isEqual(oldObj, newObj)) {
                updatedObjects.push(newObj);
            } else if (!oldObj) {
                updatedObjects.push(newObj);
            }
        });

        return updatedObjects;
    }

    getDeletedSettings() {
        const deletedSettings = [];

        this.initalRunHourList.forEach((initalSetting) => {
            const foundSetting = this.runHourSettingList.find((o) => o.id === initalSetting.id);
            if (!foundSetting) {
                deletedSettings.push(initalSetting);
            }
        });

        return deletedSettings;
    }

    saveButtonIsDisabled() {
        return this.settingListhasErrors() || !this.settingsListHasChanged();
    }

    settingListhasErrors() {
        let hasErrors = false;
        this.runHourSettingList.map((runHourSetting: RunHourSetting) => {
            const errors = getValidationErrors(RunHourSettingValidator(), runHourSetting);
            if (errors.length > 0) {
                hasErrors = true;
            } else {
                let filteredChannels = this.channels.filter((channel) => {
                    if (channel.fcuData.fcuChannelId === runHourSetting.runHourChannel) {
                        if (this.isDigitalChannel(channel.fcuData.channelType)) {
                            return false;
                        }
                        return true;
                    }
                    return false;
                });

                if (filteredChannels.length === 0) {
                    hasErrors = true;
                }

                filteredChannels = this.channels.filter((channel) => {
                    if (channel.fcuData.fcuChannelId === runHourSetting.sourceChannel) {
                        if (this.isDigitalChannel(channel.fcuData.channelType)) {
                            return false;
                        }
                        return true;
                    }
                    return false;
                });
                if (filteredChannels.length === 0) {
                    hasErrors = true;
                }
            }
        });

        return hasErrors;
    }

    handleSelectedChanged(runHourSetting) {
        if (runHourSetting) {
            this.selectedSetting = runHourSetting;
        } else {
            this.selectedSetting = null;
        }
    }

    isDigitalChannel(channelType) {
        const digitalChannelTypes = new Set([
            ChannelType.DIGITAL_MOTOR,
            ChannelType.DIGITAL_NORMAL_CLOSE,
            ChannelType.DIGITAL_NORMAL_OPEN,
        ]);
        return digitalChannelTypes.has(channelType);
    }
}
