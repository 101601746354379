//A list of strings in the i8n file to send the error to.
//Note that some of these may have a prefix added in the i8n file
//For example, a schema may throw different errors depending on
// where it's validated from, so we will prefix a code such as "excel-import."
// to the key in the i8n file for that source.
export var GenericErrorStrings = {
    UNKNOWN_ERROR: 'errors.validation.unknown',
    GENERIC_INVALID_VALUE: 'errors.validation.generic.invalid-value',
    MISSING_REQUIRED_ATTRIBUTE: 'errors.validation.generic.missing-required',
    BELOW_MINIMUM: 'errors.validation.generic.below-minimum',
    ABOVE_MAXIMUM: 'errors.validation.generic.above-maximum',
    NON_INTEGER: 'errors.validation.generic.non-integer',
    NUMBER_NOT_BELOW: 'errors.validation.generic.number-not-below',
    NUMBER_NOT_ABOVE: 'errors.validation.generic.number-not-above',
    NON_POSITIVE_NUMBER: 'errors.validation.generic.non-positive-number',
    NON_NEGATIVE_NUMBER: 'errors.validation.generic.non-negative-number',
    ARRAY_TOO_LONG: 'errors.validation.generic.array-too-long',
    ARRAY_SIZE_MISMATCH: 'errors.validation.generic.array-size-mismatch',
    NOT_WITHIN_ALLOWED_VALUES: 'errors.validation.generic.not-within-allowed-values',
    ARRAY_TOO_SHORT: 'errors.validation.generic.array-too-short',
    NON_STRING: 'errors.validation.generic.non-string',
    NOT_NULL: 'errors.validation.generic.not-null',
    NOT_VALID_IP: 'errors.validation.generic.not-valid-Ip',
};
