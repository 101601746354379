import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
    ChangeDetectorRef,
    Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Hmi, View } from 'common/models/view';
import { FuxaViewComponent, GaugesManager } from 'ngx-fuxa';
import { TesterComponent } from '@components/tester/tester.component';
import { ProjectService } from '@service/project.service';
import { TesterService } from '@components/tester/tester.service';
import { DeviceListService } from '@service/device-list.service';
import { DeviceFlags } from 'icreate/common/models/device-flags';
import { Location } from '@angular/common';
import { NavigationService } from '@service/navigation.service';
import { LocalStorageService } from '@service/local-storage.service';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { UrlParserService } from '@service/url-parser.service';

declare let SVG: any;
declare let Raphael: any;

@Component({
    // moduleId: module.id,
    templateUrl: 'lab.component.html',
    styleUrls: ['./lab.component.css'],
})
export class LabComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('messagecontainer', { read: ViewContainerRef })
    entry: ViewContainerRef;
    @ViewChild('tester') tester: TesterComponent;
    @ViewChild('fuxaview') fuxaView: FuxaViewComponent;

    isLoading = true;
    currentView: View = new View();
    hmi: Hmi = new Hmi();
    svgMain: any;
    componentRef: any;
    labView: View = null;
    backgroudColor = 'black';
    elementsObj = {};
    projectId: string = '';
    activeViewId: string = '';
    getUpdatedTagValue: Observable<DeviceFlags>;

    private subscriptionLoad: Subscription;

    constructor(
        private projectService: ProjectService,
        private gaugesManager: GaugesManager,
        private changeDetector: ChangeDetectorRef,
        private testerService: TesterService,
        private deviceListService: DeviceListService,
        private location: Location,
        private localStorageService: LocalStorageService,
        private router: Router,
        private urlParserService: UrlParserService,
    ) {
        this.getUpdatedTagValue = this.deviceListService.onGetUpdatedTagValue();
    }

    ngOnInit() {
        this.getParserUrl();
    }

    ngAfterViewInit() {
        try {
            this.loadHmi();
        } catch (err) {
            console.error(err);
        }
    }

    ngOnDestroy() {
        try {
            if (this.subscriptionLoad) {
                this.subscriptionLoad.unsubscribe();
            }
        } catch (e) {}
    }

    getProjectId() {
        let pathTree = this.router.parseUrl(this.location.path());
        const g: UrlSegmentGroup = pathTree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;
        if (s.length > 1) {
            return s[1].path;
        } else {
            return this.localStorageService.getCurrentProjectId();
        }
    }

    getParserUrl() {
        const urlSegments = this.urlParserService.getSegment(this.location.path());
        this.projectId = urlSegments[1].path;
        this.activeViewId = urlSegments[1].parameters.view;
    }

    onTest() {
        const svgCanvas = new DOMParser().parseFromString(this.labView.svgcontent, 'text/xml');
        for (const item in this.labView.items) {
            if (svgCanvas.querySelector('#' + item) && !item.startsWith('g-svg')) {
                this.elementsObj[item] = this.labView.items[item];
            }
        }
        this.tester.setSignals(this.elementsObj);
        this.testerService.toggle(true);
    }

    private async loadHmi() {
        let projectData = await this.projectService.getProjectData(this.projectId);
        this.hmi = projectData.hmi;
        if (this.hmi && this.hmi.views && this.hmi.views.length > 0) {
            for (let i = 0; i < this.hmi.views.length; i++) {
                if (this.hmi.views[i].id === this.activeViewId) {
                    this.currentView = this.hmi.views[i];
                    this.labView = this.hmi.views[i];
                    this.setBackground();
                    break;
                } else {
                    this.currentView = this.hmi.views[0];
                    this.labView = this.hmi.views[0];
                }
            }
        }
        this.isLoading = false;
        this.changeDetector.detectChanges();
    }

    private setBackground() {
        if (this.currentView && this.currentView.profile) {
            this.backgroudColor = this.currentView.profile.bkcolor;
        }
    }
}
