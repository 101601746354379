import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectService } from '@service/project.service';
import { ProjectVersionSelectorService } from '@service/project-version-selector.service';
import { VesselBuilderSelectService } from '@service/vessel-builder-select.service';
import { VesselSelectService } from '@service/vessel-select.service';
import { ProjectData } from 'icreate/common/models/project';
import { ResWebApiService } from '@service/reswebapi.service';
import { BlobStorageApiService } from '@service/blob-storage-api.service';

@Component({
    selector: 'app-project-version-selector',
    templateUrl: './project-version-selector.component.html',
    styleUrls: ['./project-version-selector.component.scss'],
})
export class ProjectVersionSelectorComponent implements OnInit {
    projectData: ProjectData;
    loadingInputData: boolean = false;
    @Input() projectVersion: string;
    @Output() onVersionSelected: EventEmitter<string> = new EventEmitter<string>();
    @Output() onDisablePrint: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private projectService: ProjectService,
        private resWebApiService: ResWebApiService,
        private projectVersionSelectorService: ProjectVersionSelectorService,
        private vesselSelectService: VesselSelectService,
        private vesselBuilderSelectService: VesselBuilderSelectService,
        private blobStorageApiService: BlobStorageApiService,
    ) {}

    async ngOnInit() {
        this.loadingInputData = true;
        this.onDisablePrint.emit(true);
        if (this.projectVersion && this.projectData) {
            this.projectData.AMSVersion = this.projectVersion;
        } else {
            const projectId = this.projectService.getProjectId();
            this.projectData = await this.projectService.getProjectData(projectId);
            if (this.projectData.VesselID && this.projectData.VesselBuilderCompanyID) {
                const builderId = this.projectData.VesselBuilderCompanyID;
                const vesselId = this.projectData.VesselID;
                const versionList: [] = await this.blobStorageApiService.getVersionList(
                    builderId,
                    vesselId,
                );
                if (versionList) {
                    this.projectData.AMSVersion = versionList[versionList.length - 1];
                    this.onVersionSelected.emit(versionList[versionList.length - 1]);
                }
            }
        }
        this.loadingInputData = false;
        this.onDisablePrint.emit(false);
    }

    async projectVersionList() {
        if (this.loadingInputData) {
            return;
        }
        this.onDisablePrint.emit(true);
        const projectId = this.projectService.getProjectId();
        const projectData = await this.projectService.getProjectData(projectId);
        if (projectData.VesselID && projectData.VesselBuilderCompanyID) {
            const blobVersion = await this.projectVersionSelectorService.openVersionList(
                this.projectData,
            );
            if (blobVersion) {
                this.projectData.AMSVersion = blobVersion;
                this.onVersionSelected.emit(blobVersion);
            }
        } else {
            const vesselBuilder = await this.vesselBuilderSelectService.openBuilderSelectDialog();
            this.projectData.VesselBuilderCompanyID = vesselBuilder.id;
            this.projectData.VesselBuilderCompanyName = vesselBuilder.name;
            const vessel = await this.vesselSelectService.openVesselSelectDialog(
                vesselBuilder.id,
                vesselBuilder.name,
            );
            this.projectData.VesselID = vessel.id;
            this.projectData.VesselName = vessel.name;
            await this.resWebApiService.setProjectBuilderAndVessel(
                vesselBuilder,
                vessel,
                projectId,
            );
            const blobVersion = await this.projectVersionSelectorService.openVersionList(
                this.projectData,
            );
            if (blobVersion) {
                this.projectData.AMSVersion = blobVersion;
                this.onVersionSelected.emit(blobVersion);
            }
        }
        this.onDisablePrint.emit(false);
    }
}
