import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogDocNameComponent } from '@components/editor/dialog-doc-name/dialog-doc-name.component';
import { DialogDocPropertyComponent } from '@components/editor/dialog-doc-property/dialog-doc-property.component';
import { ConfirmDialogComponent } from '@components/editor/gui-helpers/confirm-dialog/confirm-dialog.component';
import { Utils } from '@helpers/utils';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@service/auth.service';
import { EditFlgService, EditView } from '@service/editflg.service';
import { ProjectService } from '@service/project.service';
import { SocketService } from '@service/socket.service';
import { ViewService } from '@service/view.service';
import { Hmi, View, ViewType } from 'common/models/view';
import FileSaver from 'file-saver';

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.css'],
})
export class ViewComponent {
    readonly svgViewType = Utils.getEnumKey(ViewType, ViewType.svg);
    @Input() view: View;
    @Input() hmi: Hmi;
    @Input() editingView: Map<string, EditView>;

    @Output() onSetPDF = new EventEmitter();
    @Output() onFinishEdit = new EventEmitter();
    @Output() onSelectView = new EventEmitter();

    @Output() onSaveView = new EventEmitter<View>();

    canvas;
    loadingPDFViews: Boolean = false;

    constructor(
        public dialog: MatDialog,
        private translateService: TranslateService,
        private projectService: ProjectService,
        private viewService: ViewService,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        this.canvas = this.viewService.getViewCanvas();
    }

    stopClickEvent(event: MouseEvent) {
        event.stopPropagation();
    }

    onRenameView(view: View) {
        const exist = this.hmi.views
            .filter((v: View) => v.id !== view.id)
            .map((v: View) => {
                return v.name;
            });
        const dialogRef = this.dialog.open(DialogDocNameComponent, {
            position: { top: '60px' },
            data: { name: view.name, exist: exist },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.name) {
                view.name = result.name;
                this.onSaveView.emit(view);
            }
        });
    }

    onDeleteView(view: View) {
        let msg = '';
        this.translateService
            .get('msg.view-remove', { value: view.name })
            .subscribe((txt: string) => {
                msg = txt;
            });
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: { msg: msg },
            position: { top: '60px' },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && this.hmi.views) {
                let toselect = null;
                for (let i = 0; i < this.hmi.views.length; i++) {
                    if (this.hmi.views[i].id === view.id) {
                        this.hmi.views.splice(i, 1);
                        if (i > 0 && i < this.hmi.views.length) {
                            toselect = this.hmi.views[i];
                        }
                        break;
                    }
                }
                if (toselect) {
                    this.onSelectView.emit(toselect);
                } else if (this.hmi.views.length > 0) {
                    this.onSelectView.emit(this.hmi.views[0]);
                }
                this.projectService.removeView(this.projectService.getProjectId(), view);
            }
        });
    }

    onPropertyView(view: View) {
        const dialogRef = this.dialog.open(DialogDocPropertyComponent, {
            position: { top: '60px' },
            data: { name: view.name, type: view.type, profile: view.profile },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.profile) {
                if (result.profile.height) view.profile.height = parseInt(result.profile.height);
                if (result.profile.width) view.profile.width = parseInt(result.profile.width);
                if (result.profile.margin >= 0)
                    view.profile.margin = parseInt(result.profile.margin);
                view.profile.bkcolor = result.profile.bkcolor;
                this.canvas.setResolution(
                    (view.profile.width - 50) * 0.8,
                    (view.profile.height - 65) * 0.8,
                );
                this.onSelectView.emit(view);
            }
        });
    }

    onCloneView(view: any) {
        if (view) {
            const nn = 'View_';
            let idx = 1;
            for (idx = 1; idx < this.hmi.views.length + 2; idx++) {
                let found = false;
                for (let i = 0; i < this.hmi.views.length; i++) {
                    if (this.hmi.views[i].name === nn + idx) {
                        found = true;
                        break;
                    }
                }
                if (!found) break;
            }

            const { renamedId, newItems } = this.viewService.renameSvgItemId(view);
            const v = this.viewService.replaceSvgContentId(renamedId, view, newItems);
            v.id = Utils.getGUID();
            v.name = nn + idx;
            this.hmi.views.push(v);
            this.onSelectView.emit(v);
            this.onSaveView.emit(v);
        }
    }

    onExportView(view: View) {
        const filename = 'fuxa-view.json';
        const date = new Date();
        const content = JSON.stringify(view);
        const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, filename);
    }

    isEditingView(view: View) {
        let editingView = this.editingView.get(view.id);
        return editingView;
    }

    isLocking(view: View) {
        let editingView = this.editingView.get(view.id);
        return editingView
            ? editingView.editor.oid !== this.authService.getCurrentUser().oid
            : false;
    }
}
