import { EventEmitter, Injectable } from '@angular/core';
import { CanvasService } from './canvas.service';
import { deletePaths } from './connector/connector-utils';

@Injectable()
export class UndoManagerService {
    constructor(private canvasService: CanvasService) {}

    handleUndo() {
        const canvas = this.canvasService.getCanvas();
        const { undoMgr } = canvas;
        if (undoMgr.getUndoStackSize() > 0) {
            this.redrawConnectionUndoMovement(canvas, undoMgr);
            undoMgr.undo();
        }
    }

    handleRedo() {
        const canvas = this.canvasService.getCanvas();
        const { undoMgr } = canvas;
        if (undoMgr.getRedoStackSize() > 0) {
            undoMgr.redo();
            this.redrawConnectionRedoMovement(canvas);
        }
    }

    private redrawConnectionUndoMovement(canvas, undoMgr) {
        const undoAction =
            canvas.undoMgr.undoStack[canvas.undoMgr.undoStackPointer - 1];
        if (undoAction) {
            if (
                undoAction.elem &&
                undoAction.text !== 'delete connector path'
            ) {
                if (undoAction.elem.id.startsWith('connect-')) {
                    undoMgr.undo();
                    this.redrawConnectionUndoMovement(canvas, undoMgr);
                }
            } else if (undoAction.text === 'Delete Elements') {
                if (undoAction.stack[0].elem.getAttribute('pointPosition')) {
                    canvas.undoMgr.undo();
                    this.redrawConnectionUndoMovement(canvas, undoMgr);
                }
            }
        }
    }

    private redrawConnectionRedoMovement(canvas) {
        const undoAction =
            canvas.undoMgr.undoStack[canvas.undoMgr.undoStackPointer];
        if (undoAction) {
            if (undoAction.elem) {
                if (
                    undoAction.elem.id.startsWith('connect-') &&
                    undoAction.text === 'Change path'
                ) {
                    canvas.undoMgr.redo();
                    this.redrawConnectionRedoMovement(canvas);
                }
            }
            if (undoAction.text === 'Delete Elements') {
                if (undoAction.stack[0].elem.getAttribute('pointPosition')) {
                    canvas.undoMgr.redo();
                }
            }
        } else if (
            canvas.undoMgr.undoStack[canvas.undoMgr.undoStackPointer - 1]
                .text === 'Delete Elements'
        ) {
            const elementDeleted =
                canvas.undoMgr.undoStack[canvas.undoMgr.undoStackPointer - 1]
                    .stack[0].elem;
            deletePaths(elementDeleted, canvas);
        }
    }
}
