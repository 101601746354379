<div *ngIf="this.devices">
  <div class="container">
    <div
      class="filter"
      *ngIf="deviceSelected"
      style="color: var(--formInputColor)"
    >
      <button
        mat-icon-button
        title="Device Map"
        (click)="onGoBack()"
        style="margin-right: 10px; margin-left: -10px"
      >
        <mat-icon aria-label="Show devices map">arrow_back</mat-icon>
      </button>
      <div class="my-form-field" style="padding-right: 10px">
        <span>{{ "device.list-device" | translate }}</span>
        <mat-select
          [(value)]="deviceSelected"
          style="width: 300px"
          (selectionChange)="onDeviceChange($event.source)"
        >
          <mat-option *ngFor="let device of devicesValue()" [value]="device">
            {{ device.name }}
          </mat-option>
        </mat-select>
      </div>
      <div class="my-form-field" style="padding-right: 10px">
        <span>{{ "device.list-filter" | translate }}</span>
        <input
          (keyup)="applyFilter($event.target.value)"
          style="width: 450px"
          type="text"
        />
      </div>
    </div>
    <mat-table #table [dataSource]="dataSource" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="channelNo">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "device.list-channelNo" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ getTagLabel(element) }}
        </mat-cell>
      </ng-container>

      <!-- Address Column -->
      <ng-container matColumnDef="channel-name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "device.list-channel-name" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ getAddress(element) }}
        </mat-cell>
      </ng-container>

      <!-- Device Column -->
      <ng-container matColumnDef="FCU-No">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "device.list-FCU-No" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.deviceName }}
        </mat-cell>
      </ng-container>

      <!-- Device Column -->
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "device.list-type" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.type }}
        </mat-cell>
      </ng-container>

      <!-- Min Column -->
      <ng-container matColumnDef="min">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "device.list-min" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.min }}
        </mat-cell>
      </ng-container>

      <!-- Max Column -->
      <ng-container matColumnDef="max">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "device.list-max" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.max }}
        </mat-cell>
      </ng-container>

      <!-- Value Column -->
      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "device.list-value" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.value }}
        </mat-cell>
      </ng-container>

      <!-- Logger Column -->
      <ng-container matColumnDef="logger">
        <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div *ngIf="element.daq && element.daq.enabled">
            <mat-icon>storage</mat-icon>
            <span
              *ngIf="element.daq.interval"
              style="display: inline-block; vertical-align: super"
            >
              {{ element.daq.interval }} s
            </span>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Graph Column -->
      <ng-container matColumnDef="graph">
        <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon>multiline_chart</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- Warning Column -->
      <ng-container matColumnDef="warning">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [matTooltip]="element.error ? element.error : null"
          matTooltipPosition="left"
        >
          <button mat-icon-button *ngIf="element.error" class="warning">
            <mat-icon>warning_amber</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Button edit options row -->
      <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <!-- <button mat-icon-button [matMenuTriggerFor]="optionsgMenu" (click)="$event.stopPropagation();editOptions(element)" class="options" -->
          <button
            *ngIf="!readonly"
            mat-icon-button
            class="options"
            matTooltip="{{ 'device.list-options' | translate }}"
          >
            <!-- (click)="$event.stopPropagation(); onEditOptions(element)" -->
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- <mat-menu #optionsgMenu [overlapTrigger]="false">
                    <button mat-menu-item (click)="editOptions(element)" style="font-size: 14px;">{{'device.list-options' | translate}}</button>
                </mat-menu>-->
        </mat-cell>
      </ng-container>

      <!-- Button remove Column -->
      <ng-container matColumnDef="remove">
        <mat-header-cell *matHeaderCellDef [ngClass]="'selectidthClass'">
          <button
            *ngIf="!readonly"
            mat-icon-button
            (click)="onRemoveAll()"
            class="remove"
            matTooltip="{{ 'device.list-remove-all' | translate }}"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            *ngIf="!readonly"
            mat-icon-button
            (click)="$event.stopPropagation(); onRemoveRow(element)"
            class="remove"
            matTooltip="{{ 'device.list-remove' | translate }}"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns"
        [ngStyle]="{ 'min-width.px': tableWidth }"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="my-mat-row"
        [ngStyle]="{ 'min-width.px': tableWidth }"
      ></mat-row>
    </mat-table>
    <mat-paginator
      [length]="tagListLength"
      [pageSizeOptions]="pageSizeOptions"
      aria-label="Select page"
      [pageSize]="pageSize"
      style="position: relative; right: 100px"
      (page)="onPaginatorClick($event)"
    >
    </mat-paginator>
  </div>
</div>
