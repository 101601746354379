import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '@components/editor/gui-helpers/confirm-dialog/confirm-dialog.component';
import { DeviceType } from '@models/hmi';
import { TranslateService } from '@ngx-translate/core';
import { DeviceListService } from '@service/device-list.service';
import { ICreateHmiService } from '@service/hmi.service';
import { ProjectService } from '@service/project.service';
import { Device, Tag } from 'icreate/common/models/device';
import { ProjectData } from 'icreate/common/models/project';

@Component({
    selector: 'app-device-list',
    templateUrl: './device-list.component.html',
    styleUrls: ['./device-list.component.css'],
})
export class DeviceListComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly defAllColumns = [
        'channelNo',
        'channel-name',
        'FCU-No',
        'value',
        'warning',
        'logger',
        'options',
        'remove',
    ];
    readonly defClientColumns = [
        'channelNo',
        'channel-name',
        'FCU-No',
        'type',
        'value',
        'warning',
        'remove',
    ];
    readonly defInternalColumns = ['channelNo', 'FCU-No', 'type', 'value', 'options', 'remove'];
    readonly defAllRowWidth = 1400;
    readonly defClientRowWidth = 1400;
    readonly defInternalRowWidth = 1200;

    displayedColumns = this.defAllColumns;

    dataSource = new MatTableDataSource([]);
    devices: Device[];
    dirty: boolean = false;
    deviceType = DeviceType;
    tableWidth = this.defAllRowWidth;
    tagsToValueMap = {};
    reloadActive = false;
    curretnProject: ProjectData = new ProjectData();

    private subscriptions = [];

    tagListLength = 0;
    pageSizeOptions = [10, 25, 100];
    pageSize = 25;
    pageIndex = 0;

    @Input() deviceSelected: Device;
    @Input() readonly = false;
    @Input() currentProjectId: string;
    @Output() save = new EventEmitter();
    @Output() goto = new EventEmitter();

    @ViewChild(MatTable) table: MatTable<any>;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private dialog: MatDialog,
        private hmiService: ICreateHmiService,
        private translateService: TranslateService,
        private changeDetector: ChangeDetectorRef,
        private projectService: ProjectService,
        private deviceListService: DeviceListService,
    ) {}

    ngOnInit() {
        this.loadCurrentProject(this.deviceSelected);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    ngAfterViewInit() {
        if (!this.deviceSelected && this.devices) {
            this.deviceSelected = this.devices[0];
        }
        this.mapTags();
        if (this.deviceSelected) {
            this.bindToTable(this.deviceSelected.tags);
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.table?.renderRows();
        this.changeDetector.detectChanges();
    }

    async loadCurrentProject(device) {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.curretnProject = await this.projectService.getProjectData(this.currentProjectId);
        if (this.curretnProject && this.curretnProject.amsConfig[1]) {
            this.devices = this.curretnProject.amsConfig[1].device;
        }

        if (device) {
            this.deviceSelected = device;
        }

        if (this.deviceSelected) {
            if (this.deviceSelected.type === 'AmsInterface') {
                if (this.deviceSelected) {
                    this.bindToTable(this.deviceSelected.tags);
                    this.subscribeToUpdateTags();
                    this.subscribeToUpdateTagValue();
                }
            }
        }
    }

    public subscribeToUpdateTags() {
        const subscription = this.deviceListService.onGetTags().subscribe({
            next: (data: { id; values }) => {
                if (this.deviceSelected.name == data.values.deviceName) {
                    this.deviceSelected.tags[data.values.id] = data.values;
                    this.bindToTable(this.deviceSelected.tags);
                }
            },
        });
        this.subscriptions.push(subscription);
    }

    public subscribeToUpdateTagValue() {
        const subscription = this.deviceListService.onGetUpdatedTagValue().subscribe({
            next: (data: { id; value }) => {
                if (this.deviceSelected) {
                    const deviceChannels = Object.keys(this.deviceSelected.tags);
                    for (let i = 0; i < deviceChannels.length; i++) {
                        if (deviceChannels[i] == data.id) {
                            this.deviceSelected.tags[deviceChannels[i]].value = data['value'];
                        }
                    }
                }
            },
        });

        this.subscriptions.push(subscription);
    }

    mapTags() {
        if (this.devices) {
            Object.values(this.devices).forEach((d) => {
                Object.values(d.tags).forEach((t: Tag) => {
                    this.tagsToValueMap[t.id] = t;
                });
            });
        }
    }

    private bindToTable(tags) {
        const tagsValues = Object.values(tags);
        this.tagListLength = tagsValues.length;
        this.dataSource.data = tagsValues;
    }

    onPaginatorClick(event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.bindToTable(this.deviceSelected.tags);
    }

    onDeviceChange(source) {
        this.dataSource.data = [];
        this.deviceSelected = source.value;
        this.loadCurrentProject(this.deviceSelected);
        this.updateDeviceValue();
    }

    async setSelectedDevice(device: Device) {
        if (this.deviceSelected && this.deviceSelected.id === device.id) {
            if (Object.keys(this.deviceSelected.tags).length > Object.keys(device.tags).length) {
                await this.loadCurrentProject(this.deviceSelected);
                this.updateDeviceValue();
            }
        } else {
            await this.loadCurrentProject(device);
            this.updateDeviceValue();
            setTimeout(() => {
                const deviceReload = this.projectService.getDeviceFromId(
                    this.curretnProject,
                    device.id,
                );
                if (Object.keys(deviceReload.tags).length > Object.keys(device.tags).length) {
                    this.loadCurrentProject(deviceReload);
                }
            }, 2000);
        }

        this.displayedColumns = this.defAllColumns;
        this.tableWidth = this.defAllRowWidth;
    }

    onGoBack() {
        this.goto.emit();
    }

    onRemoveRow(row) {
        const index = this.dataSource.data.indexOf(row, 0);
        if (this.dataSource.data[index]) {
            delete this.deviceSelected.tags[this.dataSource.data[index].id];
        }
        this.bindToTable(this.deviceSelected.tags);
    }

    onRemoveAll() {
        let msg = '';
        this.translateService.get('msg.tags-remove-all').subscribe((txt: string) => {
            msg = txt;
        });
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: { msg: msg },
            position: { top: '60px' },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.clearTags();
            }
        });
    }

    private clearTags() {
        this.deviceSelected.tags = {};
        this.bindToTable(this.deviceSelected.tags);
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    getTagLabel(tag: Tag) {
        return tag.name;
    }

    getAddress(tag: Tag) {
        return tag.address;
    }

    updateDeviceValue() {
        const sigs = this.hmiService.getAllSignals();
        for (const id in sigs) {
            if (this.tagsToValueMap[id]) {
                this.tagsToValueMap[id].value = sigs[id].value;
                this.tagsToValueMap[id].error = sigs[id].error;
            }
        }
        this.changeDetector.detectChanges();
    }

    devicesValue(): Array<Device> {
        return Object.values(this.devices);
    }
}

export interface Element extends Tag {
    position: number;
}
