import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ViewType } from 'common/models/view';

@Component({
    selector: 'app-dialog-new-doc',
    templateUrl: './dialog-new-doc.component.html',
    styleUrls: ['./dialog-new-doc.component.css'],
})
export class DialogNewDocComponent {
    docType = ViewType;
    constructor(
        public dialogRef: MatDialogRef<DialogNewDocComponent>,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        Object.keys(this.docType).forEach((key) => {
            this.translateService.get(this.docType[key]).subscribe((txt: string) => {
                this.docType[key] = txt;
            });
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    isValid(name): boolean {
        if (!this.data.type) return false;
        if (!this.data.name) return false;
        return this.data.exist.find((n) => n === name) ? false : true;
    }
}
