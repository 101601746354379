import { AlarmSetting, AlarmSettingFns } from './alarm-setting';
import { ChannelType } from './channel-type';

export class ChannelFcuData {
    id: string;
    channelId: string;
    fcuChannelId: number;
    channelType: ChannelType;
    fcuNo: number;
    partNo: number;
    measurableRange: {
        max: number;
        min: number;
    };
    normalRange: {
        max: number;
        min: number;
    };
    scale: number;
    inputData: {
        fuInputAddress: {
            fuNo: number;
            slotNo: number;
            pin: number;
            pinCount: number;
        };
        memoryInputAddress: {
            address: number;
            mask: number;
        };
        misc: {
            digitalInputFilter: number;
            hysteresis: number;
        };
    };
    outputData: {
        fuOutputAddress: {
            fuNo: number;
            slotNo: number;
            pin: number;
            pinCount: number;
        };
        memoryOutputAddress: {
            address: number;
            mask: number;
        };
        misc: {
            setPoint: number;
            keepSignal: boolean;
            oneShotSignal: boolean;
            pulseWidth: number;
            hysteresisForOpen: number;
            hysteresisForClose: number;
        };
    };
    alarmSetting: AlarmSetting;
    alarmSettingVeryHigh: AlarmSetting;
    alarmSettingHigh: AlarmSetting;
    alarmSettingLow: AlarmSetting;
    alarmSettingVeryLow: AlarmSetting;
    alarmSettingSensorFail: AlarmSetting;
    alarmSettingFeedbackError: AlarmSetting;
    fixedDigit: number;
    digitalInputFilter: number;
    standardNameId: string;
}

export var ChannelFcuDataFns = {
    create,
};

function create(): ChannelFcuData {
    return {
        id: '',
        channelId: '',
        fcuChannelId: null,
        channelType: null,
        fcuNo: 0,
        partNo: 0,
        measurableRange: {
            max: 0,
            min: 0,
        },
        normalRange: {
            max: 0,
            min: 0,
        },
        scale: 1,
        inputData: {
            fuInputAddress: {
                fuNo: 0,
                slotNo: 0,
                pin: 0,
                pinCount: 0,
            },
            memoryInputAddress: {
                address: 0,
                mask: 0,
            },
            misc: {
                digitalInputFilter: 0,
                hysteresis: 0,
            },
        },
        outputData: {
            fuOutputAddress: {
                fuNo: 0,
                slotNo: 0,
                pin: 0,
                pinCount: 0,
            },
            memoryOutputAddress: {
                address: 0,
                mask: 0,
            },
            misc: {
                setPoint: 0,
                keepSignal: false,
                oneShotSignal: false,
                pulseWidth: 0,
                hysteresisForOpen: 0,
                hysteresisForClose: 0,
            },
        },
        alarmSetting: AlarmSettingFns.create(),
        alarmSettingVeryHigh: AlarmSettingFns.create(),
        alarmSettingHigh: AlarmSettingFns.create(),
        alarmSettingLow: AlarmSettingFns.create(),
        alarmSettingVeryLow: AlarmSettingFns.create(),
        alarmSettingSensorFail: AlarmSettingFns.create(),
        alarmSettingFeedbackError: AlarmSettingFns.create(),
        fixedDigit: 0,
        digitalInputFilter: 0,
        standardNameId: '0',
    };
}
