import { Injectable } from '@angular/core';
import { CanvasService } from './canvas.service';
import { SvgEditExtension } from '@models/svg-edit-extension';

@Injectable()
export class CanvasExtensionsService {
    constructor(private canvas: CanvasService) {
        this.extensionAddedHandler = this.extensionAddedHandler.bind(this);
    }

    public init() {
        this.canvas.getCanvas().bind('extension_added', this.extensionAddedHandler);
    }

    public install(extension: SvgEditExtension, initParams: any = {}): Promise<void> {
        return this.canvas.getCanvas().addExtension(extension.name, extension.init, initParams);
    }

    public installLegacyExtensions() {
        const extensions = [
            {
                name: 'html-button',
                obj: import(`@assets/lib/extensions/ext-html-button.js`),
            },
            {
                name: 'html-select',
                obj: import(`@assets/lib/extensions/ext-html-select.js`),
            },
            {
                name: 'html-input',
                obj: import(`@assets/lib/extensions/ext-html-input.js`),
            },
            {
                name: 'value',
                obj: import(`@assets/lib/extensions/ext-value.js`),
            },
            {
                name: 'html-slider',
                obj: import(`@assets/lib/extensions/ext-html-slider.js`),
            },
            {
                name: 'html-switch',
                obj: import(`@assets/lib/extensions/ext-html-switch.js`),
            },
            {
                name: 'gauge-progress',
                obj: import(`@assets/lib/extensions/ext-gauge-progress.js`),
            },
            {
                name: 'html-bag',
                obj: import(`@assets/lib/extensions/ext-html-bag.js`),
            },
            {
                name: 'html-chart',
                obj: import(`@assets/lib/extensions/ext-html-chart.js`),
            },
            {
                name: 'lock-element-extension',
                obj: import(`@assets/lib/extensions/ext-locking.js`),
            },
            {
                name: 'ext-grid',
                obj: import(`@assets/lib/extensions/ext-grid.js`),
            },
        ];

        extensions.forEach((ext) => {
            ext.obj.then((result) => {
                const { name = ext.name, init: initfn } = result.default;
                this.canvas.addExtension(
                    {
                        name,
                        init: initfn && initfn.bind(this),
                    },
                    { langParam: 'en' } /** @todo  change to current lng */,
                );
            });
        });

        this.canvas.getCanvas().call('extensions_added');
    }

    private extensionAddedHandler(win, ext) {
        if (!ext) {
            return undefined;
        }
        let cbCalled = false;

        const runCallback = () => {
            if (ext.callback && !cbCalled) {
                cbCalled = true;
                ext.callback.call(this);
            }
        };

        return runCallback();
    }
}
