import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SvgUtilsService {
    public calculateBoundingBox(elements: SVGGraphicsElement[]): {
        x: number;
        y: number;
        width: number;
        height: number;
    } {
        if (!elements || !elements.length) return { x: 0, y: 0, width: 0, height: 0 };

        const boundingBox = this.getBounds(elements[0]);

        for (let i = 1; i < elements.length; i++) {
            const currentElement = elements[i];
            const compare = this.getBounds(currentElement);

            if (compare.leftBound < boundingBox.leftBound)
                boundingBox.leftBound = compare.leftBound;
            if (compare.topBound < boundingBox.topBound) boundingBox.topBound = compare.topBound;
            if (compare.rightBound > boundingBox.rightBound)
                boundingBox.rightBound = compare.rightBound;
            if (compare.bottomBound > boundingBox.bottomBound)
                boundingBox.bottomBound = compare.bottomBound;
        }

        return {
            x: boundingBox.leftBound,
            y: boundingBox.topBound,
            width: boundingBox.rightBound - boundingBox.leftBound,
            height: boundingBox.bottomBound - boundingBox.topBound,
        };
    }

    private getBounds(element: SVGGraphicsElement) {
        const bbox = element.getBBox();

        return {
            leftBound: bbox.x,
            topBound: bbox.y,
            rightBound: bbox.x + bbox.width,
            bottomBound: bbox.y + bbox.height,
        };
    }
}
