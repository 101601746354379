<h2>Vessel Information</h2>
<ul>
  <li *ngFor="let d of data">
    <span class="areaName">{{ d.areaName }} : </span>
    <span class="areaValue" *ngIf="d.areaValue != null">{{ d.areaValue }}</span>
    <span class="noValue" *ngIf="d.areaValue == null">not registered</span>
  </li>
</ul>
<mat-dialog-actions>
  <div style="margin: 0 0 0 auto">
    <button mat-raised-button color="primary" (click)="onNoClick()">OK</button>
  </div>
</mat-dialog-actions>
