import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DeviceType } from '@models/hmi';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@service/app.service';
import { DeviceService } from '@service/device.service';
import { ICreateHmiService } from '@service/hmi.service';
import { ValidationError, getValidationErrors, mapErrors } from 'icreate/common/validators';
import { deviceValidator } from 'icreate/common/validators/device/device-validator';

@Component({
    selector: 'app-device-property',
    templateUrl: './device-property.component.html',
    styleUrls: ['./device-property.component.scss'],
})
export class DevicePropertyComponent implements OnInit, OnDestroy {
    @ViewChild(MatExpansionPanel) panelProperty: MatExpansionPanel;
    securityRadio: any;
    mode: any;
    deviceType: any = {};

    pollingPlcType = [
        { text: '200 ms', value: 200 },
        { text: '500 ms', value: 500 },
        { text: '700 ms', value: 700 },
        { text: '1 sec', value: 1000 },
        { text: '1.5 sec', value: 1500 },
        { text: '2 sec', value: 2000 },
        { text: '3 sec', value: 3000 },
        { text: '4 sec', value: 4000 },
        { text: '5 sec', value: 5000 },
    ];

    pollingType = this.pollingPlcType;

    fcuIds;

    isWithPolling = true;
    isToRemove = false;
    propertyError = '';
    securityMode: any = [];

    result = '';

    private currentProjectId: string;
    validationErrors: { [propertyName: string]: ValidationError } = {};

    constructor(
        private translateService: TranslateService,
        private appService: AppService,
        public dialogRef: MatDialogRef<DevicePropertyComponent>,
        private deviceService: DeviceService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.currentProjectId = data.projectId;
    }

    ngOnInit() {
        this.deviceService.getFcuIds(this.currentProjectId).then((result) => {
            this.fcuIds = result;
        });

        this.isToRemove = this.data.remove;
        if (this.appService.isClientApp || this.appService.isDemoApp) {
            this.isWithPolling = false;
        }
        for (const key in DeviceType) {
            for (let idx = 0; idx < this.data.availableType.length; idx++) {
                if (key.startsWith(this.data.availableType[idx])) {
                    this.deviceType[key] = DeviceType[key];
                }
            }
        }

        this.checkFormErrors();
    }

    ngOnDestroy() {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    onOkClick(): void {
        this.dialogRef.close(this.data.device);
    }

    isValid(device): boolean {
        if (!device.name || !device.type) {
            return false;
        }
        return this.data.exist.find((n) => n === device.name) ? false : true;
    }

    isSecurityMode(sec) {
        if (JSON.stringify(this.mode) === JSON.stringify(sec)) {
            return true;
        } else {
            return false;
        }
    }
    hasErrorString = (propName: string) => {
        return propName in this.validationErrors;
    };

    getErrorString = (propName: string) => {
        if (!this.hasErrorString(propName)) return '';

        const err = this.validationErrors[propName];
        return this.translateService.instant(err.errors[0], err.params);
    };

    checkFormErrors() {
        const errors = getValidationErrors(deviceValidator, this.data.device);
        if (errors) {
            this.validationErrors = mapErrors(errors);
        }
    }
}
