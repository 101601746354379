import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-settings-optimistic-error-prompt',
    template: `<div class="dialog-wrapper">
        <h2>{{ 'dlg.app-settings.optimistic-error.title' | translate }}</h2>
        <div class="button-wrapper">
            <button class="component-large primary caption" (click)="this.dialogRef.close()">
                {{ 'dlg.app-settings.optimistic-error.continue' | translate }}
            </button>
        </div>
    </div>`,
    styleUrls: ['./optimistic-error.component.scss'],
})
export class OptimisticErrorComponent {
    constructor(public dialogRef: MatDialogRef<OptimisticErrorComponent>) {}
}
