<div cdkDropListGroup>
    <div>
        <div *ngFor="let category of viewCategories">
            <div
                cdkDropList
                [cdkDropListData]="category"
                (cdkDropListDropped)="dropInGroup($event, category)"
            >
                <app-view-group
                    [group]="category.group"
                    (onDeleteGroup)="handleDeleteViewGroup($event)"
                    (onEditGroup)="handleEditViewGroup($event)"
                >
                    <div
                        cdkDrag
                        *ngFor="let view of category.views"
                        (cdkDragStarted)="dragStarted(view)"
                        [ngClass]="{
                            'leftbar-item-active': isActiveView(view)
                        }"
                        style="flex: 1"
                        class="leftbar-item mycursor-pointer"
                    >
                        <div
                            *cdkDragPlaceholder
                            style="background-color: white; height: 30px"
                        ></div>
                        <app-view
                            [view]="view"
                            [hmi]="hmi"
                            (onSetPDF)="onSetPdfEmitter($event)"
                            (onFinishEdit)="onFinishEditEmitter($event)"
                            (onSelectView)="onSeletedViewEmitter($event)"
                            (onSaveView)="onSaveViewEmitter($event)"
                            [editingView]="editingView"
                        ></app-view>
                    </div>
                </app-view-group>
            </div>
        </div>
    </div>
    <div>
        <div
            cdkDropList
            [cdkDropListData]="viewList"
            (cdkDropListDropped)="dropInList($event)"
            style="min-height: 30px"
        >
            <div
                cdkDrag
                *ngFor="let item of viewList; let i = index"
                (cdkDragStarted)="dragStarted(item.view)"
                (click)="onSelectedView.emit(item.view)"
                [ngClass]="{
                    'leftbar-item-active': isActiveView(item.view)
                }"
                class="leftbar-item mycursor-pointer"
            >
                <div style="flex: 1" *ngIf="item.type === 'view'">
                    <div
                        *cdkDragPlaceholder
                        style="background-color: white; height: 30px"
                    ></div>
                    <app-view
                        [view]="item.view"
                        [hmi]="hmi"
                        (onSetPDF)="onSetPdfEmitter($event)"
                        (onFinishEdit)="onFinishEditEmitter($event)"
                        (onSelectView)="onSeletedViewEmitter($event)"
                        (onSaveView)="onSaveViewEmitter($event)"
                        [editingView]="editingView"
                    ></app-view>
                </div>
                <div style="height: 30px"></div>
            </div>
        </div>
    </div>
</div>
