import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash'

@Component({
    selector: 'app-channel-group-selector',
    templateUrl: './channel-group-selector.component.html',
    styleUrls: ['./channel-group-selector.component.scss'],
})
export class ChannelGroupSelectorComponent implements OnChanges{
    constructor() {}

    @Input() groupList: Group[];
    @Output() onGroupSelectedChanged: EventEmitter<Group[]> = new EventEmitter<Group[]>()

    showGroups: boolean = false;
    groupsSelected: Group[] = [];
    printAll: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.groupList) {
            this.showGroups = false
            this.printAll = false;
            this.groupsSelected = []
        }
    }

    checkedGroup(group: Group, labelClicked) {
        if (group.checked && labelClicked) {
            this.groupsSelected.push(group);
        }
        if (!group.checked && !labelClicked) {
            group.checked = true;
            this.groupsSelected.push(group);
        } else if (group.checked && !labelClicked) {
            group.checked = false;
            this.groupsSelected.splice(this.groupsSelected.indexOf(group), 1);
        } else if (!group.checked && labelClicked) {
            this.groupsSelected.splice(this.groupsSelected.indexOf(group), 1);
        }
        this.printAll = this.allGroupsSelected()
        this.onGroupSelectedChanged.emit(this.groupsSelected)
    }

    unselectGroup(selectedGroup) {
        this.groupList.forEach((group: Group) => {
            if (_.isEqual(group , selectedGroup)) {
                group.checked = false;
                this.groupsSelected.splice(this.groupsSelected.indexOf(selectedGroup), 1);
                this.onGroupSelectedChanged.emit(this.groupsSelected)
            }
        });
        this.printAll = this.allGroupsSelected()
    }

    selectAllGroups() {
        if (this.printAll) {
            this.groupList.map((group: Group) => {
                if (!group.checked) {
                    this.checkedGroup(group, false);
                }
            });
        } else {
            this.groupList.map((group: Group) => {
                if (group.checked) {
                    this.unselectGroup(group);
                }
            });
        }
        this.onGroupSelectedChanged.emit(this.groupsSelected)
    }

    allGroupsSelected(): boolean {
        const unselectedGroups = this.groupList.filter((group) => {
            return !group.checked;
        });
        return unselectedGroups.length === 0;
    }
}

export class Group {
    name: string;
    checked: boolean;
    id: string;
}
