import * as uuid from 'uuid';

export class FcuSetting {
    id: string;
    projectId: string;
    name: string;
    remarks: string;
    slot1: SlotType;
    slot2: SlotType;
    slot3: SlotType;
    slot4: SlotType;
    slot5: SlotType;
    locked: boolean;
}

export class FuSetting {
    id: string = uuid.v4();
    fcuId: string;
    active: boolean = false;
    name: string = '';
    remarks: string = '';
    fuIndex: number;
    slot1: SlotType = SlotType.Undefined;
    slot2: SlotType = SlotType.Undefined;
    slot3: SlotType = SlotType.Undefined;
    slot4: SlotType = SlotType.Undefined;
    slot5: SlotType = SlotType.Undefined;
    slot6: SlotType = SlotType.Undefined;
    slot7: SlotType = SlotType.Undefined;
    slot8: SlotType = SlotType.Undefined;
}

export enum SlotType {
    Undefined = '',
    DI = 'DI',
    DO = 'DO',
    AI_4 = 'AI(4-20mA)',
    AI_1 = 'AI(1-5V)',
    Temp_PT = 'Temp(Pt100)',
    Temp_K = 'Temp(K)',
    AO = 'AO(4-20mA)',
}

export const MAX_FU = 20;
export const MAX_FCU = 8;
