<div class="alarm-wrap">
    <div *ngIf="selectedChannel" class="alarm-info">
        <p>
            <span>{{ "channel.alarm.label.name" | translate }}</span> :
            {{ selectedChannel.opsData.name }}
        </p>
        <p>
            <span>{{ "channel.alarm.label.tag" | translate }}</span> :
            {{ selectedChannel.opsData.tag }}
        </p>
    </div>
    <div *ngIf="!selectedChannel" class="alarm-wrap-select">
        {{ "channel.alarm.select" | translate }}
    </div>
</div>
