import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { GraphService } from '@service/graphs/graph.service';
import { NotificationService } from '@service/notification.service';
import { Channel } from 'common/models/channel';
import { ChannelType } from 'common/models/channel-type';
import { ExhaustGraph } from 'common/models/graphs';
import { getValidationErrors, mapErrors } from 'common/validators';
import { ExhaustGraphValidator } from 'common/validators/graphs/exhaust-graph/exhaust-graph-validator';
import * as _ from 'lodash';
import { lastValueFrom, take } from 'rxjs';

@Component({
    selector: 'app-exhaust-graph',
    templateUrl: './exhaust-graph.component.html',
    styleUrls: ['./exhaust-graph.component.scss'],
})
export class ExhaustGraphComponent implements OnInit {
    @Input() channels: Channel[];
    @Input() graph: ExhaustGraph;
    @Input() graphListId: string;
    @Output() onExitGraph: EventEmitter<void> = new EventEmitter();
    @ViewChild('confirmationWindow') confirmationWindow: ConfirmationWindowComponent;

    initalSettings: ExhaustGraph;

    constructor(
        private graphService: GraphService,
        private notificationService: NotificationService,
    ) {}

    ngOnInit(): void {
        this.initalSettings = _.cloneDeep(this.graph);
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnload() {
        this.graphService.sendUnlockBeacon(this.graphListId);
        this.onExitGraph.emit();
    }

    handleExhaustTableChange(exhaustChannels) {
        this.graph.exhaustChannels = exhaustChannels;
    }

    handleTurboTableChange(turboChargerChannels) {
        this.graph.turboChargerChannels = turboChargerChannels;
    }

    inputLimit(event, currentValue) {
        const value = event.target.value.trim();
        if (value === '') {
            return '';
        }
        const numValue = Number(value);
        if (isNaN(numValue) || numValue < 0 || numValue > 9999) {
            event.target.value = currentValue;
            return currentValue;
        }
        return numValue;
    }

    async saveGraph() {
        await this.graphService.saveExhaustGraph(this.graph, this.graphListId);
        this.initalSettings = _.cloneDeep(this.graph);
        this.notificationService.successMessage(
            'graph-settings.exhaust-graph.notification.success.save',
        );
    }

    async exit() {
        if (this.settingsHaveChanged()) {
            this.confirmationWindow.setVisible(true);
            const closeWidndow = await lastValueFrom(
                this.confirmationWindow.onOptionSelected.pipe(take(1)),
            );
            if (closeWidndow) {
                this.onExitGraph.emit();
            }
        } else {
            this.onExitGraph.emit();
        }
    }

    settingsHaveChanged() {
        return !_.isEqual(this.initalSettings, this.graph);
    }

    getGraphErrors(key) {
        const errors = getValidationErrors(ExhaustGraphValidator(), this.graph);
        if (errors) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                return validationErrors[key].errors[0];
            }
        }
    }

    saveButtonIsDisabled() {
        const errors = getValidationErrors(ExhaustGraphValidator(), this.graph);
        if (errors.length > 0) {
            return true;
        }
        if (
            this.isChannelMissing(this.channels, this.graph.exhaustChannels) ||
            this.isChannelMissing(this.channels, this.graph.turboChargerChannels)
        ) {
            return true;
        }
        const hasAverageChannel = this.channels.some(
            (channel) => channel.fcuData.fcuChannelId === this.graph.averageChannel,
        );
        if (!hasAverageChannel) {
            return true;
        }

        if (
            this.hasDuplicateTitles(this.graph.turboChargerChannels) ||
            this.hasDuplicateTitles(this.graph.exhaustChannels)
        ) {
            return true;
        }

        return !this.settingsHaveChanged();
    }

    isChannelMissing = (channels, graphChannels) => {
        return graphChannels.some(
            (graphChannel) =>
                !channels.some(
                    (channel) =>
                        channel.fcuData.fcuChannelId === graphChannel.fcuChannelId &&
                        !this.isDigitalChannel(channel.fcuData.channelType),
                ),
        );
    };

    hasDuplicateTitles = (channels) => {
        const titles = channels.map((channel) => channel.title);
        return titles.length !== new Set(titles).size;
    };

    isDigitalChannel(channelType) {
        const digitalChannelTypes = new Set([
            ChannelType.DIGITAL_MOTOR,
            ChannelType.DIGITAL_NORMAL_CLOSE,
            ChannelType.DIGITAL_NORMAL_OPEN,
        ]);
        return digitalChannelTypes.has(channelType);
    }
}
