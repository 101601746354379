<div class="diff-info-header">
    <div class="header-top">
        <div>Selected Version : {{ selectedVersion }}</div>
        <div class="diff-info-status">
            <div class="add">+{{ channelCompareCount.add }}</div>
            <div class="update">~{{ channelCompareCount.update }}</div>
            <div class="delete">-{{ channelCompareCount.delete }}</div>
        </div>
    </div>
    <div class="header-bottom">
        <div>Ver.{{ selectedVersion }}(On Cloud)</div>
        <div>LOCAL</div>
    </div>
</div>
