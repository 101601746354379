import { ValidationError } from 'yup';
import { GenericErrorStrings } from './validation-errors/generic';

let mixed = {
    default: GenericErrorStrings.GENERIC_INVALID_VALUE,
    required: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    defined: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    notNull: GenericErrorStrings.NOT_NULL,
    nonNullable: GenericErrorStrings.NOT_NULL,
    oneOf: GenericErrorStrings.NOT_WITHIN_ALLOWED_VALUES,
    notOneOf: '${path} must not be one of the following values: ${values}',

    notType: GenericErrorStrings.GENERIC_INVALID_VALUE,
};

let string = {
    required: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    defined: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    string: GenericErrorStrings.NON_STRING,
    default: GenericErrorStrings.GENERIC_INVALID_VALUE,
    nonNullable: GenericErrorStrings.NOT_NULL,
    notNull: GenericErrorStrings.NOT_NULL,
    uuid: GenericErrorStrings.GENERIC_INVALID_VALUE,
    length: '${path} must be exactly ${length} characters',
    min: '${path} must be at least ${min} characters',
    max: '${path} must be at most ${max} characters',
    matches: '${path} must match the following: "${regex}"',
    email: GenericErrorStrings.INVALID_EMAIL,
    url: '${path} must be a valid URL',
    trim: '${path} must be a trimmed string',
    lowercase: '${path} must be a lowercase string',
    uppercase: '${path} must be a upper case string',
};

let number = {
    required: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    defined: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    default: GenericErrorStrings.GENERIC_INVALID_VALUE,
    notNull: GenericErrorStrings.NOT_NULL,
    nonNullable: GenericErrorStrings.NOT_NULL,
    min: GenericErrorStrings.BELOW_MINIMUM,
    max: GenericErrorStrings.ABOVE_MAXIMUM,
    lessThan: GenericErrorStrings.NUMBER_NOT_BELOW,
    moreThan: GenericErrorStrings.NUMBER_NOT_ABOVE,
    positive: GenericErrorStrings.NON_POSITIVE_NUMBER,
    negative: GenericErrorStrings.NON_NEGATIVE_NUMBER,
    integer: GenericErrorStrings.NON_INTEGER,
};

let date = {
    required: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    defined: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    min: '${path} field must be later than ${min}',
    max: '${path} field must be at earlier than ${max}',
    notNull: GenericErrorStrings.NOT_NULL,
    nonNullable: GenericErrorStrings.NOT_NULL,
};

let boolean = {
    required: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    defined: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    isValue: '${path} field must be ${value}',
    notNull: GenericErrorStrings.NOT_NULL,
    nonNullable: GenericErrorStrings.NOT_NULL,
};

let object = {
    required: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    defined: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    default: GenericErrorStrings.GENERIC_INVALID_VALUE,
    notNull: GenericErrorStrings.NOT_NULL,
    nonNullable: GenericErrorStrings.NOT_NULL,
    noUnknown: GenericErrorStrings.GENERIC_INVALID_VALUE,
};

let array = {
    required: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    defined: GenericErrorStrings.MISSING_REQUIRED_ATTRIBUTE,
    default: GenericErrorStrings.GENERIC_INVALID_VALUE,
    notNull: GenericErrorStrings.NOT_NULL,
    nonNullable: GenericErrorStrings.NOT_NULL,
    min: GenericErrorStrings.ARRAY_TOO_SHORT,
    max: GenericErrorStrings.ARRAY_TOO_LONG,
    length: GenericErrorStrings.ARRAY_SIZE_MISMATCH,
};

export let tuple = {
    notType: (params: any) => {
        const { path, value, spec } = params;
        const typeLen = spec.types.length;
        if (Array.isArray(value)) {
            if (value.length < typeLen)
                return `${path} tuple value has too few items, expected a length of ${typeLen} but got ${value.length} for value: \`${value}\``;
            if (value.length > typeLen)
                return `${path} tuple value has too many items, expected a length of ${typeLen} but got ${value.length} for value: \`${value}\``;
        }

        return ValidationError.formatError('', params);
    },
};

export var locale = {
    mixed,
    string,
    number,
    date,
    boolean,
    object,
    array,
    // tuple,
};
