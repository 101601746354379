import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { EndPointApi } from '@helpers/endpointapi';

@Injectable()
export class OpsAmsConfigFileService {
    private env: string = environment.env;

    private domain: string;
    private channelConfig: any;
    public needToGetOnce: boolean;

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
        this.needToGetOnce = true;
    }

    public updateChannelConfig() {
        const observer = this.getFromServer();
        observer.subscribe(
            (data) => {
                this.channelConfig = JSON.parse(data);
                this.needToGetOnce = false;
            },
            (e) => {
                if (this.env === 'edge') {
                    console.log(e);
                }
            },
        );
    }

    private getFromServer(): Observable<any> {
        return this.http.get(`${this.domain}/api/ops/channelConfig`);
    }

    public getChannelConfigByChNo(chno: any): any {
        try {
            const config = this.channelConfig.configs.find((d) => d.chno == chno);
            return config;
        } catch {
            return null;
        }
    }
}
