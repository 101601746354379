<div
    *ngFor="let group of channelGroups; let i = index"
    class="group-list"
    [class]="{ active: group.id === activeChannelGroup.id }"
    (click)="selectGroup(group)"
>
    <div>
        <div>{{ i + 1 }}</div>
        <div>{{ group.displayName }}</div>
        <div class="sub-title">
            {{ group.categoryId ? group.channelCategory.category : "" }}
        </div>
    </div>
    <span
        class="material-symbols-outlined more-icon"
        (click)="openMore(group); $event.stopPropagation()"
    >
        more_vert
    </span>

    <div class="more-lists" *ngIf="selectedChannelGroup.id == group.id">
        <app-more-options
            (deleteEmitter)="onDelete()"
            (renameEmitter)="onRename()"
            (duplicateEmitter)="onDuplicate()"
        ></app-more-options>
    </div>
</div>
