<div style="width: 200px; overflow: visible">
  <form #gridForm="ngForm">
    <div mat-dialog-title style="display: inline-block">
      <div
        style="
          font-size: 18px;
          display: inline-block;
          vertical-align: super;
          padding-left: 0px;
        "
      >
        Grid Settings
      </div>
    </div>
    <div mat-dialog-content style="margin-bottom: 10px; overflow: visible">
      <div style="display: block; margin-bottom: 10px">
        <div class="my-form-field grid-field">
          <span>Grid Enabled</span>
          <mat-slide-toggle
            numberOnly
            [(ngModel)]="data.show_grid"
            name="show_grid"
            style="width: 50px; text-align: center; display: inline-block"
            type="text"
          ></mat-slide-toggle>
        </div>
      </div>
      <div style="display: block">
        <div class="my-form-field grid-field">
          <span>Grid Spacing</span>
          <input
            [disabled]="!data.show_grid && !data.grid_snapping"
            [(ngModel)]="data.grid_spacing"
            #grid_spacing="ngModel"
            name="grid_spacing"
            type="number"
            min="1"
            max="999"
            step="1"
            pattern="\d{1,4}"
            required
            style="width: 50px; text-align: center; display: inline-block"
          />
          <div
            *ngIf="
              grid_spacing.invalid &&
              (grid_spacing.dirty || grid_spacing.touched)
            "
            class="alert"
          >
            <div
              *ngIf="grid_spacing.errors?.['required'] && !grid_spacing.errors?.['pattern']"
            >
              Only numbers can be inputed
            </div>
            <div *ngIf="grid_spacing.errors?.['pattern']">
              Grid Spacing must be positive integer
            </div>
            <div
              *ngIf="grid_spacing.errors?.['min'] && !grid_spacing.errors?.['pattern']"
            >
              Grid Spacing must be greater than 1
            </div>
            <div
              *ngIf="grid_spacing.errors?.['max'] && !grid_spacing.errors?.['pattern']"
            >
              Grid Spacing must be less than 1000
            </div>
          </div>
        </div>
        <div class="my-form-field grid-field" style="padding-left: 30px">
          <span>Grid Color</span>
          <input
            [(colorPicker)]="data.grid_color"
            [style.background]="data.grid_color"
            [cpAlphaChannel]="'always'"
            class="input-color"
            [cpPresetColors]="defaultColor"
            [cpOKButton]="true"
            [cpCancelButton]="true"
            [cpCancelButtonClass]="'cpCancelButtonClass'"
            [cpCancelButtonText]="'Cancel'"
            [cpOKButtonText]="'OK'"
            [cpOKButtonClass]="'cpOKButtonClass'"
            style="width: 78px; border-radius: 4px"
            [cpPosition]="'left'"
          />
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="dialog-action">
      <button
        [disabled]="gridForm.invalid"
        mat-raised-button
        [mat-dialog-close]="data"
      >
        {{ "dlg.ok" | translate }}
      </button>
    </div>
  </form>
</div>
