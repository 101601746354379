import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    styleUrls: ['./lab-sidebar.component.css'],
    selector: 'app-mimics-groups',
    template: `
        <div class="dropdown-submenu">
            <span class="dropdown-title">Views</span>
            <a *ngFor="let view of groupData">
                <div
                    [routerLink]="'/ops/mimics'"
                    [routerLinkActive]="'link_active'"
                    (click)="onClickView($event)"
                    [queryParams]="{ id: view.data.id }"
                    class="view-container"
                >
                    <mat-icon class="view-icon"> insert_drive_file </mat-icon>
                    <span class="dropdown-name">{{ view.data.name }}</span>
                </div>
            </a>
        </div>
    `,
})
export class MimicsGroupSubmenuComponent {
    @Input() groupData;
    @Output() stopPropagationEvent = new EventEmitter();
    @Output() toggleDropdownEvent = new EventEmitter();

    onClickView(event: Event) {
        this.stopPropagationEvent.emit(event);
        this.toggleDropdownEvent.emit();
    }
}
