import { Injectable } from '@angular/core';
import { GaugeProperty, Flags, activeFlags } from '@models/hmi';
import { MimicAnimaitonOption } from 'icreate/common/models/mimic-animation-option';
import { Channel, ChannelFns } from 'common/models/channel';
// prettier-ignore
@Injectable({ providedIn: 'root' })
export class MimicAnimationDeciderService {
    public getAnimation(
        properties: GaugeProperty,
        channel: Channel,
    ): MimicAnimaitonOption {
        let enabledProperties = this.aggregateEditorProperties(
            properties.editorProperties,
        );
        let alarmFlags =
            this.aggregateAlarmFlag(properties.flags) & enabledProperties;
        let cancelFlags =
            this.aggregateCancelFlags(properties.flags) & enabledProperties;
        let unAckFlags =
            this.aggregateUnackFlag(properties.flags) & enabledProperties;

        if (
            (alarmFlags && unAckFlags && cancelFlags) ||
            (alarmFlags && cancelFlags)
        ) {
            return MimicAnimaitonOption.NONE;
        } else if (alarmFlags) {
            return unAckFlags
                ? MimicAnimaitonOption.ALARM_UNACK
                : MimicAnimaitonOption.ALARM;
        } else if (cancelFlags) {
            return unAckFlags
                ? MimicAnimaitonOption.ALARM_UNACK
                : MimicAnimaitonOption.CANCEL;
        } else if (unAckFlags) {
            return MimicAnimaitonOption.UNACK;
        } else {
            return channel && ChannelFns.isDigital(channel)
                ? MimicAnimaitonOption.DIGITAL_NONE
                : MimicAnimaitonOption.NONE;
        }
    }

    private aggregateAlarmFlag(flags: Flags) {
        return (
            0 +
            flags.alarm.highHigh      * 1 +
            flags.alarm.high          * 2 +
            flags.alarm.low           * 4 +
            flags.alarm.lowLow        * 8 +
            (flags.alarm.outputSensor??0)  * 16 +
            flags.alarm.feedbackError * 32 +
            flags.alarm.over * 64 +
            flags.alarm.under * 128
        );
    }

    private aggregateCancelFlags(flags: Flags) {
        return (
            this.aggregateCancelFlag(flags.cancelManual) |
            this.aggregateCancelFlag(flags.cancelAuto1) |
            this.aggregateCancelFlag(flags.cancelAuto2) |
            this.aggregateCancelFlag(flags.cancelAuto3)
        );
    }

    private aggregateCancelFlag(cancelFlag: any) {
        return (
            0 +
            cancelFlag.highHigh     * 1 +
            cancelFlag.high         * 2 +
            cancelFlag.low          * 4 +
            cancelFlag.lowLow       * 8 +
            cancelFlag.feedbackError * 32 +
            cancelFlag.over         * 64 +
            cancelFlag.under        * 128
        );
    }

    private aggregateUnackFlag(flags: Flags) {
        return (
            0 +
            flags.unAck.highHigh      * 1 +
            flags.unAck.high          * 2 +
            flags.unAck.low           * 4 +
            flags.unAck.lowLow        * 8 +
            (flags.unAck.outputSensor??0)  * 16 +
            flags.unAck.feedbackError * 32 +
            flags.unAck.over * 64 +
            flags.unAck.under * 128
        );
    }

    private aggregateEditorProperties(properties: activeFlags) {
        return (
            0 +
            (properties.highHigh ? 1 : 0) +
            (properties.high ? 2 : 0) +
            (properties.low ? 4 : 0) +
            (properties.lowLow ? 8 : 0) +
            (properties.sensorFail ? 16 : 0) +
            (properties.feedback ? 32 : 0) +
            (properties.over ? 64 : 0) +
            (properties.under ? 128 : 0)
        );
    }
}
