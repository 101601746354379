import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { GraphService } from '@service/graphs/graph.service';
import { NotificationService } from '@service/notification.service';
import { Channel } from 'common/models/channel';
import { ChannelType } from 'common/models/channel-type';
import { BarDisplayType, BarGraph, BarGraphChannel } from 'common/models/graphs';
import { getValidationErrors, mapErrors } from 'common/validators';
import {
    BarGraphChannelValidator,
    BarGraphValidator,
} from 'common/validators/graphs/bar-graphs/bar-graph-validator';
import * as _ from 'lodash';
import { lastValueFrom, take } from 'rxjs';

@Component({
    selector: 'app-bar-graph',
    templateUrl: './bar-graph.component.html',
    styleUrls: ['./bar-graph.component.scss'],
})
export class BarGraphComponent implements OnInit {
    readonly MAX__BAR_GRAPHS: number = 24; //Not ure if we have max?

    @Input() channels: Channel[];
    @Input() graph: BarGraph;
    @Input() graphListId: string;
    @Output() onExitGraph: EventEmitter<void> = new EventEmitter();
    @ViewChild('confirmationWindow') confirmationWindow: ConfirmationWindowComponent;

    tableDataSource = new MatTableDataSource<BarGraphChannel>();

    displayedColumns: string[] = ['number', 'Ch. No.', 'title'];
    displayTypes: BarGraphDisplay[] = [
        {
            value: BarDisplayType.Range,
            displayString: 'graph-settings.bar-graph.display-type.range',
        },
        {
            value: BarDisplayType.Percentage,
            displayString: 'graph-settings.bar-graph.display-type.percentage',
        },
    ];

    divisionsTypes: BarGraphDivisions[] = [
        { value: 4, displayString: 'graph-settings.bar-graph.division-type.4' },
        { value: 6, displayString: 'graph-settings.bar-graph.division-type.6' },
    ];

    selectedRow: BarGraphChannel;
    showChannels: boolean = false;
    filteredChannelsByInput = [];

    initalSettings: BarGraph;

    constructor(
        private graphService: GraphService,
        private notificationService: NotificationService,
    ) {}

    ngOnInit(): void {
        this.initalSettings = _.cloneDeep(this.graph);
        this.tableDataSource.data = this.graph.channels;
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnload() {
        this.graphService.sendUnlockBeacon(this.graphListId);
        this.onExitGraph.emit();
    }

    selectRow(row) {
        this.showChannels = false;
        this.selectedRow = row;
    }

    canAddChannel() {
        return this.graph.channels.length < this.MAX__BAR_GRAPHS;
    }

    addChannel() {
        const newChannel = new BarGraphChannel();
        this.graph.channels.push(newChannel);
        this.tableDataSource.data = this.graph.channels;
        this.notificationService.successMessage(
            'graph-settings.bar-graph.notification.success.channel-added',
        );
    }

    deleteChannel() {
        const index = this.graph.channels.findIndex((channel) => channel === this.selectedRow);
        if (index !== -1) {
            this.graph.channels.splice(index, 1);
            this.selectedRow = null;
            this.notificationService.successMessage(
                'graph-settings.bar-graph.notification.success.channel-deleted',
            );
        }
        this.tableDataSource.data = this.graph.channels;
    }

    async saveGraph() {
        await this.graphService.saveBarGraph(this.graph, this.graphListId);
        this.initalSettings = _.cloneDeep(this.graph);
        this.notificationService.successMessage(
            'graph-settings.bar-graph.notification.success.save',
        );
    }

    async exit() {
        if (this.settingsHaveChanged()) {
            this.confirmationWindow.setVisible(true);
            const closeWidndow = await lastValueFrom(
                this.confirmationWindow.onOptionSelected.pipe(take(1)),
            );
            if (closeWidndow) {
                this.onExitGraph.emit();
            }
        } else {
            this.onExitGraph.emit();
        }
    }

    unselectRow() {
        this.selectedRow = null;
        this.showChannels = false;
    }

    settingsHaveChanged() {
        return !_.isEqual(this.initalSettings, this.graph);
    }

    getGraphErrors(key) {
        const errors = getValidationErrors(BarGraphChannelValidator(), this.graph);
        if (errors) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                return validationErrors[key].errors[0];
            }
        }
    }

    getChannelErrors(channelRow: BarGraphChannel, key) {
        const errors = getValidationErrors(BarGraphChannelValidator(), channelRow);
        if (errors) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                return validationErrors[key].errors[0];
            } else if (key === 'title') {
                let repeatedTitleError;
                this.graph.channels.map((channels) => {
                    if (channels !== channelRow) {
                        if (channels.title === channelRow.title) {
                            repeatedTitleError = 'graph-settings.graph.error.repeated-title';
                        }
                    }
                });
                if (repeatedTitleError) {
                    return repeatedTitleError;
                }
            }
        }
    }

    saveButtonIsDisabled() {
        const errors = getValidationErrors(BarGraphValidator(), this.graph);
        if (errors.length > 0) {
            return true;
        } else {
            const channelNotFound = this.graph.channels.some((graphChannel) => {
                return !this.channels.some((channel) => {
                    return (
                        channel.fcuData.fcuChannelId === graphChannel.fcuChannelId &&
                        !this.isDigitalChannel(channel.fcuData.channelType)
                    );
                });
            });

            if (channelNotFound) {
                return true;
            }
        }
        return !this.settingsHaveChanged();
    }

    isDigitalChannel(channelType) {
        const digitalChannelTypes = new Set([
            ChannelType.DIGITAL_MOTOR,
            ChannelType.DIGITAL_NORMAL_CLOSE,
            ChannelType.DIGITAL_NORMAL_OPEN,
        ]);
        return digitalChannelTypes.has(channelType);
    }
}

class BarGraphDisplay {
    value: BarDisplayType;
    displayString: string;
}

class BarGraphDivisions {
    value: number;
    displayString: string;
}
