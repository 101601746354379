import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.css'],
})
export class DialogConfirmComponent {
    content: string;
    @Output() confirmEmitter = new EventEmitter<boolean>();

    isShow: boolean;

    onClick(confirm: boolean) {
        this.confirmEmitter.emit(confirm);
        this.onClose();
    }

    open() {
        this.isShow = true;
    }

    onClose() {
        this.isShow = false;
    }

    setContentMsg(msg: string) {
        this.content = msg;
    }
}
