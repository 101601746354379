<div class="top-bar">
  <div class="left-panel">
    <img
      src="assets/images/iops-logo.png"
      style="width: 33px; height: 33px; display: block"
    />
    <div class="alarm-notification">
      <div class="latest-alarm-container">
        <div class="alarms">
          <div class="latest-alarm-noti">
            <div class="alarm-info">
              <app-alarm-status-icon
                [statusText]="'alarm'"
              ></app-alarm-status-icon>
              <p>TEST ALARM NAME</p>
              <span>{{ "2022-09-26 13:52:51" | date : "HH:mm:ss" }}</span>
            </div>
            <button class="ack-btn primary">ACK</button>
          </div>
          <div class="nav-alarm">
            <mat-icon svgIcon="alarm" />
            <p class="alarm">10</p>
            <p class="warn">10</p>
            <p class="caution">10</p>
          </div>
        </div>

        <div>
          <button class="buzzer-btn">
            <p>STOP BUZZER</p>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="right-panel">
    <div class="status-pannel-container">
      <div class="status-pannel">
        <img
          style="display: block"
          [src]="
            statusAlarm
              ? 'assets/icons/status-alarm-good.svg'
              : 'assets/icons/status-alarm-bad.svg'
          "
        />
        <img
          style="display: block"
          [src]="
            statusMaintenance
              ? 'assets/icons/status-maintenance-good.svg'
              : 'assets/icons/status-maintenance-bad.svg'
          "
        />
      </div>
    </div>

    <div class="languages">
      <button
        class="language-button"
        [class.language-button-active]="translate.currentLang == 'en'"
        data-testid="en"
      >
        EN
      </button>
      <button
        class="language-button"
        [class.language-button-active]="translate.currentLang == 'jp'"
        data-testid="jp"
      >
        JP
      </button>
    </div>
    <mat-icon svgIcon="leading"></mat-icon>
    <div class="timezone">
      <span>{{ utctime }} UTC</span>
      <span>{{ localtime }} JST</span>
    </div>
    <!-- <mat-icon
      svgIcon="default-user"
      *ngIf="isLoggedIn"
      (click)="onOpenSwitchAccounts()"
    ></mat-icon> -->
    <ng-container>
      <button class="sign-in btn" [disabled]="true">
        {{ "header.ops.signin" | translate }}
      </button>
    </ng-container>
  </div>
</div>
<!-- <div class="alarm-window-container" *ngIf="alarmWindowOpen">
  <app-alarm-window
    [alarmWindowData]="alarmWindowData"
    [alarmWindowMoreNumber]="alarmWindowMoreNumber"
    (alarmWindowClose)="onAlarmWindowClose()"
  ></app-alarm-window>
  </div> -->
<!-- </div> -->
