import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { FuSettingService } from '@service/fu-settings.service';
import { ProjectService } from '@service/project.service';
import { FcuSetting, FuSetting, MAX_FCU } from 'icreate/common/models/FU-settings';
import { Subscription, lastValueFrom, take } from 'rxjs';

@Component({
    selector: 'app-fu-settings',
    templateUrl: './fu-settings.component.html',
    styleUrls: ['./fu-settings.component.scss'],
})
export class FuSettingsComponent implements OnInit, OnDestroy {
    @ViewChild('deletePage') deletePage: ConfirmationWindowComponent;
    fcuPages: FcuSetting[];
    fuSettings: FuSetting[];
    activePage: FcuSetting;
    activePageId: string;

    deletedPageSubscription: Subscription;
    newPageSubscription: Subscription;
    lockedPageSubscription: Subscription;
    unlockedPageSubscription: Subscription;

    activeSubscriptions: Subscription[];

    constructor(
        private fuSettingService: FuSettingService,
        private projectService: ProjectService,
    ) {}

    @HostListener('window:beforeunload', ['$event'])
    async beforeUnload() {
        this.activePage = null;
        if (this.activePageId) {
            this.fuSettingService.sendUnlockBeacon(this.activePageId);
        }
    }

    async ngOnInit() {
        this.startSocketSubscriptions();
        const projectId = this.projectService.getProjectId();
        this.fcuPages = await this.fuSettingService.getFcuPages(projectId);
    }

    ngOnDestroy(): void {
        this.activeSubscriptions?.map((sub) => {
            sub.unsubscribe();
        });
    }

    startSocketSubscriptions() {
        this.newPageSubscription = this.fuSettingService
            .newPage$()
            .subscribe((fcuSetting: FcuSetting) => {
                this.fcuPages.push(fcuSetting);
            });

        this.deletedPageSubscription = this.fuSettingService.deletedPage$().subscribe((pageId) => {
            this.fcuPages.map((fcuPage, i) => {
                if (fcuPage.id === pageId) {
                    this.fcuPages.splice(i, 1);
                    if (this.activePage === fcuPage) {
                        this.activePage = null;
                    }
                }
            });
        });

        this.lockedPageSubscription = this.fuSettingService
            .lockedPage$()
            .subscribe((pageId: string) => {
                this.fcuPages.map((fcuPage) => {
                    if (fcuPage.id === pageId) {
                        fcuPage.locked = true;
                    }
                });
            });

        this.unlockedPageSubscription = this.fuSettingService
            .unlockedPage$()
            .subscribe((pageId: string) => {
                this.fcuPages.map((fcuPage) => {
                    if (fcuPage.id === pageId) {
                        fcuPage.locked = false;
                        if (this.activePage === fcuPage) {
                            this.fuSettingService.onUnlockActivePage.next(this.activePage.id);
                            this.activePageId = this.activePage.id
                        }
                    }
                });
            });

        this.activeSubscriptions = [
            this.deletedPageSubscription,
            this.newPageSubscription,
            this.lockedPageSubscription,
            this.unlockedPageSubscription,
        ];
    }

    addFcuPage() {
        if (this.fcuPages.length < MAX_FCU) {
            const projectId = this.projectService.getProjectId();
            this.fuSettingService.createFcuPage(projectId);
        }
    }

    canAddFcuPage() {
        return this.fcuPages.length < MAX_FCU;
    }

    async deleteFcuPage(fcuPage: FcuSetting) {
        if (this.activePageId !== fcuPage.id) {
            return;
        }

        this.deletePage.setVisible(true);
        const confirmationWindowResult = await lastValueFrom(
            this.deletePage.onOptionSelected.pipe(take(1)),
        );
        if (confirmationWindowResult) {
            this.fuSettingService.deleteFcuPage(fcuPage.id);
        }
    }

    async selectPage(fcuPage: FcuSetting) {
        const currentPageId = this.activePage?.id;
        if (currentPageId && currentPageId === this.activePageId) {
            await this.fuSettingService.unlockFcuPage(currentPageId);
        }
        this.activePageId = null;
        this.activePage = fcuPage;
        if (!fcuPage.locked) {
            this.activePageId = fcuPage.id;
        }
    }
}
