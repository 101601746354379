export const DEVICE_READONLY = 'rodevice';

export class HelpData {
    page: string;
    tag: string;
}

export class SelElement {
    id: string = '';
    type: string = '';
    isMulti: boolean;
    ele: any = null;
    tagName?: string;
    getAttribute(string: any): any {
        return (this.ele && this.isMulti ? this.ele[0] : this.ele)?.getAttribute(string);
    }
    setAttribute(string: any, value: any): any {
        return (this.ele && this.isMulti ? this.ele[0] : this.ele)?.setAttribute(string, value);
    }
}

export class GaugeProperty {
    variableId: string;
    variableValue: string;
    permission: number;
    channelConfig: any;
    ranges: GaugeRangeProperty[];
    events: GaugeEvent[] = [];
    actions: GaugeAction[] = [];
    options: any;
    readonly: boolean;
    editorProperties: activeFlags = {
        highHigh: false,
        high: false,
        lowLow: false,
        low: false,
        over: false,
        under: false,
        sensorFail: false,
        feedback: false,
    };
    flags: Flags = {
        alarm: {
            highHigh: 0,
            high: 0,
            low: 0,
            lowLow: 0,
            feedbackError: 0,
            over: 0,
            under: 0,
            outputSensor: 0,
        },
        cancelManual: {
            highHigh: 0,
            high: 0,
            low: 0,
            lowLow: 0,
            feedbackError: 0,
            over: 0,
            under: 0,
        },
        cancelAuto1: {
            highHigh: 0,
            high: 0,
            low: 0,
            lowLow: 0,
            feedbackError: 0,
            over: 0,
            under: 0,
        },
        cancelAuto2: {
            highHigh: 0,
            high: 0,
            low: 0,
            lowLow: 0,
            feedbackError: 0,
            over: 0,
            under: 0,
        },
        cancelAuto3: {
            highHigh: 0,
            high: 0,
            low: 0,
            lowLow: 0,
            feedbackError: 0,
            over: 0,
            under: 0,
        },
        unAck: {
            highHigh: 0,
            high: 0,
            low: 0,
            lowLow: 0,
            feedbackError: 0,
            over: 0,
            under: 0,
            outputSensor: 0,
        },
        disable: {
            outputFUBreak: 0,
            inputFUBreak: 0,
        },
    };
    type = undefined;
}

export class GaugeRangeProperty {
    min: number;
    max: number;
    text: string;
    textId: string;
    color: string;
    type: any;
    style: any;
    stroke: string;
}

export class GaugeEvent {
    type: string;
    action: string;
    actparam: string;
    actoptions: any = {};
}

export class GaugeAction {
    variableId: string;
    range: GaugeRangeProperty;
    type: any;
    options: any = {};
}

export class activeFlags {
    highHigh: boolean;
    high: boolean;
    lowLow: boolean;
    low: boolean;
    over: boolean;
    under: boolean;
    sensorFail: boolean;
    feedback: boolean;
}

export class Flags {
    alarm;
    cancelManual;
    cancelAuto1;
    cancelAuto2;
    cancelAuto3;
    unAck;
    disable;
}

export class GaugeStatus {
    variablesValue = {};
    channelAlarms = {};
    channelRepose = {};
    channelSensor = {};
    channelBlink = {};
    onlyChange = false; // to process value only by change
    takeValue = false; // to process value by check change with gauge value
    actionRef: GaugeActionStatus;
}

export class GaugeSettings {
    name: string = '';
    property: any = null; // set to GaugeProperty after upgrate
    label: string = '';
    constructor(
        public id: string,
        public type: string,
    ) {}
}

export class GaugeActionStatus {
    type: string;
    timer: any = null;
    animr: any = null;
    spool: any;
    amsblinkcolor: string = null;
    constructor(type: string) {
        this.type = type;
    }
}

export class Size {
    height: number;
    width: number;
    constructor(h: number, w: number) {
        this.height = h;
        this.width = w;
    }
}

export enum GaugeActionsType {
    hide = 'shapes.action-hide',
    show = 'shapes.action-show',
    blink = 'shapes.action-blink',
    stop = 'shapes.action-stop',
    clockwise = 'shapes.action-clockwise',
    anticlockwise = 'shapes.action-anticlockwise',
    downup = 'shapes.action-downup',
    ams = 'shapes.action-ams',
}

export enum GaugeEventActionType {
    onpage = 'shapes.event-onpage',
    onwindow = 'shapes.event-onwindow',
    ondialog = 'shapes.event-ondialog',
    oniframe = 'shapes.event-oniframe',
    oncard = 'shapes.event-oncard', // wrong name exchange with 'onwindow'
    onSetValue = 'shapes.event-onsetvalue',
    onToggleValue = 'shapes.event-ontogglevalue',
    onSetInput = 'shapes.event-onsetinput',
    onclose = 'shapes.event-onclose',
}

export enum GaugeEventType {
    click = 'shapes.event-click',
}

export enum DeviceType {
    FuxaServer = 'FuxaServer',
    SiemensS7 = 'SiemensS7',
    OPCUA = 'OPCUA',
    BACnet = 'BACnet',
    ModbusRTU = 'ModbusRTU',
    ModbusTCP = 'ModbusTCP',
    WebAPI = 'WebAPI',
    MQTTclient = 'MQTTclient',
    WebStudio = 'WebStudio',
    internal = 'internal',
    AmsInterface = 'AmsInterface',
}

//USING FROM UNUSED FILE
export class GaugeActionBlink {
    strokeA: string = null;
    strokeB: string = null;
    fillA: string = null;
    fillB: string = null;
    interval: number = 1000;
}

export class GaugeActionAMS {
    defaultCol: string = '#000000';
    alarmCol: string = '#FF0000';
    reposeCol: string = '#FFFF00';
    sensorCol: string = '#FFC0CB';
    interval: number = 750;
}
