<div style="width: 280px">
  <div mat-dialog-title style="display: inline-block">
    <div class="logo" style="display: inline-block"></div>
    <div
      style="
        font-size: 18px;
        display: inline-block;
        vertical-align: super;
        padding-left: 5px;
      "
    >
      {{ "dlg.info-title" | translate }}
    </div>
  </div>
  <div mat-dialog-content style="margin-bottom: 10px">
    <div style="display: block">
      {{ "header.info-version" | translate }} {{ data.version }}
    </div>
    <div style="display: block; font-size: 13px; margin-top: 10px">
      powered by <span><b>infoceanus</b></span>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-action">
    <button mat-raised-button [mat-dialog-close]="data">
      {{ "dlg.ok" | translate }}
    </button>
  </div>
</div>
