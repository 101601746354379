import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProjectConfirmDialogComponent } from '@components/project-confirm-dialog/project-confirm-dialog.component';
import { Utils } from '@helpers/utils';
import { ProjectConfirmDialogType } from '@models/project-confirm.enum';
import { TranslateService } from '@ngx-translate/core';
import { ResWebApiService } from '@service/reswebapi.service';
import { VesselBuilderSelectService } from '@service/vessel-builder-select.service';
import { VesselSelectService } from '@service/vessel-select.service';
import { blobOperationParamValidator } from 'common/validators';
import { ProjectData } from 'icreate/common/models/project';
import { ValidationError, getValidationErrors, mapErrors } from 'icreate/common/validators';

class SelectedInfo {
    id: string;
    name: string;
}

@Component({
    selector: 'app-project-blob-select-dialog',
    templateUrl: './project-blob-select-dialog.html',
    styleUrls: ['./project-blob-select-dialog.scss'],
})
export class ProjectBlobSelectDialogComponent implements OnInit {
    title: string;

    vesselSelectDisable: boolean = true;
    actDisable: boolean = true;

    selectedBuilder: SelectedInfo = new SelectedInfo();
    selectedVessel: SelectedInfo = new SelectedInfo();
    projectData: ProjectData = new ProjectData();
    validationErrors: { [propertyName: string]: ValidationError } = {};

    constructor(
        private vesselBuilderSelectService: VesselBuilderSelectService,
        private vesselSelectService: VesselSelectService,
        public projectOnBlobSelectDialog: MatDialogRef<ProjectBlobSelectDialogComponent>,
        private resWebApiService: ResWebApiService,
        public dialog: MatDialog,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: IProjectBlobSelectDialog,
    ) {
        this.title = data.title;
        this.projectData = data.projectData;
    }

    ngOnInit(): void {
        this.checkFormErrors();
    }

    onBuilderSelect() {
        this.vesselBuilderSelectService
            .openBuilderSelectDialog()
            .then((res) => {
                if (Utils.isNullOrUndefined(res)) {
                    throw new Error();
                }
                this.selectedBuilder.id = res.id;
                this.selectedBuilder.name = res.name;
                this.vesselSelectDisable = false;
                this.checkFormErrors();
            })
            .catch((error) => {
                this.vesselSelectDisable = true;
                this.actDisable = true;
            });
    }

    onVesselSelect() {
        this.vesselSelectService
            .openVesselSelectDialog(this.selectedBuilder.id, this.selectedBuilder.name)
            .then((res) => {
                if (Utils.isNullOrUndefined(res)) {
                    throw new Error();
                }
                this.selectedVessel.id = res.id;
                this.selectedVessel.name = res.name;
                this.actDisable = false;
                this.checkFormErrors();
            })
            .catch((error) => {
                this.actDisable = true;
            });
    }

    onAct() {
        const dialogContents = {
            VesselCompanyName: this.projectData.VesselBuilderCompanyName
                ? this.projectData.VesselBuilderCompanyName
                : this.selectedBuilder.name,
            VesselName: this.projectData.VesselName
                ? this.projectData.VesselName
                : this.selectedVessel.name,
        };

        let dialogRef;

        if (this.data.type === ProjectConfirmDialogType.PullBlobConfirm) {
            dialogRef = this.dialog.open(ProjectConfirmDialogComponent, {
                data: {
                    contents: dialogContents,
                    type: ProjectConfirmDialogType.PullBlobConfirm,
                },
            });
        } else if (this.data.type === ProjectConfirmDialogType.CompareMimics) {
            dialogRef = this.dialog.open(ProjectConfirmDialogComponent, {
                data: {
                    contents: dialogContents,
                    type: ProjectConfirmDialogType.CompareMimics,
                },
            });
        } else if (this.data.type === ProjectConfirmDialogType.CompareChannels) {
            dialogRef = this.dialog.open(ProjectConfirmDialogComponent, {
                data: {
                    contents: dialogContents,
                    type: ProjectConfirmDialogType.CompareChannels,
                },
            });
        }

        dialogRef.afterClosed().subscribe((confirm) => {
            if (confirm) {
                this.onActConfirm();
            } else {
                this.projectOnBlobSelectDialog.close();
            }
        });
    }

    async onActConfirm() {
        if (!this.projectData.VesselBuilderCompanyID || !this.projectData.VesselID) {
            try {
                await this.resWebApiService.setProjectBuilderAndVessel(
                    this.selectedBuilder,
                    this.selectedVessel,
                    this.projectData.id,
                );

                this.projectOnBlobSelectDialog.close({
                    builderId: this.selectedBuilder.id,
                    vesselId: this.selectedVessel.id,
                });
            } catch (error) {
                console.error('fail:', error);
            }
        } else {
            this.projectOnBlobSelectDialog.close({
                builderId: this.projectData.VesselBuilderCompanyID,
                vesselId: this.projectData.VesselID,
            });
        }
    }

    onCancel(): any {
        this.projectOnBlobSelectDialog.close();
    }

    hasErrorString = (propName: string) => {
        return propName in this.validationErrors;
    };

    getErrorString = (propName: string) => {
        if (!this.hasErrorString(propName)) return '';

        const err = this.validationErrors[propName];
        return this.translateService.instant(err.errors[0], err.params);
    };

    checkFormErrors() {
        const validateObject = {
            builderId: this.selectedBuilder.id,
            vesselId: this.selectedVessel.id,
            projectId: this.projectData.id,
        };
        const errors = getValidationErrors(blobOperationParamValidator, validateObject);
        if (errors) {
            this.validationErrors = mapErrors(errors);
        }
    }
}

interface IProjectBlobSelectDialog {
    title: string;
    type: string;
    projectData: ProjectData;
}
