import { Component, Input, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { HostListener } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { CanvasInputModeService, CanvasInputMode } from '@service/canvas/canvas-input-mode.service';
import { EyeDropperService } from '@service/canvas/eye-dropper.service';

@Component({
    selector: 'app-color-panel-wrapper',
    templateUrl: './color-panel-wrapper.component.html',
    styleUrls: ['./color-panel-wrapper.component.css'],
    animations: [
        trigger('slideInOut', [
            state(
                'in',
                style({
                    overflow: 'visible',
                    transform: 'scale(1)',
                }),
            ),
            state(
                'out',
                style({
                    opacity: '0',
                    overflow: 'hidden',
                    transform: 'scale(0)',
                }),
            ),
            transition('in => out', animate('200ms ease-in-out')),
            transition('out => in', animate('200ms ease-in-out')),
        ]),
    ],
})
export class ColorPanelWrapperComponent {
    @Output() colorEvent = new EventEmitter<string>();
    @ViewChild('colorpanel') panel: any;
    @ViewChild('colorindicator') indicator: any;

    @Input() selectedColor = '#aaa';
    panelOpen = false;
    panelAnimState = 'out';
    displayedColor: string;
    colorChangeSubscription: Subscription;

    colors = [
        '#FFFFFF',
        '#000000',
        '#FF6900',
        '#FFF450',
        '#7BDCB5',
        '#00D084',
        '#8ED1FC',
        '#0693E3',
        '#ABB8C3',
        '#EB144C',
        '#F78DA7',
        '#9900EF',
    ];

    constructor(
        private canvasMode: CanvasInputModeService,
        private eyeDropperService: EyeDropperService,
    ) {}

    openPanel(x, y): void {
        this.panel.nativeElement.style.top = `${y}px`;
        this.panel.nativeElement.style.left = `${x - 380}px`;
        this.panelOpen = true;
        this.panelAnimState = 'in';
    }

    colorChanged(event: any): void {
        this.selectedColor = event.color.hex;
        this.colorEvent.emit(this.selectedColor);
    }

    colourPicker() {
        this.canvasMode.setMode(CanvasInputMode.EYE_DROPPER);
        this.colorChangeSubscription = this.eyeDropperService
            .onDisplayColorChanged()
            .subscribe((color) => {
                this.displayedColor = color;
            });
    }

    @HostListener('document:click', ['$event', '$event.target'])
    clicked(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }
        if (!this.panelOpen) {
            if (this.indicator.nativeElement.contains(targetElement))
                this.openPanel(event.clientX, event.clientY);
            return;
        }

        const clickedInside = this.panel.nativeElement.contains(targetElement);
        if (!clickedInside && this.panelOpen) {
            this.panelOpen = false;
            this.panelAnimState = 'out';
            this.colorChangeSubscription.unsubscribe();
            if (this.displayedColor) {
                this.colorEvent.emit(this.displayedColor);
                this.colorChangeSubscription.unsubscribe();
            }
        }
    }
}
