import { Component } from '@angular/core';
import { ChannelSheetService } from '@service/channel-sheet.service';
import { ChannelService } from '@service/channel.service';
import { ProjectService } from '@service/project.service';
import { Channel } from 'common/models/channel';
import { ProjectData } from 'icreate/common/models/project';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-channel-panel-alarm',
    templateUrl: './channel-panel-alarm.component.html',
    styleUrls: ['./channel-panel-alarm.component.css'],
})
export class ChannelPanelAlarmComponent {
    selectedChannel: Channel;
    constructor(
        private channelSheetService: ChannelSheetService,
        private channelService: ChannelService,
        private projectService: ProjectService,
    ) {}

    ngOnInit() {
        this.channelSheetService.selectRowEmitter.subscribe(async (rowIdx: number) => {
            this.selectedChannel = await lastValueFrom(
                this.channelService.getChannel(
                    this.projectService.getProjectId(),
                    this.channelSheetService.getRowValue(rowIdx),
                    this.channelSheetService._activeGroup.id,
                ),
            );
        });
    }
}
