<div style="flex: 1; display: contents; position: relative">
    <div
        *ngIf="!pageIsLocked && fcuSetting"
        style="overflow: scroll; flex: 1; scrollbar-width: auto; height: 100%"
    >
        <table mat-table [dataSource]="tableDataSource" class="table">
            <ng-container matColumnDef="info">
                <th mat-header-cell *matHeaderCellDef class="table-header"></th>
                <td
                    mat-cell
                    *matCellDef="let setting; let i = index"
                    class="table-row"
                >
                    <div *ngIf="i == 0" style="user-select: none">
                        {{ "FCU" }}
                    </div>
                    <div *ngIf="i > 0" style="user-select: none">
                        {{ "FU " + i }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.active" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let setting; let i = index"
                    class="table-row"
                >
                    <mat-checkbox
                        *ngIf="i > 0"
                        [(ngModel)]="setting.active"
                    ></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.name" | translate }}
                </th>
                <td mat-cell *matCellDef="let setting" class="table-row">
                    <input
                        [(ngModel)]="setting.name"
                        maxlength="48"
                        [value]="setting.name || ''"
                        placeholder="Name"
                    />
                    <div
                        class="error-container text light"
                        *ngIf="getSettingErrors(setting, 'name')"
                    >
                        <mat-icon svgIcon="error" class="error-icon"></mat-icon>
                        <span data-testid="errorSetPoint">
                            {{
                                getSettingErrors(setting, "name") | translate
                            }}</span
                        >
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="remark">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.remark" | translate }}
                </th>
                <td mat-cell *matCellDef="let setting" class="table-row">
                    <input
                        [(ngModel)]="setting.remarks"
                        maxlength="48"
                        [value]="setting.remarks || ''"
                        placeholder="Remark"
                    />
                </td>
            </ng-container>

            <ng-container matColumnDef="slot1">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.slot1" | translate }}
                </th>
                <td mat-cell *matCellDef="let setting" class="table-row">
                    <mat-select
                        [value]="setting.slot1"
                        (selectionChange)="setting.slot1 = $event.value"
                    >
                        <mat-option
                            *ngFor="let type of slotTypes"
                            [value]="type"
                        >
                            {{ type | translate }}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <ng-container matColumnDef="slot2">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.slot2" | translate }}
                </th>
                <td mat-cell *matCellDef="let setting" class="table-row">
                    <mat-select
                        [value]="setting.slot2"
                        (selectionChange)="setting.slot2 = $event.value"
                    >
                        <mat-option
                            *ngFor="let type of slotTypes"
                            [value]="type"
                        >
                            {{ type | translate }}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <ng-container matColumnDef="slot3">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.slot3" | translate }}
                </th>
                <td mat-cell *matCellDef="let setting" class="table-row">
                    <mat-select
                        [value]="setting.slot3"
                        (selectionChange)="setting.slot3 = $event.value"
                    >
                        <mat-option
                            *ngFor="let type of slotTypes"
                            [value]="type"
                        >
                            {{ type | translate }}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <ng-container matColumnDef="slot4">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.slot4" | translate }}
                </th>
                <td mat-cell *matCellDef="let setting" class="table-row">
                    <mat-select
                        [value]="setting.slot4"
                        (selectionChange)="setting.slot4 = $event.value"
                    >
                        <mat-option
                            *ngFor="let type of slotTypes"
                            [value]="type"
                        >
                            {{ type | translate }}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <ng-container matColumnDef="slot5">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.slot5" | translate }}
                </th>
                <td mat-cell *matCellDef="let setting" class="table-row">
                    <mat-select
                        [value]="setting.slot5"
                        (selectionChange)="setting.slot5 = $event.value"
                    >
                        <mat-option
                            *ngFor="let type of slotTypes"
                            [value]="type"
                        >
                            {{ type | translate }}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <ng-container matColumnDef="slot6">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.slot6" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let setting; let i = index"
                    class="table-row"
                >
                    <mat-select
                        *ngIf="i > 0"
                        [value]="setting.slot6"
                        (selectionChange)="setting.slot6 = $event.value"
                    >
                        <mat-option
                            *ngFor="let type of slotTypes"
                            [value]="type"
                        >
                            {{ type | translate }}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <ng-container matColumnDef="slot7">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.slot7" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let setting; let i = index"
                    class="table-row"
                >
                    <mat-select
                        *ngIf="i > 0"
                        [value]="setting.slot7"
                        (selectionChange)="setting.slot7 = $event.value"
                    >
                        <mat-option
                            *ngFor="let type of slotTypes"
                            [value]="type"
                        >
                            {{ type | translate }}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <ng-container matColumnDef="slot8">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ "fu-settings.table-header.slot8" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let setting; let i = index"
                    class="table-row"
                >
                    <mat-select
                        *ngIf="i > 0"
                        [value]="setting.slot8"
                        (selectionChange)="setting.slot8 = $event.value"
                    >
                        <mat-option
                            *ngFor="let type of slotTypes"
                            [value]="type"
                        >
                            {{ type | translate }}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
                class="table-header"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <div *ngIf="pageIsLocked" class="lock-wrapper">
        {{ "fu-settings.page.message.locked" | translate }}
        <mat-icon svgIcon="locked" class="icon"></mat-icon>
    </div>

    <div *ngIf="loadingData && fcuSetting" class="lock-wrapper">
        {{ "fu-settings.page.message.retrieving-data" | translate }}
        <app-loading-spinner #loadingSpinner></app-loading-spinner>
    </div>

    <div *ngIf="!fcuSetting" class="lock-wrapper">
        {{ "fu-settings.page.message.no-page-selected" | translate }}
    </div>
</div>

<div>
    <div class="buttons-container">
        <button
            class="save"
            [disabled]="!saveButtonIsDisabled() || !fcuSetting"
            (click)="saveList()"
        >
            {{ "fu-settings.button.save" | translate }}
        </button>
        <button class="exit" (click)="exit()">
            {{ "fu-settings.button.exit" | translate }}
        </button>
    </div>
</div>

<app-confirmation-window
    #confirmationWindow
    [mainLabel]="'fu-settings.close.confirmation.title' | translate"
    [confirmLabel]="'fu-settings.close.confirmation.confirm' | translate"
    [cancelLabel]="'fu-settings.close.confirmation.cancel' | translate"
></app-confirmation-window>
