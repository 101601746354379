export function calculateEllipsePoints(rectangle) {
    const xAxis = Number(rectangle.getAttribute('rx'));
    const yAxis = Number(rectangle.getAttribute('ry'));
    const initialX = Number(rectangle.getAttribute('cx'));
    const initialY = Number(rectangle.getAttribute('cy'));
    const numberOfPoints = 12;

    const points = [];
    const deltaTheta = (2 * Math.PI) / numberOfPoints;

    for (let i = 0; i < numberOfPoints; i++) {
        const theta = i * deltaTheta;
        const x = initialX + xAxis * Math.cos(theta);
        const y = initialY + yAxis * Math.sin(theta);
        points.push({ x, y });
    }

    return points;
}