import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@environments/environment';
import { EndPointApi } from '@helpers/endpointapi';
import { AppSettings } from 'common/models/app-settings';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    public appSettings = new AppSettings();

    private editModeLocked = false;

    constructor(
        private fuxaLanguage: TranslateService,
        private translateService: TranslateService,
        private toastr: ToastrService,
    ) {}

    init() {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.fuxaLanguage.setDefaultLang('en');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.fuxaLanguage.use('en');
        // to load saved settings
    }

    //#region Editor Mode Check
    lockEditMode() {
        this.editModeLocked = true;
    }

    unlockEditMode() {
        this.editModeLocked = false;
    }

    isEditModeLocked(): boolean {
        return this.editModeLocked;
    }

    notifyEditorLocked() {
        let msg = '';
        this.translateService.get('msg.editor-mode-locked').subscribe((txt: string) => {
            msg = txt;
        });
        this.toastr.warning(msg, '', {
            timeOut: 3000,
            closeButton: true,
            disableTimeOut: false,
        });
    }
}
