import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthorizationErrorService {
    azureAuthorizationError: Subject<void> = new Subject<void>();
    iApiAuthorizationError: Subject<void> = new Subject<void>();

    getAzureAuthorizationErrorObservable() {
        return this.azureAuthorizationError.asObservable();
    }

    getIapiAuthorizationErrorObservable() {
        return this.iApiAuthorizationError.asObservable();
    }
}
