import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Define } from '@helpers/define';
import { WindowRef } from '@helpers/windowref';
import { SelElement } from '@models/hmi';
import { SelectedElementsService } from '@service/canvas/selected-elements.service';
import { View } from 'common/models/view';
// import { GaugeBaseComponent } from '../gauges/gauge-base/gauge-base.component';
// import { GaugesManager } from '../gauges/gauges.component';
import { GaugeBaseComponent, GaugesManager } from 'ngx-fuxa';

declare let paper: any;
@Component({
    selector: 'app-properties-bar',
    templateUrl: './properties-bar.component.html',
    styleUrls: ['./properties-bar.component.css'],
})
export class PropertiesBarComponent implements OnInit, OnChanges {
    selectedTextAnchor = 'start';
    selectedFont = '';
    noShowXYSideBarShapes = [
        'svg-ext-shapes-circle',
        'svg-ext-shapes-ellipse',
        'svg-ext-shapes-line',
        'svg-ext-shapes-path',
        'svg-ext-value',
    ];

    strokeTypes: any = [
        { id: '...', val: '2,2' },
        { id: '- -', val: '5,5' },
        { id: '- .', val: '5,2,2,2' },
        { id: '- ..', val: '5,2,2,2,2,2' },
    ];
    selectedStroke = '';

    panelPropertyIdOpenState: boolean = true;
    panelPropertyTransformOpenState: boolean = true;
    panelAlignOpenState: boolean = true;
    panelFillOpenState: boolean = true;
    panelImageOpenState: boolean = true;
    panelOpacityOpenState: boolean = true;
    panelStrokeOpenState: boolean = true;
    panelEventOpenState: boolean = true;
    panelMarkerOpenState: boolean = true;
    panelHyperlinkOpenState: boolean = true;
    fonts = Define.fonts;
    settings: any;

    @Input() canvas: any;
    @Input() selectedElement: SelElement;
    @Input() currentView: View;

    @Output() gaugeEditEvent = new EventEmitter();
    @Output() nameSetEvent = new EventEmitter();

    @ViewChild('gaugepanel') gaugePanelComponent: GaugeBaseComponent;

    constructor(
        private winRef: WindowRef,
        private gaugesManager: GaugesManager,
        private changeDetector: ChangeDetectorRef,
        private selection: SelectedElementsService,
    ) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        this.selectedStroke = this.selectedElement?.getAttribute('stroke-dasharray') ?? '';
        this.selectedFont = this.selectedElement?.getAttribute('font-family') ?? '';
        this.selectedTextAnchor = this.selectedElement?.getAttribute('text-anchor') ?? 'middle';
    }

    IsSomethingSelected = () => {
        return this.getSelectedElements().length > 0;
    };
    AreMultipleSelected = () => {
        return this.getSelectedElements().length > 1;
    };
    IsOneThingSelected = () => {
        return this.getSelectedElements().length == 1;
    };

    /**
     * set font to svg selected
     * @param font font family
     */
    onFontFamilyChange = (font) => {
        this.canvas.setFontFamily(font);
    };

    onFontSizeChange = (event) => {
        this.canvas.setFontSize(event.target.value);
    };

    /**
     * align the svg text (start,middle,end)
     * @param align type
     */
    onTextAlignChange = (align) => {
        this.canvas.setTextAnchor(align);
    };

    stringToInt = (t) => {
        return parseInt(t);
    };

    changeSelectedAttr = (attr, val) => {
        this.canvas.changeSelectedAttribute(attr, val);
    };

    getSelAttribute = (name: string, def: any) => {
        return this.selectedElement?.getAttribute(name) || def;
    };

    /**
     * align the selected element
     * @param letter align type (left,center,right,top,middle,bottom)
     */
    onAlignSelected(letter: string) {
        this.winRef.nativeWindow.svgEditor.alignSelectedElements(letter.charAt(0));
    }

    public shouldShowGenericOpacity(): boolean {
        const ele = this.getSelectedElements();
        if (!ele[0]) return false;

        if (ele.length > 1) return false;

        return !this.shouldShowFillAndStroke();
    }

    public shouldShowFillAndStroke(): boolean {
        const ele = this.getSelectedElements()[0];
        if (!ele) return false;
        if (this.getSelectedElements().length > 1) return false;

        if (this.selectedElement.type?.includes('image')) return false;

        if (ele.tagName == 'g') return false;

        if (this.selectedElement?.type == 'svg-ext-shapes-line') return false;

        if (
            !this.selectedElement.type?.includes('svg-ext-shape') &&
            !this.selectedElement.type?.includes('svg-ext-val')
        )
            return false;

        return true;
    }

    get PreserveAspectRatio(): boolean {
        const sel = this.getSelectedElements();
        if (sel?.length != 1) return false;
        if (sel[0].attributes['preserveAspectRatio'] === undefined) return true;

        return sel[0].getAttribute('preserveAspectRatio') == 'xMidYMid';
    }

    set PreserveAspectRatio(val: boolean) {
        const setting = val ? 'xMidYMid' : 'none';

        const sel = this.getSelectedElements();
        if (sel?.length != 1) return;

        sel[0].setAttribute('preserveAspectRatio', setting);
    }

    get SelectedObjAngle(): number {
        const sel = this.getSelectedElements();
        if (sel?.length != 1) return 0;

        return this.canvas.getRotationAngle(sel[0], false);
    }

    set SelectedObjAngle(val: number) {
        this.canvas.setRotationAngle(val, false);
    }

    get SelObjStrokeWidth(): number {
        const sel = this.getSelectedElements();
        if (sel.length != 1) return 0;

        return Number(sel[0].getAttribute('stroke-width')) || 1;
    }

    set SelObjStrokeWidth(val: number) {
        const sel = this.getSelectedElements();
        if (sel?.length != 1) return;

        val = Math.abs(val);

        sel[0].setAttribute('stroke-width', '' + val);
    }

    private preventMinus(event: KeyboardEvent) {
        if (event.key == '-') {
            event.preventDefault();
            return false;
        }
        return true;
    }

    onPreventMinus(event: KeyboardEvent) {
        this.preventMinus(event);
    }

    /**
     * event from svg-editor: change fill color
     * @param event color code
     */
    onChangeFillColor = (event) => {
        this.canvas.setColor('fill', event, false);
    };

    /**
     * event change stroke color (from bottom color panel)
     * @param event color code
     */
    onChangeStrokeColor = (event) => {
        this.canvas.setColor('stroke', event, false);
    };

    /**
     * set stroke join (miter, round, bevel)
     * @param join join type
     */
    onSetLineJoin = (join: string) => {
        this.canvas.changeSelectedAttribute('stroke-linejoin', join);
    };
    /**
     * set stroke cap (butt, square, round)
     * @param cap cap type
     */
    onSetLineCap = (cap: string) => {
        this.canvas.changeSelectedAttribute('stroke-linecap', cap);
    };

    onStrokeStyleSelect = (value) => {
        this.canvas.changeSelectedAttribute('stroke-dasharray', value);
        this.selectedStroke = value;
    };
    /**
     * set shadow to svg selected
     * @param event shadow
     */
    onSetShadowOption = (event) => {
        this.winRef.nativeWindow.svgEditor.onSetShadowOption(event);
    };

    SetSegment = () => {
        const path = this.canvas?.getPathObj();
        path?.setSegType(4);
    };
    SetCurve = () => {
        const path = this.canvas?.getPathObj();
        path?.setSegType(6);
    };
    VectorToolEnableCheck = (type: string) => {
        const path = this.canvas?.getPathObj();
        if (!path) return false;

        if (type == 'setsegment') {
            if (path.selected_pts.length == 0) {
                return false;
            }
            for (const pt of path.selected_pts) {
                if (path.segs[pt].type == 6) return true;
            }
        } else if (type == 'setcurve') {
            if (path.selected_pts.length == 0) {
                return false;
            }
            for (const pt of path.selected_pts) {
                if (path.segs[pt].type == 4) return true;
            }
        } else if (type === 'splitpath') {
            if (path.selected_pts.length === 0) {
                return false;
            }
            return true;
        }
        return false;
    };

    SplitPath() {
        const selectedPathObj = this.canvas.getPathObj();
        const selectedPath = this.canvas.getElement(selectedPathObj.elem.id);
        const selected_path_d: string = selectedPath.getAttribute('d');
        const dPara: string[] = selected_path_d.split(/[a-z|A-Z]/).filter(Boolean);
        const paperPath = new paper.Path(selected_path_d);
        const splitPoint: string = dPara[selectedPathObj.selected_pts[0]];
        let splitPointSet: string[] = splitPoint.replace(/\s/g, ',').split(',').filter(Boolean);

        //Curve path
        if (splitPointSet.length === 6) {
            splitPointSet = splitPointSet.splice(-2);
        }

        const paperPoint = new paper.Point(splitPointSet);
        const paperSplitPoint = paperPath.getLocationOf(paperPoint);
        const paperSplitedPath = paperPath.splitAt(paperSplitPoint);
        const paperPathSVG = paperPath.exportSVG();
        const paperSplitedPathSVG = paperSplitedPath.exportSVG();
        const convertedPath_1 = this.canvas.pathActions.convertPath(paperPathSVG);
        const convertedPath_2 = this.canvas.pathActions.convertPath(paperSplitedPathSVG);

        selectedPath.setAttribute('d', convertedPath_1);

        //Open path
        if (convertedPath_1 !== convertedPath_2) {
            const color = selectedPath.getAttribute('stroke');
            const fill = selectedPath.getAttribute('fill');
            paperSplitedPathSVG.setAttribute('d', convertedPath_2);
            paperSplitedPathSVG.setAttribute('id', this.canvas.getNextId());
            paperSplitedPathSVG.setAttribute('stroke', color);
            paperSplitedPathSVG.setAttribute('fill', fill);
            const paperSplitedPathJSON = this.canvas.getJsonFromSvgElements(paperSplitedPathSVG);
            this.canvas.addSVGElementsFromJson(paperSplitedPathJSON);
        }
        selectedPathObj.init();
    }

    getDecimalPointLength(number: String) {
        const numbers = number.split('.');

        return numbers[1] ? numbers[1].length : 0;
    }

    RoundGiveParameter(attr: string, val: number) {
        const roundValue = Math.round(val * 10 * 10) / 100; // to avoid round error
        if (val > roundValue) {
            this.changeSelectedAttr(attr, String(roundValue + 0.1));
            this.changeDetector.detectChanges();
        }
        console.log(roundValue);
        this.changeSelectedAttr(attr, roundValue);
    }

    roundBBoxCoordinate(elem: any, axis: string) {
        let bbox: number = 0;
        if (axis === 'x') {
            bbox = elem.ele.getBBox().x;
        } else if (axis === 'y') {
            bbox = elem.ele.getBBox().y;
        }
        bbox = Math.round(bbox * 10 * 10) / 100;
        return bbox;
    }
    /**
     * get gauge settings from current view items, if not exist create void settings from GaugesManager
     * @param ele gauge id
     */
    getGaugeSettings(ele) {
        if (ele && this.currentView) {
            if (this.currentView.items[ele.id]) {
                return this.currentView.items[ele.id];
            }
            return this.gaugesManager.createSettings(ele.id, ele.type);
        }
        return null;
    }

    OnGaugeEdit = (event) => {
        console.log(event, 'properties bar');
        this.gaugeEditEvent.emit({
            ev: event,
            settings: this.gaugePanelComponent.settings,
        });
        this.changeDetector.detectChanges();
    };
    onNameEdit = (event) => {
        this.nameSetEvent.emit(event);
    };

    getSelectedElements(): SVGElement[] {
        return this.selection.getSelectedElements();
    }

    selectedLinejoin(lineJoin){
        let isSelectedLineJoin = false
        if (this.selectedElement){
            const selectedLinejoin = this.selectedElement.getAttribute('stroke-linejoin')    
            if (selectedLinejoin === lineJoin) {
                isSelectedLineJoin = true
            }
        }
        return isSelectedLineJoin
    }
}
