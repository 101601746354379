import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core';

import { IcreateWindowReferenceService } from './icreate-window-reference.service';
import SvgCanvas from '@svgeditor/svgcanvas.js';
import { WindowRef } from '@helpers/windowref';
import { SvgEditExtension } from '@models/svg-edit-extension';
import { transformPoint } from '@svgeditor/math.js';
import { View } from 'common/models/view';

@Injectable()
export class CanvasService {
    private _onChanged = new EventEmitter<any>();
    private _onGaugeAdded = new EventEmitter<any>();

    private canvas: SvgCanvas;

    constructor(
        private windowElements: IcreateWindowReferenceService,
        private winRef: WindowRef,
    ) {}

    init(): void {
        const container = this.windowElements.getEditorContainer();
        const { width, height } = { width: 0, height: 0 };

        const config = {
            initFill: { color: 'FFFFFF', opacity: 1 },
            initStroke: { color: '000000', opacity: 1, width: 1 },
            text: { stroke_width: 0, font_size: 24, font_family: 'serif' },
            initOpacity: 1,
            imgPath: '../src/editor/images',
            dimensions: [width, height],
            baseUnit: 'px',
        };

        this.canvas = new SvgCanvas(container, config);
        this.winRef.nativeWindow.canvas = this.canvas;

        this.prepCanvas();
        this.bindCanvasEvents();
    }

    private prepCanvas() {
        this.canvas.textActions.setInputElem(document.getElementById('text'));
        this.canvas.sanitizeSvg = () => {};
        this.canvas?.updateCanvas(0, 0);
        this.canvas.linkControlPoints(false);
    }

    private bindCanvasEvents() {
        this.canvas.bind('gauge_added', (win, elem) => this.emitGaugeAdded(elem));
        this.canvas.bind('changed', (win, elem) => this._onChanged.emit(elem));
    }

    /**
     * @warn This function should not be called directly unless necessary for a function. Keep all features that use the canvas directly abstracted.
     * @returns a reference to SvgEdit's svgCanvas object
     */
    getCanvas(): SvgCanvas {
        return this.canvas;
    }

    onChanged() {
        return this._onChanged;
    }

    onGaugeAdded() {
        return this._onGaugeAdded;
    }

    emitGaugeAdded(gauge) {
        this._onGaugeAdded.emit(gauge);
    }

    toCanvasCoordinates(x: number, y: number) {
        const sctm = this.getSCTM();
        const pt = transformPoint(x, y, sctm);
        return pt;
    }

    getSCTM(): SVGMatrix {
        return (this.windowElements.getSvgContentElement().getElementsByTagName('g')[0] as any)
            .getScreenCTM()
            .inverse();
    }

    getSvgRoot() {
        return this.canvas.getSvgRoot();
    }

    addExtension(extension: SvgEditExtension, initArgs: any) {
        // Note that we don't want this on this.ready since some extensions
        // may want to run before then (like server_opensave).
        if (!this.canvas || this.canvas.extensions == undefined) {
            throw new Error('Extension added too early');
        }
        this.canvas.addExtension(extension.name, extension.init, initArgs);
    }

    getEmptySvg(view: View) {
        return (
            '<svg id="' +
            view.name +
            '" width="' +
            (view.profile.width - 50) * 0.8 +
            '" height="' +
            (view.profile.height - 65) * 0.8 +
            '" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">' +
            '<filter id="blur-filter" x="-3" y="-3" width="200" height="200"><feGaussianBlur in="SourceGraphic" stdDeviation="3" /></filter>' +
            '<g class="layer"><title>Layer 1</title></g></svg>'
        );
    }
}
