import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UrlParserService {
    constructor(private router: Router) {}

    getSegment(path: string): UrlSegment[] {
        let pathTree = this.router.parseUrl(path);
        const urlSegmentGroup: UrlSegmentGroup = pathTree.root.children[PRIMARY_OUTLET];
        return urlSegmentGroup.segments;
    }
}
