import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ContactFormComponent } from '@components/contact-form/contact-form.component';
import { AuthService } from '@service/auth.service';
import { ContactService } from '@service/contact.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-i-create-login',
    templateUrl: './i-create-login.component.html',
    styleUrls: ['./i-create-login.component.css'],
})
export class ICreateLoginComponent {
    public get dialog(): MatDialog {
        return this._dialog;
    }
    public set dialog(value: MatDialog) {
        this._dialog = value;
    }
    isIframe = false;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        private authService: AuthService,
        private contactService: ContactService,
        private _dialog: MatDialog,
        private router: Router,
    ) {
        const userIsUnauthorized = this.router.getCurrentNavigation().extras.state?.unauthorized;
        if (userIsUnauthorized) {
            this.dialog.open(UnauthorizedUserComponent);
            return;
        }
    }

    ngOnInit() {
        this.isIframe = window !== window.parent && !window.opener;
    }

    onLogin() {
        this.authService.azureLogIn();
    }

    ngOnDestroy() {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    openContactForm = () => {
        const dialogRef = this.dialog.open(ContactFormComponent, {
            height: '700px',
            width: '500px',
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res) this.contactService.postInquiry(res);
        });
    };
}

@Component({
    selector: 'app-azure-login-prompt',
    template: `<div class="dialog-wrapper">
        <h2>{{ 'iCreateLogin.unauthorized-user.prompt.title' | translate }}</h2>
        <div class="button-wrapper">
            <button class="sign-in-button" (click)="this.dialogRef.close()">
                {{ 'iCreateLogin.unauthorized-user.prompt.confirm' | translate }}
            </button>
        </div>
    </div>`,
    styleUrls: ['./i-create-login.component.css'],
})
export class UnauthorizedUserComponent {
    constructor(public dialogRef: MatDialogRef<UnauthorizedUserComponent>) {}
}
