import { Injectable } from '@angular/core';
import { IoEventTypes } from 'icreate/common/io-event/websocket-message';
import { ExcelCommands } from 'icreate/common/excel-import/websocket-message';
import { ProjectSocketCommands } from 'icreate/common/project/websocket-message';
import { ViewsSocketCommands } from 'icreate/common/view/websocket-message';
import { Observable } from 'rxjs';
import { SocketMiddlewareService } from './socket-middleware.service';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    lastJoined: string = '';
    constructor(private socketService: SocketMiddlewareService) {}

    deviceLoad(projectId: string) {
        this.socketService.sendMessage(IoEventTypes.DEVICE_LOAD, projectId);
    }

    viewLocked$(): Observable<any> {
        return this.socketService.receiveMessageObserver(ViewsSocketCommands.LockView);
    }

    viewUnlocked$(): Observable<any> {
        return this.socketService.receiveMessageObserver(ViewsSocketCommands.UnlockView);
    }

    viewUnlockedAll$(): Observable<any> {
        return this.socketService.receiveMessageObserver(ViewsSocketCommands.UnlockViewAll);
    }

    updateView$() {
        return this.socketService.receiveMessageObserver(ViewsSocketCommands.UpdateView);
    }

    removeView$() {
        return this.socketService.receiveMessageObserver(ViewsSocketCommands.RemoveView);
    }

    channelsUpdated$(): Observable<any> {
        return this.socketService.receiveMessageObserver(ExcelCommands.ChannelsUpdated);
    }

    joinProject(id: string): void {
        if (this.lastJoined != '') {
            this.leaveProject(this.lastJoined);
        }
        this.socketService.sendMessage(ProjectSocketCommands.JoinProject, id);
        this.lastJoined = id;
    }

    onProjectSettingsUpdated() {
        return this.socketService.receiveMessageObserver(ProjectSocketCommands.SettingsUpdated);
    }

    leaveProject(id: string): void {
        this.socketService.sendMessage(ProjectSocketCommands.LeaveProject, id);
    }

    getSocket() {
        return this.socketService.getSocket();
    }
}
