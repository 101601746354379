import { ElementRef, Injectable } from '@angular/core';

export interface IcreateWindowComponentInjector {
    workArea: ElementRef;
}

@Injectable()
export class IcreateWindowReferenceService {
    private injectedElements: IcreateWindowComponentInjector;

    public init(elements: IcreateWindowComponentInjector) {
        const injectionCopy = {};
        for (const index in elements) {
            injectionCopy[index] = elements[index];
        }
        this.injectedElements = injectionCopy as IcreateWindowComponentInjector;
    }

    getWorkArea(): ElementRef {
        return this.injectedElements.workArea;
    }

    getEditorContainer(): HTMLElement {
        return document.querySelector('#editorContainer');
    }

    getCanvasBackground(): HTMLElement {
        return document.getElementById('canvasBackground');
    }

    getSvgContentElement() {
        return document.getElementById('svgcontent');
    }
}
