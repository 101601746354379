export enum AlarmLevel {
    Caution,
    Warning,
    Alarm,

    None,
}

export enum AlarmStatus {
    LOW = 'LOW',
    LOWLOW = 'LOW-LOW',
    HIGHHIGH = 'HIGH-HIGH',
    HIGH = 'HIGH',
    FAIL = 'FAIL',
    ABNORMAL = 'ABNORMAL',
    FEEDBACK = 'FEDBACK',
    UNDEFINED = '',
}

export const DefaultAlarmLevel = AlarmLevel.Alarm;
