<!--HERE-->
<mat-dialog-content>
    <h1>Contact Us</h1>
    <form [formGroup]="contactForm">
        <mat-form-field appearance="fill">
            <mat-label for="name">氏名 / Name</mat-label>
            <input matInput id="name" type="text" formControlName="name" />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label for="email">メールアドレス / Email address</mat-label>
            <input
                matInput
                id="email"
                type="email"
                email="true"
                formControlName="email"
            />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label for="company">会社名 / Company</mat-label>
            <input
                matInput
                id="company"
                type="text"
                formControlName="company"
            />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label for="subject">件名 / Subject</mat-label>
            <input
                matInput
                id="subject"
                type="text"
                formControlName="subject"
            />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label for="message">メッセージ / Message</mat-label>
            <textarea
                matInput
                id="message"
                rows="5"
                type="text"
                formControlName="message"
            ></textarea>
        </mat-form-field>

        <!--<button mat-button type="submit" [disabled]="!contactForm.valid">Submit</button>-->
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button
        mat-raised-button
        color="primary"
        [disabled]="!contactForm.valid"
        [mat-dialog-close]="contactForm.value"
        cdkFocusInitial
    >
        Submit
    </button>
</mat-dialog-actions>
