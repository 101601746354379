import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

type AOA = any[][];

class spreadSheetData {
    sheetName: string;
    data: AOA;
}

@Injectable()
export class SpreadSheetReaderService {
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

    constructor() {}

    public read(file: File) {
        return new Promise<any>((res, rej) => {
            try {
                const bookData: spreadSheetData[] = [];
                // wire up file reader
                const reader: FileReader = new FileReader();
                reader.onload = (e: any) => {
                    // read workbook
                    const bstr: string = e.target.result;
                    const wb: XLSX.WorkBook = XLSX.read(bstr, {
                        type: 'binary',
                    });

                    wb.SheetNames.forEach((name) => {
                        const ws: XLSX.WorkSheet = wb.Sheets[name];
                        // save data
                        const data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
                        const sheetData = new spreadSheetData();
                        sheetData.sheetName = name;
                        sheetData.data = data;
                        bookData.push(sheetData);
                    });
                    res(bookData);
                };
                reader.readAsBinaryString(file);
            } catch (error) {
                rej(error);
            }
        });
    }
}
