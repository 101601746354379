<div>
    <div *ngIf="!isLoading" class="sheet-top-wrap">
        <div class="view-wrap">
            <div class="view-mode">
                <div
                    class="mode left"
                    [class]="{ active: viewMode == SIMPLE_VIEW }"
                    (click)="selectViewMode(SIMPLE_VIEW)"
                >
                    {{ "channel.label.simpleView" | translate }}
                </div>
                <div
                    class="mode right"
                    [class]="{ active: viewMode == VIEW_ALL }"
                    (click)="selectViewMode(VIEW_ALL)"
                >
                    {{ "channel.label.viewAll" | translate }}
                </div>
            </div>
            <div class="alarm-wrap">
                <div
                    *ngFor="let alarmColumn of alarmColumns"
                    class="alarm"
                    (click)="selectedShowAlarm(alarmColumn)"
                >
                    {{ "channel.label.alarm." + alarmColumn | translate }}
                    <mat-icon
                        svgIcon="visible"
                        *ngIf="selectedAlarmKeys.has(alarmColumn)"
                    ></mat-icon>
                    <mat-icon
                        svgIcon="invisible"
                        *ngIf="!selectedAlarmKeys.has(alarmColumn)"
                    ></mat-icon>
                </div>
            </div>
        </div>
        <div class="channel-group-tabs">
            <div
                *ngFor="let group of groups; let index = index"
                (click)="onSelectGroup(group)"
                style="cursor: pointer"
                class="channel-group-tab"
                [ngClass]="{
                    active: activeGroup.id === group.id
                }"
            >
                {{ index + 1 }}
            </div>
            <div
                class="channel-group-add"
                (click)="openGroupDialog(ADD_CHANNEL_GROUP, null)"
            >
                <span class="material-symbols-outlined add-icon"> add </span>
                {{ "channel.add-groups" | translate }}
            </div>
        </div>
    </div>

    <div #spreadsheet [hidden]="groups.length == 0"></div>
    <div class="sheet-loading" *ngIf="isSheetLoading">
        <app-loading-spinner></app-loading-spinner>
    </div>
</div>

<app-dialog-channel-group
    #groupDialog
    (addGroup)="addGroup($event)"
></app-dialog-channel-group>
