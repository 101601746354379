<div
  [ngStyle]="{
    top: newCommentPosition.top,
    left: newCommentPosition.left,
    position: 'absolute'
  }"
>
  <div class="flex-container">
    <img src="/assets/icons/blue-speech-bubble.svg" class="speech-bubble-img" />
    <div class="my-form-field" [ngStyle]="{ display: 'flex' }">
      <textarea
        class="textarea"
        cdkTextareaAutosize
        [(ngModel)]="commentInput"
        contenteditable="true"
      >
      </textarea>
      <div class="icon-container">
        <img
          src="/assets/icons/arrow-up.svg"
          class="icon"
          (click)="postComment()"
        />
      </div>
    </div>
  </div>
</div>
