import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    public getCurrentProjectId(): string {
        return localStorage.getItem('currentProject');
    }

    public setCurrentProjectId(id: string): void {
        localStorage.setItem('currentProject', id);
    }

    public getPanelsState(): string {
        return localStorage.getItem('@frango.webeditor.panelsState');
    }

    public setPanelsState(panelsState: string): void {
        localStorage.setItem('@frango.webeditor.panelsState', panelsState);
    }

    public getCurrentViewId(): string {
        return localStorage.getItem('@frango.webeditor.currentview');
    }

    public setCurrentViewId(view: string): void {
        localStorage.setItem('@frango.webeditor.currentview', view);
    }
}
