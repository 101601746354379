import { LocalStorageService } from '@service/local-storage.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService } from '@service/project.service';

@Component({
    selector: 'app-project-selector-page',
    templateUrl: './project-selector-page.component.html',
    styleUrls: ['./project-selector-page.component.scss'],
})
export class ProjectSelectorPageComponent {
    constructor(
        private projectService: ProjectService,
        private router: Router,
        private localStorageService: LocalStorageService,
    ) {}

    async ngOnInit() {
        await this.openProjectSelectDialog();
    }

    async openProjectSelectDialog() {
        const selectedProject = await this.projectService.openDialog();
        if (selectedProject) {
            this.localStorageService.setCurrentProjectId(selectedProject.id);
            await this.router.navigate(['/editor', selectedProject.id]);
        }
    }
}
