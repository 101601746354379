export enum ChannelType {
    DIGITAL_NORMAL_CLOSE = 0,
    DIGITAL_NORMAL_OPEN = 1,
    DIGITAL_MOTOR = 2,

    ANALOG_420MA_PT = 21,
    ANALOG_420MA_AI = 22,
    ANALOG_1_5_V = 23,
    ANALOG_PT100_3WIRE = 24,
    ANALOG_PT100_2WIRE = 25,
    ANALOG_TEMP_K = 26,
}

export var ChannelTypeStrings = {};
ChannelTypeStrings[ChannelType.DIGITAL_NORMAL_CLOSE] = 'Digital (Normal close)';
ChannelTypeStrings[ChannelType.DIGITAL_NORMAL_OPEN] = 'Digital (Normal open)';
ChannelTypeStrings[ChannelType.DIGITAL_MOTOR] = 'Digital (Motor)';

ChannelTypeStrings[ChannelType.ANALOG_420MA_AI] = 'Analog (4-20mA PT)';
ChannelTypeStrings[ChannelType.ANALOG_420MA_PT] = 'Analog (4-20mA AI)';
ChannelTypeStrings[ChannelType.ANALOG_1_5_V] = 'Analog (1-5V)';
ChannelTypeStrings[ChannelType.ANALOG_PT100_3WIRE] = 'Analog (Temp Pt100 3-wire)';
ChannelTypeStrings[ChannelType.ANALOG_PT100_2WIRE] = 'Analog (Temp Pt100 2-wire)';
ChannelTypeStrings[ChannelType.ANALOG_TEMP_K] = 'Analog (Temp K)';

export var ChannelTypeStringLookup = {};
for (let type in ChannelTypeStrings) {
    ChannelTypeStringLookup[ChannelTypeStrings[type]] = Number(type);
}

var digitalTypes = new Set([
    ChannelType.DIGITAL_NORMAL_CLOSE,
    ChannelType.DIGITAL_NORMAL_OPEN,
    ChannelType.DIGITAL_MOTOR,
]);

export function isDigital(type: ChannelType) {
    return digitalTypes.has(type);
}
