<div class="tester-panel" *ngIf="show" ngDraggable>
  <style>
    pre {
      white-space: pre-line;
    }
  </style>
  <div name="dlgTesterForm">
    <div #DragHandle class="tester-header">
      <div class="tester-title">
        {{ "tester.title" | translate }}
      </div>
      <div class="tester-close" (click)="close()">&times;</div>
    </div>
    <div class="tester-body">
      <div *ngFor="let item of items | keyvalue" class="variable-tester">
        <div class="svg-property">
          <span
            >{{ item.key }} - {{ item.value["property"].variableId }} :</span
          >
          <input
            id="item.name"
            [(ngModel)]="item.value['property'].variableValue"
            type="text"
            class="no-spinners"
          />
          <button
            mat-raised-button
            color="primary"
            type="button"
            class=""
            (click)="setSignal(item)"
            [disabled]="!item.value['property'].variableId"
          >
            {{ "tester.send" | translate }}
          </button>
        </div>
        <app-tester-check-options
          [item]="item"
          (sendCheckedOptions)="setFlag($event)"
        ></app-tester-check-options>
      </div>
    </div>
  </div>
  <div class="tester-output">
    <span *ngFor="let item of output; index as i" class="output-item">
      {{ item }}
    </span>
  </div>
</div>
