import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';
import { Channel } from 'common/models/channel';
import { ChannelGroup } from 'common/models/channel-group';
import { CompareResponse } from 'icreate/common/models/channel-compare';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ChannelCompareService {
    private domain: string;
    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    public getChannelDiff(
        projectId: string | number,
        vesselId: string,
        builderId: string,
        version: string,
    ): Promise<CompareResponse> {
        const param = {
            projectId,
            vesselId,
            builderId,
            version,
        };

        return lastValueFrom(
            this.http.get<CompareResponse>(`${this.domain}/api/channels/compare`, {
                params: param,
            }),
        );
    }

    public updateChannelDiff(
        projectId: string | number,
        vesselId: string,
        builderId: string,
        updateChannels: { channels: Channel[]; groups: ChannelGroup[] },
        deleteChannels: { channels: Channel[]; groups: ChannelGroup[] },
    ): Promise<any> {
        const param = {
            projectId,
            vesselId,
            builderId,
            updateChannels,
            deleteChannels,
        };

        return lastValueFrom(
            this.http.put(`${this.domain}/api/channels/compare`, {
                params: param,
            }),
        );
    }
}
