import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';

import { Channel } from 'common/models/channel';
import { Observable } from 'rxjs';
@Injectable()
export class ChannelService {
    private domain: string;

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    public getChannels(projectId: string): Observable<Channel[]> {
        const param = {
            projectId: projectId,
        };
        return this.http.get<Channel[]>(`${this.domain}/api/channels`, {
            params: param,
        });
    }

    public getChannel(projectId: string, rowId: string, groupId: string): Observable<Channel> {
        const param = {
            projectId: projectId,
            rowId,
            groupId,
        };
        return this.http.get<Channel>(`${this.domain}/api/channel`, {
            params: param,
        });
    }
}
