/**
 * Base class for errors when importing channels from an excel file
 */
export class ExcelImportError extends Error {
    errorCode: number;
    properties: any;
    /**
     * @param {number} errorCode An error code from ErrorCodes
     * @param {object} properties Details of the error, such as erronous value or row number
     */
    constructor(errorCode, properties = {}) {
        super();
        this.errorCode = errorCode;
        this.properties = properties;
    }
}

export enum ErrorCodes {
    UNKNOWN_HEADER = 400,
    MISSING_HEADER = 401,
    MISSING_REQUIRED_VALUE = 402,
    VALUE_NOT_ALLOWED = 410,
    INVALID_STATUS_STRING = 420,
    UNIQUENESS_VALUE = 421,
    ALARM_OVERLAP = 430,
    INVALID_RANGE = 440,
    NORMAL_RANGE_OUTSIDE_MEASURABLE_RANGE = 441,
    ALARM_OUTSIDE_MEASURABLE_RANGE = 442,
    MISSING_CATEGORY = 450,
    MISSING_CATEGORY_SHEET = 451,

    //Server Errors
    UNKNOWN_ERROR = 500,
}

export var ErrorNameLookup = {};

function initNameLookup() {
    let errorNames = Object.keys(ErrorCodes);
    for (let i = 0; i < errorNames.length; i++) {
        ErrorNameLookup[ErrorCodes[errorNames[i]]] = errorNames[i];
    }
}

initNameLookup();
