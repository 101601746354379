export function calculateRectPoints(rectangle) {
    const initialX = Number(rectangle.getAttribute('x'));
    const initialY = Number(rectangle.getAttribute('y'));
    const height = Number(rectangle.getAttribute('height'));
    const width = Number(rectangle.getAttribute('width'));

    const topLeft = {
        x: initialX,
        y: initialY,
    };
    const topRight = {
        x: initialX + width,
        y: initialY,
    };
    const bottomLeft = {
        x: initialX + width,
        y: initialY + height,
    };
    const bottomRight = {
        x: initialX,
        y: initialY + height,
    };

    const verticalMiddleLine = [];
    verticalMiddleLine.push({
        x: initialX,
        y: Math.round(initialY + height / 4),
    });
    verticalMiddleLine.push({
        x: initialX,
        y: Math.round(initialY + (height * 2) / 4),
    });
    verticalMiddleLine.push({
        x: initialX,
        y: Math.round(initialY + (height * 3) / 4),
    });
    verticalMiddleLine.push({
        x: initialX + width,
        y: Math.round(initialY + height / 4),
    });
    verticalMiddleLine.push({
        x: initialX + width,
        y: Math.round(initialY + (height * 2) / 4),
    });
    verticalMiddleLine.push({
        x: initialX + width,
        y: Math.round(initialY + (height * 3) / 4),
    });

    const horizontalMiddleLine = [];
    horizontalMiddleLine.push({
        x: Math.round(initialX + width / 4),
        y: initialY,
    });
    horizontalMiddleLine.push({
        x: Math.round(initialX + (width * 2) / 4),
        y: initialY,
    });
    horizontalMiddleLine.push({
        x: Math.round(initialX + (width * 3) / 4),
        y: initialY,
    });
    horizontalMiddleLine.push({
        x: Math.round(initialX + width / 4),
        y: initialY + height,
    });
    horizontalMiddleLine.push({
        x: Math.round(initialX + (width * 2) / 4),
        y: initialY + height,
    });
    horizontalMiddleLine.push({
        x: Math.round(initialX + (width * 3) / 4),
        y: initialY + height,
    });

    return [
        topLeft,
        topRight,
        bottomLeft,
        bottomRight,
        ...horizontalMiddleLine,
        ...verticalMiddleLine,
    ];
}
