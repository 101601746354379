import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '@service/project.service';
import { ViewGroupsService } from '@service/view-groups.service';
import { View, ViewGroup } from 'common/models/view';
import { DialogNewViewGroupComponent } from '../dialog-new-view-group/dialog-new-view-group.component';

@Component({
    selector: 'app-views',
    templateUrl: './views.component.html',
    styleUrls: ['./views.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ViewsComponent {
    @Input() viewGroups = [];
    @Input() hmi;
    @Input() views = [];
    @Input() currentView;
    @Input() currentProjectId;
    @Input() editingView;
    @Output() onSelectedView = new EventEmitter<View>();
    @Output() onCloneView = new EventEmitter<View>();
    @Output() onExportView = new EventEmitter<View>();
    @Output() onSetPDF = new EventEmitter<View>();
    @Output() onChangeEdit = new EventEmitter<View>();
    @Output() onFinishEdit = new EventEmitter<View>();
    @Output() onSaveView = new EventEmitter<View>();
    @Output() addView = new EventEmitter();

    openGroup: Boolean = false;
    viewList: Views[] = [];
    selectedView: View;
    viewCategories: ViewCategory[] = [];

    constructor(
        private viewGroupsService: ViewGroupsService,
        public dialog: MatDialog,
        private projectService: ProjectService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.viewGroups) {
            this.updateGroupsList();
        }
        if (changes.views) {
            this.updateList();
        }

        if (!changes.views && changes.currentView?.currentValue) {
            if (!this.isExistViewFromViewList(changes.currentView.currentValue.id)) {
                this.updateList();
            }
        }
    }

    async dropInGroup(event: CdkDragDrop<ViewCategory | ViewCategory[]>, category: ViewCategory) {
        let isExistDropedCategory = category.views.find((view) => view.id === this.selectedView.id);
        if (!isExistDropedCategory) {
            category.views.splice(event.currentIndex, 0, this.selectedView);
            this.viewGroupsService.updateViewCategory(category.group.id, this.selectedView.id);
            //category to category section
            const previousCategory = { ...event.previousContainer.data } as ViewCategory;
            if (previousCategory.type === viewsGroupsArrayTypes.container) {
                let category = this.viewCategories.find(
                    (category: ViewCategory) => category.group.id === previousCategory.group.id,
                );
                category.views = previousCategory.views.filter(
                    (veiw) => veiw.id !== this.selectedView.id,
                );
            } else {
                //list to category section
                await this.updateViews();
                this.updateList();
            }
        } else {
            //same category reorder.
            const category = { ...event.container.data } as ViewCategory;
            let selected = category.views[event.previousIndex];
            category.views.splice(event.previousIndex, 1);
            category.views.splice(event.currentIndex, 0, selected);
        }
    }

    async dropInList(event: CdkDragDrop<Views[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        let preview = event.previousContainer as any;
        if (preview.data.type === viewsGroupsArrayTypes.container) {
            await this.viewGroupsService.updateViewCategory(null, this.selectedView.id);
            await this.updateViews();
        }

        if (!this.isExistViewFromViewList(this.selectedView.id)) {
            this.viewList.splice(event.currentIndex, 0, this.generateViewList(this.selectedView));
        }
        this.viewGroups = await this.viewGroupsService.getAll(this.projectService.getProjectId());
        this.updateGroupsList();
    }

    dragStarted(event: View) {
        this.selectedView = event;
    }

    handleEditViewGroup(group: ViewGroup) {
        let views = [];
        const dialogRef = this.dialog.open(DialogNewViewGroupComponent, {
            position: { top: '60px' },
            data: {
                name: group.groupName,
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result.name && result.name !== group.groupName) {
                group.groupName = result.name;
                views = this.filteredViewByGroup(group, this.views);
                await this.viewGroupsService.updateName(group);
            }
        });
    }

    async updateViews() {
        const updatedViews = await this.viewGroupsService.getViewsByProjectId(
            this.currentProjectId,
        );
        this.views = updatedViews;
    }

    async handleDeleteViewGroup(group: ViewGroup) {
        const groupId = group.id;
        await this.viewGroupsService.delete(groupId);
        this.viewGroups = await this.viewGroupsService.getAll(this.projectService.getProjectId());
        await this.updateViews();
        this.updateGroupsList();
        this.updateList();
    }

    updateGroupsList() {
        this.viewCategories = [];
        if (this.viewGroups && Array.isArray(this.viewGroups)) {
            this.viewGroups.forEach((group) => {
                this.viewCategories.push(this.generateViewCatetories(group, this.views));
            });
        }
    }

    updateList() {
        this.viewList = [];
        if (this.views && Array.isArray(this.views)) {
            this.views.forEach((view) => {
                if (!view.viewsGroupId) {
                    this.viewList.push(this.generateViewList(view));
                }
            });
        }
    }

    generateViewList(view: View) {
        return {
            type: viewsGroupsArrayTypes.view,
            view: view,
        };
    }

    generateViewCatetories(group: ViewGroup, views: View[]) {
        return {
            type: viewsGroupsArrayTypes.container,
            group: group,
            views: this.filteredViewByGroup(group, views),
        };
    }

    isExistViewFromViewList(existingViewId: string) {
        return this.viewList.find((view) => view.view.id === existingViewId);
    }

    isActiveView(view: View) {
        return view.id == this.currentView.id;
    }

    filteredViewByGroup(group: ViewGroup, views: View[]) {
        return views.filter((view: View) => view.viewsGroupId === group.id);
    }

    stopClickEvent(event: MouseEvent) {
        event.stopPropagation();
    }

    //emitters start
    onFinishEditEmitter(event: View) {
        this.onFinishEdit.emit(event);
    }

    onSetPdfEmitter(event: View) {
        this.onSetPDF.emit(event);
    }

    onSeletedViewEmitter(event: View) {
        this.onSelectedView.emit(event);
    }

    onSaveViewEmitter(event: View) {
        this.onSaveView.emit(event);
    }
    //emitters end
}

export enum viewsGroupsArrayTypes {
    container = 'container',
    view = 'view',
}

export interface Views {
    type: viewsGroupsArrayTypes;
    view: View;
}

export interface ViewCategory {
    type: viewsGroupsArrayTypes;
    group: ViewGroup;
    views: View[];
}
