import { AlarmLevel } from './alarm-level';
import { ChannelFcuData, ChannelFcuDataFns } from './channel-fcu-data';
import { ChannelOpsData, ChannelOpsDataFns } from './channel-ops-data';
import { ChannelType, ChannelTypeStrings, isDigital } from './channel-type';

export class Channel {
    id: string;
    projectId: string;
    groupId: string;
    opsData: ChannelOpsData;
    fcuData: ChannelFcuData;
    rowIndex?: number;
    groupName?: String;
    checked?: Boolean;
}

export enum CalculatedAlarmLevel {
    VERY_LOW,
    LOW,
    NORMAL,
    HIGH,
    VERY_HIGH,

    GAP_LOW_TO_NORMAL,
    GAP_NORMAL_TO_HIGH,

    UNKNOWN,
}

export const ChannelFns = {
    create,
    getCalculatedAlarmLevel,
    toAlarmLevel,
    scaleValue,
    transformValue,
    isDigital: isChannelDigital,
    setChannelType,
};

function create(): Channel {
    let fcuData = ChannelFcuDataFns.create();
    let opsData = ChannelOpsDataFns.create();

    return {
        id: '',
        projectId: '',
        groupId: '',
        opsData: opsData,
        fcuData: fcuData,
    };
}

function toAlarmLevel(channel: Channel, alarmLevel: CalculatedAlarmLevel): AlarmLevel {
    switch (alarmLevel) {
        case CalculatedAlarmLevel.VERY_LOW:
            return channel.fcuData.alarmSettingVeryLow.alarmLevel;
        case CalculatedAlarmLevel.LOW:
            return channel.fcuData.alarmSettingLow.alarmLevel;
        case CalculatedAlarmLevel.HIGH:
            return channel.fcuData.alarmSettingHigh.alarmLevel;
        case CalculatedAlarmLevel.VERY_HIGH:
            return channel.fcuData.alarmSettingVeryHigh.alarmLevel;
        default:
            return AlarmLevel.None;
    }
}

function getCalculatedAlarmLevel(channel: Channel, value: number) {
    if (
        channel.fcuData.alarmSettingVeryHigh.enable &&
        value >= channel.fcuData.alarmSettingVeryHigh.setPoint
    ) {
        return CalculatedAlarmLevel.VERY_HIGH;
    } else if (
        channel.fcuData.alarmSettingHigh.enable &&
        value >= channel.fcuData.alarmSettingHigh.setPoint
    ) {
        return CalculatedAlarmLevel.HIGH;
    } else if (value > channel.fcuData.normalRange.max) {
        return CalculatedAlarmLevel.GAP_NORMAL_TO_HIGH;
    } else if (value >= channel.fcuData.normalRange.min) {
        return CalculatedAlarmLevel.NORMAL;
    } else if (
        channel.fcuData.alarmSettingVeryLow.enable &&
        value <= channel.fcuData.alarmSettingVeryLow.setPoint
    ) {
        return CalculatedAlarmLevel.VERY_LOW;
    } else if (
        channel.fcuData.alarmSettingLow.enable &&
        value <= channel.fcuData.alarmSettingLow.setPoint
    ) {
        return CalculatedAlarmLevel.LOW;
    } else if (value < channel.fcuData.normalRange.min) {
        return CalculatedAlarmLevel.GAP_LOW_TO_NORMAL;
    } else {
        return CalculatedAlarmLevel.UNKNOWN;
    }
}

function transformValue(channel: Channel, value: number) {
    const currentValue = isChannelDigital(channel)
        ? getDigitalString(channel, value)
        : scaleValue(channel, value);
    return Number.isNaN(currentValue) ? ' - ' : currentValue;
}

function scaleValue(channel: Channel, value: number) {
    return value / channel.fcuData.scale;
}

function getDigitalString(channel: Channel, value: number) {
    return value
        ? channel.fcuData.alarmSetting.digitalOnString
        : channel.fcuData.alarmSetting.digitalOffString;
}

function isChannelDigital(channel: Channel): boolean {
    return isDigital(channel.fcuData.channelType);
}

function setChannelType(channel: Channel, type: ChannelType) {
    channel.opsData.channelType = ChannelTypeStrings[type];
    channel.fcuData.channelType = type;
}
