import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { ChannelService } from '@service/channel.service';
import { NotificationService } from '@service/notification.service';
import { ProjectService } from '@service/project.service';
import { ReposeSettingsService } from '@service/repose-settings.service';
import { Channel } from 'common/models/channel';
import { ChannelType } from 'common/models/channel-type';
import { MAX_REPOSE_SETTINGS, ReposeSetting } from 'common/models/repose-setting';
import { getValidationErrors } from 'common/validators';
import { ReposeSettingValidator } from 'common/validators/repose-settings/repose-settings-validator';
import * as _ from 'lodash';
import { Subscription, lastValueFrom, take } from 'rxjs';

@Component({
    selector: 'app-repose-settings',
    templateUrl: './repose-settings.component.html',
    styleUrls: ['./repose-settings.component.scss'],
})
export class ReposeSettingsComponent implements OnInit, OnDestroy {
    @ViewChild('confirmationWindow') confirmationWindow: ConfirmationWindowComponent;

    initalReposeList: ReposeSetting[];
    reposeList: ReposeSetting[];
    selectedSetting: ReposeSetting;
    channels: Channel[];

    deletedSettingSubscription: Subscription;
    newSettingSubscription: Subscription;
    updatedSettingSubscription: Subscription;

    activeSubscriptions: Subscription[];

    constructor(
        private router: Router,
        private channelService: ChannelService,
        private projectService: ProjectService,
        private reposeSettingService: ReposeSettingsService,
        private notificationService: NotificationService,
    ) {}

    async ngOnInit() {
        const projectId = this.projectService.getProjectId();
        this.reposeList = await this.reposeSettingService.getAll(projectId);
        this.startSocketSubscriptions();
        this.channels = await lastValueFrom(this.channelService.getChannels(projectId));
        this.initalReposeList = _.cloneDeep(this.reposeList);
    }

    startSocketSubscriptions() {
        this.newSettingSubscription = this.reposeSettingService
            .settingCreated$()
            .subscribe((reposeSetting: ReposeSetting) => {
                this.reposeList.push(reposeSetting);
            });

        this.deletedSettingSubscription = this.reposeSettingService
            .settingDeleted$()
            .subscribe((reposeId) => {
                const index = this.reposeList.findIndex(
                    (reposeSetting) => reposeSetting.id === reposeId,
                );
                if (index !== -1) {
                    this.reposeList.splice(index, 1);
                }
            });

        this.updatedSettingSubscription = this.reposeSettingService
            .settingUpdated$()
            .subscribe((updatedSetting: ReposeSetting) => {
                this.reposeList.map((reposeSetting) => {
                    if (reposeSetting.id === updatedSetting.id) {
                        reposeSetting = updatedSetting;
                    }
                });
            });

        this.activeSubscriptions = [
            this.deletedSettingSubscription,
            this.newSettingSubscription,
            this.updatedSettingSubscription,
        ];
    }

    ngOnDestroy(): void {
        this.activeSubscriptions?.map((sub) => {
            sub.unsubscribe();
        });
    }

    canAddSetting() {
        return this.reposeList.length < MAX_REPOSE_SETTINGS;
    }

    async addReposeSetting() {
        const projectId = this.projectService.getProjectId();
        try {
            const reposeSetting = new ReposeSetting();
            reposeSetting.projectId = projectId;
            this.reposeList.push(reposeSetting);
        } catch (err) {
            this.notificationService.errorMessage('repose-settings.notification.error.create');
        }
    }

    async deleteReposeSetting() {
        try {
            const index = this.reposeList.findIndex(
                (reposeSettings) => reposeSettings === this.selectedSetting,
            );
            if (index !== -1) {
                this.reposeList.splice(index, 1);
                this.selectedSetting = null;
                this.notificationService.successMessage(
                    'repose-settings.notification.success.delete',
                );
            }
        } catch (err) {
            this.notificationService.errorMessage('repose-settings.notification.error.delete');
        }
    }

    saveList() {
        try {
            const updatedSettings = this.getChangedSettings();
            const deletedSettings = this.getDeletedSettings();

            updatedSettings.map(async (reposeSetting) => {
                await this.reposeSettingService.update(reposeSetting);
            });

            deletedSettings.forEach(async (reposeSetting: ReposeSetting) => {
                await this.reposeSettingService.delete(reposeSetting.id);
            });

            this.initalReposeList = _.cloneDeep(this.reposeList);
            this.notificationService.successMessage('repose-settings.notification.success.save');
        } catch (err) {
            this.notificationService.errorMessage('repose-settings.notification.error.save');
        }
    }

    async exit() {
        if (this.settingsListHasChanged()) {
            this.confirmationWindow.setVisible(true);
            const closeWidndow = await lastValueFrom(
                this.confirmationWindow.onOptionSelected.pipe(take(1)),
            );
            if (closeWidndow) {
                const projectId = this.projectService.getProjectId();
                this.router.navigate(['editor', projectId]);
            }
        } else {
            const projectId = this.projectService.getProjectId();
            this.router.navigate(['editor', projectId]);
        }
    }

    settingsListHasChanged() {
        return !_.isEqual(this.initalReposeList, this.reposeList);
    }

    getChangedSettings() {
        const updatedObjects = [];

        this.reposeList.forEach((newObj) => {
            const oldObj = this.initalReposeList.find((o) => o.id === newObj.id);
            if (oldObj && !_.isEqual(oldObj, newObj)) {
                updatedObjects.push(newObj);
            } else if (!oldObj) {
                updatedObjects.push(newObj);
            }
        });

        return updatedObjects;
    }

    getDeletedSettings() {
        const deletedSettings = [];

        this.initalReposeList.forEach((initalSetting) => {
            const foundSetting = this.reposeList.find((o) => o.id === initalSetting.id);
            if (!foundSetting) {
                deletedSettings.push(initalSetting);
            }
        });

        return deletedSettings;
    }

    saveButtonIsDisabled() {
        return this.settingListhasErrors() || !this.settingsListHasChanged();
    }

    settingListhasErrors() {
        let hasErrors = false;
        this.reposeList.map((reposeSetting) => {
            const errors = getValidationErrors(ReposeSettingValidator(), reposeSetting);
            if (errors.length > 0) {
                hasErrors = true;
            } else {
                let filteredChannels = this.channels.filter((channel) => {
                    if (channel.fcuData.fcuChannelId === reposeSetting.trigger) {
                        if (this.isDigitalChannel(channel.fcuData.channelType)) {
                            return false;
                        }
                        return true;
                    }
                    return false;
                });
                if (filteredChannels.length === 0) {
                    hasErrors = true;
                }
            }
        });

        return hasErrors;
    }

    isDigitalChannel(channelType) {
        const digitalChannelTypes = new Set([
            ChannelType.DIGITAL_MOTOR,
            ChannelType.DIGITAL_NORMAL_CLOSE,
            ChannelType.DIGITAL_NORMAL_OPEN,
        ]);
        return digitalChannelTypes.has(channelType);
    }

    handleSelectedChanged(reposeSetting) {
        if (reposeSetting) {
            this.selectedSetting = reposeSetting;
        } else {
            this.selectedSetting = null;
        }
    }
}
