<div #overlay class="confirm-overlay" [ngStyle]="style">
    <div class="confirm-content center-content">
        <div *ngIf="!isLoadingPdf" class="search-container">
            <div>
                <app-graph-selector
                    [graphList]="graphList"
                    (onGraphSelectedChanged)="
                        handleSelectedGraphChanged($event)
                    "
                >
                </app-graph-selector>
                <mat-checkbox
                    [(ngModel)]="compareVersionGraphs"
                    *ngIf="selectedGraphs.length > 0"
                >
                    {{ "print-graphs.button.compare" | translate }}
                </mat-checkbox>
                <app-project-version-selector
                    *ngIf="compareVersionGraphs && selectedGraphs.length > 0"
                    [projectVersion]="projectVersion"
                    (onVersionSelected)="projectVersionSelected($event)"
                    (onDisablePrint)="disablePrintButton($event)"
                >
                </app-project-version-selector>
            </div>

            <div
                style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 30px;
                "
            >
                <button (click)="exit()">
                    {{ "print-graphs.button.exit" | translate }}
                </button>
                <button
                    (click)="printGraphs()"
                    [disabled]="shouldDisablePrintButton()"
                >
                    {{ "print-graphs.button.print" | translate }}
                </button>
            </div>
        </div>
        <app-loading-spinner #loadingSpinner *ngIf="isLoadingPdf">
        </app-loading-spinner>
    </div>
</div>
