export class View {
    id: string = '';
    svgcontent: string = '';
    items: DictionaryGaugeSettings = {};
    profile: DocProfile = new DocProfile();
    name: string;
    projectId: any;
    type: string;
    createdAt?: any;
    updatedAt?: any;
    locked: number = 0;
    lockingEditorName: string;
    userID: string;
    category: string;
    variables?: any | DictionaryVariables = {};
    viewsGroupId?: string;
}

export interface ViewGroup {
    id: string;
    projectId: any;
    groupName: string;
}

export class DocProfile {
    width?: number = 1920;
    height?: number = 1080;
    bkcolor?: string = '';
    margin?: number = 10;
}

export class GaugeSettings {
    name: string = '';
    property: any = null; // set to GaugeProperty after upgrate
    label: string = '';
    constructor(
        public id: string,
        public type: string,
    ) {}
}
export interface DictionaryGaugeSettings {
    [x: string]: any | GaugeSettings;
}

export enum ViewType {
    svg = 'editor.view-svg',
    cards = 'editor.view-cards',
}

export const viewTypeArray = ['svg', 'cards'];

interface DictionaryVariables {
    [id: string]: Variable;
}

export class Variable {
    id: string;
    name: string;
    source: string;
    value: string;
    error: number;
    //ams specific settings
    alarm: boolean;
    repose: boolean;
    sensor: boolean;
    blink: boolean;

    constructor(id: string, source: string, name: string) {
        this.id = id;
        this.name = name;
        this.source = source;
    }
}
export class Hmi {
    /** Layout for navigation menu, header bar, ...  */
    layout: LayoutSettings = new LayoutSettings();
    /** Views list of hmi project */
    views: View[] = [];
}

export class LayoutSettings {
    /** Start view (home) */
    start: string = '';
    /** Left side navigation menu settings */
    navigation: NavigationSettings = new NavigationSettings();
    /** On top header settings */
    header: HeaderSettings = new HeaderSettings();
    /** Show development blue button (Home, Lab, Editor) */
    showdev: boolean = true;
    /** Enable zoom in view */
    zoom: ZoomModeType;
    /** Show input dialog for input field */
    inputdialog: string = 'false';
    /** Hide navigation Header and sidebarmenu */
    hidenavigation: boolean = false;
    /** GUI Theme */
    theme: string = '';
    /** Canvas Size */
    targetresolution: ScreenResolution;
}

export class NavigationSettings {
    /** Side menu mode (over, push, fix) */
    mode: NaviModeType;
    /** Menu item show type (text, icon) */
    type: NaviItemType;
    /** Menu background color */
    bkcolor: string;
    /** Menu item text and icon color */
    fgcolor: string;
    /** Menu items */
    items: NaviItem[];
    constructor() {
        this.mode = Object.keys(NaviModeType).find(
            (key) => (NaviModeType as any)[key] === NaviModeType.over,
        ) as NaviModeType;
        this.type = Object.keys(NaviItemType).find(
            (key) => (NaviModeType as any)[key] === NaviItemType.block,
        ) as NaviItemType;
    }
}

export enum NaviModeType {
    void = 'item.navsmode-none',
    push = 'item.navsmode-push',
    over = 'item.navsmode-over',
    fix = 'item.navsmode-fix',
}

export enum NaviItemType {
    icon = 'item.navtype-icons',
    text = 'item.navtype-text',
    block = 'item.navtype-icons-text-block',
    inline = 'item.navtype-icons-text-inline',
}

export interface NaviItem {
    text: string;
    link: string;
    view: string;
    icon: string;
    permission: number;
}

export class HeaderSettings {
    title: string;
    alarms: NotificationModeType;
    infos: NotificationModeType;
    bkcolor: string = '#ffffff';
    fgcolor: string = '#000000';
}

export enum NotificationModeType {
    hide = 'item.notifymode-hide',
    fix = 'item.notifymode-fix',
    float = 'item.notifymode-float',
}

export enum ZoomModeType {
    disabled = 'item.zoommode-disabled',
    enabled = 'item.zoommode-enabled',
}

export enum InputModeType {
    false = 'item.inputmode-disabled',
    true = 'item.inputmode-enabled',
}

export enum HeaderBarModeType {
    true = 'item.headerbarmode-hide',
    false = 'item.headerbarmode-show',
}

export interface ScreenResolution {
    identifier: string;
    displayName: string;
    width: number;
    height: number;
}
