import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationError } from 'icreate/common/validators';

import { ToastrService } from 'ngx-toastr';
@Injectable()
export class NotificationService {
    constructor(
        private toastr: ToastrService,
        private translateService: TranslateService,
    ) {}

    successMessage(message: string) {
        let msg = '';
        this.translateService.get(message).subscribe((txt: string) => {
            msg = txt;
            this.toastr.success(msg, '', {
                timeOut: 6000,
                closeButton: true,
            });
        });
    }

    errorMessage(message: string) {
        let msg;
        this.translateService.get(message).subscribe((txt: string) => {
            msg = txt;
            this.toastr.error(msg, '', {
                timeOut: 6000,
                closeButton: true,
            });
        });
    }

    infoMessage(message: string) {
        let msg = '';
        this.translateService.get(message).subscribe((txt: string) => {
            msg = txt;
            this.toastr.info(msg, '', {
                timeOut: 6000,
                closeButton: true,
            });
        });
    }

    validationError(error: ValidationError) {
        this.toastr.error(this.translateService.instant(error.message, error.params), '', {
            timeOut: 6000,
            closeButton: true,
        });
    }

    projectPullError(projectName: string, error?: { message: string }) {
        let msg = '';
        if (error?.message) {
            msg = this.translateService.instant(error.message, { projectName });
            this.toastr.error(msg, '', {
                timeOut: 6000,
                closeButton: true,
            });
        } else {
            this.errorMessage('msg.project-pull-fail');
        }
    }

    projectPublishError(error?: { message: string }) {
        let msg = '';
        if (error) {
            msg = error.message;
            this.toastr.error(msg, '', {
                timeOut: 6000,
                closeButton: true,
            });
        } else {
            this.errorMessage('msg.project-publish-fail');
        }
    }

    requestPublishProjectError() {
        this.errorMessage('msg.request-project-publish-fail');
    }

    requestPublishProjectSuccessful() {
        this.successMessage('msg.request-project-publish-success');
    }

    projectPublishSuccessful() {
        this.successMessage('msg.project-publish-success');
    }

    projectPullSuccessful() {
        this.successMessage('msg.project-pull-success');
    }

    projectOverwriteSuccessful() {
        this.successMessage('msg.project-overwrite-success');
    }

    projectOverwriteError() {
        this.errorMessage('msg.project-overwrite-fail');
    }

    updateDeviceError(error: string) {
        this.errorMessage('msg.update-device-error');
        this.errorMessage(error);
    }

    saveTemplatesError(error: string) {
        this.errorMessage('msg.saving-templates-error');
        this.errorMessage(error);
    }

    accessBlobError() {
        this.errorMessage('msg.access-blob-error');
    }

    loadProjectError() {
        this.errorMessage('msg.project-load-error');
    }

    projectUpToDate() {
        this.infoMessage('msg.project-up-to-date');
    }

    opsUsersSavedSuccess() {
        this.successMessage('msg.ops-users.save.success')
    }

    opsUsersSavedError() {
        this.errorMessage('msg.ops-users.save.error')
    }
}
