import { locale } from 'common/validators/yup-locale';
import * as yup from 'yup';
import { ObjectSchema, object, string } from 'yup';
yup.setLocale(locale);

export class ChannelCategory {
    category: string;
}

export function CategoryValidator(): ObjectSchema<ChannelCategory> {
    return object({
        category: string().nonNullable().strict().required(),
    })
        .noUnknown()
        .strict();
}
