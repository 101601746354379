import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { Utils } from '@helpers/utils';
import { GaugeProperty } from '@models/hmi';
import { Tag, Device } from 'icreate/common/models/device';

interface Variable {
    id: string;
    name: string;
}

@Component({
    selector: 'flex-head',
    templateUrl: './flex-head.component.html',
    styleUrls: ['./flex-head.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class FlexHeadComponent implements OnInit {
    @Input() data: any;
    aultValue;
    @Input() property: GaugeProperty;
    @Input() withStaticValue = true;

    variable: any;
    withInput = null;
    alarme: any;
    currentTag: Tag = null;
    defaultValue: any;
    defaultColor = Utils.defaultColor;

    // alarm: string;
    public alarmDeviceCtrl: UntypedFormControl = new UntypedFormControl();
    public alarmDeviceFilterCtrl: UntypedFormControl = new UntypedFormControl();

    public alarmCtrl: UntypedFormControl = new UntypedFormControl();
    public alarmFilterCtrl: UntypedFormControl = new UntypedFormControl();

    /** list of variable filtered by search keyword */
    public filteredAlarmDevice: ReplaySubject<Device[]> = new ReplaySubject<Device[]>(1);
    /** list of variable filtered by search keyword */
    public filteredAlarm: ReplaySubject<Variable[]> = new ReplaySubject<Variable[]>(1);

    /** Subject that emits when the component has been destroyed. */
    private _onDestroy = new Subject<void>();

    constructor() {}

    ngOnInit() {
        if (!this.property) {
            this.property = new GaugeProperty();
        }
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getProperty() {
        return this.property;
    }

    getVariableLabel(vari) {
        if (vari.label) {
            return vari.label;
        } else {
            return vari.name;
        }
    }

    setVariable(event) {
        this.property.variableId = event.variableId;
        this.property.variableValue = event.variableValue;
        this.property.editorProperties = event.alarmThreshold;
        this.property.ranges = event.ranges;
    }

    onAddInput() {}

    onRangeViewToggle(slideView) {}
}
