import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewGroup } from 'common/models/view';

@Component({
    selector: 'app-view-group',
    templateUrl: './view-group.component.html',
    styleUrls: ['./view-group.component.css'],
})
export class ViewGroupComponent {
    @Input() group: ViewGroup;
    @Output() onEditGroup = new EventEmitter();
    @Output() onDeleteGroup = new EventEmitter();
    openGroup: Boolean = false;

    expandGroup() {
        this.openGroup ? (this.openGroup = false) : (this.openGroup = true);
    }
}
