import { RuntimeEnvironments } from '../app/models/runtime-enviroment.enum';

declare function require(moduleName: string): any;
export const environment = {
    env: RuntimeEnvironments.CLOUD,
    version: require('../../package.json').version,
    production: false,
    apiEndpoint: null,
    apiPort: null,
    serverEnabled: true,
    type: null,
};
