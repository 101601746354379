import { Injectable } from '@angular/core';
import { Graph, GraphType } from 'common/models/graphs';
import { Subject, lastValueFrom } from 'rxjs';
import { GraphService } from './graph.service';

import { printBarGraph } from './print-bar-graph-utils';
import { printGaugeGraph } from './print-gauge-graph-utils';
import { printExhaustGraph } from './print exhaust-graph-utils';
import { printDeviationGraph } from './print-deviation-graph-utils';
import { printTurboChargerGraph } from './print-turbo-charger-graph-utils';
import { ChannelService } from '@service/channel.service';
import { ProjectService } from '@service/project.service';
import { Channel } from 'common/models/channel';

@Injectable({
    providedIn: 'root',
})
export class PrintGraphsService {
    onPrintGraphs: Subject<void> = new Subject<void>();

    constructor(
        private graphService: GraphService,
        private channelService: ChannelService,
        private projectService: ProjectService,
    ) {}

    async getPdpFile(graphs: Graph[], versionsDifferences) {
        const graphsToPrint = [];
        await Promise.all(
            graphs.map(async (graph) => {
                const graphToPrint = await this.graphService.getGraph(graph.id, graph.type);
                graphsToPrint.push(graphToPrint);
            }),
        );

        const projectId = this.projectService.getProjectId();
        const projectChannels: Channel[] = await lastValueFrom(
            this.channelService.getChannels(projectId),
        );

        return await this.preparePdf(graphsToPrint, projectChannels, versionsDifferences);
    }

    async preparePdf(graphs, projectChannels, versionsDifferences) {
        const pdfContent = await this.generatePdfContent(
            graphs,
            projectChannels,
            versionsDifferences,
        );

        return {
            pageOrientation: 'landscape',
            pageSize: 'A4',
            content: pdfContent,
            pageMargins: [34.0157, 62.3622, 34.0157, 36.8504],
        };
    }

    async generatePdfContent(graphs: Graph[], projectChannels: Channel[], versionsDifferences) {
        const content = [];
        for (var i = 0; i < graphs.length; i++) {
            const graphDifferences = versionsDifferences[graphs[i].id];
            let contentObject: any = {
                layout: this.getPdfLayout(),
                pageBreak: 'before',
            };

            if (i === 0) {
                contentObject = {
                    layout: this.getPdfLayout(),
                };
            }

            if (graphs[i].type === GraphType.Bar) {
                const graphTabel = await printBarGraph(
                    graphs[i],
                    projectChannels,
                    graphDifferences,
                );
                contentObject.table = graphTabel;
                content.push(contentObject);
            } else if (graphs[i].type === GraphType.Gauge) {
                const graphTabel = await printGaugeGraph(
                    graphs[i],
                    projectChannels,
                    graphDifferences,
                );
                contentObject.table = graphTabel;
                content.push(contentObject);
            } else if (graphs[i].type === GraphType.Exhaust) {
                const exhaustGraph = await printExhaustGraph(
                    graphs[i],
                    projectChannels,
                    graphDifferences,
                );
                contentObject.table = exhaustGraph;
                content.push(contentObject);
                const deviationGraph = await printDeviationGraph(
                    graphs[i],
                    projectChannels,
                    graphDifferences,
                );
                contentObject = {
                    layout: this.getPdfLayout(),
                    pageBreak: 'before',
                };
                contentObject.table = deviationGraph;
                content.push(contentObject);
                const turboChargerGraph = await printTurboChargerGraph(
                    graphs[i],
                    projectChannels,
                    graphDifferences,
                );
                contentObject = {
                    layout: this.getPdfLayout(),
                    pageBreak: 'before',
                };
                contentObject.table = turboChargerGraph;
                content.push(contentObject);
            }
        }
        return content;
    }

    getPdfLayout() {
        return {
            paddingTop: () => {
                return 0;
            },
            paddingRight: () => {
                return 0;
            },
            paddingLeft: () => {
                return 0;
            },
            paddingBottom: () => {
                return 0;
            },
        };
    }
}
