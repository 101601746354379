<div style="display: block; margin-top: 20px">
    <div class="input-wrapper">
        <div class="label">
            {{ "print-channels.label.project-version-selector" | translate }}
        </div>
        <div class="form-input" (click)="projectVersionList()">
            <mat-icon
                *ngIf="loadingInputData"
                svgIcon="loading-icon"
                class="loading-icon"
                ></mat-icon
            >
            <input
                [disabled]="loadingInputData"
                matInput
                type="text"
                required="required"
                placeholder="Not Selected"
                [value]="projectData?.AMSVersion"
                readonly="true"
                [ngClass]="{'disabled-input': loadingInputData}"
            />
            <mat-icon
                style="
                    vertical-align: middle;
                    transform: translate(-120%, 0);
                    color: white;
                "
                >link</mat-icon
            >
        </div>
    </div>
</div>
