import { Channel } from 'common/models/channel';
import { ExhaustChannel } from 'common/models/graphs';
import * as echarts from 'echarts';

export async function printExhaustGraph(graph, projectChannels: Channel[], graphDifferences) {
    const exhaustChannels = graph.EXHAUST_GAS.exhaustChannels;

    if (!exhaustChannels || exhaustChannels.length === 0) {
        return;
    }

    const channelsIdsSet: Set<number> = new Set();
    exhaustChannels.forEach((channel: ExhaustChannel) => {
        channelsIdsSet.add(channel.fcuChannelId);
    });

    const graphChannels = getGraphChannels(channelsIdsSet, projectChannels);
    const { notBorderedLables, borderedLables } = prepareXAxislabel(
        exhaustChannels,
        graphDifferences?.channelsChanges?.exhaustGraph,
    );
    const graphValuesArray = prepareGraphValuesArray(graphChannels);
    const maxYAxis = findMaxYAxis(graphChannels);
    const titleBorder = graphDifferences?.oldTitle
        ? [true, true, true, true]
        : [false, false, false, false];

    let exhaustGraphDivision = document.createElement('div');
    exhaustGraphDivision.style.height = '490pt';
    exhaustGraphDivision.style.width = '763pt';
    let myChart = echarts.init(exhaustGraphDivision);
    let option;

    option = {
        color: ['black'],
        xAxis: [
            {
                type: 'category',
                data: notBorderedLables.data,
                position: 'bottom',
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    interval: 0,
                    rich: {
                        border: {
                            borderColor: 'red',
                            borderWidth: 1,
                            padding: [2, 2, 2, 2],
                        },
                    },

                },
            },
            {
                type: 'category',
                data: borderedLables.data,
                position: 'bottom',
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    customValues: borderedLables.customValues,
                    interval: 0,
                    textStyle: {
                        borderColor: 'red',
                        borderWidth: 1,
                        padding: [2, 2, 2, 2]
                      }
                },
            },
        ],
        yAxis: {
            type: 'value',

            max: maxYAxis,
            interval: maxYAxis / 4,
            axisLine: {
                lineStyle: {
                    color: 'black',
                },
            },
            splitLine: {
                lineStyle: {
                    color: 'black',
                },
            },
            axisLabel: {
                formatter: (value) => {
                    return value.toFixed();
                },
            },
        },
        series: [
            {
                data: graphValuesArray,
                type: 'bar',
            },
        ],
    };

    myChart.setOption(option);

    await new Promise((resolve) => setTimeout(resolve, 500));

    const dataUrl = myChart.getDataURL({
        type: 'png',
        pixelRatio: 1,
        backgroundColor: '#fff',
    });

    return {
        body: [
            [
                [
                    {
                        table: {
                            margin: [10, 10],
                            body: [
                                [
                                    {
                                        text: graph.title,
                                        border: titleBorder,
                                        borderColor: ['red', 'red', 'red', 'red'],
                                    },
                                ],
                            ],
                        },
                        margin: [10, 10],
                    },
                    {
                        columns: [
                            {
                                width: 'auto',
                                table: {
                                    body: [
                                        [
                                            {
                                                image: dataUrl,
                                                width: 750,
                                                height: 440,
                                                border: [false, false],
                                            },
                                        ],
                                    ],
                                    alignment: 'center',
                                },
                            },
                        ],
                    },
                ],
            ],
        ],
        widths: [763],
        heights: [490],
    };
}

function getGraphChannels(channelsIdsSet: Set<number>, projectChannels: Channel[]) {
    const graphChannels = [];
    projectChannels.forEach((channel) => {
        if (channelsIdsSet.has(channel.fcuData.fcuChannelId)) {
            graphChannels.push(channel);
        }
    });
    return graphChannels;
}

function prepareXAxislabel(channels: ExhaustChannel[], channelsDifferences) {
    let newChannelsIds = new Set();
    let updatedIds = new Set();
    let updatedTitles = new Set();
    let deletedChannelsLength = 0;

    let borderedLables = {
        data: [],
        customValues: []
    }

    let notBorderedLables = {
        data: [],
        customValues: []
    }

    if (channelsDifferences) {
        deletedChannelsLength = channelsDifferences.deleted.length;
        channelsDifferences.added.forEach((channel) => {
            newChannelsIds.add(channel.fcuChannelId);
        });
        channelsDifferences.updated.fcuId.forEach((channel) => {
            updatedIds.add(channel.fcuChannelId);
        });
        channelsDifferences.updated.title.forEach((channel) => {
            updatedTitles.add(channel.fcuChannelId);
        });
    }

    channels.forEach((channel: ExhaustChannel, index) => {
        if (newChannelsIds.has(channel.fcuChannelId)) {
            borderedLables.data.push(`*****\n${channel.title}\n${channel.fcuChannelId}`)
            borderedLables.customValues.push(index)
            notBorderedLables.data.push('')
        } else if (updatedIds.has(channel.fcuChannelId)) {
            notBorderedLables.data.push(`*****\n${channel.title}\n{border|${channel.fcuChannelId}}`)
            notBorderedLables.customValues.push(index)
            borderedLables.data.push(``)
        } else if (updatedTitles.has(channel.fcuChannelId)) {
            notBorderedLables.data.push(`*****\n{border|${channel.title}}\n${channel.fcuChannelId}`)
            notBorderedLables.customValues.push(index)
            borderedLables.data.push(``)
        } else {
            notBorderedLables.data.push(`*****\n${channel.title}\n${channel.fcuChannelId}`)
            notBorderedLables.customValues.push(index)
            borderedLables.data.push(``)
        }
    });

    for (let i  = 0; i < deletedChannelsLength; i++) {
        notBorderedLables.data.push('')
        borderedLables.data.push(`     \n     \n     ` )
        borderedLables.customValues.push(channels.length + i)
    }

    return { borderedLables, notBorderedLables};
}

function findMaxYAxis(graphChannels: Channel[]) {
    return Math.max(
        ...graphChannels.map((channel: Channel) => channel.fcuData.measurableRange.max),
    );
}

function prepareGraphValuesArray(graphChannels: Channel[]) {
    const channelsValueArray = [];
    graphChannels.map((channel: Channel) => {
        const randomNumber =
            Math.floor(Math.random() * channel.fcuData.measurableRange.max) +
            channel.fcuData.measurableRange.min;
        channelsValueArray.push(randomNumber);
    });
    return channelsValueArray;
}
