import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@service/notification.service';
import { OpsUsersService } from '@service/ops-users.service';
import { ProjectService } from '@service/project.service';
import { MAX_OPS_USERS, OpsUser } from 'common/models/ops-users';
import { getValidationErrors } from 'common/validators';
import { OpsUserValidator } from 'common/validators/ops-users/ops-users-validator';
import * as _ from 'lodash';

@Component({
    selector: 'app-ops-settings',
    templateUrl: './ops-settings.component.html',
    styleUrls: ['./ops-settings.component.scss'],
})
export class OpsSettingsComponent implements OnInit {
    isLoadingData: boolean = false;

    userList: OpsUser[];
    initialUserList: OpsUser[];

    constructor(
        private opsUsersService: OpsUsersService,
        private projectService: ProjectService,
        public dialogRef: MatDialogRef<OpsSettingsComponent>,
        private translateService: TranslateService,
        private notificationService: NotificationService,
    ) {}

    async ngOnInit() {
        this.isLoadingData = true;
        const projectId = this.projectService.getProjectId();
        this.userList = await this.opsUsersService.getUsers(projectId);
        this.initialUserList = _.cloneDeep(this.userList);
        if (this.userList.length === 0) {
            this.userList = this.opsUsersService.createUserList(projectId);
        }
        this.isLoadingData = false;
    }

    canAddUser(): boolean {
        return this.userList.length < MAX_OPS_USERS;
    }

    addUser() {
        const projectId = this.projectService.getProjectId();
        const newUser = new OpsUser(projectId);
        this.userList.push(newUser);
    }

    deleteUser(userId) {
        if (this.userList.length <= 2) {
            return;
        }
        for (let i = 0; i < this.userList.length; i++) {
            if (this.userList[i].id === userId) {
                this.userList.splice(i, 1);
            }
        }
    }

    exit() {
        this.dialogRef.close();
    }

    saveButtonIsDisabled(): boolean {
        if (_.isEqual(this.initialUserList, this.userList)) {
            return true;
        }

        let hasErrors = false;
        this.userList.map((user) => {
            const errors = getValidationErrors(OpsUserValidator, user);
            if (errors.length > 0) {
                hasErrors = true;
            }
        });

        return hasErrors;
    }

    async saveUsers() {
        if (!this.saveButtonIsDisabled()) {
            let usersToUpdate = [];
            const initialUserMap = new Map(this.initialUserList.map((user) => [user.id, user]));
            this.userList.forEach((updatedUser) => {
                const oldUser = initialUserMap.get(updatedUser.id);
                if (oldUser) {
                    if (!_.isEqual(updatedUser, oldUser)) {
                        usersToUpdate.push(updatedUser);
                    }
                } else {
                    usersToUpdate.push(updatedUser);
                }
                initialUserMap.delete(updatedUser.id);
            });

            const IdsToDelete = Array.from(initialUserMap.keys());

            try {
                if (usersToUpdate.length > 0) {
                    await this.opsUsersService.saveList(usersToUpdate);
                }
                if (IdsToDelete.length > 0) {
                    await this.opsUsersService.deleteUsers(IdsToDelete);
                }
                this.notificationService.opsUsersSavedSuccess();
            } catch (err) {
                console.error(err);
                this.notificationService.opsUsersSavedError();
            }
            this.initialUserList = _.cloneDeep(this.userList);
        }
    }
}
