import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { EndPointApi } from '@helpers/endpointapi';

@Injectable({
    providedIn: 'root',
})
export class ViewGroupsService {
    private endPointConfig: string = EndPointApi.getURL();
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    getAll(projectId: string): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/viewGroup`, {
                params: { projectId },
            }),
        );
    }

    create(groupName: string, projectId: string): Promise<any> {
        return lastValueFrom(
            this.http.post(`${this.domain}/api/viewGroup`, {
                headers: this.headers,
                params: { groupName: groupName, projectId: projectId },
            }),
        );
    }

    updateName(groupData): Promise<any> {
        return lastValueFrom(
            this.http.put(`${this.domain}/api/viewGroup/${groupData.id}`, {
                headers: this.headers,
                params: groupData.groupName,
            }),
        );
    }

    delete(groupId: string): Promise<any> {
        return lastValueFrom(this.http.delete(`${this.domain}/api/viewGroup/${groupId}`));
    }

    updateViewCategory(groupId, viewId) {
        return lastValueFrom(
            this.http.put(`${this.domain}/api/viewGroup/view/${viewId}`, {
                headers: this.headers,
                params: groupId,
            }),
        );
    }

    getViewsByProjectId(projectId: string): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/views`, {
                params: { projectId },
            }),
        );
    }
}
