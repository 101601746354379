import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OpsControlType } from '@models/request-base';
import { OpsRequestToFcuService } from '@service/ops-request-to-fcu.service';

@Component({
    selector: 'app-confirm-dialog-dialog',
    templateUrl: './control-confirm-dialog.component.html',
    styleUrls: ['./control-confirm-dialog.component.scss'],
})
export class ControlConfirmDialogComponent implements OnInit {
    message: string;
    confirmedContents: string;

    showOkButton: boolean;
    showCancelButton: boolean;

    controlData: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public controlConfirmDialogComponent: MatDialogRef<ControlConfirmDialogComponent>,
        private opsRequestToFcuService: OpsRequestToFcuService,
    ) {
        if (this.data.type == ConfirmDialogType.OkAndCancel) {
            this.message = `Are you sure you want to set data?`;
            this.confirmedContents = `Channel No: ${data.setData.sigId}  Value: ${data.setData.value}`;
            this.showOkButton = true;
            this.showCancelButton = true;
        }
        this.controlData = data;
    }

    ngOnInit(): void {}

    onOk(): any {
        // ToDo
        // Check if value user are trying to set is appropriate.(e.g. Is value within the range?)
        // If possible, checking service instance is attached in injected data when this dialog open.
        // If not appropriate, error message should be shown.
        this.opsRequestToFcuService.requestToFcu(
            this.controlData,
            OpsControlType.ChannelMultiControlRequest,
        );
        this.controlConfirmDialogComponent.close(true);
    }

    onCancel(): any {
        this.controlConfirmDialogComponent.close(false);
    }
}

export enum ConfirmDialogType {
    OkAndCancel = 'OkAndCancel',
}
