<div>
    <input
        [(ngModel)]="selectedChannel"
        [value]="selectedChannel"
        (input)="filterChannels($event)"
        placeholder="Channel Number"
        maxlength="8"
    />
    <div
        *ngIf="displayErrorContainer && selectedChannelErrors()"
        class="error-container text light"
    >
        <mat-icon svgIcon="error" class="error-icon"></mat-icon>
        <span data-testid="errorSetPoint">
            {{ selectedChannelErrors() | translate }}</span
        >
    </div>
</div>
