import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpsUser } from 'common/models/ops-users';
import { ValidationError, getValidationErrors, mapErrors } from 'common/validators';
import { OpsUserValidator } from 'common/validators/ops-users/ops-users-validator';

@Component({
    selector: 'app-ops-user',
    templateUrl: './ops-user.component.html',
    styleUrls: ['./ops-user.component.scss'],
})
export class OpsUserComponent implements OnInit {
    @Input() index: number;
    @Input() user: OpsUser;
    @Input() deleteButtonIsDisabled: boolean;
    @Output() onDeleteUser: EventEmitter<string> = new EventEmitter();

    validationErrors: { [propertyName: string]: ValidationError } = {};
    isChangingPassword: boolean = false;

    ngOnInit(): void {
        this.checkFormErrors();
    }

    checkFormErrors() {
        const errors = getValidationErrors(OpsUserValidator, this.user);
        if (errors) {
            this.validationErrors = mapErrors(errors);
        }
    }

    deleteUser() {
        this.onDeleteUser.emit(this.user.id);
    }

    openPasswordModal() {
        this.isChangingPassword = true;
    }

    savePassword(newPassword: string) {
        this.user.password = newPassword;
        this.isChangingPassword = false;
        this.checkFormErrors();
    }

    handleUpdatePermission(updatedPermission) {
        if (updatedPermission in this.user.permissions) {
            this.user.permissions[updatedPermission] = !this.user.permissions[updatedPermission];
        }
    }
}
