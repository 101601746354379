import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-channel-text-input',
    templateUrl: './channel-text-input.component.html',
    styleUrls: ['./channel-text-input.component.css'],
})
export class ChannelTextInputComponent {
    @Input() name: string;
    @Input() label: string;
    @Input() value: string;

    inputValue = '';

    @Output() inputChange = new EventEmitter();

    ngOnInit() {
        this.inputValue = this.value;
    }

    onChangeEmit(value: string) {
        this.inputChange.emit({ name: this.name, value: value });
    }
}
