import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { EndPointApi } from '@helpers/endpointapi';
import { SocketMiddlewareService } from './socket-middleware.service';
import { ReposeSetting } from 'common/models/repose-setting';
import { ReposeSettingsEvents } from 'icreate/common/io-event/websocket-message';

@Injectable({
    providedIn: 'root',
})
export class ReposeSettingsService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(
        private http: HttpClient,
        private socketService: SocketMiddlewareService,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    getAll(projectId: string): Promise<ReposeSetting[]> {
        return lastValueFrom(
            this.http.get<ReposeSetting[]>(`${this.domain}/api/reposeSettings`, {
                params: { projectId },
            }),
        );
    }
    
    create(projectId: string): Promise<ReposeSetting> {
        return lastValueFrom(
            this.http.post<ReposeSetting>(`${this.domain}/api/reposeSettings`, {
                headers: this.headers,
                params: { projectId }
            }),
            
        );
    }

    delete(reposeSettingId: string): Promise<any> {
        return lastValueFrom(
            this.http.delete<void>(`/api/reposeSettings/${reposeSettingId}`),
        );
    }

    update(reposeSetting: ReposeSetting): Promise<any> {
        return lastValueFrom(
            this.http.put<void>(`${this.domain}/api/reposeSettings/${reposeSetting.id}`, reposeSetting, {
                headers: this.headers,
            }),
        );
    }

    settingDeleted$() {
        return this.socketService.receiveMessageObserver(ReposeSettingsEvents.DELETE_SETTING);
    }

    settingCreated$() {
        return this.socketService.receiveMessageObserver(ReposeSettingsEvents.CREATE_SETTING);
    }

    settingUpdated$() {
        return this.socketService.receiveMessageObserver(ReposeSettingsEvents.UPDATE_SETTING);
    }


}
