import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import { ResourceStorageService } from './resource-storage.service';
import { EndPointApi } from '@helpers/endpointapi';
import { ServerSettings } from './project.service';

@Injectable()
export class ResWebApiService implements ResourceStorageService {
    private endPointConfig: string = EndPointApi.getURL();
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    getDemoProject(): Observable<any> {
        return this.http.get<any>('./assets/project.demo.fuxap', {});
    }

    getVersion(): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/buildNumber`, {
                headers: this.headers,
            }),
        );
    }

    setProjectBuilderAndVessel(builderData, vesselData, projectId) {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        const param = {
            id: projectId,
            builderData: builderData,
            vesselData: vesselData,
        };

        return lastValueFrom(
            this.http.post<any>(
                this.endPointConfig + '/api/projectvessel',
                // param,
                {
                    headers: header,
                    params: param,
                },
            ),
        );
    }
}
