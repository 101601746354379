<div class="dlg-container">
    <h1
        mat-dialog-title
        style="display: inline-block; cursor: move"
        mat-dialog-draggable
    >
        {{ 'dlg.setup-title' | translate }}
    </h1>
    <mat-icon (click)="onNoClick()" class="dialog-close-btn">clear</mat-icon>
    <div mat-dialog-content>
        <div class="separator">
            <div
                class="separator-line"
                style="position: absolute; left: 0px"
            ></div>
            <div class="separator-text">
                {{ 'dlg.setup-gui' | translate }}
            </div>
            <div
                class="separator-line"
                style="position: absolute; right: 0px"
            ></div>
        </div>
        <div class="btn-cards">
            <div class="btn-card">
                <button mat-button (click)="goTo('/editor')" class="card-btn">
                    <div class="card-btn-content">
                        <mat-icon>dashboard</mat-icon>
                        <span>{{ 'dlg.setup-views' | translate }}</span>
                    </div>
                </button>
            </div>
            <!-- <div class="btn-card">
                <button
                    mat-button
                    (click)="onChartConfig()"
                    class="card-btn"
                    [disabled]="true"
                >
                    <div class="card-btn-content">
                        <mat-icon>multiline_chart</mat-icon>
                        <span>{{ 'dlg.setup-line-charts' | translate }}</span>
                    </div>
                </button>
            </div>
            <div class="btn-card">
                <button mat-button (click)="onLayoutConfig()" class="card-btn">
                    <div class="card-btn-content">
                        <mat-icon>view_compact</mat-icon>
                        <span>{{ 'dlg.setup-layout' | translate }}</span>
                    </div>
                </button>
            </div>
            <div class="btn-card">
                <button
                    mat-button
                    (click)="onChartConfig()"
                    class="card-btn"
                    [disabled]="true"
                >
                    <div class="card-btn-content">
                        <mat-icon>insert_chart</mat-icon>
                        <span>{{ 'dlg.setup-bar-charts' | translate }}</span>
                    </div>
                </button>
            </div> -->
        </div>
        <div class="separator">
            <div
                class="separator-line"
                style="position: absolute; left: 0px"
            ></div>
            <div class="separator-text">
                {{ 'dlg.setup-diverse' | translate }}
            </div>
            <div
                class="separator-line"
                style="position: absolute; right: 0px"
            ></div>
        </div>
        <div class="btn-cards">
            <div class="btn-card">
                <button mat-button (click)="goTo('/device')"  class="card-btn">
                    <div class="card-btn-content">
                        <mat-icon>device_hub</mat-icon>
                        <span>{{'dlg.setup-connections' | translate}}</span>
                    </div>
                </button>
            </div>
            <div class="btn-card">
                <button
                    mat-button
                    (click)="goTo('/users')"
                    class="card-btn"
                    [disabled]="isToDisable('users')"
                >
                    <div class="card-btn-content">
                        <mat-icon>people</mat-icon>
                        <span>{{ 'dlg.setup-users' | translate }}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
