import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { projectNameValidation } from '@models/models-validators/project-name';
import { TranslateService } from '@ngx-translate/core';
import { getValidationErrors } from 'common/validators';
import { ProjectData } from 'icreate/common/models/project';
import { GenericErrorStrings } from 'icreate/common/validators/validation-errors/generic';

@Component({
    selector: 'app-dialog-text-input',
    templateUrl: './dialog-text-input.component.html',
    styleUrls: ['./dialog-text-input.component.css'],
})
export class DialogTextInputComponent {
    title: string = 'Text Input';
    guideText: string = 'Enter text below';
    hint: string = '';

    inputText: string;

    projectList: ProjectData[] = [];
    constructor(
        public dialogRef: MatDialogRef<DialogTextInputComponent>,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: DialogTextInputData,
    ) {
        if (data.title != null) {
            this.title = data.title;
        }
        if (data.guideText != null) {
            this.guideText = data.guideText;
        }
        if (data.hint != null) {
            this.hint = data.hint;
        }
        if (data.seedText != null) {
            this.inputText = data.seedText;
        }

        if (data.projectList) {
            this.projectList = data.projectList;
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    hasError() {
        return this.getErrorString() !== '';
    }

    getErrorString() {
        const error = getValidationErrors(projectNameValidation, this.inputText);
        if (error.length > 0) {
            return this.translateService.instant(error[0].message, error[0].params);
        } else {
            const eixst = this.projectNameIsExist();
            if (eixst) {
                return this.translateService.instant(eixst);
            } else {
                return '';
            }
        }
    }

    projectNameIsExist() {
        return this.projectList.find((project) => project.name == this.inputText)
            ? this.translateService.instant(GenericErrorStrings.CONFLICT, {
                  property: 'Project Name',
              })
            : '';
    }
}

class DialogTextInputData {
    title: string;
    guideText: string;
    hint: string;
    seedText: string;
    projectList: ProjectData[];
}
