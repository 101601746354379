import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ViewType } from 'common/models/view';

@Component({
    selector: 'app-dialog-new-view-group',
    templateUrl: './dialog-new-view-group.component.html',
    styleUrls: ['./dialog-new-view-group.component.css'],
})
export class DialogNewViewGroupComponent {
    docType = ViewType;
    constructor(
        public dialogRef: MatDialogRef<DialogNewViewGroupComponent>,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        Object.keys(this.docType).forEach((key) => {
            this.translateService.get(this.docType[key]).subscribe((txt: string) => {
                this.docType[key] = txt;
            });
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
