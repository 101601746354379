import { View, ViewGroup } from './view';

export interface viewCategories {
    type: viewsGroupsArrayTypes;
    name: string;
    data: ViewGroup | View;
    views: viewCategories[];
}

export enum viewsGroupsArrayTypes {
    container = 'container',
    view = 'view',
}
