<div class="wrapper">
    <h1 mat-dialog-title style="display:inline-block;cursor:move;">New Vessel Registration</h1>
    <form [formGroup]="reactiveForm">
        <div class="container">
          <div class="form">
            <label for="vesselnumber">Vessel Number (*Required)</label><br>
            <!-- <span *ngIf="ctrl('name').hasError('dirtyAndNull')">*Required</span> -->
            <!-- <span *ngIf="ctrl('name').hasError('required') && ctrl('name').touched">*Required</span> -->
            <input type="text" id="vesselnumber" formControlName="vesselnumber">
          </div>
          <div class="form">
            <label for="name">Vessel Name</label><br>
            <input type="text" id="name" formControlName="name">  
          </div>
          <div class="form">
            <label for="firstclass">Class</label><br>
            <input type="text" id="firstclass" formControlName="firstclass">  
          </div>
        </div>
        <div class="register-button">
          <button (click)="onRegister()" [disabled]="reactiveForm.invalid">Register</button>
        </div>
        <div class="cancel-button">
          <button (click)="onCancel()">Cancel</button>
        </div>
      </form>
</div>

