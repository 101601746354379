import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Location } from '@angular/common';
import { DEVICE_READONLY } from '@models/hmi';
import { DeviceService } from '@service/device.service';
import { ICreateHmiService } from '@service/hmi.service';
import { LocalStorageService } from '@service/local-storage.service';
import { NavigationService } from '@service/navigation.service';
import { ProjectService, SaveMode } from '@service/project.service';
import { SocketService } from '@service/socket.service';
import { UrlParserService } from '@service/url-parser.service';
import { Device } from 'icreate/common/models/device';
import { ProjectData } from 'icreate/common/models/project';
import { DeviceListComponent } from './device-list/device-list.component';
import { DeviceMapComponent } from './device-map/device-map.component';

@Component({
    selector: 'app-device',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.css'],
})
export class DeviceComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('devicelist') deviceList: DeviceListComponent;
    @ViewChild('devicemap') deviceMap: DeviceMapComponent;

    private subscriptionLoad: Subscription;
    private subscriptionDeviceChange: Subscription;
    private subscriptionVariableChange: Subscription;
    private subscriptionSave: Subscription;
    private askStatusTimer;
    showMode: string = 'map';
    readonly = false;
    reloadActive = false;
    currentProjectId: string;
    currentProjectData = new ProjectData();

    constructor(
        private router: Router,
        private projectService: ProjectService,
        private hmiService: ICreateHmiService,
        private deviceService: DeviceService,
        private location: Location,
        private navigation: NavigationService,
        private urlParserService: UrlParserService,
        private socketService: SocketService,
    ) {
        if (this.router.url.indexOf(DEVICE_READONLY) >= 0) {
            this.readonly = true;
        }

        this.currentProjectId = this.getProjectId();
    }

    ngOnInit() {
        this.socketService.deviceLoad(this.currentProjectId);
        this.subscriptionLoad = this.projectService.onLoadHmi.subscribe((projectId) => {
            this.currentProjectId = projectId;
            this.deviceMap.loadCurrentProject(projectId);
            this.deviceList.mapTags();
        });
        this.subscriptionDeviceChange = this.hmiService.onDeviceChanged.subscribe((event) => {
            this.deviceMap.setDeviceStatus(event);
        });
        this.subscriptionVariableChange = this.hmiService.onVariableChanged.subscribe((event) => {
            this.deviceList.updateDeviceValue();
        });
        this.subscriptionSave = this.projectService.onSaveCurrent.subscribe((mode: SaveMode) => {
            if (mode === SaveMode.SaveAs) {
                this.projectService.saveAs(this.currentProjectData);
            } else if (mode === SaveMode.Save) {
                this.projectService.save(this.currentProjectData);
            }
        });
        this.askStatusTimer = setInterval(() => {
            this.hmiService.askDeviceStatus();
        }, 10000);
        this.hmiService.askDeviceStatus();
    }

    async ngAfterViewInit() {
        this.showMode = 'map';
        this.currentProjectData = await this.projectService.getProjectData(this.currentProjectId);
    }

    ngOnDestroy() {
        // this.checkToSave();
        try {
            if (this.subscriptionLoad) {
                this.subscriptionLoad.unsubscribe();
            }
            if (this.subscriptionDeviceChange) {
                this.subscriptionDeviceChange.unsubscribe();
            }
            if (this.subscriptionVariableChange) {
                this.subscriptionVariableChange.unsubscribe();
            }
            if (this.subscriptionSave) {
                this.subscriptionSave.unsubscribe();
            }
        } catch (e) {}
        try {
            clearInterval(this.askStatusTimer);
            this.askStatusTimer = null;
        } catch {}
    }

    show(mode: string) {
        // this.checkToSave();
        this.showMode = mode;
        if (this.showMode === 'tags') {
            this.deviceList.updateDeviceValue();
            try {
                if (Object.values(this.deviceMap.devicesValue()).length > 0) {
                    this.deviceList.setSelectedDevice(this.deviceMap.devicesValue()[0]);
                }
            } catch (e) {}
        }
    }

    gotoMap() {
        this.show('map');
    }

    gotoList(device: Device) {
        this.show('tags');
        this.deviceList.setSelectedDevice(device);
    }

    addItem() {
        this.deviceService.setNewDevice(true);
        this.deviceMap.addDevice();
    }

    async onReload() {
        this.reloadActive = true;
        this.projectService.reload(this.currentProjectId);
        setTimeout(() => {
            this.reloadActive = false;
        }, 1000);
    }

    onEditor() {
        this.navigation.toEditor(this.currentProjectId);
    }

    getProjectId() {
        let segments = this.urlParserService.getSegment(this.location.path());
        return segments[1].path;
    }
}
