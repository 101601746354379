import { EventEmitter, Injectable } from '@angular/core';

import { CanvasService } from './canvas.service';
import { CanvasInputModeService, CanvasInputMode } from './canvas-input-mode.service';
import { SelectedElementsService } from './selected-elements.service';

import { PasteService } from './paste.service';
import { SvgEditExtension, SvgEditExtensionObject } from '@models/svg-edit-extension';
import { SvgComponentSerializationService } from '@service/svg/svg-component-serialization.service';
import { SvgUtilsService } from '@service/svg/svg-utils.service';
import { SvgTransformService } from '@service/svg/svg-transform.service';
import { TemplateService } from '@service/template.service';
import { EditorTemplate } from 'icreate/common/models/editor-templates';

@Injectable()
export class TemplateInsertService implements SvgEditExtension {
    name: string = 'template_insert';

    private _onTemplateInserted = new EventEmitter<SVGGraphicsElement[]>();

    constructor(private plugin: TemplateInsertPlugin) {
        this.init = this.init.bind(this);
    }

    init(): SvgEditExtensionObject {
        return this.plugin;
    }

    onTemplateInserted() {
        return this.plugin.onTemplateInserted();
    }

    setTemplate(template: EditorTemplate) {
        this.plugin.setSelectedTemplate(template);
    }

    getSelectedTemplate() {
        return this.plugin.getSelectedTemplate();
    }
}

@Injectable()
export class TemplateInsertPlugin implements SvgEditExtensionObject {
    private selectedTemplate: EditorTemplate;
    private _onTemplateInserted = new EventEmitter<SVGGraphicsElement[]>();

    constructor(
        private canvasService: CanvasService,
        private selection: SelectedElementsService,
        private svgSerialization: SvgComponentSerializationService,
        private inputMode: CanvasInputModeService,
        private svgUtils: SvgUtilsService,
        private templateService: TemplateService,
        private transform: SvgTransformService,
        private pasteService: PasteService,
    ) {}

    onTemplateInserted() {
        return this._onTemplateInserted;
    }

    private emitTemplateInserted(elements: SVGGraphicsElement[]) {
        this._onTemplateInserted.emit(elements);
    }

    setSelectedTemplate(template: EditorTemplate) {
        this.selectedTemplate = template;
    }

    getSelectedTemplate() {
        return this.selectedTemplate;
    }

    callback(deps: any) {}

    mouseDown(event) {
        if (this.selectedTemplate && this.inputMode.getMode() === CanvasInputMode.TEMPLATE_INSERT) {
            const canvas = this.canvasService.getCanvas();
            const templateElements = this.svgSerialization.deserialize(
                this.selectedTemplate.serializedComponents,
            );
            const svgCanvasSerializations = templateElements.map((x) =>
                canvas.getJsonFromSvgElements(x),
            );
            const createdElements = this.pasteService.paste(svgCanvasSerializations);

            //Move the created elements to the center of the mouse
            const translate = this.calculateTemplateInsertPoint(
                createdElements,
                event.start_x,
                event.start_y,
            );

            for (let i = 0; i < createdElements.length; i++) {
                this.transform.shift(createdElements[i], translate.x, translate.y);
            }

            this.selection.setSelectedElements(createdElements);
            this.inputMode.setMode(CanvasInputMode.SELECT);

            this.emitTemplateInserted(createdElements);
        }
    }

    calculateTemplateInsertPoint(createdElements: SVGGraphicsElement[], clickX, clickY) {
        // const canvasClickPosition = this.canvasService.toCanvasCoordinates(
        //     clickX,
        //     clickY,
        // );
        const canvasClickPosition = {
            x: clickX,
            y: clickY,
        };
        const boundingBox = this.svgUtils.calculateBoundingBox(createdElements);
        const translateX = Math.max(canvasClickPosition.x - Math.floor(boundingBox.width / 2), 0);
        const translateY = Math.max(canvasClickPosition.y - Math.floor(boundingBox.height / 2), 0);

        return {
            x: translateX,
            y: translateY,
        };
    }
}
