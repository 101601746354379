import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { EndPointApi } from '@helpers/endpointapi';
import { BarGraph, ExhaustGraph, GaugeGraph, Graph } from 'common/models/graphs';

@Injectable({
    providedIn: 'root',
})
export class GraphService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    create(graph: Graph): Promise<any> {
        return lastValueFrom(
            this.http.post(`${this.domain}/api/graphs`, graph, {
                headers: this.headers,
            }),
        );
    }

    delete(graphId: string): Promise<any> {
        return lastValueFrom(
            this.http.delete(`/api/graphs/${graphId}`, {
                responseType: 'text',
            }),
        );
    }

    update(graph: Graph): Promise<any> {
        return lastValueFrom(
            this.http.put(`${this.domain}/api/graphs`, graph, {
                headers: this.headers,
            }),
        );
    }

    clone(graph: Graph, refrenceId: string): Promise<any> {
        const body = {
            graph,
            refrenceId,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/graphs/clone`, body, {
                headers: this.headers,
            }),
        );
    }

    getAll(projectId: string): Promise<Graph[]> {
        return lastValueFrom(
            this.http.get<Graph[]>(`${this.domain}/api/graphs`, {
                params: { projectId },
            }),
        );
    }

    getGraph(graphId: string, graphType: string, lockGraph: boolean = false): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/graph`, {
                params: { graphId, graphType, lockGraph },
            }),
        );
    }

    sendUnlockBeacon(graphId: string) {
        navigator.sendBeacon(`${this.domain}/api/graph/unlock?graphId=${graphId}`)
    }

    sendUnloadGraphListBeacon(projectId: string) {
        navigator.sendBeacon(`${this.domain}/api/graph/unloadList?projectId=${projectId}`)
    }

    unlockGraph(graphId: string): Promise<any> {
        return lastValueFrom(
            this.http.put(
                `${this.domain}/api/graph/unlock`,
                { graphId },
                {
                    headers: this.headers,
                },
            ),
        );
    }

    saveBarGraph(barGraph: BarGraph, graphListId: string): Promise<any> {
        const body = {
            barGraph,
            graphListId,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/graphs/barGraph`, body, {
                headers: this.headers,
            }),
        );
    }

    saveExhaustGraph(exhaustGraph: ExhaustGraph, graphListId: string): Promise<any> {
        const body = {
            exhaustGraph,
            graphListId,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/graphs/exhaustGraph`, body, {
                headers: this.headers,
            }),
        );
    }

    saveGaugeGraph(gaugeGraph: GaugeGraph, graphListId: string): Promise<any> {
        const body = {
            gaugeGraph,
            graphListId,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/graphs/gaugeGraph`, body, {
                headers: this.headers,
            }),
        );
    }

    public getGraphsDiff(
        projectId: string,
        vesselId: string,
        builderId: string,
        version: string,
    ): Promise<any> {
        const param = {
            projectId,
            vesselId,
            builderId,
            version,
        };

        return lastValueFrom(
            this.http.get<any>(`${this.domain}/api/graphs/compare`, {
                params: param,
            }),
        );
    }
}
