<div #overlay class="confirm-overlay" [ngStyle]="style">
    <div class="confirm-content center-content">
        <div class="center-content column-flex">
            <h2>
                {{ mainLabel }}<br />
                {{ subLabel }}
            </h2>
            <div class="button-container">
                <button
                    class="component-large stay"
                    (click)="emitValue(false)"
                    *ngIf="showCancel"
                >
                    {{ cancelLabel }}
                </button>
                <button class="component-large leave" (click)="emitValue(true)">
                    {{ confirmLabel }}
                </button>
            </div>
        </div>
    </div>
</div>
