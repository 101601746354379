import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';
import { Observable, lastValueFrom } from 'rxjs';
import { ProjectData } from 'icreate/common/models/project';

@Injectable({ providedIn: 'root' })
export class ProjectApiService {
    private endpoint: string = EndPointApi.getURL();
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private http: HttpClient) {}

    getProjectList(): Observable<Array<ProjectData>> {
        return this.http.get<ProjectData[]>(this.endpoint + '/api/projectlist');
    }

    getProject(projectId: string): Observable<any> {
        const params = new HttpParams().set('projectid', projectId);
        return this.http.get<ProjectData>(this.endpoint + '/api/project', {
            params: params,
        });
    }

    getProjectData(projectId: string): Promise<ProjectData> {
        const params = new HttpParams().set('projectid', projectId);

        return lastValueFrom(
            this.http.get<ProjectData>(this.endpoint + '/api/project', {
                params: params,
            }),
        );
    }

    setServerProject(prj: ProjectData): Observable<any> {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = new HttpParams().set('projectid', prj.id);
        return this.http.post<ProjectData>(this.endpoint + '/api/project', prj, {
            headers: header,
            params: params,
        });
    }

    saveView(projectId: string, data: any): Promise<any> {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        const requestBody = {
            projectId: projectId,
            data: data,
        };
        return lastValueFrom(
            this.http.post(this.endpoint + '/api/view', requestBody, {
                headers: header,
            }),
        );
    }

    removeView(projectId: string, data: any): Promise<any> {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        const requestBody = {
            projectId: projectId,
            data: data,
        };
        return lastValueFrom(
            this.http.delete(this.endpoint + '/api/view', {
                headers: header,
                body: requestBody,
            }),
        );
    }

    saveDevice(projectId: string, data: any): Promise<any> {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        const requestBody = {
            projectId: projectId,
            data: data,
        };
        return lastValueFrom(
            this.http.post(this.endpoint + '/api/device', requestBody, {
                headers: header,
            }),
        );
    }

    removeDevice(projectId: string, data: any): Promise<any> {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        const requestBody = {
            projectId: projectId,
            data: data,
        };
        return lastValueFrom(
            this.http.delete(this.endpoint + '/api/device', {
                headers: header,
                body: requestBody,
            }),
        );
    }

    setServerProjectParam(param): Promise<any> {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        return lastValueFrom(
            this.http.post(this.endpoint + '/api/projectparam', param, {
                headers: header,
            }),
        );
    }

    cloneProject(param): Observable<any> {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = new HttpParams()
            .set('id', param.projectId)
            .set('updatedBy', param.updatedBy)
            .set('newName', param.newName)
            .set('view', param.view);
        return this.http.post<any>(this.endpoint + '/api/cloneproject', param, {
            headers: header,
            params: params,
        });
    }

    deleteProject(param): Observable<any> {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = new HttpParams().set('id', param.projectId);
        return this.http.delete<any>(this.endpoint + '/api/deleteproject', {
            headers: header,
            params: params,
        });
    }

    setServerNewProjectName(param) {
        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = new HttpParams()
            .set('id', param.id)
            .set('name', param.name)
            .set('updatedBy', param.updatedBy);
        return this.http.post<any>(this.endpoint + '/api/projectrename', param, {
            headers: header,
            params: params,
        });
    }

    createNewProject(projectData: ProjectData) {
        return this.http.post<ProjectData>(`${this.endpoint}/api/new-project`, projectData);
    }
}
