import {
    Component,
    OnInit,
    AfterViewInit,
    Output,
    EventEmitter,
    ElementRef,
    Input,
    OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DevicePropertyComponent } from './../device-property/device-property.component';
import { Utils } from '@helpers/utils';
import { DEVICE_PREFIX, DeviceNetProperty } from '@models/device';
import { DeviceType } from '@models/hmi';
import { AppService } from '@service/app.service';
import { DeviceService } from '@service/device.service';
import { ICreateHmiService } from '@service/hmi.service';
import { ProjectService } from '@service/project.service';
import { Device } from 'icreate/common/models/device';
import { Location } from '@angular/common';
import { UrlParserService } from '@service/url-parser.service';
import { ProjectData } from 'icreate/common/models/project';

@Component({
    selector: 'app-device-map',
    templateUrl: './device-map.component.html',
    styleUrls: ['./device-map.component.scss'],
})
export class DeviceMapComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() goto: EventEmitter<Device> = new EventEmitter();
    @Input() readonly = false;
    @Input() mode: string;
    @Input() currentProjectId: string;
    currentProjectData: ProjectData = new ProjectData();

    deviceBorder = 5;
    deviceWidth = 160;
    deviceHeight = 90;
    deviceLineHeight = 60;

    lineFlowSize = 6;
    lineFlowHeight = 60;
    lineDeviceSize = 6;
    mainDeviceLineHeight = 60;
    mainWidth = 160;
    mainHeight = 90;
    mainBorder = 5;

    server;
    devices = {};
    plugins = [];

    devicesStatus = {};
    dirty: boolean = false;
    domArea: any;

    constructor(
        private dialog: MatDialog,
        private elementRef: ElementRef,
        private appService: AppService,
        private projectService: ProjectService,
        private deviceService: DeviceService,
    ) {
        this.domArea = this.elementRef.nativeElement.parent;
    }

    async ngOnInit() {
        await this.loadCurrentProject(this.currentProjectId);
        this.loadAvailableType();
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}

    onEditDevice(device: Device) {
        this.deviceService.setNewDevice(false);
        this.editDevice(device, false);
    }

    async loadCurrentProject(projectId: string) {
        // take the copy of devices to save by leave
        this.currentProjectData = await this.projectService.getProjectData(projectId);

        if (this.currentProjectData && this.currentProjectData.amsConfig[0]) {
            this.server = this.currentProjectData.amsConfig[0].server;
        }
        if (this.currentProjectData && this.currentProjectData.amsConfig[1]) {
            this.devices = this.currentProjectData.amsConfig[1].device;
        }
    }

    loadAvailableType() {
        // define available device type (plugins)
        this.plugins = [];
        if (!this.appService.isClientApp && !this.appService.isDemoApp) {
            this.plugins.push(DeviceType.AmsInterface);
        }
    }

    addDevice() {
        const device = new Device(Utils.getGUID(DEVICE_PREFIX));
        device.property = [new DeviceNetProperty(), new DeviceNetProperty()];
        device.enabled = false;
        device.tags = {};
        this.editDevice(device, false);
    }

    onRemoveDevice(device: Device) {
        this.editDevice(device, true);
    }

    removeDevice(device: Device) {
        delete this.devices[device.id];
    }

    private getWindowWidth() {
        let result = window.innerWidth;
        if (
            this.appService.isClientApp &&
            this.elementRef.nativeElement &&
            this.elementRef.nativeElement.parentElement
        ) {
            result = this.elementRef.nativeElement.parentElement.clientWidth;
        }
        if (this.devices) {
            if (result < (this.plcs().length + 2) * this.deviceWidth) {
                result = (this.plcs().length + 2) * this.deviceWidth;
            }
        }
        return result;
    }

    private getHorizontalCenter() {
        return this.getWindowWidth() / 2;
    }

    private getVerticalCenter() {
        return (window.innerHeight / 5) * 2;
    }

    getMainLeftPosition() {
        return this.getHorizontalCenter() - this.mainWidth / 2;
    }

    getMainTopPosition() {
        return this.getVerticalCenter() - this.mainHeight / 2;
    }

    getMainLineLeftPosition() {
        return this.getHorizontalCenter() - 1 + this.lineDeviceSize / 2;
    }

    getMainLineTopPosition(type = null) {
        if (type === 'flow') {
            return (
                this.getVerticalCenter() +
                this.mainBorder -
                (this.lineFlowHeight + this.mainHeight / 2)
            );
        }
        return this.getVerticalCenter() + this.mainBorder + this.mainHeight / 2;
    }

    getMainLineHeight() {
        if (this.devices) {
            if (this.plcs().length) {
                return this.mainDeviceLineHeight;
            }
        }
        return 0;
    }

    getDeviceLeftPosition(index: number) {
        if (this.devices) {
            if (this.plcs().length) {
                const pos = index + 1;
                const centerd = this.plcs().length + 1;
                const result = ((this.getWindowWidth() - this.deviceWidth) / centerd) * pos;
                return result;
            }
        }
        return 0;
    }

    getDeviceTopPosition() {
        if (!this.server) {
            let pos = this.elementRef.nativeElement.parentElement.clientHeight / 2;
            if (pos < 200) {
                pos = 200;
            }
            pos += this.mainHeight / 2;
            return pos;
        } else {
            return (
                this.getVerticalCenter() +
                (this.mainHeight / 2 + this.deviceLineHeight + this.mainDeviceLineHeight)
            );
        }
    }

    getDeviceLineLeftPosition(index: number) {
        if (this.devices) {
            if (this.plcs().length) {
                const pos = index + 1;
                const centerd = this.plcs().length + 1;
                let result = ((this.getWindowWidth() - this.deviceWidth) / centerd) * pos;
                result += this.deviceBorder + this.deviceWidth / 2 - this.lineDeviceSize / 2;
                return result;
            }
        }
        return 0;
    }

    getDeviceLineTopPosition() {
        return this.getDeviceTopPosition() - this.deviceLineHeight;
    }

    getDeviceConnectionLeftPosition() {
        const centerd = this.plcs().length + 1;
        let result = ((this.getWindowWidth() - this.deviceWidth) / centerd) * 1;
        result += this.deviceBorder + (this.deviceWidth - this.lineDeviceSize) / 2;
        return result;
    }

    getDeviceConnectionTopPosition() {
        return this.getDeviceLineTopPosition();
    }

    getDeviceConnectionWidth() {
        if (this.devices) {
            const count = this.plcs().length;
            if (count) {
                const centerd = this.plcs().length + 1;
                const result =
                    ((this.getWindowWidth() - this.deviceWidth) / centerd) * count -
                    ((this.getWindowWidth() - this.deviceWidth) / centerd) * 1;
                return result;
            }
        }
        return 0;
    }

    devicesValue(): Array<Device> {
        if (this.devices) {
            if (this.plcs().length) {
                const result: Device[] = this.plcs();
                return result.sort((a, b) => (a.name > b.name ? 1 : -1));
            }
        }
        return [];
    }

    onListDevice(device) {
        this.goto.emit(device);
        this.projectService.reload(this.currentProjectId);
    }

    getDevicePropertyToShow(device) {
        let result = '';
        return result;
    }

    getDeviceStatusColor(device) {
        if (this.devicesStatus[device.id]) {
            const milli = new Date().getTime();
            if (this.devicesStatus[device.id].last + 15000 < milli) {
                this.devicesStatus[device.id].status = 'connect-error';
                this.devicesStatus[device.id].last = new Date().getTime();
            }
            const st = this.devicesStatus[device.id].status;
            if (st === 'connect-ok') {
                return '#00b050';
            } else if (st === 'connect-error' || st === 'connect-failed') {
                return '#ff2d2d';
            } else if (st === 'connect-off' || st === 'connect-busy') {
                return '#ffc000';
            }
        }
    }

    getNodeClass(device: Device) {
        return 'node-device';
    }

    setDeviceStatus(event) {
        this.devicesStatus[event.id] = {
            status: event.status,
            last: new Date().getTime(),
        };
    }

    async editDevice(device: Device, toremove: boolean) {
        const exist = Object.values(this.devices)
            .filter((d: Device) => d.id !== device.id)
            .map((d: Device) => {
                return d.name;
            });
        exist.push('server');

        const tempdevice = JSON.parse(JSON.stringify(device));

        const tempdeviceAMS = JSON.parse(JSON.stringify(this.currentProjectData.amsConfig));
        const dialogRef = this.dialog.open(DevicePropertyComponent, {
            panelClass: 'dialog-property',
            data: {
                projectId: this.currentProjectId,
                projectData: this.currentProjectData,
                device: tempdevice,
                remove: toremove,
                exist: exist,
                availableType: this.plugins,
                projectService: this.projectService,
                amsConfig: tempdeviceAMS,
            },
            position: { top: '60px' },
        });

        dialogRef.afterClosed().subscribe(async (result: Device) => {
            if (result) {
                this.dirty = true;
                try {
                    if (toremove) {
                        this.removeDevice(device);

                        await this.projectService.removeDevice(this.currentProjectId, device);
                    } else {
                        await this.projectService.setDevice(this.currentProjectId, result);
                    }
                    this.loadCurrentProject(this.currentProjectId);
                } catch (error) {
                    this.projectService.notifySaveError(error);
                }
            }
        });
    }

    plcs(): Device[] {
        return Object.values(this.devices);
    }
}
