import { ObjectSchema, boolean, mixed, number, object, string } from 'yup';
import { locale } from './../yup-locale';
import * as yup from 'yup';
yup.setLocale(locale);

export const PublishRequestValidator: ObjectSchema<any> = object({
    builderId: string().required().nonNullable().strict(),
    vesselId: string().required().nonNullable().strict(),
    projectId: string().required().nonNullable(),
    approverEmail: string().email().required().nonNullable().strict(),
    publishReason: string().required().nonNullable().strict(),
})
    .strict()
    .noUnknown();
