import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';

import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SocketMiddleware extends Socket {
    constructor() {
        super({ url: EndPointApi.getURL(), options: {} });
    }
}

@Injectable({
    providedIn: 'root',
})
export class SocketMiddlewareService {
    constructor(private socket: SocketMiddleware) {}

    // Method to send messages
    sendMessage(eventName: string, ..._args: any[]): void {
        this.socket.emit(eventName, ..._args);
    }

    // Method to receive messages wrapped in an Observer
    receiveMessageObserver(socketSignalName): Observable<any> {
        return new Observable((observer) => {
            const socketEventCallback = (data) => {
                observer.next(data);
            };
            this.socket.on(socketSignalName, socketEventCallback);
            return this._createCleanupFunction(socketSignalName, socketEventCallback);
        });
    }

    getSocket(): Socket {
        return this.socket;
    }

    _createCleanupFunction(evtName, evtFunction): () => void {
        return () => this.socket.removeListener(evtName, evtFunction);
    }
}
