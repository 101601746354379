import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ResourceStorageService } from './resource-storage.service';
import { ProjectData, ProjectDataCmdType } from 'icreate/common/models/project';

@Injectable()
export class ResDemoService implements ResourceStorageService {
    private _projectId = 'default';

    get projectId(): string {
        return this._projectId;
    }

    set projectId(val: string) {
        this._projectId = val;
    }

    public onRefreshProject: () => boolean;

    constructor(private http: HttpClient) {}

    init(): boolean {
        return true;
    }

    getProjectList(): Observable<Array<Object>> {
        return of([
            {
                name: 'Demo',
                id: '00000000',
                lastEdited: '2022-04-18T07:03:48Z',
                createdBy: 'N/A',
                updatedBy: 'N/A',
            },
        ]);
    }

    getDemoProject(): Observable<any> {
        return this.http.get<any>('./assets/project.demo.fuxap', {});
    }

    getStorageProject(): Observable<any> {
        return new Observable((observer) => {
            const prj = localStorage.getItem(this.getAppId());
            if (prj) {
                observer.next(JSON.parse(prj));
            } else {
                // try root path
                this.getDemoProject().subscribe(
                    (demo) => {
                        observer.next(demo);
                    },
                    (err) => {
                        observer.error(err);
                    },
                );
            }
        });
    }

    setServerProject(prj: ProjectData) {
        return new Observable((observer) => {
            localStorage.setItem(this.getAppId(), JSON.stringify(prj));
            observer.next();
        });
    }

    getDeviceSecurity(id: string): Observable<any> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    setDeviceSecurity(name: string, value: string): Observable<any> {
        return new Observable((observer) => {
            observer.next('Not supported!');
        });
    }

    getAppId() {
        return ResourceStorageService.prjresource;
    }
}
