<div
   
    [ngStyle]="{
        top: comment.positionTop,
        left: comment.positionLeft,
        position: 'absolute',
    }"
    class="main"
    #commentContainer
    (mouseenter)="mouseEnter()"
    (mouseleave)="mouseLeave()"
>
    <div *ngIf="!showMessage; else displayMessage" class="flex-container">
        <div class="speech-bubble">
            <div class="bubble-circle">
                <div class="bubble-text">
                    {{ nameInitials }}
                </div>
            </div>
        </div>
        <div *ngIf="showPostIt">
            <div class="post-it">
                <div class="close-icon">
                    <mat-icon (click)="closePostIt()">clear</mat-icon>
                </div>
                <div class="comment-box" *ngIf="!editingComment; else editBox">
                    <div class="comment-title">
                        <div class="bubble-circle">
                            <div class="bubble-text">
                                {{ nameInitials }}
                            </div>
                        </div>
                        <div class="comment-author">
                            {{ comment.userName }}
                        </div>
                        <div class="comment-time">
                            {{ timeFromNow }}
                        </div>
                        <div class="icons">
                            <mat-icon
                                (click)="editComment()"
                                [ngStyle]="{ color: 'white' }"
                                >edit</mat-icon
                            >
                            <mat-icon
                                (click)="deleteComment()"
                                [ngStyle]="{ color: 'white' }"
                                >delete_forever</mat-icon
                            >
                        </div>
                    </div>
                    <div class="comment-text">
                        {{ comment.commentText }}
                    </div>
                </div>
                <ng-template #editBox>
                    <div class="edit-container">
                        <div class="bubble-circle">
                            <div class="bubble-text">
                                {{ nameInitials }}
                            </div>
                        </div>
                        <div class="textarea-container">
                            <textarea
                                class="textarea"
                                cdkTextareaAutosize
                                [(ngModel)]="comment.commentText"
                            ></textarea>
                            <div class="edit-buttons">
                                <button
                                    mat-flat-button
                                    color="warn"
                                    (click)="cancelEdit()"
                                >
                                    Cancel
                                </button>
                                <button
                                    mat-flat-button
                                    color="primary"
                                    (click)="saveChanges()"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <ng-template #displayMessage>
        <div class="flex-container" (click)="openPostIt()">
            <div class="speech-bubble-open">
                <div class="bubble-circle-open">
                    <div class="bubble-text-open">
                        {{ nameInitials }}
                    </div>
                </div>
                <div class="comment-box">
                    <div class="comment-title">
                        <div class="comment-author">
                            {{ comment.userName }}
                        </div>
                        <div class="comment-time">
                            {{ timeFromNow }}
                        </div>
                    </div>
                    <div class="comment-text">
                        {{ comment.commentText }}
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
