import { Injectable } from '@angular/core';
import { Device } from 'icreate/common/models/device';
import { ProjectData } from 'icreate/common/models/project';
import { Observable } from 'rxjs';

@Injectable()
export abstract class ResourceStorageService {
    public static prjresource = 'prj-data';

    public static defileProject(source: ProjectData): ProjectData {
        if (!source) return source;
        const destination = JSON.parse(JSON.stringify(source));
        const devices = {};
        for (let i = 0; i < destination.devices.length; i++) {
            const tags = {};
            for (let x = 0; x < destination.devices[i].tags.length; x++) {
                tags[destination.devices[i].tags[x].id] = destination.devices[i].tags[x];
            }
            destination.devices[i].tags = tags;
            devices[destination.devices[i].id] = destination.devices[i];
        }
        destination.devices = devices;
        return destination;
    }

    public static sanitizeProject(source: ProjectData): ProjectData {
        const destination = JSON.parse(JSON.stringify(source));
        destination.devices = Object.values(destination.devices);
        for (let i = 0; i < destination.devices.length; i++) {
            destination.devices[i].tags = Object.values(destination.devices[i].tags);
            for (let x = 0; x < destination.devices[i].tags.length; x++) {
                delete destination.devices[i].tags[x].value;
            }
        }
        return destination;
    }

    public static sanitizeDevice(source: Device) {
        const destination = JSON.parse(JSON.stringify(source));
        destination.tags = Object.values(destination.tags);
        return destination;
    }
}
