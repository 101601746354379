import * as uuid from 'uuid';
import { Channel } from './channel';

export class Graph {
    id: string = uuid.v4();
    type: GraphType = GraphType.Bar;
    title: string = '';
    projectId: string;
    locked?: boolean = false;
}

export class BarGraph {
    id: string = uuid.v4();
    displayType: BarDisplayType = BarDisplayType.Range;
    divisions: number = 6;
    channels: BarGraphChannel[] = [];
    graphsId?: string;
}

export class GaugeGraph {
    id: string = uuid.v4();
    channels: GaugeGraphChannel[] = [];
    graphsId?: string;
}

export class ExhaustGraph {
    id: string = uuid.v4();
    deviationLimit: number = undefined;
    averageChannel: number = undefined;
    turboChargerTitle: string = '';
    turboChargerChannels: TurboChargerChannel[] = [];
    exhaustChannels: ExhaustChannel[] = [];
    graphsId?: string;
}

export class GaugeGraphChannel {
    fcuChannelId: number = undefined;
    divisions: number = undefined;
}

export class BarGraphChannel {
    fcuChannelId: number = undefined;
    title: string = '';
}

export class TurboChargerChannel {
    fcuChannelId: number = undefined;
    title: string = '';
}

export class ExhaustChannel {
    fcuChannelId: number = undefined;
    deviationChannel: number = undefined;
    title: string = '';
}

export enum BarDisplayType {
    undefined = 'undefined',
    Percentage = 'percentage',
    Range = 'range',
}

export enum GraphType {
    undefined = 'undefined',
    Gauge = 'ANALOG_METER',
    Bar = 'BAR_GRAPH',
    Exhaust = 'EXHAUST_GAS',
}

export const MAX_GRAPHS = 64;
