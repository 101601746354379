import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { DndDropEvent } from 'ngx-drag-drop';
import { SvgPreviewComponent } from '../svg-preview/svg-preview.component';
import { EditFlgService } from '@service/editflg.service';
import { View } from 'common/models/view';

@Component({
    selector: 'app-hierarchy-draggable',
    templateUrl: './hierarchy-draggable.component.html',
    styleUrls: ['./hierarchy-draggable.component.css'],
})
export class HierarchyDraggableComponent {
    @Output() selectEvent: EventEmitter<any> = new EventEmitter();
    @Output() renameEvent: EventEmitter<any> = new EventEmitter();
    @Input() rootNode: any;
    @Input() canvas: any;
    @Input() elemName: any = [];
    @Input() gaugeSettings: any;
    @Input() view: View;
    @ViewChildren('preview') svgPreviews: QueryList<SvgPreviewComponent>;
    dropTarget: any;
    dropIndex: any;
    disableDrag: boolean = true;
    renameFlag: number = -1;
    currentName: string;

    utils = HierarchyUtils;

    constructor(
        private cdRef: ChangeDetectorRef,
        private editflg: EditFlgService,
    ) {}

    // This ridiculous loop exists because
    // -> only whitelisted elements are shown in the list
    // -> ngx-drag-drop index is based on visible elements
    // So we have to find the actual index of the targeted node
    // by iterating down and skipping non-whitelisted elements
    // Also, the list is in reverse order, because it makes more sense
    // to the user that way. So we have to account for that as well.
    onDragged = (item: any) => {
        let counter = 0;
        let realIndex = this.dropTarget.childNodes.length - 1;
        while (counter < this.dropIndex) {
            const el = this.dropTarget.childNodes[realIndex];
            realIndex--;
            if (this.isNodeNameWhitelisted(el)) counter++;
        }
        this.dropTarget.insertBefore(item, this.dropTarget.childNodes[realIndex + 1]);
    };

    onDrop = (event: DndDropEvent, dropTarget: any) => {
        this.dropTarget = dropTarget;
        this.dropIndex = event.index; //this is the visible index, so it gets a bit warped if there are elements that dont show in list
    };

    handleExpand = (elem: any) => {
        elem.visible = !elem.visible;
    };

    isNodeNameWhitelisted = (node: any) => {
        const whitelisted = [
            'g',
            'line',
            'circle',
            'rect',
            'path',
            'ellipse',
            'text',
            'image',
            'foreignObject',
        ];
        if (String(node.id).includes('highlightBBox')) {
            return false;
        }
        return whitelisted.includes(node.nodeName);
    };

    raiseSelectEvent = (t: any) => {
        //this is hard cording.
        if (t.getAttribute('lock') == 'true') {
            const bbox = t.getBBox();
            const xFinish = Number(bbox.x) + Number(bbox.width);
            const yFinish = Number(bbox.y) + Number(bbox.height);
            if (!document.getElementById('highlightBBox' + t.id)) {
                this.canvas.createSVGElement({
                    element: 'path',
                    attr: {
                        id: 'highlightBBox' + t.id,
                        lock: 'true',
                        fill: 'none',
                        stroke: '#EB144C',
                        'stroke-width': '2',
                        'stroke-dasharray': '5,5',
                        style: 'pointer-events:none',
                        d:
                            'M' +
                            bbox.x +
                            ',' +
                            bbox.y +
                            'L' +
                            xFinish +
                            ',' +
                            bbox.y +
                            ',' +
                            xFinish +
                            ',' +
                            yFinish +
                            ',' +
                            bbox.x +
                            ',' +
                            yFinish +
                            'z',
                    },
                });
            } else {
                document.getElementById('highlightBBox' + t.id).remove();
            }
        } else {
            console.log('select');
            this.selectEvent.emit(t);
        }
    };

    renameElement = (elem: any) => {
        //disable contextmenu
        if (this.editflg.checkEditMode(this.view) === false) {
            return;
        }
        const noContext = document.getElementsByClassName('tree-item');
        this.currentName = this.gaugeSettings[elem.id].name;
        Array.from(noContext).map((e) =>
            e.addEventListener('contextmenu', (el) => {
                el.preventDefault();
            }),
        );

        this.renameFlag = elem.id;
        this.cdRef.detectChanges();
        document.getElementById('renameInputBox').focus();
    };

    onBlur = (elem, newName) => {
        if (this.renameFlag == -1) {
            return;
        }
        const prevFlag = this.renameFlag;
        this.gaugeSettings[elem.id].name = newName;
        this.renameEvent.emit(this.gaugeSettings[elem.id]);
        this.renameFlag = -1;
    };

    checkEnterKey = (event) => {
        if (event.key === 'Enter') {
            const obj: any = document.activeElement;
            if (obj) {
                obj.blur();
            }
        }
    };

    forceUpdate = (elems: any = undefined) => {
        this.cdRef.markForCheck();

        if (elems != undefined) {
            this.svgPreviews.forEach((comp) => {
                comp.checkUpdate(elems);
            });
        }
    };

    select = (elem: any) => {
        if (HierarchyUtils.isLocked(elem)) return;
        this.selectEvent.emit(elem);
    };

    backgroundColor = (elem) => {
        if (document.getElementById('highlightBBox' + elem.id)) {
            return '#000006';
        }
        return this.canvas?.getSelectedElements()?.includes(elem) ? '#353536' : '#252526';
    };

    getDisplayName(elem) {
        //let elemName: Array<any> = Array.from(new Set(this.viewElementName));
        const retValue: { id: any; name: any } = this.elemName.find(
            (val: { id; name }) => val.id === elem.id,
        );
        if (retValue?.name) {
            return retValue?.name;
        } else {
            if (elem.tagName == 'g') {
                return elem.getAttribute('id') ?? 'g';
            } else {
                //this is temporary element name
                return (
                    this.firstLetterToUpper(elem.tagName) +
                    ' - ' +
                    elem.getAttribute('id').slice(0, 6)
                );
            }
        }
    }
    firstLetterToUpper = (str) => {
        return str[0].toUpperCase() + str.substring(1);
    };

    lockDnd = () => {
        this.disableDrag = true;
    };
    unlockDnd = () => {
        this.disableDrag = false;
    };
}

export class HierarchyUtils {
    static isLocked = (elem: any) => {
        return elem?.getAttribute('lock') == 'true';
    };
    static getLockClass = (elem: any) => {
        if (!HierarchyUtils.isVisible(elem) && HierarchyUtils.isLocked(elem)) {
            return 'bothForceShow';
        }
        return HierarchyUtils.isLocked(elem) ? 'forceShow' : 'showOnHover';
    };
    static toggleLocked = (elem: any) => {
        const toSet = HierarchyUtils.isLocked(elem) ? 'false' : 'true';
        if (toSet == 'false') {
            document.getElementById('highlightBBox' + elem.id)?.remove();
        }
        elem?.setAttribute('lock', toSet);
    };

    static getVisibleClass = (elem: any) => {
        if (HierarchyUtils.isVisible(elem) && !HierarchyUtils.isLocked(elem)) {
            return 'bothShowOnHover';
        }
        return HierarchyUtils.isVisible(elem) ? 'showOnHover' : 'forceShow';
    };
    static toggleVis = (elem: any) => {
        elem.style.display = HierarchyUtils.isVisible(elem) ? 'none' : 'block';
    };
    static isVisible = (elem: any) => {
        return elem?.style.display != 'none';
    };
}
