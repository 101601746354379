import { AfterViewInit, EventEmitter, Injectable } from '@angular/core';
import { CanvasService } from './canvas.service';

@Injectable()
export class SelectedElementsService {
    private _onElementsSelected = new EventEmitter<SVGElement[]>();

    constructor(private canvas: CanvasService) {}

    init(): void {
        this.canvas.getCanvas().bind('selected', (win, elems) => {
            this.emitElementsSelected(elems);
        });
    }

    onElementsSelected() {
        return this._onElementsSelected;
    }

    private emitElementsSelected(elements) {
        this._onElementsSelected.emit(elements);
    }

    setSelectedElements(elements: SVGGraphicsElement[]) {
        this.canvas.getCanvas().selectOnly(elements);
    }

    getSelectedElements(): SVGGraphicsElement[] {
        return this.canvas.getCanvas().getSelectedElements();
    }
}
