import { EventEmitter, Injectable } from '@angular/core';
import { CanvasService } from './canvas.service';
import { BatchCommand, InsertElementCommand } from '@svgeditor/history.js';

@Injectable()
export class PasteService {
    private _onGaugePasted = new EventEmitter<any>();

    constructor(private canvasService: CanvasService) {}

    public onGaugePasted() {
        return this._onGaugePasted;
    }

    private emitGaugePasted(gauge) {
        this._onGaugePasted.emit(gauge);
    }

    public paste(objectJson: any[]): SVGGraphicsElement[] {
        return this.safePasteFromJson(objectJson) as SVGGraphicsElement[];
    }

    //The code below checks the ids of objects and overwrites them with uuids in the case that they were pasted, because
    // they will share ids with the original components otherwise.
    private safePasteFromJson(json: any) {
        let clipb = json;

        if (typeof json === 'string') {
            clipb = JSON.parse(json);
        }

        if (!clipb) return;
        let len = clipb.length;
        if (!len) return;

        const canvas = this.canvasService.getCanvas();
        const pasted = [];
        const batchCmd = new BatchCommand('Paste elements');

        const changedIDs = {};

        const checkIDs = (elem) => {
            let srcid;
            if (elem.attr && elem.attr.id) {
                srcid = elem.attr.id;
                changedIDs[elem.attr.id] = canvas.getNextId();
                elem.attr.id = changedIDs[elem.attr.id];
            }
            if (elem.children) {
                if (elem.element == 'foreignObject' || elem.attr.type?.includes('svg-ext')) {
                    const srctype = elem.attr.type;
                    this.replaceForeignObjectIDs(elem, elem.attr.id, changedIDs);
                    if (srcid && srctype) {
                        this.emitGaugePasted(elem);
                    }
                } else elem.children.forEach((child) => checkIDs(child));
            }
        };
        clipb.forEach((elem) => checkIDs(elem));

        canvas
            .runExtensions(
                'IDsUpdated',
                /** @type {module:svgcanvas.SvgCanvas#event:ext_IDsUpdated} */
                { elems: clipb, changes: changedIDs },
                true,
            )
            .forEach(function (extChanges) {
                if (!extChanges || !('remove' in extChanges)) return;

                extChanges.remove.forEach(function (removeID) {
                    clipb = clipb.filter(function (clipBoardItem) {
                        return clipBoardItem.attr.id !== removeID;
                    });
                });
            });

        while (len--) {
            const elem = clipb[len];
            if (!elem) {
                continue;
            }

            elem.attr.x = (Number(elem.attr.x) + 50).toString()
            elem.attr.y =( Number(elem.attr.y) + 50).toString()

            const copy = canvas.addSVGElementsFromJson(elem);
            pasted.push(copy);
            batchCmd.addSubCommand(new InsertElementCommand(copy));

            canvas.restoreRefElements(copy);
        }

        canvas.selectOnly(pasted);
        canvas.addCommandToHistory(batchCmd);
        canvas.call('changed', pasted);

        return pasted;
    }

    //basically, we have a foreignObject that gets a new ID, then we replace all of the I-HXI_svg_#, etc in children with the new id
    svgreg = /(svg_[0-9]+)/i;
    private replaceForeignObjectIDs(elem, id, changedDict) {
        elem.children?.forEach((child) => {
            if (child.attr && child.attr.id) {
                const newId = this.svgreg.test(child.attr.id)
                    ? child.attr.id.replace(this.svgreg, id)
                    : this.canvasService.getCanvas().getNextId();
                changedDict[child.attr.id] = newId;
                child.attr.id = changedDict[child.attr.id];
                child.namespace = 'http://www.w3.org/1999/xhtml';
                child.element = child.element.toLowerCase();
            }
            this.replaceForeignObjectIDs(child, id, changedDict);
        });
    }
}
