import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { EndPointApi } from '@helpers/endpointapi';
import { SocketMiddlewareService } from './socket-middleware.service';
import { RunHourSetting } from 'common/models/run-hour-setting';
import { RunHourSettingEvents } from 'icreate/common/io-event/websocket-message';

@Injectable({
    providedIn: 'root',
})
export class RunHourSettingsService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(
        private http: HttpClient,
        private socketService: SocketMiddlewareService,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    getAll(projectId: string): Promise<RunHourSetting[]> {
        return lastValueFrom(
            this.http.get<RunHourSetting[]>(`${this.domain}/api/runHourSettings`, {
                params: { projectId },
            }),
        );
    }
    
    create(projectId: string): Promise<RunHourSetting> {
        return lastValueFrom(
            this.http.post<RunHourSetting>(`${this.domain}/api/runHourSettings`, {
                headers: this.headers,
                params: { projectId }
            }),
            
        );
    }

    delete(runHourSettingId: string): Promise<any> {
        return lastValueFrom(
            this.http.delete<void>(`/api/runHourSettings/${runHourSettingId}`),
        );
    }

    update(runHourSetting: RunHourSetting): Promise<any> {
        return lastValueFrom(
            this.http.put<void>(`${this.domain}/api/runHourSettings/${runHourSetting.id}`, runHourSetting, {
                headers: this.headers,
            }),
        );
    }

    settingDeleted$() {
        return this.socketService.receiveMessageObserver(RunHourSettingEvents.DELETE_SETTING);
    }

    settingCreated$() {
        return this.socketService.receiveMessageObserver(RunHourSettingEvents.CREATE_SETTING);
    }

    settingUpdated$() {
        return this.socketService.receiveMessageObserver(RunHourSettingEvents.UPDATE_SETTING);
    }


}
