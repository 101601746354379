import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';
import { View } from 'common/models/view';

import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ViewsService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private http: HttpClient) {
        this.domain = EndPointApi.iApiDomain();
    }

    register(accountName: string, accountOID: string): Promise<any> {
        const requestBody = {
            accountName: accountName,
            accountOID: accountOID,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/views/register`, requestBody, {
                headers: this.headers,
            }),
        );
    }

    lockedViews(projectId: string): Promise<any> {
        return lastValueFrom(
            this.http.get(`${this.domain}/api/views/lockedViews`, {
                headers: this.headers,
                params: { projectId: projectId },
            }),
        );
    }

    lockView(
        viewID: string,
        editorName: string,
        accountOID: string,
        projectId: string,
    ): Promise<any> {
        const requestBody = {
            viewID: viewID,
            editorName: editorName,
            accountOID: accountOID,
            projectId: projectId,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/views/lock`, requestBody, {
                headers: this.headers,
            }),
        );
    }

    unlockView(
        viewID: string,
        projectId: string,
        editorName: string,
        accountOID: string,
    ): Promise<any> {
        const requestBody = {
            viewID: viewID,
            projectId: projectId,
            editorName: editorName,
            editorOid: accountOID,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/views/unlock`, requestBody, {
                headers: this.headers,
            }),
        );
    }

    unregister(accountOID: string, projectId: string): Promise<any> {
        const requestBody = {
            accountOID: accountOID,
            projectId: projectId,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/views/unregister`, requestBody, {
                headers: this.headers,
            }),
        );
    }

    unlockViewAll(accountOID: string, projectId: string): Promise<any> {
        const requestBody = {
            accountOID: accountOID,
            projectId: projectId,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/views/unlockViewAll`, requestBody, {
                headers: this.headers,
            }),
        );
    }

    getLockedViewAll(projectId: string): Promise<View[]> {
        return lastValueFrom(
            this.http.get<View[]>(`${this.domain}/api/views/lockedViewAll`, {
                headers: this.headers,
                params: { projectId: projectId },
            }),
        );
    }
}
