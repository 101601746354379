import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-changes-detector',
    templateUrl: './changes-detector.component.html',
})
export class ChangesDetectorComponent {
    @Input() changesInProject;
    @Input() currentViewId;
}
