<div class="views-group-title">
    <mat-icon (click)="expandGroup()" [ngStyle]="{ cursor: 'pointer' }">{{
        !openGroup ? "arrow_right" : "arrow_drop_down"
    }}</mat-icon>
    <span>{{ group.groupName }}</span>
    <div class="icons-container">
        <mat-icon (click)="onEditGroup.emit(group)" class="icon-display"
            >edit</mat-icon
        >
        <mat-icon (click)="onDeleteGroup.emit(group)" class="icon-display"
            >delete_forever</mat-icon
        >
    </div>
</div>
<div *ngIf="openGroup" style="margin-left: 10%">
    <ng-content></ng-content>
</div>
