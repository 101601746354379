<div class="input-wrap" (click)="onOpenSelect()">
    <p class="input-label">{{ label }}</p>
    <input
        [name]="name"
        [value]="inputValue"
        readonly
        placeholder="select category"
    />
</div>
<div *ngIf="isShowSelect" class="select-lists">
    <div *ngFor="let category of categories" class="select-list">
        <div (click)="$event.preventDefault(); onSelectValue(category)">
            {{ category.category }}
        </div>
    </div>
</div>
