<div
    *ngIf="isShow"
    class="dlg-wrap"
    (click)="onClose(); $event.preventDefault()"
>
    <div class="dlg-container" (click)="$event.stopPropagation()">
        <div class="dlg-contents">
            <div class="dlg-content">
                {{ content | translate }}
            </div>
            <div class="dlg-buttons">
                <button (click)="onClick(true)" class="primary">
                    {{ "dlg.yes" | translate }}
                </button>
                <button (click)="onClick(false)" class="white">
                    {{ "dlg.no" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
