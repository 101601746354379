import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChannelCategoryService } from '@service/channel-category.service';
import { ChannelCategory } from 'common/models/channel-category';
import { ChannelGroup } from 'common/models/channel-group';
import { DialogType } from '../dialog-channel-category/dialog-channel-category.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-channel-panel-category',
    templateUrl: './channel-panel-category.component.html',
    styleUrls: ['./channel-panel-category.component.css'],
})
export class ChannelPanelCategoryComponent {
    readonly CATEGORY_ADD = DialogType.add;
    readonly CATEGORY_RENAME = DialogType.rename;
    readonly CATEGORY_DELETE = DialogType.delete;

    @Input() channelCategories: ChannelCategory[] = [];
    @Input() channelGroups: ChannelGroup[] = [];
    @Output() openCategoryEmitter = new EventEmitter();
    private addCategorySub: Subscription;
    activeCategory = new ChannelCategory();
    constructor(private channelCategoryService: ChannelCategoryService) {}

    ngOnInit() {
        this.addCategorySub = this.channelCategoryService.addNewCateogry.subscribe(
            (newCategory) => {
                this.channelCategories.push(newCategory);
            },
        );
    }

    ngOnDestroy() {
        this.addCategorySub.unsubscribe();
    }

    getGroupsCount(categoryId) {
        return this.channelGroups.filter((group) => group.categoryId == categoryId).length;
    }

    onOpenCategoryDialog(dialogType: DialogType, category: ChannelCategory) {
        this.openCategoryEmitter.emit({ type: dialogType, category: category });
    }

    openMore(category: ChannelCategory) {
        if (this.activeCategory.id == category.id) {
            this.activeCategory = new ChannelCategory();
        } else {
            this.activeCategory = category;
        }
    }

    closeMore() {
        this.activeCategory = new ChannelCategory();
    }

    onRename() {
        this.openCategoryEmitter.emit({ type: DialogType.rename, category: this.activeCategory });
        this.closeMore();
    }

    onDuplicate() {
        console.log('Design is not done yet');
        this.closeMore();
    }

    onDelete() {
        this.openCategoryEmitter.emit({ type: DialogType.delete, category: this.activeCategory });
        this.closeMore();
    }
}
