// Control types are defined by SMS-55 specification
// TODO: commented controls will be needed to implement in the future

export enum OpsControlType {
    //FcuStatusRequest = '2100',
    //SwitchActiveLineRequest = '2101',
    //RunningTimeRequest = '2102',
    //SoftwareVersionRequest = '2103',
    //AdjustClockRequest = '2301',
    //ChannelDataSettingChangingRequest = '2302',
    //TimerSettingChangingRequest = '2303',
    //LoggingTimeSettingChangingRequest = '2304',
    //AlarmDisplayModeChangingRequest = '2305',
    //SetModeChangingRequest = '2306',
    //PrintSettingChangingRequest = '2307',
    //DutySettingChangingRequest = '2309',
    //ChannelControlRequest = '2401',
    //IOStatusRequest = '2405',
    //FcuControlRequest = '2410',
    ChannelMultiControlRequest = '2411',
    //EventLogDeletingRequest = '2412',
    //PIDControlRequest = '2430' ,
    //FileRequest = '2502',
}

export class OpsRequestBase {
    command: string;
    request: any;
}
