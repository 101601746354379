import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidationError } from 'common/validators';
import { OpsPasswordValidator } from 'common/validators/ops-users/ops-users-validator';
import * as bcrypt from 'bcryptjs';

@Component({
    selector: 'app-ops-user-password',
    templateUrl: './ops-user-password.component.html',
    styleUrls: ['./ops-user-password.component.scss'],
})
export class OpsUserPasswordComponent implements OnInit {
    @Input() currentPassword: string;
    @Output() onCloseModal: EventEmitter<void> = new EventEmitter();
    @Output() onSavePassword: EventEmitter<string> = new EventEmitter();
    passwordVisible: boolean = false;
    validationErrors: { [propertyName: string]: ValidationError } = {};
    newPassword: string;
    oldPassword: string;

    private availableCharacters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`-!@#$%^&*()_+-={}[]|:;"\'<>.,?/';
    private DEFAULT_PASSWORD_LENGTH = 8;

    ngOnInit(): void {
        if (this.currentPassword) {
            this.checkPasswordErrors('oldPassword', this.oldPassword);
        }
        this.checkPasswordErrors('newPassword', this.newPassword);
    }

    showPassword() {
        return this.passwordVisible;
    }

    onPasswordVisible() {
        this.passwordVisible = !this.passwordVisible;
    }

    checkPasswordErrors(propertyName, password) {
        try {
            OpsPasswordValidator.validateSync(password, { abortEarly: false });
            if (
                propertyName === 'oldPassword' &&
                !bcrypt.compareSync(password, this.currentPassword)
            ) {
                this.validationErrors[propertyName] = new ValidationError(
                    'ops-settings.users.password.error.no-match',
                );
            } else {
                this.validationErrors[propertyName] = null;
            }
        } catch (e: any) {
            if (e.name !== 'ValidationError') {
                console.error(e);
                this.validationErrors[propertyName] = null;
            }

            this.validationErrors[propertyName] = e as ValidationError;
        }
    }

    generatePassword(): void {
        let password = '';
        const charactersLength = this.availableCharacters.length;

        for (let i = 0; i < this.DEFAULT_PASSWORD_LENGTH; i++) {
            const randomIndex = Math.floor(Math.random() * charactersLength);
            password += this.availableCharacters[randomIndex];
        }
        this.newPassword = password;
        this.checkPasswordErrors('newPassword', this.newPassword);
    }

    savePassword() {
        let salt = bcrypt.genSaltSync(10);
        const hashedPassword = bcrypt.hashSync(this.newPassword, salt);
        this.onSavePassword.emit(hashedPassword);
    }

    canSave(): boolean {
        let hasError = false;
        const validationErrorsKey = Object.keys(this.validationErrors);
        for (let i = 0; i < validationErrorsKey.length; i++) {
            if (this.validationErrors[validationErrorsKey[i]]) {
                hasError = true;
            }
        }
        return hasError;
    }
}
