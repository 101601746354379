import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from '@layout/loading-spinner/loading-spinner.component';
import { IapiService } from './iapi.service';
import { VesselSelectListComponent } from '@components//vessel-select-list/vessel-select-list.component';
import { EndPointApi } from '@helpers/endpointapi';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class VesselSelectService {
    overlayRef = this.overlay.create({
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });

    vesselData: any;

    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(
        public dialog: MatDialog,
        private iApi: IapiService,
        private overlay: Overlay,
        private toastr: ToastrService,
        private translateService: TranslateService,
        private http: HttpClient,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    saveVesselData(vesselData: IVesselData, projectId: string): Promise<any> {
        const requestBody = {
            projectId: projectId,
            vesselData: vesselData,
        };
        return lastValueFrom(
            this.http.post(`${this.domain}/api/vessel/vesselData`, requestBody, {
                headers: this.headers,
            }),
        );
    }

    async openVesselSelectDialog(builderId: string, builderName: string) {
        this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));

        try {
            const vesselList = await this.iApi.getVesselList(builderId);

            this.overlayRef.detach();

            if (vesselList.length == 0) {
                return;
            }

            vesselList.sort(this.iApi.compareForSort);

            vesselList.push({ builder_id: builderId, name: builderName });

            const dialogRef = this.dialog.open(VesselSelectListComponent, {
                data: vesselList,
            });

            const result = await lastValueFrom(dialogRef.afterClosed());

            const companyList: Array<any> = this.iApi.companyListCache;
            const ownerName = await companyList.find((element) => element.id === result.ownerId)
                ?.name;
            const managerName = await companyList.find((element) => element.id === result.managerId)
                ?.name;

            const AMSVer = null; //now, AMSVer doesn't exist in DB. So this is mock.

            result.ownerName = ownerName;
            result.managerName = managerName;
            result.AMSVer = AMSVer;

            this.iApi.clearCache();
            return result;
        } catch (e) {
            this.notifyError(e);
            this.overlayRef.detach();
            return;
        }
    }

    public notifyError(error) {
        console.error(error);

        this.toastr.error(error?.message, '', {
            timeOut: 3000,
            closeButton: true,
            disableTimeOut: true,
        });
    }
}
