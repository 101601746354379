import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthorizationErrorService } from './authorization.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HttpInterceptorService implements HttpInterceptor {
    constructor(
        private authorizationService: AuthorizationErrorService,
        private router: Router,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        const modifiedReq = req.clone({ withCredentials: true });
        return next.handle(modifiedReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (req.url === '/auth/check') {
                    if (error.error.oid) {
                        return this.router.navigate(['/iCreateLogin'], {
                            state: { unauthorized: true },
                        });
                    }
                    return this.router.navigate(['/iCreateLogin'], {
                        state: { unauthorized: false },
                    });
                } else if (req.url === '/auth/check') {
                    return this.router.navigate(['/iCreateLogin']);
                } else if (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden) {
                    this.emitAzureAuthorizationError();
                } else if (error.status === 498) {
                    this.emitIapiAuthorizationError();
                }
                return throwError(() => error);
            }),
        );
    }

    emitAzureAuthorizationError() {
        this.authorizationService.azureAuthorizationError.next();
    }

    emitIapiAuthorizationError() {
        this.authorizationService.iApiAuthorizationError.next();
    }
}
