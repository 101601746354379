import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    DoCheck,
    IterableDiffers,
    IterableDiffer,
    HostListener,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationWindowComponent } from '@components/editor/confirmation-window/confirmation-window.component';
import { GraphService } from '@service/graphs/graph.service';
import { NotificationService } from '@service/notification.service';
import { ProjectService } from '@service/project.service';
import { Graph, GraphType } from 'common/models/graphs';
import { getValidationErrors, mapErrors } from 'common/validators';
import { GraphValidator } from 'common/validators/graphs/graph-validator';
import * as _ from 'lodash';
import { lastValueFrom, take } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
@Component({
    selector: 'app-graph-list',
    templateUrl: './graph-list.component.html',
    styleUrls: ['./graph-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphListComponent implements OnInit {
    @Input() graphs: Graph[];
    @Output() onEditGraph: EventEmitter<Graph> = new EventEmitter();
    @ViewChild('confirmationWindow') confirmationWindow: ConfirmationWindowComponent;
    readonly MAX_GRAPH_PAGES: number = 64;

    tableDataSource = new MatTableDataSource<Graph>();

    displayedColumns: string[] = ['locked', 'number', 'type', 'title', 'icon'];

    selectedGraph: Graph;
    copiedGraph: Graph;
    initalGraphsList: Graph[];

    iterableDiffer: IterableDiffer<any>;

    graphTypes: GraphTypeOption[] = [
        { value: GraphType.Bar, displayString: 'graph-settings.types.bar' },
        { value: GraphType.Exhaust, displayString: 'graph-settings.types.exhaust' },
        { value: GraphType.Gauge, displayString: 'graph-settings.types.gauge' },
    ];

    constructor(
        private router: Router,
        private projectService: ProjectService,
        private graphService: GraphService,
        private iterableDiffers: IterableDiffers,
        private notificationService: NotificationService,
        private cdr: ChangeDetectorRef,
    ) {
        this.iterableDiffer = iterableDiffers.find([]).create(null);
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnload() {
        const projectId = this.projectService.getProjectId();
        this.graphService.sendUnloadGraphListBeacon(projectId);
    }

    ngOnInit(): void {
        this.initalGraphsList = _.cloneDeep(this.graphs);
        this.tableDataSource.data = this.graphs;
    }

    ngDoCheck() {
        let graphListChanges = this.iterableDiffer.diff(this.graphs);
        if (graphListChanges) {
            this.tableDataSource.data = this.graphs;
        }
    }

    async editGraph(graph: Graph) {
        if (!graph.locked) {
            const updatedGraphs = this.getChangedGraphs();
            updatedGraphs.map(async (graph) => {
                await this.graphService.update(graph);
            });
            this.onEditGraph.emit(graph);
        }
    }

    selectGraph(graph) {
        this.selectedGraph = graph;
    }

    canAddGraph() {
        return this.graphs.length < this.MAX_GRAPH_PAGES;
    }

    async addGraph() {
        const projectId = this.projectService.getProjectId();
        const newGraph = new Graph();
        newGraph.projectId = projectId;
        try {
            await this.graphService.create(newGraph);
        } catch (err) {
            console.log(err);
        }
    }

    async deleteGraph() {
        try {
            const index = this.graphs.findIndex((graph) => graph === this.selectedGraph);
            if (index !== -1) {
                this.graphs.splice(index, 1);
                this.selectedGraph = null;
                this.notificationService.successMessage(
                    'graph-settings.graph.notification.success.delete',
                );
            }
        } catch (err) {
            this.notificationService.errorMessage('graph-settings.graph.notification.error.delete');
            console.error(err);
        }
    }

    copyGraph() {
        this.copiedGraph = this.selectedGraph;
        this.notificationService.successMessage('graph-settings.graph.notification.success.copy');
    }

    async pasteGraph() {
        const newGraph = new Graph();
        newGraph.type = this.copiedGraph.type;
        newGraph.title = this.copiedGraph.title;
        newGraph.projectId = this.copiedGraph.projectId;
        const refrenceGraphId = this.copiedGraph.id;
        try {
            await this.graphService.clone(newGraph, refrenceGraphId);
            this.notificationService.successMessage(
                'graph-settings.graph.notification.success.clone',
            );
        } catch (err) {
            this.notificationService.errorMessage('graph-settings.graph.notification.error.clone');
            console.error(err);
        }
    }

    saveGraphs() {
        try {
            const updatedGraphs = this.getChangedGraphs();
            const deletedGraphs = this.getDeletedGraphs();

            updatedGraphs.map(async (graph) => {
                await this.graphService.update(graph);
            });
            deletedGraphs.forEach(async (graph: Graph) => {
                await this.graphService.delete(graph.id);
            });
            this.initalGraphsList = _.cloneDeep(this.graphs);
            this.notificationService.successMessage(
                'graph-settings.graph.notification.success.save',
            );
        } catch (err) {
            this.notificationService.errorMessage('graph-settings.graph.notification.error.save');
            console.error(err);
        }
    }

    async exit() {
        if (this.graphListHasChanged()) {
            this.confirmationWindow.setVisible(true);
            const closeWidndow = await lastValueFrom(
                this.confirmationWindow.onOptionSelected.pipe(take(1)),
            );
            if (closeWidndow) {
                const projectId = this.projectService.getProjectId();
                this.router.navigate(['editor', projectId]);
            }
        } else {
            const projectId = this.projectService.getProjectId();
            this.graphService.sendUnloadGraphListBeacon(projectId);
            this.router.navigate(['editor', projectId]);
        }
    }

    unselectRow() {
        this.selectedGraph = null;
    }

    graphListHasChanged() {
        return !_.isEqual(this.initalGraphsList, this.graphs);
    }

    getChangedGraphs() {
        const updatedObjects = [];

        this.graphs.forEach((newObj) => {
            const oldObj = this.initalGraphsList.find((o) => o.id === newObj.id);
            if (oldObj && !_.isEqual(oldObj, newObj)) {
                updatedObjects.push(newObj);
            } else if (!oldObj) {
                updatedObjects.push(newObj);
            }
        });

        return updatedObjects;
    }

    getDeletedGraphs() {
        const deletedGraphs = [];

        this.initalGraphsList.forEach((initialGraph) => {
            const graphs = this.graphs.find((o) => o.id === initialGraph.id);
            if (!graphs) {
                deletedGraphs.push(initialGraph);
            }
        });

        return deletedGraphs;
    }

    getGraphErrors(graph, key) {
        const errors = getValidationErrors(GraphValidator(), graph);
        if (errors && errors.length > 0) {
            const validationErrors = mapErrors(errors);
            if (validationErrors[key]) {
                return validationErrors[key].errors[0];
            }
        } else if (key === 'title') {
            let repeatedTitleError;
            this.graphs.map((graphFromList) => {
                if (graphFromList !== graph) {
                    if (graph.title === graphFromList.title) {
                        repeatedTitleError = 'graph-settings.graph.error.repeated-title';
                    }
                }
            });
            if (repeatedTitleError) {
                return repeatedTitleError;
            }
        }
    }

    graphListErrors() {
        let hasErrors = false;
        this.graphs.map((graph) => {
            const errors = getValidationErrors(GraphValidator(), graph);
            if (errors.length > 0) {
                hasErrors = true;
            }
        });
        if (!hasErrors) {
            const titles = this.graphs.map((graph) => graph.title);
            const uniqueTitles = new Set(titles);
            hasErrors = titles.length !== uniqueTitles.size;
        }

        return hasErrors;
    }

    saveButtonIsDisabled() {
        return this.graphListErrors() || !this.graphListHasChanged();
    }
}

class GraphTypeOption {
    value: GraphType;
    displayString: string;
}
