<div style="width: 350px">
    <h1
        mat-dialog-title
        style="display: inline-block; cursor: move"
        mat-dialog-draggable
    >
        {{'dlg.gauge-permission-title' | translate}}
    </h1>
    <mat-icon
        (click)="onNoClick()"
        style="
            float: right;
            margin-right: -10px;
            margin-top: -10px;
            cursor: pointer;
            color: gray;
        "
    >
        clear
    </mat-icon>
    <div mat-dialog-content>
        <div class="my-form-field" style="display: block; margin-bottom: 10px">
            <span style="display: inline-block; width: 45px; text-align: center"
                >{{'dlg.gauge-permission-show' | translate}}</span
            >
            <span style="display: inline-block; width: 70px"
                >{{'dlg.gauge-permission-enabled' | translate}}</span
            >
            <span style="display: inline-block"
                >{{'dlg.gauge-permission-label' | translate}}</span
            >
        </div>
    </div>
    <div mat-dialog-actions class="dialog-action">
        <button mat-raised-button (click)="onNoClick()">
            {{'dlg.cancel' | translate}}
        </button>
        <button
            mat-raised-button
            color="primary"
            (click)="onOkClick()"
            cdkFocusInitial
        >
            {{'dlg.ok' | translate}}
        </button>
    </div>
</div>
