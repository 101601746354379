import { Component, Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { lastValueFrom } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectData } from 'icreate/common/models/project';
import { LoadingSpinnerComponent } from '@layout/loading-spinner/loading-spinner.component';
import { BlobVersionSelectorComponent } from '@components/blob-version-selector/blob-version-selector.component';
import { BlobStorageApiService } from './blob-storage-api.service';

@Injectable({ providedIn: 'root' })
export class ProjectVersionSelectorService {
    public overlayRef: any = this.overlay.create({
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });

    constructor(
        public dialog: MatDialog,
        private overlay: Overlay,
        private blobStorageApiService: BlobStorageApiService
    ) {}

    async openVersionList(projectData: ProjectData) {
        if (!this.overlayRef.hasAttached()) {
            this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));
        }
        try {
            const builderId = projectData.VesselBuilderCompanyID;
            const vesselId = projectData.VesselID;
            const versionList = await this.blobStorageApiService.getVersionList(builderId, vesselId);
            this.overlayRef.detach();
            if (versionList.length == 0) {
                this.dialog.open(EmptyBlobStorageErrorComponent);
                return;
            }
            const dialogRef = this.dialog.open(BlobVersionSelectorComponent, {
                data: {
                    versionList: versionList,
                    builderName: projectData.VesselBuilderCompanyName,
                    vesselName: projectData.VesselName,
                },
            });

            const result = await lastValueFrom(dialogRef.afterClosed());
            return result;
        } catch (e) {
            this.overlayRef.detach();
            return;
        }
    }
}

@Component({
    selector: 'app-azure-login-prompt',
    template: `<div class="dialog-wrapper">
        <h2>{{ 'project-settings.error.empty-blob.title' | translate }}</h2>
        <div class="button-wrapper">
            <button class="component-large primary caption" (click)="this.dialogRef.close()">
                {{ 'project-settings.error.empty-blob.continue' | translate }}
            </button>
        </div>
    </div>`,
    styleUrls: ['./../components/project-version-selector/project-version-selector.component.scss'],
})
export class EmptyBlobStorageErrorComponent {
    constructor(public dialogRef: MatDialogRef<EmptyBlobStorageErrorComponent>) {}
}
