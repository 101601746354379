<ng-container *ngIf="operationType !== UPDATE">
  <span class="accept-span left" (click)="onAcceptClick(id, blob, LEFT)"></span>
  <span
    class="accept-span right"
    (click)="onAcceptClick(id, local, RIGHT)"
  ></span>
</ng-container>

<tr
  *ngFor="
    let diffItemRow of blob | objectToRowPipe : local;
    index as j;
    count as c
  "
>
  <td
    [ngClass]="[
      operationType,
      LEFT,
      'index-td',
      diffItemRow.blob !== diffItemRow.local ? 'diff' : '',
      operationType === UPDATE
        ? isSelected(diffItemRow.flattenKey, diffItemRow.blob)
        : isSelected(diffItemRow.flattenKey, LEFT)
    ]"
  >
    <span [ngClass]="[blob !== local ? 'delete' : '', 'index-items']">{{
      rowNum * c + j + 1
    }}</span>
  </td>
  <td
    [ngClass]="[
      operationType,
      LEFT,
      diffItemRow.blob !== diffItemRow.local ? 'diff' : '',
      operationType === UPDATE
        ? isSelected(diffItemRow.flattenKey, diffItemRow.blob)
        : isSelected(diffItemRow.flattenKey, LEFT)
    ]"
    (click)="
      operationType === UPDATE
        ? onResolvedClick(id, diffItemRow.flattenKey, diffItemRow.blob)
        : ''
    "
  >
    <span
      [ngClass]="[
        'obj-item-' + diffItemRow.flattenKey.split('.').length.toString()
      ]"
      >{{ diffItemRow.key }} : {{ diffItemRow.blob }}</span
    >
  </td>
  <td
    [ngClass]="[
      operationType,
      RIGHT,
      'index-td',
      diffItemRow.blob !== diffItemRow.local ? 'diff' : '',
      operationType === UPDATE
        ? isSelected(diffItemRow.flattenKey, diffItemRow.local)
        : isSelected(diffItemRow.flattenKey, RIGHT)
    ]"
  >
    <span
      [ngClass]="[
        diffItemRow.blob !== diffItemRow.local ? 'add' : '',
        'index-items'
      ]"
      >{{ rowNum * c + j + 1 }}</span
    >
  </td>
  <td
    [ngClass]="[
      operationType,
      RIGHT,
      diffItemRow.blob !== diffItemRow.local ? 'diff' : '',
      operationType === UPDATE
        ? isSelected(diffItemRow.flattenKey, diffItemRow.local)
        : isSelected(diffItemRow.flattenKey, RIGHT)
    ]"
    (click)="
      operationType === UPDATE
        ? onResolvedClick(id, diffItemRow.flattenKey, diffItemRow.local)
        : ''
    "
  >
    <span
      [ngClass]="[
        'obj-item-' + diffItemRow.flattenKey.split('.').length.toString()
      ]"
      >{{ diffItemRow.key }} : {{ diffItemRow.local }}</span
    >
  </td>
</tr>
