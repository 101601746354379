<div *ngIf="server && server.property">
  <div
    class="main-line"
    [style.left.px]="getMainLineLeftPosition()"
    [style.top.px]="getMainLineTopPosition()"
    [style.width.px]="lineDeviceSize"
    [style.height.px]="getMainLineHeight()"
  ></div>
  <div
    *ngFor="let device of devicesValue(); index as i"
    class="device-line"
    [style.left.px]="getDeviceLineLeftPosition(i)"
    [style.top.px]="getDeviceLineTopPosition()"
    [style.width.px]="lineDeviceSize"
    [style.height.px]="deviceLineHeight"
  ></div>
</div>
<div class="container">
  <div
    class="main-device"
    *ngIf="server && server.property"
    [style.left.px]="getMainLeftPosition()"
    [style.top.px]="getMainTopPosition()"
    [style.width.px]="mainWidth"
    [style.height.px]="mainHeight"
    [style.borderWidth.px]="mainBorder"
  >
    <span class="device-header" style="padding-top: 10px">{{
      server.name
    }}</span>
    <span class="device-pro">{{ server.property.address }}</span>
  </div>

  <div
    *ngFor="let device of devicesValue(); index as i"
    [ngClass]="getNodeClass(device)"
    [style.color]="device.enabled ? '#FFFFFF' : '#C5C5C5'"
    [style.left.px]="getDeviceLeftPosition(i)"
    [style.top.px]="getDeviceTopPosition()"
    [style.width.px]="deviceWidth"
    [style.height.px]="deviceHeight"
    [style.borderWidth.px]="deviceBorder"
  >
    <span class="device-header">{{ device.name }}</span>
    <span class="device-pro" *ngIf="device.property">{{
      device.property.address
    }}</span>
    <span class="device-pro" style="padding-bottom: 10px">{{
      getDevicePropertyToShow(device)
    }}</span>
    <div
      class="device-status"
      *ngIf="device.enabled"
      [style.background-color]="getDeviceStatusColor(device)"
    ></div>
    <mat-icon
      (click)="onListDevice(device)"
      class="device-icon device-list"
      [style.right.px]="readonly ? 2 : 28"
      >list_alt</mat-icon
    >
    <mat-icon
      (click)="onEditDevice(device)"
      class="device-icon device-edit"
      *ngIf="!readonly"
      >edit</mat-icon
    >
    <mat-icon
      (click)="onRemoveDevice(device)"
      class="device-icon device-delete"
      *ngIf="!readonly"
      >clear</mat-icon
    >
  </div>
</div>
