import { Injectable, OnDestroy } from '@angular/core';
import { WindowRef } from '@helpers/windowref';

export interface KeyboardShortcutHandler {
    key: string;
    keyDownIgnoreModifiers?: boolean;
    handleKeyDown?: (event: KeyboardEvent) => void;
    keyUpIngoreModifiers?: boolean;
    handleKeyUp?: (event: KeyboardEvent) => void;
}

type handlerMap = { [key: string]: KeyboardShortcutHandler[] };

@Injectable()
export class KeyboardShortcutService implements OnDestroy {
    keydownHandlers: handlerMap = {};
    keyupHandlers: handlerMap = {};

    keyDownIgnoringModifierHandlers: handlerMap = {};
    keyUpIgnoringModifierHandlers: handlerMap = {};

    constructor(private winRef: WindowRef) {
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    init() {
        this.winRef.nativeWindow.addEventListener('keydown', this.handleKeyDown);
        this.winRef.nativeWindow.addEventListener('keyup', this.handleKeyUp);
    }

    ngOnDestroy(): void {
        this.winRef.nativeWindow.removeEventListener('keydown', this.handleKeyDown);
        this.winRef.nativeWindow.removeEventListener('keyup', this.handleKeyUp);
    }

    public addShortcut(handler: KeyboardShortcutHandler) {
        if (handler.handleKeyDown) {
            if (handler.keyDownIgnoreModifiers) {
                this.addHandler(this.keyDownIgnoringModifierHandlers, handler);
            } else {
                this.addHandler(this.keydownHandlers, handler);
            }
        }

        if (handler.handleKeyUp) {
            if (handler.keyUpIngoreModifiers) {
                this.addHandler(this.keyUpIgnoringModifierHandlers, handler);
            } else {
                this.addHandler(this.keyupHandlers, handler);
            }
        }
    }

    private addHandler(collection: handlerMap, handler: KeyboardShortcutHandler) {
        if (!(handler.key in collection)) {
            collection[handler.key] = [handler];
        } else {
            collection[handler.key].push(handler);
        }
    }

    private handleKeyDown(event: KeyboardEvent) {
        if ((event.target as Element).tagName === 'INPUT') {
            return;
        }

        if ((event.target as Element).tagName === 'TEXTAREA') {
            return;
        }

        const key = this.parseKeyString(event);

        if (key in this.keydownHandlers) {
            this.executeKeyDown(this.keydownHandlers[key], event);
        }

        if (event.key in this.keyDownIgnoringModifierHandlers) {
            this.executeKeyDown(this.keyDownIgnoringModifierHandlers[key], event);
        }
    }

    private executeKeyDown(listeners: KeyboardShortcutHandler[], event: KeyboardEvent) {
        event.preventDefault();
        for (let i = 0; i < listeners.length; i++) {
            listeners[i].handleKeyDown(event);
        }
    }

    private handleKeyUp(event: KeyboardEvent) {
        if ((event.target as Element).tagName === 'INPUT') {
            return;
        }

        const key = this.parseKeyString(event);

        if (key in this.keyupHandlers) {
            this.executeKeyUp(this.keyupHandlers[key], event);
        }

        if (event.key in this.keyUpIgnoringModifierHandlers) {
            this.executeKeyUp(this.keyUpIgnoringModifierHandlers[key], event);
        }
    }

    private executeKeyUp(listeners: KeyboardShortcutHandler[], event: KeyboardEvent) {
        event.preventDefault();
        for (let i = 0; i < listeners.length; i++) {
            listeners[i].handleKeyUp(event);
        }
    }

    private parseKeyString(event: KeyboardEvent) {
        return `${event.altKey ? 'alt+' : ''}${event.shiftKey ? 'shift+' : ''}${
            event.metaKey ? 'meta+' : ''
        }${event.ctrlKey ? 'ctrl+' : ''}${event.key.toLowerCase()}`;
    }
}
