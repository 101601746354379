import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPointApi } from '@helpers/endpointapi';
import { lastValueFrom } from 'rxjs';
import { DEFAULT_USERS, OpsUser } from 'common/models/ops-users';

@Injectable({
    providedIn: 'root',
})
export class OpsUsersService {
    private domain: string;
    private headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(
        private http: HttpClient,
    ) {
        this.domain = EndPointApi.iApiDomain();
    }

    getUsers(projectId: string): Promise<OpsUser[]> {
        return lastValueFrom(
            this.http.get<OpsUser[]>(`${this.domain}/api/ops-users`, {
                params: { projectId },
            }),
        );
    }

    saveList(userList: OpsUser[]): Promise<any> {
        return lastValueFrom(
            this.http.put(`${this.domain}/api/ops-users/`, userList, {
                headers: this.headers,
            }),
        );
    }

    deleteUsers(IdsToDelete: string[]): Promise<any> {
        return lastValueFrom(
            this.http.delete(`${this.domain}/api/ops-users/`,{ body: {IdsToDelete}}),
        );
    }

    createUserList(projectId: string): OpsUser[] {
        const usersList = [];
        for (let i = 0; i < DEFAULT_USERS.length; i++) {
            let user;
            if (DEFAULT_USERS[i] === 'Admin') {
                user = new OpsUser(projectId, DEFAULT_USERS[i], true)
            } else {
                user = new OpsUser(projectId, DEFAULT_USERS[i])
            }
            usersList.push(user);
        }

        return usersList;
    }
}
