import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '@service/app.service';
import { ProjectService } from '@service/project.service';
import { AppSettingsComponent } from '../app-settings/app-settings.component';

@Component({
    selector: 'app-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss'],
})
export class SetupComponent implements OnInit, AfterViewInit {
    constructor(
        private router: Router,
        private appService: AppService,
        public dialog: MatDialog,
        private projectService: ProjectService,
        public dialogRef: MatDialogRef<SetupComponent>,
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {}

    onNoClick() {
        this.dialogRef.close();
    }

    goTo(destination: string) {
        this.onNoClick();
        this.router.navigate([destination]);
    }

    /**
     * edit application settings
     */
    onSettings() {
        this.onNoClick();
        const dialogRef = this.dialog.open(AppSettingsComponent, {
            position: { top: '60px' },
        });
        dialogRef.afterClosed().subscribe((result) => {});
    }

    isToDisable(section: string) {
        if (section === 'messages') {
            return this.appService.isClientApp;
        } else if (section === 'users') {
            return this.appService.isClientApp;
        }
        return false;
    }
}
