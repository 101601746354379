<div class="channel-container">
    <div class="channel-wrap">
        <div class="channel-header">
            <div class="channel-title-wrap">
                <div>{{ project.VesselName }}</div>
                <div style="font-size: 40px">Channel</div>
            </div>
            <div class="button-wrap">
                <button (click)="onCancel()">
                    {{ "channel.label.cancel" | translate }}
                </button>
                <button
                    [disabled]="isButtonDisabled()"
                    (click)="onSave()"
                    class="primary"
                >
                    {{ "channel.label.save" | translate }}
                </button>
            </div>
        </div>
        <div
            class="panel-label"
            (click)="onOpenPanel()"
            *ngIf="isShowElements() && channelGroups.length > 0"
        >
            <div *ngIf="!showPannel">
                <span class="material-symbols-outlined panel-icon">
                    open_in_full
                </span>
                {{ "channel.label.alarm.show-panel" | translate }}
            </div>
            <div *ngIf="showPannel">
                <span class="material-symbols-outlined panel-icon">
                    close_fullscreen
                </span>
                {{ "channel.label.alarm.hide-panel" | translate }}
            </div>
        </div>
        <div class="channel-contents">
            <div class="channel-sheet-continer">
                <app-channel-sheet
                    [groups]="channelGroups"
                    [activeGroup]="activeGroup"
                    *ngIf="channelGroups.length > 0"
                    #channelSheet
                ></app-channel-sheet>
                <div *ngIf="channelGroups.length == 0">
                    <input
                        id="selectedFile"
                        type="file"
                        accept=".xlsm"
                        (change)="selectedFileChange($event)"
                        #fileInput
                    />
                    <button (click)="importExcelFile()">import</button>
                </div>
            </div>
            <div
                class="channel-pannel"
                *ngIf="showPannel && channelGroups.length > 0"
            >
                <app-channel-panel
                    [channelGroups]="channelGroups"
                    [channelCategories]="channelCategories"
                ></app-channel-panel>
            </div>
        </div>
    </div>
</div>
<app-dialog-confirm
    #confirm
    [title]="'title'"
    (confirmEmitter)="onConfirm($event)"
></app-dialog-confirm>
